import React from "react"
import Table from "components/Table"
import { config } from "./config"
import withData from "hoc/withData"
import { Box } from "styledComponents/Containers"
import withState from "hoc/withState"
import withUrls from "hoc/withUrls"
import withSubmit from "hoc/withSubmit"
import * as R from "ramda"
import { involvedPartyRequest } from "data/cockpit/api"
import ModalButtons from "components/ModalButtons"
import Button from "components/Button"
import LinkIcon from "icons/Link"
import PlusFillIcon from "icons/PlusFill"
import StopIcon from "icons/Stop"
import WarningConfirmModal from "components/WarningConfirmModal"
import { conclusionRequest } from "data/conclusions/api"

const LinkedObjectsTable = ({
  data,
  loading,
  refetchData,
  ids,
  setIds,
  submit,
  urls,
  party,
  conclusionTypes,
  case_,
  suburls,
}) => {
  const handleCheck = id => {
    if (ids.includes(id)) {
      return setIds(ids.filter(x => x !== id))
    }

    return setIds(ids.concat(id))
  }
  const d = R.pathOr([], ["objects"], data)

  return (
    <>
      <Table
        loading={loading}
        sorting={{
          index: 7,
          desc: true,
        }}
        config={config()}
        count={R.path(["count"], data)}
        data={d}
        refetch={refetchData}
        ids={ids}
        handleCheck={handleCheck}
        boldHeader
        darkHeader
        borderless
        conclusionTypes={conclusionTypes}
        noPagination={d.length < 5}
        suburls={suburls}
      />
      {case_.canEdit && (
        <Box mt={20} mb={20} mr={20}>
          <ModalButtons nomargin>
            <Button
              secondary
              icon={LinkIcon}
              text="Link object"
              to={urls.linkObjects(party.id)}
            />
            {ids.length > 0 && (
              <WarningConfirmModal
                onConfirm={submit}
                title="Unlink selected objects from the Involved party"
                text="Selected assessments/conclusions will be unlinked from the involved party."
                buttonText="Do you want to unlink selected objects?"
                confirmText="Yes"
                cancelText="No"
                doConfirm={R.always(true)}
                dangerConfirm
              >
                {onClick => (
                  <Button
                    icon={StopIcon}
                    secondary
                    text="Unlink selected object"
                    onClick={onClick}
                  />
                )}
              </WarningConfirmModal>
            )}
            <Button
              text="Create assessment"
              icon={PlusFillIcon}
              to={`${suburls.assessmentTemplate.url()}?involvedPartyId=${
                party.id
              }`}
            />
          </ModalButtons>
        </Box>
      )}
    </>
  )
}

export default R.compose(
  withUrls,
  withData(() => conclusionRequest.listTypes(), {
    dataKey: "conclusionTypes",
  }),
  withData(p => involvedPartyRequest.objects(p.party.id, p.searchParams), {
    refetchParams: p => [p.searchParams],
    clearOnRefetch: true,
    noLoader: true,
    onError: p => () => {
      p.refetchParent()
    },
  }),
  withState("ids", "setIds", []),
  withSubmit({
    onSubmit: p => () =>
      involvedPartyRequest.unlink(p.party.id, { objects: p.ids }),
    onSuccess: p => () => {
      p.setIds([])
      p.refetchData()
      p.refetchParent()
    },
    successText: () => () => "Selected objects have been unlinked.",
  }),
)(LinkedObjectsTable)

import React from "react"
import { filterSafeProps } from "common/react"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"

const InputWrap = ({
  children,
  label,
  error,
  required,
  noEmptyError,
  sublabel,
  labelAfter,
  readOnly,
  ...rest
}) => (
  <Box mb={20} {...filterSafeProps(rest)}>
    {label && (
      <Box flex acenter>
        <P mb={5} required={required}>
          <P bold gray inline>
            {label}
          </P>
          {sublabel && (
            <P ml={5} inline lightgray>
              ({sublabel})
            </P>
          )}
        </P>
        {labelAfter}
      </Box>
    )}
    {children}
    {(!noEmptyError || error) && (
      <P fs={12} error mt={3}>
        {error}
      </P>
    )}
  </Box>
)

export default InputWrap

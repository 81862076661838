import React from "react"
import { RibbonsWrap, Ribbon } from "./styled"

const Ribbons = ({ ribbons, left = 20, right, inline, light }) => (
  <RibbonsWrap left={left} right={right} inline={inline}>
    {ribbons.map((x, xi) => (
      <Ribbon light={light} key={xi}>
        {x}
      </Ribbon>
    ))}
  </RibbonsWrap>
)

export default Ribbons

import React from "react"
import withData from "hoc/withData"
import AddCommentForm from "./AddCommentForm"
import CommentList from "./CommentList"
import * as R from "ramda"

const Comments = ({
  data,
  refetchData,
  fetchCount,
  assessmentId,
  requests,
  readOnly,
  noAttachments,
  setOpenId,
  openId,
  addCommentId,
  sublabel,
  noAdd,
  long,
}) => (
  <>
    <AddCommentForm
      key={`add-${fetchCount}`}
      onSuccess={refetchData}
      assessmentId={assessmentId}
      requests={requests}
      open={() => setOpenId(addCommentId)}
      isOpen={openId === addCommentId && openId !== undefined}
      isSomeOpen={!R.isNil(openId)}
      close={() => setOpenId()}
      noAttachments={noAttachments}
      readOnly={readOnly}
      sublabel={sublabel}
      noAdd={noAdd}
      long={long}
    />
    <CommentList
      key={`list-${fetchCount}`}
      comments={data}
      refetch={refetchData}
      assessmentId={assessmentId}
      requests={requests}
      readOnly={readOnly}
      setOpenId={setOpenId}
      openId={openId}
      noAttachments={noAttachments}
      long={long}
    />
  </>
)

export default withData(p => p.requests.comments)(Comments)

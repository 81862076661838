import React from "react"
import FixedPanel from "components/FixedPanel"
import ModalButtons from "components/ModalButtons"
import { statuses } from "data/assessments/statuses"
import { Spread } from "styledComponents/Containers"
import BookmarkedOnly from "components/BookmarkedOnly"
import ApprovePanel from "./ApprovePanel"
import DelegateButton from "./DelegateButton"

const BottomPanel = ({
  assessment,
  status,
  isQuestionsTab,
  refetch,
  bookmarkedOnly,
  setBookmarkedOnly,
  suburlsArr,
  currIndex,
  isPreassessment,
  canEdit,
  refetchCase,
  case_,
}) => {
  const inProgress = status === statuses.inProcess.value
  const inApproval = status === statuses.requestForApproval.value
  const canApprove =
    status === statuses.requestForApproval.value && assessment.canApprove

  if (inApproval && (canApprove || assessment.canEdit)) {
    return (
      <ApprovePanel
        assessment={assessment}
        refetch={refetch}
        bookmarkedOnly={bookmarkedOnly}
        setBookmarkedOnly={setBookmarkedOnly}
        canApprove={canApprove}
        isQuestionsTab={isQuestionsTab}
        suburlsArr={suburlsArr}
        currIndex={currIndex}
        isPreassessment={isPreassessment}
        refetchCase={refetchCase}
        case_={case_}
      />
    )
  }
  if (isQuestionsTab && canEdit) {
    return (
      <FixedPanel>
        <Spread>
          <BookmarkedOnly value={bookmarkedOnly} onChange={setBookmarkedOnly} />
          {inProgress && (
            <ModalButtons nomargin>
              <DelegateButton
                assessment={assessment}
                isPreassessment={isPreassessment}
                refetchCase={refetchCase}
              />
            </ModalButtons>
          )}
        </Spread>
      </FixedPanel>
    )
  }

  return null
}
export default BottomPanel

import styled, { css } from "styled-components/macro"

export const EmptyWrap = styled.div`
  text-align: center;
  padding: 10px;

  svg {
    flex-shrink: 0;
  }

  ${p =>
    p.arrow &&
    css`
      display: flex;
      align-items: flex-end;
      padding-top: 20px;
      text-align: left;
    `}
`

import styled from "styled-components/macro"

export const ItemWrap = styled.div`
  display: flex;
  border-bottom: 1px solid #ccc;
  padding-bottom: 10px;
  margin-bottom: 10px;

  &:last-of-type {
    margin: 0;
    border: 0;
  }
`

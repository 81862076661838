import React from "react"
import Link from "components/Link"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"

const English = () => (
  <>
    <P italic mb={40} bold fs={24}>
      English:
    </P>
    <P bold mb={20} fs={20}>
      Privacy Statement according to Art. 13, 14 EU General Data Protection
      Regulation (GDPR)
    </P>
    <P mb={20}>
      The following privacy statement is intended to explain to you in a clear,
      transparent and understandable way how your personal data is processed by
      us while you use PwC DAC6 Compliance Solution (
      <Link to="https://dac6compliance.pwc.com">
        https://dac6compliance.pwc.com
      </Link>
      ). However, if you have any questions of understanding or other queries
      about data protection at PwC, you are welcome to contact the data
      protection officer for your territory as outlined in the Data Controller
      statement.
    </P>
    <P mb={20}>
      DAC6 Compliance Solution is developed to prepare DAC6 forms, to be filed
      with the tax authorities. It includes an assessment function as a
      necessary first pre-step in the course of preparing these forms. It
      assists Customers with its DAC6 compliance.
    </P>
    <P mb={20}>
      DAC6 Compliance Solution may be used in the context of engagements where
      clients are granted access. Engagements in this context means that you or
      your employer enter into or have entered into a business relationship
      (advisory service) with PwC. In addition to this privacy notice, section 6
      applies.
    </P>
    <P mb={20}>
      DAC6 Compliance Solution may be offered or distributed as SaaS
      stand-alone. SaaS stand-alone means in this context that the technology is
      offered or distributed exclusively outside of a specific consultancy
      service. The responsibility for the processing activities described in
      section 7 concerns the controller according to Article 4 No. 7 of the GDPR
      named therein. Section 7 is purely informative in this context.
    </P>

    <P bold>1. Controller</P>
    <P mb={20}>
      Controller within the meaning of Art. 4 (7) GDPR for the processing of
      your personal data is:{" "}
    </P>

    <P bold>PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft</P>
    <P>Friedrich-Ebert-Anlage 35-37</P>
    <P>60327 Frankfurt am Main</P>
    <P>email: webkontakt_anfragen@de.pwc.com</P>
    <P>Phone Central: +49 69 9585-0</P>
    <P mb={20}>Fax: +49 69 9585-1000</P>

    <P bold mb={20}>
      2. Data Protection Officer
    </P>
    <P mb={20}>
      PwC has appointed a Data Protection Officer in accordance with Art. 37
      GDPR. You can contact the data protection officer of PwC, Dr. Tobias
      Gräber, at the following contact details:{" "}
    </P>

    <P>
      E-Mail:{" "}
      <Link to="mailto:DE_Datenschutz@pwc.com">DE_Datenschutz@pwc.com</Link>
    </P>
    <P mb={20}>Telefon: +49 69 9585-0</P>

    <P>Postal contact address:</P>
    <P>PricewaterhouseCoopers GmbH WPG</P>
    <P>Dr. Tobias Gräber, Data Protection Officer</P>
    <P>Friedrich-Ebert-Anlage 35-37</P>
    <P mb={20}>60327 Frankfurt am Main</P>

    <P bold mb={20}>
      3. Data subject rights/your rights under data protection law
    </P>
    <P mb={20}>
      You have the following rights under applicable data protection law with
      respect to personal data concerning you.
    </P>
    <P mb={20}>
      <P inline bold>
        Right to access:
      </P>{" "}
      You can request access from us at any time about whether and which
      personal data we store about you. The provision of information is free of
      charge for you. The right to access does not exist or exists only to a
      limited extent if and insofar as information requiring secrecy would be
      disclosed by the information, e.g. information subject to professional
      secrecy.
    </P>
    <P mb={20}>
      <P inline bold>
        Right to rectification:
      </P>{" "}
      If your personal data stored by us is incorrect or incomplete, you have
      the right to demand that we correct this data at any time.
    </P>
    <P mb={20}>
      <P inline bold>
        Right to erasure:
      </P>{" "}
      You have the right to request PwC to erase your personal data if and to
      the extent that the data is no longer needed for the purposes for which it
      was collected or, if the processing is based on your consent, you have
      withdrawn your consent. In this case, we must stop processing your
      personal data and remove it from our IT systems and databases.
    </P>

    <P>A right to erasure does not exist insofar as</P>
    <P ul ml={20} mb={20}>
      <li>
        the data may not be erased due to a legal obligation or must be
        processed due to a legal obligation;
      </li>
      <li>
        the data processing is necessary for the assertion, exercise or defense
        of legal claims.
      </li>
    </P>
    <P mb={20}>
      <P inline bold>
        Right to restriction of processing:
      </P>{" "}
      You have the right to request that we restrict the processing of your
      personal data.
    </P>

    <P mb={20}>
      <P inline bold>
        Right to data portability:
      </P>{" "}
      You have the right to receive from us the data you have provided in a
      structured, common and machine-readable format, as well as the right to
      have this data transferred to another controller. This right only exists
      if
    </P>
    <P ul ml={20} mb={20}>
      <li>
        you have provided us with the data on the basis of consent or on the
        basis of a contract concluded with you;
      </li>
      <li>
        the processing is carried out with the aid of automated procedures.
      </li>
    </P>
    <Box mb={20} highlight>
      <P>
        Right to object to processing: If the processing of your data is based
        on Art. 6 (1) (f) GDPR, you may object to the processing at any time.
      </P>
    </Box>

    <P mb={20}>
      All of the data subject rights described above may be asserted against PwC
      by sending your specific request to the following contact information:
    </P>

    <P bold>By E-Mail:</P>
    <P mb={20}>
      <Link to="mailto:DE_Datenschutz@pwc.com">DE_Datenschutz@pwc.com</Link>
    </P>

    <P bold>By mail:</P>
    <P>PricewaterhouseCoopers GmbH WPG</P>
    <P>Dr. Tobias Gräber, Data Protection Officer</P>
    <P>Friedrich-Ebert-Anlage 35-37</P>
    <P mb={20}>60327 Frankfurt am Main</P>

    <P bold mb={20}>
      4. Right of appeal to a data protection supervisory authority
    </P>

    <P mb={20}>
      In accordance with Art. 77 GDPR, you have the right to lodge a complaint
      with a data protection supervisory authority if you believe that the
      processing of your personal data violates data protection law.
    </P>

    <P bold mb={20}>
      5. Description of the data processing and legal bases for the processing
    </P>

    <P bold mb={20}>
      5.1 Recipients of personal data
    </P>

    <P mb={20}>
      In order to fulfill the processing purposes listed below, a data transfer
      to third parties also takes place. This may also include the transfer of
      personal data to other European and non-European countries and the storage
      of personal data also outside the EU or the European Economic Area (EEA).
    </P>

    <P underline mb={20}>
      Recipients bound by instructions
    </P>

    <P mb={20}>
      We share your data with service providers bound by instructions, both
      within the PwC network and with other third parties, such as IT service
      providers, who support us in our activities, e.g. in the context of the
      administration and maintenance of the websites and the associated systems
      and/or for other internal or administrative purposes.
    </P>

    <P mb={20}>
      PwC is a member of the global PwC network, which consists of the
      individual legally independent PwC firms. In the course of our activities,
      we use other German or foreign PwC network companies as network-internal
      IT service providers bound by instructions, which provide services of
      operation, maintenance and care of the IT systems as well as applications
      used by the PwC network companies. In particular, this is PwC IT Services
      Ltd. based in the United Kingdom (UK).
    </P>

    <P mb={20}>
      If we pass on data to service providers bound by instructions, we do not
      require a separate legal basis for this.
    </P>

    <P mb={20}>
      You can find out more information about the cloud service providers used
      by PwC at the following link:{" "}
      <Link to="www.pwc.de/externe-dienstleister">
        www.pwc.de/externe-dienstleister
      </Link>
      .
    </P>

    <P mb={20} underline>
      Independent recipients
    </P>

    <P mb={20}>
      In addition, in individual cases we pass on your data both within the PwC
      network and to other third parties who use your data on their own
      responsibility. For example, in individual cases we also transfer personal
      data to other companies in the PwC network, in order to support and
      improve the effectiveness of our business processes (including coordinated
      marketing activities).
    </P>

    <P mb={20}>
      In addition, in individual cases, we also disclose your data to other
      third parties, such as public authorities, courts or other bodies, if we
      are required by law or by official or court order of an EU member state to
      disclose personal data to these bodies. These bodies also use the data on
      their own responsibility.
    </P>

    <P mb={20}>
      Insofar as you have explicitly consented, Art. 6 para. 1 lit. a) GDPR is
      the legal basis for the data transfer. Insofar as there is a legal
      obligation to disclose the data, Art. 6 para. 1 lit. c) GDPR is the legal
      basis for the data transfer. If, on the other hand, the disclosure is
      necessary for the fulfillment of a contractual or pre-contractual measure
      with you as a natural person, Art. 6 para. 1 lit. b) GDPR is the legal
      basis for the data transfer. Otherwise, the transfer is based on our
      legitimate interests and the legal basis is Art. 6 para. 1 lit f) GDPR; we
      and the other companies in the PwC network have an interest in making our
      work processes efficient and sharing business processes within the PwC
      network for this purpose.
    </P>

    <P underline>
      Data transfer to recipients in third countries outside the EU/EEA
    </P>
    <P mb={20}>
      To the extent that any of the above-mentioned data transfers are made to a
      recipient outside the EEA (to so-called "third countries"), an adequate
      level of data protection for the foreign transfer is ensured through
      appropriate security measures.
    </P>

    <P mb={20}>
      For data transfers within the PwC network, the PwC network companies have,
      among other things, concluded an internal data protection agreement which
      provides for compliance with the EU standard contractual clauses of the EU
      Commission within the meaning of Art. 46 para. 2 lit. c GDPR for data
      transfers of personal data from EU/EEA countries to PwC network companies
      outside the EU/EEA.
    </P>

    <P mb={20}>
      To the extent that you have any questions regarding such data protection
      agreements based on the EU standard contractual clauses or you would like
      to have more information regarding further security mechanisms and
      security measures for the transfer of data to third countries, please feel
      free to contact our data protection officer, e.g. at
      DE_Datenschutz@pwc.com.
    </P>

    <P bold mb={20}>
      5.2 Processing of personal data when accessing and using PwC DAC6
      Compliance Solution
    </P>

    <P mb={20}>
      When you access the web-based tool, we collect the data that is
      technically necessary to display PwC DAC6 Compliance Solution to you. This
      is the following personal data, which is automatically transmitted to our
      server by your browser:
    </P>

    <P>DAC6 Compliance Solution:</P>
    <P ul ml={20} mb={20}>
      <li>E-mail address</li>
    </P>

    <P>
      Azure only and for troubleshooting purposes (please note the below is only
      at Azure level, our DCS solution does NOT process or collect the below):
    </P>
    <P ul ml={20} mb={20}>
      <li>IP address (and location based on IP address)</li>
      <li>Date and time of your request/call </li>
      <li>Time zone difference from Greenwich Mean Time (GMT)</li>
      <li>
        Content of the request (information about which specific page you
        accessed)
      </li>
      <li>Access status/http status code</li>
      <li>Website, from which the request was initiated</li>
      <li>Browser (information about the browser you are using)</li>
      <li>
        Operating system and its interface (operating system of the computer you
        use to access (Name of the technology))
      </li>
      <li>Language and version of the browser software</li>
      <li>
        Device identifier (this is the unique number of your device, e.g. UDID,
        DeviceID, IMEI)
      </li>
      <li>Network subscriber number (IMSI)</li>
      <li>Mobile phone number (MSISDN)</li>
      <li>MAC address for WiFi usage</li>
      <li>Name of the mobile device</li>
    </P>

    <P mb={20}>
      The tool cannot be accessed and offered to users without the use of this
      data; there is a legitimate interest in making the call-up and use of the
      tool technically possible. In addition, the data is stored in order to
      technically secure PwC DAC6 Compliance Solution. The responsible party has
      an interest in the security and availability of the tool. The processing
      of this personal data is based on Art. 6 para. 1 lit. f) DSGVO.
    </P>

    <P mb={20}>
      The above enlisted data is stored for 90 days and then deleted.
    </P>

    <P mb={20}>
      PwC DAC6 Compliance Solution is hosted by the service provider Microsoft
      Corporation One Microsoft Way Redmond, WA 98052-6399 USA. The personal
      data collected on our DAC6 Compliance Solution is therefore stored on the
      servers of the service provider. The server locations are in Germany and
      other EU countries.
    </P>

    <P bold mb={20}>
      5.3 Registration and Login
    </P>

    <P mb={20}>
      Accessing and using our PwC DAC6 Compliance Solution is not possible
      without registration and login. Registration can only take place following
      the signing of a contract with PwC.
    </P>

    <P mb={20}>
      The registration will be done via the Group and User management (GUM) of
      PwC Identity, our firm wide single-sign-on-solution. In this context you
      have to register with several personal data attributes listed below to be
      able to login via PwC Identity.
    </P>

    <P mb={20}>
      During registration and login, PwC processes the following information:
    </P>
    <P ul ml={20} mb={20}>
      <li>Email address</li>
      <li>Name</li>
      <li>Mobile phone number (optional)</li>
      <li>Password</li>
    </P>

    <P mb={20}>
      This personal data is processed on the basis of Art. 6 para. 1 c) in
      connection with Art. 32 GDPR. All data will be erased by us after
      termination of the contractual relationship. Insofar as legal storage
      obligations exist, the data will be stored for the duration of the legal
      retention period.
    </P>

    <P bold mb={20}>
      5.4 Cookies
    </P>

    <P mb={20}>
      In order to make visiting our tool attractive and to enable the use of
      certain functions, we use cookies on our tool. These are small text files
      that are stored on your terminal device. Some of the cookies we use are
      deleted after the end of the browser session, i.e. after you close your
      browser (so-called session cookies). Other cookies remain on your terminal
      device and enable us or our partner companies to recognize your browser on
      your next visit (so-called persistent cookies).
    </P>

    <P mb={20}>The following cookie categories are used:</P>

    <P mb={20} underline>
      No Cookies are used in the recent technical setup of the tool. However we
      provide you with information on technically necessary cookies in case this
      may change in future technical setups.
    </P>

    <P>
      <P inline bold>
        Technically necessary
      </P>{" "}
      cookies:
    </P>
    <P mb={20}>
      These cookies are technically necessary for the operation and
      functionality of the website. They make the website technically
      accessible, secure and usable and provide essential and basic
      functionalities, such as navigation on the website, the correct display of
      the website in the Internet browser or consent management.
    </P>

    <P mb={20}>
      The legal basis for the use of technically necessary cookies is Section 25
      par. 2 number 2 of the German Telecommunication-Telemedia- Act ("TTDSG")
      and on the basis of Art. 6 par. 1 f) GDPR to protect our legitimate
      interests. Our legitimate interests here lie in particular in being able
      to provide you with a technically optimized and user-friendly website
      designed to meet your needs, as well as to ensure the security of our
      systems.
    </P>

    <P bold mb={20}>
      6. Data processing when using DAC6 Compliance Solution within engagements
    </P>

    <P mb={20}>You or your employer obtain a consultancy service from PwC.</P>

    <P mb={20}>
      We use DAC6 Compliance Solution to provide our contractually agreed
      services. In addition to this data privacy notice, the "Privacy Policy
      PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft" applies,
      which was provided prior to our engagement. The "Privacy Policy
      PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft" describes how
      PwC processes personal data within the scope of various engagements e.g.
      tax consultancy, legal consultancy or management consultancy/auditing
      services or other assignments. The "Privacy Policy PricewaterhouseCoopers
      GmbH Wirtschaftsprüfungsgesellschaft" can be accessed again at the
      following link:
    </P>
    <P mb={20}>
      <Link to="https://www.pwc.de/en/privacy-policy-for-clients/privacy-policy-pricewaterhousecoopers-gmbh.html">
        https://www.pwc.de/en/privacy-policy-for-clients/privacy-policy-pricewaterhousecoopers-gmbh.html
      </Link>
    </P>

    <P bold mb={20}>
      7. Processing for which the customer is the Controller (SaaS stand-alone)
    </P>

    <P mb={20}>
      Processing of customer data within DAC6 Compliance Solution as SaaS
      stand-alone, where the client only obtains the tool and no
      advisory/consultancy/auditing services from PwC:
    </P>

    <P>
      Personal data is processed within DAC6 Compliance Solution. This data
      processing is the Client's responsibility (Art. 4 (7) GDPR). In this
      context, PwC acts as a software-as-a-service (SaaS) provider for the
      client as a processor within the meaning of Art. 4 No. 8 GDPR.
    </P>
  </>
)

export default English

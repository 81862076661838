import styled, { css } from "styled-components/macro"
import boxModel from "../bases/boxModel"
import theme from "theme"

export const IconWrap = styled.div`
  width: 40px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: all;
  cursor: pointer;
  ${boxModel};

  ${p =>
    p.autowidth &&
    css`
      width: auto;
    `};

  svg {
    width: 16px;
    height: 16px;
    fill: #666;

    ${p =>
      p.large &&
      css`
        width: 24px;
        height: 24px;
      `};

    ${p =>
      p.extra &&
      css`
        width: 36px;
        height: 36px;
      `};

    ${p =>
      p.rotate &&
      css`
        transform: rotate(180deg);
      `};

    ${p =>
      p.rotateLeft &&
      css`
        transform: rotate(-90deg);
      `};
  }

  &:hover {
    svg {
      fill: #333;
    }
  }

  ${p =>
    p.static &&
    css`
      pointer-events: none;
    `};

  ${p =>
    p.height &&
    css`
      height: ${p.height}px;
    `};

  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
      opacity: 0.5;
    `};

  ${p =>
    p.error &&
    css`
      svg {
        fill: ${theme.color.error};
      }
    `};
  ${p =>
    p.success &&
    css`
      svg {
        fill: ${theme.color.success};
      }
    `};
  ${p =>
    p.red &&
    css`
      svg {
        fill: ${theme.color.red};
      }
    `};
  ${p =>
    p.black &&
    css`
      svg {
        fill: #333;
      }
    `};
  ${p =>
    p.white &&
    css`
      svg {
        fill: #fff;
      }
    `};
`

export const ActiveIcon = styled(IconWrap)`
  border: 1px solid #999;
  border-radius: 4px;
  height: 30px;
  width: 30px;
  margin-right: 10px;

  &:last-child {
    margin-right: 0;
  }

  svg {
    fill: #999;
  }

  &:hover {
    border-color: ${theme.color.red};
    svg {
      fill: ${theme.color.red};
    }
  }

  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
      opacity: 0.4;
    `};

  ${p =>
    p.active &&
    css`
      &,
      &:hover {
        background: ${theme.color.red};
        border-color: ${theme.color.red};
        svg {
          fill: #fff;
        }
      }
    `};

  ${p =>
    p.borderless &&
    css`
      border: none;
    `};
`

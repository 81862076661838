export const folderStatuses = {
  inProcess: {
    value: "InProgress",
    name: "In progress",
    color: "rgb(208, 201, 221)",
  },
  completed: {
    value: "Completed",
    name: "Completed",
    color: "rgb(99, 74, 143)",
  },
}

export const caseStatuses = {
  inProcess: {
    value: "InProgress",
    name: "In progress",
    color: "rgb(179, 220, 249)",
  },
  completed: {
    value: "Completed",
    name: "Completed",
    color: "rgb(38, 155, 238)",
  },
}

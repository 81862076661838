import React from "react"
import LogicNode from "./LogicNode"
import { P } from "styledComponents/Typography"
import { localize } from "locale"
import { addImplicitBrackets, hasMixedOperators } from "./helpers"
import Warning from "components/Warning"

const LogicView = ({
  title,
  previousQuestions,
  value,
  entityName,
  readOnly,
}) => {
  if (!value || value.length === 0) return null

  const transformed = addImplicitBrackets(value)
  return (
    <>
      <P gray bolder mb={5}>
        {title}
      </P>
      {hasMixedOperators(value) && !readOnly && (
        <Warning
          error
          text={localize("pleaseNoteThatYouHaveEnteredAndAndOr")}
        />
      )}
      <LogicNode
        item={transformed}
        indices={[]}
        questions={previousQuestions}
        entityName={entityName}
      />
    </>
  )
}

export default LogicView

import React from "react"
import Loader from "components/Loader"
import Empty from "components/Empty"

const EmptyLoader = ({
  children,
  // flags deciding what to render
  isLoading,
  isEmpty,
  // props for predefined components
  loaderProps,
  emptyProps,
  // custom components
  loader,
  empty,
}) => {
  if (isLoading) return loader ? loader() : <Loader {...loaderProps} />
  if (isEmpty) return empty ? empty() : <Empty {...emptyProps} />

  return children()
}

export default EmptyLoader

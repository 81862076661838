import TableRow from "./TableRow"
import * as R from "ramda"

export const config = () => ({
  rowComponent: TableRow,
  columns: [
    { label: "Internal arrangement ID", sortBy: R.prop("arrangementId") },
    {
      label: "Name",
      sortBy: R.propOr("", "name"),
    },
    {
      label: "Country",
      sortBy: R.propOr("", "countryCode"),
    },
    {
      label: "Status",
      sortBy: R.propOr("", "status"),
    },
    {
      label: "Conclusion type",
      sortBy: R.propOr("", "conclusionType"),
    },
    {
      label: "Due date",
      sortBy: R.propOr("", "dueDateUtc"),
    },
    {
      label: "Contact",
      sortBy: R.propOr("", "contact"),
    },
    {
      label: "Last status change",
      sortBy: R.propOr("", "lastStatusChange"),
    },
    {
      label: "",
    },
  ],
})

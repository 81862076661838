import React from "react"
import Autocomplete from "components/Autocomplete"
import Button from "components/Button"
import Empty from "components/Empty"
import UserList from "inputs/UserList"
import withData from "hoc/withData"
import withFormField from "hoc/withFormField"
import Radio from "inputs/Radio"
import { localize } from "locale"
import * as R from "ramda"
import { InputWithButtonWrap } from "styledComponents/Containers"
import withAutocomplete from "./withAutocomplete"
import withDefaultProps from "hoc/withDefaultProps"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"
import withState from "hoc/withState"

const AutocompleteList = ({
  data,
  loading,
  text,
  changeText,
  selectedOption,
  selectAutocompleteItem,
  handleAdd,
  handleRemove,
  handleClear,
  role,
  setRole,
  listRoleChange,
  value = [],
  readOnly,
  roles,
  showRadio,
  mapOptions,
  title,
  optionToAvatar,
  optionToTitle,
  optionToSubtitle,
  emptyListText,
  noResultText,
}) => {
  const options = data ? mapOptions(data) : []
  const isEmpty = value.filter(x => x.permission !== null).length === 0

  return (
    <>
      {!readOnly && (
        <InputWithButtonWrap>
          <Autocomplete
            options={options}
            loading={loading}
            text={text}
            changeText={changeText}
            onChange={selectAutocompleteItem}
            noResultText={noResultText}
          />
          {showRadio && (
            <Radio options={roles} value={role} onChange={setRole} inline />
          )}
          <Button
            text={localize("add")}
            disabled={!selectedOption}
            onClick={handleAdd}
          />
        </InputWithButtonWrap>
      )}
      {isEmpty ? (
        <Empty text={emptyListText} arrow requiredText />
      ) : (
        <UserList
          value={value}
          title={title}
          optionToAvatar={optionToAvatar}
          optionToTitle={optionToTitle}
          optionToSubtitle={optionToSubtitle}
          removeUser={readOnly ? undefined : handleRemove}
          clear={readOnly ? undefined : handleClear}
          readOnly={readOnly}
          // we could just filter the users prop, but we're depending on indices in update/remove functions
          hideIf={x => x.permission === null}
          customComponent={
            showRadio
              ? (u, i) => (
                  <Radio
                    inline
                    options={roles}
                    value={u.permission}
                    byValue
                    readOnly={readOnly}
                    onChange={listRoleChange(i)}
                  />
                )
              : undefined
          }
        />
      )}
    </>
  )
}

export default R.compose(
  withFormField,
  withDefaultProps({ value: [] }),
  withState("role", "setRole", p => p.roles && p.roles[0]),
  withAutocomplete,
  withData(
    p =>
      p.request(
        p.text,
        p.value.filter(x => x.permission !== null).map(x => x.id),
      ),
    {
      skip: p => !p.text,
      refetchParams: p => [p.text],
      noLoader: true,
    },
  ),
  withProps(p => ({ showRadio: p.roles && p.roles.length > 1 })),
  withHandlers({
    handleAdd: p => () => {
      p.onChange(
        p.value
          // in case it's there but with permission set to null
          .filter(x => x.id !== p.selectedOption.id)
          .concat({
            ...p.selectedOption,
            ...(p.roles ? { permission: p.role.value } : {}),
          }),
      )
      p.resetAutocomplete()
    },
    handleRemove: p => i => {
      // this means removal is done, by setting permission to null
      if (p.deleteByNullingPermission) {
        return p.onChange(R.set(R.lensPath([i, "permission"]), null, p.value))
      }

      return p.onChange(R.remove(i, 1, p.value))
    },
    handleClear: p => () => {
      if (p.deleteByNullingPermission) {
        return p.onChange(p.value.map(x => ({ ...x, permission: null })))
      }

      return p.onChange([])
    },
    listRoleChange: p => i => v =>
      p.onChange(R.update(i, { ...p.value[i], permission: v }, p.value)),
  }),
)(AutocompleteList)

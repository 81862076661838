import React, { useEffect } from "react"
import Button from "components/Button"
import Country from "components/Country"
import Panel from "components/Panel"
import Columns from "components/Columns"
import { Hr, P } from "styledComponents/Typography"
import { localize } from "locale"
import { formatDate } from "data/units/date/formatters"
import Language from "components/Language"
import withUrls from "hoc/withUrls"
import LabelValue from "components/LabelValue"
import { Box, Spread } from "styledComponents/Containers"
import Link from "components/Link"
import FileIcon from "icons/File"
import * as R from "ramda"
import { assessmentLibraryRequest } from "data/assessments/api"
import withData from "hoc/withData"
import withOpen from "hoc/withOpen"
import Ribbons from "components/Ribbons"
import { ActiveIcon } from "styledComponents/Icons"
import CaretIcon from "icons/Caret"
import useOpen from "hooks/useOpen"
import ButtonModal from "components/ButtonModal"
import CreateAssessmentInvolvedPartyModal from "./CreateAssessmentInvolvedPartyModal"
import CreateAssessmentFromLibraryModal from "components/CreateAssessmentFromLibraryModal"
import CreatePreassessmentModal from "./CreatePreassessmentModal"

const Template = ({
  template,
  urls,
  involvedPartyId,
  isOpen,
  toggle,
  libraryItems,
  case_,
  caseUrls,
  isPreassessment,
  autoCreate,
  refetchCase,
}) => {
  const { isOpen: isDetailsOpen, toggle: toggleDetails } = useOpen()
  const {
    isOpen: isCreateEmptyOpen,
    open: openCreateEmpty,
    close: closeCreateEmpty,
  } = useOpen()

  useEffect(() => {
    if (autoCreate) {
      openCreateEmpty()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Panel mb={20} noPadding data-id={`template:${template.name}`}>
      <Box p={20}>
        <Spread mb={20}>
          <P fs={18} bold mb={15}>
            {template.name}
          </P>
          <Box flex acenter>
            <P nowrap nobreak mr={10}>
              {template.countryCode}
            </P>
            <ActiveIcon onClick={toggleDetails} rotate={isDetailsOpen}>
              <CaretIcon />
            </ActiveIcon>
          </Box>
        </Spread>
        {isDetailsOpen && (
          <>
            <Columns mb={15}>
              <LabelValue
                label="Published"
                value={formatDate(template.publishedAtUtc)}
              />
              <LabelValue
                label="Applicable time from"
                value={
                  formatDate(template.applicableTimePeriodStart) ||
                  localize("n/a")
                }
              />
              <LabelValue
                label="to"
                value={
                  formatDate(template.applicableTimePeriodEnd) ||
                  localize("n/a")
                }
              />
            </Columns>
            <Columns>
              <LabelValue
                label="Language"
                value={<Language code={template.languageCode} />}
              />
              {!isPreassessment && (
                <LabelValue
                  label="Country"
                  value={<Country code={template.countryCode} />}
                />
              )}
              {!isPreassessment && (
                <LabelValue
                  label="Version"
                  value={template.schemas
                    .map(x => `${x.reportType} v${x.version}`)
                    .join(", ")}
                />
              )}
            </Columns>
            <Hr />
            <P mb={20}>{template.description}</P>
          </>
        )}
        <Box flex acenter>
          {template.containsPublishedLibraryItems && (
            <Link ul onClick={toggle} mr="auto">
              {`${isOpen ? "Hide" : "Show"} related library items`}
            </Link>
          )}
          <Box ml="auto">
            {case_ ? (
              <>
                {isPreassessment ? (
                  <>
                    <Button
                      secondary
                      text="Create empty pre-assessment"
                      onClick={openCreateEmpty}
                    />
                    {isCreateEmptyOpen && (
                      <CreatePreassessmentModal
                        close={closeCreateEmpty}
                        redirect={r => `${caseUrls.detail.url(r.id)}`}
                        case_={case_}
                        templateId={template.id}
                        refetchCase={refetchCase}
                      />
                    )}
                  </>
                ) : (
                  <ButtonModal
                    secondary
                    text="Create empty assessment"
                    modal={CreateAssessmentInvolvedPartyModal}
                    involvedPartyId={involvedPartyId}
                    icon={FileIcon}
                    redirect={r => `${caseUrls.assessment.url(r.id)}`}
                    case_={case_}
                    templateId={template.id}
                  />
                )}
              </>
            ) : (
              <Button
                secondary
                text="Create empty assessment"
                to={`${urls.assessmentsCreate("new")}?templateId=${
                  template.id
                }`}
                icon={FileIcon}
                redirect={r => `${urls.assessmentsCreate(r)}`}
              />
            )}
          </Box>
        </Box>
      </Box>
      {isOpen && (
        <Box bg="#F2F2F2" p={20}>
          <P bold gray>
            Related library items
          </P>
          {libraryItems.map((x, xi) => (
            <React.Fragment key={x.id}>
              {xi > 0 && <Hr />}
              <Box mt={10} data-id={`libItem:${x.name}`}>
                <P mb={5}>{x.name}</P>
                {x.description && <P mb={5}>{x.description}</P>}
                <Ribbons light inline ribbons={x.tags.map(x => x.name)} />
                <Box flex>
                  {case_ ? (
                    <>
                      {isPreassessment ? (
                        <ButtonModal
                          ml="auto"
                          text="Create partly pre-filled pre-assessment"
                          modal={CreatePreassessmentModal}
                          libraryItemId={x.id}
                          redirect={r => `${caseUrls.detail.url(r.id)}`}
                          case_={case_}
                          refetchCase={refetchCase}
                        />
                      ) : (
                        <ButtonModal
                          ml="auto"
                          text="Create partly pre-filled assessment"
                          modal={CreateAssessmentInvolvedPartyModal}
                          involvedPartyId={involvedPartyId}
                          libraryItemId={x.id}
                          redirect={r => `${caseUrls.assessment.url(r.id)}`}
                          case_={case_}
                        />
                      )}
                    </>
                  ) : (
                    <ButtonModal
                      ml="auto"
                      text="Create partly pre-filled assessment"
                      modal={CreateAssessmentFromLibraryModal}
                      assessment={x}
                      isPreassessment={x.isPreAssessment}
                      redirect={r => `${urls.assessmentsCreate(r)}`}
                      refetchCase={refetchCase}
                    />
                  )}
                </Box>
              </Box>
            </React.Fragment>
          ))}
        </Box>
      )}
    </Panel>
  )
}

export default R.compose(
  withUrls,
  withOpen,
  withData(p => assessmentLibraryRequest.listTemplate(p.template.id), {
    refetchParams: p => [p.isOpen],
    skip: p => !p.isOpen,
    dataKey: "libraryItems",
  }),
)(Template)

import React from "react"
import PageTitle from "components/PageTitle"
import withData from "hoc/withData"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { Container } from "styledComponents/Containers"
import CaretIcon from "icons/Caret"
import { IconWrap } from "styledComponents/Icons"
import { preassessmentRequest } from "data/preassessments/api"
import TemplateComponent from "pages/AssessmentsTemplatePage/Template"
import Empty from "components/Empty"

const Template = ({ data, suburls, case_, history, refetchCase }) => {
  return (
    <Container>
      <PageTitle
        title={
          <Box flex acenter>
            <P red link fs={30} onClick={() => history.push(suburls.list.url)}>
              Pre-assessment
            </P>
            <IconWrap rotateLeft>
              <CaretIcon />
            </IconWrap>
            <P fs={30}>Choose template</P>
          </Box>
        }
      />
      {data.length > 0 ? (
        data.map(x => (
          <TemplateComponent
            key={x.id}
            template={x}
            case_={case_}
            caseUrls={suburls}
            isPreassessment
            refetchCase={refetchCase}
            autoCreate={
              data.length === 1 && !data[0].containsPublishedLibraryItems
            }
          />
        ))
      ) : (
        <Empty text="No published pre-assessment templates found. Please contact the template team." />
      )}
    </Container>
  )
}

export default withData(() => preassessmentRequest.listTemplates())(Template)

import React from "react"
import withModal from "hoc/withModal"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"

const ConfirmClearModal = ({
  question,
  submit,
  close,
  nonCustomRoot,
  questionsWithValue,
}) => {
  return (
    <Box>
      CLEAR!
      {nonCustomRoot ? (
        <P>
          Answer for question Q{question.idx} auto-filled from the Registry for
          this subject will be cleared. Do you want to proceed?
        </P>
      ) : (
        <>
          <P>
            Answers auto-filled from the Registry for this subject will be
            cleared. Do you want to proceed?
          </P>
          <P bold gray mt={20}>
            Answers to following questions will be cleared:
          </P>
          {questionsWithValue.map(q => (
            <P>Q{q.nrLabel}</P>
          ))}
        </>
      )}
      <ModalButtons buttonText="Do you want to proceed?">
        <Button secondary type="button" onClick={close} text="No" />
        <Button
          danger
          onClick={() => {
            submit()
            close()
          }}
          text="Yes, proceed"
        />
      </ModalButtons>
    </Box>
  )
}

export default R.compose(withModal(R.always("Auto-filling from Registry")))(
  ConfirmClearModal,
)

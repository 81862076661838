import styled, { css } from "styled-components/macro"

export const SpaceTr = styled.tr`
  td {
    padding: 5px;
  }
`

export const MainTr = styled.tr`
  border: 1px solid #d8d8d8;

  ${p =>
    p.isOpen &&
    css`
      border-bottom: 0;
    `}
`

export const TableTr = styled.tr`
  border: 1px solid #d8d8d8;
  border-top: 0;

  & > td {
    padding: 0;
  }

  table {
    border-bottom: 1px solid #d8d8d8;
  }
`

export const getAutofillText = (identifier, tag, active) => {
  if (!tag) return `Autofill ${identifier.text}`

  if (active) {
    return `Data auto-filled with subject: ${tag?.keyValue}`
  }

  return `Auto-filled data overriden. Registry value for subject: ${tag.keyValue} is different`
}

export const getAutofillActive = (tag, value) => {
  if (!tag) return false

  return tag.applicableRegistryValue === value
}

import React from "react"
import ButtonModal from "components/ButtonModal"
import AutofillIcon from "components/AutofillIcon"
import { InputWithButtonWrap } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import Textfield from "inputs/Textfield"
import FlashIcon from "icons/FlashActive"
import ManualRegistryAutofillModal from "../ManualRegistryAutofillModal"
import { getAutofillText, getAutofillActive } from "./helpers"

const DoReportFields = ({
  reporting,
  canEdit,
  setTags,
  tags,
  values,
  setFieldValue,
}) => (
  <>
    <P mb={20}>Fill these fields for reporting</P>
    {reporting.reportIdentifiers.map((x, xi) => {
      const tag = tags[x.reportIdentifierDefinitionId]
      const value = values[x.reportIdentifierDefinitionId]
      const autofillActive = getAutofillActive(tag, value)

      return (
        <InputWithButtonWrap key={xi} mb={20}>
          <Textfield
            name={x.reportIdentifierDefinitionId}
            label={x.text}
            sublabel={x.label}
            labelAfter={
              <Box flex acenter>
                <AutofillIcon
                  active={autofillActive}
                  content={getAutofillText(x, tag, autofillActive)}
                  data-id={`autofill-icon-${x.name}`}
                />
                {tag && !autofillActive && (
                  <P gray fs={13} bold>
                    Auto-filled data overriden
                  </P>
                )}
              </Box>
            }
            required={x.isMandatory}
            readOnly={!canEdit}
            noEmptyError
          />
          <Box>
            {canEdit && (
              <ButtonModal
                mt={25}
                icon={FlashIcon}
                text="Auto-fill data"
                data-id={`autofill-${x.name}`}
                secondary
                tag={tag}
                modal={ManualRegistryAutofillModal}
                onSelect={y => {
                  setTags(prev => ({
                    ...prev,
                    [x.reportIdentifierDefinitionId]: y,
                  }))
                  setFieldValue(
                    x.reportIdentifierDefinitionId,
                    y.applicableRegistryValue,
                  )
                }}
              />
            )}
          </Box>
        </InputWithButtonWrap>
      )
    })}
  </>
)

export default DoReportFields

import React from "react"
import { IconWrap } from "styledComponents/Icons"
import useOpen from "hooks/useOpen"

const IconModal = ({ modal: Modal, icon: Icon, disabled, ...rest }) => {
  const { isOpen, open, close } = useOpen()

  return (
    <>
      <IconWrap onClick={open} disabled={disabled}>
        <Icon />
      </IconWrap>
      {isOpen && <Modal {...rest} close={close} />}
    </>
  )
}

export default IconModal

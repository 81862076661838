import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 32 32">
    <path
      d="M27.3136,4.6858 C33.5616,10.9338 33.5616,21.0658 27.3136,27.3138 C24.1896,30.4378 20.0956,31.9998 15.9996,31.9998 C11.9056,31.9998 7.8096,30.4378 4.6856,27.3138 C-1.5624,21.0658 -1.5624,10.9338 4.6856,4.6858 C7.8096,1.5618 11.9056,-0.0002 15.9996,-0.0002 C20.0956,-0.0002 24.1896,1.5618 27.3136,4.6858 Z M25.8996,25.8998 C31.3576,20.4418 31.3576,11.5598 25.8996,6.0998 C23.2556,3.4558 19.7396,1.9998 15.9996,1.9998 C12.2596,1.9998 8.7436,3.4558 6.0996,6.0998 C0.6416,11.5598 0.6416,20.4418 6.0996,25.8998 C8.7436,28.5438 12.2596,29.9998 15.9996,29.9998 C19.7396,29.9998 23.2556,28.5438 25.8996,25.8998 Z M17.0002,7.0002 L17.0002,15.0002 L25.0002,15.0002 L25.0002,17.0002 L17.0002,17.0002 L17.0002,25.0002 L15.0002,25.0002 L15.0002,17.0002 L7.0002,17.0002 L7.0002,15.0002 L15.0002,15.0002 L15.0002,7.0002 L17.0002,7.0002 Z"
      fillRule="evenodd"
    />
  </svg>
)

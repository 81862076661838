import styled, { css } from "styled-components/macro"
import theme from "theme"

export const AvatarWrap = styled.div`
  ${p =>
    p.mr &&
    css`
      margin-right: 10px;
    `};
`

export const AvatarCircle = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  width: 32px;
  height: 32px;
  background: ${p => (p.empty ? "none" : theme.color.red)};
  border-radius: 100%;

  svg {
    fill: #fff;
    width: 16px;
    height: 16px;
  }
`

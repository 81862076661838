import React from "react"
import Link from "components/Link"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import { FooterWrap } from "./styled"
import DisclaimerModal from "./DisclaimerModal"
import ImprintModal from "./ImprintModal"
import TermsModal from "./TermsModal"
import CreditsModal from "./CreditsModal"
import PrivacyModal from "./PrivacyModal"

const getLinks = () => [
  {
    label: localize("disclaimer"),
    modal: DisclaimerModal,
  },
  {
    label: localize("imprint"),
    modal: ImprintModal,
  },
  {
    label: localize("termsOfUse"),
    modal: TermsModal,
  },
  {
    label: "Credits",
    modal: CreditsModal,
  },
  {
    label: "Privacy Statement",
    modal: PrivacyModal,
  },
]

const Footer = () => {
  const [open, setOpen] = React.useState(null)
  const links = getLinks()
  const Modal = open !== null && links[open].modal

  return (
    <FooterWrap>
      {links.map((link, i) => (
        <Link onClick={() => setOpen(i)} key={link.label}>
          <P white inline fs={13} mr={20}>
            {link.label}
          </P>
        </Link>
      ))}
      <P mt={20} white fs={13}>
        © 2019-{new Date().getFullYear()} PricewaterhouseCoopers GmbH
        Wirtschaftsprüfungsgesellschaft. All rights reserved. PwC refers to the
        PwC network and/or one or more of its member firms, each of which is a
        separate legal entity. Please see{" "}
        <Link white ul to="https://www.pwc.com/structure">
          https://www.pwc.com/structure
        </Link>{" "}
        for further details.
      </P>
      {Modal && <Modal close={() => setOpen(null)} />}
    </FooterWrap>
  )
}

export default Footer

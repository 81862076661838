import React from "react"
import Warning from "components/Warning"
import { conclusionRequest, questionRequest } from "data/templates/api"
import { getQuestions } from "data/templates/transforms"
import { parallel } from "common/rxjs"
import withData from "hoc/withData"
import { localize } from "locale"
import * as R from "ramda"
import { getMessages } from "./helpers"
import QuestionValidationMessages from "components/QuestionValidationMessages"
import withProps from "hoc/withProps"

const ValidationErrors = ({
  questionData,
  conclusionData,
  questionMessages,
  conclusionDefinitionMessages,
  conclusionReasoningMessages,
  messages,
  count,
  isValid,
  showCompact,
}) => {
  if (isValid) return null
  const questions = !showCompact && getQuestions(questionData)

  return showCompact ? (
    <Warning
      error
      text={localize("thereAreXErrorsInQuestionsAndConclusions")(count)}
    />
  ) : (
    <>
      <QuestionValidationMessages
        questions={questions}
        questionMessages={questionMessages}
      />
      {getMessages(
        conclusionDefinitionMessages,
        conclusionReasoningMessages,
        messages,
        conclusionData,
      ).map((x, xi) => (
        <Warning error key={xi} text={x} />
      ))}
    </>
  )
}

export default R.compose(
  withProps(p => ({
    count:
      p.messages.length +
      R.keys(p.questionMessages).length +
      R.keys(p.conclusionDefinitionMessages).length +
      R.keys(p.conclusionReasoningMessages).length,
  })),
  withProps(p => ({
    showCompact: p.compact && p.count > 1,
  })),
  withData(
    p =>
      parallel(
        questionRequest.list(p.templateId),
        conclusionRequest.list(p.templateId),
      ),
    {
      skip: p => p.showCompact,
      dataKeys: ["questionData", "conclusionData"],
    },
  ),
)(ValidationErrors)

import React from "react"
import Panel from "components/Panel"
import useOpen from "hooks/useOpen"
import CaretIcon from "icons/Caret"
import { IconWrap } from "styledComponents/Icons"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import TagForm from ".."
import * as R from "ramda"
import { extractIds } from "./helpers"

const Subquestions = ({
  question,
  catalogs,
  value,
  onChange,
  templateId,
  template,
  indices,
  refetchTemplate,
  groupNumber,
  parentGroupNumber,
  canEdit,
}) => {
  const { isOpen, toggle } = useOpen()

  const allCatalogs = R.values(catalogs).flatMap(R.identity)

  return (
    <>
      <Box flex onClick={toggle}>
        <P red link hovernolink mt={5}>
          Subquestions
        </P>
        <IconWrap rotate={+isOpen}>
          <CaretIcon />
        </IconWrap>
      </Box>
      {isOpen &&
        question.subQuestions.map((x, xi, arr) => {
          const siblingIds = (arr || [])
            .map(R.prop("id"))
            .filter(y => y !== x.id)

          const siblingIsMultiple = value
            .filter(y => siblingIds.includes(y.templateQuestionId))
            .find(
              y =>
                allCatalogs.find(z => z.id === y.registryCatalogId)
                  ?.allowsMultiple,
            )

          const siblingAndTheirChildrenIds = extractIds(arr || []).filter(
            y => y !== x.id,
          )

          return (
            <Panel mt={20} nobg>
              <TagForm
                allCatalogs={allCatalogs}
                siblingAndTheirChildrenIds={siblingAndTheirChildrenIds}
                thisLevelSubquestions={arr}
                question={x}
                catalogs={catalogs}
                value={value}
                onChange={onChange}
                templateId={templateId}
                template={template}
                indices={indices.concat([xi + 1])}
                refetchTemplate={refetchTemplate}
                parentGroupNumber={groupNumber}
                grandParentGroupNumber={parentGroupNumber}
                canEdit={canEdit && !siblingIsMultiple}
              />
            </Panel>
          )
        })}
    </>
  )
}

export default Subquestions

import React from "react"
import { localize } from "locale"
import * as R from "ramda"
import Checkbox from "inputs/Checkbox"
import TableRow from "./TableRow"

export const config = () => ({
  rowComponent: TableRow,
  columns: [
    { label: "" },
    {
      label: "Case name",
      sortKey: "Name",
    },
    {
      label: "Case status",
      sortKey: "Status",
    },
    {
      label: "Included objects",
    },
    {
      label: "Last edited",
      sortKey: "EditedAtUtc",
    },
    {
      label: "Aggregated conclusion type",
    },
    {
      label: "",
    },
  ],
  filters: [
    {
      name: "favorite",
      render: (value, onChange) => (
        <Checkbox
          value={value}
          onChange={onChange}
          text={localize("showFavorites")}
        />
      ),
      filter: R.always(true),
    },
  ],
  search: R.always(true),
})

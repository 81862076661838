import React from "react"
import { templateTagRequest } from "data/registry/api"
import * as R from "ramda"
import ModalButtons from "components/ModalButtons"
import Button from "components/Button"
import { getCanBeTagged } from "data/templates/answers"
import TagForm from "./TagForm"
import { getInitValue, validateTags } from "./helpers"
import { Box } from "styledComponents/Containers"
import {
  flattenQuestion,
  normalizeNodeIntoQuestion,
} from "data/autofill/helpers"
import { scrollToEl } from "common/scroll"
import ClearAllTagging from "./ClearAllTagging"
import SkipConfirm from "components/SkipConfirm"

const Question = ({
  node,
  save,
  catalogs,
  canEdit,
  templateId,
  template,
  refetchTemplate,
  editProps,
  tagData,
  refetch,
  mt,
}) => {
  const [v, setV] = React.useState(getInitValue({ tagData, catalogs, node }))
  const resetValue = () => setV(getInitValue({ tagData, catalogs, node }))

  return (
    <Box mt={mt}>
      <SkipConfirm
        editProps={editProps}
        noSaveButton={true}
        id={node.referencedId}
        v={v}
        onChange={setV}
        resetValue={resetValue}
        disabled={!getCanBeTagged(node.data)}
        onSubmit={v =>
          templateTagRequest.tag(
            templateId,
            node.referencedId,
            // filter out tags  for type name that dont have tag, but dont filter out custom questions
            v.filter(
              x => !R.isNil(x.autoFillGroupNr) || !R.isNil(x.registryCatalogId),
            ),
          )
        }
        onSuccess={() => {
          refetch()
          scrollToEl(
            document.querySelector(`.editable-${node.referencedId}`),
            150,
          )
        }}
        modalText="Are you sure you want to leave? Unsaved progress will be lost"
        discardOnSubmit
      >
        {({ v, onChange, editingThis, save }) => {
          const questionData = normalizeNodeIntoQuestion(node)
          const editable = canEdit && editingThis

          return (
            <>
              <TagForm
                question={questionData}
                value={v}
                indices={[node.idx]}
                onChange={onChange}
                readOnly={!editingThis}
                catalogs={catalogs}
                templateId={templateId}
                template={template}
                refetchTemplate={refetchTemplate}
                canEdit={editable}
              />
              {editingThis && (
                <ModalButtons
                  nomargin
                  noBg
                  buttonText={
                    <ClearAllTagging
                      question={questionData}
                      node={node}
                      value={v}
                      onChange={onChange}
                    />
                  }
                >
                  <Button
                    onClick={() => {
                      editProps.reset()
                      resetValue()
                    }}
                    secondary
                    text="Cancel"
                  />
                  <Button
                    disabled={!validateTags(v, flattenQuestion(node), catalogs)}
                    onClick={() => save(v)}
                    text="Save"
                  />
                </ModalButtons>
              )}
            </>
          )
        }}
      </SkipConfirm>
    </Box>
  )
}

export default Question

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const LogicNodeWrap = styled.div`
  ${p =>
    p.depth > 0 &&
    css`
      border-left: 2px solid ${theme.color.red};
      border-right: 2px solid ${theme.color.red};
      padding: 10px 15px;
      position: relative;

      &::before,
      &::after {
        content: "";
        position: absolute;
        top: 0;
        bottom: 0;
        width: 10px;
        border-top: 2px solid ${theme.color.red};
        border-bottom: 2px solid ${theme.color.red};
      }

      &::before {
        left: 0;
      }
      &::after {
        right: 0;
      }
    `}
`

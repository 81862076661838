import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M7.4507,13.3809 L18.8087,2.0219 L1.4647,2.0219 C0.6557,2.0219 -0.0003,2.6779 -0.0003,3.4869 L-0.0003,23.9999 L20.5127,23.9999 C21.3217,23.9999 21.9777,23.3439 21.9777,22.5349 L21.9777,5.1919 L10.6207,16.5489 L7.4507,13.3809 Z M5.5257,18.5149 L5.5257,15.5869 L8.4537,18.5149 L5.5257,18.5149 Z M20.8305,-0.0002 L18.8475,1.9828 L18.8085,2.0218 L18.8875,2.0218 L21.9775,5.1128 L21.9775,5.1918 L22.0175,5.1528 L24.0005,3.1698 L20.8305,-0.0002 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const TextareaWrap = styled.div`
  color: #333;

  ${p =>
    !p.borderless &&
    css`
      border: 1px solid #d8d8d8;
    `};

  & > div {
    ${p =>
      !p.edge2edge &&
      css`
        padding: 11px;
      `};
    sup,
    sub {
      position: relative;
    }
    sup {
      font-size: 20px;
      top: 5px;
      vertical-align: super;
    }
    sub {
      font-size: 10px;
      bottom: 5px;
      vertical-align: sub;
    }

    ul {
      padding: 0 0 0 20px;
      margin: 10px 0;
      list-style: circle;
    }

    a {
      color: ${theme.color.red};
      cursor: pointer;
    }
  }
`

export const TextareaControls = styled.div`
  display: flex;
  padding: 5px 10px;
  border-bottom: 1px solid #d8d8d8;
`

import React from "react"
import { AvatarWrap, AvatarCircle } from "./styled"

const Avatar = ({ text, mr, icon: Icon, onClick }) => (
  <AvatarWrap mr={mr}>
    <AvatarCircle onClick={onClick} empty={!text && !Icon}>
      {text}
      {Icon && <Icon />}
    </AvatarCircle>
  </AvatarWrap>
)

export default Avatar

import React from "react"
import withFormField from "hoc/withFormField"
import { InputWrap } from "./styled"
import QuestionModal from "./QuestionModal"
import { localize } from "locale"
import CrossIcon from "icons/Cross"
import { IconWrap } from "styledComponents/Icons"
import { Box } from "styledComponents/Containers"
import * as R from "ramda"
import useOpen from "hooks/useOpen"

const getText = value => {
  if (!value || !value.text)
    return localize("selectTemplateQuestionSubquestionAnswer")

  return value.text
}

const InputQuestion = ({
  questions,
  tree,
  value,
  onChange,
  readOnly,
  elementId,
  elementOptionId,
  lastSelectedValue,
}) => {
  const { isOpen, open, close } = useOpen()

  const hasValue = value && value.text

  return (
    <>
      <InputWrap onClick={readOnly ? undefined : open} empty={!hasValue}>
        <Box grow>{getText(value)}</Box>
        {hasValue && !readOnly && (
          <IconWrap
            onClick={e => {
              e.stopPropagation()
              e.preventDefault()
              onChange(
                R.omit(["questionId", "questionOptionId", "text"], value),
              )
            }}
          >
            <CrossIcon />
          </IconWrap>
        )}
      </InputWrap>
      {isOpen && (
        <QuestionModal
          close={close}
          questions={questions}
          tree={tree}
          onChange={v => {
            onChange(v)
            close()
          }}
          value={value}
          elementId={elementId}
          elementOptionId={elementOptionId}
          lastSelectedValue={lastSelectedValue}
        />
      )}
    </>
  )
}

export default withFormField(InputQuestion)

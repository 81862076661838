import styled, { css } from "styled-components/macro"
import boxModel from "styledComponents/bases/boxModel"
import theme from "theme"

export const PanelWrap = styled.div`
  padding: ${p => p.padding || 20}px;
  border: 1px solid ${theme.color.border};
  border-radius: 2px;
  box-shadow: 0 1px 3px 0 ${theme.color.shadow};
  max-width: 100%;

  ${boxModel};
  ${p =>
    p.bg &&
    css`
      background: ${p.bg};
    `};
  ${p =>
    !p.nobg &&
    !p.bg &&
    css`
      background-color: #fff;
    `};
  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
  ${p =>
    p.relative &&
    css`
      position: relative;
    `};
  ${p =>
    p.grow &&
    css`
      flex-grow: 1;
    `};
  ${p =>
    p.center &&
    css`
      display: flex;
      justify-content: center;
      flex-direction: column;
    `};
  ${p =>
    p.noPanel &&
    css`
      padding: 0;
      border: 0;
      box-shadow: none;
    `};
  ${p =>
    p.noPadding &&
    css`
      padding: 0;
    `};
`

export const TitleWrap = styled.div`
  border-bottom: 1px solid ${theme.color.border};
  margin-bottom: 15px;
`

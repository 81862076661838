import React from "react"
import Calendar from "components/Calendar"
import ContextMenu from "components/ContextMenu"
import OverlayWrapper from "components/OverlayWrapper"
import { formatDate } from "data/units/date/formatters"
import withFormField from "hoc/withFormField"
import Textfield from "inputs/Textfield"
import useOpen from "hooks/useOpen"

const Datepicker = ({
  value,
  placeholder,
  onChange,
  borderless,
  readOnly,
  noPast,
  transparent,
  gray,
}) => {
  const { isOpen, close, toggle } = useOpen()

  return (
    <OverlayWrapper isOpen={isOpen} close={close}>
      <Textfield
        placeholder={placeholder}
        value={formatDate(value)}
        onClick={readOnly ? undefined : toggle}
        borderless={borderless}
        clearable={!readOnly}
        onChange={onChange}
        transparent={transparent}
        noDirectChange
        gray={gray}
      />
      <ContextMenu isOpen={isOpen}>
        <Calendar
          value={value}
          onChange={v => {
            onChange(v)
            close()
          }}
          noPast={noPast}
        />
      </ContextMenu>
    </OverlayWrapper>
  )
}

export default withFormField(Datepicker)

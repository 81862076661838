import React from "react"
import Status from "components/Status"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { PageTitleWrap, PageTitleContent } from "./styled"
import theme from "theme"

const PageTitle = ({ title, secondaryStatus, children, status, mb = 10 }) => (
  <PageTitleWrap>
    <Box mb={mb}>
      <P fs={30} mb={10}>
        {title}
      </P>
      {status && (
        <P>
          <Status {...status} />{" "}
          {secondaryStatus && (
            <Status text={secondaryStatus} color={theme.color.orange} />
          )}
        </P>
      )}
    </Box>
    <PageTitleContent>{children}</PageTitleContent>
  </PageTitleWrap>
)

export default PageTitle

import React from "react"
import PageTitle from "components/PageTitle"
import { assessmentRequest } from "data/assessments/api"
import withData from "hoc/withData"
import withPage from "hoc/withPage"
import { localize } from "locale"
import * as R from "ramda"
import { Container, Box } from "styledComponents/Containers"
import CountryFilter from "components/CountryFilter"
import Template from "./Template"
import { parseSearch } from "common/router"
import { parallel } from "common/rxjs"
import { countryListRequest } from "data/countries/api"

const AssessmentsTemplatePage = ({
  templates,
  location,
  countries,
  case_,
  caseUrls,
}) => {
  const [country, setCountry] = React.useState()
  const parsed = parseSearch(location.search)

  const filtered = country
    ? templates.filter(x => country === x.countryCode)
    : []

  const validCountryCodes = R.pipe(
    R.map(R.prop("countryCode")),
    R.uniq,
  )(templates)

  return (
    <Container>
      <PageTitle title={localize("newAssessment")} />
      <Box flex>
        <Box width={300} mr={20}>
          <CountryFilter
            value={country}
            onChange={setCountry}
            countries={countries}
            validCountryCodes={validCountryCodes}
          />
        </Box>
        <Box grow>
          {filtered.map(x => (
            <Template
              key={x.id}
              template={x}
              involvedPartyId={R.path(["involvedPartyId"], parsed)}
              case_={case_}
              caseUrls={caseUrls}
            />
          ))}
        </Box>
      </Box>
    </Container>
  )
}

export const EnhancedAssessmentsTemplatePage = R.compose(
  withData(
    () =>
      parallel(
        assessmentRequest.listTemplates(),
        countryListRequest(false, true),
      ),
    {
      dataKeys: ["templates", "countries"],
    },
  ),
)(AssessmentsTemplatePage)

export default withPage()(EnhancedAssessmentsTemplatePage)

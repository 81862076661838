import * as R from "ramda"
import { localize } from "locale"

const defaultMessages = {
  403: "You do not have permission. Try refreshing the page.",
  409: "You do not have permission. Try refreshing the page.",
  404: "Not found. Try refreshing the page.",
  503: "Resource is still being processed, please try in a few moments.",
}

const getMessage = (msg, status) => {
  if (R.type(msg) !== "String" || R.isEmpty(msg)) {
    return defaultMessages[status] || "Invalid input. Try it again."
  }

  return msg
}

export const getError = error => {
  const res = error.response
  if (!res) return { text: "Network error, check your connection.", error }

  const resData = res.data
  const localizedResponse = localize(resData)

  if (R.type(localizedResponse) === "String") {
    return { text: localizedResponse, error }
  }

  const status = error.status

  return { text: getMessage(resData, status), error }
}

import React from "react"
import Button from "components/Button"
import Columns from "components/Columns"
import ModalButtons from "components/ModalButtons"
import withForm from "hoc/withForm"
import Textarea from "inputs/Textarea"
import Filepicker from "inputs/Filepicker"
import { localize } from "locale"
import { Spread, Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { stringRequired, longStringRequired } from "validators/string"
import { arrayOptional } from "validators/array"

const AddCommentForm = ({
  open,
  isOpen,
  isSomeOpen,
  close,
  requests,
  noAttachments,
  readOnly,
  sublabel,
  noAdd,
}) => (
  <>
    <Spread mb={10}>
      <P>
        <P inline bold gray>
          {localize("comments")}
        </P>
        {sublabel && (
          <P ml={4} inline gray>
            ({sublabel})
          </P>
        )}
      </P>
      {!noAdd && (
        <Button
          onClick={open}
          disabled={isSomeOpen || readOnly}
          text={localize("addNewComment")}
        />
      )}
    </Spread>
    {isOpen && (
      <>
        <Columns widths={["auto", 1]} gap={5}>
          <Textarea
            required
            name="text"
            placeholder={localize("addNewComment")}
            noEmptyError
          />
          <P required mt={15} />
        </Columns>
        <Box flex align="flex-end" mb={20}>
          {!noAttachments && (
            <Filepicker
              name="attachments"
              upload={requests.uploadFile}
              download={requests.downloadFile}
              mb={0}
              noEmptyError
            />
          )}
          <Box ml="auto">
            <ModalButtons nomargin>
              <Button secondary onClick={close} text="Cancel" />
              <Button type="submit" text="Save" />
            </ModalButtons>
          </Box>
        </Box>
      </>
    )}
  </>
)

export default withForm({
  schema: p => ({
    text: p.long ? longStringRequired : stringRequired,
    ...(p.noAttachments ? {} : { attachments: arrayOptional }),
  }),
  onSubmit: p => p.requests.addComment,
  onSuccess: p => () => {
    p.onSuccess()
    p.close()
  },
})(AddCommentForm)

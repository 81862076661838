import React from "react"
import { Overlay, OverlayWrapperContent, OverlayWrapperWrap } from "./styled"
import { useScrollLock } from "hooks/useScrollLock"

const OverlayWrapper = ({ isOpen, close, children, transparent }) => {
  useScrollLock(isOpen)

  return (
    <OverlayWrapperWrap>
      {isOpen && <Overlay onClick={close} transparent={transparent} />}
      <OverlayWrapperContent
        isOpen={isOpen}
        className="overlay-wrapper-content"
      >
        {children}
      </OverlayWrapperContent>
    </OverlayWrapperWrap>
  )
}

export default OverlayWrapper

import React from "react"
import { getDateRange } from "common/date"
import { P } from "styledComponents/Typography"
import { MonthWrap, Week } from "./styled"
import * as R from "ramda"
import Day from "./Day"
import { localize } from "locale"

const dow = localize("daysOfWeekArray")

const handleOverflow = num => (num < 0 ? 6 : num)

const Month = ({
  month,
  onChange,
  value,
  minDate,
  maxDate,
  noFuture,
  noPast,
  noToday,
}) => {
  const startOfThisMonth = month.startOf("month")
  const daysInLastMonth = handleOverflow(startOfThisMonth.day() - 1)
  const daysInNextMonth = 42 - daysInLastMonth - startOfThisMonth.daysInMonth()

  const start = startOfThisMonth.subtract(daysInLastMonth, "days")
  const end = month.endOf("month").add(daysInNextMonth, "days")
  const days = getDateRange(start, end)

  return (
    <MonthWrap>
      <Week>
        {dow.map(x => (
          <P fs={12} key={x} nobreak>
            {x}
          </P>
        ))}
      </Week>
      {R.splitEvery(7, days).map((g, gi) => (
        <Week key={gi}>
          {g.map(d => (
            <Day
              key={d}
              day={d}
              value={value}
              onChange={onChange}
              month={month}
              minDate={minDate}
              maxDate={maxDate}
              noFuture={noFuture}
              noPast={noPast}
              noToday={noToday}
            />
          ))}
        </Week>
      ))}
    </MonthWrap>
  )
}

export default Month

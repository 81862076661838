import styled, { css } from "styled-components/macro"
import theme from "theme"

export const CheckboxWrap = styled.div`
  display: flex;
  align-items: center;

  ${p =>
    !p.nopadding &&
    css`
      padding: 10px 0;
    `}

  ${p =>
    p.readOnly &&
    css`
      pointer-events: none;
    `}
  
  ${p =>
    p.gray &&
    css`
      backgroud-color: gray;
    `}
`

export const StyledCheckbox = styled.div`
  height: 20px;
  width: 40px;
  background-color: #f2f2f2;
  border-radius: 20px;
  position: relative;
  cursor: pointer;
  transition: background 0.25s ease;
  flex-shrink: 0;

  &::after {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    width: 20px;
    height: 20px;
    background: #fff;
    border-radius: 100%;
    box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.5);
    transition: transform 0.25s ease;
  }

  ${p =>
    p.active &&
    css`
      background: ${theme.color.red};

      &::after {
        transform: translateX(100%);
      }
    `};
`

export const StyledCheckboxSquared = styled.div`
  position: relative;
  height: 20px;
  width: 20px;
  flex-shrink: 0;
  background: white;
  border: 1px solid ${theme.color.border};
  border-radius: 2px;
  cursor: pointer;
  transition: background 0.25s ease, border 0.25s ease;

  &::after {
    content: "";
    position: absolute;
    left: 6px;
    top: 2px;
    width: 6px;
    height: 11px;
    border: solid white;
    border-width: 0 2px 2px 0;
    transform: rotate(45deg);

    ${p =>
      p.gray &&
      css`
        border: solid #eee;
      `};
  }

  ${p =>
    p.active &&
    css`
      background: ${theme.color.red};
      border-color: ${theme.color.red};
    `};
  ${p =>
    p.gray &&
    css`
      background-color: #eee;
    `};
`

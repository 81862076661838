import styled, { css } from "styled-components/macro"
import theme from "theme"
import boxModel from "../bases/boxModel"

// restricts just width
export const WidthContainer = styled.div`
  max-width: 1800px;
  margin: 0 auto;
  padding-left: 20px;
  padding-right: 20px;
`

// WidthContainer + adds padding top and bottom
export const Container = styled(WidthContainer)`
  padding-top: 20px;
  padding-bottom: 20px;
`

export const Spread = styled.div`
  display: block;
  ${boxModel};

  ${theme.mq.tablet} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  > * {
    margin-bottom: 10px;

    &:last-child {
      margin-bottom: 0;
    }

    ${theme.mq.tablet} {
      margin-bottom: 0;
    }
  }

  > button {
    margin-right: 10px;

    ${theme.mq.tablet} {
      margin-right: 0;
    }
  }
`

export const Box = styled.div`
  ${boxModel};

  ${p =>
    p.opa &&
    css`
      opacity: ${p.opa};
    `};
  ${p =>
    p.relative &&
    css`
      position: relative;
    `};
  ${p =>
    p.center &&
    css`
      text-align: center;
    `};

  ${p =>
    p.pointer &&
    css`
      cursor: pointer;
    `};

  ${p =>
    p.bg &&
    css`
      background: ${p.bg};
    `};

  ${p =>
    p.highlight &&
    css`
      border: 1px solid purple;
      padding: 20px;
    `};
`

export const InputWithButtonWrap = styled.div`
  ${boxModel};
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  align-items: flex-start;

  ${theme.mq.tablet} {
    flex-direction: row;
  }

  > *:first-child {
    flex-grow: 1;
    margin-left: 0;
    width: 100%;

    ${theme.mq.tablet} {
      width: 0;
    }
  }

  > *:last-child {
    flex-grow: 1;
    margin-left: 0;
    width: 100%;

    ${theme.mq.tablet} {
      flex-grow: 0;
      width: auto;
      margin-left: 10px;
    }
  }

  > * {
    flex-shrink: 0;
    margin-left: 0;
    margin-bottom: 10px;

    ${theme.mq.tablet} {
      margin-left: 10px;
      margin-bottom: 0px;
    }
  }

  ${p =>
    p.alignBottom &&
    css`
      align-items: flex-end;
    `};
`

import React from "react"
import ContextMenu from "components/ContextMenu"
import AriaMenu from "components/AriaMenu"
import Textfield from "inputs/Textfield"
import OverlayWrapper from "components/OverlayWrapper"
import SearchIcon from "icons/Search"
import { localize } from "locale"
import * as R from "ramda"
import withOnUpdate from "hoc/withOnUpdate"
import HighlightedText from "components/HighlightedText"
import withState from "hoc/withState"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"
import { isWhitespace } from "common/string"

const Autocomplete = ({
  setFocus,
  options,
  text,
  changeText,
  handleChange,
  loading,
  noResultText,
  readOnly,
  borderless,
  isOpen,
  byValue,
  placeholder = localize("search"),
  small,
  transparent,
  icon,
  round,
  activeBorder,
  doNotOpen,
  gray,
}) => {
  const open = isOpen && !doNotOpen

  return (
    <OverlayWrapper isOpen={open} close={() => setFocus(false)}>
      <Textfield
        value={text}
        placeholder={placeholder}
        icon={!borderless && (icon ?? SearchIcon)}
        onChange={changeText}
        readOnly={readOnly}
        borderless={borderless}
        clearable
        preventMenuKeyEvents
        onFocus={() => setFocus(true)}
        small={small}
        transparent={transparent}
        round={round}
        activeBorder={activeBorder ? isOpen : false}
        gray={gray}
      />
      <ContextMenu isOpen={open}>
        <AriaMenu
          options={options.filter(
            o => !text || o.name.toLowerCase().includes(text.toLowerCase()),
          )}
          onChange={handleChange}
          loading={loading}
          renderText={o => <HighlightedText text={o.name} match={text} />}
          noOptionsText={noResultText}
          byValue={byValue}
        />
      </ContextMenu>
    </OverlayWrapper>
  )
}

export default R.compose(
  withState("focus", "setFocus", false),
  withHandlers({
    handleChange: p => v => {
      p.onChange(v)
      p.setFocus(false)
    },
  }),
  withProps(p => ({
    isOpen:
      p.focus &&
      !R.isNil(p.options) &&
      (p.allowEmpty || (p.text && p.text !== "" && !isWhitespace(p.text))),
  })),
  withOnUpdate((prev, p) => {
    if (prev.isOpen && !p.isOpen && p.onBlur) {
      p.onBlur()
    }
  }),
)(Autocomplete)

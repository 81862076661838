import * as R from "ramda"

export const coworkerOptions = {
  prefill: {
    name: "Prefill case co-workers",
    value: "useCaseCoworkers",
  },
  library: {
    name: "Keep co-workers defined in library item",
    value: "useLibraryItemCoworkers",
  },
  manual: {
    name: "I will define co-workers manually",
    value: "none",
  },
}

export const getCoworkerOptions = libraryItem =>
  R.pipe(
    libraryItem ? R.identity : R.omit(["library"]),
    R.values,
  )(coworkerOptions)

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentLibraryRequest } from "data/assessments/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import { strRequired } from "validators/string"
import Radio from "inputs/Radio"

const CreateAssessmentFromLibraryModal = ({ close }) => (
  <>
    <P mb={20} bold>
      {localize("createAssessmentKeepCoworkersLabel")}
    </P>

    <Radio
      options={[
        {
          name: localize("createAssessmentRemoveCoworkers"),
          value: "N",
        },
        {
          name: localize("createAssessmentKeepCoworkers"),
          value: "Y",
        },
      ]}
      name="copyCoworkers"
      byValue
    />

    <ModalButtons>
      <Button secondary type="button" onClick={close} text="Cancel" />
      <Button primary type="submit" text={localize("continue")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(R.always(`${localize("createAssessmentFromLibrary")}`)),
  withUrls,
  withForm({
    schema: R.always({
      copyCoworkers: strRequired(1),
    }),
    onSubmit: p => v =>
      assessmentLibraryRequest.create(p.assessment.id, {
        ...v,
        copyCoworkers: v.copyCoworkers === "Y",
      }),
    onSuccess: p => () => {
      p.refetchCase?.()
      p.close()
    },
    redirect: (res, p) => p.redirect && p.redirect(res, p),
  }),
)(CreateAssessmentFromLibraryModal)

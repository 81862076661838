import React from "react"
import { downloadFile } from "common/files"
import Link from "components/Link"
import { assessmentQuestionRequest } from "data/assessments/api"
import withData from "hoc/withData"
import withUrls from "hoc/withUrls"
import withModal from "hoc/withModal"
import { localize } from "locale"
import Textarea from "inputs/Textarea"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import * as R from "ramda"

const InfoModal = ({ data, templateId, urls }) => {
  const isEmpty =
    !data.supportiveInfo &&
    data.links.length === 0 &&
    data.attachments.length === 0

  return (
    <>
      {isEmpty && <P>{localize("noInfoAvailable")}</P>}
      {data.supportiveInfo && (
        <>
          <P bold mb={10}>
            {localize("supportiveInformation")}
          </P>
          <Box mb={10}>
            <Textarea
              readOnly
              borderless
              edge2edge
              value={data.supportiveInfo}
            />
          </Box>
        </>
      )}
      {data.links.length > 0 && (
        <>
          <P bold mb={10} gray>
            {localize("links")}
          </P>
          {data.links.map(x => (
            <P mb={10} key={x.id}>
              <P bold fullinline>
                {x.name}:
              </P>
              <P fullinline ml={5}>
                <Link
                  fullinline
                  to={
                    x.type === "Url"
                      ? x.linkUrl
                      : window.location.origin + urls.compareTool(x.id)
                  }
                >
                  {x.type === "Url" ? x.linkUrl : x.headerName}
                </Link>
              </P>
            </P>
          ))}
        </>
      )}
      {data.attachments.length > 0 && (
        <>
          <P bold mb={10}>
            {localize("attachedFiles")}
          </P>
          {data.attachments.map(x => (
            <P mb={10} key={x.id}>
              <P mr={10} inline>
                {x.fileName}
              </P>
              <Link
                ul
                onClick={() =>
                  downloadFile(
                    assessmentQuestionRequest.downloadFile(templateId, x.id),
                    x.fileName,
                  )
                }
              >
                {localize("download")}
              </Link>
            </P>
          ))}
        </>
      )}
    </>
  )
}
export default R.compose(
  withModal(p => `${localize("question")} ${p.index}: ${localize("help")}`),
  withUrls,
  withData(p => assessmentQuestionRequest.info(p.templateId, p.questionId)),
)(InfoModal)

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const QuestionFooterWrap = styled.div`
  display: flex;
  padding: 10px 20px;
  background: #eee;

  > * {
    margin-right: 10px;
  }

  ${theme.mq.tablet} {
    display: block;
    width: 50px;
    flex-shrink: 0;
    padding: 20px 10px;

    > * {
      margin-right: 0;
    }
  }
`

const active = css`
  background: ${theme.color.red};
  border-color: ${theme.color.red};
  svg {
    fill: white;
  }
`

export const IconWrap = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 2px;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid;
  border-color: #999;
  cursor: pointer;
  background: none;

  &:last-child {
    margin-bottom: 0;
  }

  svg {
    width: 16px;
    height: 16px;
    fill: #666;
  }

  ${p =>
    p.rotate &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};

  &:hover {
    ${active};
  }
  ${p =>
    p.active &&
    css`
      ${active};
    `};
`

import React from "react"
import { localize } from "locale"
import Checkbox from "inputs/Checkbox"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import BookmarkIcon from "icons/appkit/outline-mark"
import useMedia from "hooks/useMedia"

const BookmarkedOnly = ({ value, onChange }) => {
  const { isMobile } = useMedia()

  return (
    <Checkbox
      value={value}
      onChange={onChange}
      text={
        <Box flex>
          {localize(isMobile ? "showOnly" : "showOnlyBookmarkedQuestions")}
          <IconWrap static>
            <BookmarkIcon />
          </IconWrap>
        </Box>
      }
      flip
    />
  )
}

export default BookmarkedOnly

import React from "react"
import Textfield from "inputs/Textfield"
import withFormField from "hoc/withFormField"
import Button from "components/Button"
import Link from "components/Link"
import { localize } from "locale"
import { InputContactsWrap } from "./styled"
import UserList from "inputs/UserList"
import * as R from "ramda"
import withState from "hoc/withState"
import { emailWithDomainRequired } from "validators/string"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"
import IconEmail from "icons/appkit/fill-email"
import IconPhone from "icons/Call"
import { IconWrap } from "styledComponents/Icons"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"

// two inputs contact exist because they are too different
const InputContacts = ({
  name,
  setName,
  email,
  setEmail,
  phone,
  setPhone,
  handleAdd,
  handleRemove,
  value,
  readOnly,
  disabled,
  emailValid,
  domains,
}) => {
  const renderItem = x => (
    <Box flex>
      {[
        x.name,
        x.email && (
          <>
            <IconWrap static>
              <IconEmail />
            </IconWrap>
            <Link ul to={`mailto:${x.email}`}>
              {x.email}
            </Link>
          </>
        ),
        x.phone && (
          <>
            <IconWrap static>
              <IconPhone />
            </IconWrap>
            <Link ul to={`tel:${x.phone}`}>
              {x.phone}
            </Link>
          </>
        ),
      ]
        .filter(y => y)
        .map((y, yi) => (
          <Box flex key={yi}>
            {yi !== 0 && ", "}
            {y}
          </Box>
        ))}
    </Box>
  )

  return (
    <>
      {!readOnly && (
        <InputContactsWrap>
          <Textfield
            value={name}
            onChange={setName}
            placeholder={localize("nameDescription")}
          />
          <Box>
            <Textfield
              value={email}
              onChange={setEmail}
              placeholder={localize("email")}
            />
            {email && !emailValid && (
              <P fs={12} error mt={3}>
                {emailWithDomainRequired(domains)(email)}
              </P>
            )}
          </Box>
          <Textfield
            value={phone}
            onChange={setPhone}
            placeholder={localize("telephone")}
          />
          <Button
            text={localize("add")}
            disabled={disabled}
            onClick={handleAdd}
          />
        </InputContactsWrap>
      )}
      {value && value.length > 0 && (
        <UserList
          readOnly={readOnly}
          value={value}
          title={localize("totalContacts")}
          optionToTitle={renderItem}
          removeUser={readOnly ? undefined : handleRemove}
        />
      )}
    </>
  )
}

export default R.compose(
  withFormField,
  withState("name", "setName", ""),
  withState("email", "setEmail", ""),
  withState("phone", "setPhone", ""),
  withProps(p => {
    const nameValid = !!p.name
    const emailValid = !emailWithDomainRequired(p.domains)(p.email)
    const phoneValid = !!p.phone

    return {
      disabled: [nameValid, emailValid, phoneValid].every(x => !x),
      emailValid,
    }
  }),
  withHandlers({
    handleAdd: p => () => {
      const newContact = {
        name: p.name,
        email: p.email,
        phone: p.phone,
      }
      const oldValue = p.value || []
      p.onChange(oldValue.concat(newContact))
      p.setName("")
      p.setEmail("")
      p.setPhone("")
    },
    handleRemove: p => i => {
      p.onChange(R.remove(i, 1, p.value))
    },
  }),
)(InputContacts)

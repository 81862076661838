import React from "react"
import { Prompt } from "react-router-dom"
import { localize } from "locale"

const PreventLoseProgress = ({ children, when }) => (
  <>
    <Prompt when={when} message={localize("areYouSureYouWantToLeave")} />
    {children}
  </>
)

export default PreventLoseProgress

import * as R from "ramda"
import { reportOptions } from "data/reporting/options"

export const getDoReport = reporting => {
  const { doReport } = reporting
  if (R.isNil(doReport)) return null

  return doReport ? reportOptions.report.value : reportOptions.doNotReport.value
}

export const getInitTags = R.pipe(
  R.pathOr([], ["reporting", "reportIdentifiers"]),
  R.indexBy(R.prop("reportIdentifierDefinitionId")),
  R.map(R.prop("reportIdentifierTag")),
)

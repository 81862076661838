import styled from "styled-components/macro"

export const CountTitleWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 30px;
  padding-top: 10px;
  border-bottom: 1px solid #d8d8d8;
`
export const CountTitleLeft = styled.div`
  display: flex;
  align-items: center;
`

export const Count = styled.div`
  background: #999;
  color: #fff;
  border-radius: 4px;
  height: 20px;
  font-size: 12px;
  line-height: 20px;
  padding: 0 4px;
  margin-left: 10px;
`

export const Clear = styled.div`
  color: red;
  font-size: 12px;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

import React from "react"
import withFormField from "hoc/withFormField"
import withOnUpdate from "hoc/withOnUpdate"
import Autocomplete from "components/Autocomplete"
import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"
import withData from "hoc/withData"
import * as R from "ramda"
import { assessmentTagRequest } from "data/registry/api"
import FlashIcon from "icons/Flash"
import FlashActiveIcon from "icons/FlashActive"

const getNewText = (value, byValue, options) => {
  if (!value) return ""

  if (byValue) {
    const option = options && options.find(o => o.value === value)

    return option ? option.name : ""
  }

  return value.name
}

const AutocompleteSelectRegistry = ({
  text,
  handleChangeText,
  onChange,
  handleBlur,
  renderContent,
  readOnly,
  borderless,
  byValue,
  placeholder,
  small,
  transparent,
  data,
  loading,
  value,
}) => {
  const options = (data || []).map(x => ({
    value: x.keyId,
    name: x.searchFields.map(R.prop("value")).join("; "),
    searchFields: x.searchFields,
  }))

  return (
    <Autocomplete
      loading={loading}
      options={options}
      text={text}
      placeholder={placeholder}
      changeText={handleChangeText}
      onChange={onChange}
      renderContent={renderContent}
      readOnly={readOnly}
      borderless={borderless}
      onBlur={handleBlur}
      byValue={byValue}
      small={small}
      transparent={transparent}
      allowEmpty
      round
      activeBorder
      icon={value ? FlashActiveIcon : FlashIcon}
      doNotOpen={(value && value.name === text) || (text ?? "").length < 2}
    />
  )
}

export default R.compose(
  withFormField,
  withState("text", "setText", p => getNewText(p.value, p.byValue, p.options)),
  withHandlers({
    handleChangeText: p => v => {
      p.onChange(undefined)
      p.setText(v)
    },
    handleBlur: p => () => {
      if (!p.value) {
        p.onChange(undefined)
        p.setText("")
      }
    },
  }),
  withOnUpdate((prev, props) => {
    if (prev.value !== props.value) {
      props.setText(getNewText(props.value, props.byValue, props.options))
    }
  }),
  withData(p => assessmentTagRequest.search(p.registryType)(p.text), {
    refetchParams: p => [p.text, p.registryType],
    debounce: 300,
    skip: p => !p.registryType || (p.text ?? "").length < 2,
    noLoader: true,
  }),
)(AutocompleteSelectRegistry)

import React from "react"
import { conclusionRequest, questionRequest } from "data/templates/api"
import { getQuestions } from "data/templates/transforms"
import { parallel } from "common/rxjs"
import withData from "hoc/withData"
import Conclusions from "./Conclusions"
import Reasonings from "./Reasonings"
import Static from "./Static"
import { canEditAfterFinished } from "data/templates/statuses"
import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"
import * as R from "ramda"

const Conclusion = ({
  canEdit,
  templateId,
  conclusions,
  questions,
  editIndex,
  setEditIndex,
  onSuccess,
  moveIndex,
  setMoveIndex,
  template,
}) => {
  const commonProps = {
    editIndex,
    onSuccess,
    onEdit: setEditIndex,
    onCancelEdit: () => setEditIndex(undefined),
    moveIndex,
    onMove: setMoveIndex,
  }
  const qs = getQuestions(questions)

  // can also be edited by approvers when approved/published
  const canEditAfterDone = canEditAfterFinished(template)

  return (
    <>
      <Static
        {...commonProps}
        type="header"
        text={conclusions.headerText}
        request={conclusionRequest.updateHeader(templateId)}
        canEdit={canEdit}
        canEditAfterDone={canEditAfterDone}
      />
      <Conclusions
        {...commonProps}
        type="header"
        text={conclusions.headerText}
        heading={conclusions.headerHeading}
        templateId={templateId}
        definitions={conclusions.conclusionDefinitions}
        questions={qs}
        canEdit={canEdit}
        canEditAfterDone={canEditAfterDone}
      />
      <Reasonings
        {...commonProps}
        reasonings={conclusions.reasonings}
        templateId={templateId}
        questions={qs}
        canEdit={canEdit}
        canEditAfterDone={canEditAfterDone}
      />
      <Static
        {...commonProps}
        type="footer"
        text={conclusions.footerText}
        request={conclusionRequest.updateFooter(templateId)}
        canEdit={canEdit}
        canEditAfterDone={canEditAfterDone}
      />
      <Static
        {...commonProps}
        type="disclaimer"
        text={conclusions.disclaimerText}
        request={conclusionRequest.updateDisclaimer(templateId)}
        canEdit={canEdit}
        canEditAfterDone={canEditAfterDone}
      />
    </>
  )
}

export default R.compose(
  withData(
    p =>
      parallel(
        conclusionRequest.list(p.templateId),
        questionRequest.list(p.templateId),
      ),
    {
      dataKeys: ["conclusions", "questions"],
    },
  ),
  withState("editIndex", "setEditIndex", undefined),
  withState("moveIndex", "setMoveIndex", undefined),
  withHandlers({
    onSuccess: p => () => {
      p.refetchData()
      p.setEditIndex(undefined)
      p.setMoveIndex(undefined)
    },
  }),
)(Conclusion)

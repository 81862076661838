import React from "react"
import ButtonModal from "components/ButtonModal"
import Panel from "components/Panel"
import { previewRequest } from "data/templates/api"
import withData from "hoc/withData"
import { localize } from "locale"
import TestFilesTable from "./TestFilesTable"
import CreateTestFileModal from "./CreateTestFileModal"
import PlusIcon from "icons/Plus"
import { P } from "styledComponents/Typography"
import { statuses } from "data/templates/statuses"

const Testing = ({ data, refetchData, templateId, template }) => {
  const editable =
    template.canEdit &&
    (template.status === statuses.inProcess.value ||
      template.status === statuses.requestForApproval.value)

  return (
    <>
      <Panel mb={20}>
        <P mb={20}>{localize("templateTestingDescription")}</P>
        {editable && (
          <ButtonModal
            icon={PlusIcon}
            text={localize("createANewTestFile")}
            modal={CreateTestFileModal}
            templateId={templateId}
          />
        )}
      </Panel>
      <TestFilesTable data={data} refetch={refetchData} canEdit={editable} />
    </>
  )
}

export default withData(p => previewRequest.list(p.templateId))(Testing)

import React from "react"
import { Box } from "styledComponents/Containers"
import Button from "components/Button"
import Columns from "components/Columns"
import Required from "components/Required"
import ModalButtons from "components/ModalButtons"
import withForm from "hoc/withForm"
import EditIcon from "icons/appkit/fill-edit"
import { stringRequired } from "validators/string"
import { arrayOptional } from "validators/array"
import { ActiveIcon } from "styledComponents/Icons"
import Select from "inputs/Select"
import Textfield from "inputs/Textfield"
import Filepicker from "inputs/Filepicker"
import {
  getReportTransitionOptions,
  isInFinalState,
} from "data/assessments/statuses"
import * as R from "ramda"
import { localize } from "locale"
import { reportRequest } from "data/report/api"
import { conclusionCommentsRequests } from "data/reporting/api"
import WarningConfirmModal from "components/WarningConfirmModal"

const ConclusionStatus = ({
  openId,
  setOpenId,
  conclusion,
  readOnly,
  report,
  submitForm,
  errors,
}) => {
  const editing = openId === "conclusionStatus"
  const requests = conclusionCommentsRequests(conclusion.assessmentId)
  const canEdit =
    R.isNil(openId) && !isInFinalState(conclusion.conclusionStatus) && !readOnly

  return (
    <>
      <Box flex={!editing}>
        <Columns widths={[200, "auto"]} gap={5}>
          <Select
            options={getReportTransitionOptions(conclusion.conclusionStatus)}
            byValue
            label={localize("conclusionStatus")}
            name="conclusionStatus"
            readOnly={!editing}
            borderless={!editing}
            edge2edge={!editing}
            mb={0}
            noEmptyError
          />
          {editing && (
            <Required>
              <Textfield
                label=" "
                ml={10}
                placeholder={localize("addNewComment")}
                name="text"
                mb={0}
                noEmptyError
              />
            </Required>
          )}
        </Columns>
        {!editing && (
          <ActiveIcon
            disabled={!canEdit}
            onClick={() => setOpenId("conclusionStatus")}
            ml="auto"
          >
            <EditIcon />
          </ActiveIcon>
        )}
      </Box>
      {editing && (
        <Box flex mt={20} align="flex-end" justifyContent="space-between">
          <Filepicker
            name="attachments"
            mb={0}
            noEmptyError
            upload={requests.uploadFile}
            download={requests.downloadFile}
          />
          <ModalButtons nomargin>
            <Button
              secondary
              type="button"
              onClick={() => setOpenId()}
              text="Cancel"
            />
            <WarningConfirmModal
              onConfirm={submitForm}
              doConfirm={() =>
                !report.conclusionsAreWithoutXml &&
                R.values(errors).every(x => !x) &&
                (report.canUpdateReportFromTa || report.canSubmitReportToTa)
              }
              dangerConfirm
              title="Conclusion status change"
              text="Warning: if the conclusion status is changed manually, direct reporting functionality (Send report to Tax authority & Check updates from Tax authority) CANNOT be used."
              buttonText="Do you want to manually change conclusion status?"
              confirmText="Yes"
              cancelText="No"
            >
              {onClick => <Button onClick={onClick} text="Save" />}
            </WarningConfirmModal>
          </ModalButtons>
        </Box>
      )}
    </>
  )
}

export default R.compose(
  withForm({
    dontWrapInForm: true,
    mapProps: p => ({
      conclusionStatus: p.conclusion.conclusionStatus,
      text: "",
      attachments: [],
    }),
    schema: R.always({
      conclusionStatus: stringRequired,
      text: stringRequired,
      attachments: arrayOptional,
    }),
    onSubmit: p => v =>
      reportRequest.setStatus(p.conclusion.assessmentId)({
        conclusionStatusCode: v.conclusionStatus,
        text: v.text,
        attachments: v.attachments,
      }),
    onSuccess: p => () => {
      p.setOpenId()
      p.refetch()
    },
  }),
)(ConclusionStatus)

import React from "react"
import { UrlsContext } from "pages/urls"
import { useHistory, useLocation, useRouteMatch } from "react-router"

const withUrls = C => p => {
  const history = useHistory()
  const location = useLocation()
  const match = useRouteMatch()
  return (
    <UrlsContext.Consumer>
      {v => (
        <C {...p} {...v} history={history} location={location} match={match} />
      )}
    </UrlsContext.Consumer>
  )
}

export default withUrls

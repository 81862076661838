import React from "react"
import { helpdeskRequest } from "data/overview/api"
import withData from "hoc/withData"
import SearchIcon from "icons/Search"
import Textfield from "inputs/Textfield"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import Category from "./Category"
import SearchResults from "./SearchResults"
import { HelpcenterListWrap } from "./styled"
import Loader from "components/Loader"
import { Box } from "styledComponents/Containers"
import withState from "hoc/withState"
import * as R from "ramda"
import { parallel } from "common/rxjs"
import InputContacts from "inputs/InputContacts2"

const HelpcenterList = ({ sections, contacts, loading, setV, v }) => (
  <HelpcenterListWrap>
    <Box p={20}>
      <P fs={18}>{localize("helpCenter")}</P>
      <P mt={20} bold>
        {localize("contacts")}
      </P>
      <InputContacts readOnly domains={[]} value={contacts} />
      <P mt={20} mb={5} bold>
        {localize("tutorials")}
      </P>
      <Textfield
        onChange={setV}
        value={v}
        icon={SearchIcon}
        clearable
        placeholder={localize("search")}
        mb={20}
      />
      {loading ? (
        <Loader />
      ) : (
        <Box mt={20}>
          {v ? (
            <SearchResults v={v} categories={sections} />
          ) : (
            sections?.map(c => <Category category={c} key={c.name} />)
          )}
        </Box>
      )}
    </Box>
  </HelpcenterListWrap>
)

export default R.compose(
  withData(
    () => parallel(helpdeskRequest.sections(), helpdeskRequest.contacts()),
    {
      noLoader: true,
      dataKeys: ["sections", "contacts"],
    },
  ),
  withState("v", "setV", ""),
)(HelpcenterList)

import React from "react"
import Panel from "components/Panel"
import Preview from "./Preview"
import { reportingRequest } from "data/reporting/api"
import withData from "hoc/withData"
import { parallel } from "common/rxjs"
import { assessmentQuestionRequest } from "data/assessments/api"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import * as R from "ramda"
import { P } from "styledComponents/Typography"
import ValidationErrors from "../ValidationErrors"
import withProps from "hoc/withProps"
import { previewRequest } from "data/templates/api"

const ReportingPreview = ({
  verify,
  questions,
  assessment,
  assessmentId,
  isPreview,
  reportingTypes,
}) => {
  const count = verify.messages.length + R.keys(verify.questionMessages).length
  return (
    <>
      <Panel mb={20}>
        {verify.isValid ? (
          <P>
            {isPreview
              ? "Everything is correct, you may check for conclusion preview now."
              : "Everything is correct, you can submit the assessment now."}
          </P>
        ) : (
          <P mb={20}>
            There are {count} errors which have to be solved before you can
            submit the assessment.
          </P>
        )}
        <ValidationErrors
          {...verify}
          questions={questions}
          assessmentId={assessmentId}
        />
      </Panel>
      {reportingTypes.map(x => {
        const tree = buildTree(questions, q =>
          q.data.reportTypes.includes(x.reportingType),
        )

        return (
          <Preview
            tree={tree}
            questions={getQuestionsFromTree(tree)}
            assessmentId={assessmentId}
            assessment={assessment}
            isValid={verify.isValid}
            isPreview={isPreview}
            isSchemaActive={x.isSchemaActive}
            mappingSchemaId={x.mappingSchemaId}
            isPreviewEnabled={verify.isPreviewEnabled}
            title={x.reportingType}
          />
        )
      })}
    </>
  )
}

export default R.compose(
  withData(
    p => {
      const getQuestionListRequest = () => {
        if (p.isPreview) return previewRequest.questions

        return assessmentQuestionRequest.list
      }
      const getVerifyRequest = () => {
        if (p.isPreview) return previewRequest.verifyReporting

        return reportingRequest.verify
      }

      return parallel(
        getQuestionListRequest()(p.assessmentId),
        getVerifyRequest()(p.assessmentId),
      )
    },
    {
      dataKeys: ["questions", "verify"],
      refetchParams: () => [],
    },
  ),
  withData(p => reportingRequest.reportingTypes(p.assessmentId), {
    skip: p => p.isPreview,
    dataKey: "types",
  }),
  withProps(p => ({
    reportingTypes: p.isPreview ? p.reporting.allowedReportingTypes : p.types,
  })),
)(ReportingPreview)

import React from "react"
import { SectionWrap, SectionHeader } from "./styled"
import { P } from "styledComponents/Typography"
import Panel from "components/Panel"

const Section = ({ children, title }) => (
  <SectionWrap>
    <SectionHeader>
      <P fs={20} white>
        {title}
      </P>
    </SectionHeader>
    <Panel>{children}</Panel>
  </SectionWrap>
)

export default Section

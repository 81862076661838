import styled from "styled-components/macro"
import theme from "theme"

export const StyledNav = styled.nav`
  position: relative;
  flex-shrink: 0;
  box-shadow: 0 1px 3px 0 ${theme.color.shadow};
`
export const TopBar = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  padding-right: 20px;
`

import React from "react"
import { reportRequest } from "data/report/api"
import withData from "hoc/withData"
import withPage from "hoc/withPage"
import { Box, Container } from "styledComponents/Containers"
import PageTitle from "components/PageTitle"
import ButtonModal from "components/ButtonModal"
import ConfirmModal from "components/ConfirmModal"
import Warning from "components/Warning"
import IdSearch from "inputs/IdSearch"
import DownloadFileButton from "components/DownloadFileButton"
import InfoModal from "components/InfoModal"
import Button from "components/Button"
import { localize } from "locale"
import ChangeStatusesModal from "./ChangeStatusesModal"
import SendReportToTaModal from "./SendReportToTaModal"
import SendReportToTaUnknownModal from "./SendReportToTaUnknownModal"
import MainPanel from "./MainPanel"
import ConclusionPanel from "./ConclusionPanel"
import ModalButtons from "components/ModalButtons"
import { conclusionRequest } from "data/conclusions/api"
import { conclusionStatuses } from "data/assessments/statuses"
import * as R from "ramda"
import useOpen from "hooks/useOpen"
import CheckUpdateTaModal from "./CheckUpdateTaModal"
import CheckUpdateTaUnknownModal from "./CheckUpdateTaUnknownModal"
import { execute } from "common/rxjs"

const ReportPage = ({
  report,
  statuses,
  match,
  refetchData,
  fetchCount,
  urls,
  routes,
}) => {
  const reportId = match.params.id
  const [openId, setOpenId] = React.useState()
  const [id, setId] = React.useState()
  const { isOpen, open, close } = useOpen()

  const canDelete = report.massReportConclusions.every(
    x => x.conclusionStatus === conclusionStatuses.conclusionDeleted.value,
  )

  const hasTaxAccount = !R.isNil(R.path(["taxAccount", "id"], report))

  return (
    <Container>
      <PageTitle title={report.massReportRefId}>
        <ModalButtons nomargin>
          <ButtonModal
            secondary
            text={localize("changeStatuses")}
            modal={ChangeStatusesModal}
            conclusions={report.massReportConclusions}
            refetch={refetchData}
            reportId={reportId}
            report={report}
          />
          {canDelete ? (
            <ButtonModal
              text="Delete"
              secondary
              modal={ConfirmModal}
              title="Permanently delete report"
              confirmText={`Report ${report.massReportRefId} will be permanently deleted and cannot be restored.\nIf it contains conclusions, which are deleted (in recycle bin) and these conclusions are later restored, then it won't be possible to add these conclusions into any further reports.`}
              buttonText="Do you want to permanently delete the report?"
              cancelText="No"
              submitText="Yes, delete"
              request={() => reportRequest.delete(report.id)}
              redirect={() => {
                if (match.path.startsWith(routes.caseCreate.path)) {
                  return R.head(match.url.split("report/"))
                }
                return urls.reports
              }}
              submitDanger
            />
          ) : (
            <ButtonModal
              text="Delete"
              secondary
              modal={InfoModal}
              title="You cannot delete report"
              body={
                <Warning
                  error
                  text="Report contains active conclusions. Please delete included conclusions before proceeding with report deletion."
                />
              }
            />
          )}
          <Button
            text={localize("export")}
            secondary
            onClick={() => {
              open()
              execute(reportRequest.export(reportId))
            }}
          />
          {isOpen && (
            <InfoModal
              close={close}
              title={localize("export")}
              text={localize("exportIsBeingGenerated")}
            />
          )}
          {!report.conclusionsAreWithoutXml && (
            <DownloadFileButton
              text={localize("downloadXmlReportFile")}
              filename="report.xml"
              request={reportRequest.downloadXML(reportId)}
            />
          )}
          {report.canUpdateReportFromTa && (
            <ButtonModal
              text="Check updates from TA"
              modal={
                hasTaxAccount ? CheckUpdateTaModal : CheckUpdateTaUnknownModal
              }
              taxAccount={report.taxAccount}
              refetch={refetchData}
              report={report}
              reportId={reportId}
            />
          )}
          {report.canSubmitReportToTa && (
            <ButtonModal
              text="Send report to TA"
              modal={
                hasTaxAccount ? SendReportToTaModal : SendReportToTaUnknownModal
              }
              refetch={refetchData}
              taxAccount={report.taxAccount}
              report={report}
              reportId={reportId}
            />
          )}
        </ModalButtons>
      </PageTitle>
      <MainPanel report={report} openId={openId} setOpenId={setOpenId} />
      <Box width={250}>
        <IdSearch
          small
          value={id}
          onChange={setId}
          mt={30}
          mb={10}
          text={`You may search report(s) via "Internal arrangement ID", country specific report information (e.g. Entity ID, Entity name, etc.) if available, and IDs received from the tax authority (arrangement ID, disclosure ID, etc.) if available, through exact match.`}
        />
      </Box>
      {report.massReportConclusions
        .filter(x => {
          const ids = [
            ...R.pathOr([], ["externalReportIdentifiers"], x),
            ...R.pathOr([], ["internalReportIdentifiers"], x),
            { value: R.path(["arrangementId"], x) },
          ]

          return !id || ids.map(y => y.value).some(y => y === id)
        })
        .map(x => (
          <ConclusionPanel
            key={x.assessmentId}
            conclusion={x}
            statuses={statuses}
            openId={openId}
            setOpenId={setOpenId}
            refetch={refetchData}
            fetchCount={fetchCount}
            report={report}
          />
        ))}
    </Container>
  )
}

export const EnhancedReportPage = R.compose(
  withData(() => conclusionRequest.listTypes(), {
    dataKey: "statuses",
  }),
  withData(p => reportRequest.detail(p.match.params.id), {
    dataKey: "report",
  }),
)(ReportPage)

export default withPage()(EnhancedReportPage)

import React from "react"
import Columns from "components/Columns"
import { P } from "styledComponents/Typography"
import InputQuestion from "inputs/InputQuestion"
import { mappingRequest } from "data/mapping/api"
import * as R from "ramda"
import Status from "components/Status"
import theme from "theme"
import { localize } from "locale"
import SkipConfirm from "components/SkipConfirm"

const Multi = ({
  node,
  questions,
  tree,
  lastSelectedValue,
  setLastSelectedValue,
  editProps,
  indices,
  valueProps,
  templateId,
  schemaId,
}) => (
  <SkipConfirm
    editProps={editProps}
    title={`R${node.element.nr} ${localize("element")}`}
    id={node.element.id}
    v={R.path(indices.concat(["element", "elementOptions"]), valueProps.value)}
    onChange={v => {
      valueProps.onChange(
        R.set(
          R.lensPath(indices.concat(["element", "elementOptions"])),
          v,
          valueProps.value,
        ),
      )
    }}
    resetValue={valueProps.resetValue}
    onSubmit={v => {
      return mappingRequest.setMapping(templateId, {
        schemaId: schemaId,
        optionMappings: v
          .map(x => x.mappedOption)
          .filter(
            x =>
              x &&
              // signifies the value stayed the same, so dont send it to the update request
              (!R.isNil(x.elementOptionId) || !x.text),
          ),
      })
    }}
    onSuccess={res => {
      valueProps.resetValue(res)
      valueProps.setLastValue(res)
    }}
  >
    {({ v, editingThis, onChange }) => (
      <>
        <P mb={5} bold>
          R{node.element.nr} {node.element.displayText}
        </P>
        <Status
          wrap
          text={node.element.validationText}
          color={theme.color.red}
        />
        {node.element.elementOptions.map((x, xi) => (
          <Columns key={xi} widths={["auto", 400]} align="center" mt={10}>
            <P mr="auto">{x.text}</P>
            <InputQuestion
              questions={questions}
              tree={tree}
              element={x}
              value={R.path([xi, "mappedOption"], v)}
              readOnly={!editingThis}
              elementOptionId={x.id}
              onChange={val => {
                onChange(R.set(R.lensPath([xi, "mappedOption"]), val, v))

                if (val.questionId || val.questionOptionId) {
                  setLastSelectedValue(val)
                }
              }}
              lastSelectedValue={lastSelectedValue}
            />
          </Columns>
        ))}
      </>
    )}
  </SkipConfirm>
)

export default Multi

import React from "react"

export default () => (
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.7727273,7.09090909 L15.7727273,16.5 C15.7727273,18.3081818 14.3081818,19.7727273 12.5,19.7727273 C10.6918182,19.7727273 9.22727273,18.3081818 9.22727273,16.5 L9.22727273,6.27272727 C9.22727273,5.14363636 10.1436364,4.22727273 11.2727273,4.22727273 C12.4018182,4.22727273 13.3181818,5.14363636 13.3181818,6.27272727 L13.3181818,14.8636364 C13.3181818,15.3136364 12.95,15.6818182 12.5,15.6818182 C12.05,15.6818182 11.6818182,15.3136364 11.6818182,14.8636364 L11.6818182,7.09090909 L10.4545455,7.09090909 L10.4545455,14.8636364 C10.4545455,15.9927273 11.3709091,16.9090909 12.5,16.9090909 C13.6290909,16.9090909 14.5454545,15.9927273 14.5454545,14.8636364 L14.5454545,6.27272727 C14.5454545,4.46454545 13.0809091,3 11.2727273,3 C9.46454545,3 8,4.46454545 8,6.27272727 L8,16.5 C8,18.9872727 10.0127273,21 12.5,21 C14.9872727,21 17,18.9872727 17,16.5 L17,7.09090909 L15.7727273,7.09090909 Z"
      transform="translate(12.500000, 12.000000) rotate(45.000000) translate(-12.500000, -12.000000) "
    />
  </svg>
)

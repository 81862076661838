import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { getSuburls } from "./suburls"
import * as R from "ramda"

const Preassessment = ({ match, case_, refetchCase }) => {
  const suburls = getSuburls(match)

  return (
    <Switch>
      {R.values(suburls)
        .filter(x => !x.disabled)
        .map(x => {
          const Component = x.component

          return (
            <Route
              key={x.path}
              path={x.path}
              render={p => (
                <Component
                  {...p}
                  refetchCase={refetchCase}
                  caseUrls={suburls}
                  suburls={suburls}
                  case_={case_}
                  cancelUrl={suburls.list.url}
                />
              )}
            />
          )
        })}
      <Redirect to={suburls.list.url} />
    </Switch>
  )
}

export default Preassessment

import * as R from "ramda"

export const getDefaultAnswerSet = (value, node) => {
  if (value.length !== 0) return value

  const data = {
    ...node.data,
    id: node.referencedId,
  }

  const getAnsSets = x => ({
    questionId: x.id,
    answers: [],
    children: R.pathOr([], ["subQuestions"], x)
      .filter(x => x.questionType === "Custom")
      .map(getAnsSets),
  })

  const result = [getAnsSets(data)]
  return result
}

export const isClash = err => {
  const d = R.path(["error", "response", "data"], err)
  return (
    R.path(["error", "response", "status"], err) === 409 &&
    //  if its not an object its a different type of error
    R.type(d) === "Object"
  )
}

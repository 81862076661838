import React from "react"
import Question from "./Question"
import Section from "./Section"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"

const Node = ({ node, indices, assessmentId, bookmarkedOnly, ...rest }) => {
  if (node.nodeType === "question") {
    return (
      <div
        className={`q-${node.referencedId}`}
        data-qnumber={`Q${node.data.nrLabel}`}
      >
        <div className={`q-${node.referencedId}:before`} />
        <Box mt={R.last(indices) === 0 ? 0 : 20}>
          <Question
            {...rest}
            node={node}
            indices={indices}
            assessmentId={assessmentId}
            hideQuestion={bookmarkedOnly && !node.data.isBookmarked}
          />
        </Box>
      </div>
    )
  }

  return (
    <Section node={node} indices={indices}>
      {node.children.map((x, xi) => (
        <Node
          {...rest}
          key={x.id}
          node={x}
          indices={indices.concat(xi)}
          assessmentId={assessmentId}
          bookmarkedOnly={bookmarkedOnly}
        />
      ))}
    </Section>
  )
}

export default Node

import React from "react"
import Panel from "components/Panel"
import { P } from "styledComponents/Typography"
import Form from "./Form"
import Set from "./Set"
import * as R from "ramda"
import { localize } from "locale"

const Node = ({
  node,
  questions,
  tree,
  templateId,
  schemaId,
  last,
  editProps,
  valueProps,
  refetch,
  indices,
  optionalParent,
  lastSelectedValue,
  setLastSelectedValue,
}) => {
  const optional = !node.element.minOccurs

  const renderChildren = newOptionalParent =>
    (node.children || []).map((x, xi, arr) => (
      <Node
        key={xi}
        node={x}
        questions={questions}
        templateId={templateId}
        schemaId={schemaId}
        tree={tree}
        last={xi === arr.length - 1}
        editProps={editProps}
        refetch={refetch}
        valueProps={valueProps}
        optionalParent={newOptionalParent}
        indices={indices.concat(["children", xi])}
        lastSelectedValue={lastSelectedValue}
        setLastSelectedValue={setLastSelectedValue}
      />
    ))

  const wrap = node.element.maxOccurs > 1
  if (node.element.isContainer) {
    return (
      <Set wrap={wrap} mb={last ? 0 : 20}>
        <P mb={10}>
          <P bold inline fs={20}>
            {node.element.displayText}
          </P>
          {optional && (
            <P fs={20} ml={5} inline gray>
              ({localize("optional")})
            </P>
          )}
        </P>
        <Panel mb={last || wrap ? 0 : 20}>
          {renderChildren(optionalParent)}
        </Panel>
      </Set>
    )
  }

  const hasChildren = R.path(["children", "length"], node) > 0
  return (
    <Set
      wrap={wrap}
      mb={last ? 0 : 20}
      optional={optional}
      optionalParent={optionalParent}
    >
      <Form
        node={node}
        questions={questions}
        templateId={templateId}
        schemaId={schemaId}
        tree={tree}
        last={last || wrap}
        editProps={editProps}
        valueProps={valueProps}
        refetch={refetch}
        indices={indices}
        lastSelectedValue={lastSelectedValue}
        setLastSelectedValue={setLastSelectedValue}
      />
      {hasChildren && (
        <Panel mt={20}>{renderChildren(optionalParent || optional)}</Panel>
      )}
    </Set>
  )
}

export default Node

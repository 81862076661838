import React from "react"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"

const LabelValue = ({ label, value, sublabel, indent, ...rest }) => (
  <Box {...rest}>
    <P mb={10}>
      <P inline bold gray>
        {label}
      </P>
      {sublabel && (
        <P ml={4} inline gray>
          ({sublabel})
        </P>
      )}
    </P>
    <P ml={indent ? 15 : 0}>{value || "N/A"}</P>
  </Box>
)

export default LabelValue

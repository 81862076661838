import React from "react"
import { localize } from "locale"
import * as R from "ramda"
import Link from "components/Link"
import { formatDate } from "data/units/date/formatters"
import Checkbox from "inputs/Checkbox"
import Status from "components/Status"
import Button from "components/Button"
import dayjs from "dayjs"
import theme from "theme"

export const config = urls => ({
  columns: [
    {
      label: localize("reportId"),
      path: x => (
        <Link ul to={urls.report(x.id)}>
          {x.massReportRefId}
        </Link>
      ),
      sortKey: "MassReportRefId",
    },
    {
      label: localize("includedConclusions"),
      path: x =>
        `${x.conclusionsCount} ${localize(
          x.conclusionsCount === 1 ? "conclusion" : "conclusions",
        )}`,
    },
    {
      label: localize("country"),
      path: R.prop("countryName"),
      sortKey: "Country.Name",
    },
    {
      label: localize("createdOn"),
      path: x => formatDate(x.createdAtUtc),
      sortKey: "CreatedAtUtc",
    },
    {
      label: "Nearest due date",
      path: x => (
        <Status
          color={
            dayjs(x.nearestDueDateUtc).isBefore(dayjs(), "days")
              ? theme.color.error
              : null
          }
          text={formatDate(x.nearestDueDateUtc)}
        />
      ),
      sortKey: "NearestDueDateUtc",
    },
    {
      label: localize("filingEntityName"),
      path: R.prop("entityName"),
      sortKey: "EntityName",
    },
    {
      label: "Action",
      path: x => (
        <Button
          to={urls.report(x.id)}
          small
          text={x.conclusionsToUpdate ? "Update" : "Report"}
        />
      ),
    },
  ],
  filters: [
    {
      name: "overdue",
      render: (value, onChange) => (
        <Checkbox
          value={value}
          onChange={onChange}
          text={localize("showOverdue")}
        />
      ),
      filter: R.always(true),
    },
  ],
  search: R.always(true),
})

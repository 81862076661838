import React from "react"
import {
  getSpecificProps,
  componentMap,
  getEnabled,
  getHandleChange,
} from "./helpers"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import { answerKey } from "data/templates/answers"
import * as R from "ramda"
import Panel from "components/Panel"
import Text from "components/Text"
import { localize } from "locale"
import MappingValidators from "./MappingValidators"
import Autofill from "./Autofill"
import useOpen from "hooks/useOpen"

const SimpleAnswer = ({
  data,
  indices,
  values,
  onChange,
  assessment,
  enabled,
  nonCustomRoot,
  first,
  seq,
  readOnly,
  disabledByParent,
  disabledByRoot,
  openByRoot,
  catalogs,
  rootQuestion,
  parentQuestion,
  parentGroups,
  isFirstAnswerSet,
  prevAnswerSets,
  answerIndices,
}) => {
  const { isOpen, toggle } = useOpen()

  const C = componentMap[data.questionType]

  const isEnabled = getEnabled(
    data,
    indices,
    enabled,
    disabledByParent,
    nonCustomRoot,
    disabledByRoot,
  )

  const handleChange = getHandleChange({
    indices,
    values,
    data,
    onChange,
  })

  const value =
    R.path(indices, values).find(x => x.templateQuestionId === data.id) || {}
  const inputValue = value[answerKey[data.questionType]]

  const showContent = isEnabled || isOpen || openByRoot

  return (
    <Panel
      noPanel={nonCustomRoot}
      mt={first ? 0 : 20}
      bg={isEnabled ? undefined : "#eee"}
    >
      <Box flex mb={showContent ? 10 : 0} acenter>
        <P mr="auto">
          {!nonCustomRoot && (
            <Box flex mb={5}>
              <P
                inline
                bold
                required={data.isMandatory}
                mr={data.isMandatory ? 10 : 0}
                data-qnum={`Q${seq.join(".")}`}
                title={`Id: ${data.id}, Answer sets: ${answerIndices}`}
              >
                Q{seq.join(".")}:
              </P>
              {!nonCustomRoot && (
                <Autofill
                  indices={indices}
                  question={data}
                  value={value}
                  inputValue={inputValue}
                  onChange={handleChange}
                  onChangeFull={onChange}
                  readOnly={readOnly}
                  catalogs={catalogs}
                  rootQuestion={rootQuestion}
                  isEnabled={isEnabled}
                  values={values}
                  parentQuestion={parentQuestion}
                  enabled={enabled}
                  parentGroups={parentGroups}
                  isFirstAnswerSet={isFirstAnswerSet}
                  prevAnswerSets={prevAnswerSets}
                />
              )}
            </Box>
          )}
          <Box flex>
            <Text>{data.questionText}</Text>
            {nonCustomRoot && (
              <Autofill
                nonCustomRoot
                question={data}
                value={value}
                values={values}
                inputValue={inputValue}
                onChange={handleChange}
                readOnly={readOnly}
                catalogs={catalogs}
              />
            )}
          </Box>
        </P>
        {!isEnabled && !nonCustomRoot && (
          <IconWrap rotate={+isOpen} onClick={toggle}>
            <OutlineArrow />
          </IconWrap>
        )}
      </Box>
      {showContent && (
        <C
          {...getSpecificProps(data)}
          assessment={assessment}
          value={inputValue}
          onChange={handleChange}
          readOnly={readOnly || !isEnabled}
          transparent={!isEnabled}
        />
      )}
      {!readOnly && isEnabled && (
        <Box flex>
          <P mt={10} link hovernolink red onClick={() => handleChange(null)}>
            {localize("clear")}
          </P>
        </Box>
      )}
      {showContent && (
        <MappingValidators
          validators={data.validators}
          value={inputValue}
          questionType={data.questionType}
          questionEnabled={isEnabled}
        />
      )}
    </Panel>
  )
}

export default SimpleAnswer

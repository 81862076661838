import React from "react"
import withModal from "hoc/withModal"
import Textfield from "inputs/Textfield"
import { localize } from "locale"
import SearchIcon from "icons/Search"
import Node from "./Node"
import { Box } from "styledComponents/Containers"
import EmptyLoader from "components/EmptyLoader"
import * as R from "ramda"
import { ScrollBody } from "./styled"
import { filterTree } from "./transforms"
import { wrongNodeType, doesntMeetSearch, getChildrenLen } from "./helpers"
import { arrEmpty } from "common/array"
import withState from "hoc/withState"

const QuestionModal = ({
  // questions,
  tree,
  search,
  setSearch,
  onChange,
  elementId,
  elementOptionId,
  value,
  lastSelectedValue,
}) => {
  const mappingElement = !R.isNil(elementId)
  const results = filterTree(
    x => wrongNodeType(mappingElement)(x) || doesntMeetSearch(search)(x),
    getChildrenLen,
    tree,
  )

  const [scroller, setScroller] = React.useState(null)
  const setRef = node => {
    if (!node) return
    setScroller(node)
  }

  return (
    <>
      <Box mb={20}>
        <Textfield
          value={search}
          onChange={setSearch}
          icon={SearchIcon}
          placeholder={localize("search")}
          clearable
        />
      </Box>
      <ScrollBody ref={setRef}>
        {scroller && (
          <EmptyLoader
            isEmpty={
              results.length === 0 || results.every(x => arrEmpty(x.children))
            }
          >
            {() =>
              results.map((x, xi) => (
                <Node
                  node={x}
                  key={xi}
                  onChange={onChange}
                  elementId={elementId}
                  elementOptionId={elementOptionId}
                  value={value}
                  expand={!!search}
                  scroller={scroller}
                  lastSelectedValue={lastSelectedValue}
                />
              ))
            }
          </EmptyLoader>
        )}
      </ScrollBody>
    </>
  )
}

export default R.compose(
  withModal(() => localize("selectTheElementYouWantToMap"), {
    fullScreen: true,
    noScroll: true,
  }),
  withState("search", "setSearch", ""),
)(QuestionModal)

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const WarningWrap = styled.div`
  color: ${theme.color.warningDark};
  background: ${theme.color.warningLight};
  padding: 15px;
  display: flex;
  align-items: center;
  margin-bottom: 10px;

  svg {
    flex-shrink: 0;
  }

  * {
    color: #933401;
    fill: #933401;
  }

  ${p =>
    p.error &&
    css`
      color: ${theme.color.errorDark};
      background: ${theme.color.errorLight};
      * {
        color: ${theme.color.errorDark};
        fill: ${theme.color.errorDark};
      }
    `};

  ${p =>
    p.small &&
    css`
      padding: 7.6px;
      margin-bottom: 0;
    `}
`

import React from "react"
import Portal from "components/Portal"
import OverlayWrapper from "components/OverlayWrapper"
import CrossLightIcon from "icons/appkit/outline-close"
import { P } from "styledComponents/Typography"
import { ModalBody, ModalHeader, ModalInner, CrossWrap } from "./styled"

const Modal = ({
  close,
  title,
  children,
  noPadding,
  width = 650,
  fullScreen,
  fixedRight,
  noScroll,
  boldTitle,
  titleLine = true,
}) => (
  <OverlayWrapper isOpen close={close}>
    <Portal>
      <ModalBody
        width={width}
        fullScreen={fullScreen}
        fixedRight={fixedRight}
        noScroll={noScroll}
      >
        <ModalHeader titleLine={titleLine}>
          <P fs={18} pt={10} pb={10} bold={boldTitle}>
            {title}
          </P>
          <CrossWrap onClick={close}>
            <CrossLightIcon />
          </CrossWrap>
        </ModalHeader>
        <ModalInner noPadding={noPadding}>{children}</ModalInner>
      </ModalBody>
    </Portal>
  </OverlayWrapper>
)

export default Modal

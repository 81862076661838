import { noopHandler } from "common/functions"
import { toastError, toastSuccess } from "data/toasts/rx"
import { useHistory } from "react-router"
import { getData } from "common/rxjs"
import React from "react"
import Loader from "components/Loader"

const Submit = ({
  onSubmit,
  onSuccess = noopHandler,
  onError = noopHandler,
  successText,
  errorText,
  redirect,
  loadingText,
  children,
}) => {
  const [obs, setObs] = React.useState()
  const [submitLoading, setSubmitLoading] = React.useState(false)
  const history = useHistory()

  const submit = v => {
    setSubmitLoading(true)
    const observable = onSubmit(v).subscribe({
      error: error => {
        const message = error.text
        const t = errorText ? errorText(message, error) : message
        if (t) {
          toastError(t)
        }
        setSubmitLoading(false)
        onError(message, error)
      },
      next: result => {
        const data = getData(result)
        setSubmitLoading(false)
        onSuccess(data, v)
        if (successText) {
          const t = successText(data, v, result)
          if (t) {
            toastSuccess(t)
          }
        }
        if (redirect) {
          const redirUrl = redirect(data)
          if (redirUrl) {
            history.push(redirUrl)
          }
        }
      },
    })

    setObs(() => observable)
  }

  React.useEffect(() => {
    return () => obs?.unsubscribe()
  }, []) // eslint-disable-line

  return (
    <>
      {children({ submit })}
      {submitLoading && <Loader center loadingText={loadingText} />}
    </>
  )
}

export default Submit

import React from "react"
import { localize } from "locale"
import { templateSuburls } from "pages/TemplatesCreatePage/suburls"
import { assessmentSuburls } from "pages/AssessmentsCreatePage/suburls"
import { caseSuburls } from "pages/CaseCreatePage/suburls"
import { folderSuburls } from "pages/FolderCreatePage/suburls"
import { conclusionSuburls } from "pages/ConclusionsDetailPage/suburls"
import { taskSuburls } from "pages/TasksPage/suburls"

export const getRoutes = tenantId => ({
  // protected routes
  home: {
    path: `/${tenantId}/`,
    url: `/${tenantId}/`,
    navLabel: localize("overview"),
    module: "overview",
    component: React.lazy(() => import("pages/HomePage")),
    props: {
      exact: true,
    },
  },
  tasks: {
    path: `/${tenantId}/tasks`,
    url: (subroute = taskSuburls.templates) => `/${tenantId}/tasks/${subroute}`,
    navLabel: localize("myTasks"),
    module: "mytasks",
    component: React.lazy(() => import("pages/TasksPage")),
  },
  templates: {
    navLabel: localize("templates"),
    submodules: {
      local: {
        path: `/${tenantId}/templates-local`,
        url: `/${tenantId}/templates-local`,
        navLabel: localize("localTemplates"),
        module: "templates/local",
        component: React.lazy(() => import("pages/TemplatesPage")),
      },
      pool: {
        path: `/${tenantId}/templates-pool`,
        url: `/${tenantId}/templates-pool`,
        navLabel: localize("templatePool"),
        module: "templates/pool",
        component: React.lazy(() => import("pages/TemplatesPoolPage")),
      },
    },
  },
  folders: {
    path: `/${tenantId}/folders`,
    url: `/${tenantId}/folders`,
    navLabel: "Folders",
    module: "folders",
    component: React.lazy(() => import("pages/FoldersPage")),
  },
  assessments: {
    navLabel: localize("assessments"),
    submodules: {
      local: {
        path: `/${tenantId}/assessments`,
        url: `/${tenantId}/assessments`,
        navLabel: localize("myAssessments"),
        module: "assessments/local",
        component: React.lazy(() => import("pages/AssessmentsPage")),
      },
      library: {
        path: `/${tenantId}/assessment-library`,
        url: `/${tenantId}/assessment-library`,
        navLabel: localize("library"),
        module: "assessments/library",
        component: React.lazy(() => import("pages/AssessmentLibraryListPage")),
      },
    },
  },
  assessmentsTemplate: {
    path: `/${tenantId}/assessments-template`,
    url: `/${tenantId}/assessments-template`,
    navLabel: localize("assessments"),
    hideFromNav: true,
    module: "assessments",
    component: React.lazy(() => import("pages/AssessmentsTemplatePage")),
  },
  conclusions: {
    path: `/${tenantId}/conclusions`,
    url: `/${tenantId}/conclusions`,
    navLabel: localize("conclusions"),
    module: "conclusions",
    component: React.lazy(() => import("pages/ConclusionsPage")),
  },
  reports: {
    path: `/${tenantId}/reports`,
    url: `/${tenantId}/reports`,
    navLabel: localize("reports"),
    module: "massreports",
    component: React.lazy(() => import("pages/ReportsPage")),
  },
  report: {
    path: `/${tenantId}/report/:id`,
    url: id => `/${tenantId}/report/${id}`,
    module: "massreports",
    component: React.lazy(() => import("pages/ReportPage")),
  },
  tenants: {
    path: `/${tenantId}/tenants`,
    url: `/${tenantId}/tenants`,
    navLabel: localize("pwcSites"),
    module: "tenants",
    component: React.lazy(() => import("pages/TenantsPage")),
  },
  clients: {
    path: `/${tenantId}/clients`,
    url: `/${tenantId}/clients`,
    navLabel: localize("clientSites"),
    module: "tenants",
    component: React.lazy(() => import("pages/TenantsPage")),
    props: {
      isClient: true,
    },
  },
  assessmentsCreate: {
    path: `/${tenantId}/assessments-create/:id`,
    url: (id, subroute = assessmentSuburls.settings) =>
      `/${tenantId}/assessments-create/${id}/${subroute}`,
    navLabel: localize("assessments"),
    hideFromNav: true,
    component: React.lazy(() => import("pages/AssessmentsCreatePage")),
  },
  assessmentLibraryCreate: {
    path: `/${tenantId}/library-create/:id`,
    url: (id, subroute = assessmentSuburls.settings) =>
      `/${tenantId}/library-create/${id}/${subroute}`,
    navLabel: localize("assessments"),
    hideFromNav: true,
    component: React.lazy(() => import("pages/AssessmentLibraryCreatePage")),
  },
  labelManagement: {
    path: `/${tenantId}/label-management`,
    url: `/${tenantId}/label-management`,
    navLabel: "Label management",
    hideFromNav: true,
    component: React.lazy(() => import("pages/LabelManagementPage")),
  },
  templatesCreate: {
    path: `/${tenantId}/templates-create/:id`,
    url: (id, subroute = templateSuburls.settings) =>
      `/${tenantId}/templates-create/${id}/${subroute}`,
    navLabel: localize("templates"),
    hideFromNav: true,
    component: React.lazy(() => import("pages/TemplatesCreatePage")),
  },
  folderCreate: {
    path: `/${tenantId}/folder-create/:id`,
    url: (id, subroute = folderSuburls.settings) =>
      `/${tenantId}/folder-create/${id}/${subroute}`,
    navLabel: "Folder",
    hideFromNav: true,
    component: React.lazy(() => import("pages/FolderCreatePage")),
  },
  caseCreate: {
    path: `/${tenantId}/case-create/:id`,
    url: (id, subroute = caseSuburls.settings) =>
      `/${tenantId}/case-create/${id}/${subroute}`,
    navLabel: "Folder",
    hideFromNav: true,
    component: React.lazy(() => import("pages/CaseCreatePage")),
  },
  linkObjects: {
    path: `/${tenantId}/link/:id`,
    url: id => `/${tenantId}/link/${id}`,
    navLabel: "Folder",
    hideFromNav: true,
    component: React.lazy(() => import("pages/LinkObjectsPage")),
  },
  templatePoolPermission: {
    path: `/${tenantId}/permission/:id`,
    url: id => `/${tenantId}/permission/${id}`,
    component: React.lazy(() => import("pages/TemplatePoolPermissionPage")),
  },
  conclusionsDetail: {
    path: `/${tenantId}/conclusions-detail/:id`,
    url: (id, subroute = conclusionSuburls.conclusion) =>
      `/${tenantId}/conclusions-detail/${id}/${subroute}`,
    component: React.lazy(() => import("pages/ConclusionsDetailPage")),
  },
  tutorials: {
    path: `/${tenantId}/tutorials`,
    url: `/${tenantId}/tutorials`,
    navLabel: localize("tutorials"),
    module: "tutorials",
    component: React.lazy(() => import("pages/TutorialsPage")),
  },
  downloads: {
    path: `/${tenantId}/downloads`,
    url: `/${tenantId}/downloads`,
    navLabel: localize("downloadCenter"),
    module: "conclusions",
    component: React.lazy(() => import("pages/DownloadsPage")),
  },
  configuration: {
    navLabel: localize("configuration"),
    submodules: {
      users: {
        path: `/${tenantId}/configuration/users`,
        url: `/${tenantId}/configuration/users`,
        navLabel: localize("users"),
        module: "configuration/users",
        component: React.lazy(() => import("pages/UsersPage")),
      },
      reportTeam: {
        path: `/${tenantId}/configuration/reportingteam`,
        url: `/${tenantId}/configuration/reportingteam`,
        navLabel: localize("reportingSettings"),
        module: "configuration/reportingteam",
        component: React.lazy(() => import("pages/ReportingTeamPage")),
      },
      reviewTeam: {
        path: `/${tenantId}/configuration/reviewteam`,
        url: `/${tenantId}/configuration/reviewteam`,
        navLabel: localize("reviewSettings"),
        module: "configuration/reviewteam",
        component: React.lazy(() => import("pages/ReviewTeamPage")),
      },
      retentionPeriod: {
        path: `/${tenantId}/configuration/retentionPeriod`,
        url: `/${tenantId}/configuration/retentionPeriod`,
        navLabel: localize("retentionPeriod"),
        module: "configuration/retentionperiod",
        component: React.lazy(() => import("pages/RetentionPeriodPage")),
      },
      registry: {
        path: `/${tenantId}/configuration/registry`,
        url: `/${tenantId}/configuration/registry`,
        navLabel: localize("registry"),
        module: "configuration/registry",
        component: React.lazy(() => import("pages/RegistryPage")),
      },
      siteSettings: {
        path: `/${tenantId}/configuration/local-site-settings`,
        url: `/${tenantId}/configuration/local-site-settings`,
        navLabel: localize("siteSettings"),
        module: "configuration/sitesettings",
        component: React.lazy(() => import("pages/SiteSettingsPage")),
      },
      announcements: {
        path: `/${tenantId}/configuration/announcements`,
        url: `/${tenantId}/configuration/announcements`,
        navLabel: localize("announcements"),
        module: "configuration/announcements",
        component: React.lazy(() => import("pages/AnnouncementsPage")),
      },
      taAccounts: {
        path: `/${tenantId}/configuration/ta-accounts`,
        url: `/${tenantId}/configuration/ta-accounts`,
        navLabel: "Registered TA accounts",
        module: "configuration/taxaccounts",
        component: React.lazy(() => import("pages/TaAccounts")),
      },
    },
  },
  announcementDetail: {
    path: `/${tenantId}/announcement-detail/:id`,
    url: id => `/${tenantId}/announcement-detail/${id}`,
    module: "configuration/announcements",
    component: React.lazy(() => import("pages/AnnouncementCreatePage")),
  },
  reportTeam: {
    path: `/${tenantId}/report-team/:id`,
    url: id => `/${tenantId}/report-team/${id}`,
    module: "siteconfiguration",
    component: React.lazy(() => import("pages/SiteConfigurationTeamPage")),
    props: {
      report: true,
    },
  },
  reviewTeam: {
    path: `/${tenantId}/review-team/:id`,
    url: id => `/${tenantId}/review-team/${id}`,
    module: "siteconfiguration",
    component: React.lazy(() => import("pages/SiteConfigurationTeamPage")),
  },
  registrySetup: {
    path: `/${tenantId}/registry-setup`,
    url: `/${tenantId}/registry-setup`,
    module: "registry",
    component: React.lazy(() => import("pages/RegistrySetupPage")),
  },
  registryImport: {
    path: `/${tenantId}/registry-import`,
    url: `/${tenantId}/registry-import`,
    module: "registry",
    component: React.lazy(() => import("pages/RegistryImportPage")),
  },
  registryEntry: {
    path: `/${tenantId}/registry-entry/:id`,
    url: id => `/${tenantId}/registry-entry/${id}`,
    module: "registry",
    component: React.lazy(() => import("pages/RegistryEntryPage")),
  },
  tutorialsCreate: {
    path: `/${tenantId}/tutorials-create/:id`,
    url: id => `/${tenantId}/tutorials-create/${id}`,
    component: React.lazy(() => import("pages/TutorialsCreatePage")),
  },
  testFile: {
    path: `/${tenantId}/test-file/:id`,
    url: id => `/${tenantId}/test-file/${id}`,
    component: React.lazy(() => import("pages/TestFilePage")),
  },
  compareTool: {
    path: `/${tenantId}/compare-tool/:id`,
    url: id => `/${tenantId}/compare-tool/${id}`,
    component: React.lazy(() => import("pages/CompareToolPage")),
  },
  // public routes
  login: {
    public: true,
    path: "/login",
    url: "/login",
    component: React.lazy(() => import("pages/LoginPage")),
  },
  loginRedirect: {
    public: true,
    path: "/login_redir*",
    url: "/login_redir*",
    component: React.lazy(() => import("pages/TenantSelectionPage")),
  },
  tenantSelection: {
    public: true,
    path: `/tenant-selection/:id`,
    url: id => `/tenant-selection/${id}`,
    component: React.lazy(() => import("pages/TenantSelectionPage")),
  },
  logout: {
    public: true,
    path: "/logout",
    url: "/logout",
    component: React.lazy(() => import("pages/LogoutPage")),
  },
})

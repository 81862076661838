export const getHandleChange =
  ({ value, onChange, validCountryCodes, multi, setEmptyCountry }) =>
  code => {
    if (!validCountryCodes.includes(code)) {
      return setEmptyCountry(code)
    }

    const getNewVal = () => {
      if (!multi) return code

      if (value.find(y => y === code)) {
        return value.filter(y => y !== code)
      }

      return value.concat(code)
    }

    return onChange(getNewVal())
  }

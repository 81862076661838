import React from "react"
import LabelValue from "components/LabelValue"
import Columns from "components/Columns"
import { Box } from "styledComponents/Containers"
import { answerTypeOptions } from "data/templates/answers"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"
import CaretIcon from "icons/Caret"
import { IconWrap } from "styledComponents/Icons"
import * as R from "ramda"

const NonTaggableContent = ({ question }) => {
  const { isOpen, toggle } = useOpen()

  const ops = R.pathOr([], ["options"], question)
  return (
    <Box mt={20}>
      <Columns>
        <LabelValue
          label="Type of answer"
          value={
            answerTypeOptions.find(x => x.value === question.questionType).name
          }
        />
        <LabelValue label="Tag" value="Tagging not allowed" />
      </Columns>
      {ops.length > 0 && (
        <>
          <Box flex mt={20} onClick={toggle}>
            <P link red hovernolink>
              Answers
            </P>
            <IconWrap rotate={+isOpen}>
              <CaretIcon />
            </IconWrap>
          </Box>
          {isOpen &&
            ops.map(x => (
              <P key={x.id} mt={10}>
                {x.text}
              </P>
            ))}
        </>
      )}
    </Box>
  )
}

export default NonTaggableContent

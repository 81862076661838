import React from "react"
import { mappingRequest } from "data/mapping/api"
import withData from "hoc/withData"
import Panel from "components/Panel"
import { P } from "styledComponents/Typography"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import Node from "./Node"
import { questionRequest } from "data/templates/api"
import { SchemaMappingWrap } from "./styled"
import { localize } from "locale"
import theme from "theme"
import * as R from "ramda"

const SchemaMapping = ({
  mappings,
  templateId,
  schema,
  editProps,
  refetch,
  isSchemaActive,
  reportType,
  qs,
}) => {
  const [lastValue, setLastValue] = React.useState(mappings)
  const [value, setValue] = React.useState(lastValue)
  const [lastSelectedValue, setLastSelectedValue] = React.useState()

  const tree = buildTree(qs)
  const questions = getQuestionsFromTree(tree)
  const valueProps = {
    value,
    onChange: setValue,
    resetValue: v => setValue(v || lastValue),
    setLastValue,
  }

  return (
    <SchemaMappingWrap>
      {isSchemaActive ? (
        mappings.map((x, xi, arr) => (
          <Node
            key={xi}
            node={x}
            tree={tree}
            questions={questions}
            templateId={templateId}
            schemaId={schema.id}
            editProps={editProps}
            last={xi === arr.length - 1}
            refetch={refetch}
            indices={[xi]}
            valueProps={valueProps}
            lastSelectedValue={lastSelectedValue}
            setLastSelectedValue={setLastSelectedValue}
          />
        ))
      ) : (
        <Panel>
          <Panel bg={theme.color.borderLight}>
            <P>
              {reportType} {localize("reportTypeXMLFileGenerationNotSupported")}
            </P>
          </Panel>
        </Panel>
      )}
    </SchemaMappingWrap>
  )
}

export default R.compose(
  withData(p => questionRequest.list(p.templateId), {
    dataKey: "qs",
  }),
  withData(p => mappingRequest.mappings(p.templateId, p.schema.id), {
    dataKey: "mappings",
  }),
)(SchemaMapping)

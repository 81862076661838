import React from "react"
import { notificationStatuses } from "data/notifications/statuses"
import withModal from "hoc/withModal"
import { localize } from "locale"
import { P } from "styledComponents/Typography"

const getModalTitle = status => {
  switch (status) {
    case notificationStatuses.templateRejected:
    case notificationStatuses.assessmentRejected:
      return localize("notifsApprovalIsNotPossible")
    case notificationStatuses.templateApproved:
    case notificationStatuses.assessmentApproved:
      return localize("notifsApprovalIsAlreadyProvided")
    default:
      return status
  }
}

const getFileType = n => {
  if (n.assessmentId) {
    return "assessment"
  } else if (n.templateId) {
    return "template"
  }
}

const getFileName = (data, fileType) => {
  if (fileType === "assessment") {
    return data.find(item => item.key === "AssessmentName").value
  } else if (fileType === "template") {
    return data.find(item => item.key === "TemplateName").value
  }
}

const getPeople = data => {
  const filtered = data.filter(
    item =>
      item.key === "FirstApprover" ||
      item.key === "SecondApprover" ||
      item.key === "RejectedBy",
  )
  return filtered.map(item => item.value)
}

const ConfirmModal = ({ data, notification, status }) => (
  <P mb={20}>
    {localize(status)(
      getFileName(data, getFileType(notification)),
      getPeople(data),
    )}
  </P>
)

export default withModal(p => getModalTitle(p.status))(ConfirmModal)

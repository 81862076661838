import {
  conclusionErrorStatus,
  preconclusionStatuses,
} from "data/conclusions/statuses"
import * as R from "ramda"
import theme from "theme"

export const conclusionColors = ["#E2EAEF", "#85A7BD", "#2F698F", "#05355E"]

export const preconclusionColors = {
  [preconclusionStatuses["Potentially reportable"]]: R.last(conclusionColors),
  [preconclusionStatuses["Not reportable"]]: R.head(conclusionColors),
}

export const indexColorsByStatuses = statuses =>
  statuses
    ? {
        ...R.zipObj(statuses, conclusionColors),
        [conclusionErrorStatus]: theme.color.error,
        ...preconclusionColors,
      }
    : {}

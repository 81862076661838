import React from "react"
import InputSubquestions from "inputs/InputSubquestions"
import { answerTypes } from "data/templates/answers"

const Subquestions = ({
  question,
  ops,
  canBeMoved,
  readOnly,
  questionType,
}) => {
  if (questionType !== answerTypes.custom.value) return null

  return (
    <InputSubquestions
      name="subQuestions"
      required
      readOnly={readOnly}
      borderless={readOnly}
      allowTextChangeInReadOnly
      questionIndex={question.idx}
      questionId={question.id}
      canMove={canBeMoved}
      onMove={ops.onMove}
      moveIndex={ops.moveIndex}
      onMoveConfirm={ops.onMoveConfirm}
    />
  )
}

export default Subquestions

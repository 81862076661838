import * as R from "ramda"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"

export const getSubtypeValue = (subType, value) => {
  return value.find(x => x.subType === subType && !R.isNil(x.registryCatalogId))
}

export const getNewVal = (subType, question, value) => v => {
  if (R.isNil(v)) {
    return value.filter(
      x => !(x.templateQuestionId === question.id && x.subType === subType),
    )
  }

  const i = value.findIndex(
    x => x.templateQuestionId === question.id && x.subType === subType,
  )

  if (i === -1) {
    return value.concat({
      templateQuestionId: question.id,
      subType,
      registryCatalogId: v.id,
      name: v.name,
      value: v.id,
    })
  }

  return R.over(
    R.lensPath([i]),
    R.mergeLeft({
      registryCatalogId: v.id,
      name: v.name,
      value: v.id,
    }),
    value,
  )
}

export const getOptions = (type, catalogs) =>
  (type ? catalogs[type === "E" ? "entities" : "individuals"] : []).map(x => ({
    ...x,
    value: x.id,
    name: x.fieldName,
    typeLabel: (
      <Box>
        <P lightgray fs={14} talign="end">
          {R.path(["fieldType"], x)}
        </P>
        {R.path(["allowsMultiple"], x) && (
          <P lightgray fs={14}>
            multiple records
          </P>
        )}
      </Box>
    ),
  }))

import React from "react"
import CaretIcon from "icons/Caret"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"
import InputSubquestions from "inputs/InputSubquestions"
import { answerTypes } from "data/templates/answers"
import * as R from "ramda"

const Subquestions = ({ question, ops, canBeMoved, questionType, values }) => {
  const { isOpen, toggle } = useOpen(false)
  const hasSubquestions = R.pathOr([], ["subQuestions"], values).length > 0

  if (questionType !== answerTypes.custom.value) return null

  if (!hasSubquestions) return <P>{localize("noSubquestions")}</P>

  return (
    <>
      <Box mb={isOpen ? 20 : 0} onClick={toggle} flex>
        <P red link>
          {localize("subquestions")}
        </P>
        <IconWrap static rotate={+isOpen}>
          <CaretIcon />
        </IconWrap>
      </Box>
      {isOpen && (
        <InputSubquestions
          value={values.subQuestions}
          required
          readOnly
          borderless
          questionIndex={question.idx}
          questionId={question.id}
          canMove={canBeMoved}
          onMove={ops.onMove}
          moveIndex={ops.moveIndex}
          onMoveConfirm={ops.onMoveConfirm}
        />
      )}
    </>
  )
}

export default Subquestions

import React from "react"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { StarPosition } from "./styled"

const Required = ({ children }) => (
  <Box relative>
    {children}
    <StarPosition>
      <P required />
    </StarPosition>
  </Box>
)

export default Required

import React from "react"
import { ToggleMarkWrap } from "./styled"
import Plus from "./Plus"
import Minus from "./Minus"

const ToggleMark = ({ isOpen, hide, onClick }) => (
  <ToggleMarkWrap onClick={onClick} hide={hide}>
    {isOpen ? <Minus /> : <Plus />}
  </ToggleMarkWrap>
)

export default ToggleMark

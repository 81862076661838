import React from "react"
import Question from "./Question"
import Section from "./Section"
import * as R from "ramda"

const Node = ({ node, indices, ...rest }) => {
  if (node.nodeType === "question") {
    return (
      <Question
        {...rest}
        node={node}
        indices={indices}
        mt={R.last(indices) === 0 ? 0 : 20}
      />
    )
  }

  return (
    <Section node={node} indices={indices}>
      {node.children.map((x, xi) => (
        <Node {...rest} key={x.id} node={x} indices={indices.concat(xi)} />
      ))}
    </Section>
  )
}

export default Node

import React from "react"
import * as R from "ramda"
import Panel from "components/Panel"
import { Hr, P } from "styledComponents/Typography"
import withUrls from "hoc/withUrls"
import { Box, Spread } from "styledComponents/Containers"
import Link from "components/Link"
import Ribbons from "components/Ribbons"
import useOpen from "hooks/useOpen"
import Checkbox from "inputs/Checkbox"
import { RadioCircle } from "inputs/Radio/RadioItem/styled"
import withOpen from "hoc/withOpen"
import withData from "hoc/withData"
import { assessmentLibraryRequest } from "data/assessments/api"
import { ActiveIcon } from "styledComponents/Icons"
import Columns from "components/Columns"
import LabelValue from "components/LabelValue"
import { formatDate } from "data/units/date/formatters"
import { localize } from "locale"
import Language from "components/Language"
import Country from "components/Country"
import CaretIcon from "icons/Caret"
import { getHandleToggle } from "./helpers"
import Loader from "components/Loader"

const Template = ({
  template,
  value,
  onChange,
  isOpen,
  toggle,
  libraryItems,
  countries,
  loading,
}) => {
  const { isOpen: isDetailsOpen, toggle: toggleDetails } = useOpen()

  const handleToggle = getHandleToggle(value, onChange, countries)

  return (
    <Panel mb={20} noPadding data-id={`template:${template.name}`}>
      <Box p={20}>
        <Spread mb={20}>
          <P fs={18} bold mb={15}>
            {template.name}
          </P>
          <Box flex acenter>
            <P nowrap nobreak mr={10}>
              {template.countryCode}
            </P>
            <ActiveIcon onClick={toggleDetails} rotate={isDetailsOpen}>
              <CaretIcon />
            </ActiveIcon>
          </Box>
        </Spread>
        {isDetailsOpen && (
          <>
            <Columns mb={15}>
              <LabelValue
                label="Published"
                value={formatDate(template.publishedAtUtc)}
              />
              <LabelValue
                label="Applicable time from"
                value={
                  formatDate(template.applicableTimePeriodStart) ||
                  localize("n/a")
                }
              />
              <LabelValue
                label="to"
                value={
                  formatDate(template.applicableTimePeriodEnd) ||
                  localize("n/a")
                }
              />
            </Columns>
            <Columns>
              <LabelValue
                label="Language"
                value={<Language code={template.languageCode} />}
              />
              <LabelValue
                label="Country"
                value={<Country code={template.countryCode} />}
              />
              <LabelValue
                label="Version"
                value={template.schemas
                  .map(x => `${x.reportType} v${x.version}`)
                  .join(", ")}
              />
            </Columns>
            <Hr />
            <P mb={20}>{template.description}</P>
          </>
        )}
        <Box flex acenter>
          <Checkbox
            text="Use this template"
            flip
            mr={20}
            value={value.find(x => x.templateId === template.id)}
            onChange={() => handleToggle(template)}
          />
          {template.containsPublishedLibraryItems && (
            <Link ul onClick={toggle}>
              {`${isOpen ? "Hide" : "Show"} related library items`}
            </Link>
          )}
        </Box>
      </Box>
      {isOpen && (
        <Box bg="#F2F2F2" p={20}>
          <P bold gray>
            Related library items
          </P>
          {loading ? (
            <Loader />
          ) : (
            (libraryItems || []).map(x => (
              <Box
                key={x.id}
                flex
                onClick={() => handleToggle(template, x)}
                mt={10}
                pointer
                data-id={`libItem:${x.name}`}
              >
                <RadioCircle
                  active={value.find(
                    y =>
                      y.templateId === template.id && y.libraryItemId === x.id,
                  )}
                />
                <Box>
                  <P mb={5}>{x.name}</P>
                  {x.description && <P mb={5}>{x.description}</P>}
                  <Ribbons inline light ribbons={x.tags.map(x => x.name)} />
                </Box>
              </Box>
            ))
          )}
        </Box>
      )}
    </Panel>
  )
}

export default R.compose(
  withUrls,
  withOpen,
  withData(p => assessmentLibraryRequest.listTemplate(p.template.id), {
    refetchParams: p => [p.isOpen],
    skip: p => !p.isOpen,
    dataKey: "libraryItems",
    noLoader: true,
  }),
)(Template)

import React from "react"

export default () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <path d="M4,20 L20,4" id="Path" stroke="#FFFFFF" strokeWidth="3" />
      <path
        d="M4,20 L20,4"
        stroke="#FFFFFF"
        strokeWidth="3"
        transform="translate(12.000000, 12.000000) scale(-1, 1) translate(-12.000000, -12.000000) "
      />
    </g>
  </svg>
)

import React from "react"

export default () => (
  <svg width="9" height="14">
    <path
      d="M7.303 5.232L2-.07.232 1.697 5.536 7 .232 12.303 2 14.071 9.071 7 7.303 5.232z"
      fillRule="evenodd"
    />
  </svg>
)

import React from "react"
import Panel from "components/Panel"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import ReportForm from "./ReportForm"
import { preconclusionStatuses } from "data/conclusions/statuses"

const Decisions = ({
  reporting,
  assessmentId,
  refetch,
  refetchReporting,
  reportingFetchCount,
  suburls,
  isPreview,
  canEdit,
  isLibrary,
  isValid,
  template,
  conclusion,
  isPreassessment,
}) => {
  if (
    isPreassessment &&
    conclusion?.status === preconclusionStatuses["Not reportable"]
  ) {
    return (
      <Panel mt={20}>
        <P>
          Conclusion type is not reportable. You can submit the pre-assessment
          now.
        </P>
      </Panel>
    )
  }

  if (
    isPreassessment &&
    conclusion?.status === preconclusionStatuses["Potentially reportable"]
  ) {
    return (
      <Panel mt={20}>
        <P>
          Conclusion type is potentially reportable. You can submit the
          pre-assessment now.
        </P>
      </Panel>
    )
  }

  if (isPreassessment) {
    return null
  }

  if (!reporting) {
    return (
      <Panel mt={20}>
        <P>{localize("reportingNotSupportedForYourCountry")}</P>
      </Panel>
    )
  }

  if (!reporting.isReportingAllowed && !isLibrary) {
    return (
      <Panel mt={20}>
        <P>{localize("reportingNotRequiredForYourConclusionType")}</P>
      </Panel>
    )
  }

  if (isValid && !reporting.isReportingAllowed && isLibrary) {
    return (
      <Panel mt={20}>
        <P>{localize("reportingNotRequiredForYourConclusionType")}</P>
      </Panel>
    )
  }

  return (
    <Panel mt={20}>
      {!isLibrary && (
        <P mb={10}>
          {localize(
            isPreview
              ? "reportingIsRequiredForYourConclusionType"
              : "reportingIsRequiredForYourConclusionTypeSelectNextStep",
          )}
        </P>
      )}
      {!isPreview && (
        <ReportForm
          key={reportingFetchCount}
          canEdit={canEdit}
          reporting={reporting}
          refetch={refetch}
          refetchReporting={refetchReporting}
          assessmentId={assessmentId}
          suburls={suburls}
          isLibrary={isLibrary}
          libPreview={
            isLibrary &&
            (!isValid || (!isValid && !reporting.isReportingAllowed))
          }
          template={template}
        />
      )}
    </Panel>
  )
}

export default Decisions

import React from "react"
import { auth$ } from "data/auth/rx"
import { authRequest } from "data/auth/api"
import withData from "hoc/withData"
import { withRouter } from "react-router"
import Routes from "./Routes"
import Toasts from "./Toasts"
import { parallel } from "common/rxjs"
import { AppContext } from "data/auth/context"
import withState from "hoc/withState"
import withSelectTenant from "hoc/withSelectTenant"
import * as R from "ramda"
import { parseSearch } from "common/router"
import ErrorPage from "pages/ErrorPage"
import { getUrls, UrlsContext } from "pages/urls"
import { getRoutes } from "pages/urls/routes"
import { retrieve } from "common/storage"

const Content = ({
  profile,
  modules,
  tenants,
  loggedIn,
  submit,
  changing,
  setChanging,
}) => {
  const parsed = parseSearch(window.location.search)

  if (changing) return null

  const noProfile = loggedIn && !profile
  const noModule = profile && modules && modules.modules.length === 0

  const showError = noProfile || noModule

  if (
    loggedIn &&
    parsed.tid &&
    profile.tenantId !== parsed.tid &&
    (tenants || []).find(x => x.id === parsed.tid)
  ) {
    setChanging(true)
    submit(parsed.tid)

    return null
  }

  const v = {
    profile,
    modules,
    tenants,
    tenant: (tenants || []).find(x => x.id === profile.tenantId),
  }

  const routes = getRoutes(R.path(["tenantId"], profile))
  const urls = getUrls(routes)

  return (
    <AppContext.Provider value={v}>
      <UrlsContext.Provider value={{ urls, routes }}>
        {showError ? (
          <ErrorPage noModule loggedIn={loggedIn} />
        ) : (
          <Routes loggedIn={loggedIn} urls={urls} routes={routes} {...v} />
        )}
        <Toasts />
      </UrlsContext.Provider>
    </AppContext.Provider>
  )
}

export default R.compose(
  withRouter,
  withState("loggedIn", "setLoggedIn", !!retrieve("accessToken")),
  C => p => {
    React.useEffect(() => {
      const sub = auth$.subscribe(val => {
        const newVal = !!val.accessToken
        p.setLoggedIn(newVal)

        return () => sub.unsubscribe()
      })
    }, []) // eslint-disable-line
    return <C {...p} loggedIn={p.loggedIn} />
  },
  withData(
    () =>
      parallel(
        authRequest.profile(),
        authRequest.modules(),
        authRequest.tenants(),
      ),
    {
      dataKeys: ["profile", "modules", "tenants"],
      skip: p => !p.loggedIn,
      refetchParams: p => [p.loggedIn],
    },
  ),
  withState("changing", "setChanging", false),
  withSelectTenant(
    p => {
      p.refetchData()
      p.setChanging(false)
    },
    { noRedir: true },
  ),
)(Content)

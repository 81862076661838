import React from "react"
import {
  assessmentQuestionRequest,
  assessmentLibraryQuestionRequest,
} from "data/assessments/api"
import { preassessmentQuestionRequest } from "data/preassessments/api"
import withModal from "hoc/withModal"
import withProps from "hoc/withProps"
import { localize } from "locale"
import Comments from "components/Comments"
import * as R from "ramda"

const CommentsModal = ({
  assessmentId,
  questionId,
  canEdit,
  questionRequestOrDefault,
}) => {
  const [openId, setOpenId] = React.useState()

  return (
    <Comments
      readOnly={!canEdit}
      addCommentId="add"
      setOpenId={setOpenId}
      openId={openId}
      noAttachments
      requests={{
        comments: questionRequestOrDefault.comments(assessmentId, questionId),
        addComment: v =>
          questionRequestOrDefault.addComment(assessmentId, questionId, v),
        deleteComment: id =>
          questionRequestOrDefault.deleteComment(assessmentId, questionId, id),
        editComment: (id, v) =>
          questionRequestOrDefault.editComment(assessmentId, questionId, id, v),
      }}
    />
  )
}

export default R.compose(
  withModal(p => `${localize("question")} ${p.index}: ${localize("comments")}`),
  withProps(p => {
    const getQuestionRequest = () => {
      if (p.isLibrary) return assessmentLibraryQuestionRequest
      if (p.isPreassessment) return preassessmentQuestionRequest

      return assessmentQuestionRequest
    }

    return {
      questionRequestOrDefault: getQuestionRequest(),
    }
  }),
)(CommentsModal)

import React from "react"
import * as R from "ramda"

const withState = (key, setKey, defaultValue) => C => p => {
  const [v, setV] = React.useState(
    R.type(defaultValue) === "Function" ? defaultValue(p) : defaultValue,
  )

  return <C {...p} {...{ [key]: v, [setKey]: setV }} />
}

export default withState

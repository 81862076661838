import dayjs from "dayjs"
import { localize } from "locale"

export const formatTime = (d, seconds = false) => {
  if (!d) return undefined

  return dayjs(d).format("HH:mm" + (seconds ? ":ss" : ""))
}

export const formatDate = d => {
  if (!d) return undefined

  const dt = dayjs(d)

  return `${dt.format("D")} ${
    localize("monthsArray")[dt.format("M") - 1]
  } ${dt.format("YYYY")}`
}

export const formatDateTime = d => {
  if (!d) return undefined

  return `${formatDate(d)}, ${formatTime(d, true)}`
}

export const formatDateRange = (a, b) => {
  const x = formatDate(a)
  const y = formatDate(b)

  if (a && b) return `${x} - ${y}`

  if (a && !b) return `${localize("from")} ${x}`
  if (!a && b) return `${localize("to")} ${y}`

  return ""
}

export const leftPadDay = d => (d < 10 ? `0${d}` : `${d}`)

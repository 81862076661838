import Content from "./Content"
import GlobalStyle from "./GlobalStyle"
import { Router } from "react-router-dom"
import { Normalize } from "styled-normalize"
import ErrorBoundary from "components/ErrorBoundary"
import { withAITracking } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "appInsights"
import { history } from "./history"
import CSP from "./CSP"

const AppComponent = () => (
  <>
    <CSP />
    <Normalize />
    <GlobalStyle />
    <Router history={history}>
      <ErrorBoundary>
        <Content />
      </ErrorBoundary>
    </Router>
  </>
)

export default withAITracking(reactPlugin, AppComponent)

import React from "react"
import Avatar from "components/Avatar"
import Columns from "components/Columns"
import CrossIcon from "icons/Cross"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import { UserListItemRight, UserListItemWrap } from "./styled"
import useMedia from "hooks/useMedia"

const UserListItem = ({
  data,
  optionToAvatar,
  optionToTitle,
  optionToSubtitle,
  onRemove,
  customComponent,
  readOnly,
}) => {
  const { isMobile } = useMedia()

  return (
    <UserListItemWrap readOnly={readOnly}>
      <Columns
        gap={0}
        widths={["auto", "auto", "auto"]}
        align="center"
        noCols={isMobile}
      >
        <Box flex>
          {optionToAvatar && <Avatar text={optionToAvatar(data)} mr />}
          <div>
            <P>{optionToTitle(data)}</P>
            {optionToSubtitle && (
              <P gray fs={12}>
                {optionToSubtitle(data)}
              </P>
            )}
          </div>
        </Box>
        {isMobile && customComponent}
      </Columns>
      <UserListItemRight>
        {!isMobile && customComponent}
        {onRemove && (
          <IconWrap onClick={onRemove}>
            <CrossIcon />
          </IconWrap>
        )}
      </UserListItemRight>
    </UserListItemWrap>
  )
}

export default UserListItem

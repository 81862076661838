import styled, { css } from "styled-components/macro"
import theme from "theme"

export const TableWrap = styled.div`
  background: #fff;
  width: 100%;
  overflow-y: hidden;
  overflow-x: auto;

  ${p =>
    !p.borderless &&
    css`
      border: 1px solid #d8d8d8;
      box-shadow: 0 1px 3px 0 ${theme.color.shadow};
    `}
`

export const StyledTable = styled.table`
  width: 100%;
  border-spacing: 0;
  border-collapse: collapse;

  td {
    padding: 11px 20px;
    vertical-align: middle;
    border: 0;
    ${p =>
      !p.noRowBorder &&
      css`
        border-top: 1px solid #d8d8d8;
        border-bottom: 1px solid #d8d8d8;
      `};
  }
  ${p =>
    p.grid &&
    css`
      th,
      td {
        border: 1px solid #d8d8d8;
      }
    `}
`
export const StyledRow = styled.tr`
  ${p =>
    p.striped &&
    css`
      &:nth-child(2n) {
        background: #f2f2f2;
      }
    `};

  ${p =>
    p.highlight &&
    css`
      background: #f2f2f2;
    `};
`

import * as R from "ramda"

export const filterNavLinks = (routes, modules) =>
  routes.filter(r => {
    if (r.submodules) {
      return (
        r.navLabel &&
        !r.hideFromNav &&
        R.values(r.submodules).some(x => modules.includes(x.module))
      )
    }

    return r.navLabel && !r.hideFromNav && modules.includes(r.module)
  })

export const linksToOptions = (routes, modules) =>
  routes.map(x => {
    const ops = x.submodules ? R.values(x.submodules) : []
    return {
      exact: R.path(["props", "exact"], x),
      path: x.path,
      value: x.navLabel,
      name: x.navLabel,
      options: ops
        .filter(y => modules.includes(y.module) && !y.hideFromNav)
        .map(y => ({
          name: y.navLabel,
          value: y.navLabel,
          path: y.path,
        })),
    }
  })

export const getCurrentOption = (routes, match) => {
  const activeOption = routes.find(o => {
    if (o.path === match.path) return true
    if (!o.submodules) return false

    return R.values(o.submodules).find(y => y.path === match.path)
  })

  return activeOption
}

export const getCurrentSuboption = (currentOption, match) =>
  R.pipe(
    R.pathOr({}, ["submodules"]),
    R.values,
    R.find(x => x.path === match.path),
  )(currentOption)

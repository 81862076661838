import InvolvedParty from "./InvolvedParty"
import * as R from "ramda"
import IdSearch from "inputs/IdSearch"
import Checkbox from "inputs/Checkbox"
import AutocompleteSelectCountry from "inputs/AutocompleteSelectCountry"
import { localize } from "locale"

export const config = () => ({
  rowComponent: InvolvedParty,
  idKey: "id",
  columns: [
    {
      label: "Involved party",
      sortBy: R.propOr("", "name"),
    },
    {
      label: "Country",
      sortBy: R.propOr("", "countryCode"),
    },
    {
      label: "Role",
      sortBy: R.propOr("", "role"),
    },
    {
      label: "Reporting responsibility",
      sortBy: R.propOr("", "reportingResponsibility"),
    },
    {
      label: "Linked objects",
    },
    {
      label: "",
    },
  ],
  filters: [
    {
      name: "overdue",
      render: (value, onChange) => (
        <Checkbox
          value={value}
          onChange={onChange}
          text={localize("showOverdue")}
          flip
        />
      ),
      filter: R.always(true),
    },
    {
      name: "id",
      render: (value, onChange) => (
        <IdSearch
          small
          value={value}
          onChange={onChange}
          text={`You may search assessment(s) via "Internal arrangement ID", country specific report information (e.g. Entity ID, Entity name, etc.) if available, and IDs received from the tax authority (arrangement ID, disclosure ID, etc.) if available, through exact match.”`}
        />
      ),
      filter: R.always(true),
    },
    {
      name: "country",
      render: (value, onChange) => (
        <AutocompleteSelectCountry
          value={value}
          onChange={onChange}
          small
          placeholder="All countries"
        />
      ),
      filter: R.always(true),
    },
  ],
  search: R.always(true),
})

import React from "react"
import Avatar from "components/Avatar"
import ContextMenu from "components/ContextMenu"
import AriaMenu from "components/AriaMenu"
import OverlayWrapper from "components/OverlayWrapper"
import config from "config"
import { formatUserName, formatInitials } from "data/user/formatters"
import { localizeRole } from "data/user/roles"
import LogoutIcon from "icons/appkit/outline-logout"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"

const AvatarMenu = ({ profile, logout }) => {
  const { isOpen, close, toggle } = useOpen()
  return (
    <OverlayWrapper isOpen={isOpen} close={close}>
      <Box flex acenter onClick={toggle} pointer id="avatar">
        <Avatar text={formatInitials(profile)} />
        <Box ml={10}>
          <P bold fs={14} nowrap nobreak>
            {formatUserName(profile)}
          </P>
          <P fs={11} nowrap nobreak>
            {localizeRole(profile.role)}
          </P>
          <P fs={11} gray nowrap nobreak title={config.buildNumberFull}>
            {localize("build")}: {config.buildNumber}
          </P>
        </Box>
      </Box>
      <ContextMenu isOpen={isOpen}>
        <AriaMenu
          options={[
            {
              onClick: logout,
              name: localize("logout"),
              icon: LogoutIcon,
            },
          ]}
        />
      </ContextMenu>
    </OverlayWrapper>
  )
}

export default AvatarMenu

import * as R from "ramda"

export const getHandleChangeCountry =
  ({ country, setCountry, setValue, templates, countries }) =>
  v => {
    const newCountries = R.difference(v, country)

    // we added a country, so we have to automatically select template if there is only one with no lib items
    if (newCountries.length > 0) {
      const [country] = newCountries
      const countryTemplates = templates.filter(x => x.countryCode === country)
      const [template] = countryTemplates

      // should be automatically selected since there is only one choice
      if (
        countryTemplates.length === 1 &&
        !template.containsPublishedLibraryItems
      ) {
        setValue(
          R.concat([
            {
              templateId: template.id,
              countryName: countries.find(
                x => x.countryCode === template.countryCode,
              ).countryName,
              countryCode: template.countryCode,
            },
          ]),
        )
      }
    }

    const lostCountries = R.difference(country, v)

    // we removed a country, so we have to clear template with such country
    if (lostCountries.length > 0) {
      const [country] = lostCountries
      const countryName = countries.find(
        x => x.countryCode === country,
      ).countryName

      setValue(R.filter(x => x.countryName !== countryName))
    }

    return setCountry(v)
  }

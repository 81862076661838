import styled, { css } from "styled-components/macro"

export const InputWrap = styled.div`
  background-color: #fff;
  border: 1px solid #d8d8d8;
  height: 42px;
  align-items: center;
  display: flex;
  position: relative;
  padding: 0 0 0 15px;

  ${p =>
    p.empty &&
    css`
      color: #999;
    `}
`

import React from "react"
import { Box } from "styledComponents/Containers"
import { Hr } from "styledComponents/Typography"
import Panel from "components/Panel"
import Columns from "components/Columns"
import { localize } from "locale"
import { formatDate } from "data/units/date/formatters"
import LabelValue from "components/LabelValue"
import Comments from "components/Comments"
import { reportCommentsRequests } from "data/report/api"
import dayjs from "dayjs"
import * as R from "ramda"

const MainPanel = ({ report, openId, setOpenId }) => {
  const nearestDueDate = R.head(
    report.massReportConclusions
      .map(x => x.dueDateUtc)
      .filter(x => x)
      .map(x => dayjs(x))
      .sort((a, b) => a.diff(b)),
  )

  return (
    <Panel>
      <Columns>
        <Box>
          <LabelValue
            label={localize("createdOn")}
            value={formatDate(report.createdAtUtc)}
          />
        </Box>
        <Box>
          <LabelValue
            label={localize("includedConclusions")}
            value={report.conclusionsCount}
          />
        </Box>
        <Box>
          <LabelValue
            label={localize("createdBy")}
            value={report.createdByUser}
          />
        </Box>
        <Box>
          <LabelValue
            label={localize("nearestDueDate")}
            value={nearestDueDate ? formatDate(nearestDueDate) : "N/A"}
          />
        </Box>
        <Box>
          <LabelValue label={localize("country")} value={report.countryName} />
        </Box>
        <Box>
          <LabelValue
            label={localize("filingEntityId")}
            value={report.entityId}
          />
        </Box>
        <Box>
          <LabelValue
            label={localize("filingEntityName")}
            value={report.entityName}
          />
        </Box>
      </Columns>
      <Hr />
      <Comments
        addCommentId="add"
        setOpenId={setOpenId}
        openId={openId}
        requests={reportCommentsRequests(report.id)}
        long
      />
    </Panel>
  )
}

export default MainPanel

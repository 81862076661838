import React from "react"
import ConclusionForm from "./ConclusionForm"
import Section from "../Section"
import { localize } from "locale"

const Conclusions = ({ definitions, ...rest }) => (
  <Section title={localize("conclusions")}>
    {definitions.map((d, di) => (
      <ConclusionForm key={d.id} definition={d} {...rest} first={di === 0} />
    ))}
  </Section>
)

export default Conclusions

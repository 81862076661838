import React from "react"
import { uploadRequest } from "data/templates/api"
import Textarea from "inputs/Textarea"
import CompareToolLinks from "inputs/CompareToolLinks"
import { localize } from "locale"
import Filepicker from "inputs/Filepicker"

const SupportiveInformation = ({ templateId, isEdited, template }) => (
  <>
    <Textarea
      name="supportiveInfo"
      label={localize("supportiveInformation")}
      placeholder={localize("enterSupportiveInformation")}
      readOnly={!isEdited}
      borderless={!isEdited}
      noEmptyError
    />
    <CompareToolLinks
      name="links"
      label={localize("links")}
      readOnly={!isEdited}
      borderless={!isEdited}
      template={template}
      noEmptyError
    />
    <Filepicker
      name="attachments"
      label={localize("attachFiles")}
      upload={uploadRequest.create(templateId)}
      download={uploadRequest.download(templateId)}
    />
  </>
)

export default SupportiveInformation

import React from "react"
import withModal from "hoc/withModal"
import * as R from "ramda"
import { P } from "styledComponents/Typography"

const InfoModal = ({ text, body }) => (
  <>
    {text && <P>{text}</P>}
    {body}
  </>
)

export default withModal(R.prop("title"))(InfoModal)

import React from "react"
import { IconWrap } from "styledComponents/Icons"
import WarningIcon from "icons/appkit/outline-alert"
import InfoModal from "components/InfoModal"
import { localize } from "locale"
import useOpen from "hooks/useOpen"

const TemplateEditWarning = ({ disabled }) => {
  const { isOpen, close, toggle } = useOpen()

  return (
    <>
      <IconWrap error onClick={toggle} disabled={disabled}>
        <WarningIcon />
      </IconWrap>
      {isOpen && (
        <InfoModal
          close={close}
          title={localize("templateEdit")}
          text={localize("templateEditWarning")}
        />
      )}
    </>
  )
}

export default TemplateEditWarning

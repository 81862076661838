import React from "react"
import { EditBarWrap } from "./styled"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import EditBarForm from "./EditBarForm"
import EditBarIcons from "./EditBarIcons"
import useOpen from "hooks/useOpen"

const EditBar = ({
  data,
  type,
  templateId,
  template,
  title,
  ops,
  questions,
}) => {
  const { isOpen, open, close } = useOpen()

  const editing = ops.editIndex && ops.editIndex.nodeId === data.id

  if (editing && type === "question") return null

  return (
    <>
      <EditBarWrap type={type}>
        {editing ? (
          <EditBarForm
            name={data.data.name}
            cancelEdit={() => ops.onEdit(undefined)}
            templateId={templateId}
            editIndex={ops.editIndex}
            onSuccess={ops.onUpdate}
          />
        ) : (
          <EditBarIcons
            title={data.data.name || title}
            onDelete={open}
            onEdit={() =>
              ops.onEdit({
                nodeId: data.id,
                referencedId: data.referencedId,
              })
            }
            data={data}
            questions={questions}
            disabled={!!ops.editIndex}
            onMove={() =>
              ops.onMove({
                ...ops,
                nodeId: data.id,
                referencedId: data.referencedId,
                type,
              })
            }
            onCancelMove={() => ops.onMove(undefined)}
            moving={!!ops.moveIndex}
            movingThis={
              ops.moveIndex && ops.moveIndex.referencedId === data.referencedId
            }
            onCopy={() =>
              ops.onCopy({
                ...ops,
                nodeId: data.id,
                hasSubsection: (data.children || []).some(
                  x => x.nodeType === "section",
                ),
                referencedId: data.referencedId,
                type,
              })
            }
            onCancelCopy={() => ops.onCopy(undefined)}
            copying={!!ops.copyIndex}
            copyingThis={
              ops.copyIndex && ops.copyIndex.referencedId === data.referencedId
            }
            canEdit={ops.canEdit}
            type={type}
            template={template}
          />
        )}
      </EditBarWrap>
      {isOpen && (
        <ConfirmDeleteModal
          close={close}
          data={data}
          type={type}
          templateId={templateId}
          onSuccess={ops.onUpdate}
        />
      )}
    </>
  )
}

export default EditBar

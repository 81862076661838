import * as R from "ramda"

export const addImplicitBrackets = items => {
  if (R.type(items) === "Object" || items.length === 0) return items

  const arr = items.slice(1)
  if (
    arr.length > 1 &&
    arr.some(x => x.operationBefore === "and") &&
    arr.some(x => x.operationBefore === "or")
  ) {
    const wrapped = items.reduce(
      (acc, cur) => {
        if (cur.operationBefore === "and") {
          return [
            ...acc.slice(0, -1),
            {
              ...R.last(acc),
              item: R.last(acc).item.concat(cur),
            },
          ]
        }

        return acc.concat({
          operationBefore: cur.operationBefore,
          item: [cur],
        })
      },
      [{ operationBefore: items[0].operationBefore, item: [] }],
    )
    const res = wrapped.map(x =>
      x.item.length === 1
        ? { ...x, item: x.item[0].item }
        : { ...x, item: addImplicitBrackets(x.item) },
    )
    return res
  }

  return items.map(x => ({ ...x, item: addImplicitBrackets(x.item) }))
}

export const hasMixedOperators = (items, n = 0) => {
  if (!items || R.type(items) === "Object") return false

  const arr = items.slice(1)
  if (
    arr.length > 1 &&
    arr.some(x => x.operationBefore === "and") &&
    arr.some(x => x.operationBefore === "or" && R.type(x.item) === "Object")
  ) {
    return true
  }

  return items.some(x => hasMixedOperators(x.item, n + 1))
}

import React from "react"
import Button from "components/Button"
import Columns from "components/Columns"
import ModalButtons from "components/ModalButtons"
import Panel from "components/Panel"
import { caseRequest } from "data/cockpit/api"
import withForm from "hoc/withForm"
import Filepicker from "inputs/Filepicker"
import Select from "inputs/Select"
import Textarea from "inputs/Textarea"
import Textfield from "inputs/Textfield"
import Checkbox from "inputs/Checkbox"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { arrayRequired } from "validators/array"
import { stringRequired, strRequired } from "validators/string"
import AutocompleteList from "inputs/AutocompleteList"
import Datepicker from "inputs/Datepicker"
import { assessmentCoworkersRequest } from "data/assessments/api"
import { formatInitialsFromName, formatUserName } from "data/user/formatters"
import { caseStatuses } from "data/cockpit/statuses"
import { roles } from "data/cockpit/roles"
import { caseSuburls } from "../suburls"
import { folderSuburls } from "pages/FolderCreatePage/suburls"
import useOpen from "hooks/useOpen"
import Loader from "components/Loader"
import InfoModal from "components/InfoModal"
import { Box } from "styledComponents/Containers"
import withData from "hoc/withData"

const Settings = ({
  case_,
  urls,
  folderId,
  match,
  values,
  isSubmitting,
  suburls,
  setFieldValue,
  data,
  errors,
}) => {
  const readOnly = case_ && !case_.canEdit
  const isNew = !case_
  const showDueDateWarning =
    !isNew && case_.estimatedDueDate !== values.estimatedDueDate
  const showCaseDescriptionWarning =
    !isNew &&
    (case_.description !== values.description ||
      case_.attachments !== values.attachments) &&
    data
  const showWarning = showDueDateWarning || showCaseDescriptionWarning

  const {
    isOpen: dueDateWarningOpen,
    open: openDueDateWarning,
    close: closeDueDateWarning,
  } = useOpen()
  const {
    isOpen: preassessmentWarningOpen,
    open: openPreassessmentWarning,
    close: closePreassessmentWarning,
  } = useOpen()
  const {
    isOpen: caseDescriptionWarningOpen,
    open: openCaseDescriptionWarning,
    close: closeCaseDescriptionWarning,
  } = useOpen()

  return (
    <Panel>
      <Textfield
        name="name"
        label="Case name"
        placeholder={localize("enterName")}
        required
        readOnly={readOnly}
        borderless={readOnly}
      />
      <Columns>
        <Select
          label="Case status"
          required
          name="status"
          options={R.values(caseStatuses)}
          byValue
          readOnly={readOnly}
          borderless={readOnly}
        />
        <Datepicker
          name="createdAtUtc"
          label="Creation date"
          readOnly
          borderless
        />
      </Columns>
      <Columns>
        <Datepicker
          name="estimatedDueDate"
          label="Case estimated due date"
          placeholder={readOnly ? "" : localize("selectDate")}
          readOnly={readOnly}
          borderless={readOnly}
          required
        />
        <Datepicker
          name="estimatedReportingDueDate"
          label="Case estimated reporting date"
          placeholder={readOnly ? "" : localize("selectDate")}
          readOnly={readOnly}
          borderless={readOnly}
        />
      </Columns>
      <Textarea
        required
        name="description"
        label="Case description"
        placeholder="Enter short description of the case"
        readOnly={readOnly}
        borderless={readOnly}
      />
      <Filepicker
        name="attachments"
        upload={
          case_
            ? caseRequest.uploadExisting(
                case_.breadcrumbs.id,
                case_.breadcrumbs.case.id,
              )
            : caseRequest.uploadNew(folderId)
        }
        download={
          case_ &&
          caseRequest.downloadFile(
            case_.breadcrumbs.id,
            case_.breadcrumbs.case.id,
          )
        }
        readOnly={readOnly}
      />
      <Box mb={20}>
        <Checkbox
          text="Pre-Assessment"
          label="Additional settings"
          value={values.isPreAssessmentAllowed}
          onChange={v => {
            if (case_?.hasActivePreAssessment) {
              return openPreassessmentWarning()
            }
            setFieldValue("isPreAssessmentAllowed", v)
          }}
          readOnly={readOnly}
        />
      </Box>
      <AutocompleteList
        name="coworkers"
        label={localize("addCoworker")}
        request={assessmentCoworkersRequest.list}
        roles={R.values(roles)}
        noResultText={localize("noEmailInTenant")}
        mapOptions={R.map(x => ({
          ...x,
          value: x.id,
          name: x.email,
        }))}
        title="Assessment team"
        optionToAvatar={R.pipe(formatUserName, formatInitialsFromName)}
        optionToTitle={formatUserName}
        optionToSubtitle={R.prop("email")}
        readOnly={readOnly}
      />
      {!readOnly && (
        <ModalButtons>
          <Button
            secondary
            type="button"
            to={urls.folderCreate(
              folderId || match.params.id,
              folderSuburls.cases,
            )}
            text="Cancel"
          />
          <Button
            onClick={() => {
              if (R.values(errors).some(x => x)) return

              if (showDueDateWarning) {
                return openDueDateWarning()
              }
              if (showCaseDescriptionWarning) {
                return openCaseDescriptionWarning()
              }
            }}
            type={showWarning ? "button" : "submit"}
            text="Save"
          />
        </ModalButtons>
      )}
      {dueDateWarningOpen && (
        <InfoModal
          title="Due date(s) updated"
          text={`Due date(s) was updated and will be propagated to all "In progress" assessments linked under the case.`}
          close={closeDueDateWarning}
          body={
            <>
              {isSubmitting && <Loader center />}
              <ModalButtons buttonText="Do you want to proceed?">
                <Button
                  secondary
                  type="button"
                  onClick={closeDueDateWarning}
                  text="Cancel"
                />
                <Button
                  type="submit"
                  danger
                  text="Yes, proceed"
                  form="settings-form"
                />
              </ModalButtons>
            </>
          }
        />
      )}
      {caseDescriptionWarningOpen && (
        <InfoModal
          title="Warning"
          text={`The content of Case description field was updated and will be propagated to pre-assessment (if available) and all linked assessments that are "in progress" status. Linked objects in other statuses will not be impacted.`}
          close={closeCaseDescriptionWarning}
          body={
            <>
              {isSubmitting && <Loader center />}
              <ModalButtons buttonText="Do you want to save the updated Case description?">
                <Button
                  secondary
                  type="button"
                  onClick={closeCaseDescriptionWarning}
                  text="Cancel"
                />
                <Button
                  type="submit"
                  danger
                  text="Yes, proceed"
                  form="settings-form"
                />
              </ModalButtons>
            </>
          }
        />
      )}
      {preassessmentWarningOpen && (
        <InfoModal
          title="Pre-assessment already exists for the case"
          text={`To disable pre-assessment for the case, please first delete existing pre-assessment in the "Pre-assessement" tab.`}
          close={closePreassessmentWarning}
          body={
            <>
              {isSubmitting && <Loader center />}
              <ModalButtons buttonText="How do you want to proceed?">
                <Button
                  secondary
                  type="button"
                  onClick={closePreassessmentWarning}
                  text="Cancel"
                />
                <Button
                  to={suburls.preassessment.url}
                  danger
                  text="Go to Pre-assessment tab"
                />
              </ModalButtons>
            </>
          }
        />
      )}
    </Panel>
  )
}

export default R.compose(
  withUrls,
  withForm({
    formId: "settings-form",
    preventLoseProgress: true,
    mapProps: p => ({
      createdAtUtc: new Date(),
      status: "InProgress",
      ...p.case_,
      coworkers: p.case_
        ? R.pathOr([], ["case_", "coworkers"], p).map(y => ({
            id: y.userId,
            ...y,
          }))
        : [
            // i should be default coworkers
            {
              ...p.profile,
              permission: roles.editor.value,
            },
          ],
    }),
    schema: R.always({
      name: strRequired(100),
      description: strRequired(1000),
      coworkers: arrayRequired,
      estimatedDueDate: stringRequired,
    }),
    onSubmit: p => v => {
      const val = {
        ...v,
        coworkers: v.coworkers.map(x => ({
          ...x,
          userId: x.id,
        })),
      }
      return p.case_
        ? caseRequest.update(p.match.params.id, val)
        : caseRequest.create(p.folderId, val)
    },
    onSuccess: p => () => {
      if (p.case_) {
        p.refetchCase()
      }
    },
    redirect: (res, p) => {
      const suburl = p.values.isPreAssessmentAllowed
        ? caseSuburls.preassessment
        : caseSuburls.country

      const id = p.case_ ? p.match.params.id : res

      return p.urls.caseCreate(id, suburl)
    },
  }),
  withData(p => caseRequest.hasLinkedObjects(p.caseId, true), {
    skip: p => p.caseId === "new",
  }),
)(Settings)

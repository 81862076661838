import { get } from "common/request"
import * as R from "ramda"
import { map } from "rxjs"

export const languageListRequest = () =>
  get("/api/common/languages").pipe(
    map(
      R.over(
        R.lensPath(["data"]),
        R.map(x => ({
          ...x,
          name: x.englishName,
          value: x.code,
        })),
      ),
    ),
  )

export const countryListRequest = (
  forTenantOnly = false,
  forTemplateOnly = false,
) =>
  get(
    `/api/common/countries?lang=en&forTenantOnly=${forTenantOnly}&forTemplateOnly=${forTemplateOnly}`,
  )

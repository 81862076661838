import * as R from "ramda"

const withHandlers = getHandlers => C => p => {
  const handlers =
    R.type(getHandlers) === "Function" ? getHandlers() : getHandlers

  return <C {...p} {...R.map(f => f(p))(handlers)} />
}

export default withHandlers

import React from "react"
import { localize } from "locale"
import InputLogic from "inputs/InputLogic"

const Precondition = ({ question, questions, readOnly }) => (
  <InputLogic
    conditionText={localize("showQuestionIf")}
    name="precondition"
    readOnly={readOnly}
    previousQuestions={questions.slice(0, question.idx - 1)}
    noEmptyError
  />
)

export default Precondition

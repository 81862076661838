import React from "react"
import Button from "components/Button"
import Warning from "components/Warning"
import ModalButtons from "components/ModalButtons"
import withForm from "hoc/withForm"
import withState from "hoc/withState"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { reportOptions } from "data/reporting/options"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { boolOptional } from "validators/bool"
import { reportingRequest } from "data/reporting/api"
import { reportingLibraryRequest } from "data/reporting/api"
import {
  stringOptional,
  longStringOptional,
  stringRequired,
} from "validators/string"
import Radio from "inputs/Radio"
import InputNotify from "inputs/InputNotify"
import Datepicker from "inputs/Datepicker"
import { scrollToTop } from "common/scroll"
import DoReportFields from "./DoReportFields"
import DoNotReportFields from "./DoNotReportFields"
import { getDoReport, getInitTags } from "./helpers"
import { combineValidators } from "validators/helpers"
import errors from "validators/errors"

const ReportForm = ({
  reporting,
  canEdit,
  assessmentId,
  values,
  urls,
  tags,
  setTags,
  setFieldValue,
  isLibrary,
  libPreview,
  template,
}) => {
  const yes = (
    <DoReportFields
      reporting={reporting}
      canEdit={canEdit}
      tags={tags}
      setTags={setTags}
      values={values}
      setFieldValue={setFieldValue}
    />
  )

  const no = (
    <DoNotReportFields
      canEdit={canEdit}
      assessmentId={assessmentId}
      isLibrary={isLibrary}
    />
  )

  return (
    <>
      {!libPreview && (
        <>
          {R.isNil(reporting.doReport) && (
            <Warning text={localize("reportingIsNotSaved")} />
          )}
          <Radio
            options={R.values(reportOptions)}
            name="doReport"
            inline
            byValue
            readOnly={!canEdit}
            mb={30}
            noEmptyError
          />
          {values.doReport === reportOptions.report.value &&
            template.schemas.length === 0 && (
              <Box mb={20}>
                <Warning text="Warning: XML report won't be generated, because assessment is based on an unmapped template" />
              </Box>
            )}
          <P mb={10}>{localize("estimatedReportingDate")}</P>
          <Box flex>
            <Datepicker
              name="dueDate"
              placeholder={localize("selectDate")}
              readOnly={!canEdit}
              onChange={x => {
                if (R.isNil(x)) {
                  setFieldValue("notifyDaysBefore", null)
                }
              }}
            />
            <InputNotify
              name="notifyDaysBefore"
              ml={20}
              readOnly={!canEdit || isLibrary || !values.dueDate}
              gray={isLibrary}
            />
          </Box>
        </>
      )}
      {libPreview ? (
        <>
          {yes}
          {no}
        </>
      ) : (
        <>
          {values.doReport === reportOptions.report.value && yes}
          {values.doReport === reportOptions.doNotReport.value && no}
        </>
      )}
      {canEdit && (
        <ModalButtons>
          <Button
            secondary
            type="button"
            to={urls.assessments.local}
            text="Cancel"
          />
          <Button type="submit" text="Save" />
        </ModalButtons>
      )}
    </>
  )
}

export default R.compose(
  withUrls,
  withState("tags", "setTags", getInitTags),
  withForm({
    preventLoseProgress: true,
    mapProps: p => ({
      doReport: getDoReport(p.reporting),
      ...R.pipe(
        R.pathOr([], ["reporting", "reportIdentifiers"]),
        R.indexBy(R.prop("reportIdentifierDefinitionId")),
        R.map(R.prop("value")),
      )(p),
      ...R.omit(["doReport", "reportIdentifiers"], p.reporting),
    }),
    schema: (p, values) => ({
      doReport: boolOptional,
      terminateReason: combineValidators(longStringOptional, v => {
        if (values.doReport === reportOptions.report.value) return ""

        return stringRequired(v)
      }),
      ...R.pipe(
        R.pathOr([], ["reporting", "reportIdentifiers"]),
        R.indexBy(R.prop("reportIdentifierDefinitionId")),
        R.map(x =>
          combineValidators(stringOptional, v => {
            const errorText = x.validatorText || errors.required
            if (values.doReport === reportOptions.doNotReport.value) return ""

            if (!v) return x.isMandatory ? errorText : ""

            if (!x.validatorRegex) return ""

            return new RegExp(x.validatorRegex).test(v) ? "" : errorText
          }),
        ),
      )(p),
    }),
    onSubmit: p => v => {
      const val = R.omit(
        p.reporting.reportIdentifiers.map(
          R.prop("reportIdentifierDefinitionId"),
        ),
      )(v)
      const reportIdentifiers = R.pipe(
        R.map(R.pick(["reportIdentifierDefinitionId", "reportIdentifierId"])),
        R.map(x => {
          const reportIdentifierTag = p.tags[x.reportIdentifierDefinitionId]
          return {
            ...x,
            value: v[x.reportIdentifierDefinitionId],
            ...(reportIdentifierTag
              ? {
                  reportIdentifierTag,
                }
              : {}),
          }
        }),
      )(p.reporting.reportIdentifiers)

      const request = p.isLibrary
        ? reportingLibraryRequest.editReporting
        : reportingRequest.editReporting

      return request(p.assessmentId, {
        ...val,
        reportIdentifiers,
        doReport: v.doReport === reportOptions.report.value,
      })
    },
    onSuccess: p => (_, values) => {
      return p.refetchReporting(() => {
        if (values.doReport === reportOptions.report.value) {
          return p.history.push(p.suburls.reporting.url)
        }
        scrollToTop()
      })
    },
  }),
)(ReportForm)

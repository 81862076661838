import React from "react"
import Select from "inputs/Select"
import Columns from "components/Columns"
import { localize } from "locale"
import { relationOptions, operationOptions } from "data/templates/logic"
import AddButtons from "../AddButtons"
import * as R from "ramda"
import {
  ConclusionQuestionId,
  isConclusionTerm,
  getAnswerOptions,
  getQuestionOptions,
} from "./helpers"

const Condition = ({
  applicableQuestions,
  statuses,
  addBrackets,
  addCondition,
  editQuestionId,
  editRelation,
  editAnswerId,
  editConclusion,
  editOperation,
  deleteNode,
  term,
  operationBefore,
  indices,
  entityName,
}) => {
  const showSelect = R.last(indices) !== 0 && indices.length > 0
  const showSelectIndent = !showSelect && indices.length > 0
  const showNothing = !showSelect && !showSelectIndent

  const isConclusion = isConclusionTerm(term)
  const answerOptions = getAnswerOptions(term, applicableQuestions, statuses)

  return (
    <Columns
      widths={[...(showNothing ? [] : [80]), 180, 95, "<300", 146]}
      gap={10}
      mb={10}
      mt={10}
    >
      {showSelectIndent && <div />}
      {showSelect && (
        <Select
          placeholder={localize("operation")}
          options={operationOptions}
          value={operationBefore}
          onChange={editOperation(indices)}
          byValue
        />
      )}
      <Select
        placeholder={entityName}
        options={getQuestionOptions(applicableQuestions, statuses, entityName)}
        // backend doesnt send us back questionId: "conclusion", so we have to mimic it by checking for conclusion property
        value={term.conclusion ? ConclusionQuestionId : term.questionId}
        onChange={editQuestionId(indices)}
        byValue
      />
      <Select
        placeholder={localize("relation")}
        options={relationOptions}
        value={term.relation}
        onChange={editRelation(indices)}
        byValue
      />
      <Select
        placeholder={localize("answer")}
        readOnly={!term.questionId && !term.conclusion}
        options={answerOptions}
        value={term.conclusion || term.answerId}
        onChange={
          isConclusion ? editConclusion(indices) : editAnswerId(indices)
        }
        byValue
      />
      <AddButtons
        addBrackets={addBrackets}
        addCondition={addCondition}
        deleteNode={deleteNode}
        indices={indices}
        withDelete
      />
    </Columns>
  )
}
export default Condition

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M11.99985,23.99955 C14.48835,23.99955 16.67835,22.81305 18.10785,20.99955 L5.89185,20.99955 C7.32135,22.81305 9.51135,23.99955 11.99985,23.99955 Z M23.12085,13.5015 L21.30435,12.675 L18.66435,5.484 C18.44835,4.893 17.88585,4.5 17.25585,4.5 L16.49985,4.5 C16.49985,2.019 14.48085,0 11.99985,0 C9.51885,0 7.49985,2.019 7.49985,4.5 L6.74385,4.5 C6.11385,4.5 5.55285,4.893 5.33535,5.484 L2.69685,12.675 L0.87885,13.5015 C0.34335,13.746 -0.00015,14.28 -0.00015,14.868 L-0.00015,18 L23.99985,18 L23.99985,14.868 C23.99985,14.28 23.65635,13.746 23.12085,13.5015 Z M10.49985,4.5 C10.49985,3.6735 11.17185,3 11.99985,3 C12.82785,3 13.49985,3.6735 13.49985,4.5 L10.49985,4.5 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

import { localize } from "locale"

export const statuses = {
  published: {
    value: "Published",
    name: localize("Published"),
  },
  approved: {
    value: "Approved",
    name: localize("Approved"),
  },
  requestForApproval: {
    value: "RequestForApproval",
    name: localize("RequestForApproval"),
  },
  inProcess: {
    value: "InProcess",
    name: localize("InProcess"),
  },
  deactivated: {
    value: "Deactivated",
    name: localize("Deactivated"),
  },
  deleted: {
    value: "Deleted",
    name: localize("recycleBin"),
  },
}

export const colors = {
  InProcess: "#fdd7de",
  RequestForApproval: "#e36c84",
  Approved: "#d93954",
  Published: "#b3223f",
  Deactivated: "#7f182d",
  Deleted: "#C28A00",
}

export const canEditAfterFinished = template =>
  template &&
  template.canApprove &&
  (template.status === statuses.approved.value ||
    template.status === statuses.published.value)

import React from "react"
import { P } from "styledComponents/Typography"
import * as R from "ramda"

const getContent = (c, props) => {
  if (R.type(c) === "String") {
    return (
      <>
        {c.split("\n").map((x, xi) => (
          <P key={xi} mt={xi === 0 ? 0 : 3} {...props}>
            {x}
          </P>
        ))}
      </>
    )
  }

  return c
}

const Text = ({ children, bold, ...rest }) => (
  <P {...rest}>{getContent(children, { bold })}</P>
)

export default Text

import { answerTypes } from "data/templates/answers"
import * as R from "ramda"
import { getHandleChange } from "../../helpers"
import { addAnswerSet } from "../../../helpers"
import { getQuestionGroup, getNewIndex } from "data/autofill/helpers"

export const getOptions = (data, catalogId) => {
  const item = data.find(x => x.registryCatalogId === catalogId)

  if (!item?.value) return []

  return item.value.split(",").map((x, xi) => ({ name: x, value: xi }))
}

export const getTagsFromQuestion = q => ({
  FirstName: q.registryCatalogTags.find(y => y.subType === "FirstName"),
  MiddleName: q.registryCatalogTags.find(y => y.subType === "MiddleName"),
  LastName: q.registryCatalogTags.find(y => y.subType === "LastName"),
  first: q.registryCatalogTags[0],
})

const getValFromIndex = (
  values,
  tags,
  subtype,
  multipleValuesIndices,
  isMulti,
) => {
  if (R.isNil(tags[subtype])) return null

  const tmp = values.find(
    y => y.registryCatalogId === tags[subtype].registryCatalogId,
  )
  const v = R.pathOr("", ["value"], tmp)

  if (!isMulti || !multipleValuesIndices) return v

  return R.path([multipleValuesIndices[subtype]], v.split(","))
}

export const handleChange =
  (question, values, multipleValuesIndices, isMulti) => x => {
    const getNewVal = () => {
      // name question type
      const tags = getTagsFromQuestion(question)
      if (question.questionType === answerTypes.name.value) {
        return {
          firstName: getValFromIndex(
            values,
            tags,
            "FirstName",
            multipleValuesIndices,
            isMulti,
          ),
          middleName: getValFromIndex(
            values,
            tags,
            "MiddleName",
            multipleValuesIndices,
            isMulti,
          ),
          lastName: getValFromIndex(
            values,
            tags,
            "LastName",
            multipleValuesIndices,
            isMulti,
          ),
        }
      }

      //  other types
      return getValFromIndex(
        values,
        tags,
        "first",
        multipleValuesIndices,
        isMulti,
      )
    }

    const getNewRegistryValueTags = () => {
      if (question.questionType === answerTypes.name.value) {
        const nameValueTags = question.registryCatalogTags.map(y => {
          const value = values.find(
            z => z.registryCatalogId === y.registryCatalogId,
          )

          return {
            subType: y.subType,
            registryValueId: value.id,
            registryValue: value.value,
            applicableRegistryValue:
              isMulti && multipleValuesIndices
                ? R.pathOr("", ["value"], value).split(",")[
                    multipleValuesIndices[y.subType]
                  ]
                : value.value,
            valueIndex:
              isMulti && multipleValuesIndices
                ? multipleValuesIndices[y.subType]
                : undefined,
          }
        })

        return nameValueTags.filter(x => !R.isNil(x.registryValueId))
      }

      const value = values.find(
        y =>
          y.registryCatalogId ===
          question.registryCatalogTags[0].registryCatalogId,
      )
      if (!value) return []

      const result = [
        {
          registryValueId: value.id,
          registryValue: value.value,
          applicableRegistryValue:
            isMulti && multipleValuesIndices
              ? R.pathOr("", ["value"], value).split(",")[
                  multipleValuesIndices.first
                ]
              : value.value,
          valueIndex:
            isMulti && multipleValuesIndices
              ? multipleValuesIndices.first
              : undefined,
        },
      ]

      return result.filter(x => !R.isNil(x.registryValueId))
    }

    const result = [
      getNewVal(),
      getNewRegistryValueTags(),
      {
        registryValueId: x.value,
        name: x.name,
      },
    ]

    return result
  }

export const autofillGroup = ({
  question,
  values,
  onChangeFull,
  data,
  subject,
  indices,
  rootQuestion,
  allCatalog,
}) => {
  const groupNr = getQuestionGroup(question)
  let lastValue = values

  const callOnChangeForQuestion = (q, parentQuestion, i) => {
    const tags = getTagsFromQuestion(q)
    const catalogItem = allCatalog.find(
      y => y.id === tags.first.registryCatalogId,
    )

    const multi = catalogItem.allowsMultiple

    const catalogValue = data.find(
      y => y.registryCatalogId === tags.first.registryCatalogId,
    )

    // no value for this catalog item
    if (!catalogValue) {
      return
    }

    const len = multi
      ? R.pathOr("", ["value"], catalogValue).split(",").length
      : 1

    if (len > 1) {
      const numOfExistingAnswers = R.path(i.slice(0, -2), lastValue).filter(
        x => x.questionId === parentQuestion.id,
      ).length

      // create new answer sets  for multiple value
      R.range(0, len - numOfExistingAnswers).forEach(() => {
        lastValue = addAnswerSet(parentQuestion, lastValue, i.slice(0, -2))
      })

      R.range(0, len)
        .map(n => ({
          first: n,
          FirstName: n,
          MiddleName: n,
          LastName: n,
        }))
        .forEach((x, xi) => {
          const ansIndices = R.path(i.slice(0, -2), lastValue)
            .map((x, xi) =>
              x.questionId === parentQuestion.id ? xi : undefined,
            )
            .filter(x => !R.isNil(x))
          const multiAnsIndex = ansIndices[xi]

          lastValue = getHandleChange({
            indices: multi
              ? i.slice(0, -2).concat([multiAnsIndex, "answers"])
              : i,
            values: lastValue,
            data: q,
            onChange: onChangeFull,
          })(...handleChange(q, data, x, multi)(subject))
        })
    }

    // CASE OF SINGLE VALUE
    if (len === 1) {
      lastValue = getHandleChange({
        indices: i,
        values: lastValue,
        data: q,
        onChange: onChangeFull,
      })(...handleChange(q, data, undefined, multi)(subject))
    }
  }

  const fill = (q, i) => {
    q.subQuestions.forEach(sq => {
      const gr = getQuestionGroup(sq)

      if (gr === groupNr && sq.questionType !== "Custom") {
        callOnChangeForQuestion(sq, q, i.concat(["answers"]))
      }

      if (sq.questionType === "Custom") {
        let newIndex = getNewIndex(indices, i, values, sq)

        // answerset doesnt exist, so we create one and adjust index
        if (newIndex === -1) {
          const ind = i.concat(["children"])
          lastValue = addAnswerSet(sq, lastValue, ind)
          newIndex = R.path(ind, lastValue).findIndex(
            x => x.questionId === sq.id,
          )
        }

        fill(sq, i.concat(["children", newIndex]))
      }
    })
  }

  fill(rootQuestion, indices.slice(0, 1))
}

import React from "react"
import ListArrow from "icons/ListArrow"
import { P } from "styledComponents/Typography"
import { localize } from "locale"
import { EmptyWrap } from "./styled"

const Empty = ({ text, arrow, requiredText }) => {
  if (requiredText && !text) return null

  return (
    <EmptyWrap arrow={arrow}>
      {arrow && <ListArrow />}
      <P ml={arrow ? 10 : 0} lightgray>
        {text || localize("noResults")}
      </P>
    </EmptyWrap>
  )
}

export default Empty

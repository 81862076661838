import React from "react"
import withModal from "hoc/withModal"
import { localize } from "locale"
import { P } from "styledComponents/Typography"

const TermsModal = () => (
  <>
    <P mb={20}>
      (1) This website is subject to the following terms of use, which are
      binding in the relationship between the user and PricewaterhouseCoopers
      GmbH Wirtschaftsprüfungsgesellschaft, Friedrich-Ebert-Anlage 35-37, 60327
      Frankfurt am Main, hereinafter "PwC", by accessing this website. Insofar
      as special conditions for individual use of this website deviate from the
      following terms of use, this is expressly pointed out at the appropriate
      point on the website. The special terms of use shall then apply in each
      individual case.
    </P>
    <P mb={20}>
      (2) This website contains data and information of all kinds which are
      protected by trademark and/or copyright in favour of PwC or in individual
      cases also in favour of third parties. It is therefore not permitted to
      download, duplicate and/or distribute the website as a whole or individual
      parts such as texts, images, graphics, layout, photos thereof. All rights,
      including those of reproduction, distribution and translation, are
      reserved to PwC. No part of this website may be reproduced in any form or
      stored, processed, duplicated or distributed using electronic systems
      without the express written permission of PwC.
      <br />
      Only the technically conditioned duplication for the purpose of browsing
      is permitted, as far as this action serves neither commercial purposes,
      nor the permanent duplication for own use. Furthermore, it is permitted to
      set a link to this website, provided that it serves solely as a
      cross-reference. PwC reserves the right to revoke this permission. Framing
      this website is expressly prohibited.
    </P>
    <P mb={20}>
      (3) The information on this website is of a general nature and is intended
      to introduce PwC to interested internet users. PwC assumes liability for
      the content of its websites in accordance with legal provisions. The
      information is selected with due care. While we have made every attempt to
      ensure that the information contained in this site has been obtained and
      arranged with due care, PwC is not responsible for any inaccuracies,
      errors or omissions contained in or relating to, this information.
      <br />
      PwC accepts no responsibility for decisions made by the user on the basis
      of the above information. PwC points out that the present content does not
      constitute individual legal, accounting, tax or other professional
      information or recommendation and is not suitable for replacing individual
      advice by competent persons taking into account the specific circumstances
      of the individual case. PwC reserves the right to change or update the
      information, products or services offered on this website at any time
      without prior notice.
    </P>
    <P mb={20}>
      (4) References and links to third-party websites do not mean that PwC
      adopts the content behind the reference or link as its own. PwC has no
      influence on the content behind the link. PwC is therefore not liable for
      illegal, incorrect or incomplete content and for damage caused by the use
      of content behind the link.
    </P>
    <P mb={20}>
      (5) Use of the internet is at the user's own risk. In particular, PwC is
      not liable for the technical failure of the Internet or access to the
      internet.
    </P>
    <P mb={20}>
      (6) If the contracting party is a merchant, a legal entity under public
      law or a special fund under public law, the place of jurisdiction shall be
      at PwC's registered office in Frankfurt. German law shall apply to the
      exclusion of the UN Convention on Contracts for the International Sale of
      Goods and the rules of conflict of the international private law.
    </P>
    <P mb={20}>
      (7) In the event that the user is a consumer (not an entrepreneur), the
      reference to the possibility of out-of-court settlement of disputes shall
      be made in accordance with Article 14 of Regulation (EU) No. 524/2013 -
      ODR Regulation. Details can be found in the aforementioned regulation and
      at http://ec.europa.eu/consumers/odr.
    </P>
    <P mb={20}>
      (8) PwC reserves the right to modify these Terms of Use from time to time
      and to adapt them to technical and legal developments. The user will be
      notified of the change - insofar as he has registered. In the event that
      individual provisions of this user agreement are invalid, this shall not
      affect the validity of the remaining provisions.
    </P>
  </>
)

export default withModal(() => localize("termsOfUse"))(TermsModal)

import React from "react"
import { Box } from "styledComponents/Containers"
import Loader from "components/Loader"
import Empty from "components/Empty"
import { AriaMenuItem, MenuItemText } from "./styled"
import * as R from "ramda"
import { withRouter } from "react-router"
import withHandlers from "hoc/withHandlers"

const AriaMenu = ({
  options,
  value,
  handleClick,
  byValue,
  loading,
  noOptionsText,
  renderText = R.prop("name"),
}) => {
  const ref = React.useRef()
  React.useEffect(() => {
    if (!value || !R.path(["current"], ref)) return
    const i = options.findIndex(x =>
      byValue ? value === x.value : value && value.value === x.value,
    )
    if (i === -1) return

    const el = ref.current.querySelector(`:nth-child(${i + 1})`)
    setTimeout(() => {
      el.scrollIntoView({
        block: "nearest",
      })
    })
  }, [ref]) // eslint-disable-line

  if (loading) return <Loader small />

  if (options.length === 0) return <Empty text={noOptionsText} />

  return (
    <Box flex column ref={ref}>
      {options.map((x, xi) => {
        const isActive = byValue
          ? value === x.value
          : value && value.value === x.value
        return (
          <AriaMenuItem
            key={xi}
            onClick={() => handleClick(x)}
            isActive={isActive}
          >
            {x.icon && <x.icon />}
            <MenuItemText>{renderText(x)}</MenuItemText>
            {x?.typeLabel && <Box ml="auto">{x.typeLabel}</Box>}
          </AriaMenuItem>
        )
      })}
    </Box>
  )
}

export default R.compose(
  withRouter,
  withHandlers({
    handleClick: p => x => {
      p.close?.()
      if (x.to) {
        p.history.push(x.to)
      }

      if (x.onClick) {
        x.onClick()
      }
      // whole menu has onChange
      if (p.onChange) {
        p.onChange(p.byValue ? x.value : x, x)
      }
    },
  }),
)(AriaMenu)

import Checkbox from "inputs/Checkbox"
import Select from "inputs/Select"
import IdSearch from "inputs/IdSearch"
import Textfield from "inputs/Textfield"
import AutocompleteSelectCountry from "inputs/AutocompleteSelectCountry"
import {
  conclusionStatuses,
  reportStatuses,
  statuses,
} from "data/assessments/statuses"
import { stringToOption } from "common/options"
import { localize } from "locale"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import ModalButtons from "components/ModalButtons"
import ButtonModal from "components/ButtonModal"
import ConfirmModal from "components/ConfirmModal"
import { conclusionRequest } from "data/conclusions/api"
import { withRouter } from "react-router"
import SearchIcon from "icons/Search"

const Filters = ({
  status,
  conclusionTypes,
  pageIds,
  ids,
  setIds,
  d,
  refetch,
  onChange,
  searchValues,
  caseId,
}) => (
  <Box flex acenter p={10}>
    {pageIds.length > 0 && (
      <Box mr={10}>
        <P
          link
          red
          hovernolink
          onClick={() =>
            setIds(ids.filter(id => !pageIds.includes(id)).concat(pageIds))
          }
        >
          {localize("selectAllDisplayed")}
        </P>
      </Box>
    )}
    <Box ml="auto" flex acenter>
      <Select
        value={R.path(["xml"], searchValues)}
        small
        onChange={onChange("xml")}
        placeholder="With and without report"
        options={[{ name: "With and without report", value: undefined }].concat(
          [
            {
              name: "With report",
              value: "true",
            },
            {
              name: "Without report",
              value: "false",
            },
          ],
        )}
        byValue
      />
      <Checkbox
        ml={10}
        value={R.path(["favoritesOnly"], searchValues)}
        onChange={onChange("favoritesOnly")}
        text={localize("showFavorites")}
        small
      />
      <Checkbox
        ml={10}
        value={R.path(["overdueOnly"], searchValues)}
        onChange={onChange("overdueOnly")}
        text={localize("showOverdue")}
        small
      />
      <Checkbox
        ml={10}
        value={R.path(["marketableArrangementOnly"], searchValues)}
        onChange={onChange("marketableArrangementOnly")}
        text={localize("showMA")}
        small
      />
      <AutocompleteSelectCountry
        ml={10}
        value={R.path(["country"], searchValues)}
        onChange={onChange("country")}
        small
        placeholder={localize("allCountries")}
        byValue
      />
      {status === statuses.conclusionDeleted.value ? (
        <Select
          ml={10}
          value={R.path(["type"], searchValues)}
          small
          onChange={onChange("type")}
          placeholder="All conclusion types"
          options={[{ name: "All conclusion types", value: undefined }].concat(
            conclusionTypes.map(stringToOption),
          )}
          byValue
        />
      ) : (
        <Select
          ml={10}
          value={
            caseId
              ? conclusionStatuses.conclusionReadyForGeneration.value
              : R.path(["status"], searchValues)
          }
          readOnly={caseId}
          gray={caseId}
          small
          onChange={onChange("status")}
          placeholder={localize("allConclusionStatuses")}
          options={[
            { name: localize("allConclusionStatuses"), value: undefined },
          ]
            .concat(
              R.values(conclusionStatuses).filter(
                x => x.value !== conclusionStatuses.conclusionDeleted.value,
              ),
            )
            .concat(R.values(reportStatuses))}
          byValue
        />
      )}
      <IdSearch
        ml={10}
        value={R.pathOr("", ["id"], searchValues)}
        small
        onChange={onChange("id")}
        text={`You may search conclusion(s) via "Internal arrangement ID", country specific report information (e.g. Entity ID, Entity name, etc.) if available, and IDs received from the tax authority (arrangement ID, disclosure ID, etc.) if available, through exact match.”`}
      />
      <Textfield
        ml={10}
        value={R.pathOr("", ["search"], searchValues)}
        small
        debounce={600}
        onChange={onChange("search")}
        placeholder="Search"
        clearable
        icon={SearchIcon}
      />
    </Box>
    {status === statuses.conclusionDeleted.value && (
      <Box ml={10}>
        <ModalButtons nomargin>
          <ButtonModal
            text="Restore all"
            small
            secondary
            modal={ConfirmModal}
            disabled={d.length === 0}
            title="Restore all"
            confirmText="All conclusions will be restored into original reporting status, and all relevant coworkers will receive tasks and notifications."
            buttonText="Do you want to restore all conclusions?"
            cancelText="No"
            submitText="Yes"
            request={() => conclusionRequest.restoreAll()}
            successText={() => localize("restoreAllAssessmentsSuccess")}
            onSuccess={refetch}
          />
          <ButtonModal
            text="Empty bin"
            small
            modal={ConfirmModal}
            disabled={d.length === 0}
            title="Empty bin"
            confirmText={`All conclusions in bin will be permanently deleted and cannot be restored.\n\nIf included in the mass report object, they will also be permanently removed from this object, which will cause inconsistency between mass report detail and XML report file, unless the mass report object is also deleted.`}
            buttonText="Do you want to empty the recycle bin?"
            cancelText="No"
            submitText="Yes"
            request={() => conclusionRequest.deleteAllPermanent()}
            successText={(msg, _, response) =>
              R.path(["status"], response) === 200
                ? "You have permanently deleted all conclusions in bin."
                : msg
            }
            onSuccess={refetch}
            submitDanger
          />
        </ModalButtons>
      </Box>
    )}
  </Box>
)

export default withRouter(Filters)

import React from "react"
import { ModalButtonsWrap, ButtonWrap } from "./styled"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"

const ModalButtons = ({ nomargin, children, buttonText, noBg }) => (
  <ModalButtonsWrap nomargin={nomargin} buttonText={buttonText} noBg={noBg}>
    <Box flex acenter>
      {buttonText && (
        <P gray bold>
          {buttonText}
        </P>
      )}
      <Box flex ml="auto">
        {React.Children.map(children, (c, ci) => (
          <ButtonWrap key={ci} first={ci === 0} nomargin={nomargin}>
            {c}
          </ButtonWrap>
        ))}
      </Box>
    </Box>
  </ModalButtonsWrap>
)

export default ModalButtons

import React from "react"
import { Container } from "styledComponents/Containers"
import PageTitle from "components/PageTitle"
import Button from "components/Button"
import PreassessmentTable from "./PreassessmentTable"
import { Redirect, Route, Switch } from "react-router-dom"
import { statuses } from "data/assessments/statuses"
import Tabs from "components/Tabs"
import DeleteIcon from "icons/appkit/fill-delete"
import withData from "hoc/withData"
import { preassessmentRequest } from "data/preassessments/api"

const isDeleted = item =>
  item.status === statuses.deleted.value ||
  item.status === statuses.conclusionDeleted.value

const List = ({ suburls, case_, match, data, refetchData, refetchCase }) => {
  return (
    <Container>
      <PageTitle title="Pre-assessment">
        {case_.canEdit && (
          <Button
            text="Create pre-assessment"
            disabled={case_.hasActivePreAssessment}
            to={suburls.template.url}
          />
        )}
      </PageTitle>

      <Switch>
        <Route
          path={`${match.url}/:status`}
          render={routeProps => {
            const status = routeProps.match.params.status
            const isDeletedTab = status === statuses.deleted.value
            return (
              <>
                <Tabs
                  options={[
                    {
                      ...statuses.all,
                      count: data.filter(x => !isDeleted(x)).length,
                    },
                    {
                      ...statuses.deleted,
                      right: true,
                      icon: DeleteIcon,
                      count: data.filter(isDeleted).length,
                    },
                  ]}
                  value={status}
                  onChange={v => routeProps.history.push(`${match.url}/${v}`)}
                  inPanel
                />
                <PreassessmentTable
                  status={status}
                  data={data.filter(x =>
                    isDeletedTab ? isDeleted(x) : !isDeleted(x),
                  )}
                  key={status}
                  suburls={suburls}
                  isDeletedTab={isDeletedTab}
                  refetch={refetchData}
                  case_={case_}
                  refetchCase={refetchCase}
                />
              </>
            )
          }}
        />
        <Redirect to={`${match.url}/${statuses.all.value}`} />
      </Switch>
    </Container>
  )
}

export default withData(p => preassessmentRequest.list(p.case_.id))(List)

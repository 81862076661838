import React from "react"
import { assessmentQuestionRequest } from "data/assessments/api"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import withData from "hoc/withData"
import Questions from "pages/AssessmentsCreatePage/Questions"
import withProps from "hoc/withProps"
import * as R from "ramda"

const AssessmentQuestions = ({
  tree,
  questions,
  assessmentId,
  assessment,
  refetchData,
}) => (
  <Questions
    tree={tree}
    questions={questions}
    assessmentId={assessmentId}
    assessment={assessment}
    refetch={refetchData}
  />
)

export default R.compose(
  withData(p => assessmentQuestionRequest.list(p.assessmentId)),
  withProps(p => {
    const tree = buildTree(p.data)
    return {
      tree,
      questions: getQuestionsFromTree(tree),
    }
  }),
)(AssessmentQuestions)

import styled, { css } from "styled-components/macro"

export const NotificationListWrap = styled.div`
  width: 500px;
  max-width: 100%;

  ${p =>
    p.padding &&
    css`
      padding: 10px 0;
    `}
`

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import withForm from "hoc/withForm"
import EditIcon from "icons/appkit/fill-edit"
import RichTextarea from "inputs/RichTextarea"
import { localize } from "locale"
import { longStringRequired } from "validators/string"
import { ActiveIcon } from "styledComponents/Icons"
import { NodeHeader } from "../nodeStyles"
import Section from "../Section"
import Panel from "components/Panel"
import * as R from "ramda"
import TemplateEditWarning from "components/TemplateEditWarning"

const Static = ({
  type,
  editIndex,
  moveIndex,
  onEdit,
  onCancelEdit,
  canEdit,
  canEditAfterDone,
}) => {
  const editing = editIndex && editIndex.type === type
  return (
    <Section
      title={`${localize("conclusions")} ${localize(type).toLowerCase()}`}
    >
      <Panel>
        {(canEdit || canEditAfterDone) && (
          <NodeHeader>
            <ActiveIcon
              disabled={editIndex || moveIndex}
              onClick={() => onEdit({ type })}
              ml="auto"
            >
              <EditIcon />
            </ActiveIcon>
            {canEditAfterDone && <TemplateEditWarning />}
          </NodeHeader>
        )}

        <RichTextarea
          name="text"
          readOnly={!editing}
          borderless={!editing}
          mb={0}
          noEmptyError
        />
        {editing && (
          <ModalButtons>
            <Button
              secondary
              type="button"
              onClick={onCancelEdit}
              text="Cancel"
            />
            <Button type="submit" text="Save" />
          </ModalButtons>
        )}
      </Panel>
    </Section>
  )
}

export default withForm({
  mapProps: p => ({
    text: p.text,
  }),
  schema: R.always({
    text: longStringRequired,
  }),
  onSubmit: p => v => p.request(v.text),
  onSuccess: p => p.onSuccess,
})(Static)

import React from "react"
import ContextMenu from "components/ContextMenu"
import OverlayWrapper from "components/OverlayWrapper"
import withData from "hoc/withData"
import { notificationsRequest } from "data/notifications/api"
import OutlineNotification from "icons/appkit/outline-notification"
import { IconWrap } from "styledComponents/Icons"
import NotificationList from "./NotificationList"
import { Unread } from "./styled"
import useOpen from "hooks/useOpen"

const Notifications = ({ data, refetchData }) => {
  const { isOpen, close, toggle } = useOpen()

  return (
    <OverlayWrapper isOpen={isOpen} close={close}>
      <IconWrap data-id="notification-bell" onClick={toggle} large>
        <OutlineNotification />
        {data > 0 && <Unread>{data > 99 ? "99+" : data}</Unread>}
      </IconWrap>
      <ContextMenu isOpen={isOpen}>
        <NotificationList refetch={refetchData} close={close} />
      </ContextMenu>
    </OverlayWrapper>
  )
}

export default withData(() => notificationsRequest.count(), {
  noLoader: true,
})(Notifications)

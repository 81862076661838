import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import Panel from "components/Panel"
import { coworkersRequest } from "data/templates/api"
import { templateRoles } from "data/templates/roles"
import { formatInitialsFromName } from "data/user/formatters"
import withData from "hoc/withData"
import withForm from "hoc/withForm"
import AutocompleteList from "inputs/AutocompleteList"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { P } from "styledComponents/Typography"
import { arrayOptional } from "validators/array"
import { coworkerTransform } from "data/user/transforms"

const Coworkers = ({ canEdit, urls }) => (
  <Panel>
    {canEdit && (
      <P gray mb={20}>
        {localize("templateApproversInfoText")}
      </P>
    )}
    <AutocompleteList
      name="coworkers"
      label={localize("addApprover")}
      roles={templateRoles}
      deleteByNullingPermission
      request={coworkersRequest.find}
      readOnly={!canEdit}
      mapOptions={R.map(coworkerTransform)}
      title={localize("addedUsers")}
      optionToAvatar={x => formatInitialsFromName(x.fullName)}
      optionToTitle={R.prop("fullName")}
      optionToSubtitle={R.prop("email")}
      noResultText={localize("noEmailInTenant")}
    />
    {canEdit && (
      <ModalButtons>
        <Button
          secondary
          type="button"
          to={urls.templates.local}
          text="Cancel"
        />
        <Button type="submit" text="Save" />
      </ModalButtons>
    )}
  </Panel>
)

export default R.compose(
  withUrls,
  withData(p => coworkersRequest.list(p.templateId)),
  withForm({
    preventLoseProgress: true,
    mapProps: p => ({ coworkers: p.data.map(coworkerTransform) }),
    schema: R.always({
      coworkers: arrayOptional,
    }),
    onSubmit: p => v =>
      coworkersRequest.edit(p.templateId, {
        roles: v.coworkers.map(x => ({
          userId: x.id,
          role: x.permission,
        })),
      }),
    onSuccess: p => () => p.refetch(),
    redirect: (_, p) => p.suburls.questions.url,
  }),
)(Coworkers)

import React from "react"
import { StyledTab, ArrowWrap } from "./styled"
import ArrowNext from "./ArrowNext"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import CaretIcon from "icons/Caret"
import { IconWrap } from "styledComponents/Icons"
import useOpen from "hooks/useOpen"
import ContextMenu from "components/ContextMenu"
import OverlayWrapper from "components/OverlayWrapper"
import * as R from "ramda"
import AriaMenu from "components/AriaMenu"

const isActive = (val, cur, prefixActive, exact) => {
  if (!exact && prefixActive && val !== "/" && cur !== "/") {
    return val.startsWith(cur)
  }

  return val === cur
}

const Tab = ({
  index,
  option,
  value,
  onChange,
  inPanel,
  prefixActive,
  arrows,
  menuValue,
  disableActive,
  underline,
  ...rest
}) => {
  const { isOpen, close, toggle } = useOpen(false)

  const Icon = option.icon
  const active = isActive(value, option.value, prefixActive, option.exact)

  const options = R.pathOr([], ["options"], option)
  const hasOptions = options.length > 0

  const handleClick = () => {
    if (active && (disableActive || option.disableActive)) return

    if (hasOptions) return toggle()

    if (option.onClick) {
      return option.onClick()
    }

    return onChange(option.value, option)
  }

  return (
    <Box ml={option.right ? "auto" : 0}>
      <OverlayWrapper isOpen={isOpen} close={close}>
        <Box flex>
          {arrows && index !== 0 && (
            <ArrowWrap>
              <ArrowNext />
            </ArrowWrap>
          )}
          <StyledTab
            arrows={arrows}
            onClick={handleClick}
            active={active}
            inPanel={inPanel}
            disabled={option.disabled}
            count={option.count}
            right={option.right}
            underline={underline}
            disableActive={disableActive || option.disableActive}
            data-active={active}
          >
            {Icon && <Icon />}
            <P
              inline
              {...rest}
              red={underline && active}
              gray={underline && !option.black && !active}
            >
              {option.name}
            </P>
            {hasOptions && (
              <IconWrap rotate={+isOpen}>
                <CaretIcon />
              </IconWrap>
            )}
          </StyledTab>
        </Box>
        {hasOptions && (
          <ContextMenu isOpen={isOpen}>
            <AriaMenu
              options={options}
              value={menuValue}
              onChange={onChange}
              close={close}
              byValue
            />
          </ContextMenu>
        )}
      </OverlayWrapper>
    </Box>
  )
}

export default Tab

import React from "react"
import { EditBarWrap } from "./styled"
import { ActiveIcon } from "styledComponents/Icons"
import { Spread } from "styledComponents/Containers"
import TemplateEditWarning from "components/TemplateEditWarning"
import EditIcon from "icons/appkit/fill-edit"
import DeleteIcon from "icons/appkit/fill-delete"
import SortIcon from "icons/appkit/fill-sort-default"
import CopyIcon from "icons/appkit/fill-duplicate"
import { P } from "styledComponents/Typography"
import { canEditAfterFinished } from "data/templates/statuses"
import WarningConfirmModal from "components/WarningConfirmModal"
import { localize } from "locale"
import * as R from "ramda"

const containsPrecondition = precondition => {
  if (R.type(precondition) === "Object") {
    return !precondition.questionId
  }
  return !R.isNil(precondition)
}

const referencesQuestion = (questionId, precondition) => {
  if (!precondition) return false

  if (R.type(precondition) === "Object") {
    return questionId === precondition.questionId
  }

  return precondition.some(x => referencesQuestion(questionId, x.item))
}

const EditBarIcons = ({
  title,
  onDelete,
  onEdit,
  onMove,
  onCancelMove,
  moving,
  movingThis,
  onCopy,
  onCancelCopy,
  copying,
  copyingThis,
  canEdit,
  disabled,
  type,
  template,
  data,
  questions,
}) => {
  const isQuestion = type === "question"
  const canEditDone = isQuestion && canEditAfterFinished(template)

  return (
    <Spread>
      <P fs={20} white>
        {title}
      </P>
      {canEdit && (
        <EditBarWrap>
          {!moving && !copying && (
            <>
              <ActiveIcon onClick={onEdit} disabled={disabled}>
                <EditIcon />
              </ActiveIcon>
              <ActiveIcon onClick={onDelete} disabled={disabled}>
                <DeleteIcon />
              </ActiveIcon>
              <WarningConfirmModal
                onConfirm={onMove}
                text={localize("pleaseNoteQuestionContainsLogicIsReferenced")}
                doConfirm={() =>
                  containsPrecondition(data.data.precondition) ||
                  questions.some(x =>
                    referencesQuestion(data.referencedId, x.data.precondition),
                  )
                }
              >
                {onClick => (
                  <ActiveIcon onClick={onClick} disabled={disabled}>
                    <SortIcon />
                  </ActiveIcon>
                )}
              </WarningConfirmModal>
              <WarningConfirmModal
                onConfirm={onCopy}
                text={localize("pleaseNoteQuestionContainsLogic")}
                doConfirm={() => containsPrecondition(data.data.precondition)}
              >
                {onClick => (
                  <ActiveIcon onClick={onClick} disabled={disabled}>
                    <CopyIcon />
                  </ActiveIcon>
                )}
              </WarningConfirmModal>
            </>
          )}
          {moving && movingThis && (
            <>
              <ActiveIcon onClick={onCancelMove} active>
                <SortIcon />
              </ActiveIcon>
            </>
          )}
          {copying && copyingThis && (
            <>
              <ActiveIcon onClick={onCancelCopy} active>
                <CopyIcon />
              </ActiveIcon>
            </>
          )}
        </EditBarWrap>
      )}
      {canEditDone && (
        <EditBarWrap>
          <ActiveIcon onClick={onEdit} disabled={disabled}>
            <EditIcon />
          </ActiveIcon>
          <TemplateEditWarning disabled={disabled} />
        </EditBarWrap>
      )}
    </Spread>
  )
}

export default EditBarIcons

import Template from "./Template"
import List from "./List"
import Detail from "./Detail"
import Conclusion from "./Conclusion"

export const getSuburls = match => {
  const path = match.path
  const url = match.url

  const urls = {
    list: {
      path: `${path}/list`,
      url: `${url}/list`,
      component: List,
    },
    template: {
      path: `${path}/template`,
      url: `${url}/template`,
      component: Template,
    },
    detail: {
      path: `${path}/detail/:id`,
      url: id => `${url}/detail/${id}`,
      component: Detail,
    },
    conclusion: {
      path: `${path}/conclusion/:id`,
      url: id => `${url}/conclusion/${id}`,
      component: Conclusion,
    },
  }

  return urls
}

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import { stringRequired } from "validators/string"
import Radio from "inputs/Radio"
import {
  coworkerOptions,
  getCoworkerOptions,
} from "data/assessments/coworkerOptions"
import { preassessmentRequest } from "data/preassessments/api"

const CreatePreassessmentModal = ({ libraryItemId, close }) => (
  <>
    <P mb={20} bold>
      Choose options for coworkers
    </P>

    <Radio
      options={getCoworkerOptions(!R.isNil(libraryItemId))}
      name="copyCoworkers"
      byValue
    />

    <ModalButtons buttonText="Do you want to create pre-assessment?">
      <Button secondary type="button" onClick={close} text="No" />
      <Button primary type="submit" text="Yes, create" />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(p =>
    R.isNil(p.libraryItemId)
      ? "Create empty pre-assessment"
      : "Create pre-assessment from library item",
  ),
  withUrls,
  withForm({
    mapProps: R.always({
      copyCoworkers: coworkerOptions.prefill.value,
    }),
    schema: R.always({
      copyCoworkers: stringRequired,
    }),
    onSubmit: p => v =>
      preassessmentRequest.createPreassessment(p.case_.id, {
        ...(p.libraryItemId
          ? {
              libraryItemAssessmentCreationOptions: {
                assessmentsCoworkersOption: v.copyCoworkers,
              },
              libraryItemId: p.libraryItemId,
            }
          : {
              templateAssessmentCreationOptions: {
                assessmentsCoworkersOption: v.copyCoworkers,
              },
              templateId: p.templateId,
            }),
      }),
    onSuccess: p => () => {
      p.close()
      p.refetchCase?.()
    },
    redirect: (res, p) => p.redirect(res),
  }),
)(CreatePreassessmentModal)

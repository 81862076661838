import React from "react"
import { P } from "styledComponents/Typography"
import RichTextarea from "inputs/RichTextarea"
import Empty from "components/Empty"
import { HistoryItem } from "./styled"
import OutlineAvatar from "icons/appkit/outline-avatar"
import OutlineTime from "icons/appkit/outline-time"
import { formatDate, formatTime } from "data/units/date/formatters"
import { localize } from "locale"

const History = ({ data }) => {
  if (data.length === 0) {
    return <Empty />
  }

  return (
    <>
      {data.map((x, xi) => (
        <HistoryItem key={xi}>
          {x.message && (
            <P mb={10} bold gray>
              {x.message}
            </P>
          )}
          {x.comment && (
            <RichTextarea
              mb={10}
              value={x.comment}
              readOnly
              borderless
              edge2edge
            />
          )}
          {x.newValue && (
            <>
              <P bold mb={3}>
                {localize("newValue")}
              </P>
              <RichTextarea value={x.newValue} readOnly borderless edge2edge />
            </>
          )}
          {x.oldValue && (
            <>
              <P bold mb={3}>
                {localize("oldValue")}
              </P>
              <RichTextarea value={x.oldValue} readOnly borderless edge2edge />
            </>
          )}
          <P inline fs={12} mr={10}>
            <OutlineAvatar />
            {x.userName}
          </P>
          <P inline fs={12}>
            <OutlineTime />
            {[formatDate(x.atUtc), formatTime(x.atUtc)].join(", ")}
          </P>
        </HistoryItem>
      ))}
    </>
  )
}

export default History

import React from "react"
import Modal from "./Modal"

const withModal =
  (getTitle, config = {}) =>
  Component =>
  props =>
    (
      <Modal
        close={e => {
          if (props.onClose) {
            props.onClose(e)
          }
          props.close(e)
        }}
        title={getTitle(props)}
        {...config}
      >
        <Component {...props} />
      </Modal>
    )

export default withModal

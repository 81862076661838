import React from "react"
import Button from "components/Button"
import Textfield from "inputs/Textfield"
import withSubmit from "hoc/withSubmit"
import { groupRequest } from "data/templates/api"
import { InputWithButtonWrap } from "styledComponents/Containers"
import { localize } from "locale"
import PreventLoseProgress from "components/PreventLoseProgress"

const EditBarForm = ({ cancelEdit, submit, name }) => {
  const [dirty, setDirty] = React.useState(false)
  const [text, setText] = React.useState(name || "")

  return (
    <PreventLoseProgress when={dirty}>
      <InputWithButtonWrap>
        <Textfield
          value={text}
          onChange={v => {
            setText(v)
            setDirty(true)
          }}
          placeholder={localize("enterName")}
        />
        <Button secondary type="button" onClick={cancelEdit} text="Cancel" />
        <Button
          onClick={() => submit(text)}
          text="Save"
          disabled={text === ""}
        />
      </InputWithButtonWrap>
    </PreventLoseProgress>
  )
}

export default withSubmit({
  onSubmit: p => text =>
    p.editIndex.referencedId === "new"
      ? groupRequest.create(p.templateId, {
          name: text,
          parentNodeId: p.editIndex.parentNodeId,
          previousNodeId: p.editIndex.previousNodeId,
        })
      : groupRequest.edit(p.templateId, p.editIndex.referencedId, {
          name: text,
        }),
  onSuccess: p => () => p.onSuccess(),
})(EditBarForm)

import React from "react"
import withFormField from "hoc/withFormField"
import Textfield from "inputs/Textfield"
import Columns from "components/Columns"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import useMedia from "hooks/useMedia"

const InputName = ({ value, onChange, readOnly, transparent }) => {
  const { isMobile } = useMedia()

  const v = value || {}

  return (
    <Columns align="flex-end" noCols={isMobile}>
      <div>
        <P gray bolder mb={5}>
          {localize("firstName")}
        </P>
        <Textfield
          value={v.firstName}
          onChange={val => onChange({ ...v, firstName: val })}
          readOnly={readOnly}
          transparent={transparent}
        />
      </div>
      <div>
        <P gray bolder mb={5}>
          {localize("middleName")} ({localize("optional")})
        </P>
        <Textfield
          value={v.middleName}
          onChange={val => onChange({ ...v, middleName: val })}
          readOnly={readOnly}
          transparent={transparent}
        />
      </div>
      <div>
        <P gray bolder mb={5}>
          {localize("lastName")}
        </P>
        <Textfield
          value={v.lastName}
          onChange={val => onChange({ ...v, lastName: val })}
          readOnly={readOnly}
          transparent={transparent}
        />
      </div>
    </Columns>
  )
}

export default withFormField(InputName)

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentRequest } from "data/assessments/api"
import { preassessmentRequest } from "data/preassessments/api"
import withModal from "hoc/withModal"
import withSubmit from "hoc/withSubmit"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import withUrls from "hoc/withUrls"
import { getCaseUrl } from "data/cockpit/redirect"

const ConfirmApproveModal = ({ close, submit, isPreassessment }) => (
  <>
    <P mb={20} bold>
      Do you want to approve this {isPreassessment ? "pre-" : ""}assessment?
    </P>
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="No" />
      <Button success onClick={submit} text={localize("yesApprove")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(
    p =>
      `Approve ${p.isPreassessment ? "pre-" : ""}assessment ${
        p.assessment.name
      }`,
  ),
  withUrls,
  withSubmit({
    onSubmit: p => () =>
      (p.isPreassessment ? preassessmentRequest : assessmentRequest).approve(
        p.assessment.id,
      ),
    onSuccess: p => () => {
      p.close()
      if (p.isPreassessment) {
        p.refetchCase?.()
      }
    },
    redirect: (_, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return getCaseUrl(p.match, p.assessment)
      }
      return p.urls.assessments.local
    },
    successText: p => () =>
      `You have approved the ${p.isPreassessment ? "pre-" : ""}assessment ${
        p.assessment.name
      }.`,
  }),
)(ConfirmApproveModal)

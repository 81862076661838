import styled, { css } from "styled-components/macro"

export const Tr = styled.tr`
  background: ${p => (p.rowIndex % 2 === 0 ? "#fff" : "#f2f2f2")} !important;
`

export const CaretTd = styled.td`
  text-align: right;

  svg {
    width: 20px;
    height: 20px;
    color: #666;
  }

  ${p =>
    p.open &&
    css`
      svg {
        transform: rotate(180deg);
      }
    `};
`

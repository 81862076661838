import * as R from "ramda"
import { localize } from "locale"

export const registryTypes = {
  entity: { name: "Entity", value: "E" },
  individual: { name: "Individual", value: "I" },
}

export const registryTypeOptions = R.values(registryTypes)

export const fieldTypeOptions = [
  { value: "Freetext", name: localize("freeText") },
  { value: "Email", name: localize("email") },
  { value: "Date", name: localize("dateYYYYMMDD") },
  { value: "CountryCode", name: localize("countryCodeISO") },
]

import * as R from "ramda"
import { localize } from "locale"

const getConclusionText = (conclusions, id) =>
  conclusions.conclusionDefinitions.find(x => x.id === +id).name

const getReasoningText = (conclusions, id) =>
  conclusions.reasonings.findIndex(x => x.id === +id) + 1

const getTexts = (fn, data) => R.compose(R.values, R.mapObjIndexed(fn))(data)

export const getMessages = (
  conclusionDefinitionMessages,
  conclusionReasoningMessages,
  messages,
  conclusions,
) => [
  ...getTexts(
    (v, k) =>
      `${localize("conclusion")} "${getConclusionText(conclusions, k)}": ${v}`,
    conclusionDefinitionMessages,
  ),
  ...getTexts(
    (v, k) =>
      `${localize("reasoning")} ${getReasoningText(conclusions, k)}: ${v}`,
    conclusionReasoningMessages,
  ),
  ...messages,
]

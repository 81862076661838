import { arrEmpty } from "common/array"

export const hasInSubquestions = predicate => data => {
  if (arrEmpty(data.subQuestions)) return false

  const subs = data.subQuestions.filter(x => x)
  return subs.find(predicate) || subs.some(hasInSubquestions(predicate))
}

export const getIsOpen = ({ value, lastSelectedValue, node }) => {
  const val =
    value?.questionOptionId || value?.questionId ? value : lastSelectedValue

  if (!val) return false
  // A: question
  if (val.questionId) {
    // is subtype of this question
    if (val.questionId === node.referencedId && val.subType) return true

    // is subquestion of this question
    return hasInSubquestions(x => x.id === val.questionId)(node.data)
  }

  // B: option
  if (val.questionOptionId) {
    // is option of this question
    if (node.data.options.find(x => x.id === val.questionOptionId)) return true

    // is option of subquestion
    return hasInSubquestions(x =>
      x.options.find(y => y.id === val.questionOptionId),
    )(node.data)
  }

  return false
}

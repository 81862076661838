import React from "react"

export default ({ active, desc }) => (
  <svg width="32px" height="32px" viewBox="0 0 32 32">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g>
        <g transform="translate(16.000000, 16.000000) rotate(-90.000000) translate(-16.000000, -16.000000) translate(0.000000, 2.000000)">
          <g>
            <g>
              <polygon
                fill={active && !desc ? "#999999" : "#D8D8D8"}
                points="0 16 0 19.9643211 21.5296301 19.9643211 16.3518495 25.197225 19.1251002 28 31 16"
              />
              <polygon
                fill={active && desc ? "#999999" : "#D8D8D8"}
                points="12.8727856 0 1 12 32 12 32 8.0347941 10.4694618 8.0347941 15.6461532 2.80246642"
              />
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

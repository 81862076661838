import React from "react"
import InfoModal from "components/InfoModal"
import Button from "components/Button"
import { localize } from "locale"
import ModalButtons from "components/ModalButtons"
import useOpen from "hooks/useOpen"

const WarningConfirmChangeModal = ({
  children,
  onConfirm,
  text,
  confirmText = localize("ok"),
  cancelText = localize("cancel"),
  title = localize("warning"),
  buttonText,
  dangerConfirm,
}) => {
  const { isOpen, open, close } = useOpen()
  const [tmpState, setTmpState] = React.useState()

  return (
    <>
      {isOpen && (
        <InfoModal
          title={title}
          text={text}
          body={
            <ModalButtons buttonText={buttonText}>
              <Button secondary text={cancelText} onClick={close} />
              <Button
                danger={dangerConfirm}
                text={confirmText}
                onClick={() => {
                  onConfirm(tmpState)
                  close()
                }}
              />
            </ModalButtons>
          }
          close={close}
        />
      )}
      {children(v => {
        open()
        return setTmpState(v)
      })}
    </>
  )
}

export default WarningConfirmChangeModal

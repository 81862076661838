import React from "react"
import Columns from "components/Columns"
import {
  answerTypeOptions,
  fileUploadOptions,
  getDefaultAnswers,
  isSingleMultipleOrConfirmation,
  isCustom,
} from "data/templates/answers"
import Checkbox from "inputs/Checkbox"
import Radio from "inputs/Radio"
import Select from "inputs/Select"
import { localize } from "locale"
import CountryLabel from "components/CountryLabel"
import * as R from "ramda"

const onTypeChange = (values, setValues) => v => {
  const prev = values.questionType
  if (v === prev) return
  if (R.pathOr([], ["options"], values).length > 0) return

  // reset options upon changing type
  setValues({
    ...values,
    questionType: v,
    subQuestions: isCustom(v) ? values.isCustom : null,
    options: isSingleMultipleOrConfirmation(v) ? getDefaultAnswers(v) : [],
  })
}

const Flags = ({ values, setValues, readOnly, isPreAssessment }) => {
  const singleOrMultiOrConfirm = isSingleMultipleOrConfirmation(
    values.questionType,
  )
  const isCustom = values.questionType === "Custom"

  return (
    <Columns widths={[90, 140, "auto", 300, 50]}>
      {isCustom ? (
        <div />
      ) : (
        <Checkbox
          name="isMandatory"
          label={localize("mandatoryAnswer")}
          readOnly={readOnly}
          noEmptyError
          mb={0}
        />
      )}
      <Checkbox
        name="forReportingOnly"
        label={localize("forReportingOnly")}
        readOnly={readOnly || isPreAssessment}
        noEmptyError
        mb={0}
      />
      <div>
        <Select
          options={answerTypeOptions}
          name="questionType"
          onChange={onTypeChange(values, setValues)}
          label={localize("typeOfAnswer")}
          placeholder={localize("selectTypeOfTheAnswer")}
          required={!readOnly}
          readOnly={readOnly}
          borderless={readOnly}
          byValue
          mb={0}
          noEmptyError
        />
        {!readOnly && <CountryLabel type={values.questionType} />}
      </div>
      {singleOrMultiOrConfirm ? (
        <div />
      ) : (
        <Radio
          name="answerAttachmentPolicy"
          label={localize("attachment")}
          readOnly={readOnly}
          options={fileUploadOptions}
          inline
          byValue
          noEmptyError
          mb={0}
        />
      )}
      {/* multiple answers have cross here to delete */}
      {/* so we include this with 50px width for alignment */}
      <div />
    </Columns>
  )
}

export default Flags

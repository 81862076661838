import React from "react"
import ArrowSharpIcon from "icons/ArrowSharp"
import { PrevNextArrowsWrap, Title, ArrowWrap } from "./styled"
import { localize } from "locale"
import dayjs from "dayjs"
import { modes } from "../modes"
import { startOfDecade, endOfDecade } from "../helpers"

const getTitle = (mode, offset) => {
  if (mode === modes.month) {
    const month = dayjs().add(offset, "months")
    return `${localize("monthsArray")[month.format("M") - 1]} ${month.format(
      "YYYY",
    )}`
  }

  const year = dayjs().add(offset, "years")
  if (mode === modes.year) {
    return year.format("YYYY")
  }

  return `${startOfDecade(offset * 10)} - ${endOfDecade(offset * 10)}`
}

const PrevNextArrows = ({ offset, prev, next, mode, higherMode }) => (
  <PrevNextArrowsWrap>
    <ArrowWrap rotate="true" onClick={prev}>
      <ArrowSharpIcon />
    </ArrowWrap>
    <Title fs={12} bold onClick={higherMode} openable={mode !== !modes.decade}>
      {getTitle(mode, offset)}
    </Title>
    <ArrowWrap onClick={next}>
      <ArrowSharpIcon />
    </ArrowWrap>
  </PrevNextArrowsWrap>
)

export default PrevNextArrows

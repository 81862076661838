import React from "react"
import Button from "components/Button"
import { Box } from "styledComponents/Containers"
import { localize } from "locale"
import SkipModal from "./SkipModal"
import Submit from "components/Submit"
import * as R from "ramda"
import { ActivePanel } from "./styled"
import PreventLoseProgress from "components/PreventLoseProgress"

const SkipConfirm = ({
  resetValue,
  modalText = localize("youHaveUnsavedAnswer"),
  noSaveButton,
  noPadding,
  hide,
  discardOnSubmit,
  onSubmit,
  onSuccess,
  onError,
  errorText,
  disabledNoBg,

  id,
  editProps,
  v,
  onChange,
  disabled,
  title,
  children,
}) => {
  const editingThis = editProps.ids.editId === id
  const skippingFromThis = editProps.ids.skipFromId === id

  const onDiscard = () => {
    editProps.forceSkipTo(editProps.ids.skipToId)
    onChange()
    resetValue()
  }

  const close = discardOnSubmit ? () => editProps.stopSkip() : onDiscard

  return (
    <Submit
      onSubmit={onSubmit}
      onSuccess={(...v) => {
        onSuccess(...v)
        editProps.reset()
      }}
      onError={onError}
      errorText={errorText}
    >
      {({ submit }) => (
        <PreventLoseProgress when={!R.isNil(editProps.ids.dirtyId)}>
          <ActivePanel
            onClick={
              editingThis || disabled ? undefined : () => editProps.skipTo(id)
            }
            active={editingThis}
            noPadding={noPadding}
            hide={hide}
            disabled={disabled}
            disabledNoBg={disabledNoBg}
            className={`editable-${id}`}
          >
            {skippingFromThis && (
              <SkipModal
                submit={() => {
                  submit(v)
                  editProps.forceSkipTo(editProps.ids.skipToId)
                }}
                discard={onDiscard}
                close={close}
                title={title}
                text={modalText}
                discardOnSubmit={discardOnSubmit}
              />
            )}
            {children({
              v,
              editingThis,
              onChange: x => {
                onChange(x)
                editProps.setDirty(id)
              },
              save: submit,
            })}
            {editingThis && !noSaveButton && (
              <Box flex mt={10}>
                <Button onClick={() => submit(v)} text="Save" ml="auto" />
              </Box>
            )}
          </ActivePanel>
        </PreventLoseProgress>
      )}
    </Submit>
  )
}

export default SkipConfirm

import React from "react"
import FillNotification from "icons/appkit/fill-notification"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"

const Empty = () => (
  <Box flex column acenter pt={50} pb={50}>
    <IconWrap large static mb={10}>
      <FillNotification />
    </IconWrap>
    <P>{localize("allCaughtUp")}</P>
  </Box>
)

export default Empty

import React from "react"
import { localize } from "locale"
import * as R from "ramda"
import Checkbox from "inputs/Checkbox"
import Status from "components/Status"
import {
  reportStatuses,
  getStatusName,
  statuses,
  conclusionStatuses,
} from "data/assessments/statuses"
import { indexColorsByStatuses } from "data/conclusions/colors"
import { formatConclusionStatus } from "data/conclusions/formatters"
import FavoriteButton from "components/FavoriteButton"
import ArrangementId from "components/ArrangementId"
import Link from "components/Link"
import { formatDate } from "data/units/date/formatters"
import dayjs from "dayjs"
import theme from "theme"
import OptionBox from "components/OptionBox"
import IcoMore from "icons/IcoMore"
import { assessmentRequest } from "data/assessments/api"
import XmlIcon from "icons/Xml"

export const config = ({
  urls,
  conclusionTypes,
  refetch,
  ids,
  handleCheck,
  rowOptions,
  status,
  caseConclusionUrls,
}) => ({
  columns: [
    {
      label: "",
      path: x =>
        x.canCreateReport ? (
          <Checkbox
            value={ids.includes(x.id)}
            onChange={() => handleCheck(x.id)}
            squared
          />
        ) : null,
    },
    {
      label: "",
      path: x => (
        <FavoriteButton
          request={assessmentRequest.setFavorite(x.id)}
          isFavorite={x.favorite}
          onSuccess={refetch}
        />
      ),
    },
    {
      label: localize("arrangementId"),
      path: x => <ArrangementId arrangementId={x.arrangementId} />,
    },
    {
      label: "With report",
      path: x => (x.hasXmlReportPreview ? <XmlIcon /> : null),
    },
    {
      label: localize("name"),
      path: x => (
        <Link
          ul
          to={
            caseConclusionUrls
              ? caseConclusionUrls.conclusion.url(x.id)
              : urls.conclusionsDetail(x.id)
          }
        >
          {x.name}
        </Link>
      ),
      sortKey: "Name",
    },
    {
      label: localize("country"),
      path: R.prop("countryName"),
      sortKey: "Template.Country.Name",
    },
    {
      label: localize("conclusionType"),
      path: x => (
        <Status
          color={indexColorsByStatuses(conclusionTypes)[x.conclusionType]}
          text={formatConclusionStatus(x.conclusionType)}
          rejected={x.isRejectedConclusion}
        />
      ),
      sortKey: "Conclusion",
    },
    {
      label: localize("conclusionStatus"),
      path: x => getStatusName(x.conclusionStatus),
      sortKey: "Status",
    },
    {
      label: localize("approvedOn"),
      path: x => formatDate(x.approvedAtUtc),
      sortKey: "ApprovedAtUtc",
    },
    {
      label: localize("dueDate"),
      path: x => {
        const isLate = dayjs(x.dueDateUtc).isBefore(dayjs(), "days")
        const showError = ![
          ...R.values(reportStatuses),
          conclusionStatuses.conclusionDeleted,
          conclusionStatuses.conclusionRejected,
        ]
          .map(R.prop("value"))
          .includes(x.conclusionStatus)

        return (
          <Status
            color={isLate && showError ? theme.color.error : undefined}
            text={formatDate(x.dueDateUtc)}
          />
        )
      },
      sortKey: "RpDueDate",
    },
    ...(status === statuses.conclusionDeleted.value
      ? [
          {
            path: x => (
              <OptionBox
                options={rowOptions.map(option => {
                  return {
                    ...option,
                    onClick: () => option.onClick(x),
                  }
                })}
                icon={IcoMore}
              />
            ),
          },
        ]
      : []),
  ],
})

import React from "react"
import { fromEvent } from "rxjs"
import { breakpoints } from "theme"
import { throunceTime } from "common/rxjs"

const useMedia = () => {
  const w = window.innerWidth
  const [state, setState] = React.useState({
    isMobile: w < breakpoints.tablet,
    isTablet: w >= breakpoints.tablet && w < breakpoints.desktop,
    isDesktop: w >= breakpoints.desktop,
  })

  const setFlags = () => {
    const w = window.innerWidth

    setState({
      isMobile: w < breakpoints.tablet,
      isTablet: w >= breakpoints.tablet && w < breakpoints.desktop,
      isDesktop: w >= breakpoints.desktop,
    })
  }

  React.useEffect(() => {
    const sub = fromEvent(window, "resize")
      .pipe(throunceTime(200))
      .subscribe(() => setFlags())

    return () => sub.unsubscribe()
  }, [])

  return state
}

export default useMedia

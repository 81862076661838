import React from "react"
import Language from "components/Language"
import Link from "components/Link"
import Status from "components/Status"
import { formatDate, formatDateRange } from "data/units/date/formatters"
import { localize } from "locale"
import * as R from "ramda"
import theme from "theme"

export const config = urls => ({
  columns: [
    {
      label: localize("createdOn"),
      path: R.pipe(R.prop("createdAtUtc"), formatDate),
      sortBy: R.prop("createdAtUtc"),
    },
    {
      label: localize("name"),
      path: x => (
        <Link
          ul
          to={{
            pathname: urls.templatesCreate(x.templateId),
            state: { fromTasks: true },
          }}
        >
          {x.name}
        </Link>
      ),
      sortBy: R.prop("name"),
    },
    {
      label: localize("pwcSite"),
      path: R.prop("tenantName"),
      sortBy: R.prop("tenantName"),
    },
    {
      label: localize("applicableTimePeriod"),
      path: x => formatDateRange(x.applicableFrom, x.applicableTo),
    },
    {
      label: localize("language"),
      path: x => <Language code={x.language} />,
    },
    {
      label: localize("action"),
      path: x => (
        <Link
          to={{
            pathname: urls.templatesCreate(x.templateId),
            state: { fromTasks: true },
          }}
        >
          <Status color={theme.color.red} text={localize(x.action)} />
        </Link>
      ),
    },
  ],
  search: (searchValue, item) =>
    item.name.toLowerCase().includes(searchValue.toLowerCase()),
})

import * as R from "ramda"
import { getData } from "common/rxjs"
import { toastError, toastSuccess } from "data/toasts/rx"

export const getInitialState = (schema, initialValues) => ({
  values: initialValues,
  dirty: {},
  errors: R.mapObjIndexed(
    (validateFn, key) => validateFn(initialValues[key]),
    schema,
  ),
  isSubmitting: false,
  submitCount: 0,
})

export const handleSubmit =
  ({
    form,
    setForm,
    p,
    onSubmit,
    onSuccess,
    successText,
    onError,
    errorText,
    redirect,
    onChangeHandler,
    history,
  }) =>
  e => {
    e?.preventDefault()

    // something not valid, do not go through
    if (R.values(form.errors).some(x => x)) {
      return setForm(x => ({
        ...x,
        submitCount: x.submitCount + 1,
      }))
    }

    setForm(x => ({
      ...x,
      isSubmitting: true,
      submitCount: x.submitCount + 1,
    }))

    const task = onSubmit(p)(form.values).subscribe({
      error: error => {
        const message = error.text
        const t = errorText ? errorText(p)(message, error) : message
        if (t) {
          toastError(t)
        }
        setForm(x => ({ ...x, isSubmitting: false }))
        onError(p, onChangeHandler)(message, error, form.values)
      },
      next: result => {
        const data = getData(result)
        setForm(x => ({ ...x, isSubmitting: false, dirty: {} }))
        onSuccess(p)(data, form.values)
        if (successText) {
          const fn = successText(p)
          if (R.type(fn) === "Function") {
            const t = successText(p)(data, form.values, result)
            if (t) {
              toastSuccess(t)
            }
          }
        }
        if (redirect) {
          const redirUrl = redirect(data, { ...p, values: form.values })
          if (redirUrl) {
            history.push(redirUrl)
          }
        }
      },
    })

    return task
  }

import styled, { css } from "styled-components/macro"

export const PageWrap = styled.div`
  height: 100vh;
  display: flex;
  flex-direction: column;
`

export const PageContent = styled.div`
  flex-grow: 1;
  /* flex-shrink: 0; */
  background: #f8f8f8;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
`

export const PageBody = styled.div`
  flex-grow: 1;
  flex-shrink: 0;
  ${p =>
    p.center &&
    css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 10px;
    `}
`

import React from "react"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import ToggleMark from "components/ToggleMark"
import useOpen from "hooks/useOpen"

const Node = ({ node, onChange, value, closeModal }) => {
  const { isOpen, toggle } = useOpen()

  const isOpenable = node.children && node.children.length > 0

  return (
    <>
      <Box flex mb={10}>
        <ToggleMark onClick={toggle} hide={!isOpenable} isOpen={isOpen} />
        <P
          bold={!node.isCriterion}
          red={!node.isCriterion}
          link={!node.isCriterion && value.headerId !== node.id}
          hovernolink
          onClick={() => {
            if (node.isCriterion) return

            onChange({
              headerName: node.value,
              headerId: node.id,
            })
            closeModal()
          }}
        >
          {node.value}
        </P>
      </Box>
      {isOpenable && isOpen && (
        <Box pl={20}>
          {node.children.map(x => (
            <Node
              node={x}
              key={x.id}
              onChange={onChange}
              value={value}
              closeModal={closeModal}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default Node

import styled, { css } from "styled-components/macro"
import { IconWrap } from "styledComponents/Icons"
import theme from "theme"

export const TextfieldWrap = styled.div`
  border: 1px solid ${p => (p.activeBorder ? theme.color.orange : "#d8d8d8")};
  display: flex;
  position: relative;
  background-color: ${p => (p.transparent ? "transparent" : "#fff")};
  overflow: hidden;

  ${p => p.round && `border-radius: 4px;`};

  ${p =>
    !p.edge2edge &&
    css`
      min-height: 42px;
    `};
  ${p =>
    p.width &&
    css`
      width: ${p.width}px;
    `};

  ${p =>
    p.borderless &&
    css`
      border: 0;
    `};

  ${p =>
    p.small &&
    !p.edge2edge &&
    css`
      min-height: 32px;
    `};
`

export const AbsoluteIconWrap = styled(IconWrap)`
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
`

export const ReadonlyTextfield = styled.div`
  color: #333;
  font-size: 16px;
  width: 100%;
  padding: 11.8px 15px 0 ${p => (p.hasIcon ? 40 : 15)}px;

  ${p =>
    p.small &&
    css`
      padding-top: 6.8px;
      padding-bottom: 6.8px;
    `};
  ${p =>
    p.edge2edge &&
    css`
      padding: 0;
    `};
  ${p =>
    p.gray &&
    css`
      background-color: #eee;
    `};
`

export const StyledTextfield = styled.input`
  border: 0;
  color: #333;
  outline: none;
  font-size: 16px;
  width: 100%;
  height: 42px;
  padding: 0 15px 0 ${p => (p.hasIcon ? 40 : 15)}px;
  background-color: ${p => (p.transparent ? "transparent" : "#fff")};

  &::placeholder,
  &:-ms-input-placeholder {
    fill: rgba(51, 51, 51, 0.333);
    color: rgba(51, 51, 51, 0.333);
    text-shadow: none;
  }
  ::-webkit-input-placeholder {
    color: rgba(51, 51, 51, 0.333);
    text-shadow: none;
  }

  &::-ms-clear {
    display: none;
  }

  ${p =>
    p.small &&
    css`
      height: 30px;
    `};

  ${p =>
    p.readOnly &&
    css`
      outline: none;
    `};
  ${p =>
    p.gray &&
    css`
      background-color: #eee;
    `};
  ${p =>
    p.noDirectChange &&
    css`
      color: transparent;
      text-shadow: 0 0 0 #333;
    `}
`

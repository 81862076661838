import { statuses } from "data/assessments/statuses"

export const assessmentColors = {
  [statuses.inProcess.value]: "#FFECBD",
  [statuses.requestForApproval.value]: "#FFC83D",
  [statuses.deleted.value]: "#C28A00",
  [statuses.deactivated.value]: "#7f182d",
  [statuses.conclusionApproved.value]: "#FFB600",
  [statuses.conclusionDeleted.value]: "#C28A00",
  [statuses.libraryInDraft.value]: "#D1EBD2",
  [statuses.libraryInReview.value]: "#94CF97",
  [statuses.libraryPublished.value]: "#48A34D",
  [statuses.libraryDeactivated.value]: "#7f182d",
}

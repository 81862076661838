export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M2 1.5V4.5H0V1.5H2ZM16 1.5V4.5H4V1.5H16ZM16 9.5V6.5H4V9.5H16ZM2 6.5V9.5H0V6.5H2ZM16 14.5V11.5H4V14.5H16ZM2 11.5V14.5H0V11.5H2Z"
      fill="white"
    />
    <mask
      id="mask0"
      mask-type="alpha"
      maskUnits="userSpaceOnUse"
      x="0"
      y="1"
      width="16"
      height="14"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2 1.5V4.5H0V1.5H2ZM16 1.5V4.5H4V1.5H16ZM16 9.5V6.5H4V9.5H16ZM2 6.5V9.5H0V6.5H2ZM16 14.5V11.5H4V14.5H16ZM2 11.5V14.5H0V11.5H2Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)"></g>
  </svg>
)

import React from "react"
import { assessmentRequest } from "data/assessments/api"
import withData from "hoc/withData"
import * as R from "ramda"
import { Container, Box } from "styledComponents/Containers"
import CountryFilter from "components/CountryFilter"
import Templates from "./Templates"
import { parseSearch } from "common/router"
import { parallel } from "common/rxjs"
import { P } from "styledComponents/Typography"
import withState from "hoc/withState"
import useOpen from "hooks/useOpen"
import { IconWrap } from "styledComponents/Icons"
import CaretIcon from "icons/Caret"
import { caseRequest } from "data/cockpit/api"
import { getHandleChangeCountry } from "./helpers"
import BottomPanel from "./BottomPanel"

const Country = ({
  templates,
  countries,
  assessmentCountries,
  location,
  value,
  setValue,
  caseId,
  suburls,
  case_,
}) => {
  const [country, setCountry] = React.useState([])
  const { isOpen, toggle } = useOpen()

  const parsed = parseSearch(location.search)
  const filtered = templates.filter(x => country.includes(x.countryCode))

  const validCountryCodes = R.pipe(
    R.map(R.prop("countryCode")),
    R.uniq,
  )(templates)

  const hasExisting = assessmentCountries.length > 0

  const handleChangeCountry = getHandleChangeCountry({
    country,
    setCountry,
    setValue,
    templates,
    countries,
  })

  return (
    <Container>
      {hasExisting && (
        <Box flex mb={20}>
          <P>Case contains assessments/conclusions from:</P>
          <P bold ml={5}>
            {assessmentCountries.map(R.prop("countryName")).join(", ")}
          </P>
          <Box flex onClick={toggle}>
            <P red link ml={20}>
              Generate additional assessments
            </P>
            <IconWrap rotate={+isOpen} red small>
              <CaretIcon />
            </IconWrap>
          </Box>
        </Box>
      )}
      {(isOpen || !hasExisting) && (
        <>
          <P>
            You can generate new blank assessment based on active template or
            create partly prefilled assessment based on library item.
          </P>
          <P mb={20}>
            All generated assessments are automatically linked to the case and
            are available in Case details.
          </P>
          <Box flex>
            <Box width={300} mr={20}>
              <CountryFilter
                value={country}
                onChange={handleChangeCountry}
                multi
                countries={countries}
                validCountryCodes={validCountryCodes}
                assessmentCountries={assessmentCountries}
                templates={templates}
                readOnly={!case_.canEdit}
              />
            </Box>
            <Box grow>
              {case_.canEdit && (
                <Templates
                  filtered={filtered}
                  countries={countries}
                  country={country}
                  involvedPartyId={R.path(["involvedPartyId"], parsed)}
                  value={value}
                  onChange={setValue}
                />
              )}
            </Box>
          </Box>
        </>
      )}
      <BottomPanel
        value={value}
        setValue={setValue}
        caseId={caseId}
        suburls={suburls}
      />
    </Container>
  )
}

export default R.compose(
  withData(
    p =>
      parallel(
        assessmentRequest.listTemplates(),
        caseRequest.listCountries(p.caseId),
        caseRequest.assessmentCountries(p.caseId),
      ),
    {
      dataKeys: ["templates", "countries", "assessmentCountries"],
    },
  ),
  withState("value", "setValue", []),
)(Country)

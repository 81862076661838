import React from "react"
import { mappingRequest } from "data/mapping/api"
import { Box } from "styledComponents/Containers"
import Select from "inputs/Select"
import Checkbox from "inputs/Checkbox"
import * as R from "ramda"
import { localize } from "locale"
import WarningConfirmChangeModal from "components/WarningConfirmChangeModal"
import SkipConfirm from "components/SkipConfirm"

const getDefaultId = options => {
  const def = options.find(x => x.isDefaultSchema)

  return def ? def.id : R.head(options).id
}

const getInit = arr => {
  const selected = arr.find(x => x.isSelected)

  return selected ? selected.id : null
}

const SchemaForm = ({ options, templateId, refetch, editProps }) => {
  const [v, setV] = React.useState(getInit(options))

  const ops = [
    { value: null, name: localize("mappingDisabled") },
    ...options.map(x => ({
      ...x,
      value: x.id,
      name: `${x.reportType} (${localize("version")} ${x.version})`,
    })),
  ]

  return (
    <SkipConfirm
      editProps={editProps}
      id={"schema"}
      v={v}
      onChange={setV}
      resetValue={() => setV(getInit(options))}
      onSubmit={() =>
        mappingRequest.setSchemas(templateId, {
          id: R.isNil(v) ? undefined : v,
          isSelected: !R.isNil(v),
        })
      }
      disabled={options.length === 0}
      disabledNoBg
      onSuccess={() => refetch()}
    >
      {({ editingThis, v, onChange }) => (
        <Box flex acenter>
          <WarningConfirmChangeModal
            onConfirm={onChange}
            text={localize("youAreAboutToChangeTheSchemaVersion")}
            title={localize("notice")}
          >
            {fn => (
              <>
                <Checkbox
                  value={v}
                  onChange={v => fn(v ? getDefaultId(options) : null)}
                  readOnly={!editingThis}
                  squared
                />
                <Box ml={20} width={250}>
                  <Select
                    options={ops}
                    byValue
                    value={v}
                    readOnly={!editingThis}
                    onChange={fn}
                    noEmptyError
                    mb={0}
                  />
                </Box>
              </>
            )}
          </WarningConfirmChangeModal>
        </Box>
      )}
    </SkipConfirm>
  )
}

export default SchemaForm

import { localize } from "locale"
import { validURL } from "common/router"
import * as R from "ramda"
import { combineValidators, fieldOptional, fieldRequired } from "./helpers"

const maxLength = len => v =>
  v?.length > len ? localize("stringMaxLength")(len) : ""

const correctEmail = v =>
  !v || /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(v) ? "" : localize("correctEmail")

export const strOptional = len =>
  combineValidators(fieldOptional, maxLength(len))

export const strRequired = len =>
  combineValidators(fieldRequired, maxLength(len))

// these 4 were the common ones, but seems it will be more per use
export const stringOptional = strOptional(15000)
export const stringRequired = strRequired(15000)
export const longStringOptional = strOptional(50000)
export const longStringRequired = strRequired(50000)

export const emailOptional = combineValidators(fieldOptional, correctEmail)

export const emailRequired = combineValidators(fieldRequired, correctEmail)

export const emailWithDomainRequired = domains =>
  combineValidators(emailRequired, v =>
    domains.length === 0 || domains.includes(R.last(v.split("@")))
      ? ""
      : localize("emailDomainNotAllowed"),
  )

export const urlOptional = combineValidators(
  stringOptional,
  x => (x ? validURL(x) : ""),
  x =>
    !x || x.startsWith("http://") || x.startsWith("https://")
      ? ""
      : localize("mustStartWithHttps"),
)

export const urlRequired = combineValidators(fieldRequired, urlOptional)

export const emailDomain = combineValidators(stringRequired, x =>
  x && emailRequired("name@" + x) ? "" : localize("validDomain"),
)

export const passwordRequired = combineValidators(stringRequired, x =>
  x.length > 7 ? "" : localize("minLength")(8),
)

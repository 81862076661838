import withSubmit from "hoc/withSubmit"
import { authRequest } from "data/auth/api"
import { getClientId, login } from "data/auth/rx"
import withUrls from "hoc/withUrls"
import { noop } from "common/functions"
import * as R from "ramda"
import { removeSearchParam } from "common/router"
import { withRouter } from "react-router-dom"
import { localize } from "locale"

const withSelectTenant = (cb = noop, { noRedir, onError } = {}) =>
  R.pipe(
    withRouter,
    withUrls,
    withSubmit({
      onSubmit: p => id => {
        return authRequest.selectTenant(
          getClientId(),
          id,
          R.path(["data", "access_token"], p)
            ? {
                Authorization: `Bearer ${p.data.access_token}`,
              }
            : undefined,
        )
      },
      onSuccess: p => res => {
        cb(p, res)
        login({
          accessToken: res.access_token,
          refreshToken: res.refresh_token,
        })
      },
      onError,
      errorText: () => (message, err) => {
        if (R.path(["error", "response", "status"], err) === 403) {
          return localize("accessToSiteDenied")
        }

        return message
      },
      redirect: (_, p) => {
        if (noRedir) {
          if (!p.location.search.includes("tid")) return

          p.history.push(
            p.location.pathname + removeSearchParam("tid", p.location.search),
          )
          return
        }

        if (p.referer && p.referer.includes("tid")) {
          const [path, search] = p.referer.split("?")
          return path + "?" + removeSearchParam("tid", "?" + search)
        }

        return p.referer || p.urls.home
      },
    }),
  )

export default withSelectTenant

import React from "react"
import useOpen from "hooks/useOpen"
import DownloadFileModal from "./DownloadFileModal"
import Button from "components/Button"

const DownloadFileButton = ({ text, request, filename }) => {
  const { isOpen, open, close } = useOpen(false)

  return (
    <>
      <Button text={text} onClick={open} />
      {isOpen && (
        <DownloadFileModal
          request={request}
          close={close}
          filename={filename}
        />
      )}
    </>
  )
}

export default DownloadFileButton

import React from "react"
import { P } from "styledComponents/Typography"
import { SizeErrorWrap, StyledImg } from "./styled"
import img from "components/AppError/errorimg.png"
import { localize } from "locale"

const SizeError = () => (
  <SizeErrorWrap>
    <StyledImg src={img} alt="error" />
    <P mt={20} gray bolder>
      {localize("screenSizeError")}
    </P>
  </SizeErrorWrap>
)

export default SizeError

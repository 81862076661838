import React from "react"
import AppLogo from "components/AppLogo"
import { StyledNav, TopBar } from "../Navigation/styled"

const ErrorNavigation = () => (
  <StyledNav>
    <TopBar>
      <AppLogo />
    </TopBar>
  </StyledNav>
)

export default ErrorNavigation

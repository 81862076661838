import React from "react"
import withForm from "hoc/withForm"
import { involvedPartyRequest } from "data/cockpit/api"
import * as R from "ramda"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import Textfield from "inputs/Textfield"
import AutocompleteSelectCountry from "inputs/AutocompleteSelectCountry"
import { strRequired, strOptional } from "validators/string"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { ActiveIcon } from "styledComponents/Icons"
import EditIcon from "icons/appkit/fill-edit"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import LinkedObjectsTable from "./LinkedObjectsTable"
import DeleteButton from "./DeleteButton"
import { SpaceTr, MainTr, TableTr } from "./styled"

const InvolvedParty = ({
  item,
  editId,
  setEditId,
  submitForm,
  refetch,
  rowIndex,
  case: case_,
  searchParams,
  suburls,
  openParties,
  setOpenParties,
}) => {
  const readOnly = editId !== item.id || !case_.canEdit
  const isNew = item.id === "new"

  const isOpen = openParties.includes(item.id)
  const toggle = () =>
    setOpenParties(prev => {
      if (prev.includes(item.id)) {
        return prev.filter(x => x !== item.id)
      }

      return [...prev, item.id]
    })

  return (
    <>
      {rowIndex !== 0 && (
        <SpaceTr>
          <td colSpan={6}></td>
        </SpaceTr>
      )}
      <MainTr isOpen={isOpen}>
        <td>
          {readOnly ? (
            <P ml={20} bold>
              {item.name}
            </P>
          ) : (
            <Textfield name="name" mb={0} noEmptyError />
          )}
        </td>
        <td>
          {readOnly ? (
            <P>{item.countryCode}</P>
          ) : (
            <AutocompleteSelectCountry
              name="countryCode"
              byValue
              placeholder={readOnly ? undefined : "Select one"}
              templateOnlyCountries
              mb={0}
              noEmptyError
            />
          )}
        </td>
        <td>
          <Textfield
            name="role"
            readOnly={readOnly}
            borderless={readOnly}
            mb={0}
            noEmptyError
          />
        </td>
        <td>
          <Textfield
            name="reportingResponsibility"
            readOnly={readOnly}
            borderless={readOnly}
            mb={0}
            noEmptyError
          />
        </td>
        <td>
          <P>{R.pathOr(0, ["linkedObjectsCount"], item)} objects</P>
        </td>
        <td>
          <Box flex justifyContent="flex-end">
            {readOnly && case_.canEdit && (
              <>
                <ActiveIcon onClick={() => setEditId(item.id)}>
                  <EditIcon />
                </ActiveIcon>
                <DeleteButton data={item} refetch={refetch} party={item} />
              </>
            )}
            <ActiveIcon
              onClick={toggle}
              disabled={isNew}
              // the boolean convert is so that styled-components dont throw warning
              rotate={+(isOpen || isNew)}
            >
              <OutlineArrow />
            </ActiveIcon>
          </Box>
        </td>
      </MainTr>
      {(isOpen || !readOnly) && (
        <TableTr>
          <td colSpan={6}>
            {isOpen && (
              <LinkedObjectsTable
                party={item}
                case_={case_}
                searchParams={searchParams}
                refetchParent={refetch}
                suburls={suburls}
              />
            )}
            {!readOnly && (
              <Box mt={20} mb={20} mr={20}>
                <ModalButtons nomargin>
                  <Button
                    secondary
                    type="button"
                    onClick={() => setEditId()}
                    text="Cancel"
                  />
                  <Button onClick={submitForm} text="Save" />
                </ModalButtons>
              </Box>
            )}
          </td>
        </TableTr>
      )}
      {
        <tr>
          <td colSpan={6}></td>
        </tr>
      }
    </>
  )
}

export default R.compose(
  withForm({
    dontWrapInForm: true,
    preventLoseProgress: true,
    mapProps: R.prop("item"),
    schema: R.always({
      name: strRequired(50),
      // countryCode: stringRequired,
      role: strOptional(50),
      reportingResponsibility: strOptional(100),
    }),
    onSubmit: p => v =>
      p.item.id === "new"
        ? involvedPartyRequest.create(p.caseId, v)
        : involvedPartyRequest.edit(p.item.id, v),
    onSuccess: p => () => {
      p.setEditId()
      p.refetch()
    },
  }),
)(InvolvedParty)

import React from "react"
import Button from "components/Button"
import Panel from "components/Panel"
import PlusIcon from "icons/Plus"
import { localize } from "locale"
import * as R from "ramda"
import { Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import EditBar from "../EditBar"
import QuestionBody from "./QuestionBody"
import { showAddButtons } from "../../helpers"
import {
  showCopySection,
  showCopySubsection,
  showCopyQuestion,
  showMoveQuestion,
  showMoveSubsection,
} from "./helpers"

const Question = ({
  question = {},
  section,
  ops,
  templateId,
  template,
  questions,
}) => (
  <>
    <Panel mt={20} id={`q-${question.referencedId}`}>
      <Spread mb={20}>
        <P fs={18} bold mr="auto">
          {localize("question")} {question.idx}
        </P>
        <EditBar
          type="question"
          data={question}
          ops={ops}
          templateId={templateId}
          template={template}
          questions={questions}
        />
      </Spread>
      <QuestionBody
        question={question}
        templateId={templateId}
        template={template}
        questions={questions}
        ops={ops}
      />
    </Panel>
    {showAddButtons(ops) && (
      <Spread mt={20}>
        {R.isNil(ops.subsectionIndex) && (
          <Button
            secondary
            text={localize("addSubsection")}
            onClick={() => ops.addSection(section.id, question.id)}
            disabled={!!ops.editIndex}
          />
        )}
        <Button
          ml="auto"
          icon={PlusIcon}
          text={localize("addQuestion")}
          onClick={() => ops.addQuestion(section.id, question.id)}
          disabled={!!ops.editIndex}
        />
      </Spread>
    )}
    {showMoveSubsection(ops) && (
      <Button
        text={localize("moveSubsectionHere")}
        onClick={() => ops.onMoveConfirm(section.id, question.id)}
        mt={20}
      />
    )}
    {showMoveQuestion(ops) && (
      <Button
        text={localize("moveQuestionHere")}
        onClick={() => ops.onMoveConfirm(section.id, question.id)}
        mt={20}
      />
    )}
    {showCopyQuestion(ops) && (
      <Button
        text={localize("pasteQuestionHere")}
        onClick={() => ops.onCopyConfirm(section.id, question.id)}
        mt={20}
      />
    )}
    {showCopySubsection(ops) && (
      <Button
        text={localize("pasteSubsectionHere")}
        onClick={() => ops.onCopyConfirm(section.id, question.id)}
        mt={20}
      />
    )}
    {showCopySection(ops) && (
      <Button
        text={localize("pasteSectionHere")}
        onClick={() => ops.onCopyConfirm(section.id, question.id)}
        mt={20}
      />
    )}
  </>
)

export default Question

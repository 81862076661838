import React from "react"
import withModal from "hoc/withModal"
import English from "./English"
import Deutsch from "./Deutsch"
import { Box } from "styledComponents/Containers"
import { Hr } from "styledComponents/Typography"

const PrivacyModal = () => (
  <>
    <English />
    <Box mt={40} mb={40}>
      <Hr />
    </Box>
    <Deutsch />
  </>
)

export default withModal(() => "Privacy statement")(PrivacyModal)

import React from "react"
import Panel from "components/Panel"
import Button from "components/Button"
import ButtonModal from "components/ButtonModal"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import { ActiveIcon } from "styledComponents/Icons"
import Questions from "../../Questions"
import { reportingRequest } from "data/reporting/api"
import { downloadContentAsFile } from "common/files"
import PollModal from "components/PollModal"
import useOpen from "hooks/useOpen"
import { previewRequest } from "data/templates/api"

const Preview = ({
  tree,
  questions,
  assessmentId,
  assessment,
  isValid,
  isPreview,
  mappingSchemaId,
  isSchemaActive,
  isPreviewEnabled,
  title,
}) => {
  const { isOpen, toggle } = useOpen()

  return (
    <>
      <Panel mb={20}>
        <Box flex acenter>
          <P mr="auto">{title}</P>
          <Button
            text={localize("XMLreportPreview")}
            disabled={!isValid || !isPreviewEnabled || !isSchemaActive}
            onClick={() =>
              downloadContentAsFile(
                isPreview
                  ? previewRequest.downloadXML(assessmentId, mappingSchemaId)
                  : reportingRequest.downloadXML(assessmentId),
              )
            }
            mr={10}
          />
          <ButtonModal
            text={localize("PDFreportPreview")}
            disabled={!isValid || isPreview || !isSchemaActive}
            modal={PollModal}
            initRequest={reportingRequest.initDownloadPDF(assessmentId, {
              type: "PdfReportPreview",
            })}
            title={localize("downloadPDF")}
            body={localize("pdfIsBeingGeneratedPleaseWait")}
          />
          <ActiveIcon rotate={+isOpen} onClick={toggle} ml={10}>
            <OutlineArrow />
          </ActiveIcon>
        </Box>
      </Panel>
      {isOpen && (
        <Questions
          tree={tree}
          questions={questions}
          assessmentId={assessmentId}
          assessment={assessment}
        />
      )}
    </>
  )
}

export default Preview

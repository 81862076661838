import React from "react"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import ButtonModal from "components/ButtonModal"
import AutofillIcon from "components/AutofillIcon"
import EntityModal from "./EntityModal"
import ListIcon from "icons/List"
import AutocompleteSelectRegistry from "inputs/AutocompleteSelectRegistry"
import SmartPortal from "components/SmartPortal"
import {
  getAutofillName,
  getAutofillText,
  getIsActive,
  showAutofill,
  getAutofillTooltip,
  clearGroup,
  getIsOverriden,
} from "./helpers"
import * as R from "ramda"
import MultipleValuesModal from "./MultipleValuesModal"
import useOpen from "hooks/useOpen"
import ConfirmClearModal from "./ConfirmClearModal"
import Tooltip from "components/Tooltip"
import { getQuestionsWithValue } from "data/autofill/helpers"

const Autofill = ({
  question,
  onChange,
  readOnly,
  value,
  nonCustomRoot,
  indices,
  inputValue,
  catalogs,
  rootQuestion,
  isEnabled,
  onChangeFull,
  values,
  enabled,
  parentGroups,
  isFirstAnswerSet,
  prevAnswerSets,
}) => {
  const [subject, setSubject] = React.useState()
  const { isOpen, open, close } = useOpen()
  const [v, setV] = React.useState()
  const [prevV, setPrevV] = React.useState()

  const init = R.path(["keyRegistryValue"], value)
  React.useEffect(() => {
    setV(
      init && {
        value: init.registryValueId,
        // currently different shape that comes from BE and FE
        name: init.registryValue || init.name,
      },
    )
  }, [init, init?.registryValueId])

  const tag = R.path(["registryCatalogTags", 0], question)

  if (!tag) return null

  const active = getIsActive(question, value, inputValue)

  const show =
    !readOnly &&
    (nonCustomRoot ||
      showAutofill({
        question,
        rootQuestion,
        isEnabled,
        indices,
        enabled,
        parentGroups,
        isFirstAnswerSet,
      }))

  const clear = () => {
    if (nonCustomRoot) {
      return onChange(undefined, undefined, undefined, true)
    }

    return clearGroup({
      onChangeFull,
      indices,
      values,
      question,
      rootQuestion,
    })
  }

  return (
    <>
      <Box flex acenter>
        <AutofillIcon
          active={active}
          content={getAutofillText(
            nonCustomRoot,
            question,
            tag,
            value,
            active,
            isFirstAnswerSet,
            prevAnswerSets,
            parentGroups,
          )}
        />
        {getIsOverriden(
          value,
          active,
          nonCustomRoot,
          isFirstAnswerSet,
          prevAnswerSets,
          parentGroups,
          question,
        ) && (
          <P gray fs={13} bold>
            Auto-filled data overriden
          </P>
        )}
      </Box>
      {show && (
        <SmartPortal cls={`q-${question.id}`} firstChild>
          <Box flex justifyContent="flex-end" mt={20} mb={20}>
            <Box flex acenter>
              <Box ml="auto" mr={10} flex>
                <P inline mr={4}>
                  Auto-fill
                </P>{" "}
                <Tooltip
                  content={
                    <P white>
                      {getAutofillTooltip(
                        question,
                        rootQuestion,
                        indices,
                        enabled,
                        nonCustomRoot,
                      )}
                    </P>
                  }
                >
                  <P inline bold link>
                    {getAutofillName(nonCustomRoot, question, tag)}
                  </P>
                </Tooltip>
                <P ml={4} inline>
                  data
                </P>
              </Box>
              <AutocompleteSelectRegistry
                value={v}
                onChange={x => {
                  if (!x && v) {
                    return open()
                  }

                  setV(x)
                  setPrevV(v)
                  return setSubject(x)
                }}
                registryType={tag.registryType}
              />
              <ButtonModal
                ml={10}
                icon={ListIcon}
                modal={EntityModal}
                registryValue={v}
                disabled={!v}
              />
            </Box>
          </Box>
        </SmartPortal>
      )}
      {subject && (
        <MultipleValuesModal
          question={question}
          onChange={onChange}
          subject={subject}
          closeModal={() => setSubject()}
          close={() => {
            setV(prevV)
            setSubject()
          }}
          closeClear={close}
          catalogs={catalogs}
          rootQuestion={rootQuestion}
          nonCustomRoot={nonCustomRoot}
          onChangeFull={onChangeFull}
          indices={indices}
          values={values}
          inputValue={inputValue}
          enabled={enabled}
          clear={clear}
        />
      )}
      {isOpen && (
        <ConfirmClearModal
          question={question}
          close={() => {
            close()
            // to reset text to current value
            setV(x => ({ ...x }))
          }}
          nonCustomRoot={nonCustomRoot}
          questionsWithValue={
            nonCustomRoot
              ? []
              : getQuestionsWithValue({
                  question,
                  values,
                  indices,
                  rootQuestion,
                  enabled,
                })
          }
          submit={() => {
            setV()
            clear()
          }}
        />
      )}
    </>
  )
}

export default Autofill

import React from "react"
import Button from "components/Button"
import Panel from "components/Panel"
import PlusIcon from "icons/Plus"
import { localize } from "locale"
import { Spread } from "styledComponents/Containers"
import EditBar from "./EditBar"
import Question from "./Question"
import Subsection from "./Subsection"
import { showAddButtons } from "../helpers"
import {
  showMoveSubsection,
  showMoveQuestion,
  showCopyQuestion,
  showCopySubsection,
  showCopySection,
} from "./helpers"

const Section = ({ section, ops, templateId, template, questions }) => (
  <div data-group-id={section.referencedId}>
    <EditBar
      data={section}
      type="section"
      ops={ops}
      templateId={templateId}
      template={template}
      questions={questions}
    />
    <Panel mb={20}>
      {showAddButtons(ops) && (
        <Spread>
          <Button
            secondary
            text={localize("addSubsection")}
            onClick={() => ops.addSection(section.id, null)}
            disabled={!!ops.editIndex}
          />
          <Button
            icon={PlusIcon}
            text={localize("addQuestion")}
            onClick={() => ops.addQuestion(section.id, null)}
            disabled={!!ops.editIndex}
          />
        </Spread>
      )}
      {showMoveSubsection(ops) && (
        <Button
          text={localize("moveSubsectionHere")}
          onClick={() => ops.onMoveConfirm(section.id, null)}
        />
      )}
      {showMoveQuestion(ops) && (
        <Button
          text={localize("moveQuestionHere")}
          onClick={() => ops.onMoveConfirm(section.id, null)}
        />
      )}
      {showCopyQuestion(ops) && (
        <Button
          text={localize("pasteQuestionHere")}
          onClick={() => ops.onCopyConfirm(section.id, null)}
        />
      )}
      {showCopySubsection(ops) && (
        <Button
          text={localize("pasteSubsectionHere")}
          onClick={() => ops.onCopyConfirm(section.id, null)}
        />
      )}
      {showCopySection(ops) && (
        <Button
          text={localize("pasteSectionHere")}
          onClick={() => ops.onCopyConfirm(section.id, null)}
        />
      )}
      {section.children.map((x, xi, arr) => {
        return x.nodeType === "question" ? (
          <Question
            questions={questions}
            key={x.id}
            section={section}
            question={x}
            templateId={templateId}
            template={template}
            ops={{ ...ops, nodeIndex: xi }}
          />
        ) : (
          <Subsection
            key={x.id}
            questions={questions}
            section={section}
            subsection={x}
            ops={{
              ...ops,
              nodeIndex: xi,
              subsectionIndex:
                xi -
                arr.slice(0, xi).filter(a => a.nodeType === "question").length,
            }}
            templateId={templateId}
            template={template}
          />
        )
      })}
    </Panel>
  </div>
)

export default Section

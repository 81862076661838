import { getQuestionGroup } from "data/autofill/helpers"
import * as R from "ramda"

const getChildren = (nodes, groups, questions) => node => {
  if (node.nodeType === "question") {
    return {
      ...node,
      data: R.omit(
        ["id"],
        questions.find(
          q =>
            q.id === node.referencedId ||
            q.templateQuestionId === node.referencedId,
        ),
      ),
    }
  }

  return {
    ...node,
    children: nodes
      .filter(n => n.parentId === node.id)
      .map(getChildren(nodes, groups, questions)),
    data: R.omit(
      ["id"],
      groups.find(g => g.id === node.referencedId),
    ),
  }
}

// beware, ugly mutation ahead
const addIndicesMutation = (nodes, questions) => {
  // add question indices
  let qi = 1
  nodes
    .filter(x => x.nodeType === "question")
    .forEach(x => {
      x.idx = qi++
    })

  const addIndicesToSqs = (q, parentGroups, parentIndices) => {
    let sqi = 1

    q.subQuestions?.forEach(y => {
      y.idx = sqi++
      y.parentGroups = parentGroups
      y.parentIndices = parentIndices

      addIndicesToSqs(
        y,
        parentGroups.concat(getQuestionGroup(y)),
        parentIndices.concat(y.id),
      )
    })
  }

  questions.forEach(x => addIndicesToSqs(x, [getQuestionGroup(x)], [x.id]))
}

export const filterQuestions =
  (predicate, subquestionPredicate = R.always(true)) =>
  node => {
    if (node.nodeType === "question") {
      const subQuestions = R.pathOr([], ["data", "subQuestions"], node).filter(
        subquestionPredicate,
      )
      return { ...node, data: { ...node.data, subQuestions } }
    }

    return {
      ...node,
      children: node.children
        .filter(y => y.nodeType !== "question" || predicate(y))
        .map(filterQuestions(predicate, subquestionPredicate)),
    }
  }

export const filterSections = (sections, predicate, subquestionPredicate) => {
  const sectWithFilteredQuestions = sections.map(
    filterQuestions(predicate, subquestionPredicate),
  )

  const filterEmptySection = node => {
    if (node.nodeType === "question") {
      return node
    }

    return {
      ...node,
      children: node.children
        .filter(x => {
          if (x.nodeType === "question") {
            return (
              x.data.questionType !== "Custom" || x.data.subQuestions.length > 0
            )
          }

          return x.children.length !== 0
        })
        .map(filterEmptySection),
    }
  }

  // would fail if we had another level of sections, but we dont
  return sectWithFilteredQuestions
    .map(filterEmptySection)
    .filter(x => x.children.length !== 0)
}

export const buildTree = ({ nodes, groups, questions }, predicate) => {
  // backend shouldn't send this
  const n = nodes.filter(x => x.type !== "subquestion")
  addIndicesMutation(n, questions)

  const topLvlSections = n.filter(x => R.isNil(x.parentId))
  const result = topLvlSections.map(getChildren(n, groups, questions))

  if (!predicate) return result

  return filterSections(result, predicate)
}

const extractChildren = x => {
  if (x.nodeType === "question") return [x]

  return R.chain(extractChildren, x.children)
}

export const getQuestionsFromTree = R.chain(extractChildren)

export const getQuestions = R.pipe(buildTree, getQuestionsFromTree)

import styled, { css } from "styled-components/macro"

export const TabsWrap = styled.div`
  display: flex;
  overflow-x: auto;
  overflow-y: hidden;
  position: relative;

  ${p =>
    p.inPanel &&
    css`
      height: 50px;
      box-shadow: 0 0.0625rem 0.1875rem 0 rgba(49, 57, 63, 0.2),
        0 0 0 0.0625rem rgba(49, 57, 63, 0.05);
      border: 1px solid #d8d8d8;
      border-radius: 0.125rem;
      background-color: #fff;
    `};
  ${p =>
    p.underline &&
    css`
      margin-bottom: 20px;
      padding: 0 10px;

      &::after {
        display: block;
        position: absolute;
        content: "";
        bottom: 1px;
        left: 0;
        background: #d8d8d8;
        width: 100%;
        height: 1px;
      }
    `};
`

import styled from "styled-components/macro"
import theme from "theme"

export const Unread = styled.div`
  position: absolute;
  border-radius: 2px;
  background-color: ${theme.color.error};
  color: #fff;
  padding: 0 5px;
  line-height: 18px;
  font-size: 11px;
  top: 0;
  right: 0;
  transform: translate(5%, -25%);
`

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 32 32">
    <path
      d="M349.028374,2465.6376 L362.637597,2452.02837 L363.998766,2453.38954 L350.389543,2466.99877 L363.974093,2480.58332 L362.610457,2481.94695 L349.025907,2468.3624 L335.362403,2482.02591 L334.001234,2480.66474 L347.664738,2467.00123 L334.025907,2453.3624 L335.389543,2451.99877 L349.028374,2465.6376 Z"
      transform="translate(-333.000000, -2451.000000)"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"
import { useHistory } from "react-router-dom"

const withRemountOnSameRoute = Component => props => {
  const [key, setKey] = React.useState(0)
  const history = useHistory()

  React.useEffect(() => {
    return history.listen((_, action) => {
      if (action !== "PUSH") return
      setKey(prev => prev + 1)
    })
  }, [history])

  return <Component key={key} {...props} />
}

export default withRemountOnSameRoute

import * as R from "ramda"

export const encodeQueryParams = obj => {
  const res = R.pipe(
    R.keys,
    R.reduce((acc, cur) => {
      const v = obj[cur]
      if (R.isNil(v) || R.isEmpty(v)) {
        return acc
      }

      if (R.type(v) === "Array") {
        const arrVal = v.map(x => `${cur}=${x}`).join("&")
        return [...acc, arrVal]
      }

      return [...acc, `${cur}=${v}`]
    }, []),
    R.join("&"),
  )(obj)

  return R.isEmpty(res) ? "" : "?" + res
}

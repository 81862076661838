import React from "react"
import Navigation from "./Navigation"
import Footer from "./Footer"
import SizeError from "./SizeError"
import ErrorNavigation from "./ErrorNavigation"
import { PageWrap, PageContent, PageBody } from "./styled"
import ErrorBoundary from "components/ErrorBoundary"
import Announcements from "components/Announcements"
import useMedia from "hooks/useMedia"
import withRemountOnPush from "hoc/withRemountOnPush"
import * as R from "ramda"

const withPage =
  ({ hideNav, title, titleOnly, center, noMobile, showAnnouncements } = {}) =>
  Component =>
  props => {
    const { isMobile } = useMedia()

    const showError = isMobile && noMobile

    return (
      <PageWrap>
        {!hideNav && !showError && (
          <Navigation title={title} titleOnly={titleOnly} />
        )}
        {showAnnouncements && <Announcements />}
        {showError && <ErrorNavigation />}
        <PageContent id="pageContent">
          <PageBody center={showError || center}>
            {showError ? (
              <SizeError />
            ) : (
              <ErrorBoundary>
                <Component {...props} />
              </ErrorBoundary>
            )}
          </PageBody>
          {!showError && <Footer />}
          <div id="footerSpacer" />
        </PageContent>
      </PageWrap>
    )
  }

export default config => R.compose(withPage(config), withRemountOnPush)

export const showMoveQuestionToStart = ({
  moveIndex,
  sectionIndex,
  subsectionIndex,
}) =>
  moveIndex &&
  moveIndex.type === "question" &&
  (moveIndex.sectionIndex !== sectionIndex ||
    moveIndex.subsectionIndex !== subsectionIndex ||
    moveIndex.subsectionNodeIndex > 0)

export const showCopyQuestionToStart = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "question"

export const showMoveSubsection = ({ moveIndex, sectionIndex, nodeIndex }) =>
  moveIndex &&
  moveIndex.type === "subsection" &&
  (moveIndex.sectionIndex !== sectionIndex ||
    (moveIndex.nodeIndex !== nodeIndex &&
      moveIndex.nodeIndex !== nodeIndex + 1))

export const showMoveQuestion = ({ moveIndex, sectionIndex, nodeIndex }) =>
  moveIndex &&
  moveIndex.type === "question" &&
  (moveIndex.sectionIndex !== sectionIndex ||
    moveIndex.subsectionIndex !== undefined ||
    (moveIndex.nodeIndex !== nodeIndex &&
      moveIndex.nodeIndex !== nodeIndex + 1))

export const showCopyQuestion = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "question"

export const showCopySubsection = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "subsection"

export const showCopySection = ({ copyIndex, sectionIndex }) =>
  copyIndex &&
  copyIndex.type === "section" &&
  copyIndex.sectionIndex !== sectionIndex &&
  !copyIndex.hasSubsection

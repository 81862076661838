import React from "react"
import img from "components/AppLogo/logo.png"
import Panel from "components/Panel"
import { formatDate } from "data/units/date/formatters"
import RichTextarea from "inputs/RichTextarea"
import { localize } from "locale"
import { Box, Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { Disclaimer, StyledImg } from "./styled"

const Conclusion = ({ conclusion, ...rest }) => (
  <Panel {...rest}>
    <Spread mb={20}>
      <StyledImg src={img} />
      {conclusion.approvedAtUtc && (
        <P mb="auto">
          {localize("date")}: {formatDate(conclusion.approvedAtUtc)}
        </P>
      )}
    </Spread>
    <RichTextarea value={conclusion.headerText} readOnly borderless />
    <Box mt={20}>
      {conclusion.conclusionDefinitions.map(x => (
        <RichTextarea key={x.id} value={x.text} readOnly borderless />
      ))}
    </Box>
    <Box mt={20} mb={20}>
      {conclusion.reasonings.map(x => (
        <RichTextarea key={x.id} value={x.text} readOnly borderless />
      ))}
    </Box>
    <RichTextarea value={conclusion.footerText} readOnly borderless />
    <Disclaimer>
      <P mb={15}>{localize("disclaimer")}:</P>
      <RichTextarea value={conclusion.disclaimerText} readOnly borderless />
    </Disclaimer>
  </Panel>
)

export default Conclusion

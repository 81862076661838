import React from "react"
import { P } from "styledComponents/Typography"
import FlashIcon from "icons/Flash"
import { IconWrap } from "styledComponents/Icons"
import Tooltip from "components/Tooltip"

const AutofillIcon = ({ active, content, "data-id": dataId }) => {
  return (
    <Tooltip content={<P white>{content}</P>}>
      <IconWrap data-id={dataId}>
        <FlashIcon active={active} />
      </IconWrap>
    </Tooltip>
  )
}

export default AutofillIcon

import React from "react"
import OverlayWrapper from "components/OverlayWrapper"
import { localize } from "locale"
import { LoaderSpinner, LoaderText, LoaderWrap, StyledLoader } from "./styled"
import Portal from "components/Portal"

const Loader = ({ center, small, loadingText = localize("loading") }) => {
  const el = (
    <LoaderWrap center={center}>
      <StyledLoader small={small}>
        {!small && <LoaderText>{loadingText}</LoaderText>}
        <LoaderSpinner small={small} />
        {!small && <LoaderText>{localize("pleaseWait")}</LoaderText>}
      </StyledLoader>
    </LoaderWrap>
  )

  return center ? (
    <Portal node={document.getElementById("loader")}>
      <OverlayWrapper isOpen>{el}</OverlayWrapper>
    </Portal>
  ) : (
    el
  )
}

export default Loader

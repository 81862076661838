import React from "react"
import CountTitle from "components/CountTitle"
import UserListItem from "./UserListItem"
import withFormField from "hoc/withFormField"
import * as R from "ramda"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"

const UserList = ({
  title,
  optionToAvatar,
  optionToTitle,
  optionToSubtitle,
  removeUser,
  clear,
  hideIf = () => false,
  customComponent = () => null,
  value,
  onCustomUpdate,
  readOnly,
}) => {
  if (value.length === 0) return null

  return (
    <>
      {!readOnly && title && (
        <CountTitle
          title={title}
          count={value.filter(x => !hideIf(x)).length}
          onClear={clear}
        />
      )}
      {value.map((x, xi) => {
        if (hideIf(x)) return null

        return (
          <UserListItem
            key={xi}
            data={x}
            readOnly={readOnly}
            optionToAvatar={optionToAvatar}
            optionToTitle={optionToTitle}
            optionToSubtitle={optionToSubtitle}
            onRemove={removeUser ? () => removeUser(xi, x) : undefined}
            customComponent={customComponent(x, xi, onCustomUpdate(xi))}
          />
        )
      })}
    </>
  )
}

export default R.compose(
  withFormField,
  withProps(p => ({ value: p.value || [] })),
  withHandlers({
    onCustomUpdate: p => i => v => p.onChange(R.update(i, v, p.value)),
  }),
)(UserList)

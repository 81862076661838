import { answerTypes } from "data/templates/answers"
import * as R from "ramda"
import {
  getQuestionGroup,
  flattenQuestion,
  getNewIndex,
  isQuestionEnabled,
} from "data/autofill/helpers"
import { getHandleChange } from "../helpers"
import { eq, nilEmpty } from "common/objects"

export const getAutofillName = (nonCustomRoot, question, tag) => {
  if (nonCustomRoot) return `Q${question.idx}`

  return tag.autofillGroupName || `Group ${tag.autoFillGroupNr}`
}

const getTags = value => {
  const allTags = R.pathOr([], ["registryValueTags"], value)
  const relevantTags = allTags.filter(
    x =>
      x.subType === "FirstName" ||
      x.subType === "MiddleName" ||
      x.subType === "LastName",
  )

  return {
    firstName: relevantTags.find(y => y.subType === "FirstName"),
    middleName: relevantTags.find(y => y.subType === "MiddleName"),
    lastName: relevantTags.find(y => y.subType === "LastName"),
    first: allTags[0],
  }
}

export const getAutofillText = (
  nonCustomRoot,
  question,
  tag,
  value,
  active,
  isFirstAnswerSet,
  prevAnswerSets,
  parentGroups,
) => {
  const name = getAutofillName(nonCustomRoot, question, tag)

  const regVal = R.pathOr([], ["keyRegistryValue"], value)
  const isEmpty = regVal.length === 0
  const subjectName = regVal.name || regVal.registryValue

  const group = getQuestionGroup(question)

  const prevAnswerAutofilled = R.pathOr(
    [],
    [0, "answers"],
    prevAnswerSets,
  ).find(x => !R.isNil(R.path(["keyRegistryValue", "registryValueId"], x)))
  if (
    !nonCustomRoot &&
    !isFirstAnswerSet &&
    prevAnswerAutofilled &&
    !parentIsRootOfGroup(parentGroups, group) &&
    isEmpty &&
    !active
  ) {
    return `Auto-fill data overridden. Answer manually added.`
  }

  if (!isEmpty && !active) {
    return `Auto-filled data overriden. Registry value for subject: ${subjectName} is different`
  }

  if (nonCustomRoot) {
    if (isEmpty) return `Auto-fill data`

    return `Data autofilled with subject: ${subjectName}`
  }

  if (isEmpty) return `Autofill ${name} data`

  return `${name} data auto-filled with subject ${subjectName}`
}

export const getIsOverriden = (
  value,
  active,
  nonCustomRoot,
  isFirstAnswerSet,
  prevAnswerSets,
  parentGroups,
  question,
) => {
  const regVal = R.pathOr([], ["keyRegistryValue"], value)
  const prevAnswerAutofilled = R.pathOr(
    [],
    [0, "answers"],
    prevAnswerSets,
  ).find(x => !R.isNil(R.path(["keyRegistryValue", "registryValueId"], x)))

  const group = getQuestionGroup(question)

  if (
    !nonCustomRoot &&
    !isFirstAnswerSet &&
    prevAnswerAutofilled &&
    !parentIsRootOfGroup(parentGroups, group) &&
    regVal.length === 0 &&
    !active
  ) {
    return true
  }

  return value.keyRegistryValue && !active
}

export const getIsActive = (question, value, inputValue) => {
  const tags = getTags(value)
  const allEmpty = [
    tags.firstName,
    tags.middleName,
    tags.lastName,
    tags.first,
  ].every(R.isNil)

  if (value.keyRegistryValue && allEmpty && inputValue === "") return true
  if (nilEmpty(value.keyRegistryValue) && allEmpty && nilEmpty(inputValue))
    return false

  const isName = question.questionType === answerTypes.name.value
  const getV = R.pathOr("", ["applicableRegistryValue"])

  if (isName) {
    const tagValue = {
      firstName: getV(tags.firstName),
      middleName: getV(tags.middleName),
      lastName: getV(tags.lastName),
    }

    return eq(tagValue, inputValue)
  }

  return getV(tags.first) === inputValue
}

const parentIsRootOfGroup = (parentGroups, group) =>
  !parentGroups ||
  parentGroups.length === 1 ||
  parentGroups[parentGroups.length - 1] !==
    parentGroups[parentGroups.length - 2] ||
  group !== R.last(parentGroups)

export const showAutofill = ({
  question,
  rootQuestion,
  isEnabled,
  indices,
  enabled,
  parentGroups,
  isFirstAnswerSet,
}) => {
  const group = getQuestionGroup(question)
  const seq = flattenQuestion(rootQuestion, false)

  if (R.isNil(group) || !isEnabled) {
    return false
  }

  if (!isFirstAnswerSet && !parentIsRootOfGroup(parentGroups, group)) {
    return false
  }

  const isFirstLevel =
    seq.find(y => y.id === question.id).parentIndices.length === 1
  const isMulti = R.path(
    ["registryCatalogTags", 0, "allowsMultipleRecords"],
    question,
  )
  if (!isFirstAnswerSet && isFirstLevel && isMulti) {
    return false
  }

  const firstOfGroup = seq.find(
    x =>
      getQuestionGroup(x) === group &&
      x.questionType !== "Custom" &&
      isQuestionEnabled(x, indices, enabled),
  )

  return firstOfGroup?.id === question.id
}

const getAutofillQuestionNames = (question, rootQuestion, indices, enabled) => {
  const group = getQuestionGroup(question)
  const seq = flattenQuestion(rootQuestion, false)

  return seq
    .filter(
      x =>
        getQuestionGroup(x) === group &&
        x.questionType !== "Custom" &&
        isQuestionEnabled(x, indices, enabled),
    )
    .map(x => `Q${x.indices.join(".")}`)
    .join(", ")
}
export const getAutofillTooltip = (
  question,
  rootQuestion,
  indices,
  enabled,
  nonCustomRoot,
) => {
  if (nonCustomRoot) return `Question Q${question.idx} will be auto-filled`

  return `Questions ${getAutofillQuestionNames(
    question,
    rootQuestion,
    indices,
    enabled,
  )} will be auto-filled`
}

export const clearGroup = ({
  onChangeFull,
  indices,
  values,
  question,
  rootQuestion,
}) => {
  const targetGroup = getQuestionGroup(question)
  let lastValue = values

  const seq = flattenQuestion(rootQuestion, false)
  const qPos = seq.findIndex(x => x.id === question.id)

  const parGroupsGrouped = R.groupWith(R.equals, question.parentGroups)
  const lastParGroup = R.last(parGroupsGrouped)
  const lastParGroupDepth = lastParGroup.length
  const parGroupDepth = R.sum(parGroupsGrouped.slice(0, -1).map(R.length))

  const rootGroupQuestionId = R.path(
    [-lastParGroupDepth],
    question.parentIndices,
  )

  const clearAnswer = (q, i, fil) => {
    q.subQuestions.forEach(sq => {
      const gr = getQuestionGroup(sq)

      if (gr === targetGroup && sq.questionType !== "Custom") {
        // const isMulti = R.path(
        //   ["registryCatalogTags", 0, "allowsMultipleRecords"],
        //   sq,
        // )

        const filterOutMultiAnswers = () => {
          const added = {}

          return R.pipe(
            R.over(
              R.lensPath(i.slice(0, -1)),
              R.filter(x => {
                const id = x.questionId

                if (id !== q.id) return true

                if (!added[id]) {
                  added[id] = true
                  return true
                }

                return false
              }),
            ),
            R.over(
              R.lensPath(i.slice(0, -1)),
              R.map(x => {
                const pos = seq.findIndex(y => y.id === x.questionId)

                const isAfter = pos >= qPos

                return added[x.questionId] && isAfter
                  ? {
                      ...x,
                      answers: x.answers.map(
                        R.pick(["id", "templateQuestionId"]),
                      ),
                    }
                  : x
              }),
            ),
          )(lastValue)
        }

        lastValue = fil ? filterOutMultiAnswers() : lastValue

        // in case there is no further onchange call, the filtration of multi answers has to happen
        onChangeFull(lastValue)

        // answer might have been deleted by `filterOutMultiAnswers` so check answer exists
        if (
          R.pathOr([], i.concat(["answers"]), lastValue).find(
            y => y.templateQuestionId === sq.id,
          )
        ) {
          lastValue = getHandleChange({
            indices: i.concat(["answers"]),
            values: lastValue,
            data: sq,
            onChange: onChangeFull,
          })(undefined, undefined, undefined, true)
        }
      }

      if (sq.questionType === "Custom") {
        const newI = getNewIndex(indices, i, values, sq)

        clearAnswer(sq, i.concat(["children", newI]), true)
      }
    })
  }

  clearAnswer(
    seq.find(x => x.id === rootGroupQuestionId),
    indices.slice(0, parGroupDepth * 2 + 1),
    false,
  )

  return lastValue
}

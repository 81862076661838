import styled, { css } from "styled-components/macro"
import { P } from "styledComponents/Typography"

export const PrevNextArrowsWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

export const Title = styled(P)`
  margin: 0 10px;
  cursor: pointer;
  padding: 2px 20px;
  border-radius: 2px;

  ${p =>
    p.openable &&
    css`
      &:hover {
        background: #eee;
      }
    `}
`

export const ArrowWrap = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${p =>
    p.rotate &&
    css`
      transform: rotate(180deg);
    `};

  svg {
    width: 16px;
    height: 16px;
  }
`

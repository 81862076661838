import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentRequest } from "data/assessments/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textfield from "inputs/Textfield"
import Checkbox from "inputs/Checkbox"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { stringRequired } from "validators/string"
import { boolOptional } from "validators/bool"
import * as R from "ramda"
import { P } from "styledComponents/Typography"

const CopyAssessmentModal = ({ close }) => (
  <>
    <Textfield
      required
      name="name"
      label={localize("nameOfAssessment")}
      placeholder={localize("assessmentCopyPlaceholder")}
      noEmptyError
    />
    <Checkbox
      name="preserveArrangementId"
      text={localize("preserveArrangementId")}
      flip
      squared
      noEmptyError
      mb={0}
    />
    <P ml={30} fs={14} gray>
      {localize("preserveArrangementIdTooltip")}
    </P>
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="Cancel" />
      <Button type="submit" text={localize("makeACopy")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(R.prop("title")),
  withUrls,
  withForm({
    mapProps: p => ({
      name: `${p.assessment.name}_${localize("copy")}`,
      preserveArrangementId: false,
    }),
    schema: R.always({
      name: stringRequired,
      preserveArrangementId: boolOptional,
    }),
    onSubmit: p => v => assessmentRequest.copy(p.assessment.id, v),
    onSuccess: p => p.close,
    redirect: (_, p) => p.urls.assessments.local,
    successText: p => p.successText,
  }),
)(CopyAssessmentModal)

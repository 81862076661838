import * as R from "ramda"
import { isCompatible } from "./TagForm/Simple/TagSelect/TagValidations/helpers"
import { getAllQuestionIdsFromNode } from "data/autofill/helpers"

export const getInitValue = p => {
  const d = R.pathOr([], ["tagData"], p)
  const ids = getAllQuestionIdsFromNode(p.node)

  // filter out such values that have id of this question or of any subquestion
  return d
    .filter(x => ids.includes(x.templateQuestionId))
    .map(x => {
      const optionFields = x.registryCatalogId
        ? {
            name: R.values(p.catalogs)
              .flatMap(R.identity)
              .find(y => y.id === x.registryCatalogId).fieldName,
            type: p.catalogs.individuals.find(y => y.id === x.registryCatalogId)
              ? "I"
              : "E",
          }
        : {}

      return {
        ...x,
        value: x.registryCatalogId,
        ...optionFields,
      }
    })
}

export const validateTags = (values, questions, catalogs) => {
  // check for incomplete
  // group by questionId, so name questions get merged
  const grouped = R.groupBy(R.prop("templateQuestionId"), values)
  if (
    R.values(grouped).some(x =>
      x.every(y => R.isNil(y.autoFillGroupNr) && R.isNil(y.registryCatalogId)),
    )
  ) {
    return false
  }

  // check for compatibility
  const allCatalogs = R.values(catalogs).flatMap(R.identity)
  if (
    values.some(value => {
      const catalogItem = allCatalogs.find(
        x => x.id === value.registryCatalogId,
      )
      const q = questions.find(x => x.id === value.templateQuestionId)

      return !isCompatible(q.questionType, catalogItem)
    })
  ) {
    return false
  }

  return true
}

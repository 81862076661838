import React from "react"
import Link from "components/Link"
import Status from "components/Status"
import { formatDate } from "data/units/date/formatters"
import Checkbox from "inputs/Checkbox"
import { localize } from "locale"
import dayjs from "dayjs"
import * as R from "ramda"
import theme from "theme"
import FavoriteButton from "components/FavoriteButton"
import ArrangementId from "components/ArrangementId"
import { assessmentRequest } from "data/assessments/api"
import { caseSuburls } from "pages/CaseCreatePage/suburls"

export const config = (urls, refetch) => {
  const getRedirUrl = x =>
    x.isPreAssessment
      ? `${urls.caseCreate(x.caseId, caseSuburls.preassessment)}/detail/${
          x.assessmentId
        }/settings`
      : urls.assessmentsCreate(x.assessmentId)

  return {
    columns: [
      {
        label: "",
        path: x =>
          x.isPreAssessment ? null : (
            <FavoriteButton
              request={assessmentRequest.setFavorite(x.assessmentId)}
              isFavorite={x.favorite}
              onSuccess={refetch}
            />
          ),
      },
      {
        label: localize("arrangementId"),
        path: x => (
          <ArrangementId
            arrangementId={x.arrangementId}
            fromTasks
            isPreassessment={x.isPreAssessment}
            caseId={x.caseId}
            getLink={x.isPreAssessment ? () => getRedirUrl(x) : undefined}
          />
        ),
      },
      {
        label: localize("name"),
        path: x => (
          <Link
            ul
            to={{
              pathname: getRedirUrl(x),
              state: { fromTasks: true },
            }}
          >
            {x.name}
          </Link>
        ),
        sortBy: R.prop("name"),
      },
      {
        label: localize("pwcSite"),
        path: R.prop("tenantName"),
        sortBy: R.prop("tenantName"),
      },
      {
        label: localize("createdOn"),
        path: R.pipe(R.prop("createdAtUtc"), formatDate),
        sortBy: R.prop("createdAtUtc"),
      },
      {
        label: localize("dueDate"),
        path: x => (
          <Status
            color={
              dayjs(x.dueDate).isBefore(dayjs(), "days")
                ? theme.color.error
                : null
            }
            text={formatDate(x.dueDate)}
          />
        ),
        sortBy: R.prop("dueDate"),
      },
      {
        label: localize("action"),
        path: x => (
          <Link
            to={{
              pathname: getRedirUrl(x),
              state: { fromTasks: true },
            }}
          >
            <Status color={theme.color.red} text={localize(x.action)} />
          </Link>
        ),
      },
    ],
    filters: [
      {
        name: "favorite",
        render: (value, onChange) => (
          <Checkbox
            value={value}
            onChange={onChange}
            text={localize("showFavorites")}
          />
        ),
        filter: (filterValue, item) => !filterValue || item.favorite,
      },
      {
        name: "overdue",
        render: (value, onChange) => (
          <Checkbox
            value={value}
            onChange={onChange}
            text={localize("showOverdue")}
          />
        ),
        filter: (filterValue, item) =>
          !filterValue || dayjs(item.dueDate).isBefore(dayjs(), "days"),
      },
    ],
    search: (searchValue, item) => {
      const s = (searchValue || "").toLowerCase()
      const a = item.name.toLowerCase()
      const b = item.arrangementId.toLowerCase()

      return a.includes(s) || b.includes(s)
    },
  }
}

import React from "react"

export default () => (
  <svg width="20" height="20" viewBox="0 0 24 24">
    <path
      d="M3.33481956,7.54655344 L3.6379844,20.8137675 C3.6559942,21.699249 4.02669577,22.4961823 4.61351485,23.0709949 C4.76059482,23.2150732 4.92118214,23.3441434 5.09227517,23.4582054 C5.43596205,23.6878303 5.82617423,23.8514192 6.2434011,23.9354649 C6.45351535,23.9789886 6.66963286,24 6.89025282,24 L17.7336489,24 C18.6191304,24 19.4235678,23.6458074 20.0103869,23.0709949 C20.5987068,22.4961823 20.9694084,21.699249 20.9874182,20.8137675 L21.2890822,7.54655344 L3.33481956,7.54655344 Z M22.1239862,1.50081607 L18.0177534,1.50081607 C18.0177534,0.672365599 17.3453878,0 16.5169373,0 L8.1078649,0 C7.27791362,0 6.60704883,0.672365599 6.60704883,1.50081607 L2.50081607,1.50081607 C1.67086478,1.50081607 1,2.17318167 1,3.00163214 C1,3.83008261 1.67086478,4.50244821 2.50081607,4.50244821 L22.1239862,4.50244821 C22.9539375,4.50244821 23.6248022,3.83008261 23.6248022,3.00163214 C23.6248022,2.17318167 22.9539375,1.50081607 22.1239862,1.50081607 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"
import CaretIcon from "icons/Caret"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"
import InputAnswers from "inputs/InputAnswers"
import { isSingleMultipleOrConfirmation } from "data/templates/answers"
import * as R from "ramda"

const Answers = ({ questionType, values }) => {
  const { isOpen, toggle } = useOpen(false)
  const hasAnswers = R.pathOr([], ["options"], values).length > 0

  if (!isSingleMultipleOrConfirmation(questionType)) return null

  if (!hasAnswers) return <P mb={10}>{localize("noAnswers")}</P>

  return (
    <>
      <Box mb={isOpen ? 20 : 0} onClick={toggle} flex>
        <P red link>
          {localize("answers")}
        </P>
        <IconWrap static rotate={+isOpen}>
          <CaretIcon />
        </IconWrap>
      </Box>
      {isOpen && (
        <InputAnswers
          value={values.options}
          type={questionType}
          readOnly
          borderless
          withAttachments
          noEmptyError
          mb={0}
        />
      )}
    </>
  )
}

export default Answers

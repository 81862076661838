import { EnhancedReportPage } from "pages/ReportPage"
import List from "./List"

export const getSuburls = match => {
  const path = match.path
  const url = match.url

  const urls = {
    list: {
      path: `${path}/list`,
      url: `${url}/list`,
      component: List,
    },
    report: {
      path: `${path}/report/:id`,
      url: id => `${url}/report/${id}`,
      component: EnhancedReportPage,
    },
  }

  return urls
}

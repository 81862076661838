import styled, { css } from "styled-components/macro"
import Link from "components/Link"
import theme from "theme"

export const AppLogoWrap = styled.div`
  display: flex;
  align-items: center;

  ${p =>
    p.column &&
    css`
      flex-direction: column;
    `};
`

export const LogoLink = styled(Link)`
  width: 50px;
  height: 50px;
  display: flex;
  align-items: center;
  margin-left: 15px;
  flex-shrink: 0;

  img {
    width: 100%;
  }

  ${p =>
    p.nolink &&
    css`
      pointer-events: none;
    `}
`

export const AppName = styled.h1`
  font-family: Georgia;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
  padding: 0;
  color: #333;

  ${theme.mq.desktop} {
    font-size: 20px;
  }

  ${p =>
    !p.column &&
    css`
      border-left: 0.0625rem solid #d8d8d8;
      padding-left: 20px;
      margin-left: 20px;
    `}
`

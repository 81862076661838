import React from "react"
import { addAnswerSet, getIsEnabled } from "./helpers"
import Button from "components/Button"
import SimpleAnswer from "./SimpleAnswer"
import Ribbons from "components/Ribbons"
import { localize } from "locale"
import Panel from "components/Panel"
import PlusIcon from "icons/Plus"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import CrossIcon from "icons/Cross"
import * as R from "ramda"
import useOpen from "hooks/useOpen"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import { getQuestionGroup } from "data/autofill/helpers"

const Answer = ({
  data,
  value,
  values,
  onChange,
  indices = [],
  assessment,
  enabled,
  first,
  seq,
  readOnly,
  disabledByParent,
  rootEnabled,
  rootOpen,
  catalogs,
  rootQuestion,
  parentGroups,
  answerIndices = [],
}) => {
  const { isOpen, toggle } = useOpen()

  const isCustom = data.questionType === "Custom"
  // top level is calculated on backend
  const isTopLevel = indices.length === 0
  const isEnabled = getIsEnabled({
    disabledByRoot: !rootEnabled,
    disabledByParent,
    isTopLevel,
    indices,
    enabled,
    data,
  })

  const shared = {
    values,
    onChange,
    assessment,
    enabled,
    disabledByParent: !isEnabled,
    readOnly,
    catalogs,
    rootQuestion,
  }

  // this is just for non-custom root questions
  if (!isCustom) {
    return (
      <SimpleAnswer
        {...shared}
        data={data}
        indices={[0, "answers"]}
        nonCustomRoot
        first
        disabledByRoot={!rootEnabled}
        openByRoot={rootOpen}
      />
    )
  }

  let count = 0

  const bg = isEnabled ? undefined : "#eee"
  const showContent = isEnabled || isOpen || rootOpen

  const currParentGroups = parentGroups
    ? parentGroups
    : [getQuestionGroup(data)]

  return (
    <Panel mt={first ? 0 : 20} bg={bg} noPanel={isTopLevel}>
      <Box flex mb={showContent ? 10 : 0} acenter>
        <P mr="auto" title={`ID: ${data.id}`}>
          {seq.length > 1 && (
            <>
              <P
                inline
                bold
                required={data.isMandatory}
                mr={data.isMandatory ? 10 : 0}
                data-qnum={`Q${seq.join(".")}`}
              >
                Q{seq.join(".")}:
              </P>{" "}
            </>
          )}
          {data.questionText}
        </P>
        {!isEnabled && !isTopLevel && (
          <IconWrap rotate={+isOpen} onClick={toggle}>
            <OutlineArrow />
          </IconWrap>
        )}
      </Box>
      {(value || []).map((answerSet, i, arr) => {
        if (answerSet.questionId !== data.id) {
          return null
        }
        if (!showContent) return null

        count++
        const newAnswerIndices = answerIndices.concat(count)

        const isFirstAnswerSet =
          arr.findIndex(x => data.id === x.questionId) === i
        const prevAnswerSets = arr
          .slice(0, i)
          .filter(x => data.id === x.questionId)

        const newIndices = indices.concat([i])
        const newReadOnly = readOnly || !isEnabled
        return (
          <Panel
            key={i}
            relative
            mt={20}
            bg={bg}
            data-qanswer={newAnswerIndices.join(".")}
          >
            <Ribbons
              ribbons={[`${localize("answer")} ${count}`].concat(
                data.reportTypes || [],
              )}
            />
            <>
              {!newReadOnly && (
                <Box flex mb={10}>
                  <IconWrap
                    ml="auto"
                    autowidth
                    onClick={() => {
                      const newVal = R.over(
                        R.lensPath(newIndices.slice(0, -1)),
                        R.remove(R.last(newIndices), 1),
                        values,
                      )
                      onChange(newVal)
                    }}
                  >
                    <CrossIcon />
                  </IconWrap>
                </Box>
              )}
              {!data.subQuestions && (
                <P red>{localize("noSubquestionDefined")}</P>
              )}
              {(data.subQuestions || []).map((x, xi) => {
                const gr = getQuestionGroup(x)
                const newParentGroups = currParentGroups.concat(gr)

                if (x.questionType === "Custom") {
                  return (
                    <div
                      data-qnumber={`Q${x.nrLabel}`}
                      data-qgroup={getQuestionGroup(x)}
                      key={x.id}
                    >
                      <Answer
                        data={x}
                        indices={newIndices.concat(["children"])}
                        value={value[i].children}
                        first={xi === 0}
                        seq={seq.concat(xi + 1)}
                        readOnly={newReadOnly}
                        parentGroups={newParentGroups}
                        answerIndices={newAnswerIndices}
                        {...shared}
                      />
                    </div>
                  )
                }

                return (
                  <div
                    className={`q-${x.id}`}
                    data-qnumber={`Q${x.nrLabel}`}
                    data-qgroup={getQuestionGroup(x)}
                    key={x.id}
                  >
                    <div className={`q-${x.id}:before`} />
                    <SimpleAnswer
                      data={x}
                      indices={newIndices.concat(["answers"])}
                      first={xi === 0}
                      seq={seq.concat(xi + 1)}
                      readOnly={newReadOnly}
                      parentQuestion={data}
                      parentGroups={currParentGroups}
                      isFirstAnswerSet={isFirstAnswerSet}
                      prevAnswerSets={prevAnswerSets}
                      answerIndices={newAnswerIndices}
                      {...shared}
                    />
                  </div>
                )
              })}
            </>
          </Panel>
        )
      })}
      {!readOnly && isEnabled && (
        <Button
          mt={20}
          icon={PlusIcon}
          secondary
          text={`${localize("addMoreAnswers")} Q${seq.join(".")}`}
          onClick={() => onChange(addAnswerSet(data, values, indices))}
        />
      )}
    </Panel>
  )
}

export default Answer

import React from "react"
import Text from "components/Text"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { statuses } from "data/templates/statuses"
import SupportiveInformation from "./SupportiveInformation"
import Subquestions from "./Subquestions"
import Answers from "./Answers"
import Precondition from "./Precondition"
import Flags from "./Flags"
import LabelValue from "components/LabelValue"

const QuestionView = ({ question, ops, templateId, questions, template }) => {
  const d = question.data
  const canBeMoved = template.status === statuses.inProcess.value

  return (
    <>
      <Precondition questions={questions} question={question} values={d} />
      <Box mt={20} mb={20}>
        <LabelValue
          label={localize("question")}
          value={<Text>{d.questionText}</Text>}
        />
      </Box>
      <Box responsive>
        <Box minWidth={612}>
          <Flags values={d} />
        </Box>
      </Box>
      <Box mt={20}>
        <Answers values={d} questionType={d.questionType} />
        <Subquestions
          question={question}
          ops={ops}
          canBeMoved={canBeMoved}
          questionType={d.questionType}
          values={d}
        />
      </Box>
      <Box mt={20}>
        <SupportiveInformation
          template={template}
          templateId={templateId}
          values={d}
        />
      </Box>
    </>
  )
}

export default QuestionView

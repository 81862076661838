import { localize } from "locale"

export const relationOptions = [
  { value: "is", name: localize("is") },
  { value: "isNot", name: localize("isNot") },
]

export const operationOptions = [
  { value: "and", name: localize("and") },
  { value: "or", name: localize("or") },
]

export const termToFormula = term => ({
  operationBefore: operationOptions[0].value,
  item: term,
})

export const defaultTerm = {
  questionId: undefined,
  relation: relationOptions[0].value,
  answerId: undefined,
}

export const defaultCondition = {
  operationBefore: operationOptions[0].value,
  item: defaultTerm,
}

export const defaultBrackets = {
  operationBefore: operationOptions[0].value,
  item: [],
}

export const defaultLogic = [
  {
    operationBefore: operationOptions[0].value,
    item: null,
  },
]

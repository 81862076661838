import React from "react"
import { preassessmentRequest } from "data/preassessments/api"
import ConfirmModal from "components/ConfirmModal"
import InfoModal from "components/InfoModal"
import { preconclusionRequest } from "data/preassessments/api"
import { conclusionStatuses } from "data/assessments/statuses"

const RestoreModalButton = ({ case_, onSuccess, detail, setDetail }) => {
  const handleClose = () => {
    setDetail()
  }

  return (
    <>
      {case_.hasActivePreAssessment ? (
        <InfoModal
          title="Pre-assessment already exists for the case"
          body="Pre-assessment cannot be restored, because there is already existing pre-assessment under the case. Only 1 pre-assessment for the case is allowed."
          close={handleClose}
        />
      ) : (
        <ConfirmModal
          close={handleClose}
          onSuccess={onSuccess}
          title="Restore pre-assessment"
          confirmText={`Pre-assessment ${detail.name} will be restored to ${
            detail.status === conclusionStatuses.conclusionDeleted.value
              ? '"Approved"'
              : '"In Progress"'
          } status, and all relevant coworkers will receive notifications.`}
          confirmQuestion="Do you want to restore the pre-assessment?"
          cancelText="No"
          submitText="Yes"
          successText={() =>
            `You have restored the pre-assessment ${detail.name}.`
          }
          request={() =>
            (detail.conclusionType
              ? preconclusionRequest
              : preassessmentRequest
            ).restore({
              assessmentIds: [detail.id],
            })
          }
        />
      )}
    </>
  )
}

export default RestoreModalButton

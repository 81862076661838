import React from "react"
import SettingsForm from "./SettingsForm"
import Panel from "components/Panel"
import TemplateSettings from "pages/TemplatesCreatePage/SettingsForm"
import { localize } from "locale"

const Settings = ({ assessment, template, templateId, profile, ...rest }) => (
  <>
    <SettingsForm {...rest} data={assessment} templateId={templateId} />
    <Panel title={localize("usedTemplateDetails")}>
      <TemplateSettings template={template} preview profile={profile} />
    </Panel>
  </>
)

export default Settings

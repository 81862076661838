import React from "react"
import Warning from "components/Warning"
import * as R from "ramda"
window.R = R
const getQuestionIndices = (k, questions, indices = []) => {
  // its a key in an object so it is a string
  if (!questions) return null

  const id = +k
  const index = questions.findIndex(q => q.id === id)

  if (index >= 0) return indices.concat(index)

  return questions
    .map((x, xi) => getQuestionIndices(k, x.subQuestions, indices.concat(xi)))
    .find(x => x)
}

const QuestionValidationMessages = ({ questionMessages, questions }) => (
  <>
    {R.pipe(
      R.mapObjIndexed((v, k) => [
        getQuestionIndices(
          k,
          questions.map(x => ({ ...x.data, id: x.referencedId })),
        ),
        v,
      ]),
      R.values,
      x => {
        const longestArray = Math.max(...x.map(y => y[0].length))
        const sorters = R.range(0, longestArray).map(i =>
          R.ascend(R.path([0, i])),
        )

        return R.sortWith(sorters)(x)
      },
      R.map(
        ([indices, messages]) =>
          `Q${(indices || []).map(R.inc).join(".")}: ${messages.join(", ")}`,
      ),
      R.map(x => <Warning key={x} error text={x} />),
    )(questionMessages)}
  </>
)

export default QuestionValidationMessages

import React from "react"
import Button from "components/Button"
import useOpen from "hooks/useOpen"

const ButtonModal = ({ modal: Modal, text, onClose, ...rest }) => {
  const { isOpen, open, close } = useOpen()

  return (
    <>
      <Button {...rest} onClick={open} text={text} />
      {isOpen && (
        <Modal
          {...rest}
          open={open}
          close={e => {
            if (onClose) onClose(e)
            close(e)
          }}
        />
      )}
    </>
  )
}

export default ButtonModal

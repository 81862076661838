import React from "react"
import * as R from "ramda"
import withProps from "hoc/withProps"
import { Box } from "styledComponents/Containers"
import Button from "components/Button"
import { localize } from "locale"
import { getShowFileUpload, isAttachmentRequired } from "./attachments"
import AnswerFileUpload from "./AnswerFileUpload"
import Answer from "./Answer"
import {
  assessmentLibraryQuestionRequest,
  assessmentQuestionRequest,
} from "data/assessments/api"
import { preassessmentQuestionRequest } from "data/preassessments/api"

const QuestionAnswer = ({
  data,
  values,
  onChange,
  submit,
  assessment,
  enabled,
  isPreview,
  attachments,
  setAttachments,
  readOnly,
  rootEnabled,
  rootOpen,
  attachmentRequests,
  catalogs,
}) => {
  const showFileUpload = getShowFileUpload(data, values)
  const attachmentRequired = isAttachmentRequired(data, values)

  return (
    <>
      <Answer
        first
        seq={[data.idx]}
        data={data}
        rootQuestion={data}
        values={values}
        value={values}
        onChange={onChange}
        assessment={assessment}
        enabled={enabled}
        readOnly={readOnly}
        rootEnabled={rootEnabled}
        rootOpen={rootOpen}
        catalogs={catalogs}
      />
      {showFileUpload && (
        <Box mt={10}>
          <AnswerFileUpload
            attachments={attachments}
            setAttachments={setAttachments}
            assessmentId={assessment.id}
            questionId={data.id}
            readOnly={readOnly}
            required={attachmentRequired}
            isPreview={isPreview}
            attachmentRequests={attachmentRequests}
          />
        </Box>
      )}
      {!readOnly && (
        <Box flex mt="20">
          <Button onClick={submit} text={localize("saveAndNext")} ml="auto" />
        </Box>
      )}
    </>
  )
}

export default R.compose(
  withProps(p => {
    const getRequests = () => {
      if (p.isLibrary) {
        return assessmentLibraryQuestionRequest
      }

      if (p.isPreassessment) {
        return preassessmentQuestionRequest
      }

      return assessmentQuestionRequest
    }

    return {
      attachmentRequests: getRequests(),
    }
  }),
)(QuestionAnswer)

import * as R from "ramda"

export const incrementLast = arr =>
  arr.length > 0 ? [...arr.slice(0, -1), R.last(arr) + 1] : []

export const splitBool = (predicate, arr) => {
  const satisfies = []
  const notSatisfies = []

  arr.forEach(x => (predicate(x) ? satisfies.push(x) : notSatisfies.push(x)))

  return [satisfies, notSatisfies]
}

export const eqArrays = (a, b) =>
  a.length === b.length && a.every((x, xi) => x === b[xi])

export const arrEmpty = arr => !arr || !arr.length || arr.every(x => !x)

import React from "react"
import { TabsWrap } from "./styled"
import Tab from "./Tab"

const Tabs = ({ options, underline, inPanel, ...rest }) => (
  <TabsWrap inPanel={inPanel} underline={underline}>
    {options.map((o, i) => (
      <Tab
        key={i}
        index={i}
        option={o}
        inPanel={inPanel}
        underline={underline}
        {...rest}
      />
    ))}
  </TabsWrap>
)

export default Tabs

import React from "react"
import withPage from "hoc/withPage"
import { useHistory } from "react-router"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import AppLogo from "components/AppLogo"
import Button from "components/Button"
import AppError from "components/AppError"
import * as R from "ramda"
import { P } from "styledComponents/Typography"
import { logout } from "data/auth/rx"

const is403 = R.pathEq(["error", "response", "status"], 403)

const getErrorTitle = (noModule, error) => {
  if (noModule) {
    return localize("noModuleError")
  }

  if (is403(error)) {
    return localize("noRoleAssigned")
  }

  return localize("noAccess")
}

const ErrorPage = ({ error, noModule, loggedIn, urls }) => {
  const history = useHistory()

  React.useEffect(() => {
    history.push(urls.home)
  }, []) // eslint-disable-line

  return (
    <>
      <AppLogo />
      <AppError title={getErrorTitle(noModule, error)} />
      {is403(error) && <P>{localize("contactSupportForAccess")}</P>}
      {loggedIn && <Button text={localize("logout")} onClick={logout} />}
    </>
  )
}

export default R.compose(
  withPage({ hideNav: true, center: true }),
  withUrls,
)(ErrorPage)

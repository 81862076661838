import React from "react"
import { Col, Row } from "./styled"

const Columns = ({
  children,
  gap = 15,
  align,
  widths,
  noCols,
  flexColumn,
  ...rest
}) => (
  <Row align={align} noCols={noCols} {...rest}>
    {children
      .filter(x => x)
      .map((c, ci, arr) => (
        <Col
          key={ci}
          index={ci}
          count={arr.length}
          gap={gap}
          widths={widths}
          noCols={noCols}
          flexColumn={flexColumn}
        >
          {c}
        </Col>
      ))}
  </Row>
)

export default Columns

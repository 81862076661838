import { hasInSubquestions } from "../helpers"

export const getIsOpen = ({ value, subquestion, lastSelectedValue }) => {
  const val =
    value?.questionId || value?.questionOptionId ? value : lastSelectedValue

  if (!val) return false

  // A: is subtype of this subquestion
  if (val.questionId === subquestion.id && val.subType) return true

  // is option of this subquestion
  if (
    subquestion.options.find(y => y.id === val.questionOptionId) ||
    hasInSubquestions(x => x.options.find(y => y.id === val.questionOptionId))(
      subquestion,
    )
  ) {
    return true
  }

  // is subquestion of this subquestion
  if (
    subquestion.subQuestions.find(y => y?.id === val.questionId) ||
    hasInSubquestions(x => x.subQuestions.find(y => y?.id === val.questionId))(
      subquestion,
    )
  ) {
    return true
  }

  return false
}

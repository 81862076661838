import React from "react"
import { filterSafeProps } from "common/react"
import OutlineAlert from "icons/appkit/outline-alert"
import Text from "components/Text"
import { WarningWrap } from "./styled"

const Warning = ({ text, error, fs, ...rest }) => (
  <WarningWrap error={error} {...filterSafeProps(rest)}>
    <OutlineAlert />
    <Text ml={10} fs={fs}>
      {text}
    </Text>
  </WarningWrap>
)

export default Warning

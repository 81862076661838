import React from "react"
import withModal from "hoc/withModal"
import { localize } from "locale"
import Link from "components/Link"
import { P } from "styledComponents/Typography"

const ImprintModal = () => (
  <>
    <P italic mb={40} bold fs={24}>
      English:
    </P>
    <P mb={20}>
      Imprint pursuant to Section 5 Telemediengesetz (German Telemedia Act) and
      translation of the Company Information according to Sections 2 and 3
      Dienstleistungs-Informationspflichten-Verordnung (German By-Law on
      Information Duties with respect to Services - DL-InfoV).
    </P>
    <P bold>PricewaterhouseCoopers GmbH</P>
    <P mb={20}>Wirtschaftsprüfungsgesellschaft</P>
    <P>Friedrich-Ebert-Anlage 35-37</P>
    <P mb={20}> 60327 Frankfurt am Main</P>
    <P bold>Chairman of the Supervisory Board:</P>
    <P mb={40}>WP StB Dr. Norbert Vogelpoth</P>

    <P bold>Management Board:</P>
    <P mb={20}>
      WP StB Petra Justenhoven • WP Stefan Frühauf • WP Daniela Geretshuber •
      Rusbeh Hashemian • FCA Erik Hummitzsch • WP Clemens Koch • Damir Maras •
      WP StB Dietmar Prümm • StB RA Björn Viebrock
    </P>
    <P>
      <P bold inline>
        Email:
      </P>{" "}
      <Link to="mailto:DE_Kontakt@pwc.com">DE_Kontakt@pwc.com</Link>
    </P>
    <P>
      <P bold inline>
        Phone:
      </P>{" "}
      +49 69 9585-0
    </P>
    <P mb={20}>
      <P bold inline>
        Fax:
      </P>{" "}
      +49 69 9585-1000
    </P>
    <P bold>Supervisory Authority:</P>
    <P mb={20}>
      final responsibility: Abschlussprüferaufsichtsstelle (Commission for the
      Oversight of Auditors in Germany (Auditor Oversight Body - APAS),
      Uhlandstraße 88-90, 10717 Berlin Deutschland; Wirtschaftsprüferkammer
      (German Chamber of Public Accountants - WPK), Körperschaft des
      öffentlichen Rechts, Rauchstraße 26, 10787 Berlin, Deutschland
    </P>
    <P mb={20}>
      <P bold inline>
        Domicile:
      </P>{" "}
      Frankfurt am Main • Registered in the Commercial Register at Amtsgericht
      (German District Court) Frankfurt am Main HRB No. 107858
      PricewaterhouseCoopers GmbH Wirtschaftprüfungsgesellschaft is a German
      Wirtschaftsprüfungsgesellschaft (German public audit firm) both admitted
      and registered in Germany in the Berufsregister (public register of
      Wirtschaftsprüfer and Wirtschaftsprüfungsgesellschaften) as number 150 9
      337 by the Wirtschaftsprüferkammer (German Chamber of Public Accountants).
    </P>
    <P bold>
      Professional provisions and regulations applicable to the services:
    </P>
    <P ul ml={20}>
      <li>Wirtschaftsprüferordnung (WPO)</li>
      <li>
        Berufssatzung für Wirtschaftsprüfer/vereidigte Buchprüfer (BS WP/vBP)
      </li>
      <li>Satzung für Qualitätskontrolle</li>
    </P>
    <P mb={20}>
      You can find these provisions and information on them (in German language)
      at: <Link to="http://www.wpk.de/">http://www.wpk.de/</Link>
    </P>
    <P mb={20}>
      <P bold inline>
        Value Added Tax Identification No.:
      </P>{" "}
      DE194 821 795
    </P>
    <P mb={20}>
      <P bold inline>
        Professional Insurance:
      </P>{" "}
      PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft has insurance
      protection as required under Section 54 WPO from Versicherungsstelle
      Wiesbaden, Dotzheimer Str. 23, 65185 Wiesbaden, Germany. The range of this
      insurance protection is not locally restricted.
    </P>
    <P>
      <P bold inline>
        Codes of Conduct:
      </P>{" "}
      PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft adheres to the
      following Codes of Conduct:
    </P>
    <P ul ml={20} mb={40}>
      <li>
        PwC-Ethik-Grundsätze / PwC Code of Conduct (available for download in
        German language at{" "}
        <Link to="https://www.pwc.de/de/unternehmensinformationen/ethikgrundsaetze.html">
          http://www.pwc.de/de/ethikcode
        </Link>
        )
      </li>
      <li>
        Ten Principles of UN Global Compact (available in German and English
        language at{" "}
        <Link to="http://www.globalcompact.de/">www.globalcompact.de</Link>)
      </li>
    </P>

    <P italic mb={40} bold fs={24}>
      Deutsch:
    </P>
    <P mb={20}>
      Unternehmensangaben nach § 5 Telemediengesetz (TMG) und §§ 2,3
      Dienstleistungs-Informationspflichten-Verordnung (DL-InfoV).
    </P>
    <P bold>PricewaterhouseCoopers GmbH</P>
    <P mb={20}>Wirtschaftsprüfungsgesellschaft</P>
    <P>Friedrich-Ebert-Anlage 35-37</P>
    <P mb={20}> 60327 Frankfurt am Main</P>
    <P bold>Vorsitzender des Aufsichtsrats:</P>
    <P mb={40}>WP StB Dr. Norbert Vogelpoth</P>

    <P bold>Geschäftsführer:</P>
    <P mb={20}>
      WP StB Petra Justenhoven • WP Stefan Frühauf • WP Daniela Geretshuber •
      Rusbeh Hashemian • FCA Erik Hummitzsch • WP Clemens Koch • Damir Maras •
      WP StB Dietmar Prümm • StB RA Björn Viebrock
    </P>
    <P>
      <P bold inline>
        E-Mail:
      </P>{" "}
      <Link to="mailto:DE_Kontakt@pwc.com">DE_Kontakt@pwc.com</Link>
    </P>
    <P>
      <P bold inline>
        Telefonzentrale:
      </P>{" "}
      +49 69 9585-0
    </P>
    <P mb={20}>
      <P bold inline>
        Fax:
      </P>{" "}
      +49 69 9585-1000
    </P>
    <P bold>Aufsichtsbehörde:</P>
    <P mb={20}>
      letztverantwortlich: Abschlussprüferaufsichtsstelle (APAS), Uhlandstraße
      88-90, 10717 Berlin, Deutschland; Wirtschaftsprüferkammer (WPK),
      Körperschaft des öffentlichen Rechts, Rauchstraße 26, 10787 Berlin,
      Deutschland
    </P>
    <P mb={20}>
      <P bold inline>
        Sitz:
      </P>{" "}
      Frankfurt am Main • Amtsgericht Frankfurt am Main HRB 107858 Die
      PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft ist eine in
      Deutschland von der Wirtschaftsprüferkammer zugelassene und im
      Berufsregister unter der laufenden Nummer 150 9 337 eingetragene
      Wirtschaftsprüfungsgesellschaft.
    </P>
    <P bold>Berufsrechtliche Regelungen:</P>
    <P ul ml={20}>
      <li>Wirtschaftsprüferordnung (WPO)</li>
      <li>
        Berufssatzung für Wirtschaftsprüfer/vereidigte Buchprüfer (BS WP/vBP)
      </li>
      <li>Satzung für Qualitätskontrolle</li>
    </P>
    <P mb={20}>
      Informationen zu diesen Regelungen finden Sie auf der Internetseite der
      Wirtschaftsprüferkammer:{" "}
      <Link to="http://www.wpk.de/">http://www.wpk.de/</Link>
    </P>
    <P mb={20}>
      <P bold inline>
        Umsatzsteueridentifikations-Nr.:
      </P>{" "}
      DE194821795
    </P>
    <P mb={20}>
      <P bold inline>
        Berufshaftpflichtversicherung:
      </P>{" "}
      Die PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft unterhält
      die nach § 54 WPO erforderliche Berufshaftpflichtversicherung bei der VSW
      – Die Versicherergemeinschaft für Steuerberater und Wirtschaftsprüfer,
      Dotzheimer Str. 23, 65185 Wiesbaden, Deutschland. Der räumliche
      Geltungsbereich des Versicherungsschutzes ist nicht beschränkt.
    </P>
    <P>
      <P bold inline>
        Verhaltenskodizes:
      </P>{" "}
      Die PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft hat sich
      folgenden Verhaltenskodizes unterworfen:
    </P>
    <P ul ml={20}>
      <li>
        PwC-Ethik-Grundsätze/PwC Code of Conduct (zum Download in deutscher
        Sprache zugänglich über{" "}
        <Link to="https://www.pwc.de/de/unternehmensinformationen/ethikgrundsaetze.html">
          http://www.pwc.de/de/ethikcode
        </Link>
        )
      </li>
      <li>
        Zehn Prinzipien von UN Global Compact (in deutscher und englischer
        Sprache zugänglich über{" "}
        <Link to="http://www.globalcompact.de/">www.globalcompact.de</Link>)
      </li>
    </P>
  </>
)

export default withModal(() => localize("imprint"))(ImprintModal)

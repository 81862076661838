import React from "react"
import AppError from "components/AppError"
import { localize } from "locale"
import { AppInsightsErrorBoundary } from "@microsoft/applicationinsights-react-js"
import { reactPlugin } from "appInsights"

const ErrorBoundary = ({ children }) => {
  return (
    <AppInsightsErrorBoundary
      onError={() => <AppError title={localize("appHasCrashed")} />}
      appInsights={reactPlugin}
    >
      {children}
    </AppInsightsErrorBoundary>
  )
}

export default ErrorBoundary

import styled from "styled-components/macro"

export const StyledImg = styled.img`
  width: 100px;
`

export const Disclaimer = styled.div`
  background: #eee;
  margin-left: -20px;
  margin-bottom: -20px;
  margin-right: -20px;
  margin-top: 20px;
  padding: 20px;
`

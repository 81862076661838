import * as R from "ramda"

export const addAnswerSet = (data, values, indices) => {
  const getAnsSets = x => {
    return {
      questionId: x.id,
      answers: [],
      children: R.pathOr([], ["subQuestions"], x)
        .filter(x => x.questionType === "Custom")
        .map(getAnsSets),
    }
  }

  const result = getAnsSets(data)
  return R.over(R.lensPath(indices), R.append(result), values)
}

export const getIsEnabled = ({
  disabledByRoot,
  disabledByParent,
  isTopLevel,
  indices,
  enabled,
  data,
}) => {
  if (isTopLevel) {
    return !disabledByRoot
  }

  if (disabledByParent) return false
  if (!data.precondition) return true

  const en = R.path(indices, enabled).find(x => x.questionId === data.id)

  return !en || en.isEnabled
}

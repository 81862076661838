import React from "react"
import { P } from "styledComponents/Typography"
import { getAllQuestionIdsFromNode } from "data/autofill/helpers"
import { answerTypes } from "data/templates/answers"
import WarningConfirmModal from "components/WarningConfirmModal"

const showClearValue = (value, ids) =>
  value.filter(x => ids.includes(x.templateQuestionId)).length > 0

const ClearAllTagging = ({ node, value, onChange, question }) => {
  const ids = getAllQuestionIdsFromNode(node)
  const isCustom = question.questionType === answerTypes.custom.value

  if (!showClearValue(value, ids)) return <div />

  return (
    <WarningConfirmModal
      onConfirm={() => {
        const newVal = value.filter(x => !ids.includes(x.templateQuestionId))
        onChange(newVal)
      }}
      text="Tagging for all sub-questions within this custom question will be cleared once the change is saved"
      buttonText="Do you want to proceed?"
      title="Clear tagging"
      doConfirm={() => isCustom}
      confirmText="Yes, proceed"
      cancelText="No"
    >
      {onClick => (
        <P red link hovernolink onClick={onClick}>
          {isCustom ? "Clear tagging for all subquestions" : "Clear tagging"}
        </P>
      )}
    </WarningConfirmModal>
  )
}

export default ClearAllTagging

import React from "react"
import Checkbox from "inputs/Checkbox"
import InfoModal from "components/InfoModal"
import IconModal from "components/IconModal"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import { answerTypes } from "data/templates/answers"
import {
  defaultBrackets,
  defaultCondition,
  defaultTerm,
  termToFormula,
} from "data/templates/logic"
import LogicNode from "./LogicNode"
import { NodesWrapper } from "./styled"
import OutlineHelpQuestion from "icons/appkit/outline-help-question"
import { localize } from "locale"
import withHandlers from "hoc/withHandlers"
import useOpen from "hooks/useOpen"

const LogicEdit = ({
  value,
  previousQuestions,
  statuses,
  addBrackets,
  addCondition,
  addSingleCondition,
  addSingleBracket,
  editQuestionId,
  editRelation,
  editAnswerId,
  editConclusion,
  editOperation,
  deleteNode,
  conditionText,
  entityName,
}) => {
  const hasNoLogic = !value || value.length === 0
  const { toggle, isOpen } = useOpen(!hasNoLogic)

  const applicableQuestions = previousQuestions.filter(
    x =>
      x.data.questionType === answerTypes.single.value ||
      x.data.questionType === answerTypes.multiple.value,
  )

  const readOnly =
    !hasNoLogic || (hasNoLogic && applicableQuestions.length === 0)

  return (
    <Box mb={10}>
      <Box flex>
        <Checkbox
          value={isOpen}
          onChange={toggle}
          readOnly={readOnly}
          text={conditionText}
          flip
        />
        {isOpen && (
          <IconModal
            icon={OutlineHelpQuestion}
            modal={InfoModal}
            title={localize("help")}
            body={
              <>
                <P mb={20}>{localize("logicHelpModal1")}</P>
                <P mb={5}>{localize("logicHelpModal2")}</P>
                <P mb={5}>{localize("logicHelpModal3")}</P>
                <P mb={20}>{localize("logicHelpModal4")}</P>
                <P>{localize("logicHelpModal5")}</P>
              </>
            }
          />
        )}
      </Box>
      <NodesWrapper>
        {isOpen && (
          <LogicNode
            item={value}
            indices={[]}
            applicableQuestions={applicableQuestions}
            statuses={statuses}
            addBrackets={addBrackets}
            addSingleCondition={addSingleCondition}
            addSingleBracket={addSingleBracket}
            addCondition={addCondition}
            editQuestionId={editQuestionId}
            editRelation={editRelation}
            editAnswerId={editAnswerId}
            editConclusion={editConclusion}
            editOperation={editOperation}
            deleteNode={deleteNode}
            entityName={entityName}
          />
        )}
      </NodesWrapper>
    </Box>
  )
}

const getPath = R.chain(x => [x, "item"])

export default R.compose(
  withHandlers({
    addTo: p => x => indices => {
      const path = R.lensPath(getPath(indices.slice(0, -1)))
      const oldValue = R.view(path, p.value)
      const lastIndex = R.last(indices)

      if (!oldValue) {
        return p.onChange(R.set(path, x, p.value))
      }

      if (R.type(oldValue) === "Object") {
        return p.onChange(R.set(path, [termToFormula(oldValue), x], p.value))
      }

      return p.onChange(R.over(path, R.insert(lastIndex, x), p.value))
    },
    deleteNode: p => indices => {
      const path = R.lensPath(getPath(indices.slice(0, -1)))
      const v = R.view(path, p.value)

      // precondition = term
      if (indices.length === 0) return p.onChange(null)

      // precondition = [condition] -> null
      if (indices.length === 1 && v.length === 1) return p.onChange(null)

      // precondition = formula -> term
      // if (indices.length === 1 && v.length === 2) {
      //   return p.onChange(p.value[indices[0] === 0 ? 1 : 0].item)
      // }

      return p.onChange(
        R.over(
          R.lensPath(getPath(indices.slice(0, -1))),
          R.remove(R.last(indices), 1),
          p.value,
        ),
      )
    },
    editCondition: p => k => indices => v =>
      p.onChange(
        R.over(R.lensPath(getPath(indices)), R.mergeLeft({ [k]: v }), p.value),
      ),
    editConditionMerge: p => indices => v =>
      p.onChange(R.over(R.lensPath(getPath(indices)), R.mergeLeft(v), p.value)),
    editOperation: p => indices => v =>
      p.onChange(
        R.set(
          R.lensPath(
            getPath(indices.slice(0, -1)).concat([
              R.last(indices),
              "operationBefore",
            ]),
          ),
          v,
          p.value,
        ),
      ),
  }),
  withHandlers({
    addBrackets: p => p.addTo(defaultBrackets),
    addCondition: p => p.addTo(defaultCondition),
    addSingleCondition: p => p.addTo(defaultTerm),
    addSingleBracket: p => p.addTo([defaultBrackets]),
    editQuestionId: p => i => v => {
      // upon changing question, we must invalidate answers
      p.editConditionMerge(i)({
        conclusion: undefined,
        answerId: undefined,
        questionId: v,
      })
    },
    editRelation: p => p.editCondition("relation"),
    editAnswerId: p => p.editCondition("answerId"),
    editConclusion: p => p.editCondition("conclusion"),
  }),
)(LogicEdit)

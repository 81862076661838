import React from "react"

export default () => (
  <svg width="10" height="16" viewBox="0 0 10 16">
    <path
      fillRule="evenodd"
      d="M10,14 L10,16 L0,16 L0,14 L10,14 Z M6,0 L6,7 L10,7 L5,12 L0,7 L4,7 L4,0 L6,0 Z"
    />
  </svg>
)

import React, { useRef } from "react"
import Loader from "components/Loader"
import * as R from "ramda"
import AppError from "components/AppError"
import { getErrorTitle } from "common/errors"
import { getData } from "common/rxjs"
import { ReplaySubject } from "rxjs"

const initialValue = Symbol()

const cachedObservables = {}

const getObservableFromCache = (observable, cacheKey) => {
  if (!cacheKey) return observable

  const cacheHit = cachedObservables[cacheKey]
  if (cacheHit) {
    return cacheHit
  }

  // until the request finishes, return shared observable for all the subscriber to subscribe to
  const cachedReplay = new ReplaySubject(1)

  observable.subscribe({
    next: v => {
      cachedReplay.next(v)
    },
    error: () => {
      cachedObservables[cacheKey] = null
    },
  })

  cachedObservables[cacheKey] = cachedReplay
  return cachedReplay
}

const getDataProps = (dataKeys, dataKey, data) => {
  if (dataKeys) {
    return dataKeys.reduce((a, c, i) => ({ ...a, [c]: R.path([i], data) }), {})
  }

  return { [dataKey]: data === initialValue ? undefined : data }
}

const withData =
  (
    getObservable,
    {
      skip = R.always(false),
      cacheKey = R.always(null),
      refetchParams = R.always([]),
      noLoader = false,
      dataKey = "data",
      debounce,
      dataKeys,
      clearOnRefetch,
      onError,
    } = {},
  ) =>
  Component =>
  props => {
    const shouldSkip = skip(props)

    const [state, setState] = React.useState({
      data: initialValue,
      error: null,
      loading: shouldSkip ? false : true,
      fetchCount: 0,
    })

    const ref = useRef({
      timeoutId: null,
    })

    const refetchOn = refetchParams(props)

    const getObservableAndSub = cb =>
      getObservableFromCache(getObservable(props), cacheKey(props)).subscribe({
        error: err => {
          setState(prev => ({
            ...prev,
            loading: false,
            error: err,
          }))
          onError?.(props)(err)
        },
        next: res => {
          const d = getData(res)
          setState(prev => ({
            ...prev,
            data: d,
            loading: false,
            fetchCount: prev.fetchCount + 1,
          }))
          if (cb) {
            cb(d)
          }
        },
      })

    const fetch = cb => {
      if (shouldSkip) return

      setState(prev => ({
        ...prev,
        loading: true,
        data: clearOnRefetch ? initialValue : prev.data,
      }))

      if (debounce) {
        clearTimeout(ref.current.timeoutId)

        ref.current.timeoutId = setTimeout(() => {
          getObservableAndSub(cb)
        }, debounce)
      }

      if (!debounce) {
        getObservableAndSub(cb)
      }
    }

    React.useEffect(
      () => {
        fetch()
      },
      refetchOn, // eslint-disable-line
    )

    if (state.error) return <AppError title={getErrorTitle(state.error)} />

    if (!noLoader && !shouldSkip && state.data === initialValue)
      return <Loader center />

    return (
      <>
        <Component
          {...getDataProps(dataKeys, dataKey, state.data)}
          {...props}
          setData={newData => setState(prev => ({ ...prev, data: newData }))}
          loading={state.loading}
          fetchCount={state.fetchCount}
          refetchData={fetch}
        />
        {state.loading && !noLoader && state.data !== initialValue && (
          <Loader center />
        )}
      </>
    )
  }

export default withData

import React from "react"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import LabelValue from "components/LabelValue"
import { localize } from "locale"
import { Hr } from "styledComponents/Typography"
import * as R from "ramda"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import withForm from "hoc/withForm"
import EditIcon from "icons/appkit/fill-edit"
import { stringRequired, stringOptional } from "validators/string"
import { ActiveIcon } from "styledComponents/Icons"
import Textfield from "inputs/Textfield"
import { reportRequest } from "data/report/api"
import { EditIconWrap } from "./styled"
import withProps from "hoc/withProps"

const Identifiers = ({
  openId,
  setOpenId,
  externalReportIdentifiers,
  internalReportIdentifiers,
  readOnly,
  resetForm,
}) => {
  const editing = openId === "identifiers"

  if (
    externalReportIdentifiers.length === 0 &&
    internalReportIdentifiers.length === 0
  )
    return <P>{localize("noIdsGenerated")}</P>

  return (
    <>
      <Hr />
      <Box relative>
        <EditIconWrap>
          <ActiveIcon
            disabled={!R.isNil(openId) || readOnly}
            onClick={() => setOpenId("identifiers")}
            ml="auto"
          >
            <EditIcon />
          </ActiveIcon>
        </EditIconWrap>
        {externalReportIdentifiers.length > 0 && (
          <>
            <P gray mt={30} mb={10}>
              {localize("taxAuthorityIds")}:
            </P>
            <Box flex>
              {externalReportIdentifiers.map(x => (
                <Textfield
                  key={x.name}
                  name={x.name}
                  mb={15}
                  noEmptyError
                  label={x.text}
                  placeholder="N/A"
                  readOnly={!editing}
                  borderless={!editing}
                  edge2edge={!editing}
                  mr={30}
                />
              ))}
            </Box>
          </>
        )}
        {editing && (
          <ModalButtons>
            <Button
              secondary
              type="button"
              onClick={() => {
                setOpenId()
                resetForm()
              }}
              text="Cancel"
            />
            <Button type="submit" text="Save" />
          </ModalButtons>
        )}
        <Hr />
        {internalReportIdentifiers.length > 0 && (
          <>
            <P gray mt={30} mb={10}>
              {localize("internalLocalReportingIds")}:
            </P>
            <Box flex>
              {internalReportIdentifiers.map((x, xi) => (
                <LabelValue mr={30} key={xi} label={x.text} value={x.value} />
              ))}
            </Box>
            <Hr />
          </>
        )}
      </Box>
    </>
  )
}

export default R.compose(
  withProps(p => ({
    externalReportIdentifiers: R.pathOr(
      [],
      ["conclusion", "externalReportIdentifiers"],
      p,
    ),
    internalReportIdentifiers: R.pathOr(
      [],
      ["conclusion", "internalReportIdentifiers"],
      p,
    ),
  })),
  withForm({
    mapProps: p =>
      R.pipe(
        R.indexBy(R.prop("name")),
        R.map(R.prop("value")),
      )(p.externalReportIdentifiers),
    schema: p =>
      R.pipe(
        R.indexBy(R.prop("name")),
        R.map(x => (x.isMandatory ? stringRequired : stringOptional)),
      )(p.externalReportIdentifiers),
    onSubmit: p => v =>
      reportRequest.editIds(p.conclusion.assessmentId)(
        p.externalReportIdentifiers.map(x => ({
          ...x,
          value: v[x.name],
        })),
      ),
    onSuccess: p => () => {
      p.setOpenId()
      p.refetch()
    },
  }),
)(Identifiers)

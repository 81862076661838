import React from "react"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import Columns from "components/Columns"
import { nameSubtypes } from "../subtypes"
import TitleBox from "../TitleBox"
import { getIsOpen } from "./helpers"
import useOpen from "hooks/useOpen"

const Subquestion = ({
  subquestion,
  onChange,
  elIdObj,
  mapIdObj,
  node,
  value,
  expand,
  scroller,
  elementId,
  elementOptionId,
  indices,
  lastSelectedValue,
}) => {
  const { isOpen, toggle } = useOpen(
    getIsOpen({ value, subquestion, lastSelectedValue }),
  )

  const t = subquestion.questionType
  const isChoice = t === "Single" || t === "Multiple"
  const isName = t === "Name"
  const isCustom = t === "Custom"
  const isOpenable = isChoice || isName || isCustom
  const isExpanded = isOpen || expand
  const text = `${localize("subquestion")} ${indices.join(".")}`

  return (
    <>
      <Columns widths={[145 + indices.length * 10, "auto"]} mb={5}>
        <TitleBox
          toggle={toggle}
          onSelect={() =>
            onChange({
              questionId: subquestion.id,
              ...elIdObj,
              ...mapIdObj,
              text,
            })
          }
          isOpenable={isOpenable}
          isSelectable={!isOpenable || isName}
          isOpen={isExpanded}
          isActive={
            value && value.questionId === subquestion.id && !value.subType
          }
          wasActiveInLastSelection={
            lastSelectedValue &&
            lastSelectedValue.questionId === subquestion.id &&
            !lastSelectedValue.subType
          }
          title={text}
          scroller={scroller}
        />
        <P mt={3}>{subquestion.questionText}</P>
      </Columns>
      {isChoice && isExpanded && (
        <Box ml={60}>
          {subquestion.options.map((x, xi) => (
            <Columns widths={[80, "auto"]} mb={5} key={xi}>
              <TitleBox
                onSelect={() =>
                  onChange({
                    questionOptionId: x.id,
                    ...elIdObj,
                    ...mapIdObj,
                    text: `${text} / ${localize("answer")} ${xi + 1}`,
                  })
                }
                isSelectable
                noToggle
                isOpen={isExpanded}
                isActive={value && value.questionOptionId === x.id}
                wasActiveInLastSelection={
                  lastSelectedValue &&
                  lastSelectedValue.questionOptionId === x.id
                }
                title={`${localize("answer")} ${xi + 1}`}
                scroller={scroller}
              />
              <P mt={3}>{x.text}</P>
            </Columns>
          ))}
        </Box>
      )}
      {isName && isExpanded && (
        <Box flex ml={60} mb={10}>
          {nameSubtypes.map(x => (
            <TitleBox
              key={x.subType}
              toggle={toggle}
              onSelect={() =>
                onChange({
                  questionId: subquestion.id,
                  ...elIdObj,
                  ...mapIdObj,
                  subType: x.subType,
                  text: `${text} / ${x.name}`,
                })
              }
              isSelectable
              isActive={
                value &&
                value.questionId === subquestion.id &&
                value.subType === x.subType
              }
              wasActiveInLastSelection={
                lastSelectedValue &&
                lastSelectedValue.questionId === subquestion.id &&
                lastSelectedValue.subType === x.subType
              }
              title={x.name}
              scroller={scroller}
            />
          ))}
        </Box>
      )}
      {isCustom && isExpanded && (
        <Box ml={60} mb={10}>
          {subquestion.subQuestions.map((x, xi) => (
            <Subquestion
              key={xi}
              subquestion={x}
              onChange={onChange}
              elementId={elementId}
              elementOptionId={elementOptionId}
              elIdObj={elIdObj}
              mapIdObj={mapIdObj}
              node={node}
              value={value}
              expand={expand}
              scroller={scroller}
              indices={indices.concat(xi + 1)}
              lastSelectedValue={lastSelectedValue}
            />
          ))}
        </Box>
      )}
    </>
  )
}

export default Subquestion

import styled, { css } from "styled-components/macro"

export const FilepickerWrap = styled.div`
  ${p =>
    p.inline &&
    css`
      display: flex;
      align-items: center;
      flex-direction: row-reverse;
    `}

  ${p =>
    p.gray &&
    css`
      background-color: #eee;
    `}
`

export const StyledLabel = styled.label`
  display: inline-block;
`
export const InfoIcon = styled.div`
  border-radius: 100%;
  background: #999;
  color: white;
  width: 20px;
  font-size: 12px;
  font-weight: bold;
  height: 20px;
  line-height: 22px;
  text-align: center;
  margin-right: 5px;
`

export const HiddenInput = styled.input`
  display: none;
`

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { conclusionRequest } from "data/templates/api"
import withForm from "hoc/withForm"
import EditIcon from "icons/appkit/fill-edit"
import InputLogic from "inputs/InputLogic"
import RichTextarea from "inputs/RichTextarea"
import { localize } from "locale"
import { arrayOptional } from "validators/array"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { longStringRequired } from "validators/string"
import { ActiveIcon } from "styledComponents/Icons"
import { NodeHeader } from "../../nodeStyles"
import Panel from "components/Panel"
import * as R from "ramda"
import TemplateEditWarning from "components/TemplateEditWarning"
import { scrollToId } from "common/scroll"

const ConclusionForm = ({
  definition,
  editIndex,
  moveIndex,
  onEdit,
  onCancelEdit,
  questions,
  first,
  canEdit,
  canEditAfterDone,
}) => {
  const isEdited = editIndex && editIndex.defId === definition.id

  React.useEffect(() => {
    if (isEdited) {
      scrollToId(`c-${definition.id}`)
    }
  }, [isEdited]) // eslint-disable-line

  return (
    <Panel mt={first ? 0 : 20} id={`c-${definition.id}`}>
      <NodeHeader>
        <P fs={14}>
          {localize("conclusion")}: {definition.conclusionResult}
        </P>
        {(canEdit || canEditAfterDone) && (
          <Box flex>
            <ActiveIcon
              disabled={editIndex || moveIndex}
              onClick={() => onEdit({ defId: definition.id })}
            >
              <EditIcon />
            </ActiveIcon>
            {canEditAfterDone && <TemplateEditWarning />}
          </Box>
        )}
      </NodeHeader>

      <InputLogic
        conditionText={localize("showConclusionIf")}
        name="precondition"
        readOnly={!isEdited || !canEdit}
        previousQuestions={questions}
        noEmptyError
      />
      <RichTextarea
        name="text"
        label={localize("conclusionText")}
        readOnly={!isEdited}
        borderless={!isEdited}
        mb={0}
        noEmptyError
      />
      {isEdited && (
        <ModalButtons>
          <Button
            secondary
            type="button"
            onClick={onCancelEdit}
            text="Cancel"
          />
          <Button type="submit" text="Save" />
        </ModalButtons>
      )}
    </Panel>
  )
}

export default withForm({
  mapProps: p => ({
    text: p.definition.text,
    precondition: p.definition.precondition,
  }),
  schema: R.always({
    text: longStringRequired,
    precondition: arrayOptional,
  }),
  onSubmit: p => v =>
    conclusionRequest.updateDefinition(p.templateId, p.definition.id, v),
  onSuccess: p => p.onSuccess,
})(ConclusionForm)

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M22.71975,6.2197 L11.99975,16.9397 L1.28075,6.2197 C0.98775,5.9267 0.51275,5.9267 0.21975,6.2197 C-0.07325,6.5127 -0.07325,6.9877 0.21975,7.2807 L11.99975,19.0607 L23.78075,7.2807 C24.07375,6.9877 24.07375,6.5127 23.78075,6.2197 C23.48775,5.9267 23.01275,5.9267 22.71975,6.2197"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"
import { assessmentCoworkersRequest } from "data/assessments/api"
import CoworkersPanel from "./CoworkersPanel"
import withData from "hoc/withData"
import withForm from "hoc/withForm"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { arrayOptional } from "validators/array"
import { preassessmentCoworkersRequest } from "data/preassessments/api"
import withState from "hoc/withState"
import InfoModal from "components/InfoModal"
import UserList from "inputs/UserList"
import { P } from "styledComponents/Typography"
import { formatInitialsFromName, formatUserName } from "data/user/formatters"
import ModalButtons from "components/ModalButtons"
import Button from "components/Button"

const Coworkers = ({
  assessment,
  urls,
  cancelUrl,
  extraCoworkers,
  setExtraCoworkers,
  submitForm,
  caseUrls,
}) => (
  <>
    <CoworkersPanel
      cancelUrl={
        caseUrls
          ? `${caseUrls.list.url}?involvedPartyId=${R.path(
              ["breadcrumbs", "case", "involvedParties", 0, "id"],
              assessment,
            )}`
          : cancelUrl ?? urls.assessments.local
      }
      readOnly={!assessment.canEditCoworkers}
      apiRequest={assessmentCoworkersRequest}
      infoText="Please note that multiple approvers may be defined, but the assessment
      will only be approved upon the sign-off of at least 2 approvers from
      your selected approvers. You can add or delete coworkers at any stage of
      the assessment/conclusion, however the user's actions performed will
      remain valid and be kept in history even if the user is deleted at the
      later stage."
    />
    {extraCoworkers && (
      <InfoModal
        title="Pre-assessment coworkers"
        body={
          <>
            <P mb={20}>
              Following users are not case coworkers and won't be able to access
              pre-assessment:
            </P>
            <UserList
              value={extraCoworkers}
              optionToAvatar={R.pipe(formatUserName, formatInitialsFromName)}
              optionToTitle={formatUserName}
              optionToSubtitle={R.prop("email")}
            />
            <P mt={20}>
              If you proceed users will be added as case coworkers (with Case
              Viewer role).
            </P>
            <ModalButtons buttonText="Add access to the case for above users?">
              <Button
                secondary
                text="Cancel"
                onClick={() => setExtraCoworkers()}
              />
              <Button
                text="Yes, add"
                onClick={() => {
                  setExtraCoworkers()
                  submitForm()
                }}
              />
            </ModalButtons>
          </>
        }
        close={() => setExtraCoworkers()}
      />
    )}
  </>
)

export default R.compose(
  withData(p =>
    (p.isPreassessment
      ? preassessmentCoworkersRequest
      : assessmentCoworkersRequest
    ).coworkers(p.assessmentId),
  ),
  withState("extraCoworkers", "setExtraCoworkers"),
  withUrls,
  withForm({
    preventLoseProgress: true,
    mapProps: R.prop("data"),
    schema: R.always({
      coworkers: arrayOptional,
      reviewers: arrayOptional,
    }),
    onSubmit: p => v =>
      (p.isPreassessment
        ? preassessmentCoworkersRequest
        : assessmentCoworkersRequest
      ).edit(p.assessmentId, {
        coworkerRoles: v.coworkers.map(x => ({
          userId: x.id,
          role: x.permission,
        })),
        reviewerRoles: (v.reviewers || []).map(x => ({
          userId: x.id,
          role: x.permission,
        })),
        forceAddCoworkers: !!p.extraCoworkers,
      }),
    successText: () => () => "Coworkers updated",
    onError: p => (msg, err, values) => {
      if (err.error.status === 409) {
        p.setExtraCoworkers(
          values.coworkers.filter(x => err.error.response.data.includes(x.id)),
        )
      }
    },
    redirect: (_, p) => {
      if (R.path(["suburls", "evaluation"], p)) {
        return p.suburls.evaluation.url
      }
      // this happens for conlusion detail, where this is reused
      return null
    },
    onSuccess: p => () => {
      p.refetchCase?.()
    },
    errorText: () => (msg, err) => err.error.status === 409 ? null : msg,
  }),
)(Coworkers)

import styled from "styled-components/macro"

export const AppErrorWrap = styled.div`
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const StyledImg = styled.img`
  max-width: 100%;
  width: 200px;
  margin-bottom: 20px;
`

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentCoworkersRequest } from "data/assessments/api"
import { formatInitials, formatUserName } from "data/user/formatters"
import withModal from "hoc/withModal"
import AutocompleteList from "inputs/AutocompleteList"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"

const DelegateAssessmentModal = ({ close, isPreassessment, submit }) => (
  <>
    <AutocompleteList
      request={assessmentCoworkersRequest.list}
      required
      name="users"
      label={`Assign a coworker you want to delegate the ${
        isPreassessment ? "pre-assessment" : "assessment"
      }. (Coworkers will get Editor role)`}
      mapOptions={R.map(u => ({
        ...u,
        value: u.id,
        name: u.email,
      }))}
      title={localize("addedUsers")}
      optionToAvatar={formatInitials}
      optionToTitle={formatUserName}
      optionToSubtitle={R.prop("email")}
      emptyListText={`Search a coworker to grant him access to fill in the ${
        isPreassessment ? "pre-assessment" : "assessment"
      }.`}
      noResultText={localize("emailNotFoundInTenant")}
    />
    <Box mt={30}>
      <Textarea
        required
        name="comment"
        label={localize("addComment")}
        placeholder={localize("addYourComment")}
      />
    </Box>
    <ModalButtons>
      <Button secondary onClick={close} text="Cancel" />
      <Button type="submit" text="Submit" onClick={submit} />
    </ModalButtons>
  </>
)

export default R.compose(withModal(p => `Delegate ${p.assessment.name}`))(
  DelegateAssessmentModal,
)

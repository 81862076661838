import React from "react"

export default () => (
  <svg width="21px" height="33px" viewBox="0 0 21 33">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g transform="translate(-1588.000000, -627.000000)" fill="#FFFFFF">
        <g transform="translate(1566.000000, 612.000000)">
          <path
            d="M31.1777876,46.1630212 L31.177082,43.0292496 L24.2591029,36.2371026 C23.742283,35.7298125 22.9044349,35.7298125 22.387615,36.2371026 C21.870795,36.7443927 21.870795,37.5667914 22.387615,38.0740815 L32.5,48 L42.612385,38.0740815 C43.129205,37.5667914 43.129205,36.7443927 42.612385,36.2371026 C42.0955651,35.7298125 41.257717,35.7298125 40.742661,36.2371026 L33.8236235,43.028557 L33.8236235,15 L31.1777876,15 L31.1777876,43.0286436 L31.1777876,46.1630212 Z"
            transform="translate(32.500000, 31.500000) scale(1, -1) translate(-32.500000, -31.500000) "
          />
        </g>
      </g>
    </g>
  </svg>
)

import React from "react"
import Button from "components/Button"
import Loader from "components/Loader"
import InfoModal from "components/InfoModal"
import ModalButtons from "components/ModalButtons"
import Textfield from "inputs/Textfield"
import Checkbox from "inputs/Checkbox"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import Panel from "components/Panel"
import withForm from "hoc/withForm"
import withOpen from "hoc/withOpen"
import { publishRequest } from "data/templates/api"
import { boolOptional } from "validators/bool"
import { stringOptional } from "validators/string"
import * as R from "ramda"

const Share = ({ disabled, isSubmitting, isOpen, open, close }) => (
  <Panel mt={20} disabled={disabled}>
    <P bold mb={10}>
      Share
    </P>
    <P>
      Share the template into the template pool. Template pool is accessible by
      template team and site admins only.
    </P>
    <P bold mt={30}>
      Share to
    </P>
    <Box flex>
      <Checkbox name="shareToPwC" squared text="PwC" noEmptyError />
      <Checkbox
        name="shareToClients"
        ml={20}
        squared
        text="Clients"
        noEmptyError
      />
    </Box>
    <Textfield
      name="description"
      label="Description"
      placeholder="Enter description for template pool listing"
      noEmptyError
    />
    <ModalButtons>
      <Button onClick={open} text="Share template" />
    </ModalButtons>
    {isOpen && (
      <InfoModal
        title="Template share"
        text="By confirmation of the upload, you provide your consent to allow this template to be seen, downloading and used by all sites. Please make sure that the template does not contain sensitive data. Please confirm if you want to upload this template."
        close={close}
        body={
          <>
            {isSubmitting && <Loader center />}
            <ModalButtons>
              <Button secondary onClick={close} text="Cancel" />
              <Button type="submit" text="Confirm" form="share-form" />
            </ModalButtons>
          </>
        }
      />
    )}
  </Panel>
)

export default R.compose(
  withOpen,
  withForm({
    formId: "share-form",
    mapProps: R.prop("data"),
    schema: R.always({
      pwcUse: boolOptional,
      clientUse: boolOptional,
      description: stringOptional,
    }),
    onSubmit: p => v => publishRequest.share(p.template.id, v),
    onSuccess: p => () => {
      p.refetch()
      p.close()
    },
    successText: () => () => "Template shared",
  }),
)(Share)

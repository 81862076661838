import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M24,16.4015 L21.304,15.1755 L18.958,7.6115 C18.57,6.3575 17.41,5.5025 16.098,5.5005 L7.903,5.5005 C6.59,5.5025 5.43,6.3575 5.042,7.6115 L2.696,15.1755 L0,16.4015 L0,20.4995 L24,20.4995 L24,16.4015 Z M22.5,18.9995 L1.5,18.9995 L1.5,17.3675 L3.317,16.5405 L3.93,16.2625 L4.129,15.6195 L6.474,8.0565 C6.67,7.4245 7.246,7.0005 7.907,7.0005 L16.093,7.0005 C16.754,7.0005 17.33,7.4245 17.526,8.0565 L19.871,15.6195 L20.07,16.2625 L20.683,16.5405 L22.5,17.3675 L22.5,18.9995 Z M15.3463,22.7493 L8.6533,22.7493 C9.5563,23.5233 10.7173,23.9993 12.0003,23.9993 C13.2833,23.9993 14.4443,23.5233 15.3463,22.7493 Z M7.7762,4.0007 L9.4092,4.0007 C9.9302,3.1097 10.8962,2.5097 12.0002,2.5097 C13.1042,2.5097 14.0702,3.1097 14.5912,4.0007 L16.2242,4.0007 C15.6032,2.2587 13.9532,0.9997 12.0002,0.9997 C10.0472,0.9997 8.3972,2.2587 7.7762,4.0007 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

import { localize } from "locale"
import { combineValidators, fieldOptional, fieldRequired } from "./helpers"

export const arrayOptional = fieldOptional

export const arrayOptionalOf = testFn => x =>
  !x || x.length === 0 ? "" : x.map(testFn)?.[0]

export const arrayRequired = fieldRequired

export const arrayMinLength = n => item =>
  combineValidators(arrayRequired, x =>
    x.length >= n ? "" : localize("minLengthArray")(n, item),
  )

export const arrayMaxLength = n => item =>
  combineValidators(arrayRequired, x =>
    x.length <= n ? "" : localize("maxLengthArray")(n, item),
  )

export const arrayOptionalMaxLength = n => item =>
  combineValidators(arrayOptional, x =>
    !x || x.length <= n ? "" : localize("maxLengthArray")(n, item),
  )

export const arrayExactLength = n => item =>
  combineValidators(arrayRequired, x =>
    x.length === n ? "" : localize("exactLengthArray")(n, item),
  )

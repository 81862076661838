import React from "react"
import { assessmentTagRequest, templateTagRequest } from "data/registry/api"
import withData from "hoc/withData"
import withModal from "hoc/withModal"
import * as R from "ramda"
import { parallel } from "common/rxjs"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { ItemWrap } from "./styled"

const EntityModal = ({ values, catalogs }) => {
  const allCatalog = R.values(catalogs).flatMap(R.identity)
  return (
    <Box>
      {values.map(x => (
        <ItemWrap key={x.id}>
          <P gray bold nobreak mr={5}>
            {allCatalog.find(y => x.registryCatalogId === y.id).fieldName}
          </P>
          <P ml="auto">{x.value}</P>
        </ItemWrap>
      ))}
    </Box>
  )
}

export default R.compose(
  withModal(R.always("All subject data from registry"), { width: 840 }),
  withData(
    p =>
      parallel(
        assessmentTagRequest.values(p.registryValue.value),
        templateTagRequest.catalogs(),
      ),
    {
      dataKeys: ["values", "catalogs"],
    },
  ),
)(EntityModal)

import React from "react"
import LabelValue from "components/LabelValue"
import Columns from "components/Columns"
import Text from "components/Text"
import { Box } from "styledComponents/Containers"
import { answerTypes, answerTypeOptions } from "data/templates/answers"
import Simple from "./Simple"
import Group from "./Group"
import { P } from "styledComponents/Typography"
import { getCanBeTagged } from "data/templates/answers"
import useOpen from "hooks/useOpen"
import CaretIcon from "icons/Caret"
import { IconWrap } from "styledComponents/Icons"
import NonTaggableContent from "./NonTaggableContent"
import ClearTagging from "./ClearTagging"
import Subquestions from "./Subquestions"

const TagForm = ({
  question,
  catalogs,
  value,
  onChange,
  indices,
  templateId,
  template,
  refetchTemplate,
  parentGroupNumber,
  grandParentGroupNumber,
  canEdit,
  thisLevelSubquestions,
  siblingAndTheirChildrenIds,
}) => {
  const isCustom = question.questionType === answerTypes.custom.value
  const canBeTagged = getCanBeTagged(question)

  const groupNumber = value.find(
    x => question.id === x.templateQuestionId,
  )?.autoFillGroupNr

  const { isOpen, toggle } = useOpen()

  return (
    <>
      <P required={question.isMandatory} data-qnum={`Q${indices.join(".")}`}>
        Q{indices.join(".")}
      </P>
      <Columns widths={["auto", 50]}>
        <Text bold mt={5}>
          {question.questionText}
        </Text>
        {canBeTagged ? (
          <div />
        ) : (
          <IconWrap onClick={toggle} rotate={+isOpen}>
            <CaretIcon />
          </IconWrap>
        )}
      </Columns>
      {!canBeTagged && isOpen && <NonTaggableContent question={question} />}
      {canBeTagged && (
        <>
          <Box flex justifyContent="space-between" mt={20}>
            <Box flex>
              <LabelValue
                label="Type of answer"
                value={
                  answerTypeOptions.find(x => x.value === question.questionType)
                    .name
                }
              />
              {(indices.length > 1 || isCustom) && (
                <Group
                  value={value}
                  onChange={onChange}
                  indices={indices}
                  question={question}
                  templateId={templateId}
                  template={template}
                  root={indices.length === 1}
                  refetchTemplate={refetchTemplate}
                  groupNumber={groupNumber}
                  isCustom={isCustom}
                  canEdit={canEdit}
                  parentGroupNumber={parentGroupNumber}
                  grandParentGroupNumber={grandParentGroupNumber}
                />
              )}
            </Box>
            {!isCustom && (
              <Simple
                question={question}
                catalogs={catalogs}
                value={value}
                onChange={onChange}
                canEdit={canEdit}
                thisLevelSubquestions={thisLevelSubquestions}
                siblingAndTheirChildrenIds={siblingAndTheirChildrenIds}
              />
            )}
          </Box>
          <ClearTagging
            indices={indices}
            question={question}
            value={value}
            onChange={onChange}
            canEdit={canEdit}
            isCustom={isCustom}
          />
        </>
      )}
      {isCustom && (
        <Subquestions
          question={question}
          catalogs={catalogs}
          value={value}
          onChange={onChange}
          templateId={templateId}
          template={template}
          indices={indices}
          refetchTemplate={refetchTemplate}
          groupNumber={groupNumber}
          parentGroupNumber={parentGroupNumber}
          canEdit={canEdit}
        />
      )}
    </>
  )
}

export default TagForm

import React from "react"
import withModal from "hoc/withModal"
import Loader from "components/Loader"
import { P } from "styledComponents/Typography"
import { getFilenameFromResponse, downloadFileFromUri } from "common/files"
import * as R from "ramda"
import { reportingRequest } from "data/reporting/api"

const PollModal = ({ initRequest, close, body }) => {
  React.useEffect(() => {
    let interval
    initRequest.subscribe(idRes => {
      interval = setInterval(() => {
        reportingRequest.pollDownloadPDF(idRes.data).subscribe(res => {
          if (res.data) {
            const filename = getFilenameFromResponse(res)
            downloadFileFromUri(res.data, filename)
            clearInterval(interval)
            close()
          }
        })
      }, 2000)
    })

    return () => clearInterval(interval)
  }, []) // eslint-disable-line

  return (
    <>
      <P center>{body}</P>
      <Loader small />
    </>
  )
}

export default R.compose(withModal(R.prop("title")))(PollModal)

import React from "react"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import * as R from "ramda"
import { operationOptions } from "data/templates/logic"
import { LogicNodeWrap } from "./styled"
import Condition from "./Condition"

const LogicNode = ({
  item,
  indices,
  operationBefore,
  questions,
  entityName,
}) => {
  if (R.type(item) === "Object") {
    const q = questions.find(q => q.referencedId === item.questionId)
    return (
      <Condition
        item={item}
        indices={indices}
        operationBefore={operationBefore}
        question={q}
        entityName={entityName}
      />
    )
  }

  const operation = operationOptions.find(o => o.value === operationBefore)
  return (
    <Box flex mt={10} mb={10}>
      {indices.some(x => x !== 0) && operation && (
        <P mr={5} nowrap nobreak>
          {operation.name}
        </P>
      )}
      <LogicNodeWrap depth={indices.length}>
        {item.map((n, ni) => (
          <LogicNode
            key={ni}
            indices={indices.concat(ni)}
            item={n.item}
            operationBefore={n.operationBefore}
            questions={questions}
            entityName={entityName}
            index={ni}
          />
        ))}
      </LogicNodeWrap>
    </Box>
  )
}

export default LogicNode

import { localize } from "locale"
import * as R from "ramda"

export const parseSearch = R.pipe(
  R.slice(1, Infinity),
  R.split("&"),
  R.map(R.split("=")),
  R.map(([first, ...rest]) => [first, rest.join("=")]),
  R.reduce(
    (acc, cur) => ({
      ...acc,
      [cur[0]]: cur[1],
    }),
    {},
  ),
)

export const stringifySearch = obj => {
  if (R.values(obj).every(x => !x)) return ""

  return R.keys(obj)
    .filter(x => x !== "")
    .map(k => [k, obj[k]].join("="))
    .join("&")
}

export const removeSearchParam = (key, search) => {
  const parsed = parseSearch(search)

  const tmp = R.omit([key], parsed)

  if (R.isEmpty(tmp)) return ""

  return `?${R.keys(tmp)
    .map(k => `${k}=${tmp[k]}`)
    .join("&")}`
}

// TODO - move to string validators
export const validURL = str => {
  const pattern = new RegExp(
    "(https?://(?:www.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|www.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9].[^s]{2,}|https?://(?:www.|(?!www))[a-zA-Z0-9]+.[^s]{2,}|www.[a-zA-Z0-9]+.[^s]{2,})",
  )

  return pattern.test(str) ? "" : localize("correctUrl")
}

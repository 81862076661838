import { get, post, put, del } from "common/request"

export const folderRequest = {
  generalFolderId: () => get(`/api/cockpit/folders/general`),
  list: params => get(`/api/cockpit/folders?${params}`),
  create: data => post(`/api/cockpit/folders`, data),
  export: id => post(`/api/cockpit/folders/${id}/export`),
  detail: id => get(`/api/cockpit/folders/${id}`),
  del: id => del(`/api/cockpit/folders/${id}`),
  update: (id, data) => put(`/api/cockpit/folders/${id}`, data),
  history: id => get(`/api/cockpit/folders/${id}/history`),
  uploadNew: data =>
    post(`/api/cockpit/folders/file`, data, { doNotSetContentType: true }),
  uploadExisting: id => data =>
    post(`/api/cockpit/folders/${id}/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: id => attachmentId =>
    get(`/api/cockpit/folders/${id}/file/${attachmentId}`),
  setFavorite: id => on => put(`/api/cockpit/folders/${id}/favorite?on=${on}`),
}

export const caseRequest = {
  list: (id, params) => get(`/api/cockpit/folders/${id}/cases?${params}`),
  browser: caseId => get(`/api/cockpit/case/${caseId}/browser`),
  assessmentCountries: caseId =>
    get(`/api/cockpit/case/${caseId}/assessmentCountries`),
  listCountries: caseId => get(`/api/cockpit/case/${caseId}/countries`),
  createAssessments: (caseId, data) =>
    post(`/api/assessment/case/${caseId}`, data),
  create: (folderId, data) =>
    post(`/api/cockpit/folders/${folderId}/case`, data),
  export: id => post(`/api/cockpit/case/${id}/export`),
  detail: id => get(`/api/cockpit/case/${id}`),
  update: (id, data) => put(`/api/cockpit/case/${id}`, data),
  del: id => del(`/api/cockpit/case/${id}`),
  history: id => get(`/api/cockpit/case/${id}/history`),
  uploadNew: folderId => data =>
    post(`/api/cockpit/folders/${folderId}/case/file`, data, {
      doNotSetContentType: true,
    }),
  uploadExisting: (folderId, caseId) => data =>
    post(`/api/cockpit/folders/${folderId}/case/${caseId}/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: (folderId, caseId) => attachmentId =>
    get(`/api/cockpit/folders/${folderId}/case/${caseId}/file/${attachmentId}`),
  setFavorite: id => on => put(`/api/cockpit/case/${id}/favorite?on=${on}`),
  additionalCommennts: (id, data) =>
    put(`/api/cockpit/case/${id}/additionalComments`, data),
  listReports: params => get(`/api/massReports?${params}`),
  listConclusions: params => get(`/api/conclusions?${params}`),
  hasLinkedObjects: (caseId, includingPreAssessment = false) =>
    get(
      `/api/cockpit/case/${caseId}/linkedObject/any?includingPreAssessment=${includingPreAssessment}`,
    ),
  hasCaseDescription: objectIds =>
    post(`/api/cockpit/involvedParty/caseDescription/any`, objectIds),
}

export const involvedPartyRequest = {
  list: caseId => get(`/api/cockpit/case/${caseId}/involvedParty`),
  create: (caseId, data) =>
    post(`/api/cockpit/case/${caseId}/involvedParty`, data),
  edit: (id, data) => put(`/api/cockpit/involvedParty/${id}`, data),
  objects: (id, params) =>
    get(`/api/cockpit/involvedParty/${id}/objects?${params}`),
  del: id => del(`/api/cockpit/involvedParty/${id}`),
  pickerObjects: (id, params) =>
    get(`/api/cockpit/involvedParty/${id}/pickerObjects?${params}`),
  link: (id, data) => post(`/api/cockpit/involvedParty/${id}/link`, data),
  unlink: (id, data) => post(`/api/cockpit/involvedParty/${id}/unlink`, data),
  unlinkAll: id => post(`/api/cockpit/case/${id}/involvedParty/unlinkAll`),
  createAssessment: (caseId, involvedPartyId, data) =>
    post(
      `/api/assessment/case/${caseId}/involvedParty/${involvedPartyId}`,
      data,
    ),
}

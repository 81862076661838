import React from "react"
import { Route, Redirect } from "react-router-dom"
import * as R from "ramda"

const LoginRoute = ({
  path,
  component: C,
  availableProtectedRoutes,
  loggedIn,
  urls,
}) => (
  <Route
    path={path}
    render={p => {
      if (loggedIn) {
        return <Redirect to={R.head(availableProtectedRoutes).path} />
      }

      return <C {...p} urls={urls} />
    }}
  />
)

export default LoginRoute

import { css } from "styled-components/macro"
import * as R from "ramda"
import theme from "theme"

const toPx = x => (x === "auto" ? x : `${x}px`)

export const margin = css`
  ${p =>
    !R.isNil(p.mt) &&
    css`
      margin-top: ${toPx(p.mt)};
    `};
  ${p =>
    !R.isNil(p.mb) &&
    css`
      margin-bottom: ${toPx(p.mb)};
    `};
  ${p =>
    !R.isNil(p.ml) &&
    css`
      margin-left: ${toPx(p.ml)};
    `};
  ${p =>
    !R.isNil(p.mr) &&
    css`
      margin-right: ${toPx(p.mr)};
    `};
`

export const padding = css`
  ${p =>
    !R.isNil(p.pt) &&
    css`
      padding-top: ${p.pt}px;
    `};
  ${p =>
    !R.isNil(p.pb) &&
    css`
      padding-bottom: ${p.pb}px;
    `};
  ${p =>
    !R.isNil(p.pl) &&
    css`
      padding-left: ${p.pl}px;
    `};
  ${p =>
    !R.isNil(p.pr) &&
    css`
      padding-right: ${p.pr}px;
    `};
  ${p =>
    !R.isNil(p.p) &&
    css`
      padding: ${p.p}px;
    `};
`

export const size = css`
  ${p =>
    !R.isNil(p.width) &&
    css`
      width: ${p.width}px;
    `};
  ${p =>
    !R.isNil(p.maxWidth) &&
    css`
      max-width: ${p.maxWidth};
    `};
  ${p =>
    !R.isNil(p.minWidth) &&
    css`
      min-width: ${toPx(p.minWidth)};
    `};
  ${p =>
    !R.isNil(p.inline) &&
    css`
      display: inline-block;
      vertical-align: middle;
    `};
  ${p =>
    !R.isNil(p.fullinline) &&
    css`
      display: inline;
      vertical-align: middle;
    `};
`

export const flex = css`
  ${p =>
    p.flex &&
    css`
      display: flex;
    `};
  ${p =>
    !R.isNil(p.grow) &&
    css`
      flex-grow: 1;
    `};
  ${p =>
    !R.isNil(p.noshrink) &&
    css`
      flex-shrink: 0;
    `};
  ${p =>
    p.column &&
    css`
      flex-direction: column;
    `};
  ${p =>
    p.acenter &&
    css`
      align-items: center;
    `};
  ${p =>
    p.justifyContent &&
    css`
      justify-content: ${p.justifyContent};
    `};
  ${p =>
    p.align &&
    css`
      align-items: ${p.align};
    `};
  ${p =>
    p.wrap &&
    css`
      flex-wrap: wrap;
    `};
`

export default css`
  ${margin};
  ${padding};
  ${size};
  ${flex};

  ${p =>
    !R.isNil(p.responsive) &&
    css`
      overflow: auto;

      ${theme.mq.tablet} {
        overflow: visible;
      }
    `};
`

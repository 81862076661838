import theme from "theme"

export default () => (
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none">
    <circle
      cx="8"
      cy="8"
      r="7.5"
      stroke={theme.color.red}
      fill={theme.color.red}
    />
    <path
      fill="#fff"
      d="M8.00014 6.85756L8.93094 3.13435C8.98249 2.92814 8.71934 2.79452 8.58327 2.95781L3.83876 8.65122C3.67593 8.84662 3.81487 9.14328 4.06922 9.14328H6.85728L5.92648 12.8665C5.87493 13.0727 6.13808 13.2063 6.27415 13.043L11.0187 7.34962C11.1815 7.15422 11.0426 6.85756 10.7882 6.85756L8.00014 6.85756Z"
    />
  </svg>
)

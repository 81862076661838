import { answerTypes } from "data/templates/answers"

export const isCompatible = (qt, catalogItem) => {
  if (!catalogItem) return true

  if (qt === answerTypes.freeText.value) {
    return ["Freetext", "Email"].includes(catalogItem.fieldType)
  }
  if (qt === answerTypes.name.value) {
    return ["Freetext"].includes(catalogItem.fieldType)
  }
  if (
    qt === answerTypes.country.value ||
    qt === answerTypes.countryOther.value
  ) {
    return ["CountryCode"].includes(catalogItem.fieldType)
  }
  if (qt === answerTypes.date.value) {
    return ["Date"].includes(catalogItem.fieldType)
  }
  if (qt === answerTypes.email.value) {
    return ["Freetext", "Email"].includes(catalogItem.fieldType)
  }

  // shouldnt happen
  return true
}

import * as R from "ramda"
import { createContext } from "react"

export const UrlsContext = createContext()

export const getUrls = R.map(x => {
  if (x.submodules) return R.map(R.prop("url"))(x.submodules)

  return x.url
})

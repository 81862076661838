import React from "react"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"

const Group = ({ title, items, onSelect }) => {
  if (items.length === 0) return null

  return (
    <Box mb={20}>
      {title && (
        <P upper fs={12} gray>
          {title}
        </P>
      )}
      {items.map(x => (
        <P
          red
          link
          hovernolink
          mt={10}
          key={x.id}
          onClick={() => onSelect(x.id)}
        >
          {x.name}
        </P>
      ))}
    </Box>
  )
}

export default Group

import React from "react"
import { Box } from "styledComponents/Containers"
import { answerTypes } from "data/templates/answers"
import Select from "inputs/Select"
import { registryTypeOptions } from "data/registry/types"
import TagSelect from "./TagSelect"
import Validations from "./Validations"
import { getValue } from "./helpers"

const Simple = ({
  question,
  catalogs,
  value,
  onChange,
  canEdit,
  siblingAndTheirChildrenIds,
}) => {
  const type = value.find(x => x.templateQuestionId === question.id)?.type
  const isName = question.questionType === answerTypes.name.value

  const readOnly = !canEdit
  const readOnlyTag = readOnly || !type
  const val = getValue(value, question)

  return (
    <Box flex>
      <Box width={150}>
        <Box>
          <Select
            label="Legal form"
            options={registryTypeOptions}
            placeholder="Legal form"
            value={type}
            readOnly={readOnly}
            gray={readOnly}
            onChange={v => {
              //no change
              if (v === type) return

              // remove values
              const subtypes = isName
                ? ["FirstName", "MiddleName", "LastName"]
                : [""]
              onChange(
                value
                  .filter(x => x.templateQuestionId !== question.id)
                  .concat(
                    subtypes.map(x => ({
                      templateQuestionId: question.id,
                      subType: x,
                      type: v,
                    })),
                  ),
              )
            }}
            byValue
          />
          <Validations val={val} type={type} />
        </Box>
      </Box>
      <Box width={300} ml={10}>
        {isName ? (
          <>
            <TagSelect
              siblingAndTheirChildrenIds={siblingAndTheirChildrenIds}
              val={val}
              type={type}
              question={question}
              catalogs={catalogs}
              onChange={onChange}
              value={value}
              readOnly={readOnlyTag}
              subtype="FirstName"
              label="Tag"
            />
            <TagSelect
              siblingAndTheirChildrenIds={siblingAndTheirChildrenIds}
              val={val}
              type={type}
              question={question}
              catalogs={catalogs}
              onChange={onChange}
              value={value}
              readOnly={readOnlyTag}
              subtype="MiddleName"
              mt={10}
            />
            <TagSelect
              siblingAndTheirChildrenIds={siblingAndTheirChildrenIds}
              val={val}
              type={type}
              question={question}
              catalogs={catalogs}
              onChange={onChange}
              value={value}
              readOnly={readOnlyTag}
              subtype="LastName"
              mt={10}
            />
          </>
        ) : (
          <TagSelect
            siblingAndTheirChildrenIds={siblingAndTheirChildrenIds}
            val={val}
            type={type}
            question={question}
            catalogs={catalogs}
            onChange={onChange}
            value={value}
            readOnly={readOnlyTag}
            subtype=""
            label="Tag"
          />
        )}
      </Box>
    </Box>
  )
}

export default Simple

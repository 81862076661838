import React from "react"
import Select from "inputs/Select"
import { getSubtypeValue, getNewVal, getOptions } from "./helpers"
import TagValidations from "./TagValidations"
import * as R from "ramda"
import ConfirmModal from "components/ConfirmModal"
import { emptyRequest } from "common/rxjs"

const TagSelect = ({
  question,
  onChange,
  value,
  readOnly,
  subtype,
  label,
  catalogs,
  mt,
  val,
  type,
  siblingAndTheirChildrenIds,
}) => {
  const [tmpVal, setTmpVal] = React.useState()
  const options = getOptions(type, catalogs)

  return (
    <>
      <Select
        onChange={v => {
          if (
            v &&
            v.allowsMultiple &&
            siblingAndTheirChildrenIds &&
            R.intersection(
              value.map(R.prop("templateQuestionId")),
              siblingAndTheirChildrenIds,
            ).length > 0
          ) {
            return setTmpVal(v)
          }
          onChange(getNewVal(subtype, question, value)(v))
        }}
        value={getSubtypeValue(subtype, val)}
        label={label}
        gray={!type || readOnly}
        placeholder={type ? "Search" : "Choose legal form first"}
        options={options}
        mt={mt}
        clearable
        readOnly={readOnly}
      />
      <TagValidations
        value={val.find(x => x.subType === subtype)}
        question={question}
        catalogs={catalogs}
      />
      {tmpVal && (
        <ConfirmModal
          close={() => setTmpVal()}
          title="Warning"
          confirmText={`The tag with type Freetext, "Multiple values allowed" should be used only for a single sub-question at the lowest level. All currently assigned tags at the same and lower levels will be cleared, when you proceed with assigning this tag.`}
          cancelText="Cancel"
          submitText="Proceed"
          request={() => {
            onChange(
              getNewVal(
                subtype,
                question,
                value,
              )(tmpVal).filter(
                x => !siblingAndTheirChildrenIds.includes(x.templateQuestionId),
              ),
            )
            return emptyRequest
          }}
        />
      )}
    </>
  )
}

export default TagSelect

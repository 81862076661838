import React from "react"
import { P } from "styledComponents/Typography"
import { relationOptions, operationOptions } from "data/templates/logic"
import { localize } from "locale"
import * as R from "ramda"

const getAnswer = (item, question) => {
  if (item.conclusion) return item.conclusion

  const answer =
    question && question.data.options.find(x => x.id === item.answerId)

  return answer && answer.text
}

const getQuestion = (question, item, entityName) => {
  if (item.conclusion || item.questionId === "conclusion") {
    return localize("conclusion")
  }

  if (question) return `${entityName} ${question.idx}`

  return localize("notSelected")
}

const Condition = ({
  item,
  indices,
  operationBefore,
  question,
  entityName,
}) => {
  const operation = operationOptions.find(o => o.value === operationBefore)
  const relation = relationOptions.find(o => o.value === item.relation)
  const showOperation = R.last(indices) !== 0
  const answer = getAnswer(item, question)
  const questionText = getQuestion(question, item, entityName)

  return (
    <P mt={2} mb={2}>
      {indices.length > 0 && (
        <P inline width={35}>
          {operation && showOperation && operation.name}
        </P>
      )}
      <P inline bold error={!item.questionId && !item.conclusion}>
        {questionText}
      </P>{" "}
      <P inline>{relation && relation.name}</P>{" "}
      <P inline bold error={!answer}>
        {answer || localize("notSelected")}
      </P>
    </P>
  )
}

export default Condition

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const QuestionWrap = styled.div`
  position: relative;

  ${theme.mq.tablet} {
    display: flex;
  }
`

export const QuestionHeader = styled.div`
  background: #eee;
  position: relative;
  padding: 10px 20px;

  ${theme.mq.tablet} {
    padding: 20px 0 20px 20px;
    width: 75px;
    flex-shrink: 0;
  }

  ${p =>
    p.isAnswered &&
    css`
      &::before {
        top: 0;
        left: -1px;
        width: 10px;
        bottom: 0;
        background: green;
        position: absolute;
        content: "";
      }
    `}
`
export const QuestionBody = styled.div`
  padding: 20px;
  flex-grow: 1;
  background: ${p => (p.mandatory ? "#fff" : "#eee")};
  overflow: hidden;
`

import React from "react"
import Button from "components/Button"
import { Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import Panel from "components/Panel"
import Share from "./Share"
import { publishRequest } from "data/templates/api"
import withSubmit from "hoc/withSubmit"
import * as R from "ramda"
import { formatDateTime } from "data/units/date/formatters"

const PublishShare = ({
  template,
  submit,
  refetchPublishSettings,
  publishSettings,
  fetchCount,
}) => (
  <>
    <Panel
      disabled={
        !publishSettings.isPublishEnabled && !publishSettings.publishedAtUtc
      }
    >
      <P bold mb={20}>
        Publish
      </P>
      {publishSettings.publishedAtUtc ? (
        <P>
          Already published at {formatDateTime(publishSettings.publishedAtUtc)}
        </P>
      ) : (
        <Spread>
          <P>
            Publish this template so that users can start using it and fill
            assessments for this template.
          </P>
          <Button text="Publish template" onClick={submit} />
        </Spread>
      )}
    </Panel>
    {!publishSettings.isShareHidden && !template.isPreAssessment && (
      <Share
        template={template}
        refetch={refetchPublishSettings}
        disabled={!publishSettings.isShareEnabled}
        key={fetchCount}
      />
    )}
  </>
)

export default R.compose(
  withSubmit({
    onSubmit: p => () => publishRequest.publish(p.template.id),
    onSuccess: p => () => {
      p.refetchPublishSettings()
      p.refetch()
    },
  }),
)(PublishShare)

import React from "react"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import Link from "components/Link"
import * as R from "ramda"
import theme from "theme"
import withModal from "hoc/withModal"
import data from "./data.js"
import { ScrollBody } from "./styled"

const CreditsPage = () => (
  <ScrollBody>
    <P mb={20}>© 2023 PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft.</P>
    <P mb={20}>
      PwC refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity.
    </P>
    <P mb={20}>
      Please see <Link to="https://www.pwc.com/structure">www.pwc.com/structure</Link> for further details.
    </P>
    <P mb={20}>
      Portions of this program may use third-party open source components governed by the respective open source license terms. 
    </P>
    <P mb={20}>
      In the event of a conflict between the terms of any OSS license, or any additional terms (such as, for example, disclaimers) imposed thereby, and those of any license or other terms provided by PwC with the program ("PwC terms"), those of the OSS license shall prevail. "PwC" refers to the PwC network and/or one or more of its member firms, each of which is a separate legal entity. Please see <Link to="https://www.pwc.com/structure">www.pwc.com/structure</Link> for further details.
    </P>
    <P mb={20}>
      Without limiting the foregoing, to the extent any such OSS license prohibits restrictions contained in the PwC terms with respect to an OSS component, such restrictions will not apply to such component. Notwithstanding anything to the contrary set forth in the PwC terms, PwC will have no liability with respect to claims of infringement or misappropriation of third party intellectual property rights arising from such OSS components.
    </P>
    <P mb={20}>
      Any OSS component is provided on an "as is" basis, without warranty of any kind, either expressed, implied, or statutory, including, without limitation, warranties that it is free of defects, merchantable, fit for a particular purpose or non-infringing.
    </P>
    <P mb={20}>
      The entire risk as to the quality and performance of any OSS component is with you.
    </P>
    <P mb={20}>
      Should any OSS component prove defective in any respect, you alone assume the cost of any necessary servicing, repair, or correction.
    </P>
    <P mb={20}>
      This disclaimer of warranty constitutes an essential part of the OSS licenses and PwC terms.
    </P>
    <P mb={20}>
      No use of any OSS component is authorized under the OSS licenses or PwC terms except under this disclaimer.
    </P>
    <P mb={20}>
      Any PwC terms that differ from the terms of the OSS licenses, including any additional warranties or service level, maintenance or other obligations that may be offered by PwC, are offered by PwC alone.
    </P>
    <P mb={20}>
      Under no circumstances and under no legal theory, whether tort (including negligence), contract, or otherwise, shall any contributor to the OSS components, or anyone who distributes the OSS components as permitted by the OSS licenses, be liable to you for any direct, indirect, special, incidental, or consequential damages of any character including, without limitation, damages for lost profits, loss of goodwill, work stoppage, computer failure or malfunction, or any and all other commercial damages or losses, even if such party shall have been informed of the possibility of such damages.
    </P>
    <P mb={20}>
      This limitation of liability shall not apply to liability for death or personal injury resulting from such party's negligence to the extent applicable law prohibits such limitation. Some jurisdictions do not allow the exclusion or limitation of incidental or consequential damages, so this exclusion and limitation may not apply to you.
    </P>

    {data.componentLicenses.map((x, xi) => (
      <Box flex mt={10} key={xi} bg={theme.color.borderLight} p={10}>
        <Link ul to={x.link}>
          {x.component.projectName}@{x.component.versionName}
        </Link>
        <P ml={20}>{x.licenses.map(x => x.name).join(", ")}</P>
      </Box>
    ))}

    <P mt={30} mb={20} bold>Copyright Data</P>

    {data.componentCopyrightTexts.map((x, xi) => (
      <P>
        <P mb={5}>{x.componentVersionSummary.projectName} {x.componentVersionSummary.versionName} -- {x.originFullName}</P>
        <P mb={15} ml={20} ul>
          {x.copyrightTexts.map(ct => (
            <li>{ct}</li>
          ))}
        </P>
      </P>
    ))}

    <P mt={30} mb={20} bold>Licenses</P>

    {data.licenseTexts.map((x, xi) => (
      <P mb={20}>
        <P mb={10} fs={20}>{x.name}</P>
        <P fs={14}>{x.components.map(c => c.projectName + ' ' + c.versionName).join(", ")}</P>
        
        <Box flex mt={10} key={xi} bg={theme.color.borderLight}>
          <P mt={10} mb={10}>
            {x.text.split("\n").map((y, yi) => (
              <P>{y}</P>
            ))}
          </P>
        </Box>
      </P>
    ))}

  </ScrollBody>
)

export default withModal(R.always("Credits"), {
  noScroll: true,
  fullScreen: true,
  noPadding: true,
})(CreditsPage)

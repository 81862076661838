import { get, post, put, del } from "common/request"
import { encodeQueryParams } from "common/url"

export const assessmentRequest = {
  list: params => get(`/api/assessments?${params}`),
  count: () => get("/api/assessments/counts"),
  listTemplates: () => get(`/api/assessment/templates`),
  detail: id => get(`/api/assessment/${id}`),
  conclusion: id => get(`/api/assessment/${id}/conclusions`),
  templateDetail: templateId => get(`/api/assessment/template/${templateId}`),
  create: data => post("/api/assessment", data),
  edit: (id, data) => put(`/api/assessment/${id}`, data),
  copy: (id, data) => post(`/api/assessment/${id}/copy`, data),
  delete: (id, data) => put(`/api/assessment/${id}/delete`, data),
  delegate: (id, data) => post(`/api/assessment/${id}/delegate`, data),
  verify: id => get(`/api/assessment/${id}/verify?lang=en`),
  submit: id => put(`/api/assessment/${id}/submit?lang=en`),
  history: id => get(`/api/assessment/${id}/history?lang=en`),
  approve: id => put(`/api/assessment/${id}/approve`),
  reject: (id, data) => put(`/api/assessment/${id}/reject`, data),
  withdraw: id => put(`/api/assessment/${id}/withdraw`),
  restore: data => post(`/api/assessments/restore`, data),
  restoreAll: () => post(`/api/assessments/restoreAll`),
  deletePermanent: data => post(`/api/assessments/deletePermanent`, data),
  setFavorite: assessmentId => on =>
    put(`/api/assessment/${assessmentId}/favorites?on=${on}`),
  deleteAllPermanent: () => del(`/api/assessments/deleteAllPermanent`),
  getByArrangementId: arrangementId =>
    get(`/api/assessment/arrangement/${arrangementId}`),
  exportDetailAsZip: (assessmentId, type) =>
    post(`/api/assessment/${assessmentId}/fileExport`, {
      type,
    }),
  uploadCaseDescriptionFile: assessmentId => data =>
    post(`/api/assessment/${assessmentId}/case-description/file`, data, {
      doNotSetContentType: true,
    }),
  uploadCaseDescriptionFileNew: data =>
    post(`/api/assessment/case-description/file`, data, {
      doNotSetContentType: true,
    }),
  downloadCaseDescriptionFile: assessmentId => attachmentId =>
    get(
      `/api/assessment/${assessmentId}/case-description/file/${attachmentId}`,
    ),
}

export const assessmentQuestionRequest = {
  list: id => get(`/api/assessment/${id}/questions`),
  answer: (assessmentId, questionId, data) =>
    put(`/api/assessment/${assessmentId}/question/${questionId}/answer`, data),
  bookmark: (assessmentId, questionId, isBookmarked) =>
    put(
      `/api/assessment/${assessmentId}/question/${questionId}/bookmark/${isBookmarked}`,
    ),
  lastPosition: id => get(`/api/assessment/${id}/lastposition`),
  info: (templateId, questionId) =>
    get(`/api/template/${templateId}/question/${questionId}/info`),
  downloadFile: (templateId, attachmentId) =>
    get(`/api/template/${templateId}/file/${attachmentId}`),
  uploadAnswerFile: (assessmentId, questionId) => data =>
    post(`/api/assessment/${assessmentId}/question/${questionId}/file`, data, {
      doNotSetContentType: true,
    }),
  downloadAnswerFile: assessmentId => attachmentId =>
    get(`/api/assessment/${assessmentId}/answer-attachment/${attachmentId}`),
  comments: (assessmentId, questionId) =>
    get(`/api/assessment/${assessmentId}/question/${questionId}/comments`),
  addComment: (assessmentId, questionId, data) =>
    post(
      `/api/assessment/${assessmentId}/question/${questionId}/comment`,
      data,
    ),
  editComment: (assessmentId, questionId, commentId, data) =>
    put(
      `/api/assessment/${assessmentId}/question/${questionId}/comment/${commentId}`,
      data,
    ),
  deleteComment: (assessmentId, questionId, commentId) =>
    del(
      `/api/assessment/${assessmentId}/question/${questionId}/comment/${commentId}`,
    ),
}

export const assessmentCoworkersRequest = {
  list: (email, ids) =>
    get(
      `/api/assessment/availablePersons${encodeQueryParams({
        filterEmail: email,
        excludeUserIds: ids,
      })}`,
    ),
  coworkers: id => get(`/api/assessment/${id}/coworkers`),
  edit: (id, data) => post(`/api/assessment/${id}/coworkers`, data),
}

export const assessmentLibraryRequest = {
  addToLibrary: (assessmentId, data) =>
    post(`/api/assessmentLibrary/${assessmentId}/add`, data),
  count: () => get("/api/assessmentLibrary/counts"),
  list: params => get(`/api/assessmentLibrary?${params}`),
  listTemplate: templateId =>
    get(`/api/assessmentLibrary/template/${templateId}`),
  detail: id => get(`/api/assessmentLibrary/${id}`),
  reporting: assessmentId =>
    get(`/api/assessmentLibrary/${assessmentId}/reporting`),
  history: id => get(`/api/assessmentLibrary/${id}/history?lang=en`),
  edit: (id, data) => put(`/api/assessmentLibrary/${id}`, data),
  setFavorite: assessmentId => on =>
    put(`/api/assessmentLibrary/${assessmentId}/favorites?on=${on}`),
  verify: id => get(`/api/assessmentLibrary/${id}/verify?lang=en`),
  conclusion: id => get(`/api/assessmentLibrary/${id}/conclusions`),
  requestForPublication: id =>
    put(`/api/assessmentLibrary/${id}/requestForPublication`),
  publish: id => put(`/api/assessmentLibrary/${id}/publish`),
  withdraw: id => put(`/api/assessmentLibrary/${id}/withdraw`),
  reject: (id, data) => put(`/api/assessmentLibrary/${id}/reject`, data),
  delete: id => del(`/api/assessmentLibrary/${id}`),
  create: (id, data) => post(`/api/assessmentLibrary/${id}/create`, data),
  tags: params => get(`/api/assessmentLibrary/tags?${params}`),
  deleteTags: data => del(`/api/assessmentLibrary/tags`, data),
  createTag: data => post(`/api/assessmentLibrary/tag`, data),
  updateTag: (id, data, force) =>
    post(
      `/api/assessmentLibrary/tag/${id}?forceUpdate=${force ?? false}`,
      data,
    ),
  searchTags: (query, ids) =>
    get(
      `/api/assessmentLibrary/tags/${query}${encodeQueryParams({
        excludeTagIds: ids,
      })}`,
    ),
}

export const assessmentLibraryCoworkersRequest = {
  list: (email, ids) =>
    get(
      `/api/assessment/availablePersons${encodeQueryParams({
        filterEmail: email,
        excludeUserIds: ids,
      })}`,
    ),
  coworkers: id => get(`/api/assessmentLibrary/${id}/coworkers`),
  edit: (id, data) => post(`/api/assessmentLibrary/${id}/coworkers`, data),
}

export const assessmentLibraryQuestionRequest = {
  list: id => get(`/api/assessmentLibrary/${id}/questions`),
  lastPosition: id => get(`/api/assessmentLibrary/${id}/lastposition`),
  answer: (assessmentId, questionId, data) =>
    put(
      `/api/assessmentLibrary/${assessmentId}/question/${questionId}/answer`,
      data,
    ),
  uploadAnswerFile: (assessmentId, questionId) => data =>
    post(
      `/api/assessmentLibrary/${assessmentId}/question/${questionId}/file`,
      data,
      {
        doNotSetContentType: true,
      },
    ),
  downloadAnswerFile: assessmentId => attachmentId =>
    get(
      `/api/assessmentLibrary/${assessmentId}/answer-attachment/${attachmentId}`,
    ),
  comments: (assessmentId, questionId) =>
    get(
      `/api/assessmentLibrary/${assessmentId}/question/${questionId}/comments`,
    ),
  addComment: (assessmentId, questionId, data) =>
    post(
      `/api/assessmentLibrary/${assessmentId}/question/${questionId}/comment`,
      data,
    ),
  editComment: (assessmentId, questionId, commentId, data) =>
    put(
      `/api/assessmentLibrary/${assessmentId}/question/${questionId}/comment/${commentId}`,
      data,
    ),
  deleteComment: (assessmentId, questionId, commentId) =>
    del(
      `/api/assessmentLibrary/${assessmentId}/question/${questionId}/comment/${commentId}`,
    ),
}

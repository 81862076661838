import * as R from "ramda"

export const firstLetterSmall = str =>
  `${R.head(str).toLowerCase()}${str.slice(1)}`

export const arrToAndString = arr => {
  if (arr.length === 1) return R.head(arr)
  if (arr.length === 2) return arr.join(" and ")

  return `${arr.slice(0, -2).join(", ")}, ${arrToAndString(arr.slice(-2))}`
}

export const isWhitespace = str => /^\s+$/.test(str)

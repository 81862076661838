import React from "react"
import { statuses } from "data/assessments/statuses"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import BookmarkButton from "./BookmarkButton"
import CommentsButton from "./CommentsButton"
import InfoButton from "./InfoButton"
import { IconWrap, QuestionFooterWrap } from "./styled"

const QuestionFooter = ({
  isOpen,
  toggle,
  node,
  refetch,
  assessmentId,
  canEdit,
  assessment,
  isPreview,
  hideBookmarks,
  questionRequests,
  isLibrary,
  isPreassessment,
}) => (
  <QuestionFooterWrap>
    {!node.data.isEnabled && (
      <IconWrap rotate={+isOpen} onClick={toggle}>
        <OutlineArrow />
      </IconWrap>
    )}
    {(node.data.isEnabled || isOpen) && (
      <>
        {(canEdit || assessment.status === statuses.requestForApproval.value) &&
          !isPreview &&
          !isLibrary &&
          !hideBookmarks && (
            <BookmarkButton
              assessmentId={assessmentId}
              questionId={node.referencedId}
              active={node.data.isBookmarked}
              refetch={refetch}
            />
          )}

        <InfoButton
          templateId={assessment.templateId}
          questionId={node.referencedId}
          index={node.idx}
          hasSupportiveInfo={node.data.hasSupportiveInfo}
        />
        {!isPreview && (
          <CommentsButton
            assessmentId={assessmentId}
            questionId={node.referencedId}
            index={node.idx}
            hasComments={node.data.hasComments}
            canEdit={canEdit}
            refetch={refetch}
            questionRequests={questionRequests}
            isLibrary={isLibrary}
            isPreassessment={isPreassessment}
          />
        )}
      </>
    )}
  </QuestionFooterWrap>
)

export default QuestionFooter

import React from "react"
import { getDefaultSorting, getData } from "./helpers"
import { noop } from "common/functions"

const useTable = ({
  pageSize,
  sorting,
  config,
  data,
  params,
  onParamChange = noop,
  count,
  noPagination,
}) => {
  React.useEffect(() => {
    setSize_(params?.size || (noPagination ? Infinity : 10))
    setFilters_(params?.filters ?? {})
    setSort_(params?.sort ?? getDefaultSorting(sorting, config))
    setPage_(params?.page ?? 0)
    setSearch_(params?.search ?? "")
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])
  const [size, setSize_] = React.useState(pageSize || 10)
  const [filters, setFilters_] = React.useState({})
  const [sort, setSort_] = React.useState(getDefaultSorting(sorting, config))
  const [page, setPage_] = React.useState(0)
  const [search, setSearch_] = React.useState("")
  const { paginatedData, filteredSearchedSortedData } = getData({
    config,
    data,
    search,
    filters,
    sort,
    size,
    page,
    count,
  })

  // const firstRender = useRef(true)
  // useEffect(() => {
  //   // do not run on first render
  //   if (firstRender.current) {
  //     firstRender.current = false
  //     return
  //   }
  //   setPage(0)
  // }, [size, search, filters]) // eslint-disable-line

  const setSize = v => {
    const newVal = {
      size: v,
      page: 0,
      search,
      filters,
      sort,
    }
    setSize_(v)
    setPage_(0)
    onParamChange(newVal)
  }
  const setFilters = v => {
    const newVal = {
      filters: v,
      size,
      page: 0,
      search,
      sort,
    }
    setFilters_(v)
    setPage_(0)
    onParamChange(newVal)
  }
  const setPage = v => {
    const newVal = {
      page: v,
      size,
      search,
      filters,
      sort,
    }
    setPage_(v)
    onParamChange(newVal)
  }
  const setSearch = v => {
    const newVal = {
      search: v,
      size,
      page: 0,
      filters,
      sort,
    }
    setSearch_(v)
    setPage_(0)
    onParamChange(newVal, "search")
  }
  const setSort = v => {
    const newVal = {
      search,
      size,
      page,
      filters,
      sort: v,
    }
    setSort_(v)
    onParamChange(newVal)
  }

  return {
    size,
    setSize,
    page,
    setPage,
    filters,
    setFilters,
    sort,
    setSort,
    search,
    setSearch,
    paginatedData,
    filteredSearchedSortedData,
  }
}

export default useTable

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { conclusionRequest } from "data/conclusions/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import { stringRequired } from "validators/string"
import * as R from "ramda"

const RejectModal = ({ close }) => (
  <>
    <P mb={20}>{localize("rejectionWillRevokeConclusion")}</P>
    <Textarea
      required
      name="reason"
      label={localize("reasonForRejection")}
      placeholder={localize("writeTheReason")}
    />
    <P mb={20} bold>
      {localize("areYouSureYouWantToRejectConclusion")}
    </P>
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="No" />
      <Button danger type="submit" text={localize("yesReject")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(p => `${localize("rejectConclusion")} ${p.conclusion.name}`),
  withUrls,
  withForm({
    schema: R.always({
      reason: stringRequired,
    }),
    onSubmit: p => v => conclusionRequest.reject(p.conclusion.id, v),
    onSuccess: p => () => {
      p.close()
      // p.refetch()
    },
    successText: p => () =>
      localize("conclusionRejectedSuccess")(p.conclusion.name),
    redirect: (_, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return `${R.head(
          p.match.url.split("conclusion/"),
        )}list?involvedPartyId=${R.path(
          ["breadcrumbs", "case", "involvedParties", 0, "id"],
          p.assessment,
        )}`
      }
      return p.urls.conclusions
    },
  }),
)(RejectModal)

import React from "react"

export default () => (
  <svg width="24px" height="24px" viewBox="0 0 24 24">
    <path
      d="M15.6,11.79 C16.57,11.12 17.25,10.02 17.25,9 C17.25,6.74 15.5,5 13.25,5 L7,5 L7,19 L14.04,19 C16.13,19 17.75,17.3 17.75,15.21 C17.75,13.69 16.89,12.39 15.6,11.79 Z M10,7.5 L13,7.5 C13.83,7.5 14.5,8.17 14.5,9 C14.5,9.83 13.83,10.5 13,10.5 L10,10.5 L10,7.5 Z M13.5,16.5 L10,16.5 L10,13.5 L13.5,13.5 C14.33,13.5 15,14.17 15,15 C15,15.83 14.33,16.5 13.5,16.5 Z"
      fillRule="nonzero"
    />
  </svg>
)

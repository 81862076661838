import React from "react"
import { localize } from "locale"
import * as R from "ramda"
import Link from "components/Link"
import { formatDate } from "data/units/date/formatters"
import IdSearch from "inputs/IdSearch"

export const config = suburls => ({
  columns: [
    {
      label: localize("reportId"),
      path: x => (
        <Link ul to={suburls.report.url(x.id)}>
          {x.massReportRefId}
        </Link>
      ),
      sortKey: "MassReportRefId",
    },
    {
      label: localize("createdOn"),
      path: x => formatDate(x.createdAtUtc),
      sortKey: "CreatedAtUtc",
    },
    {
      label: localize("includedConclusions"),
      path: x =>
        `${x.conclusionsCount} ${localize(
          x.conclusionsCount === 1 ? "conclusion" : "conclusions",
        )}`,
      sortKey: "IncludedConclusions",
    },
    {
      label: localize("createdBy"),
      path: R.prop("createdByUser"),
      sortKey: "CreatedByUser.Email",
    },
    {
      label: localize("country"),
      path: R.prop("countryName"),
      sortKey: "Country.Name",
    },
    {
      label: localize("filingEntityId"),
      path: R.prop("entityId"),
      sortKey: "EntityId",
    },
    {
      label: localize("filingEntityName"),
      path: R.prop("entityName"),
      sortKey: "EntityName",
    },
  ],
  filters: [
    {
      name: "id",
      render: (value, onChange) => (
        <IdSearch
          small
          value={value}
          onChange={onChange}
          text={`You may search report(s) via "Internal arrangement ID", country specific report information (e.g. Entity ID, Entity name, etc.) if available, and IDs received from the tax authority (arrangement ID, disclosure ID, etc.) if available, through exact match.`}
        />
      ),
      filter: R.always(true),
    },
  ],
  search: R.always(true),
})

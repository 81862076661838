import styled, { css } from "styled-components/macro"
import { Tr } from "styledComponents/Table"

export const BaseTr = styled(Tr)`
  ${p =>
    p.isOpen &&
    css`
      border-bottom: 0;
    `}
`
export const MoreTr = styled.tr`
  td {
    border-top: 0;
  }
`

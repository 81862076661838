import React from "react"
import DelegateIcon from "icons/appkit/outline-share"
import DelegateAssessmentModal from "./DelegateAssessmentModal"
import ModalButtons from "components/ModalButtons"
import Button from "components/Button"
import { P } from "styledComponents/Typography"
import { formatInitialsFromName, formatUserName } from "data/user/formatters"
import UserList from "inputs/UserList"
import InfoModal from "components/InfoModal"
import * as R from "ramda"
import withForm from "hoc/withForm"
import { arrayRequired } from "validators/array"
import { stringRequired } from "validators/string"
import { preassessmentRequest } from "data/preassessments/api"
import { assessmentRequest } from "data/assessments/api"
import withState from "hoc/withState"
import withOpen from "hoc/withOpen"

const DelegateButton = ({
  assessment,
  isPreassessment,
  submitForm,
  extraCoworkers,
  setExtraCoworkers,
  isOpen,
  close,
  open,
}) => {
  return (
    <>
      <Button
        secondary
        icon={DelegateIcon}
        text="Delegate assessment"
        onClick={open}
      />
      {isOpen && !extraCoworkers && (
        <DelegateAssessmentModal
          modal={DelegateAssessmentModal}
          assessment={assessment}
          isPreassessment={isPreassessment}
          submit={submitForm}
          close={close}
        />
      )}
      {extraCoworkers && (
        <InfoModal
          title="Pre-assessment coworkers"
          body={
            <>
              <P mb={20}>
                Following users are not case coworkers and won't be able to
                access pre-assessment:
              </P>
              <UserList
                value={extraCoworkers}
                optionToAvatar={R.pipe(formatUserName, formatInitialsFromName)}
                optionToTitle={formatUserName}
                optionToSubtitle={R.prop("email")}
              />
              <P mt={20}>
                If you proceed users will be added as case coworkers (with Case
                Viewer role).
              </P>
              <ModalButtons buttonText="Add access to the case for above users?">
                <Button
                  secondary
                  text="Back"
                  onClick={() => setExtraCoworkers()}
                />
                <Button
                  text="Yes, add"
                  onClick={() => {
                    setExtraCoworkers()
                    close()
                    submitForm()
                  }}
                />
              </ModalButtons>
            </>
          }
          close={() => setExtraCoworkers()}
        />
      )}
    </>
  )
}

export default R.compose(
  withOpen,
  withState("extraCoworkers", "setExtraCoworkers"),
  withForm({
    schema: R.always({
      users: arrayRequired,
      comment: stringRequired,
    }),
    onSubmit: p => v => {
      const data = {
        comment: v.comment,
        users: v.users.map(user => user.value),
        forceAddCoworkers: !!p.extraCoworkers,
      }
      return (
        p.isPreassessment ? preassessmentRequest : assessmentRequest
      ).delegate(p.assessment.id, data)
    },
    onSuccess: p => () => {
      p.close()
      p.refetchCase?.()
    },
    successText: p => (_, values) =>
      `You have delegated the ${p.isPreassessment ? "pre-" : ""}assessment ${
        p.assessment.name
      } to ${values.users.map(user => formatUserName(user)).join(", ")}.`,
    onError: p => (_, err, values) => {
      if (err.error.status === 409) {
        p.setExtraCoworkers(
          values.users.filter(x => err.error.response.data.includes(x.id)),
        )
      }
    },
    errorText: () => (msg, err) => err.error.status === 409 ? null : msg,
  }),
)(DelegateButton)

import React from "react"
import * as R from "ramda"
import { getInitialState, handleSubmit } from "./helpers"
import { noopHandler } from "common/functions"
import { useHistory } from "react-router"
import Loader from "components/Loader"
import PreventLoseProgress from "components/PreventLoseProgress"
import { emptyRequest } from "common/rxjs"

export const ctx = React.createContext()

const withForm =
  ({
    mapProps = R.always({}),
    schema = R.always({}),
    onSubmit = () => R.always(emptyRequest),
    onSuccess = noopHandler,
    onError = noopHandler,
    successText,
    errorText,
    redirect,
    formId,
    dontWrapInForm,
    preventLoseProgress = false,
  }) =>
  C =>
  p => {
    const initialValues = mapProps(p) || {}
    const initialValidationSchema = schema(p, initialValues)
    const [form, setForm] = React.useState(
      getInitialState(initialValidationSchema, initialValues),
    )
    const [obs, setObs] = React.useState(undefined)

    const onChangeHandler = name => v => {
      setForm(old => {
        const newValues = R.set(R.lensPath([name]), v, old.values)
        return R.pipe(
          R.set(R.lensPath(["values"]), newValues),
          R.set(R.lensPath(["dirty", name]), true),
          R.set(
            R.lensPath(["errors"]),
            R.mapObjIndexed(
              (validateFn, key) => validateFn(newValues[key]),
              schema(p, newValues),
            ),
          ),
        )(old)
      })
    }

    const resetForm = () =>
      setForm(getInitialState(initialValidationSchema, initialValues))

    // unsubscribe from pending task if applicable
    React.useEffect(() => {
      return () => {
        obs?.unsubscribe()
      }
    }, []) // eslint-disable-line

    const history = useHistory()

    // returns task which is tracked to be able to cancel it upon unmounting
    const submit = e => {
      const observable = handleSubmit({
        form,
        setForm,
        p,
        onSubmit,
        onSuccess,
        onError,
        successText,
        errorText,
        redirect,
        onChangeHandler,
        history,
      })(e)

      setObs(() => observable)
    }

    // if used just for form-less submission, just submit prop is passed
    if (R.isEmpty(initialValidationSchema)) {
      return <C {...p} submit={submit} />
    }

    const content = (
      <PreventLoseProgress
        when={
          preventLoseProgress && !R.isEmpty(form.dirty) && !form.isSubmitting
        }
      >
        <ctx.Provider
          value={{
            ...form,
            onChange: onChangeHandler,
          }}
        >
          <C
            {...p}
            {...form}
            setValues={v =>
              setForm(old => {
                return R.pipe(
                  R.set(R.lensPath(["values"]), v),
                  R.set(R.lensPath(["dirty"]), R.map(R.always(true), v)),
                  R.set(
                    R.lensPath(["errors"]),
                    R.mapObjIndexed(
                      (validateFn, key) => validateFn(v[key]),
                      schema(p, v),
                    ),
                  ),
                )(old)
              })
            }
            setFieldValue={(name, v) =>
              setForm(old => {
                const newValues = R.set(R.lensPath([name]), v, old.values)
                return R.pipe(
                  R.set(R.lensPath(["values"]), newValues),
                  R.set(R.lensPath(["dirty", name]), true),
                  R.set(
                    R.lensPath(["errors"]),
                    R.mapObjIndexed(
                      (validateFn, key) => validateFn(newValues[key]),
                      schema(p, newValues),
                    ),
                  ),
                )(old)
              })
            }
            resetForm={resetForm}
            submitForm={submit}
          />
          {form.isSubmitting && <Loader center />}
        </ctx.Provider>
      </PreventLoseProgress>
    )

    // wrap in form to enable submission by pressing enter
    return dontWrapInForm ? (
      content
    ) : (
      <form onSubmit={submit} noValidate id={formId}>
        {content}
      </form>
    )
  }

export default config => withForm(config)

import styled, { css } from "styled-components/macro"

export const PaginationWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 5px 20px;
  font-size: 14px;
`

export const PaginationShown = styled.div`
  color: #999;
  font-size: 12px;
  margin-left: 20px;
  line-height: 20px;
`

export const PaginationLeft = styled.div`
  display: flex;
  align-items: center;
`
export const PaginationControls = styled.div`
  display: flex;
  align-items: center;
`

export const PaginationPage = styled.div`
  margin: 0 20px;
  color: #333;
  font-size: 12px;
`

export const ArrowWrap = styled.div`
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  ${p =>
    p.disabled &&
    css`
      pointer-events: none;
      fill: #d8d8d8;
    `};
  svg {
    width: 16px;
    fill: #333;

    ${p =>
      p.flip &&
      css`
        transform: scaleX(-1);
      `};
  }
`

import styled from "styled-components/macro"
import theme from "theme"

export const SectionWrap = styled.div`
  margin-top: 20px;
  border: 1px solid ${theme.color.border};
`

export const SectionHeader = styled.div`
  background: ${p => (p.depth === 1 ? "#333" : "#666")};
  padding: 0 20px;
  line-height: 50px;
`
export const SectionBody = styled.div`
  padding: 20px;
  background: #fff;
`

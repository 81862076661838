import React from "react"
import Table from "components/Table"
import ConfirmModal from "components/ConfirmModal"
import { previewRequest } from "data/templates/api"
import { config } from "./config"
import { localize } from "locale"
import withState from "hoc/withState"
import withUrls from "hoc/withUrls"
import * as R from "ramda"

const TestFilesTable = ({
  data,
  canEdit,
  openTest,
  setOpenTest,
  refetch,
  urls,
}) => (
  <>
    <Table config={config(urls, setOpenTest, canEdit)} data={data} />
    {openTest && (
      <ConfirmModal
        close={() => setOpenTest()}
        onSuccess={refetch}
        title={`${localize("deleteTestFile")}: ${openTest.name}`}
        confirmText={localize("doYouWantToDeleteTestFile")}
        cancelText="No"
        submitText="Yes"
        successText={() => localize("testFileSuccessfullyDeleted")}
        request={() => previewRequest.delete(openTest.id)}
      />
    )}
  </>
)

export default R.compose(
  withUrls,
  withState("openTest", "setOpenTest", undefined),
)(TestFilesTable)

import React from "react"
import Columns from "components/Columns"
import Button from "components/Button"
import { getSubquestionOptions, answerTypes } from "data/templates/answers"
import withFormField from "hoc/withFormField"
import WarningConfirmModal from "components/WarningConfirmModal"
import CrossIcon from "icons/Cross"
import Textarea from "inputs/Textarea"
import Checkbox from "inputs/Checkbox"
import Select from "inputs/Select"
import { Box, Spread } from "styledComponents/Containers"
import InputAnswers from "inputs/InputAnswers"
import InputLogic from "inputs/InputLogic"
import { IconWrap } from "styledComponents/Icons"
import PlusIcon from "icons/Plus"
import { localize } from "locale"
import * as R from "ramda"
import { P } from "styledComponents/Typography"
import { getTemporaryId } from "common/ids"
import CountryLabel from "components/CountryLabel"
import Panel from "components/Panel"
import { ActiveIcon } from "styledComponents/Icons"
import SortIcon from "icons/appkit/fill-sort-default"
import { showMoveSubquestionToStart, showMoveSubquestion } from "./helpers"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"

const toSubquestionOption = questionIndex => (y, yi) => ({
  referencedId: y.id,
  idx: `${questionIndex}.${yi + 1}`,
  data: y,
})

const InputSubquestions = ({
  value,
  add,
  remove,
  update,
  readOnly,
  borderless,
  questionIndex,
  allowTextChangeInReadOnly,
  onMove,
  canMove,
  moveIndex,
  questionId,
  onMoveConfirm,
  applicableQuestions = [],
  indices = [],
}) => {
  const moving = !!moveIndex

  return (
    <>
      {showMoveSubquestionToStart(moveIndex, questionId, indices) && (
        <Button
          text={localize("moveSubquestionHere")}
          mb={10}
          onClick={() => onMoveConfirm(null, null)}
        />
      )}
      {value.map((x, xi) => {
        const movingThis = moveIndex && moveIndex.subquestionId === x.id
        const subquestionNumber = `${questionIndex}.${xi + 1}`
        const newApplicableQuestions = applicableQuestions.concat(
          value.slice(0, xi).map(toSubquestionOption(questionIndex)),
        )
        const isCustom = x.questionType === "Custom"

        const newIndices = indices.concat(xi)

        return (
          <React.Fragment key={x.id}>
            <Panel key={xi} mt={xi === 0 ? 0 : 20}>
              <Spread mb={5}>
                <P bold fs={18} mb={5} required={!readOnly}>
                  {localize("subquestion")} {subquestionNumber}
                </P>
                {canMove && value.length > 1 && (
                  <ActiveIcon
                    onClick={() =>
                      onMove(
                        movingThis
                          ? undefined
                          : {
                              type: "subquestion",
                              subquestionId: x.id,
                              questionId: questionId,
                              indices: indices.concat(xi),
                            },
                      )
                    }
                    disabled={moving && !movingThis}
                    active={movingThis}
                  >
                    <SortIcon />
                  </ActiveIcon>
                )}
                {!readOnly && (
                  <WarningConfirmModal
                    onConfirm={() => remove(xi)}
                    text={localize(
                      "theSubquestionWillBeDeletedWithAllLowerLevelSubquestions",
                    )}
                    buttonText={localize("doYouWantToDeleteTheSubquestion")}
                    title={localize("deleteSubquestion")}
                    doConfirm={() =>
                      x.subQuestions && x.subQuestions.length > 0
                    }
                    confirmText={localize("yesDelete")}
                    dangerConfirm
                  >
                    {onClick => (
                      <IconWrap onClick={onClick} autowidth>
                        <CrossIcon />
                      </IconWrap>
                    )}
                  </WarningConfirmModal>
                )}
              </Spread>
              <InputLogic
                previousQuestions={newApplicableQuestions}
                value={x.precondition}
                onChange={update("precondition", xi)}
                conditionText={localize("showQuestionIf")}
                readOnly={readOnly}
                borderless={borderless}
                entityName={localize("subquestion")}
              />
              {!isCustom && (
                <Box mt={10}>
                  <Checkbox
                    label={localize("mandatoryAnswer")}
                    value={x.isMandatory}
                    onChange={update("isMandatory", xi)}
                    readOnly={readOnly}
                    borderless={readOnly}
                    flip
                  />
                </Box>
              )}
              <P required={!isCustom} bold gray mt={10} mb={10}>
                {localize("question")}
              </P>
              <Columns
                gap={10}
                widths={["auto", 300]}
                mb={10}
                align="flex-start"
              >
                <Textarea
                  value={x.questionText}
                  onChange={update("questionText", xi)}
                  placeholder={localize("enterYourSubquestion")}
                  readOnly={readOnly && !allowTextChangeInReadOnly}
                  borderless={readOnly && !allowTextChangeInReadOnly}
                />
                <div>
                  <Select
                    value={x.questionType}
                    onChange={update("questionType", xi)}
                    options={getSubquestionOptions(newIndices.length)}
                    byValue
                    readOnly={readOnly}
                    borderless={borderless}
                  />
                  {!readOnly && <CountryLabel type={x.questionType} />}
                </div>
              </Columns>
              {(x.questionType === answerTypes.single.value ||
                x.questionType === answerTypes.multiple.value) && (
                <Box ml={20} mb={20}>
                  <InputAnswers
                    value={x.options}
                    type={x.questionType}
                    onChange={update("options", xi)}
                    readOnly={readOnly}
                    borderless={borderless}
                    allowTextChangeInReadOnly={allowTextChangeInReadOnly}
                    allOptions={R.chain(R.propOr([], "options"), value)}
                  />
                </Box>
              )}
              {x.questionType === answerTypes.custom.value && (
                <EnhancedInputSubquestions
                  value={x.subQuestions}
                  onChange={update("subQuestions", xi)}
                  readOnly={readOnly && !allowTextChangeInReadOnly}
                  borderless={borderless}
                  questionIndex={subquestionNumber}
                  questionId={questionId}
                  applicableQuestions={newApplicableQuestions.concat(
                    toSubquestionOption(questionIndex)(x),
                  )}
                  canMove={canMove}
                  onMove={onMove}
                  moveIndex={moveIndex}
                  onMoveConfirm={onMoveConfirm}
                  indices={indices.concat(xi)}
                />
              )}
            </Panel>
            {showMoveSubquestion(
              moveIndex,
              questionId,
              movingThis,
              newIndices,
            ) && (
              <Button
                text={localize("moveSubquestionHere")}
                mt={20}
                onClick={() => onMoveConfirm(null, x.id)}
              />
            )}
          </React.Fragment>
        )
      })}
      {!readOnly && (
        <Button
          mt={value.length === 0 ? 0 : 20}
          icon={PlusIcon}
          text={`${localize("addSubquestion")} ${questionIndex}.${
            value.length + 1
          }`}
          onClick={add}
        />
      )}
    </>
  )
}

const EnhancedInputSubquestions = R.compose(
  withFormField,
  withProps(p => ({
    value: p.value || [],
  })),
  withHandlers({
    add: p => () => {
      const newVal = p.value.concat({
        id: getTemporaryId(p.value),
        questionText: "",
      })
      p.onChange(newVal)
    },
    remove: p => i => p.onChange(R.remove(i, 1, p.value)),
    update: p => (k, i) => v => {
      const newVal = R.set(R.lensPath([i, k]), v, p.value)
      p.onChange(newVal)
    },
  }),
)(InputSubquestions)

export default EnhancedInputSubquestions

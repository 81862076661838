import React from "react"
import * as R from "ramda"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { StyledRow } from "../styled"

const TBody = ({
  columns,
  rowComponent: RowComponent,
  onItemClick = R.always(),
  paginatedData,
  striped = true,
  summary,
  highlightFirst,
  idKey = R.prop("id"),
  lastRight,
  ...rest
}) => (
  <tbody>
    {summary && (
      <StyledRow highlight>
        {columns.map((col, ci) => (
          <td key={ci}>
            <P bolder nowrap={col.nowrap}>
              {col.path(summary)}
            </P>
          </td>
        ))}
      </StyledRow>
    )}
    {paginatedData.map((row, ri) => {
      const key = row[idKey] ?? ri

      return RowComponent ? (
        <RowComponent
          item={row}
          key={key}
          onClick={(_, index) => onItemClick(row, index)}
          rowIndex={ri}
          {...rest}
        />
      ) : (
        <StyledRow
          key={key}
          onClick={() => onItemClick(row)}
          striped={striped}
          highlight={ri === 0 && highlightFirst}
        >
          {columns.map((col, ci, arr) => (
            <td key={ci}>
              <Box flex>
                <P
                  ml={ci === arr.length - 1 && lastRight ? "auto" : 0}
                  nowrap={col.nowrap}
                >
                  {col.path(row)}
                </P>
              </Box>
            </td>
          ))}
        </StyledRow>
      )
    })}
  </tbody>
)

export default TBody

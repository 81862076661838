import React from "react"
import { StyledThead, Th, Arrows } from "./styled"
import Arrow from "./Arrow"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { stringToBool } from "common/bool"
import * as R from "ramda"

const THead = ({
  config,
  handleSort,
  sorting,
  bold,
  darkHeader,
  lastRight,
}) => {
  return (
    <StyledThead darkHeader={darkHeader}>
      {config.columns.find(x => x.group) && (
        <tr>
          {R.groupWith((a, b) => a.group === b.group, config.columns).map(
            (x, xi) => (
              <td
                key={xi}
                // colspan doesnt work with styled component aparently
                colSpan={x.length}
                style={{ borderBottom: 0, textAlign: "center" }}
              >
                <Box flex>
                  <P bold>{R.path([0, "group"], x)}</P>
                </Box>
              </td>
            ),
          )}
        </tr>
      )}
      <tr>
        {config.columns.map((x, i, arr) => {
          const hasSort = x.sortBy || x.sortKey
          return (
            <Th
              onClick={() => hasSort && handleSort(i, x.sortKey)}
              key={i}
              noBorderTop={!x.group}
              width={x.width}
            >
              <Box flex>
                <P
                  nowrap
                  nobreak
                  mr={hasSort ? 5 : 0}
                  ml={i === arr.length - 1 && lastRight ? "auto" : 0}
                  bold={bold}
                  white={darkHeader}
                >
                  {x.label}
                </P>
                {hasSort && (
                  <Arrows>
                    <Arrow
                      active={
                        i === sorting.index ||
                        (x.sortKey && x.sortKey === sorting.key)
                      }
                      desc={stringToBool(sorting.desc)}
                    />
                  </Arrows>
                )}
              </Box>
            </Th>
          )
        })}
      </tr>
    </StyledThead>
  )
}

export default THead

import React from "react"
import Filepicker from "inputs/Filepicker"
import ButtonModal from "components/ButtonModal"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import AttachFile from "icons/AttachFile"
import NotAvailableModal from "./NotAvailableModal"

const AnswerFileUpload = ({
  readOnly,
  attachments = [],
  setAttachments,
  assessmentId,
  questionId,
  required,
  isPreview,
  attachmentRequests,
}) => {
  if (readOnly && attachments.length === 0) return null
  return (
    <div>
      {attachments.length > 0 && <P mb={5}>{localize("attachedFiles")}</P>}
      <Box>
        {isPreview ? (
          <Box flex acenter>
            <ButtonModal
              text={localize("attachAFile")}
              icon={AttachFile}
              modal={NotAvailableModal}
            />
            {required && <P required ml={10} />}
          </Box>
        ) : (
          <Filepicker
            value={attachments}
            onChange={setAttachments}
            upload={attachmentRequests.uploadAnswerFile(
              assessmentId,
              questionId,
            )}
            download={attachmentRequests.downloadAnswerFile(assessmentId)}
            readOnly={readOnly}
            required={required}
          />
        )}
      </Box>
    </div>
  )
}

export default AnswerFileUpload

import React from "react"
import Button from "components/Button"
import Columns from "components/Columns"
import ModalButtons from "components/ModalButtons"
import Panel from "components/Panel"
import { folderRequest } from "data/cockpit/api"
import withForm from "hoc/withForm"
import Filepicker from "inputs/Filepicker"
import Datepicker from "inputs/Datepicker"
import Select from "inputs/Select"
import Textarea from "inputs/Textarea"
import Textfield from "inputs/Textfield"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { formatInitialsFromName, formatUserName } from "data/user/formatters"
import { arrayRequired } from "validators/array"
import { strRequired } from "validators/string"
import AutocompleteList from "inputs/AutocompleteList"
import { assessmentCoworkersRequest } from "data/assessments/api"
import { folderStatuses } from "data/cockpit/statuses"
import { roles } from "data/cockpit/roles"
import { folderSuburls } from "pages/FolderCreatePage/suburls"

const Settings = ({ folder, urls, readOnly, generalId }) => {
  if (folder && folder.id === generalId) {
    return (
      <Panel>
        <Textfield name="name" label="Folder name" readOnly borderless />
        <ModalButtons>
          <Button to={urls.folders} text="Cancel" secondary />
        </ModalButtons>
      </Panel>
    )
  }

  return (
    <Panel>
      <Textfield
        name="name"
        label="Folder name"
        placeholder={localize("enterName")}
        required
        readOnly={readOnly}
        borderless={readOnly}
      />
      <Columns>
        <Select
          label="Folder status"
          name="status"
          options={R.values(folderStatuses)}
          byValue
          required
          readOnly={readOnly}
          borderless={readOnly}
        />
        <Datepicker
          label="Creation date"
          name="createdAtUtc"
          readOnly
          borderless
        />
      </Columns>
      <Textarea
        required
        name="description"
        label="Folder description"
        placeholder="Enter short description of the folder"
        readOnly={readOnly}
        borderless={readOnly}
      />
      <Filepicker
        name="attachments"
        upload={
          folder
            ? folderRequest.uploadExisting(folder.id)
            : folderRequest.uploadNew
        }
        download={folderRequest.downloadFile(R.path(["id"], folder))}
        readOnly={readOnly}
      />
      <AutocompleteList
        name="coworkers"
        label="Add coworkers"
        request={assessmentCoworkersRequest.list}
        roles={R.values(roles)}
        noResultText={localize("noEmailInTenant")}
        mapOptions={R.map(x => ({
          ...x,
          value: x.id,
          name: x.email,
        }))}
        title="folder coworkers"
        optionToAvatar={R.pipe(formatUserName, formatInitialsFromName)}
        optionToTitle={formatUserName}
        optionToSubtitle={R.prop("email")}
        readOnly={readOnly}
      />
      {!readOnly && (
        <ModalButtons>
          <Button secondary type="button" to={urls.folders} text="Cancel" />
          <Button type="submit" text="Save" />
        </ModalButtons>
      )}
    </Panel>
  )
}

export default R.compose(
  withUrls,
  withForm({
    preventLoseProgress: true,
    mapProps: x => ({
      createdAtUtc: new Date(),
      status: "InProgress",
      ...x.folder,
      coworkers: x.folder
        ? R.pathOr([], ["folder", "coworkers"], x).map(y => ({
            id: y.userId,
            ...y,
          }))
        : [
            // i should be default coworkers
            {
              ...x.profile,
              permission: roles.editor.value,
            },
          ],
    }),
    schema: R.always({
      name: strRequired(100),
      description: strRequired(1000),
      coworkers: arrayRequired,
      // sectionId: stringRequired,
      // roles: arrayRequired,
      // file: objectOptional,
    }),
    onSubmit: p => v => {
      const val = {
        ...v,
        coworkers: v.coworkers.map(x => ({
          ...x,
          userId: x.id,
        })),
      }

      return p.folder
        ? folderRequest.update(p.folder.id, val)
        : folderRequest.create(val)
    },
    redirect: (res, p) =>
      p.folder
        ? p.urls.folderCreate(p.folder.id, folderSuburls.cases)
        : p.urls.folderCreate(res, folderSuburls.cases),
  }),
)(Settings)

import styled, { css } from "styled-components/macro"
// import theme from "theme"

export const StyledThead = styled.thead`
  ${p =>
    p.darkHeader &&
    css`
      background: #333;
    `}
`

export const Th = styled.th`
  font-weight: 500;
  font-size: 14px;
  padding: 11px 20px;
  text-align: left;

  ${p =>
    p.noBorderTop &&
    css`
      border-top: 0 !important;
    `}
`

export const Arrows = styled.div`
  color: #d8d8d8;

  svg {
    width: 16px;
    height: 16px;
  }
`

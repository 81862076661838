import scrollIntoView from "scroll-into-view"

export const scrollToTop = () =>
  document.getElementById("pageContent").scrollTo(0, 0)

export const scrollToId = id =>
  scrollIntoView(document.getElementById(id), {
    time: 0,
    align: {
      top: 0,
    },
  })

export const scrollToEl = (el, offset) => {
  if (!el) return

  // el.scrollIntoView({
  //   block: "start",
  // })
  const top = el.offsetTop

  document.getElementById("pageContent").scrollTo({
    top: top - offset,
    left: 0,
    behavior: "smooth",
  })
}

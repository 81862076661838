import React from "react"
import Button from "components/Button"
import ButtonModal from "components/ButtonModal"
import FixedPanel from "components/FixedPanel"
import ModalButtons from "components/ModalButtons"
import { localize } from "locale"
import { Box, Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import ConfirmApproveModal from "./ConfirmApproveModal"
import ConfirmWithdrawModal from "./ConfirmWithdrawModal"
import ConfirmRejectModal from "./ConfirmRejectModal"
import EditIcon from "icons/EditPencil"
import ApproveIcon from "icons/Approve"
import RejectIcon from "icons/Reject"
import NextIcon from "icons/appkit/outline-right-chevron-circle"
import PrevIcon from "icons/appkit/outline-left-chevron-circle"
import BookmarkedOnly from "components/BookmarkedOnly"
import TickIcon from "icons/appkit/fill-tick"
import { IconWrap } from "styledComponents/Icons"
import { formatDate } from "data/units/date/formatters"

const ApprovePanel = ({
  canApprove,
  isQuestionsTab,
  assessment,
  refetch,
  bookmarkedOnly,
  setBookmarkedOnly,
  currIndex,
  suburlsArr,
  isPreassessment,
  refetchCase,
  case_,
}) => (
  <FixedPanel>
    <Spread>
      <ModalButtons nomargin>
        <Button
          secondary
          icon={PrevIcon}
          text={localize("previous")}
          to={R.pathOr("", [currIndex - 1, "url"], suburlsArr)}
          disabled={
            currIndex === 0 || R.path([currIndex - 1, "disabled"], suburlsArr)
          }
        />
        <Button
          secondary
          icon={NextIcon}
          text="Next"
          to={R.pathOr("", [currIndex + 1, "url"], suburlsArr)}
          disabled={
            currIndex === suburlsArr.length - 1 ||
            R.path([currIndex + 1, "disabled"], suburlsArr)
          }
        />
        {isQuestionsTab && (
          <Box ml={10}>
            <BookmarkedOnly
              value={bookmarkedOnly}
              onChange={setBookmarkedOnly}
            />
          </Box>
        )}
      </ModalButtons>
      <ModalButtons nomargin>
        <ButtonModal
          secondary
          icon={EditIcon}
          text={localize("withdraw")}
          modal={ConfirmWithdrawModal}
          onSuccess={() => {
            refetch()
            refetchCase?.()
          }}
          assessment={assessment}
          isPreassessment={isPreassessment}
          case_={case_}
          caseId={case_?.id}
        />
        {canApprove && !assessment.approvedByMe && (
          <ButtonModal
            danger
            icon={RejectIcon}
            text={localize("reject")}
            modal={ConfirmRejectModal}
            assessment={assessment}
            isPreassessment={isPreassessment}
          />
        )}
        {assessment.approvedByMe && (
          <Box flex mt={12}>
            <IconWrap success>
              <TickIcon />
            </IconWrap>
            <P>Approved on {formatDate(assessment.approvedByMeAtUtc)}</P>
          </Box>
        )}
        {canApprove && !assessment.approvedByMe && (
          <ButtonModal
            success
            icon={ApproveIcon}
            text={localize("approve")}
            modal={ConfirmApproveModal}
            assessment={assessment}
            disabled={currIndex < 2}
            isPreassessment={isPreassessment}
            refetchCase={refetchCase}
          />
        )}
      </ModalButtons>
    </Spread>
  </FixedPanel>
)

export default ApprovePanel

import Settings from "./Settings"
import Preassessment from "./Preassessment"
import Country from "./Country"
import Detail from "./Detail"
import Generation from "./Generation"
import Reports from "./Reports"
import * as R from "ramda"
import BrowserIcon from "icons/Browser"

export const caseSuburls = {
  settings: "settings",
  preassessment: "preassessment",
  country: "country",
  detail: "detail",
}

export const getSuburls = (match, open, case_) => {
  const path = match.path
  const url = match.url

  const isNew = match.params.id === "new"

  const urls = {
    settings: {
      name: "Case settings",
      path: path + "/settings",
      url: url + "/settings",
      component: Settings,
      disableActive: isNew,
    },
    preassessment: {
      name: "Preassessment",
      path: path + "/preassessment",
      url: url + "/preassessment",
      component: Preassessment,
      disabled: !case_?.isPreAssessmentAllowed,
    },
    country: {
      name: "Country selection",
      path: path + "/country",
      url: url + "/country",
      component: Country,
      disabled: isNew || !case_?.canReport,
    },
    detail: {
      name: "Case detail",
      path: path + "/detail",
      url: url + "/detail",
      component: Detail,
      disabled: isNew || !case_?.canReport,
    },
    generation: {
      name: "Report generation",
      path: path + "/generation",
      url: url + "/generation",
      component: Generation,
      disabled: isNew || !case_?.canReport,
    },
    reports: {
      name: "Case reports",
      path: path + "/reports",
      url: url + "/reports",
      component: Reports,
      disabled: isNew || !case_?.canReport,
    },
    browser: {
      name: "Case browser",
      right: true,
      icon: BrowserIcon,
      onClick: open,
      black: true,
      disabled: isNew,
    },
  }

  return isNew ? R.omit(["detail"], urls) : urls
}

import { get, post } from "common/request"
import config from "config"

export const authRequest = {
  testToken: (clientId, userEmail, tenantId, headers) =>
    get(
      `/api/test/token?clientId=${clientId}&userEmail=${userEmail}${
        tenantId ? `&tenantId=${tenantId}` : ""
      }`,
      undefined,
      { headers, urlRoot: config.testApiRoot },
    ),
  token: (clientId, code) =>
    post("/api/auth/token", {
      clientId,
      code,
    }),
  selectTenant: (clientId, tenantId, headers) =>
    post(
      "/api/auth/token/tenant-selection",
      {
        clientId,
        tenantId,
      },
      { headers },
    ),
  logout: (clientId, accessToken, refreshToken) =>
    post("/api/auth/logout", {
      clientId,
      accessToken,
      refreshToken,
    }),
  profile: () => get("/api/user/info"),
  modules: () => get("/api/user/modules"),
  tenants: () => get("/api/user/tenants"),
}

import * as R from "ramda"

const boolToString = v => {
  if (v === true) return "true"
  if (v === false) return "false"

  return v
}

export const filterSafeProps = R.pipe(
  R.pickBy(
    v =>
      R.type(v) === "Number" ||
      R.type(v) === "String" ||
      R.type(v) === "Boolean",
  ),
  R.map(boolToString),
)

import Settings from "./Settings"
import Cases from "./Cases"

export const folderSuburls = {
  settings: "settings",
  cases: "cases",
}

export const getSuburls = (match, folder) => {
  const path = match.path
  const url = match.url

  const urls = {
    settings: {
      name: "Folder settings",
      path: path + "/settings",
      url: url + "/settings",
      component: Settings,
      disabled: folder && !folder.canViewFolderSettings,
    },
    cases: {
      name: "Folder cases",
      path: path + "/cases",
      url: url + "/cases",
      component: Cases,
      disabled: !folder,
    },
  }

  return urls
}

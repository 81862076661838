import { localize } from "locale"

export const ConclusionQuestionId = "conclusion"

export const isConclusionTerm = term =>
  term.questionId === ConclusionQuestionId || term.conclusion

export const getAnswerOptions = (term, questions, statuses) => {
  const isConclusion = isConclusionTerm(term)
  if (isConclusion) {
    return statuses.map(x => ({
      value: x,
      name: x,
    }))
  }

  const q =
    term.questionId && questions.find(q => q.referencedId === term.questionId)

  return q
    ? q.data.options.map(o => ({
        ...o,
        value: o.id,
        name: o.text,
      }))
    : []
}

export const getQuestionOptions = (questions, statuses, entityName) => [
  ...(statuses
    ? [
        {
          value: "conclusion",
          name: localize("conclusion"),
        },
      ]
    : []),
  ...questions.map(q => ({
    ...q,
    value: q.referencedId,
    name: `${entityName} ${q.idx}`,
  })),
]

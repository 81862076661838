import React from "react"
import Button from "components/Button"
import Columns from "components/Columns"
import LabelValue from "components/LabelValue"
import Loader from "components/Loader"
import InfoModal from "components/InfoModal"
import ModalButtons from "components/ModalButtons"
import TemplateEditWarning from "components/TemplateEditWarning"
import Panel from "components/Panel"
import { templateRequest } from "data/templates/api"
import withForm from "hoc/withForm"
import Datepicker from "inputs/Datepicker"
import InputContacts from "inputs/InputContacts"
import SelectLanguage from "inputs/SelectLanguage"
import AutocompleteSelectCountry from "inputs/AutocompleteSelectCountry"
import EditIcon from "icons/appkit/fill-edit"
import Textarea from "inputs/Textarea"
import Textfield from "inputs/Textfield"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { ActiveIcon } from "styledComponents/Icons"
import { Box } from "styledComponents/Containers"
import { arrayOptional } from "validators/array"
import { afterDateOptional } from "validators/date"
import { stringOptional, stringRequired, urlOptional } from "validators/string"
import { canEditAfterFinished } from "data/templates/statuses"
import withState from "hoc/withState"
import withProps from "hoc/withProps"
import useMedia from "hooks/useMedia"
import useOpen from "hooks/useOpen"
import Checkbox from "inputs/Checkbox"
import { combineValidators } from "validators/helpers"

const getWarningText = (changingPreassessment, turningOnPreassessment) => {
  if (changingPreassessment) {
    return turningOnPreassessment
      ? "You are about to change the country specific template to pre-assessment template. This change will delete existing mapping, question tagging and conclusion info. Reporting questions will be changed to evaluation questions."
      : "You are about to change the pre-assessment template to country specific template. This change will delete existing conclusion info."
  }

  return localize("youAreAboutToChangeCountry")
}

const SettingsForm = ({
  canEdit,
  template,
  preview,
  isSubmitting,
  values,
  isNew,
  urls,
  tenant,
  setFieldValue,
  errors,
}) => {
  const { isOpen, open, close } = useOpen()

  const { isMobile } = useMedia()
  const canSwitchToEdit = canEditAfterFinished(template)

  const [editMode, setEditMode] = React.useState(false)
  const changingCountry = template?.countryCode !== values.countryCode
  const changingPreassessment =
    template?.isPreAssessment !== values.isPreAssessment
  const turningOnPreassessment = changingPreassessment && values.isPreAssessment
  const showWarning = !isNew && (changingCountry || changingPreassessment)

  return (
    <Panel noPanel={preview}>
      <Columns widths={["auto"].concat(canSwitchToEdit ? [80] : [])}>
        <Textfield
          name="name"
          label={localize("nameOfTemplate")}
          placeholder={localize("enterName")}
          required={canEdit}
          readOnly={!canEdit}
          borderless={!canEdit}
        />
        {canSwitchToEdit && !editMode && (
          <Box flex>
            <ActiveIcon onClick={() => setEditMode(true)}>
              <EditIcon />
            </ActiveIcon>
            <TemplateEditWarning />
          </Box>
        )}
      </Columns>
      {/* {!isNew && !preview && (
        <Textfield
          name="tenantName"
          label={localize("pwcSite")}
          placeholder={localize("pwcSite")}
          readOnly
          borderless
        />
      )} */}
      <Textarea
        name="description"
        label={localize("description")}
        placeholder="Enter short description of the template"
        readOnly={!canEdit && !editMode}
        borderless={!canEdit && !editMode}
      />
      <Textfield
        name="descriptionUrl"
        label={localize("addLinkForDescription")}
        placeholder={localize("addUrl")}
        readOnly={!canEdit && !editMode}
        borderless={!canEdit && !editMode}
      />
      {R.path(["schemas", "length"], template) > 0 && (
        <LabelValue
          indent
          mb={40}
          label={localize("version")}
          value={template.schemas
            .map(x => `${x.reportType} v${x.version}`)
            .join(", ")}
        />
      )}
      <Columns
        noCols={isMobile}
        widths={preview ? ["auto", "auto"] : [200, "auto", "auto"]}
      >
        {preview && !template.isPreAssessment ? null : (
          <Checkbox
            name="isPreAssessment"
            label="Pre-assessment template"
            readOnly={!canEdit}
            noEmptyError
            onChange={v =>
              setFieldValue("countryCode", v ? null : tenant.countryCode)
            }
          />
        )}
        {preview && template.isPreAssessment ? null : (
          <AutocompleteSelectCountry
            name="countryCode"
            byValue
            placeholder={localize("selectCountry")}
            label={localize("country")}
            readOnly={!canEdit || values.isPreAssessment}
            gray={values.isPreAssessment}
            borderless={!canEdit}
            templateOnlyCountries
            required={canEdit && !values.isPreAssessment}
          />
        )}
        <SelectLanguage
          name="languageCode"
          byValue
          placeholder={localize("selectLanguage")}
          label={localize("language")}
          readOnly={!canEdit && !editMode}
          borderless={!canEdit && !editMode}
        />
      </Columns>
      <Columns noCols={isMobile}>
        <Datepicker
          name="applicableTimePeriodStart"
          label={localize("applicableTimePeriodStart")}
          placeholder={canEdit ? localize("selectDate") : ""}
          readOnly={!canEdit}
          borderless={!canEdit}
        />
        <Datepicker
          name="applicableTimePeriodEnd"
          label={localize("applicableTimePeriodEnd")}
          placeholder={canEdit ? localize("selectDate") : ""}
          readOnly={!canEdit && !editMode}
          borderless={!canEdit && !editMode}
          noPast={editMode}
        />
      </Columns>
      <InputContacts
        name="contacts"
        label={localize("addContactPerson")}
        readOnly={!canEdit && !editMode}
      />
      {(canEdit || editMode) && (
        <ModalButtons>
          <Button
            secondary
            type="button"
            to={editMode ? undefined : urls.templates.local}
            onClick={() => setEditMode(false)}
            text="Cancel"
          />
          <Button
            onClick={() => {
              if (R.values(errors).filter(x => x).length === 0 && showWarning) {
                return open()
              }
            }}
            type={showWarning ? "button" : "submit"}
            text="Save"
          />
        </ModalButtons>
      )}
      {isOpen && (
        <InfoModal
          title={localize("warning")}
          text={getWarningText(changingPreassessment, turningOnPreassessment)}
          close={close}
          body={
            <>
              {isSubmitting && <Loader center />}
              <ModalButtons>
                <Button secondary type="button" onClick={close} text="Cancel" />
                <Button type="submit" text="Save" form="settings-form" />
              </ModalButtons>
            </>
          }
        />
      )}
    </Panel>
  )
}

export default R.compose(
  withUrls,
  withState("pendingReq", "setPendingReq", null),
  withProps(p => ({ isNew: p.templateId === "new" })),
  withForm({
    formId: "settings-form",
    preventLoseProgress: true,
    mapProps: p => ({
      languageCode: "en",
      countryCode: p.template?.isPreAssessment
        ? undefined
        : R.path(["tenant", "countryCode"], p),
      ...p.template,
    }),
    schema: (_, values) => ({
      name: stringRequired,
      description: stringOptional,
      descriptionUrl: urlOptional,
      countryCode: combineValidators(stringOptional, v => {
        if (values.isPreAssessment) return ""

        return stringRequired(v)
      }),
      languageCode: stringOptional,
      applicableTimePeriodStart: stringOptional,
      applicableTimePeriodEnd: afterDateOptional(
        values.applicableTimePeriodStart,
        localize("mustBeAfterApplicablePeriodStart"),
      ),
      contacts: arrayOptional,
    }),
    onSubmit: p => v => {
      const data = {
        name: v.name,
        description: v.description,
        descriptionUrl: v.descriptionUrl,
        isPreAssessment: v.isPreAssessment,
        countryCode: v.countryCode,
        languageCode: v.languageCode,
        applicableTimePeriodStart: v.applicableTimePeriodStart,
        applicableTimePeriodEnd: v.applicableTimePeriodEnd,
        contacts: v.contacts,
      }

      return p.isNew
        ? templateRequest.create(data)
        : templateRequest.edit(p.templateId, data)
    },
    onSuccess: p => () => {
      p.refetch()
    },
    redirect: (res, p) => p.suburls.coworkers.path.replace(":id", res.id),
  }),
)(SettingsForm)

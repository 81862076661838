import React from "react"
import Tabs from "components/Tabs"
import PageTitle from "components/PageTitle"
import { caseRequest } from "data/cockpit/api"
import { Box } from "styledComponents/Containers"
import * as R from "ramda"
import { caseStatuses } from "data/cockpit/statuses"
import FavoriteButton from "components/FavoriteButton"
import CaseIcon from "icons/Case"
import { IconWrap } from "styledComponents/Icons"
import Breadcrumbs from "components/Breadcrumbs"
import Browser from "./Browser"
import useOpen from "hooks/useOpen"

const CaseTitle = ({
  case_,
  assessment,
  urls,
  location,
  history,
  refetchCase,
  folder,
  folderId,

  children,
  isBrowserOpen,
  closeBrowser,
  suburls,
  collapsed,
  objectId,
}) => {
  const caseStatus =
    case_ && R.values(caseStatuses).find(x => x.value === case_.status)

  const { isOpen, toggle } = useOpen()

  return (
    <>
      <Breadcrumbs
        breadcrumbs={
          folder
            ? { name: folder.name, id: folderId }
            : case_?.breadcrumbs || assessment.breadcrumbs
        }
        noCase
        urls={urls}
        toggleProps={
          collapsed && assessment
            ? {
                openText: "Open case details",
                hideText: "Hide case details",
                isOpen: isOpen,
                toggle: toggle,
                disabled: !case_,
              }
            : undefined
        }
      />
      {(isOpen || !collapsed) && (
        <>
          <PageTitle
            mb={0}
            title={
              <Box flex acenter>
                {case_ && (
                  <FavoriteButton
                    isFavorite={case_.isFavorite}
                    request={caseRequest.setFavorite(case_.id)}
                    large
                    onSuccess={refetchCase}
                  />
                )}
                <IconWrap large black static ml={5} mr={5}>
                  <CaseIcon />
                </IconWrap>
                <Box>{case_ ? case_.name : "Create new case"}</Box>
              </Box>
            }
            status={
              case_ && {
                color: caseStatus.color,
                text: caseStatus.name,
              }
            }
          >
            {children}
          </PageTitle>
          <Tabs
            options={R.values(suburls)
              .filter(x => x.name)
              .map(x => ({
                ...x,
                value: x.url,
              }))}
            value={location.pathname}
            onChange={v => history.push(v)}
            prefixActive
            underline
            uppercase
            bold
          />
          {isBrowserOpen && (
            <Browser
              close={closeBrowser}
              caseId={case_.breadcrumbs.case.id}
              case_={case_}
              objectId={objectId}
              caseSuburls={suburls}
            />
          )}
        </>
      )}
    </>
  )
}

export default CaseTitle

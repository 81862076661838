import React from "react"
import Table from "components/Table"
import { config } from "./config"
import withData from "hoc/withData"
import { taskRequest } from "data/tasks/api"
import withFilters from "hoc/withFilters"
import * as R from "ramda"
import withUrls from "hoc/withUrls"

const ReportsTable = ({ data, loading, setFilters, urls }) => (
  <Table
    data={R.pathOr([], ["massReports"], data)}
    loading={loading}
    count={R.path(["count"], data)}
    config={config(urls)}
    onParamChange={setFilters}
    sorting={{
      index: 3,
      desc: true,
    }}
  />
)

const getSearchParams = filters =>
  [
    `orderBy=${R.pathOr("CreatedAtUtc", ["sort", "key"], filters)}`,
    `orderDescending=${R.pathOr(true, ["sort", "desc"], filters)}`,
    `page=${R.pathOr(0, ["page"], filters)}`,
    `size=${R.pathOr(10, ["size"], filters)}`,
    filters && filters.search ? `search=${filters.search}` : "",
    filters && filters.filters.overdue ? `onlyOverdue=true` : "",
  ]
    .filter(x => x !== "")
    .join("&")

export default R.compose(
  withUrls,
  withFilters(["search"]),
  withData(p => taskRequest.reports(getSearchParams(p.filters)), {
    refetchParams: p => [getSearchParams(p.filters)],
    noLoader: true,
    clearOnRefetch: true,
  }),
)(ReportsTable)

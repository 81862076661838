import styled, { css } from "styled-components/macro"
import theme from "theme"
import boxModel from "../bases/boxModel"

const fontColor = css`
  ${p =>
    p.red &&
    css`
      color: ${theme.color.red};
    `};
  ${p =>
    p.error &&
    css`
      color: ${theme.color.error};
    `};
  ${p =>
    p.white &&
    css`
      color: #fff;
    `};
  ${p =>
    p.gray &&
    css`
      color: #666;
    `};
  ${p =>
    p.lightgray &&
    css`
      color: #999;
    `};
  ${p =>
    p.darkgray &&
    css`
      color: #333;
    `};
  ${p =>
    p.inherit &&
    css`
      color: inherit;
    `};
`

const style = css`
  ${p =>
    p.uppercase &&
    css`
      text-transform: uppercase;
    `};
  ${p =>
    p.underline &&
    css`
      text-decoration: underline;
    `};
  ${p =>
    p.link &&
    css`
      text-decoration: underline;
      cursor: pointer;
    `};
  ${p =>
    p.pointer &&
    css`
      cursor: pointer;
    `};
  ${p =>
    p.hovernolink &&
    css`
      &:hover {
        text-decoration: none;
      }
    `};
  ${p =>
    p.bolder &&
    css`
      font-weight: 500;
    `};
  ${p =>
    p.bold &&
    css`
      font-weight: 600;
    `};
  ${p =>
    p.italic &&
    css`
      font-style: italic;
    `};
  ${p =>
    p.center &&
    css`
      text-align: center;
    `};
  ${p =>
    p.talign &&
    css`
      text-align: ${p.talign};
    `};
`

export const wrapping = css`
  ${p =>
    p.ellipsis &&
    css`
      text-overflow: ellipsis;
      overflow: hidden;
    `};
  ${p =>
    p.nobreak &&
    css`
      word-break: normal;
    `};
  ${p =>
    p.nowrap &&
    css`
      white-space: nowrap;
    `};
`

export const P = styled.div`
  color: #333;
  margin: 0;
  font-weight: 400;
  min-height: 1.15em;
  word-break: break-word;
  font-size: ${p => p.fs ?? 16}px;

  ${fontColor};
  ${style};
  ${boxModel};
  ${wrapping};

  ${p =>
    p.required &&
    css`
      position: relative;

      &::after {
        position: absolute;
        content: "*";
        color: ${theme.color.error};
        margin-left: 3px;
      }
    `};
`

export const Hr = styled.div`
  height: 1px;
  background: #d8d8d8;
  margin: 20px 0;
`

import { specialBehavior } from "data/templates/answers"

export const handleUnique = (v, target, options) => {
  const isUniq = x => x.specialBehavior === specialBehavior.noneOfTheAbove
  const uniqOption = options.find(isUniq)

  // there is no uniq option, just return new value
  if (!uniqOption) return v

  const uniqInValue = !!v.find(x => x === uniqOption.id)
  const targetIsUniq = target === uniqOption.id

  // uniq option is in new value, which means the new result
  // will either be the other non-unique option you just clicked
  // or the uniq
  if (uniqInValue) {
    return [target]
  }
  if (!uniqInValue && targetIsUniq) {
    return []
  }

  return targetIsUniq ? [target] : v
}

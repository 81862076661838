import React from "react"
import { ToastsWrap } from "./styled"
import ToastItem from "./ToastItem"
import * as R from "ramda"
import { notifications$ } from "data/toasts/rx"

const Toasts = () => {
  const [toasts, setToasts] = React.useState([])
  React.useEffect(() => {
    const sub = notifications$.subscribe(v => {
      setToasts(R.append(v))
    })
    return () => sub.unsubscribe()
  }, [])

  return (
    <ToastsWrap>
      {toasts.map(t => (
        <ToastItem
          key={t.id}
          close={() => setToasts(R.filter(y => t.id !== y.id))}
          {...t}
        />
      ))}
    </ToastsWrap>
  )
}

export default Toasts

import * as R from "ramda"

export const showCopySection = ({ copyIndex, sectionIndex, subsectionIndex }) =>
  copyIndex &&
  copyIndex.type === "section" &&
  copyIndex.sectionIndex !== sectionIndex &&
  !copyIndex.hasSubsection &&
  R.isNil(subsectionIndex)

export const showCopySubsection = ({ copyIndex, subsectionIndex }) =>
  copyIndex && copyIndex.type === "subsection" && R.isNil(subsectionIndex)

export const showCopyQuestion = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "question"

export const showMoveQuestion = ({
  moveIndex,
  sectionIndex,
  subsectionIndex,
  nodeIndex,
  subsectionNodeIndex,
}) =>
  moveIndex &&
  moveIndex.type === "question" &&
  (moveIndex.sectionIndex !== sectionIndex ||
    moveIndex.subsectionIndex !== subsectionIndex ||
    (moveIndex.nodeIndex !== nodeIndex &&
      moveIndex.nodeIndex !== nodeIndex + 1) ||
    (moveIndex.subsectionNodeIndex !== undefined &&
      moveIndex.subsectionNodeIndex !== subsectionNodeIndex &&
      moveIndex.subsectionNodeIndex !== subsectionNodeIndex + 1))

export const showMoveSubsection = ({
  moveIndex,
  sectionIndex,
  subsectionIndex,
  nodeIndex,
}) =>
  moveIndex &&
  moveIndex.type === "subsection" &&
  subsectionIndex === undefined &&
  (moveIndex.sectionIndex !== sectionIndex ||
    (moveIndex.nodeIndex !== nodeIndex &&
      moveIndex.nodeIndex !== nodeIndex + 1))

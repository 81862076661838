import * as R from "ramda"

export const nilEmpty = v => R.isNil(v) || R.isEmpty(v)

export const mapObjKeys = fn => obj =>
  R.keys(obj).reduce(
    (acc, k) => ({
      ...acc,
      [fn(k)]: obj[k],
    }),
    {},
  )

export const eq = (a, b) => {
  if (R.isNil(a) && R.isNil(b)) return true
  if (R.isNil(a) || R.isNil(b)) return false

  const keysA = R.keys(a)
  const keysB = R.keys(b)

  const allKeys = R.uniq(keysA.concat(keysB))

  const getV = v => {
    if (nilEmpty(v)) return null

    return v
  }

  return allKeys.every(k => getV(a[k]) === getV(b[k]))
}

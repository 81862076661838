import { localize } from "locale"

export const reportOptions = {
  report: {
    value: "report",
    name: localize("proceedWithReportGeneration"),
  },
  doNotReport: {
    value: "doNotReport",
    name: localize("proceedWithoutReportGeneration"),
  },
}

import { noopHandler } from "common/functions"
import { toastError, toastSuccess } from "data/toasts/rx"
import { useHistory } from "react-router"
import { getData } from "common/rxjs"
import React from "react"
import Loader from "components/Loader"

const withSubmit =
  ({
    onSubmit,
    onSuccess = noopHandler,
    onError = noopHandler,
    successText,
    errorText,
    redirect,
    loadingText,
  }) =>
  Component =>
  p => {
    const [obs, setObs] = React.useState()
    const [submitLoading, setSubmitLoading] = React.useState(false)
    const history = useHistory()

    const submit = v => {
      setSubmitLoading(true)
      const observable = onSubmit(p)(v).subscribe({
        error: error => {
          const message = error.text
          const t = errorText ? errorText(p)(message, error) : message
          if (t) {
            toastError(t)
          }
          setSubmitLoading(false)
          onError(p)(message, error)
        },
        next: result => {
          const data = getData(result)
          setSubmitLoading(false)
          onSuccess(p)(data, v)
          if (successText) {
            const t = successText(p)(data, v, result)
            if (t) {
              toastSuccess(t)
            }
          }
          if (redirect) {
            const redirUrl = redirect(data, p)
            if (redirUrl) {
              history.push(redirUrl)
            }
          }
        },
      })

      setObs(() => observable)
    }

    React.useEffect(() => {
      return () => obs?.unsubcribe()
    }, []) // eslint-disable-line

    return (
      <>
        <Component {...p} submit={submit} />
        {submitLoading && <Loader center loadingText={loadingText} />}
      </>
    )
  }

export default withSubmit

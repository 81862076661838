import React from "react"
import InputAnswers from "inputs/InputAnswers"
import { isSingleMultipleOrConfirmation } from "data/templates/answers"

const Answers = ({ readOnly, questionType }) => {
  if (!isSingleMultipleOrConfirmation(questionType)) return null

  return (
    <InputAnswers
      name="options"
      type={questionType}
      readOnly={readOnly}
      allowTextChangeInReadOnly
      borderless={readOnly}
      withAttachments
      noEmptyError
      mb={0}
    />
  )
}

export default Answers

import React from "react"
import Columns from "components/Columns"
import withFormField from "hoc/withFormField"
import CurrencyField from "./CurrencyField"
import useMedia from "hooks/useMedia"

const ValueOfArrangement = ({
  value,
  onChange,
  readOnly,
  assessment,
  transparent,
}) => {
  const { isMobile } = useMedia()

  const cc = assessment.localCurrencyCode

  return (
    <Columns noCols={isMobile}>
      {cc && cc !== "EUR" && (
        <CurrencyField
          value={value}
          onChange={onChange}
          readOnly={readOnly}
          type="local"
          code={assessment.localCurrencyCode}
          transparent={transparent}
        />
      )}
      <CurrencyField
        value={value}
        onChange={onChange}
        readOnly={readOnly}
        code={assessment.otherCurrencyCode || "EUR"}
        transparent={transparent}
        type="eur"
      />
    </Columns>
  )
}

export default withFormField(ValueOfArrangement)

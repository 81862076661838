import React from "react"
import QuestionEdit from "./QuestionEdit"
import QuestionView from "./QuestionView"
import { scrollToId } from "common/scroll"

const QuestionBody = ({ question, templateId, template, questions, ops }) => {
  const isEdited = ops.editIndex && ops.editIndex.nodeId === question.id

  React.useEffect(() => {
    if (isEdited) {
      scrollToId(`q-${question.referencedId}`)
    }
  }, [isEdited]) // eslint-disable-line

  const Component = isEdited ? QuestionEdit : QuestionView

  return (
    <Component
      question={question}
      templateId={templateId}
      template={template}
      questions={questions}
      ops={ops}
      isEdited={isEdited}
    />
  )
}

export default QuestionBody

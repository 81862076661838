import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { conclusionRequest } from "data/conclusions/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import { stringRequired } from "validators/string"
import * as R from "ramda"
import { preconclusionRequest } from "data/preassessments/api"
import { caseRequest } from "data/cockpit/api"
import withData from "hoc/withData"
import { getCaseUrl } from "data/cockpit/redirect"

const DeleteModal = ({ close, isPreassessment, data, conclusion }) => (
  <>
    <P mb={20}>
      The {isPreassessment ? "pre-assessment" : "conclusion"} will be deleted.
      You may permanently delete it or restore it from recycle bin. Notification
      will be sent to all users, who have access to this{" "}
      {isPreassessment ? "pre-assessment" : "conclusion"}.
      {isPreassessment
        ? ""
        : `\nIf the conclusion is included in a mass report, and is deleted into the bin the conclusion will be displayed in the mass report as "deleted", which will cause inconsistency between mass report detail and the XML file.`}
    </P>
    {isPreassessment && data && conclusion.status === "Potentially reportable" && (
      <P mb={20} bold>
        When a Pre-assessment conclusion is deleted, it will only be possible to
        access the following sections including CASE DETAILS and any linked
        Assessments or Conclusions when you disable the switch "Pre-Assessment"
        in the CASE SETTINGS section.
      </P>
    )}
    <Textarea
      required
      name="reason"
      label="Reasons and comments:"
      placeholder={localize("writeTheReason")}
    />
    <ModalButtons
      buttonText={`Do you want to delete the ${
        isPreassessment ? "pre-assessment" : "conclusion"
      }?`}
    >
      <Button secondary type="button" onClick={close} text="No" />
      <Button danger type="submit" text={"Yes, delete"} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(p => `Delete ${p.conclusion.name}`),
  withUrls,
  withForm({
    schema: R.always({
      reason: stringRequired,
    }),
    onSubmit: p => v =>
      (p.isPreassessment ? preconclusionRequest : conclusionRequest).delete(
        p.conclusion.id,
        v,
      ),
    onSuccess: p => () => {
      p.close()
      p.onSuccess()
    },
    redirect: (_, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return getCaseUrl(p.match, p.assessment)
      }
      return p.urls.conclusions
    },
    successText: p => () =>
      `You have deleted the ${
        p.isPreassessment ? "pre-assessment" : "conclusion"
      } ${p.conclusion.name}.`,
  }),
  withData(p => caseRequest.hasLinkedObjects(p.caseId), {
    skip: p => !p.isPreassessment,
  }),
)(DeleteModal)

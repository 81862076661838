export const formatUserName = u => `${u.firstName} ${u.lastName}`

export const formatInitials = u => `${u.firstName[0]}${u.lastName[0]}`

export const formatInitialsFromName = name =>
  name
    .split(" ")
    .map(x => x[0])
    .join("")
    .toUpperCase()

import { createGlobalStyle } from "styled-components/macro"

export default createGlobalStyle`
  body {
    font-family: "Roboto", sans-serif;
  }
  *, *::before, *::after {
    box-sizing: border-box;
  }
  ul {
    padding: 0;
    list-style: none;
  }
  #loader {
    z-index: 999999999;
    position: relative;
  }
`

import withData from "hoc/withData"
import { languageListRequest } from "data/countries/api"
import * as R from "ramda"

const Language = ({ code, data = [] }) =>
  R.pipe(
    R.find(x => x.code === code),
    R.propOr(null, "englishName"),
  )(data)

export default withData(() => languageListRequest(), {
  cacheKey: () => "languages",
  noLoader: true,
})(Language)

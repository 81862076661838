import React from "react"
import CountTitle from "components/CountTitle"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import NotificationItem from "./NotificationItem"
import withSubmit from "hoc/withSubmit"
import { notificationsRequest } from "data/notifications/api"
import * as R from "ramda"
import EmptyLoader from "components/EmptyLoader"
import Empty from "./Empty"
import Loader from "components/Loader"

const pageSize = 25

const List = ({
  data = [],
  submit,
  clearNotification,
  markAsRead,
  notificationsLoading,
}) => {
  const isEmpty = data.length === 0
  const [page, setPage] = React.useState(1)
  const shownItems = data.slice(0, pageSize * page)
  const hasMore = shownItems.length !== data.length

  return (
    <>
      <Box pl={15} pr={15}>
        <CountTitle
          title={localize("notifications")}
          onClear={isEmpty ? undefined : submit}
        />
      </Box>
      <EmptyLoader
        isEmpty={isEmpty}
        isLoading={notificationsLoading}
        empty={() => <Empty />}
        loader={() => (
          <Box p={20}>
            <Loader />
          </Box>
        )}
      >
        {() =>
          shownItems.map((x, i) => (
            <NotificationItem
              key={i}
              notification={x}
              index={i}
              clearNotification={clearNotification}
              markAsRead={markAsRead}
            />
          ))
        }
      </EmptyLoader>
      {hasMore && (
        <P
          link
          hovernolink
          red
          pt={10}
          pb={10}
          center
          onClick={() => setPage(R.inc)}
        >
          {localize("showMore")}
        </P>
      )}
    </>
  )
}

export default R.compose(
  withSubmit({
    onSubmit: () => notificationsRequest.markAllAsRead,
    onSuccess: p => () => {
      p.clearAll()
      p.refetch()
    },
  }),
)(List)

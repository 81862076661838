import React from "react"
import { formatCurrency, limitToDecimal } from "data/units/number/formatters"
import Textfield from "inputs/Textfield"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import useOpen from "hooks/useOpen"

const CurrencyField = ({
  code,
  value,
  onChange,
  readOnly,
  type,
  transparent,
}) => {
  const { isOpen, open, close } = useOpen()

  const vLen = R.view(R.lensPath([type]))
  const [v, setV] = React.useState(vLen(value))
  const [lastDispatchedValue, setLastDispatchedValue] = React.useState(
    vLen(value),
  )

  // eslint-disable-next-line
  React.useEffect(() => {
    if (vLen(value) !== lastDispatchedValue) {
      setV(vLen(value))
    }
  })

  return (
    <Box flex acenter>
      <Box grow>
        <Textfield
          onFocus={open}
          value={isOpen ? v : formatCurrency(v)}
          onBlur={close}
          type="text"
          onChange={v => {
            const newV = limitToDecimal(v)
            const dispatchV = newV === "" ? null : +newV
            setV(newV)
            setLastDispatchedValue(dispatchV)
            onChange({
              ...value,
              [type]: dispatchV,
            })
          }}
          readOnly={readOnly}
          transparent={transparent}
        />
      </Box>
      <P ml={10} nobreak>
        {code}
      </P>
    </Box>
  )
}

export default CurrencyField

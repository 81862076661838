import InfoModal from "components/InfoModal"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import useOpen from "hooks/useOpen"
import * as R from "ramda"

const withWarningConfirmModal = getConfig => Component => props => {
  const {
    onConfirm,
    title,
    text,
    buttonText,
    confirmText,
    cancelText,
    doConfirm = R.always(true),
    dangerConfirm,
  } = getConfig(props)

  const { isOpen, open, close } = useOpen()

  if (isOpen) {
    return (
      <InfoModal
        title={title}
        text={text}
        body={
          <ModalButtons buttonText={buttonText}>
            <Button secondary text={cancelText} onClick={close} />
            <Button
              danger={dangerConfirm}
              text={confirmText}
              onClick={() => {
                onConfirm()
                close()
              }}
            />
          </ModalButtons>
        }
        close={close}
      />
    )
  }

  return <Component {...props} goToConfirm={doConfirm ? open : onConfirm} />
}

export default withWarningConfirmModal

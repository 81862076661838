import React from "react"
import { WidthContainer } from "styledComponents/Containers"
import { FixedPanelWrap, ScrollSpacer } from "./styled"
import Portal from "components/Portal"

const FixedPanel = ({ children }) => (
  <>
    <FixedPanelWrap>
      <WidthContainer>{children}</WidthContainer>
    </FixedPanelWrap>
    <Portal node={document.getElementById("footerSpacer")}>
      <ScrollSpacer />
    </Portal>
  </>
)

export default FixedPanel

import React from "react"
import * as R from "ramda"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { isValid } from "./helpers"

const MappingValidators = ({
  validators,
  value,
  questionType,
  questionEnabled,
}) => {
  if (!validators || validators.length === 0) return null

  const groups = R.pipe(
    R.groupBy(R.prop("reportType")),
    R.map(R.groupBy(R.prop("name"))),
  )(validators)

  return (
    <>
      {R.keys(groups).map((reportType, i) => {
        return (
          <Box key={i} mt={10}>
            <P bold>{reportType}</P>
            {R.keys(groups[reportType]).map((validatorName, j) => {
              const validatorsForType = groups[reportType][validatorName]
              const validated = validatorsForType.map(x =>
                isValid(x, value, questionType),
              )
              const hasError = questionEnabled && !validated.some(x => x)

              return (
                <Box key={j} flex mt={5}>
                  {validatorsForType.map((x, xi) => (
                    <React.Fragment key={xi}>
                      {xi > 0 && (
                        <P ml={5} mr={5} gray>
                          or
                        </P>
                      )}
                      <P error={hasError}>
                        {x.name} {x.parameters}
                      </P>
                    </React.Fragment>
                  ))}
                </Box>
              )
            })}
          </Box>
        )
      })}
    </>
  )
}

export default MappingValidators

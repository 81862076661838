import { get, put, post, del } from "common/request"

export const conclusionRequest = {
  listTypes: () => get(`/api/conclusion/results?lang=en`),
  list: params => get(`/api/conclusions?${params}`),
  count: () => get(`/api/conclusions/counts`),
  detail: id => get(`/api/conclusion/${id}`),
  bulkExport: v => post(`/api/fileExport/conclusions`, v),
  listExports: () => get(`/api/fileExports`),
  exportDownloadUrl: id => get(`/api/fileExport/${id}/doc`),
  reject: (id, v) => put(`/api/conclusion/${id}/reject`, v),
  regenerate: id => get(`/api/conclusion/${id}/regenerate`),
  withdraw: assessmentId => data =>
    put(`/api/conclusion/${assessmentId}/withdraw`, data),
  review: assessmentId => put(`/api/conclusion/${assessmentId}/review`),
  delete: (id, data) => put(`/api/conclusions/${id}/delete`, data),
  deletePermanent: data => post("/api/conclusions/deletePermanent", data),
  deleteAllPermanent: () => del("/api/conclusions/deleteAllPermanent"),
  restore: data => put("/api/conclusions/restore", data),
  restoreAll: () => post("/api/conclusions/restoreAll"),
}

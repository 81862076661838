import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { reportRequest } from "data/report/api"
import withForm from "hoc/withForm"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import withModal from "hoc/withModal"
import Select from "inputs/Select"
import { localize } from "locale"
import { stringRequired } from "validators/string"
import * as R from "ramda"
import {
  getStatusName,
  getReportTransitionOptions,
  isValidTransition,
} from "data/assessments/statuses"
import withWarningConfirmModal from "hoc/withWarningConfirmModal"
import { combineValidators } from "validators/helpers"

const ChangeStatusesModal = ({
  close,
  conclusions,
  values,
  setValues,
  goToConfirm,
}) => {
  const allConclusionOptions = R.pipe(
    R.chain(x => getReportTransitionOptions(x.conclusionStatus)),
    R.uniqBy(R.prop("value")),
  )(conclusions)

  const allConclusionValuesAreSame =
    R.pipe(R.values, R.uniq, R.length)(values) === 1

  return (
    <>
      <Box>
        <P>{localize("allConclusions")}</P>
        <Select
          placeholder={localize("selectStatus")}
          options={allConclusionOptions}
          byValue
          onChange={v => setValues(R.map(R.always(v), values))}
          value={
            allConclusionValuesAreSame
              ? R.pipe(R.values, R.head)(values)
              : undefined
          }
        />
      </Box>
      {conclusions.map((x, xi) => (
        <Box key={xi} mt={20}>
          <P>{x.assessmentName}</P>
          <Select
            name={x.assessmentId}
            options={getReportTransitionOptions(
              x.conclusionStatus,
              values[x.assessmentId],
            )}
            placeholder={localize("selectStatus")}
            byValue
          />
        </Box>
      ))}
      <ModalButtons>
        <Button secondary type="button" onClick={close} text="Cancel" />
        <Button onClick={goToConfirm} text="Save" />
      </ModalButtons>
    </>
  )
}

export default R.compose(
  withModal(R.always(localize("changeStatuses"))),
  withForm({
    dontWrapInForm: true,
    mapProps: p =>
      R.pipe(
        R.indexBy(R.prop("assessmentId")),
        R.map(R.prop("conclusionStatus")),
      )(p.conclusions),
    schema: p =>
      R.pipe(
        R.indexBy(R.prop("assessmentId")),
        R.map(x =>
          combineValidators(stringRequired, v =>
            isValidTransition(x.conclusionStatus, v)
              ? ""
              : `${localize("cannotBeSetTo")} ${getStatusName(v.value)}`,
          ),
        ),
      )(p.conclusions),
    onSubmit: p => value => {
      const val = R.pipe(
        R.mapObjIndexed((v, k) => ({ conclusionId: +k, status: v })),
        R.values,
      )(value)
      return reportRequest.setStatuses(p.reportId)(val)
    },
    onSuccess: p => () => {
      p.close()
      p.refetch()
    },
  }),
  withWarningConfirmModal(p => ({
    onConfirm: p.submitForm,
    title: "Conclusion status change",
    text: "Warning: if the conclusion status is changed manually, direct reporting functionality (Send report to Tax authority & Check updates from Tax authority) CANNOT be used.",
    buttonText: "Do you want to manually change conclusion status?",
    confirmText: "Yes",
    cancelText: "No",
    doConfirm:
      R.values(p.errors).every(x => !x) &&
      (p.report.canUpdateReportFromTa || p.report.canSubmitReportToTa),
    dangerConfirm: true,
  })),
)(ChangeStatusesModal)

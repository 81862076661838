import * as R from "ramda"

export const getDefaultSorting = (sorting, config) => {
  if (sorting) {
    return sorting
  }
  const i = config.columns.findIndex(x => x.sortBy)
  return {
    index: i === -1 ? undefined : i,
    desc: false,
  }
}

export const getData = p => {
  const filters = p.config.filters || []

  const filteredData = p.data.filter(dataItem =>
    filters.every(filterConfig => {
      const valueForCurrentFilter = p.filters[filterConfig.name]
      return valueForCurrentFilter !== undefined
        ? filterConfig.filter(valueForCurrentFilter, dataItem)
        : true
    }),
  )

  const search = p.config.search || R.always(true)
  const filteredSearchedData = filteredData.filter(x =>
    search(p.search || "", x),
  )

  const i = p.sort.index
  const descendify = p.sort.desc ? R.reverse : R.identity

  const filter = R.path(["config", "columns", i, "sortBy"], p)
  const filteredSearchedSortedData =
    i !== undefined && filter
      ? descendify(R.sortBy(R.compose(R.toLower, filter), filteredSearchedData))
      : filteredSearchedData

  const pageSize = p.size
  // if count is specified, this means serverside pagination is in place
  const paginatedData = R.isNil(p.count)
    ? filteredSearchedSortedData.slice(
        p.page * pageSize,
        (p.page + 1) * pageSize,
      )
    : filteredSearchedSortedData

  return { filteredSearchedSortedData, paginatedData }
}

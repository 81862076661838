import React from "react"

export default ({ active }) => (
  <svg width="14px" height="14px" viewBox="0 0 14 14">
    <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
      <g
        transform="translate(-166.000000, -392.000000)"
        fillRule="nonzero"
        stroke={active ? "#fff" : "#979797"}
      >
        <g transform="translate(161.000000, 340.000000)">
          <g transform="translate(0.000000, 47.000000)">
            <path
              d="M12.0000234,15.7483347 L9.12595517,17.7497977 C8.3818833,18.2679595 7.97390349,17.9548546 8.21353588,17.0548558 L9.14117003,13.5708959 L6.43726401,11.3239081 C5.7372457,10.7421821 5.89522852,10.2386675 6.78577717,10.1994684 L10.2331549,10.0477258 L11.4361173,6.65754795 C11.747554,5.77985969 12.2531726,5.78177553 12.5639296,6.65754795 L13.766892,10.0477258 L17.2142697,10.1994684 C18.1067665,10.2387533 18.2612731,10.7434519 17.5627828,11.3239081 L14.8588768,13.5708959 L15.786511,17.0548558 C16.0266676,17.9568234 15.6165394,18.2668285 14.8740917,17.7497977 L12.0000234,15.7483347 Z"
              id="Path"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
)

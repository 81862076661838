import React from "react"
import { conclusionRequest } from "data/conclusions/api"
import ValidationModal from "./ValidationModal"
import ConfirmModal from "components/ConfirmModal"

const Modals = ({
  refetch,
  validationErrors,
  setValidationErrors,
  detail,
  setDetail,
}) => (
  <>
    {validationErrors && (
      <ValidationModal
        validationErrors={validationErrors}
        close={() => setValidationErrors()}
      />
    )}
    {detail && detail.action === "restore" && (
      <ConfirmModal
        close={() => setDetail()}
        onSuccess={refetch}
        successText={() => "Conclusion was restored."}
        modal={ConfirmModal}
        title="Restore conclusion"
        confirmText={`Conclusion ${detail.name} will be restored into original reporting status. Notification will be sent to all users, who have access to this conclusion.`}
        buttonText="Do you want to restore the conclusion?"
        cancelText="No"
        submitText="Yes"
        request={() =>
          conclusionRequest.restore({
            assessmentIds: [detail.id],
          })
        }
      />
    )}
    {detail && detail.action === "deletePermanent" && (
      <ConfirmModal
        close={() => setDetail()}
        onSuccess={refetch}
        successText={() => "Conclusion was permanently deleted."}
        modal={ConfirmModal}
        title="Permanently delete conclusion"
        confirmText={`Conclusion ${detail.name} will be permanently deleted and cannot be restored.\nIf included in the mass report object, it will be also permanently removed from this object, which will cause inconsistency between mass report detail and XML report file, unless the mass report object is also deleted.`}
        buttonText="Do you want to permanently delete the conclusion?"
        cancelText="No"
        submitText="Yes"
        request={() =>
          conclusionRequest.deletePermanent({
            assessmentIds: [detail.id],
          })
        }
        submitDanger
      />
    )}
  </>
)

export default Modals

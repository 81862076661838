import styled, { css } from "styled-components/macro"
import theme from "theme"

export const ToggleMarkWrap = styled.div`
  margin-right: 5px;
  flex-shrink: 0;
  cursor: pointer;

  svg {
    fill: ${theme.color.red};
    vertical-align: middle;
  }

  ${p =>
    p.hide &&
    css`
      visibility: hidden;
    `}
`

import React from "react"
import PageTitle from "components/PageTitle"
import Tabs from "components/Tabs"
import { assessmentRequest } from "data/assessments/api"
import { assessmentColors } from "data/assessments/colors"
import { statuses, conclusionStatuses } from "data/assessments/statuses"
import withData from "hoc/withData"
import withProfile from "hoc/withProfile"
import withPage from "hoc/withPage"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import * as R from "ramda"
import { Redirect, Route, Switch } from "react-router-dom"
import { Container } from "styledComponents/Containers"
import BottomPanel from "./BottomPanel"
import { getSuburls } from "./suburls"
import { reportingRequest } from "data/reporting/api"
import TitleControl from "./TitleControl"
import ScrollToTop from "components/ScrollToTop"
import FavoriteButton from "components/FavoriteButton"
import withState from "hoc/withState"
import withProps from "hoc/withProps"
import { getTemplateId } from "data/assessments/helpers"
import CaseTitle from "components/CaseTitle"
import { caseRequest } from "data/cockpit/api"
import { getSuburls as getCaseSuburls } from "pages/CaseCreatePage/suburls"
import useOpen from "hooks/useOpen"
import { preassessmentRequest } from "data/preassessments/api"

const AssessmentsCreatePage = ({
  history,
  match,
  location,
  assessment,
  reporting,
  refetchReporting,
  refetch,
  fetchCount,
  bookmarkedOnly,
  setBookmarkedOnly,
  profile,
  template,
  templateId,
  urls,
  case_,
  refetchData,
  fromCase,
  caseUrls,
  cancelUrl,
  isPreassessment,
  refetchCase,
}) => {
  const status = assessment && assessment.status
  const canEdit =
    !assessment || (assessment.canEdit && status === statuses.inProcess.value)

  const suburls = getSuburls(match, status, assessment, reporting, template)

  const {
    isOpen: isBrowserOpen,
    open: openBrowser,
    close: closeBrowser,
  } = useOpen()

  // the assessment is already a conclusion, so redirect there
  if (R.values(conclusionStatuses).map(R.prop("value")).includes(status)) {
    if (isPreassessment) {
      return (
        <Redirect
          to={match.url.replace(
            "preassessment/detail",
            "preassessment/conclusion",
          )}
        />
      )
    }

    return (
      <Redirect
        to={
          caseUrls
            ? caseUrls.conclusion.url(assessment.id)
            : urls.conclusionsDetail(assessment.id)
        }
      />
    )
  }

  const suburlsArr = R.values(suburls)
  const currIndex = suburlsArr.findIndex(
    x => x.url === location.pathname + location.search,
  )

  const p = R.path([currIndex, "path"], suburlsArr)
  const isQuestionsTab =
    p === R.path(["evaluation", "path"], suburls) ||
    p === R.path(["reporting", "path"], suburls)

  return (
    <>
      <Container>
        {!fromCase && assessment?.breadcrumbs && (
          <CaseTitle
            objectId={assessment.id}
            assessment={assessment}
            case_={case_}
            urls={urls}
            location={{
              pathname: `/${profile.tenantId}/case-create/${assessment.breadcrumbs.case.id}/detail`,
            }}
            history={history}
            refetchCase={refetchData}
            suburls={getCaseSuburls(
              {
                params: {
                  id: assessment.breadcrumbs.case.id,
                },
                path: `/${profile.tenantId}/case-create/:id`,
                url: `/${profile.tenantId}/case-create/${assessment.breadcrumbs.case.id}`,
              },
              openBrowser,
              case_,
              R.path(
                ["breadcrumbs", "case", "involvedParties", 0, "id"],
                assessment,
              ),
            )}
            isBrowserOpen={isBrowserOpen}
            closeBrowser={closeBrowser}
            collapsed
          />
        )}
        <PageTitle
          title={
            assessment ? (
              <Box flex acenter>
                {!isPreassessment && (
                  <Box mr={10}>
                    <FavoriteButton
                      isFavorite={assessment.favorite}
                      request={assessmentRequest.setFavorite(assessment.id)}
                      large
                      onSuccess={refetch}
                    />
                  </Box>
                )}
                <Box>{assessment.name}</Box>
              </Box>
            ) : (
              localize("newAssessment")
            )
          }
          status={{
            color: assessmentColors[status],
            text: localize(status),
          }}
        >
          <TitleControl
            assessment={assessment}
            canEdit={canEdit}
            suburls={suburls}
            reporting={reporting}
            location={location}
            isPreassessment={isPreassessment}
            case_={case_}
            refetchCase={refetchCase}
            caseUrls={caseUrls}
          />
        </PageTitle>
        <Tabs
          options={R.values(suburls).map(x => ({
            ...x,
            value: x.url,
            disabled: x.disabled,
          }))}
          value={location.pathname}
          onChange={v => history.push(v)}
          inPanel
          assessment={assessment}
          arrows
          disableActive
        />
        <Switch>
          {R.values(suburls)
            .filter(x => !x.disabled)
            .map(x => {
              const Component = x.component
              // either it is new template and it comes from  url query string
              // or it comes from assesment detail, in case of edit
              return (
                <Route
                  key={x.path}
                  path={x.path}
                  exact
                  render={p => (
                    <Component
                      {...p}
                      canEdit={canEdit}
                      canApprove={assessment && assessment.canApprove}
                      suburls={suburls}
                      assessment={assessment}
                      assessmentId={+match.params.id}
                      profile={profile}
                      bookmarkedOnly={bookmarkedOnly}
                      setBookmarkedOnly={setBookmarkedOnly}
                      reporting={reporting}
                      refetch={refetch}
                      refetchReporting={refetchReporting}
                      reportingFetchCount={fetchCount}
                      template={template}
                      refetchCase={refetchCase}
                      templateId={templateId}
                      caseUrls={caseUrls}
                      cancelUrl={cancelUrl}
                      isPreassessment={isPreassessment}
                      case_={case_}
                      {...x.props}
                    />
                  )}
                />
              )
            })}
          <Redirect to={suburls.settings.url + location.search} />
        </Switch>
        <BottomPanel
          canEdit={canEdit}
          assessment={assessment}
          status={status}
          refetch={refetch}
          bookmarkedOnly={bookmarkedOnly}
          setBookmarkedOnly={setBookmarkedOnly}
          isQuestionsTab={isQuestionsTab}
          suburlsArr={suburlsArr}
          currIndex={currIndex}
          isPreassessment={isPreassessment}
          refetchCase={refetchCase}
          case_={case_}
        />
      </Container>
      <ScrollToTop />
    </>
  )
}

export const EnhancedAssessmentsCreatePage = R.compose(
  withProfile,
  withState("bookmarkedOnly", "setBookmarkedOnly", false),
  withData(
    p => {
      return p.isPreassessment
        ? preassessmentRequest.detail(p.match.params.id, p.case_.id)
        : assessmentRequest.detail(p.match.params.id)
    },
    {
      skip: p => p.match.params.id === "new",
      refetchParams: p => [p.location.pathname],
      dataKey: "assessment",
    },
  ),
  withProps(p => ({
    templateId: getTemplateId(p.location, p.assessment),
    refetch: p.refetchData,
  })),
  withData(p => assessmentRequest.templateDetail(p.templateId), {
    dataKey: "template",
  }),
  withData(p => reportingRequest.reporting(p.match.params.id), {
    skip: p => p.match.params.id === "new" || p.isPreassessment,
    refetchParams: p => [p.match.params.id],
    dataKey: "reporting",
  }),
  withProps(p => ({
    refetchReporting: p.refetchData,
  })),
  withData(p => caseRequest.detail(p.assessment.breadcrumbs.case.id), {
    skip: p => !p.assessment?.breadcrumbs?.case.isClickable,
    // case is a reserved word
    dataKey: "case_",
  }),
)(AssessmentsCreatePage)

export default withPage()(EnhancedAssessmentsCreatePage)

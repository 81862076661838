import React from "react"
import CaretIcon from "icons/Caret"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import Tutorial from "../Tutorial"
import { CategoryToggle } from "./styled"
import useOpen from "hooks/useOpen"

const Category = ({ category }) => {
  const { isOpen, toggle } = useOpen()

  return (
    <>
      <CategoryToggle onClick={toggle}>
        <P red link fs={18}>
          {category.name}
        </P>
        <IconWrap rotate={+isOpen}>
          <CaretIcon />
        </IconWrap>
      </CategoryToggle>
      {isOpen &&
        category.tutorials.map(t => <Tutorial tutorial={t} key={t.id} />)}
    </>
  )
}

export default Category

import React from "react"
import * as R from "ramda"
import { P } from "styledComponents/Typography"

export const getOptions = groupNames =>
  R.range(1, 7).map(x => {
    const typeLabel = R.pipe(
      R.find(y => y.autoFillGroupNr === x),
      R.prop("text"),
    )(groupNames)
    return {
      name: `Group ${x}`,
      value: x,
      newName: typeLabel,
      typeLabel: typeLabel && (
        <P lightgray fs={14}>
          {typeLabel}
        </P>
      ),
    }
  })

export const handleChange = (value, question, onChange) => v => {
  const i = value.findIndex(x => x.templateQuestionId === question.id)

  if (i === -1) {
    return onChange(
      value.concat({
        templateQuestionId: question.id,
        autoFillGroupNr: v,
      }),
    )
  }

  if (R.isNil(v)) {
    return onChange(value.filter(x => x.templateQuestionId !== question.id))
  }

  return onChange(R.set(R.lensPath([i, "autoFillGroupNr"]), v, value))
}

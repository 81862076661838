import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import Tabs from "components/Tabs"
import Panel from "components/Panel"
import withModal from "hoc/withModal"
import withSubmit from "hoc/withSubmit"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import QuestionAnswer from "../QuestionAnswer"
import * as R from "ramda"
import { QuestionBody, QuestionHeader, QuestionWrap } from "../styled"
import { calculateEnabled } from "../logicEvaluator"
import withState from "hoc/withState"
import withProps from "hoc/withProps"

const whos = {
  my: { name: localize("yourAnswer"), value: "my" },
  their: { name: localize("currentlySavedAnswer"), value: "theirs" },
}

const ClashModal = ({
  close,
  data,
  node,
  currentValue,
  assessment,
  tab,
  setTab,
  submit,
}) => (
  <>
    <Tabs options={R.values(whos)} value={tab} onChange={setTab} inPanel />
    <Panel noPadding>
      <QuestionWrap>
        <QuestionHeader>
          <P required={node.data.isMandatory}>Q{node.idx}</P>
        </QuestionHeader>
        <QuestionBody mandatory={node.data.isEnabled}>
          <QuestionAnswer
            data={data}
            node={node}
            value={currentValue.answerSets}
            values={currentValue.answerSets}
            attachments={currentValue.attachments}
            enabled={calculateEnabled(currentValue.answerSets, node)}
            readOnly
            rootEnabled
            assessment={assessment}
          />
        </QuestionBody>
      </QuestionWrap>
    </Panel>
    <ModalButtons>
      <Button
        secondary
        type="button"
        onClick={close}
        text={localize("discard")}
      />
      <Button onClick={() => submit(true)} text={localize("saveYourAnswer")} />
      <Button
        onClick={() => submit(false)}
        text={localize("keepCurrentlySavedAnswer")}
      />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(() => localize("anotherUserAlreadyAnswered")),
  withState("tab", "setTab", whos.my.value),
  withProps(p => {
    const showMine = p.tab === whos.my.value
    return {
      showMine,
      currentValue: showMine ? p.myValue : p.clash,
    }
  }),
  withSubmit({
    onSubmit: p => mine => {
      const data = {
        answerSets: mine ? p.myValue.answerSets : p.clash.answerSets,
        attachments: mine ? p.myValue.attachments : p.clash.attachments,
        timestamp: p.clash.timestamp,
      }

      return p.answerRequest(p.assessment.id, p.node.referencedId, data)
    },
    onSuccess: p => () => {
      p.close()
      p.onSuccess(p.node.id, true)
    },
    errorText: () => (message, err) => {
      if (R.path(["error", "response", "status"], err) === 409) {
        return localize("answerWasMeanwhileModified")
      }

      return message
    },
  }),
)(ClashModal)

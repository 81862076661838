import styled from "styled-components/macro"

export const FilterWrap = styled.div`
  padding: 5px 20px;
  color: #999;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`

export const FilterRight = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  > * {
    margin-right: 10px;

    &:last-child {
      margin-right: 0;
    }
  }
`

import React from "react"
import { P } from "styledComponents/Typography"
import * as R from "ramda"

const Validations = ({
  groupNumber,
  parentGroupNumber,
  grandParentGroupNumber,
  isCustom,
  question,
  value,
}) => {
  if (
    !R.isNil(groupNumber) &&
    groupNumber === parentGroupNumber &&
    groupNumber === grandParentGroupNumber
  ) {
    return (
      <P fs={12} error mt={3}>
        Only two levels of nesting can have the same group
      </P>
    )
  }

  const childrenIds = question.subQuestions.map(R.prop("id"))
  if (
    isCustom &&
    R.isNil(groupNumber) &&
    value.filter(
      x =>
        childrenIds.includes(x.templateQuestionId) &&
        !R.isNil(x.registryCatalogId),
    ).length > 0
  ) {
    return (
      <P fs={12} error mt={3}>
        Group is required if subquestion is tagged
      </P>
    )
  }

  return null
}
export default Validations

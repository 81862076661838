import * as R from "ramda"

const removeEmptyNodes = (removeNode, getChildrenLen) => node => {
  const len = getChildrenLen(node)
  const list = R.view(len, node)

  const nodesToRecursivelyCall = list.map((item, i) => {
    if (!item) return undefined

    item.remove = () => {
      delete list[i]
    }
    return item
  })

  nodesToRecursivelyCall.forEach(removeEmptyNodes(removeNode, getChildrenLen))

  if (removeNode(node) && node.remove) {
    node.remove()
  }

  return node
}

export const filterTree = (removeNode, getChildrenLen, tree) => {
  // removeEmptyNodes mutates, so we need to deep copy it
  const copy = JSON.parse(JSON.stringify(tree))
  return copy.map(removeEmptyNodes(removeNode, getChildrenLen))
}

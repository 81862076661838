import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import withModal from "hoc/withModal"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import * as R from "ramda"

const SkipModal = ({ submit, text, discard, close, discardOnSubmit }) => (
  <>
    <P>{text}</P>
    <ModalButtons>
      <Button
        secondary
        type="button"
        onClick={discardOnSubmit ? close : discard}
        text={discardOnSubmit ? "Cancel" : localize("noDiscard")}
      />
      <Button
        onClick={discardOnSubmit ? discard : submit}
        text={discardOnSubmit ? "OK" : localize("yesSave")}
      />
    </ModalButtons>
  </>
)

export default R.compose(withModal(p => p.title))(SkipModal)

import * as R from "ramda"

const satisfiesTerm = (term, answers, enabledMap) => {
  const ans = answers.find(x => x.templateQuestionId === term.questionId)

  // no answer for this question
  if (!ans) return false

  const en = enabledMap.find(x => x.id === term.questionId)
  const isEnabled = !en || en.isEnabled
  if (!isEnabled) return false

  if (ans.optionIds) {
    return term.relation === "is"
      ? ans.optionIds.includes(term.answerId)
      : !ans.optionIds.includes(term.answerId)
  }

  return term.relation === "is"
    ? term.answerId === ans.optionId
    : term.answerId !== ans.optionId
}

const evaluate = (item, answers, enabledMap) => {
  if (R.type(item) === "Object") {
    return satisfiesTerm(item, answers, enabledMap)
  }

  return evalFormula(answers, item, 0, item.length - 1, enabledMap)
}

const evalFormula = (answers, formula, startIndex, endIndex, enabledMap) => {
  if (formula.length === 0) {
    // empty parenthesis is invalid expression
    return false
  }

  if (startIndex > endIndex) {
    return true
  }

  if (startIndex === endIndex) {
    return evaluate(formula[startIndex].item, answers, enabledMap)
  }

  // as AND operation has higher precedence, process first the OR operations
  for (let i = startIndex + 1; i <= endIndex; ++i) {
    if (formula[i].operationBefore === "or") {
      const leftResult = evalFormula(
        answers,
        formula,
        startIndex,
        i - 1,
        enabledMap,
      )
      const rightResult = evalFormula(answers, formula, i, endIndex, enabledMap)
      return leftResult || rightResult
    }
  }

  // only AND operators left in this point, process from left to right (first evaluation to False stops processing of the rest expression)
  const left = formula[startIndex]

  return (
    evaluate(left.item, answers, enabledMap) &&
    evalFormula(answers, formula, startIndex + 1, endIndex, enabledMap)
  )
}

export const calculateEnabled = (answers, node) => {
  if (node.data.questionType !== "Custom") return null

  const data = {
    ...node.data,
    id: node.referencedId,
  }

  const getChildrenEnabled = (set, data, previousAnswers, previousEnabled) => {
    const newPreviousAnswers = previousAnswers.concat(set.answers)
    let mappedSoFar = []
    const enabled = (data.subQuestions || []).map(sq => {
      const result = {
        id: sq.id,
        isEnabled:
          !sq.precondition ||
          evaluate(
            sq.precondition,
            newPreviousAnswers,
            previousEnabled.concat(mappedSoFar),
          ),
      }
      mappedSoFar.push(result)
      return result
    })
    const newPreviousEnabled = previousEnabled.concat(enabled)

    return {
      questionId: data.id,
      enabled,
      isEnabled:
        !data.precondition ||
        evaluate(data.precondition, newPreviousAnswers, newPreviousEnabled),
      children: (set.children || []).map(s =>
        getChildrenEnabled(
          s,
          data.subQuestions.find(x => x.id === s.questionId),
          newPreviousAnswers,
          newPreviousEnabled,
        ),
      ),
    }
  }

  const result = answers.map(x => getChildrenEnabled(x, data, [], []))
  return result
}

import React from "react"
import Button from "components/Button"
import Text from "components/Text"
import ModalButtons from "components/ModalButtons"
import Checkbox from "inputs/Checkbox"
import Textfield from "inputs/Textfield"
import withModal from "hoc/withModal"
import withForm from "hoc/withForm"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import { boolOptional, boolTrue } from "validators/bool"
import { stringOptional, stringRequired } from "validators/string"

const ConfirmModal = ({
  confirmText,
  cancelText = localize("cancel"),
  submitText = localize("submit"),
  close,
  submitForm,
  confirmQuestion,
  confirmConditionText,
  reasonLabel,
  submitDanger,
  buttonText,
}) => (
  <>
    <Text>{confirmText}</Text>
    {confirmQuestion && (
      <P bold mt={20}>
        {confirmQuestion}
      </P>
    )}
    {confirmConditionText && (
      <Checkbox name="confirmation" squared flip text={confirmConditionText} />
    )}
    {reasonLabel && (
      <Textfield
        mt={20}
        name="reason"
        label={reasonLabel}
        required
        placeholder="Write the reason"
      />
    )}
    <ModalButtons buttonText={buttonText}>
      <Button secondary type="button" onClick={close} text={cancelText} />
      <Button text={submitText} onClick={submitForm} danger={submitDanger} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(p => p.title),
  withForm({
    schema: p => ({
      confirmation: p.confirmConditionText ? boolTrue : boolOptional,
      reason: p.reasonLabel ? stringRequired : stringOptional,
    }),
    onSubmit: p => v => p.request(v),
    onSuccess: p => () => {
      p.close()
      if (p.onSuccess) {
        p.onSuccess()
      }
    },
    onError:
      p =>
      (...args) => {
        if (p.onError) {
          p.onError(...args)
        }
      },
    redirect: (res, p) => p.redirect && p.redirect(res, p),
    successText: p => p.successText,
  }),
)(ConfirmModal)

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path d="M10.5 1.5H4.5V3.75H3V1.5C3 0.672 3.672 0 4.5 0H10.5C11.329 0 12 0.672 12 1.5V3H10.5V1.5Z" />
    <path d="M10.5 6.75H4.5V9H3V6.75C3 5.922 3.672 5.25 4.5 5.25H10.5C11.329 5.25 12 5.922 12 6.75V8.25H10.5V6.75Z" />
    <path d="M11.375 9H20.125C20.6082 9 21 8.66421 21 8.25C21 7.83579 20.6082 7.5 20.125 7.5H11.375C10.8918 7.5 10.5 7.83579 10.5 8.25C10.5 8.66421 10.8918 9 11.375 9Z" />
    <path d="M11.375 3.75H20.125C20.6082 3.75 21 3.41421 21 3C21 2.58579 20.6082 2.25 20.125 2.25H11.375C10.8918 2.25 10.5 2.58579 10.5 3C10.5 3.41421 10.8918 3.75 11.375 3.75Z" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M0.75 11.25C0.335786 11.25 0 11.5858 0 12V23.25C0 23.6642 0.335786 24 0.75 24H23.25C23.6642 24 24 23.6642 24 23.25V12C24 11.5858 23.6642 11.25 23.25 11.25H0.75ZM9 17.25H15V16.5C15 16.0858 15.3358 15.75 15.75 15.75C16.1642 15.75 16.5 16.0858 16.5 16.5V18C16.5 18.4142 16.1642 18.75 15.75 18.75H8.25C7.83579 18.75 7.5 18.4142 7.5 18V16.5C7.5 16.0858 7.83579 15.75 8.25 15.75C8.66421 15.75 9 16.0858 9 16.5V17.25Z"
    />
    <path d="M1.5 13.375L1.5 3.125C1.5 2.64175 1.16421 2.25 0.749999 2.25C0.335786 2.25 -4.41846e-07 2.64175 -4.20722e-07 3.125L-3.82475e-08 13.375C-1.7124e-08 13.8582 0.335786 14.25 0.75 14.25C1.16421 14.25 1.5 13.8582 1.5 13.375Z" />
    <path d="M24 13.375L24 3.125C24 2.64175 23.6642 2.25 23.25 2.25C22.8358 2.25 22.5 2.64175 22.5 3.125L22.5 13.375C22.5 13.8582 22.8358 14.25 23.25 14.25C23.6642 14.25 24 13.8582 24 13.375Z" />
  </svg>
)

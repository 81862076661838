import React from "react"
import { YearWrap, StyledYear } from "./styled"
import { localize } from "locale"
import dayjs from "dayjs"

const months = localize("monthsArray")

const Year = ({ onChange, offset }) => (
  <YearWrap>
    {months.map((x, xi) => (
      <StyledYear
        key={x}
        onClick={() => onChange(xi + offset * 12 - dayjs().format("M") + 1)}
      >
        {x}
      </StyledYear>
    ))}
  </YearWrap>
)

export default Year

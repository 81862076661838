import { localize } from "locale"
import * as R from "ramda"

export const assessmentStatuses = {
  inProcess: {
    value: "InProgress",
    name: localize("InProgress"),
  },
  requestForApproval: {
    value: "ReadyForApproval",
    name: localize("ReadyForApproval"),
  },
  deleted: {
    value: "Deleted",
    name: localize("recycleBin"),
  },
  deactivated: {
    value: "Deactivated",
    name: localize("Deactivated"),
  },
}

export const assessmentLibraryStatuses = {
  libraryInDraft: {
    value: "LibraryInDraft",
    name: localize("LibraryInDraft"),
  },
  libraryInReview: {
    value: "LibraryInReview",
    name: localize("LibraryInReview"),
  },
  libraryPublished: {
    value: "LibraryPublished",
    name: localize("Published"),
  },
  libraryDeactivated: {
    value: "LibraryDeactivated",
    name: localize("Deactivated"),
  },
}

export const conclusionStatuses = {
  conclusionApproved: {
    value: "ConclusionApproved",
    name: localize("ConclusionApproved"),
  },
  conclusionRejected: {
    value: "ConclusionRejected",
    name: localize("ConclusionRejected"),
  },
  conclusionReadyForReview: {
    value: "ConclusionReadyForReview",
    name: localize("ConclusionReadyForReview"),
  },
  conclusionReviewed: {
    value: "ConclusionReviewed",
    name: localize("ConclusionReviewed"),
  },
  conclusionReadyForGeneration: {
    value: "ConclusionReadyForGeneration",
    name: localize("ConclusionReadyForGeneration"),
  },
  conclusionReadyForReporting: {
    value: "ConclusionReadyForReporting",
    name: localize("ConclusionReadyForReporting"),
  },
  conclusionDeleted: {
    value: "ConclusionDeleted",
    name: "Deleted",
  },
}

export const reportStatuses = {
  reportSubmitted: {
    value: "ReportSubmitted",
    name: localize("ReportSubmitted"),
  },
  reportAccepted: {
    value: "ReportAcceptedByTA",
    name: localize("ReportAcceptedByTA"),
  },
  reportRejected: {
    value: "ReportRejectedByTA",
    name: localize("ReportRejectedByTA"),
  },
}

export const statuses = {
  all: {
    value: "All",
    name: localize("all"),
  },
  ...assessmentStatuses,
  ...assessmentLibraryStatuses,
  ...conclusionStatuses,
  ...reportStatuses,
  // undecided might be deprecated
  // undecided: {
  //   value: "Undecided",
  //   name: localize("Undecided"),
  // },
}

export const getStatusName = value => {
  const status = R.values(statuses).find(x => x.value === value)

  return R.path(["name"], status)
}

export const isInFinalState = status =>
  status === reportStatuses.reportAccepted.value ||
  status === reportStatuses.reportRejected.value

export const getReportTransitionOptions = (initStatus, currentStatus) => {
  const initStatusOption = R.values(statuses).find(x => x.value === initStatus)
  const currentStatusOption = R.values(statuses).find(
    x => x.value === currentStatus,
  )
  const common = [initStatusOption, currentStatusOption]

  const uniqByValue = R.pipe(
    R.filter(x => x),
    R.uniqBy(R.prop("value")),
  )

  if (initStatus === statuses.conclusionReadyForReporting.value)
    return uniqByValue([...common, statuses.reportSubmitted])

  if (initStatus === statuses.reportSubmitted.value) {
    return uniqByValue([
      ...common,
      statuses.reportAccepted,
      statuses.reportRejected,
    ])
  }

  return uniqByValue(common)
}

export const isValidTransition = (from, to) => {
  if (from === to) return true

  if (from === statuses.conclusionReadyForReporting.value) {
    return to === statuses.reportSubmitted.value
  }

  if (from === statuses.reportSubmitted.value) {
    return (
      to === statuses.reportAccepted.value ||
      to === statuses.reportRejected.value
    )
  }

  return false
}

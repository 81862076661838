import styled, { css } from "styled-components/macro"
import theme from "theme"

export const PartyObjectWrap = styled.div`
  padding: 5px 20px;
  border: 1px solid ${theme.color.border};

  ${p =>
    p.isOpen &&
    css`
      border-color: ${theme.color.orange};
      background-color: #ffe8dc;
    `}
`

export const LinksWrap = styled.div`
  background: #f2f2f2;
  padding: 0 30px;
`

export const LinkItem = styled.div`
  border-bottom: 1px solid #d8d8d8;
  padding: 10px 0;
  font-weight: 600;
  font-size: 14px;
  cursor: pointer;

  &:last-child {
    border-bottom: 0;
  }

  ${p =>
    p.disabled &&
    css`
      color: #999;
      cursor: default;
    `}
`

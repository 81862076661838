import styled from "styled-components/macro"

export const PageTitleWrap = styled.div`
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
`

export const PageTitleContent = styled.div`
  margin-left: auto;
  margin-bottom: 10px;
  display: inline-block;
`

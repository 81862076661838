import React from "react"
import { P } from "styledComponents/Typography"
import { Spread } from "styledComponents/Containers"
import { ActiveIcon } from "styledComponents/Icons"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import useOpen from "hooks/useOpen"
import { PartyObjectWrap, LinksWrap, LinkItem } from "./styled"
import { getSuburls as getAssessmentSuburls } from "pages/AssessmentsCreatePage/suburls"
import { getSuburls as getConclusionSuburls } from "pages/ConclusionsDetailPage/suburls"
import { statuses } from "data/assessments/statuses"
import * as R from "ramda"

const getUrls = ({ object, caseSuburls, isPreassessment, routes }) => {
  if (object.isConclusion) {
    if (isPreassessment) {
      return getConclusionSuburls(
        {
          path: `${caseSuburls.preassessment.path}/conclusion/${object.id}`,
          url: `${caseSuburls.preassessment.url}/conclusion/${object.id}`,
        },
        object,
      )
    }

    return getConclusionSuburls(
      {
        path: `${caseSuburls.detail.path}/conclusion/${object.id}`,
        url: `${caseSuburls.detail.url}/conclusion/${object.id}`,
      },
      object,
    )
  }

  if (isPreassessment) {
    return getAssessmentSuburls(
      {
        path: `${caseSuburls.preassessment.path}/detail/${object.id}`,
        url: `${caseSuburls.preassessment.url}/detail/${object.id}`,
      },
      statuses.inProcess.value,
      {},
      {
        doReport: object.isReportingAllowed,
      },
      { isPreAssessment: true },
    )
  }

  return getAssessmentSuburls(
    {
      path: `${caseSuburls.detail.path}/assessment/${object.id}`,
      url: `${caseSuburls.detail.url}/assessment/${object.id}`,
    },
    statuses.inProcess.value,
    {},
    {
      doReport: object.isReportingAllowed,
    },
    { isPreAssessment: false },
  )
}

const PartyObject = ({
  object,
  routes,
  objectId,
  history,
  isPreassessment,
  caseSuburls,
  close,
}) => {
  const { isOpen, toggle } = useOpen(object.id === objectId)

  const suburls = getUrls({
    object,
    caseSuburls,
    isPreassessment,
    routes,
  })

  return (
    <>
      <PartyObjectWrap isOpen={isOpen}>
        <Spread>
          <P bold fs={14}>
            {object.name}
          </P>
          <ActiveIcon onClick={toggle} rotate={+isOpen} borderless>
            <OutlineArrow />
          </ActiveIcon>
        </Spread>
      </PartyObjectWrap>
      {isOpen && (
        <LinksWrap>
          <>
            {R.values(suburls).map(x => (
              <LinkItem
                key={x.id}
                onClick={
                  x.disabled
                    ? undefined
                    : () => {
                        history.push(x.url)
                        close()
                      }
                }
                disabled={x.disabled}
              >
                {x.name}
              </LinkItem>
            ))}
          </>
        </LinksWrap>
      )}
    </>
  )
}

export default PartyObject

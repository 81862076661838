import React from "react"

const useOpen = (initialState = false) => {
  const [isOpen, setIsOpen] = React.useState(initialState)
  const open = () => setIsOpen(true)
  const close = () => setIsOpen(false)
  const toggle = () => setIsOpen(x => !x)

  return { isOpen, open, close, toggle, setIsOpen }
}

export default useOpen

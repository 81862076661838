import React from "react"
import { TextareaToggle } from "./styled"

const EditButton = ({ active, cmd, onClick, isFocused, space, icon: Icon }) => {
  const isActive = active[cmd]
  return (
    <TextareaToggle
      active={isActive}
      disabled={!isFocused}
      key={cmd}
      onMouseDown={e => {
        e.preventDefault() // Avoids loosing focus from the editable area
        document.execCommand(cmd, false, "")
        onClick()
      }}
      space={space}
    >
      <Icon />
    </TextareaToggle>
  )
}

export default EditButton

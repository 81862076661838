import React from "react"
import Portal from "components/Portal"

const SmartPortal = ({ children, cls, firstChild }) => {
  const [el, setEl] = React.useState()
  const ref = React.useRef()

  React.useEffect(() => {
    let id
    if (el) return

    id = setInterval(() => {
      if (!ref.current) return

      const element = ref.current.closest(`.${cls}`)
      if (element) {
        const e = firstChild ? element.children[0] : element
        setEl(e)
        clearInterval(id)
      }
    }, 300)
  }, []) // eslint-disable-line

  return (
    <>
      <div ref={ref} />
      {el && <Portal node={el}>{children}</Portal>}
    </>
  )
}

export default SmartPortal

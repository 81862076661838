import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M16.8102,6.9306 L21.1287,11.2491 L10.8402,11.2491 L10.8402,12.7491 L21.1287,12.7491 L16.8102,17.0691 L17.8707,18.1296 L23.9997,11.9991 L17.8707,5.8701 L16.8102,6.9306 Z M13.5,22.5 L1.5,22.5 L1.5,1.5 L13.5,1.5 L13.5,5.25 L15,5.25 L15,1.5 C15,0.672 14.328,0 13.5,0 L1.5,0 C0.672,0 0,0.672 0,1.5 L0,22.5 C0,23.328 0.672,24 1.5,24 L13.5,24 C14.328,24 15,23.328 15,22.5 L15,18.75 L13.5,18.75 L13.5,22.5 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

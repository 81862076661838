import { flattenQuestion } from "data/autofill/helpers"
import { localize } from "locale"
import * as R from "ramda"

export const answerTypes = {
  freeText: { value: "FreeText", name: localize("freeText") },
  single: { value: "Single", name: localize("singleChoice") },
  multiple: { value: "Multiple", name: localize("multipleAnswers") },
  date: { value: "Date", name: localize("date") },
  value: { value: "Value", name: localize("valueOfArrangement") },
  email: { value: "Email", name: localize("emailAddress") },
  name: { value: "Name", name: localize("name") },
  confirmation: { value: "Confirmation", name: localize("confirmation") },
  country: { value: "Country", name: localize("countryArrangement") },
  countryOther: { value: "CountryOther", name: localize("countryOther") },
  custom: { value: "Custom", name: localize("custom") },
}

export const answerTypeOptions = R.values(answerTypes)

export const defaultSingleAnswers = [
  {
    text: localize("yes"),
    answerAttachmentPolicy: "N",
  },
  {
    text: localize("no"),
    answerAttachmentPolicy: "N",
  },
]
export const defaultMultiAnswers = [
  {
    text: "",
    answerAttachmentPolicy: "N",
  },
]

export const getDefaultAnswers = type => {
  if (type === answerTypes.confirmation.value)
    return [R.head(defaultSingleAnswers)]

  return type === answerTypes.single.value
    ? defaultSingleAnswers
    : defaultMultiAnswers
}

export const fileUploadOptions = [
  { value: "N", name: localize("disallowed") },
  { value: "O", name: localize("optional") },
  { value: "R", name: localize("required") },
]

export const isSingleMultipleOrConfirmation = questionType =>
  questionType === answerTypes.single.value ||
  questionType === answerTypes.confirmation.value ||
  questionType === answerTypes.multiple.value

export const isCustom = questionType =>
  questionType === answerTypes.custom.value

export const getSubquestionOptions = depth =>
  answerTypeOptions.filter(x => {
    // dont allow infinite nesting
    if (depth > 4 && x.value === answerTypes.custom.value) return false

    return (
      x.value !== answerTypes.confirmation.value &&
      x.value !== answerTypes.value.value
    )
  })

export const answerKey = {
  [answerTypes.single.value]: "optionId",
  [answerTypes.multiple.value]: "optionIds",
  [answerTypes.confirmation.value]: "optionIds",
  [answerTypes.date.value]: "date",
  [answerTypes.value.value]: "value",
  [answerTypes.country.value]: "countryCode",
  [answerTypes.countryOther.value]: "countryCode",
  [answerTypes.freeText.value]: "text",
  [answerTypes.email.value]: "text",
  [answerTypes.name.value]: "name",
}

export const specialBehavior = {
  noneOfTheAbove: "NoneOfTheAbove",
}

export const getCanBeTagged = q => {
  const sqs = flattenQuestion(q, false)

  return sqs
    .filter(q => q.questionType !== answerTypes.custom.value)
    .some(x =>
      [
        answerTypes.freeText.value,
        answerTypes.name.value,
        answerTypes.email.value,
        answerTypes.date.value,
        answerTypes.country.value,
        answerTypes.countryOther.value,
        answerTypes.custom.value,
      ].includes(x.questionType),
    )
}

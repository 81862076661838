import React from "react"
import Text from "components/Text"
import withFormField from "hoc/withFormField"
import { CheckboxWrap, StyledCheckbox, StyledCheckboxSquared } from "./styled"

const Checkbox = ({
  value,
  onChange,
  text,
  readOnly,
  flip,
  squared,
  nopadding,
  gray,
}) => {
  const Component = squared ? StyledCheckboxSquared : StyledCheckbox
  return (
    <CheckboxWrap
      onClick={() => onChange(!value)}
      readOnly={readOnly}
      nopadding={nopadding}
      data-id-checked={!!value}
      gray={gray}
    >
      {flip ? (
        <>
          <Component active={value} gray={gray} />
          <Text gray ml={10} nobreak>
            {text}
          </Text>
        </>
      ) : (
        <>
          {text && (
            <Text gray mr={10} nobreak>
              {text}
            </Text>
          )}
          <Component active={value} gray={gray} />
        </>
      )}
    </CheckboxWrap>
  )
}

export default withFormField(Checkbox)

import { localize } from "locale"

export const tenantRoles = {
  reviewer: { value: "Reviewer", name: localize("reviewer") },
  approver: { value: "Approver", name: localize("approver") },
  approverSelected: {
    value: "ApproverSelected",
    name: localize("approverSelected"),
  },
}

export const toggleApproverSelectedRole = r =>
  r === tenantRoles.approver.value
    ? tenantRoles.approverSelected.value
    : tenantRoles.approver.value

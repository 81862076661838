import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M12,22.5 C9.375,22.5 6.978,21.525 5.1345,19.926 L19.926,5.1345 C21.525,6.978 22.5,9.375 22.5,12 C22.5,17.79 17.79,22.5 12,22.5 M1.5,12 C1.5,6.21 6.21,1.5 12,1.5 C14.625,1.5 17.022,2.475 18.8655,4.074 L4.074,18.8655 C2.475,17.022 1.5,14.625 1.5,12 M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"
import { Box } from "styledComponents/Containers"
import TenantSwitch from "./TenantSwitch"
import AvatarMenu from "./AvatarMenu"
import Helpcenter from "./Helpcenter"
import Notifications from "./Notifications"

const Extras = ({ profile, tenants, logout }) => (
  <Box flex acenter pt={10} pb={10} pr={20} grow>
    <TenantSwitch profile={profile} tenants={tenants} />
    <Box ml="auto" flex acenter>
      <Notifications />
      <Helpcenter />
      <AvatarMenu profile={profile} logout={logout} />
    </Box>
  </Box>
)

export default Extras

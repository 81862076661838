import styled, { css } from "styled-components/macro"

export const OverlayWrapperWrap = styled.div`
  position: relative;
`

export const Overlay = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
  ${p =>
    !p.transparent &&
    css`
      background: rgba(0, 0, 0, 0.1);
    `};
`

export const OverlayWrapperContent = styled.div`
  ${p =>
    p.isOpen &&
    css`
      position: relative;
      z-index: 3;
    `};
`

import React from "react"
import { filterSafeProps } from "common/react"
import Link from "components/Link"
import { IconWrap, StyledButton, StyledDivButton } from "./styled"

const Button = ({
  text,
  type = "button",
  onClick,
  to,
  icon: Icon,
  asDiv,
  disabled,
  "data-id": dataId,
  ...rest
}) => {
  const Component = asDiv ? StyledDivButton : StyledButton
  const btn = (
    <Component
      {...filterSafeProps(rest)}
      type={asDiv ? null : type}
      onClick={onClick}
      withIcon={!!Icon}
      noText={!text}
      disabled={disabled}
      data-id={dataId}
    >
      {Icon && (
        <IconWrap {...filterSafeProps(rest)}>
          <Icon />
        </IconWrap>
      )}
      {text}
    </Component>
  )

  if (to && !disabled) return <Link to={to}>{btn}</Link>

  return btn
}

export default Button

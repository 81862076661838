import React from "react"
import { SetWrap, SetHeader, SetBody } from "./styled"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import Ribbons from "components/Ribbons"
import { localize } from "locale"

const Set = ({ wrap, children, mb, optional, optionalParent, noRibbon }) => {
  const content = wrap ? (
    <SetWrap mb={mb}>
      <SetHeader>
        <P bold fs={20}>
          Question set
        </P>
      </SetHeader>
      <SetBody>{children}</SetBody>
    </SetWrap>
  ) : (
    children
  )

  return optional ? (
    <Box relative opa={optionalParent ? 1 : 0.75}>
      {!noRibbon && <Ribbons right={20} ribbons={[localize("optional")]} />}
      {content}
    </Box>
  ) : (
    content
  )
}

export default Set

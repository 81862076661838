import React from "react"
import Table from "components/Table"
import { config } from "./config"
import withUrls from "hoc/withUrls"
import withData from "hoc/withData"
import { taskRequest } from "data/tasks/api"
import * as R from "ramda"

const TemplatesTable = ({ data, urls }) => (
  <Table
    config={config(urls)}
    data={data}
    sorting={{
      index: 0,
      desc: true,
    }}
  />
)

export default R.compose(
  withUrls,
  withData(() => taskRequest.templates()),
)(TemplatesTable)

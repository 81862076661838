import React from "react"
import dayjs from "dayjs"
import Month from "./Month"
import Year from "./Year"
import Decade from "./Decade"
import PrevNextArrows from "./PrevNextArrows"
import { modes } from "./modes"
import { startOfDecade } from "./helpers"
import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"
import * as R from "ramda"

const Calendar = ({
  onChange,
  value,
  offset,
  prev,
  next,
  handleChangeYear,
  handleChangeDecade,
  mode,
  higherMode,
  minDate,
  maxDate,
  noFuture,
  noPast,
  noToday,
}) => (
  <>
    <PrevNextArrows
      prev={prev}
      next={next}
      offset={offset}
      mode={mode}
      higherMode={higherMode}
    />
    {mode === modes.decade && (
      <Decade onChange={handleChangeDecade} offset={offset} />
    )}
    {mode === modes.year && (
      <Year onChange={handleChangeYear} offset={offset} />
    )}
    {mode === modes.month && (
      <Month
        value={value}
        onChange={onChange}
        month={dayjs().add(offset, "months")}
        minDate={minDate}
        maxDate={maxDate}
        noFuture={noFuture}
        noPast={noPast}
        noToday={noToday}
      />
    )}
  </>
)

export default R.compose(
  withState("mode", "setMode", modes.month),
  withState("offset", "setOffset", p =>
    p.value
      ? dayjs(p.value).startOf("month").diff(dayjs().startOf("month"), "months")
      : 0,
  ),
  withHandlers({
    next: p => () => p.setOffset(p.offset + 1),
    prev: p => () => p.setOffset(p.offset - 1),
    higherMode: p => () => {
      if (p.mode === modes.month) {
        const m = dayjs().add(p.offset, "months")
        const off = m.startOf("year").diff(dayjs().startOf("year"), "years")
        p.setOffset(off)
        return p.setMode(modes.year)
      }
      if (p.mode === modes.year) {
        const diff = startOfDecade(p.offset) - startOfDecade(0)
        p.setOffset(diff === 0 ? 0 : diff / 10)
        return p.setMode(modes.decade)
      }
    },
    handleChangeYear: p => v => {
      p.setOffset(v)
      p.setMode(modes.month)
    },
    handleChangeDecade: p => v => {
      p.setOffset(v)
      p.setMode(modes.year)
    },
  }),
)(Calendar)

import React from "react"
import AppLogo from "components/AppLogo"
import Portal from "components/Portal"
import CrossLightIcon from "icons/appkit/outline-close"
import { localize } from "locale"
import { IconWrap } from "styledComponents/Icons"
import { MenuBody, MenuHeader, MenuWrap } from "./styled"
import withSelectTenant from "hoc/withSelectTenant"
import { withRouter } from "react-router"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import Group from "./Group"

const Menu = ({ profile, tenants, isOpen, close, submit }) => {
  if (!isOpen) return null

  const options = tenants.filter(x => x.id !== profile.tenantId)

  const central = options.filter(x => x.isCentral)
  const local = options.filter(x => !x.isCentral && !x.isClient)
  const client = options.filter(x => !x.isCentral && x.isClient)

  return (
    <Portal>
      <MenuWrap>
        <MenuHeader>
          <AppLogo name={localize("changeSites")} />
          <IconWrap onClick={close}>
            <CrossLightIcon />
          </IconWrap>
        </MenuHeader>
        <MenuBody>
          <Group items={central} onSelect={submit} />
          <Group title="PwC sites" items={local} onSelect={submit} />
          <Group title="Client sites" items={client} onSelect={submit} />
        </MenuBody>
      </MenuWrap>
    </Portal>
  )
}

export default R.compose(
  withRouter,
  withUrls,
  withSelectTenant(p => {
    p.history.push(p.urls.home)
    window.location.reload()
  }),
)(Menu)

import React from "react"

const withOnMount = cb => C => p => {
  React.useEffect(() => {
    cb(p)
  }, []) // eslint-disable-line

  return <C {...p} />
}

export default withOnMount

import * as R from "ramda"
import { eqArrays } from "common/array"

export const showMoveSubquestionToStart = (moveIndex, questionId, indices) =>
  moveIndex &&
  moveIndex.type === "subquestion" &&
  moveIndex.questionId === questionId &&
  R.last(moveIndex.indices) !== 0 &&
  eqArrays(moveIndex.indices.slice(0, -1), indices)

export const showMoveSubquestion = (
  moveIndex,
  questionId,
  movingThis,
  indices,
) =>
  !movingThis &&
  moveIndex &&
  moveIndex.type === "subquestion" &&
  moveIndex.questionId === questionId &&
  R.last(moveIndex.indices) !== R.last(indices) + 1 &&
  eqArrays(moveIndex.indices.slice(0, -1), indices.slice(0, -1)) &&
  moveIndex.indices.length === indices.length

import React from "react"
import withSubmit from "hoc/withSubmit"
import { FavoriteButtonWrap } from "./styled"
import IconStar from "icons/Star"

const FavoriteButton = ({ isFavorite, large, submit }) => (
  <FavoriteButtonWrap isFavorite={isFavorite} large={large} onClick={submit}>
    <IconStar active={isFavorite} />
  </FavoriteButtonWrap>
)

export default withSubmit({
  onSubmit: p => () => p.request(!p.isFavorite),
  onSuccess: p => () => p.onSuccess(),
})(FavoriteButton)

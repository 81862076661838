import * as R from "ramda"

export const getHandleToggle =
  (value, onChange, countries) => (template, libItem) => {
    // the existing option with same country
    const option = value.find(x => x.countryCode === template.countryCode)

    const getNewVal = () => {
      // no template with this country selected, can turn it on
      if (!option) {
        return value.concat({
          templateId: template.id,
          libraryItemId: libItem?.id,
          countryName: countries.find(
            x => x.countryCode === template.countryCode,
          ).countryName,
          countryCode: template.countryCode,
        })
      }

      // we got here means country is in use, following cases possible
      // 1) diff templ id, out with old val, in with new
      const i = value.findIndex(x => x.countryCode === template.countryCode)
      if (option.templateId !== template.id) {
        return R.over(
          R.lensPath([i]),
          R.mergeLeft({
            templateId: template.id,
            libraryItemId: libItem?.id,
          }),
          value,
        )
      }

      // onwards template id must be same
      // 2) diff library item selected
      if (libItem?.id !== option?.libraryItemId) {
        // we clicked the template itself, so turn it off
        if (!libItem) {
          return value.filter(x => x.templateId !== template.id)
        }

        return R.set(R.lensPath([i, "libraryItemId"]), libItem.id, value)
      }

      // 3) same template, same library item, turn if off
      if (libItem) {
        return R.set(R.lensPath([i, "libraryItemId"]), undefined, value)
      }

      return value.filter(x => x.templateId !== template.id)
    }

    return onChange(getNewVal())
  }

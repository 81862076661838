export const getFieldOptions = (allCatalogs, values) => {
  if (!values) return []

  return values
    .map(x => {
      const regValue = allCatalogs.find(y => y.id === x.registryCatalogId)
      return {
        ...x,
        name: regValue.fieldName,
        value: x.value,
        registryValueId: x.id,
      }
    })
    .filter(x => x.value)
}

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const TextareaToggle = styled.div`
  border: 1px solid #d8d8d8;
  padding: 0 10px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e5e5e5;
  color: #333;
  cursor: pointer;

  svg {
    fill: #333;
    height: 20px;
    width: 20px;
  }

  ${p =>
    p.active &&
    css`
      background: ${theme.color.red};
      color: #fff;

      svg {
        fill: #fff;
      }
    `};
  ${p =>
    p.space &&
    css`
      margin-right: 10px;
    `};
`

import React from "react"
import { NotificationListWrap } from "./styled"
import List from "./List"
import { notificationsRequest } from "data/notifications/api"
import withSubmit from "hoc/withSubmit"
import withData from "hoc/withData"
import { notificationStatuses } from "data/notifications/statuses"
import ConfirmModal from "./ConfirmModal"
import * as R from "ramda"
import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"
import withUrls from "hoc/withUrls"
import { emptyRequest, execute } from "common/rxjs"

const NotificationList = ({
  data,
  setData,
  loading,
  submit,
  response,
  setResponse,
  clearNotification,
  refetch,
}) => (
  <>
    <NotificationListWrap padding={data}>
      <List
        notificationsLoading={loading}
        data={data}
        clearAll={() => setData([])}
        clearNotification={clearNotification}
        markAsRead={submit}
        refetch={refetch}
      />
    </NotificationListWrap>
    {response && (
      <ConfirmModal
        close={() => setResponse(null)}
        notification={R.path(["notification"], response)}
        status={R.path(["data", "status"], response)}
        data={R.path(["data", "data"], response)}
      />
    )}
  </>
)

export default R.compose(
  withData(() => notificationsRequest.list(), {
    noLoader: true,
  }),
  withState("response", "setResponse", null),
  withHandlers({
    clearNotification: p => id => {
      p.setData(p.data.filter(x => x.id !== id))
    },
  }),
  withUrls,
  withSubmit({
    onSubmit:
      () =>
      ({ notification, silent }) => {
        if (silent) {
          execute(notificationsRequest.markAsRead(notification.id))
          return emptyRequest
        }

        return notificationsRequest.markAsRead(notification.id)
      },
    onSuccess:
      p =>
      (res, { notification, silent }) => {
        if (silent) {
          return p.clearNotification(notification.id)
        }

        if (res.status !== notificationStatuses.ok) {
          return p.setResponse({
            data: res,
            notification,
          })
        }

        if (notification.redirectUrl) {
          p.history.push({
            pathname: notification.redirectUrl,
            state: { fromNotification: true },
          })
          p.close()
        }
        p.clearNotification(notification.id)
      },
  }),
)(NotificationList)

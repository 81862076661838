import React from "react"
import withData from "hoc/withData"
import { languageListRequest } from "data/countries/api"
import Select from "inputs/Select"
import * as R from "ramda"

const LanguageSelect = ({ data, ...rest }) => (
  <Select
    {...rest}
    options={R.sortBy(R.prop("englishName"), data).map(x => ({
      ...x,
      value: x.code,
      name: x.englishName,
    }))}
  />
)

export default withData(() => languageListRequest(), {
  cacheKey: () => "languages",
})(LanguageSelect)

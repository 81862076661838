import { get, put } from "common/request"

export const mappingRequest = {
  schemas: templateId => get(`/api/template/${templateId}/schemas`),
  setSchemas: (templateId, data) =>
    put(`/api/template/${templateId}/schemas`, data),
  mappings: (templateId, schemaId) =>
    get(`/api/template/${templateId}/mappings?schemaId=${schemaId}`),
  setMapping: (templateId, data) =>
    put(`/api/template/${templateId}/mappings`, data),
  getPrecondition: (templateId, schemaId) =>
    get(`/api/template/${templateId}/schema/${schemaId}/precondition`),
  setPrecondition: (templateId, schemaId, data) =>
    put(`/api/template/${templateId}/schema/${schemaId}/precondition`, data),
}

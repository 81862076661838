import React from "react"
import Textfield from "inputs/Textfield"
import Checkbox from "inputs/Checkbox"
import Panel from "components/Panel"
import SearchIcon from "icons/Search"
import { CountryWrap } from "./styled"
import { Box } from "styledComponents/Containers"
import Empty from "components/Empty"
import InfoModal from "components/InfoModal"
import { P } from "styledComponents/Typography"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { getHandleChange } from "./helpers"

const CountryFilter = ({
  value,
  onChange,
  countries,
  multi,
  validCountryCodes,
  readOnly,
}) => {
  const [text, setText] = React.useState("")
  const [emptyCountry, setEmptyCountry] = React.useState("")

  const handleChange = getHandleChange({
    value,
    onChange,
    validCountryCodes,
    multi,
    setEmptyCountry,
  })

  const filteredData = countries.filter(x =>
    x.countryName.toLowerCase().includes((text || "").toLowerCase()),
  )

  return (
    <Panel bg="#F2F2F2">
      <Textfield
        value={text}
        onChange={setText}
        placeholder="All countries"
        icon={SearchIcon}
        small
        transparent
        clearable
        readOnly={readOnly}
      />
      <Box mt={15}>
        {filteredData.length > 0 ? (
          filteredData.map(x => {
            const isAdded = x.numberOfLinkedObjects > 0

            return (
              <CountryWrap
                key={x.countryCode}
                onClick={
                  readOnly ? undefined : () => handleChange(x.countryCode)
                }
                pt={multi ? 5 : 10}
                pb={multi ? 5 : 10}
                flex
                acenter
              >
                {multi && (
                  <Checkbox
                    value={value.includes(x.countryCode)}
                    squared
                    mr={10}
                    readOnly
                    gray={readOnly}
                  />
                )}
                <P bold={isAdded || (!multi && x.countryCode === value)}>
                  {x.countryName}
                </P>
                {isAdded && (
                  <P bold gray ml="auto">
                    {x.numberOfLinkedObjects}
                  </P>
                )}
              </CountryWrap>
            )
          })
        ) : (
          <Empty />
        )}
      </Box>
      {emptyCountry && (
        <InfoModal
          title="Error"
          body={
            <>
              <P>
                No published templates for{" "}
                {
                  countries.find(x => x.countryCode === emptyCountry)
                    .countryName
                }{" "}
                found.
              </P>
              <P>Please contact the template team.</P>
              <ModalButtons>
                <Button text="Close" onClick={() => setEmptyCountry()} />
              </ModalButtons>
            </>
          }
          close={() => setEmptyCountry()}
        />
      )}
    </Panel>
  )
}

export default CountryFilter

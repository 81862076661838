import React from "react"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"

const ExpandableText = ({ text, limit = 100 }) => {
  const { isOpen, open, close } = useOpen()

  if (text.length < limit) {
    return <P>{text}</P>
  }

  if (isOpen) {
    return (
      <>
        <P>{text}</P>
        <P mt={10} red link hovernolink onClick={close}>
          Hide more
        </P>
      </>
    )
  }

  return (
    <>
      <P>{text.slice(0, limit)}...</P>
      <P mt={10} red link hovernolink onClick={open}>
        View more
      </P>
    </>
  )
}

export default ExpandableText

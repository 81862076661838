import styled, { css } from "styled-components/macro"

export const OptionWrapper = styled.div`
  ${p =>
    p.maxHeight &&
    css`
      max-height: ${p.maxHeight}px;
      overflow: auto;
    `};
  ${p =>
    p.inline &&
    css`
      display: flex;
      flex-wrap: wrap;
    `};
`

import dayjs from "dayjs"
import * as R from "ramda"

export const isValid = (validator, value, questionType) => {
  const getValue = (value, subType, questionType) => {
    if (subType === "ValueEur") return R.propOr("", "eur", value)
    if (subType === "ValueLocal") return R.propOr("", "local", value)
    if (questionType === "Name") {
      if (subType === "FirstName") return R.propOr("", "firstName", value)
      if (subType === "MiddleName") return R.propOr("", "middleName", value)
      if (subType === "LastName") return R.propOr("", "lastName", value)

      return [
        R.prop("firstName", value),
        R.prop("middleName", value),
        R.prop("lastName", value),
      ]
        .filter(x => x)
        .join(" ")
    }
    return value || ""
  }
  const t = validator.type
  const n = validator.name
  const p = validator.parameters
  const v = getValue(value, validator.subType, questionType)

  // if types are wrong, return invalid
  if ((t === "int" || t === "decimal") && Number.isNaN(+v)) return false

  if (n === "Length") return v.length === +p
  if (n === "MinLength") return v.length >= +p
  if (n === "MaxLength") return v.length <= +p
  if (n === "Pattern") return new RegExp(`^${p}$`).test(v)
  if (n === "Enumeration") return p.includes(v)
  if (n === "MinimumInclusive") {
    if (t === "date")
      return dayjs(p).isSame(dayjs(v)) || dayjs(p).isBefore(dayjs(v))
    return +p <= +v
  }
  if (n === "MaximumInclusive") {
    if (t === "date")
      return dayjs(p).isSame(dayjs(v)) || dayjs(p).isAfter(dayjs(v))
    return +p >= +v
  }
  if (n === "MinimumExclusive") {
    if (t === "date") return dayjs(p).isBefore(dayjs(v))
    return +p <= +v
  }
  if (n === "MaximumExclusive") {
    if (t === "date") return dayjs(p).isAfter(dayjs(v))
    return +p >= +v
  }
  if (n === "TotalDigits") {
    return (
      v
        .toString()
        .split("")
        .filter(x => x !== "." && x !== ",").length <= p
    )
  }
  if (n === "Fraction" || n === "FractionDigits") {
    const split = R.pipe(R.split("."), R.chain(R.split(",")))(v.toString())

    const isDecimalPartZeroes = () => {
      const uniqDecimalPart = R.uniq(split[1].split(""))
      const zeroDecimalPart =
        uniqDecimalPart[0] === "0" && uniqDecimalPart.length === 1
      return zeroDecimalPart
    }

    return split.length === 1 || split[1].length <= p || isDecimalPartZeroes()
  }

  return false
}

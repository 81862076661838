import React from "react"
import Tutorial from "../Tutorial"
import Empty from "components/Empty"
import * as R from "ramda"

const SearchResults = ({ v, categories }) => {
  const results = R.chain(R.prop("tutorials"), categories).filter(t => {
    const reg = new RegExp(v, "i")
    return reg.test(t.name) || reg.test(t.description)
  })

  return results.length === 0 ? (
    <Empty />
  ) : (
    <>
      {results.map(t => (
        <Tutorial tutorial={t} key={t.id} />
      ))}
    </>
  )
}

export default SearchResults

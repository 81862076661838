import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { conclusionRequest } from "data/templates/api"
import withModal from "hoc/withModal"
import withSubmit from "hoc/withSubmit"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import * as R from "ramda"

const ConfirmDeleteModal = ({ close, submit }) => (
  <>
    <P>{localize("doYouWantToDeleteReasoning")}</P>
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="No" />
      <Button
        danger
        type="button"
        onClick={submit}
        text={localize("yesDelete")}
      />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(() => localize("deleteReasoning")),
  withSubmit({
    onSubmit: p => () =>
      conclusionRequest.deleteReasoning(p.templateId, p.reasoningId),
    onSuccess: p => () => {
      p.close()
      p.onSuccess()
    },
  }),
)(ConfirmDeleteModal)

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentRequest } from "data/assessments/api"
import withModal from "hoc/withModal"
import withSubmit from "hoc/withSubmit"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import withUrls from "hoc/withUrls"
import { preassessmentRequest } from "data/preassessments/api"
import { getCaseUrl } from "data/cockpit/redirect"

const ConfirmWithdrawModal = ({ close, submit, isPreassessment }) => (
  <>
    <P mb={20}>
      {isPreassessment ? "Pre-assessment" : "Assessment"} will be put back into
      the "In progress" state and{" "}
      {isPreassessment ? "pre-assessment" : "assessment"} team can edit it.
    </P>
    <ModalButtons
      buttonText={`Are you sure you want to withdraw this ${
        isPreassessment ? "pre-" : ""
      }assessment?`}
    >
      <Button secondary type="button" onClick={close} text="No" />
      <Button danger onClick={submit} text="Yes, withdraw" />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(
    p =>
      `Withdraw ${p.isPreassessment ? "pre-" : ""}assessment ${
        p.assessment.name
      }`,
  ),
  withUrls,
  withSubmit({
    onSubmit: p => () =>
      (p.isPreassessment ? preassessmentRequest : assessmentRequest).withdraw(
        p.assessment.id,
      ),
    onSuccess: p => () => {
      p.close()
      p.onSuccess()
    },
    redirect: (_, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return getCaseUrl(p.match, p.assessment)
      }
    },
  }),
)(ConfirmWithdrawModal)

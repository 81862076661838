import React from "react"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"

const Empty = ({ setEditId, readOnly }) => (
  <Box flex column acenter>
    <P gray>No linked objects yet.</P>
    {!readOnly && (
      <Box>
        <P inline gray>
          Start by
        </P>{" "}
        <P inline link gray onClick={() => setEditId("new")}>
          adding new involved party
        </P>{" "}
        <P inline gray>
          first.
        </P>
      </Box>
    )}
  </Box>
)

export default Empty

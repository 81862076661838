import React from "react"
import Tabs from "components/Tabs"
import { assessmentRequest } from "data/assessments/api"
import { conclusionRequest } from "data/conclusions/api"
import { formatConclusionStatus } from "data/conclusions/formatters"
import { indexColorsByStatuses } from "data/conclusions/colors"
import withData from "hoc/withData"
import withPage from "hoc/withPage"
import * as R from "ramda"
import { Redirect, Route, Switch } from "react-router-dom"
import { Container } from "styledComponents/Containers"
import PageTitle from "components/PageTitle"
import { getSuburls } from "./suburls"
import TitleControl from "./TitleControl"
import ScrollToTop from "components/ScrollToTop"
import FavoriteButton from "components/FavoriteButton"
import { Box } from "styledComponents/Containers"
import { reportingRequest } from "data/reporting/api"
import withProps from "hoc/withProps"
import { getSuburls as getCaseSuburls } from "pages/CaseCreatePage/suburls"
import CaseTitle from "components/CaseTitle"
import { caseRequest } from "data/cockpit/api"
import useOpen from "hooks/useOpen"
import { preassessmentRequest } from "data/preassessments/api"

const ConclusionsDetailPage = ({
  history,
  match,
  location,
  assessment,
  template,
  conclusion,
  reporting,
  profile,
  statuses,
  fetchCount,
  refetch,
  urls,
  fromCase,
  case_,
  refetchData,
  refetchCase,
  caseUrls,
  cancelUrl,
  isPreassessment,
}) => {
  const suburls = getSuburls(match, conclusion, R.path(["status"], assessment))

  const {
    isOpen: isBrowserOpen,
    open: openBrowser,
    close: closeBrowser,
  } = useOpen()

  return (
    <>
      <Container>
        {conclusion && (
          <>
            {!fromCase && assessment.breadcrumbs && (
              <CaseTitle
                objectId={assessment.id}
                assessment={assessment}
                case_={case_}
                urls={urls}
                location={{
                  pathname: `/${profile.tenantId}/case-create/${assessment.breadcrumbs.case.id}/detail`,
                }}
                history={history}
                refetchCase={refetchData}
                suburls={getCaseSuburls(
                  {
                    params: {
                      id: assessment.breadcrumbs.case.id,
                    },
                    path: `/${profile.tenantId}/case-create/:id`,
                    url: `/${profile.tenantId}/case-create/${assessment.breadcrumbs.case.id}`,
                  },
                  openBrowser,
                  case_,
                )}
                isBrowserOpen={isBrowserOpen}
                closeBrowser={closeBrowser}
                collapsed
              />
            )}
            <PageTitle
              title={
                <Box flex acenter>
                  {!isPreassessment && (
                    <FavoriteButton
                      isFavorite={conclusion.favorite}
                      request={assessmentRequest.setFavorite(conclusion.id)}
                      large
                      onSuccess={refetch}
                    />
                  )}
                  <Box ml={10}>{conclusion.name}</Box>
                </Box>
              }
              status={{
                color: indexColorsByStatuses(statuses)[conclusion.status],
                text: formatConclusionStatus(conclusion.status),
                rejected: conclusion.isRejectedConclusion,
              }}
              secondaryStatus={conclusion.reportingStatus}
            >
              <TitleControl
                assessment={assessment}
                conclusion={conclusion}
                refetch={refetch}
                refetchCase={() => {
                  refetchData()
                  refetchCase?.()
                }}
                isPreassessment={isPreassessment}
                case_={case_}
              />
            </PageTitle>
          </>
        )}
        <Tabs
          options={R.values(suburls).map(x => ({
            ...x,
            value: x.url,
            disabled: x.disabled,
          }))}
          value={location.pathname}
          onChange={v => history.push(v)}
          inPanel
          prefixActive
        />
        <Switch>
          {R.values(suburls).map(x => {
            const Component = x.component
            // either it is new template and it comes from  url query string
            // or it comes from assesment detail, in case of edit
            return (
              <Route
                key={x.path}
                path={x.path}
                render={p => (
                  <Component
                    {...p}
                    assessmentId={+match.params.id}
                    conclusionId={+match.params.id}
                    caseUrls={caseUrls}
                    assessment={assessment}
                    template={template}
                    conclusion={conclusion}
                    reporting={reporting}
                    profile={profile}
                    refetch={refetch}
                    fetchCount={fetchCount}
                    canApprove={assessment.canApprove}
                    cancelUrl={cancelUrl}
                    isPreassessment={isPreassessment}
                    {...x.props}
                  />
                )}
              />
            )
          })}
          <Redirect to={suburls.conclusion.url} />
        </Switch>
      </Container>
      <ScrollToTop />
    </>
  )
}

export const EnhancedConclusionsDetailPage = R.compose(
  withData(
    p => {
      if (p.isPreassessment) {
        return preassessmentRequest.detail(p.match.params.id, p.case_.id)
      }

      return assessmentRequest.detail(p.match.params.id)
    },
    {
      dataKey: "assessment",
      // no tab yet, it will redirect to first one
      skip: p => p.match.url === p.location.pathname,
      refetchParams: p => [p.location.pathname],
    },
  ),
  withProps(p => ({ refetchAssessment: p.refetchData })),
  withData(
    p => {
      if (p.isPreassessment) {
        return preassessmentRequest.conclusionDetail(
          p.match.params.id,
          p.case_.id,
        )
      }
      return conclusionRequest.detail(p.match.params.id)
    },
    {
      dataKey: "conclusion",
      // no tab yet, it will redirect to first one
      skip: p => p.match.url === p.location.pathname,
      refetchParams: p => [p.location.pathname],
    },
  ),
  withProps(p => ({ refetchConclusion: p.refetchData })),
  withData(p => assessmentRequest.templateDetail(p.assessment.templateId), {
    skip: p => !p.assessment,
    refetchParams: p => [R.path(["assessment", "id"], p)],
    dataKey: "template",
  }),
  withData(p => reportingRequest.conclusionReporting(p.match.params.id), {
    dataKey: "reporting",
    skip: p => p.isPreassessment,
  }),
  withProps(p => ({
    refetch: () => {
      if (!p.isPreassessment) {
        // refetch reporting
        p.refetchData()
      }

      // refetch conclusion
      p.refetchConclusion()
    },
  })),
  withData(() => conclusionRequest.listTypes(), {
    dataKey: "statuses",
    skip: p => p.match.url === p.location.pathname,
    refetchParams: p => [p.location.pathname],
  }),
  withData(p => caseRequest.detail(p.assessment.breadcrumbs.case.id), {
    skip: p => !p.assessment?.breadcrumbs?.case.isClickable,
    // case is a reserved word
    dataKey: "case_",
  }),
)(ConclusionsDetailPage)

export default withPage()(EnhancedConclusionsDetailPage)

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M0,0 L0,7.269 L1.5,7.269 L1.5,1.5 L7.269,1.5 L7.269,0 L0,0 Z M16.731,0 L16.731,1.5 L22.5,1.5 L22.5,7.269 L24,7.269 L24,0 L16.731,0 Z M22.5,16.731 L22.5,22.5 L16.731,22.5 L16.731,24 L24,24 L24,16.731 L22.5,16.731 Z M0,16.731 L0,24 L7.269,24 L7.269,22.5 L1.5,22.5 L1.5,16.731 L0,16.731 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"
import InfoModal from "components/InfoModal"

const NameWithModal = ({ name }) => {
  const { isOpen, open, close } = useOpen()

  return (
    <>
      <P onClick={open}>{name}</P>
      {isOpen && (
        <InfoModal
          close={close}
          title="Warning"
          text="You don't have access to the selected case as you are not a coworker of this case"
        />
      )}
    </>
  )
}

export default NameWithModal

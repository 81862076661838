import React from "react"
import ArrowUp from "icons/ArrowUp"
import { fromEvent } from "rxjs"
import { ScrollToTopWrap } from "./styled"
import { scrollToTop } from "common/scroll"
import { throunceTime } from "common/rxjs"

const ScrollToTop = () => {
  const [show, setShow] = React.useState(false)

  React.useEffect(() => {
    const el = document.getElementById("pageContent")
    const sub = fromEvent(el, "scroll")
      .pipe(throunceTime(200))
      .subscribe(e => setShow(e.target.scrollTop > 300))
    return () => sub.unsubscribe()
  }, []) // eslint-disable-line

  if (!show) return null

  return (
    <ScrollToTopWrap onClick={scrollToTop}>
      <ArrowUp />
    </ScrollToTopWrap>
  )
}

export default ScrollToTop

// prettier-ignore
export default {
  // calendar
  "daysOfWeekArray": ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
  "monthsArray": ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"],
  "months": "Months",

  // roles
  "siteAdmin": "Site admin",
  "centralTeam": "Central team",
  "templateUser": "Template user",
  "user": "User",

  // general words
  "appName": "PwC DAC 6 Compliance Solution",
  "appNameSupportive": "PwC DAC 6 Compliance Solution Supportive Information",
  "changeSites": "Change sites",
  "search": "Search",
  "general": "General",
  "noResults": "No results",
  "listOfAll": "List of all",
  "done": "Done",
  "doneOn": "Done on",
  "created": "Created",
  "createdOn": "Created on",
  "createdBy": "Created by",
  "deleted": "Deleted",
  "deletedOn": "Deleted on",
  "status": "Status",
  "lastAction": "Last action",
  "lastEdit": "Last edit",
  "name": "Name",
  "owner": "Owner",
  "edit": "Edit",
  "add": "Add",
  "type": "Type",
  "none": "None",
  "disallowed": "Disallowed",
  "approve": "Approve",
  "filledBy": "Filled by",
  "email": "E-mail",
  "cancel": "Cancel",
  "close": "Close",
  "save": "Save",
  "copy": "Copy",
  "create": "Create",
  "clearAll": "Clear all",
  "selectAll": "Select all",
  "selectAllDisplayed": "Select all displayed",
  "yes": "Yes",
  "no": "No",
  "custom": "Custom",
  "logout": "Logout",
  "ok": "Ok",
  "submit": "Submit",
  "previous": "Previous",
  "next": "Next",
  "reject": "Reject",
  "modify": "Modify",
  "build": "Build",
  "history": "History",
  "n/a": "N/A",
  "addComment": "Add comment",
  "addYourComment": "Add your comment",
  "makeACopy": "Make a copy",
  "optional": "Optional",
  "required": "Required",
  "confirm": "Confirm",
  "attachment": "Attachment",
  "attachments": "Attachments",
  "notify": "Notify",
  "siteSummary": "Site summary",
  "years": "Years",
  "uploading": "Uploading...",
  "from": "from",
  "to": "to",
  "statuses": "Statuses",
  "types": "Types",
  "continue": "Continue",

  //forms
  "fieldRequired": "This field is required",
  "noWhitespaceOnly": "Must contain non-empty characters",
  "stringMaxLength": x => `Must be under ${x} characters`,
  "correctEmail": "Must be correct email address",
  "correctUrl": "Must be correct URL",
  "mustStartWithHttps": "URL must start with http or https",
  "minLength": (n) => `Must be at least ${n} characters long`,
  "minLengthArray": (n, item) => `Must contain at least ${n} ${item}`,
  "maxLengthArray": (n, item) => `Must contain ${n} ${item} at most`,
  "exactLengthArray": (n, item) => `Must contain ${n} ${n > 1 ? `${item}s` : `${item}`}`,
  "validDomain": "Must be valid email domain",
  "validNumber": "Must be valid number",
  "greaterThanZero": "Must be greater than zero",
  "isInteger": "Must be integer",

  // entities
  "conclusion": "Conclusion",
  "conclusions": "Conclusions",
  "reports": "Reports",
  "assessment": "Assessment",
  "assessments": "Assessments",
  "tasks": "Tasks",
  "templates": "Templates",
  "templatePool": "Template pool",
  "localTemplates": "Local templates",
  "template": "Template",
  "users": "Users",
  "site": "Site",
  "pwcSite": "PwC site",
  "pwcSites": "PwC sites",
  "clientSites": "Client sites",
  "overview": "Overview",
  "contact": "contact",
  "downloadCenter": "Download center",

  // tenant selection
  "pleaseSelectTheSiteYouWantToLogIn": "Please select the site you want to log in",


  // registry
  "enterTheNameOfField": "Enter the name of field",
  "deleteField": "Delete field",
  "pleaseNoteIfTheFieldIsDeleted": "Please note, if the field is deleted, the value in all entries related to this field will be deleted.",
  "doYouWantToDeleteField": "Do you want to delete the field?",
  "dateYYYYMMDD": "Date, YYYY-MM-DD",
  "countryCodeISO": "ISO Code, 2 characters",
  "freetextSingle": `Freetext`,
  "freetextMultipleRecords": `Freetext, Possible to add multiple records - add comma "," to separate records`,
  "entities": "Entities",
  "individuals": "Individuals",
  "setUp": "Set up",
  "importData": "Import data",
  "addNewEntry": "Add new entry",
  "newEntry": "New entry",
  "mandatoryFields": "Mandatory fields",
  "optionalFields": "Optional fields",
  "addNewOptionalField": "Add new optional field",
  "multipleRecordsAllowed": "Multiple records allowed",
  "deleteEntry": "Delete entry",
  "theEntryWillBeDeleted": "The entry will be deleted and cannot be restored.",
  "doYouWantToDeleteTheEntry": "Do you want to delete the entry?",
  "importEntities": "Import entities",
  "importIndividuals": "Import individuals",
  "importEntitiesFailed": "Import entities failed",
  "importIndividualsFailed": "Import individuals failed",
  "importFileRequirements": "Import file requirements:",
  "registryImportCriteria1": `CSV file (.CSV extension), separator ";" with the header (containing name of the attributes from metamodel management).`,
  "registryImportCriteria2": `In case of multiple values in one column, separate them with comma ",".`,
  "registryImportCriteria3": `Keep the structure of the uploaded CSV file defined in "Set up".`,
  "registryImportCriteria4": "Max size 5MBs.",
  "registryImportCriteria5": "Max records less or equal to 3000 entites (rows) per upload.",
  "registryImportCriteria6": `Values cannot contain comma "," or semicolon ";" (these are used as separators of columns and multiple values in one column).`,

  // compare tool
  "compareToolLinks": "Compare tool links",
  "N/A": "N/A",

  // create assessments
  "createANewAssessment": "Create a new assessment",
  "arrangementId": "Internal arrangement ID",
  "showFavorites": "Show favorites",
  "showMA": "Show MA",
  "showOverdue": "Show overdue",
  "assesmentSettings": "Assessment settings",
  "newAssessment": "New assessment",
  "nameOfAssessment": "Name of assessment",
  "selectFromAvailableTemplates": "Select from available templates",
  "dueDate": "Due date",
  "estimatedDueDateAssessment": "Estimated due date of the assessment",
  "publishedTime": "Published time",
  "applicableFrom": "Applicable from",
  "applicableTo": "Applicable to",
  "useThisTemplate": "Use this template",
  "editor": "Editor",
  "approver": "Approver",
  "addCoworker": "Add coworker",
  "saveAndNext": "Save and next",
  "firstName": "First name",
  "middleName": "Middle name",
  "lastName": "Last name",
  "youHaveUnsavedAnswer": "You have unsaved answer. Do you want to save it?",
  "yesSave": "Yes, save",
  "noDiscard": "No, discard",
  "help": "Help",
  "link": "Link",
  "noInfoAvailable": "No information available for this question",
  "attachedFiles": "Attached files",
  "download": "Download",
  "usedTemplateDetails": "Used template details",
  "attachAFile": "Attach a file",
  "assessmentCopyPlaceholder": "Enter assessment name...",
  "assessmentCopySuccess": x => `Assessment ${x} copied successfully`,
  "deleteAssessment": `Delete`,
  "delegateAssessment": "Delegate assessment",
  "anotherUserAlreadyAnswered": "Another user already filled the answer",
  "answerWasMeanwhileModified": "Answer was meanwhile modified again. Discard and Save again to compare your answer to the latest one.",
  "yourAnswer": "Your answer",
  "currentlySavedAnswer": "Currently saved answer",
  "discard": "Discard",
  "saveYourAnswer": "Save your answer",
  "keepCurrentlySavedAnswer": "Keep currently saved answer",
  "emailNotFoundInTenant": "The searched email address is not found in this site. Please contact your local admin for access.",
  "showOnlyBookmarkedQuestions": "Show only bookmarked questions",
  "settings": "Settings",
  "evaluationQuestions": "Evaluation questions",
  "reportingDecisions": "Reporting decisions",
  "reportingQuestions": "Reporting questions",
  "reportingPreview": "Reporting preview",
  "reason": "Reason",
  "downloadPDF": "Download PDF",
  "downloading": "Downloading",
  "pdfIsBeingGeneratedPleaseWait": "PDF is being generated please wait",
  "statusSetTo": "Status set to",
  "XMLreportPreview": "XML report preview",
  "previewXML": "Preview XML",
  "PDFreportPreview": "PDF report preview",
  "showOnly": "Show only",
  "newValue": "New value",
  "oldValue": "Old value",
  "LastApprover": "There must always be at least one approver assigned to assessment.",
  "continueAssessment": "Please select if you would like to continue with the latest saved question?",
  "comments": "Comments",
  "addNewComment": "Add new comment",
  "noComments": "No comments",
  "deleteYourComment": "Delete your comment",
  "doYouWantToDeleteYourComment": "Do you want to delete your comment?",
  "commentDeleted": "Comment deleted",
  "daysBeforeDueDate": "Days before due date",
  "addMoreAnswers": "Add more answers to",
  "noAnswers": "No answers",
  "marketableArrangement": "Marketable arrangement",
  "estimatedReportingDate": "Estimated reporting date for the conclusion",
  "review": "Review",
  "reasonForNotToReport": "Reason for not to report",
  "enterReason": "Enter reason for not to report",
  "approversSection": "Approvers section",
  "reviewersSection": "Reviewers section",
  "pleaseSpecifyNameOfReviewer": "Please specify the name of the reviewer in the comment.",
  
  // validation and conclusion preview tab
  "conclusionPreview": "Conclusion preview",
  "conclusionPreviewUnavailable": "Conclusion cannot be previewed right now.",
  "reportingNotSupportedForYourCountry": "Reporting is not supported for your country, please submit the assessment to proceed.",
  "reportingNotRequiredForYourConclusionType": "Reporting is not required for your conclusion type, please submit the assessment to proceed.",
  "reportingIsRequiredForYourConclusionType": "Reporting is required for your conclusion type.",
  "reportingIsRequiredForYourConclusionTypeSelectNextStep": "Reporting is required for your conclusion type. Please select your next step.",
  "proceedWithReportGeneration": "Proceed with report generation",
  "proceedWithoutReportGeneration": "Proceed without report generation",
  "remindMeOn": "Remind me on",
  "notes": "Notes",
  "pleaseSelectTheReportYouWantToReport": "Please select the report(s) you want to report",
  "upo": "UPO (Confirmation of receipt)",
  "nsp": "NSP (Tax scheme no.)",

  // submit assessment
  "thereAreXErrorsInQuestions": x => `There are ${x} errors in questions.`,

  // restore
  "recycleBin": "Recycle bin",
  "restoreAll": "Restore all",
  "restore": "Restore",

  // restore assessments
  "restoreDescriptionAssessments": "All assessments will be restored to \"In Progress\" status, and all relevant coworkers will receive tasks and notifications.",
  "restoreAllAssessments": "Do you want to restore all assessments?",
  "restoreAssessment": "Restore assessment",
  "restoreTextAssessment": x => `Assessment ${x} will be restored to "In Progress" status, and all relevant coworkers will receive tasks and notifications.`,
  "restoreTextAssessmentQuestion": "Do you want to restore the assessment?",
  "restoreTextSuccessAssessment": x => `You have restored the assessment ${x}.`,
  "restoreAllAssessmentsSuccess": "You have restored all assessments.",
  
  // empty bin
  "emptyBin": "Empty bin",
  "emptyBinQuestion": "Do you want to empty the recycle bin?",
  "emptyBinDescriptionAssessments": "All assessments in bin will be permanently deleted and cannot be restored.",
  "emptyBinDescriptionTemplates": "All templates in bin will be permanently deleted and cannot be restored.",
  
  // delete assessment permanently
  "deletePermanently": "Delete permanently",
  "permanentlyDeleteAssessment": "Permanently delete assessment",
  "permanentlyDeleteAssessmentDesc": x => `Assessment ${x} will be permanently deleted and cannot be restored.`,
  "permanentlyDeleteAssessmentQuestion": `Do you want to permanently delete the assessment?`,
  "deletePermanentlyAllAssessmentsSuccess": "You have permanently deleted all assessments in bin.",
  "permanentlyDeleteAssessmentSuccess": x => `You have permanently deleted the assessment ${x}`,
  
  // reports
  "downloadXmlReportFile": "Download XML report file",
  "selectStatus": "Select status",

  // conclusions
  "conclusionStatus": "Conclusion status",
  "taxAuthorityIds": "Tax authority IDs",
  "noIdsGenerated": "No IDs generated",
  "reportingIsNotSaved": "Reporting is not saved.",
  "internalLocalReportingIds": "Internal local reporting IDs",
  "regenerateAssessment": "Regenerate assessment",
  "assessmentQuestions": "Assessment questions",
  "coworkers": "Coworkers",
  "exportConclusions&Reports": "Export conclusions & reports",
  "exportAllConclusions&Reports": "Export all conclusions & reports",
  "reporting": "Reporting",
  "downloadReportXML": "Download report (XML)",
  "uploadYourReportToYourLocalReportingTool": "Upload your report to your local reporting tool via",
  "reportingToolUrl": "https://mdr.mf.gov.pl",
  "exportIsBeingGenerated": "Export is being generated. You will receive email and app notifications once it is ready for you in the Download center.",
  "allConclusionStatuses": "All conclusion statuses",
  "allConclusions": "All conclusions",
  "conclusionType": "Conclusion type",
  "reportingStatus": "Reporting status",
  "nearestDueDate": "Nearest due date",
  "changeStatuses": "Change statuses",
  "cannotBeSetTo": "Cannot be set to",
  "selected": "Selected",
  "deselectAll": "Deselect all",
  "includedConclusions": "Included conclusions",
  "reportId": "Report ID",
  "filingEntityId": "Filing entity ID",
  "filingEntityName": "Filing entity name",
  "createReport": "Create report",
  "approvedOn": "Approved on",
  "pleaseSelectWhatFile": "Please select what file you want to download",
  "pleaseSelectWhichFormat": "Please select in which format you want to download the conclusions",
  "pdfConclusionAssessment": "PDF (conclusion and assessment)",
  "pleaseSpecifyTheCreationRangeOfTheAssessments": "Please specify the creation date range of the assessments for which the conclusions should be exported:",
  "excel":  "Excel",
  "json":  "JSON",
  "export": "Export",
  "exports": "Exports",
  "wholePackageSingle": "Whole package (conclusion and assessment and files) (*.zip)",
  "conclusionCopySuccess": x => `Conclusion ${x} copied successfully`,
  "preserveArrangementId": "Preserve internal arrangement ID",
  "preserveArrangementIdTooltip": "(By checking the box, the new copy will take over the same internal arrangement ID from the current assessment. You may check the box if the new copy will be used for the same arrangement. Otherwise please leave it unchecked, and a different arrangement ID will be generated in the copy by the system.)",
  "rejectConclusion": "Reject conclusion",
  "withdraw": "Withdraw",
  "withdrawConclusion": "Withdraw conclusion",
  "permanentlyDeleteConclusionSuccess": x => `You have permanently deleted the conclusion ${x}`,
  "yesWithdraw": "Yes, withdraw",
  "rejectionWillRevokeConclusion": "The rejection will revoke the current conclusion. A new assessment with the same internal arrangement ID will be automatically created for the same assessment team to work on.",
  "conclusionReview": "Conclusion review",
  "pleaseConfirmThatYouHavePerformedTheConclusionReview": "Please confirm that you have performed the conclusion review and did not find any issues or mistakes.",
  "conclusionHasBeenReviewedSuccessfully": x => `Conclusion ${x} has been reviewed successfully.`,
  "conclusionHasBeenWithdrawnSuccessfully": x => `Conclusion ${x} has been withdrawn (reverted back to "In progress" assessment for correction).`,
  "areYouSureYouWantToWithdrawConclusion": "Are you sure you want to withdraw this conclusion?",
  "areYouSureYouWantToRejectConclusion": "Are you sure you want to reject this conclusion?",
  "conclusionRejectedSuccess": (x) => `Conclusion ${x} has been rejected and a new assessment ${x} with the same internal arrangement ID has been generated for correction.`,
  "rejected": "Rejected",
  "linkExpiration": "Link expiration",
  "generated": "Generated",
  "conclusionExportHasStarted": "Conclusion export has started. You will receive a notification when the file is ready for download.",
  "noReportingInfosAvailable": "No reporting infos available",
  "idSearch": "ID search",
  "enterId": "Enter ID",
  "clear": "Clear",

  // dashboard
  "valueOfArrangement": "Value of arrangement",
  "quickActions": "Quick actions",
  "createNew": "Create new",

  // logout page
  "youWereLoggedOut": "You were logged out",

  // not found page
  "notFound": "Not found",
  "goHome": "Go home",

  // users page
  "noUserAdded": "No user added yet. Try to search to find a user!",
  "addedUsers": "Added users",
  "userWasChanged": x => `User ${x} was changed.`,
  "userWasAdded": "User was added.",
  "assignedPwcSites": "Assigned PwC sites",
  "addToANewSite": "Add to a new PwC site",
  "noRole": "No role",
  "role": "Role",
  "centralRole": "Central role",
  "allRoles": "All roles",
  "allSites": "All sites",
  "addUser": "Add user",
  "addOtherPwcOfficeUsers": "Add other PwC office users",
  "importLocalPwcUsers": "Import local PwC users",
  "importLocalUsers": "Import local users",
  "importPwcUsers": "Import PwC users",
  "uploadOfPwcUsersHasFailed": "Upload of PwC users import file failed",
  "invalidImportFile": "Invalid import file. Please check the import file for following",
  "importCriteria1": "file has valid CSV format with file extension .csv",
  "importCriteria2": "file size is lower than 5 MB",
  "importCriteria3": "number of records in file is lower or equal to 30 000",
  "yourImportHasBeenScheduled": "Your import has been scheduled and will be performed at 1:00 am the next day. You will receive the import result upon the completion.",
  "findPwcEmployee": "Find PwC employee",
  "noUserAddedYet": "No user added yet",

  // tenants page
  "country": "Country",
  "contacts": "Contacts",
  "createNewPwcSite": "Create new PwC site",
  "editPwcSite": "Edit PwC site",
  "selectCountry": "Select country",
  "enterName": "Enter name",
  "totalContacts": "Total contacts",
  "totalSiteAdmins": "Total site admins",
  "totalEmailDomains": "Total email domains",
  "surname": "Surname",
  "dataRetentionPeriod": "Data retention period",
  "retentionPeriodWarning": "Please note, all assessments / conclusions / reports, and their respective history will be permanently deleted once the retention period expires.",
  "assignedUsers": "Assigned users",
  "reviewer": "Reviewer",
  "selectAsApprover": "Select as approver",
  "reviewApprovalTeamRole": "Review/Approval team role",
  "allUsers": "All users",
  "assessmentTeam": "Assessment team",
  "disableSite": "Disable site",
  "disableSiteWarning": "Disabling / enabling a site will impact all users' access to this site. Notifications will be sent to all impacted users.",
  "changeCountryWarning": "Changing country will impact all users' access to this site and template pool permission access matrix.",
  "responsiblePwcFirm": "Responsible PwC firm",
  "createNewClient": "Create new client site",
  "emailDomain": "Email domain",
  "emailDomains": "Email domains",
  "enterDomain": "Enter domain",
  "permanentlyDeleteSite": "Permanently delete site",
  "siteWillBePermanentlyDeleted": "Site will be permanently deleted and CANNOT be restored.",
  "doYouWantToPermanentlyDeleteSite": "Do you want to permanently delete the site?",
  "iUnderstandThatAllData": "I understand that all data (incl. the backups) on this site will be permanently deleted and CANNOT be restored.",
  "permanentlyDeleteUser": "Permanently delete user",
  "doYouWantToPermanentlyDeleteUser": "Do you want to permanently delete the user?",
  "iUnderstandThatUser": "I understand, that the user will be permanently deleted from the site of the application. The linkage between the user and all his/ her working objects (template, assessments, conclusions and reports) and the defined teams (review team and reporting team) will be terminated, and cannot be restored even if the user is imported again.",

  // templates page
  "youAreAboutToChangeCountry": "You are about to change the currently defined country. This change will delete the existing mapping.",
  "createANewTemplate": "Create a new template",
  "validation": "Validation",
  "checkValidations": "Check validations",
  "checkReportingValidations": "Check reporting validations",
  "thereAreXErrorsInQuestionsAndConclusions": x => `There are ${x} errors in questions and conclusions.`,
  "language": "Language",
  "everythingIsCorrectYouCanSubmitTemplate": "Everything is correct, you can submit the template now.",
  "thereAreXErrorsInTemplate": x => `There are ${x} errors which have to be solved before you can submit the template.`,
  "publish": "Publish",
  "applicableTimePeriod": "Applicable time period",
  "applicableTimePeriodStart": "Applicable time period start",
  "applicableTimePeriodEnd": "Applicable time period end",
  "newTemplate": "New template",
  "editTemplate": "Edit template",
  "rejectTemplate": "Reject template",
  "approveTemplate": "Approve template",
  "youApprovedTemplate": x => `You have approved the template ${x}.`,
  "nameOfTemplate": "Name of template",
  "description": "Description",
  "templateLanguage": "Template language",
  "selectLanguage": "Select language",
  "addLinkForDescription": "Add link for description",
  "addUrl": "Add URL",
  "contactPerson": "Contact person",
  "addContactPerson": "Add contact person",
  "onlyOnePersonCanBeAdded": "Only one person can be added",
  "approvers": "Approvers", 
  "addApprover": "Add approver",
  "noEmailInTenant": "The searched email address is not found in this site. You may amend access rights in user management.",
  "templateSettings": "Template settings",
  "questions": "Questions",
  "enterYourQuestion": "Enter your question",
  "enterYourSubquestion": "Enter your sub-question",
  "typeOfAnswer": "Type of answer",
  "selectTypeOfTheAnswer": "Select type of the answer",
  "supportiveInformation": "Supportive information",
  "noSupportiveInformation": "No supportive information",
  "enterSupportiveInformation": "Enter supportive information",
  "links": "Links",
  "nameOfTheLink": "Name of the link",
  "enterNameOfTheLink": "Enter name of the link",
  "linkType": "Link type",
  "selectFromDacCompareTool": "Select from DAC6 compare tool",
  "addANewLink": "Add a new link",
  "compareTool": "Compare tool",
  "url": "URL",
  "enterTheUrl": "Enter the URL",
  "addQuestion": "Add question",
  "addSubquestion": "Add sub-question",
  "addSection": "Add section",
  "attachFiles": "Attach files",
  "mandatoryAnswer": "Mandatory",
  "forReportingOnly": "For reporting only",
  "selectDate": "Select date",
  "freeText": "Free text",
  "singleChoice": "Single choice",
  "confirmation": "Confirmation",
  "countryArrangement": "Country (arrangement)",
  "countryArrangementLabel": "This answer type is to specify the countries participating in the tax agreement. Answers to this question may have potential impact to the reporting and data exchange.",
  "countryOther": "Country (other)",
  "countryOtherLabel": "This answer type is used for other purposes. (e.g. address, place of birth, residence, etc.) but NOT to be used for countries participating in the tax arrangement.",
  "multipleAnswers": "Multiple answers",
  "date": "Date",
  "answers": "Answers",
  "uploadFile": "Upload file?",
  "emailAddress": "Email address",
  "addAnswer": "Add answer",
  "addNoneOfTheAbove": "Add \"None of the above\"",
  "other": "Other",
  "noneOfTheAbove": "None of the above",
  "youMayTranslateNoneOfTheAbove": `You may translate "none of the above" to any desired language, but its functional meaning will not change.`,
  "delete": 'Delete',
  "author": 'Author',
  "publisher": 'Publisher',
  "addSubsection": "Add subsection",
  "yesDelete": "Yes, delete",
  "original": "Original",
  "translation": "Translation",
  "section": "Section",
  "subsection": "Subsection",
  "question": "Question",
  "subquestion": "Sub-question",
  "inputTranslationHere": "Input translation here",
  "confirmDelete-section": "All subsections and questions which are included in this section will be deleted as well.",
  "confirmDelete-subsection": "All subsections and questions which are included in this section will be deleted as well.",
  "mustBeEmpty-section": "Cannot delete this Section because it is not empty.",
  "mustBeEmpty-subsection": "Cannot delete this Subsection because it is not empty.",
  "doYouWantToDeleteThis": "Do you want to delete this",
  "moveSectionHere": "Move section here",
  "moveSubsectionHere": "Move subsection here",
  "moveQuestionHere": "Move question here",
  "pasteQuestionHere": "Paste question here",
  "pasteSubsectionHere": "Paste sub-section here",
  "pasteSectionHere": "Paste section here",
  "moveSubquestionHere": "Move sub-question here",
  "noSubquestions": "No subquestions",
  "noPrecondition": "No precondition",
  "precondition": "Precondition",
  "subquestions": "Subquestions",
  "noSubquestionDefined": "No subquestion defined",
  "editable": "Editable",
  "templateWillBeRejected": "Rejection will revoke the previous approval and allow you to edit the template. You need to submit it again for approval.",
  "areYouSureYouWantToRejectEditTemplate": "Do you want to reject and edit the template?",
  "notificationWillBeSentTo": "Notification will be sent to:",
  "areYouSureYouWantToRejectTemplate": "Do you want to reject the template?",
  "yesReject": "Yes, reject",
  "yesApprove": "Yes, approve",
  "reasonForRejection": "Reasons and comments:",
  "writeTheReason": "Write the reason...",
  "doYouWantToApproveTemplate": "Do you want to approve this template?",
  "areYouSureYouWantToLeave": "Are you sure you want to leave? Unsaved progress will be lost",
  "mustBeAfterApplicablePeriodStart": "Date must be after applicable period start",
  "templateApproversInfoText": "Assign approvers for template approval. (2 approvers have to be assigned, only people with the authority should be entered.)",
  "templateRejected": x => `The template ${x} was rejected.`,
  "templateRejectedNowEdit": x => `The template ${x} was rejected. Now you can edit the template and submit it again.`,
  "incompleteSubquestions": "One or more subquestions is missing question type",
  "incompleteOptions": "One or more answers is missing text",
  "tooLongOptions": "One or more answers exceeded the 50 000 character limit",
  "incompleteSubquestionOptions": "One or more subquestion answers is missing text",
  "incompleteLinks": "Fields Name of the link and Link cannot be empty",
  "updateDescription": "Update description",
  "templateEdit": "Template edit",
  "templateEditWarning": "Amendment of the approved/published template is only allowed for immaterial changes as it does not require additional approval process. Changes will be real-time reflected in unconcluded assessments and the export files. All local team members will be notified.",
  "pleaseNoteQuestionContainsLogic": "Please note the question contains pre-condition logic. You may amend if needed.",
  "pleaseNoteQuestionContainsLogicIsReferenced": "Please note the question contains/is referenced by pre-condition logic. You may amend if needed.",

  // template mapping
  "mapping": "Mapping",
  "version": "Version",
  "mappingDisabled": "Mapping disabled",
  "element": "Element",
  "unsavedItem": "Unsaved item",
  "selectReportsWhichYouWantToInclude": "Select reports which you want to include",
  "selectVersion": "Select version",
  "noSchemaIsAvailable": "No schema is available",
  "noSchemaIsEnabled": "No schema is enabled",
  "youAreAboutToChangeTheSchemaVersion": "You are about to change the schema version. If you select a newer version, you would need to map all its elements to relevant questions / options. If you select a previously mapped schema, the previous mapping can be restored.",
  "notice": "Notice",
  "reportTypeXMLFileGenerationNotSupported": "report type XML file generation is not supported.",
  "useReportTypeIf": x => `Use ${x} report type if`,
  "selectTheElementYouWantToMap": "Select the element you want to map",
  "selectTemplateQuestionSubquestionAnswer": "Select template question / sub-question / answer",
  "valueEur": "Value eur",
  "valueLocal": "Value local",
  "useXReportTypeIf": x => `Use ${x} report type if`,
  "noPreconditionIsSet": "No precondition is set",
  "allInformationHasBeenProvided": "All information has been provided, please proceed with the next step.",

  // delete template
  "deleteTemplate": `Delete`,
  "templateWillBeDeleted": `The template will be deleted. You may permanently delete it or restore it from the recycle bin.`,
  "doYouWantToDeleteTheTemplate": "Do you want to delete the template?",
  "templateDeletedSuccess": x => `You have deleted the template ${x}.`,

  // delete template permanently
  "permanentlyDeleteTemplate": "Permanently delete template",
  "permanentlyDeleteTemplateDesc": x => `Template ${x} will be permanently deleted and cannot be restored.`,
  "permanentlyDeleteTemplateQuestion": `Do you want to permanently delete the template?`,
  "deletePermanentlyAllTemplatesSuccess": "You have permanently deleted all templates in bin.",
  "permanentlyDeleteTemplateSuccess": x => `You have permanently deleted the template ${x}`,

  // restore templates
  "restoreDescriptionTemplates": "All templates will be restored to \"In Progress\" status, and all relevant coworkers will receive tasks and notifications.",
  "restoreAllTemplates": "Do you want to restore all templates?",
  "restoreTemplate": "Restore Template",
  "restoreTextTemplate": x => `Template ${x} will be restored to "In Progress" status, and all relevant coworkers will receive tasks and notifications.`,
  "restoreTextTemplateQuestion": `Do you want to restore the template?`,
  "restoreTextSuccessTemplate": x => `You have restored the template ${x}.`,
  "restoreAllTemplatesSuccess": "You have restored all templates.",

  // template conclusions
  "reasoning": "Reasoning",
  "reasonings": "Reasonings",
  "header": "Header",
  "footer": "Footer",
  "disclaimer": "Disclaimer",
  "moveReasoningHere": "Move reasoning here",
  "conclusionText": "Conclusion text",
  "deleteReasoning": "Delete reasoning",
  "doYouWantToDeleteReasoning": "Do you want to delete this reasoning?",
  "reasoningText": "Reasoning text",
  "enterReasoningText": "Enter reasoning text",
  "showConclusionIf": "Show conclusion if",
  "showReasoningIf": "Show reasoning if",
  
  // statuses
  "InProcess": "In progress",
  "InProgress": "In progress",
  "RequestForApproval": "Ready for approval",
  "ReadyForApproval": "Ready for approval",
  "Approved": "Approved",
  "Published": "Published",
  "Deactivated": "Deactivated",
  "Deleted": "Deleted",
  "ConclusionDeleted": "Deleted",
  "ConclusionApproved": "Approved",
  "ConclusionRejected": "Rejected",
  "ConclusionReadyForReview": "Ready for review",
  "ConclusionReviewed": "Reviewed",
  "ConclusionReadyForGeneration": "Ready for generation",
  "ConclusionReadyForReporting": "Ready for reporting",
  "ReportSubmitted": "Submitted",
  "ReportAcceptedByTA": "Accepted by TA",
  "ReportRejectedByTA": "Rejected by TA",
  "LibraryInDraft": "In draft",
  "LibraryInReview": "In review",
  "LibraryPublished": "Published",
  "LibraryDeactivated": "Deactivated",

  // submit template
  "submitTemplate": "Submit template",
  "templateWillBeSentForApproval": "The template will be sent for approval to the below approvers:",
  "moreThanTwoApprovers": "There are more than 2 approvers included. By clicking \"Yes, submit\", all the listed approvers will receive notifications and tasks.",
  "modifyApprovers": "Modify approvers",
  "yesSubmit": "Yes, submit",
  "doYouWantToSubmitTheTemplate": "Do you want to submit the template?",
  "youCannotSubmit": "You cannot submit",
  "templateSubmittedSuccessfully": x => `Template ${x} was submitted successfully.`,

  // publish template
  "publishTemplate": "Publish template",
  "alreadyPublishedTo": "Already published To",
  "publishTemplateToSites": "Publish template to sites",
  "youPublishedTemplate": x => `You have published the template ${x}.`,
  "templateWillBePublished": "The template will be published to the below sites:",
  "doYouWantPublishTemplate": "Do you want to publish the template?",
  
  // template testing
  "testing": "Testing",
  "templateTestingDescription": "You may create test files to check the template logic and its corresponding assessment look. You may create multiple test files for different use cases. Please only use dummy data for test files.",
  "createANewTestFile": "Create a new test file",
  "testFileName": "Test file name",
  "nameOfTheTestFile": "Name of the test file",
  "goBackToTemplate": "Go back to template",
  "deleteTestFile": "Delete test file",
  "doYouWantToDeleteTestFile": "Do you want to delete test file?",
  "testFileSuccessfullyDeleted": "Test file successfully deleted",
  "everythingIsCorrectYouMayCheckConclusion": "Everything is correct, you may check for conclusion preview now.",
  "uploadNotAvailableInPreview": "The attachment function is only available in formal assessments but not under preview mode.",
  "notAvailable": "Not available",

  // question logic
  "operation": "Operation",
  "relation": "Relation",
  "answer": "Answer",
  "is": "Is",
  "isNot": "Is Not",
  "and": "And",
  "or": "Or",
  "showQuestionIf": "Show question if",
  "logicPreview": "Logic preview",
  "sectionIsEmpty": "Section is empty",
  "notSelected": "Not selected",
  "pleaseNoteThatYouHaveEnteredAndAndOr": `Please note that you have entered "And" and "Or" on the same level. By default, the system calculation priority is shown as below. If this is in line with your intention, please click "Save" to proceed, otherwise please amend your logic setting above.`,
  "logicHelpModal1": "Please note the following rules when setting up the preconditions:",
  "logicHelpModal2": "1. Options of normal Questions cannot be referenced in the Customs question type and vice versa.",
  "logicHelpModal3": "2. Within a Customs type question, only options of the same or upper level sub questions can be referenced.",
  "logicHelpModal4": "3. It is not possible to use the options of the sub questions in the conclusion and reporting precondition.",
  "logicHelpModal5": "You may find more detailed information and examples in the tutorial.",
  "theSubquestionWillBeDeletedWithAllLowerLevelSubquestions": "The subquestion will be deleted with all lower level subquestions",
  "deleteSubquestion": "Delete subquestion",
  "doYouWantToDeleteTheSubquestion": "Do you want to delete the subquestion",

  // deactivate template
  "deactivate": "Deactivate",
  "deactivateTemplate": x => `Deactivate template ${x}`,
  "deactivateTemplateQuestion": "Do you want to deactivate the template?",
  "thisTemplateWillBeDeactivated": "The template will be deactivated and archived. Notification will be sent to all sites which the template was published to.",
  "yesDeactivate": "Yes, deactivate",
  "deactivateTemplateSuccess": x => `You have deactivated the template ${x}.`,
  "reasonForTemplateDeactivation": "Reasons and comments:",
  "reasonForTemplateDeactivationPlaceholder": "Enter reasons and comments...",

  // navigation
  "allCaughtUp": "All caught up!",
  "notifications": "Notifications",
  "showMore": "Show more",
  "notifsApprovalIsNotPossible": "Approval request declined",
  "notifsApprovalIsAlreadyProvided": "Approval process completed",
  "AssessmentRejected": (x, y) => `Assessment ${x} has been rejected by ${y}, and is not subject to your approval. `,
  "AssessmentApproved": (x, y) => `Assessment ${x} has already been approved by ${y[0]} and ${y[1]}. You were redirected to the conclusion.`,
  "TemplateRejected": (x, y) => `Template ${x} was already rejected by ${y[0]}.`,
  "TemplateApproved": (x, y) => `Template ${x} was already approved by ${y[0]} and ${y[1]}.`,

  // error page
  "error": "Error",
  "serverError": "Server error",
  "noAccess": "You do not have the access.",
  "noRoleAssigned": "You do not have any role assigned yet.",
  "noModuleError": "You no longer have access to this site. Please refresh the page.",
  "contactSupportForAccess": "Please contact your PwC support for access.",

  // warnings
  "warning": "Warning",

  // errors
  "LastSiteAdmin": "At least one user has to remain Site admin",
  "accessToSiteDenied": "Access to site denied",
  "Assessment is not a conclusion.": "You are trying to access a conclusion which is no longer a conclusion.",
  "unknownError": "Unknown error occured.",
  "networkError": "Network error, check your connection.",
  "LastCentralAdmin": "At least one user has to remain Central Admin.",
  "IncompatibleRole": "This role is incompatible for this user",
  "pwc_users_assigned_as_admins": "The flag CanTransferAdminToPwC cannot be set to false because there are PwC users with Client admin role. They must be removed first.",
  "screenSizeError": "This page is not optimized for mobile devices. Please use bigger tablets or desktops.",
  "appHasCrashed": "Application has crashed",
  "Duplicate country": "Country must be unique.",
  "Duplicate client": "This client already exists.",
  "TemplateNotInProcess": "Template is not in process, please refresh the page.",
  "TemplateNotInRequestForApproval": "Template is no longer awaiting approval, please refresh the page.",

  // tables
  "of": "of",
  "items": "items",
  "page": "page",

  // loader
  "loading": "Loading...",
  "pleaseWait": "Please wait!",

  // footer
  "imprint": "Imprint",
  "termsOfUse": "Terms of use",

  // overview
  "overviewQuickActions": "Quick actions",
  "overviewQuickActionsReportsIntroBold": "Reporting section will be implemented once details are specified in the local laws.",
  "overviewQuickActionsReportsIntro": "Please contact your local expert team for more information.",
  "overviewOverdue": "Overdue",
  "overviewStatus": "Status",
  "arrangementCountry": "Country",
  "arrangementTotal": "Total",
  "total": "Total",
  "selectSiteInWhich": x => `Please select in which site you want to create new ${x}.`,
  "overdueAssessments": "Overdue assessments",
  "numberOfAssessments": "Number of assessments",
  "statistics": "Statistics",
  "current": "Current",
  "today": "Today",
  "dateRange": "Date range",
  "setDateRange": "Set date range",
  "reset": "Reset",
  "period": "Period",
  "apply": "Apply",
  "chooseDateFrom": "Choose date from:",
  "chooseDateTo": "Choose date to:",
  "allTypes": "All types",
  "all": "All",
  "tenant": "Tenant",
  "allCountries": "All countries",

  // tasks
  "myTasks": "My tasks",
  "action": "Action",
  "Edit": "Edit",
  "Approve": "Approve",
  "reportingWillCome": "Reporting section will be implemented once details are specified in the local laws.",
  "reportingContact": "Please contact your local expert team for more information",

  // joyride
  "helpCenter": "Help center",
  "noTutorialProvided": "No tutorial provided.",

  // tutorials page
  "tutorial": "Tutorial",
  "tutorials": "Tutorials",
  "deleteTutorial": "Delete tutorial",
  "createNewTutorial": "Create a new tutorial",
  "nameOfTutorial": "Name of tutorial",
  "selectTutorialCategory": "Select tutorial category",
  "category": "Category",
  "visibleTo": "Visible to",
  "doYouWantToDeleteTheTutorial": "Do you want to delete the tutorial?",
  "tutorialAdded": "Tutorial added",
  "tutorialSaved": "Tutorial saved",
  "tutorialDeleted": "Tutorial deleted",

  // site configuration
  "configuration": "Configuration",
  "registry": "Registry",
  "retentionPeriod": "Retention period",
  "reportingTeam": "Reporting team",
  "reportingSettings": "Reporting settings",
  "reviewTeam": "Review team",
  "reviewSettings": "Review settings",
  "siteSettings": "Site settings",
  "announcements": "Announcements",
  "enabled": "Enabled",
  "disabled": "Disabled",
  "members": "Members",
  "allowReportingTeam": "Allow reporting team",
  "addAUserToReportingTeam": "Add a user to reporting team",
  "allowReviewTeam": "Allow review team",
  "addAUserToReviewTeam": "Add a user to review team",
  "siteName": "Site name",
  "siteContacts": "Site contacts",
  "nameDescription": "Name/Description",
  "telephone": "Telephone",
  "emailDomainNotAllowed": "This email domain is not allowed.",
  "conclusionTypesWithReportingRelevance": "Conclusion types with reporting relevance.",

  //assessment library
  "myAssessments": "My assessments",
  "library": "Library",
  "assessmentLibrary": "Assessment library",
  "createLibraryItem": "Add to library",
  "addToLibModalTitle": name => `Add ${name} to library`,
  "addToLibModalPlaceholder": "Library item name",
  "addToLibModalNameLabel": "Name of the library item",
  "addToLibModalNameLabelConclusion": "Name of library item",
  "addToLibModalClearedLabel": "Following data will be cleared automatically",
  "addToLibModalClearedCoworkers": "Co-workers",
  "addToLibModalClearedComments": "All related comments",
  "addToLibModalClearedContact": "Contact person",
  "addToLibModalConfirmText": "Do you want to add the assessment to the library?",
  "addToLibModalConfirmTextConclusion": "Do you want to add the conclusion to the library?",
  "addToLibModalButtonYesAdd": "Yes, add",
  "labelManagement": "Label management",
  "showCreatedByMeOnly": "Show created by me",
  "labels": "Labels",
  "lastEdited": "Last edited",
  "descriptionLabel": "Description of the library item",
  "inputYourDescription": "Input your description (1000 characters max)",
  "addLabelFilter": "Add label filter",
  "addLabel": "Add label",
  "use": "Use",
  "assignedLabels": "Assigned labels",
  "itemHasBeenAdded": name => `${name} has been added to the library.`,
  "requestPublication": "Request the publication",
  "requestPublicationModalTitle": name => `${name} Request for publication to library`,
  "requestPublicationModalText": "The request for publication of the library item to the library will be sent to site admins. If approved, the library item will be available to all users on site.",
  "requestPublicationModalConfirm": "Do you want to request for publication?",
  "requestPublicationModalYesRequest": "Yes, request",
  "requestPublicationModalSuccess": name => `${name} has been requested for publication.`,
  "publishModalTitle": name => `Publication of ${name} into library`,
  "publishModalText": "The library item will be published in the  library and will become available for all users on the site to create assessments.",
  "publishModalConfirm": "Do you want to publish the library item to the library?",
  "publishModalYesPublish": "Yes, publish",
  "publishModalSuccess": name => `${name} has been published.`,
  "rejectModalTitle": name => `${name} Reject publication of  library item`,
  "rejectModalText": "The request for publishing the library item to the library will be rejected. User who requested the publication will be notified.",
  "rejectModalReasonLabel": "Rejection reason:",
  "rejectModalConfirm": "Do you want to reject the publication of the library item?",
  "rejectModalYesReject": "Yes, reject",
  "rejectModalSuccess": name => `The request for publication of ${name} has been rejected.`,
  "editModalTitle": name => `${name} Editing library item`,
  "editModalText": "The library item will be pulled back to “In review” status, where it can be edited. It won’t be longer available for users to create the assessments until re-published.",
  "editModalConfirm": "Do you want to edit the library item in the library?",
  "editModalYesEdit": "Yes, edit",
  "editModalSuccess": name => `${name} has been pulled back for edit.`,
  "deleteLibraryItemDesc": "The library item will be permanently deleted and cannot be restored.",
  "deleteLibraryItemConfirm": "Do you want to delete the library item?",
  "deleteLibraryItemSuccess": name => `${name} has been permanently deleted.`,
  "createAssessment": "Create assessment",
  "createAssessmentFromLibrary": "Create new assessment  from library",
  "createAssessmentKeepCoworkersLabel": "Do you want to keep the co-workers set up in the library item for the new assessment?",
  "createAssessmentRemoveCoworkers": "No, remove co-workers",
  "createAssessmentKeepCoworkers": "Yes, keep co-workers",
  "newAssessmentModalTitle": "New assessment creation",
  "newAssessmentModalText": "Please choose your preferred method to create new assessment:",
  "newAssessmentModalFromScratch": "Create from template (empty assessment)",
  "newAssessmentModalFromLibrary": "Create from library item (partly prefilled assessment)"
}

import * as R from "ramda"

export const breakpoints = {
  tablet: 1, //768,
  desktop: 2, //1024,
}

const theme = {
  color: {
    red: "#d04a02",
    success: "#006652",
    successLight: "#DCE8E7",
    successDark: "#116754",
    error: "#e0301e",
    errorLight: "#F9E4E1",
    errorDark: "#A82A22",
    border: "#d8d8d8",
    borderLight: "#e5e5e5",
    warning: "#ffa500",
    warningLight: "#f9ead4",
    warningDark: "#913511",
    pink: "#D83954",
    yellow: "#FFB500",
    orange: "#D04A02",
    blue: "#B3DCF9",
    shadow: "rgba(49, 57, 63, 0.2)",
  },
  graphColors: [
    "rgba(255, 99, 132, 0.5)",
    "rgba(54, 162, 235, 0.5)",
    "rgba(255, 206, 86, 0.5)",
    "rgba(75, 192, 192, 0.5)",
    "rgba(153, 102, 255, 0.5)",
    "rgba(255, 159, 64, 0.5)",
  ],
  breakpoints,
  mq: R.map(v => `@media (min-width: ${v}px)`, breakpoints),
}

export default theme

import { del, get, post, put } from "common/request"
import { encodeQueryParams } from "common/url"

export const preassessmentRequest = {
  list: caseId => get(`/api/preassessments/case/${caseId}`),
  listTemplates: () => get(`/api/preassessment/templates`),
  detail: (id, caseId) => get(`/api/preassessment/${id}/case/${caseId}`),
  createPreassessment: (caseId, data) =>
    post(`/api/preassessment/case/${caseId}`, data),
  edit: (id, data) => put(`/api/preassessment/${id}`, data),
  delete: (id, data) => put(`/api/preassessment/${id}/delete`, data),
  deletePermanent: data => post(`/api/preassessments/deletePermanent`, data),
  verify: id => get(`/api/preassessment/${id}/verify?lang=en`),
  submit: id => put(`/api/preassessment/${id}/submit?lang=en`),
  approve: id => put(`/api/preassessment/${id}/approve`),
  withdraw: id => put(`/api/preassessment/${id}/withdraw`),
  reject: (id, data) => put(`/api/preassessment/${id}/reject`, data),
  restore: data => post(`/api/preassessments/restore`, data),
  conclusion: id => get(`/api/preassessment/${id}/conclusions`),
  history: id => get(`/api/preassessment/${id}/history?lang=en`),
  questions: id => get(`/api/preassessment/${id}/questions`),
  lastPosition: id => get(`/api/preassessment/${id}/lastposition`),
  conclusionDetail: (id, caseId) =>
    get(`/api/preconclusion/${id}/case/${caseId}`),
  delegate: (id, data) => post(`/api/preassessment/${id}/delegate`, data),
  comments: (assessmentId, questionId) =>
    get(`/api/preassessment/${assessmentId}/question/${questionId}/comments`),
  getByArrangementId: (caseId, arrangementId) =>
    get(`/api/preassessment/case/${caseId}/arrangement/${arrangementId}`),
  exportDetailAsZip: (assessmentId, type) =>
    post(`/api/preassessment/${assessmentId}/fileExport`, {
      type,
    }),
  downloadCaseDescriptionFile: assessmentId => attachmentId =>
    get(
      `/api/preassessment/${assessmentId}/case-description/file/${attachmentId}`,
    ),
}

export const preassessmentQuestionRequest = {
  uploadAnswerFile: (assessmentId, questionId) => data =>
    post(`/api/assessment/${assessmentId}/question/${questionId}/file`, data, {
      doNotSetContentType: true,
    }),
  downloadAnswerFile: () => attachmentId =>
    get(`/api/preassessment/answer-attachment/${attachmentId}`),
  comments: (assessmentId, questionId) =>
    get(`/api/preassessment/${assessmentId}/question/${questionId}/comments`),
  addComment: (assessmentId, questionId, data) =>
    post(
      `/api/assessment/${assessmentId}/question/${questionId}/comment`,
      data,
    ),
  editComment: (assessmentId, questionId, commentId, data) =>
    put(
      `/api/assessment/${assessmentId}/question/${questionId}/comment/${commentId}`,
      data,
    ),
  deleteComment: (assessmentId, questionId, commentId) =>
    del(
      `/api/assessment/${assessmentId}/question/${questionId}/comment/${commentId}`,
    ),
}

export const preconclusionRequest = {
  delete: (id, data) => put(`/api/preconclusion/${id}/delete`, data),
  withdraw: id => data => put(`/api/preconclusion/${id}/withdraw`, data),
  deletePermanent: data => post(`/api/preconclusions/deletePermanent`, data),
  restore: data => put(`/api/preconclusions/restore`, data),
}

export const preassessmentApproverCommentRequests = assessmentId => ({
  comments: get(`/api/preassessment/${assessmentId}/approval/comments`),
  addComment: data =>
    post(`/api/preassessment/${assessmentId}/approval/comment`, data),
  deleteComment: id => del(`/api/preassessment/approval/comment/${id}`),
  editComment: (id, data) =>
    put(`/api/preassessment/approval/comment/${id}`, data),
  uploadFile: data =>
    post(`/api/preassessment/${assessmentId}/approval/comment/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: attachmentId =>
    get(`/api/preassessment/approval/comment/attachment/${attachmentId}`),
})

export const preassessmentCoworkersRequest = {
  list: (email, ids) =>
    get(
      `/api/preassessment/availablePersons${encodeQueryParams({
        filterEmail: email,
        excludeUserIds: ids,
      })}`,
    ),
  coworkers: id => get(`/api/preassessment/${id}/coworkers`),
  edit: (id, data) => post(`/api/preassessment/${id}/coworkers`, data),
}

export const preassessmentLibraryRequest = {
  addToLibrary: (assessmentId, data) =>
    post(`/api/preAssessmentLibrary/${assessmentId}/add`, data),
  conclusion: id => get(`/api/preAssessmentLibrary/${id}/conclusions`),
}

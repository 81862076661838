import { localize } from "locale"

export const nameSubtypes = [
  {
    subType: "FirstName",
    name: localize("firstName"),
  },
  {
    subType: "MiddleName",
    name: localize("middleName"),
  },
  {
    subType: "LastName",
    name: localize("lastName"),
  },
]

export const valueSubtypes = [
  {
    subType: "ValueEur",
    name: localize("valueEur"),
  },
  {
    subType: "ValueLocal",
    name: localize("valueLocal"),
  },
]

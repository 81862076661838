import React from "react"
import { downloadFile } from "common/files"
import Button from "components/Button"
import withFormField from "hoc/withFormField"
import withSubmit from "hoc/withSubmit"
import AttachFile from "icons/AttachFile"
import CrossIcon from "icons/Cross"
import { localize } from "locale"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import {
  FilepickerWrap,
  StyledLabel,
  // InfoIcon,
  HiddenInput,
} from "./styled"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"
import withData from "hoc/withData"
import { localSettingsRequest } from "data/siteConfiguration/api"
import InfoModal from "components/InfoModal"
import withState from "hoc/withState"
import useOpen from "hooks/useOpen"

const Filepicker = ({
  value,
  handleChange,
  handleRemove,
  handleOpen,
  readOnly,
  required,
  inline,
  singleFile,
  onlyUpload,
  onlyUploaded,
  gray,
  text = localize("attachAFile"),
  label,
  error,
  setError,
  data,
  accept,
}) => {
  const disabled = singleFile && value.length > 0
  const { isOpen, close } = useOpen()
  const allowed = accept ?? data.allowedExtensions

  return (
    <>
      <FilepickerWrap inline={inline} gray={gray}>
        {!onlyUpload &&
          value.map((x, xi) => (
            <Box flex mb={inline ? 0 : 10} ml={inline ? 10 : 0} key={xi}>
              {R.isNil(x.id) ? (
                <P red>
                  {x.fileName}{" "}
                  <P inline gray>
                    [new]
                  </P>
                </P>
              ) : (
                <P red link onClick={() => handleOpen(x)}>
                  {x.fileName}
                </P>
              )}
              {!readOnly && (
                <IconWrap onClick={handleRemove(xi)}>
                  <CrossIcon />
                </IconWrap>
              )}
            </Box>
          ))}
        {!readOnly && !onlyUploaded && (
          <Box flex>
            <StyledLabel>
              <Box flex acenter>
                <Button
                  asDiv
                  text={text}
                  secondary
                  disabled={disabled}
                  icon={AttachFile}
                />
                {!disabled && (
                  <HiddenInput
                    type="file"
                    onChange={handleChange}
                    accept={allowed.join(",")}
                    value=""
                  />
                )}
                {required && !label && <P required ml={10} />}
              </Box>
            </StyledLabel>
            {/* <Box ml={15} flex acenter onClick={open}>
              <InfoIcon>i</InfoIcon>
              <P gray>Allowed file formats</P>
            </Box> */}
          </Box>
        )}
      </FilepickerWrap>
      {isOpen && (
        <InfoModal
          title="Attach a file - file type and size requirements"
          body={
            <>
              <P mb={20}>
                Only following file extensions allowed: {allowed.join(", ")}.
              </P>
              <P>Max. allowed size is {data.fileSizeLimitText}.</P>
            </>
          }
          close={close}
        />
      )}
      {error && (
        <InfoModal
          title={error.title}
          body={<P>{error.body}</P>}
          close={() => setError()}
        />
      )}
    </>
  )
}

const getDefaultValue = p => {
  if (!p.value) {
    return []
  }

  if (p.singleFile) {
    return [p.value]
  }

  return p.value
}

export default R.compose(
  withFormField,
  withState("error", "setError"),
  withData(() => localSettingsRequest.fileRestrictions(), {
    cacheKey: () => "files",
  }),
  withProps(p => ({
    value: getDefaultValue(p),
  })),
  withSubmit({
    loadingText: localize("uploading"),
    onSubmit: p => v => p.upload(v.data),
    onSuccess: p => (res, v) => {
      const oldValue = p.value || []

      const newValue = {
        fileName: v.fileName,
        blobName: res,
      }

      p.onChange(
        p.singleFile
          ? // for single file, we're replacing it, so we always have 1 element array max
            newValue
          : oldValue.concat({
              fileName: v.fileName,
              blobName: res,
            }),
      )
    },
    onError: p => (_, err) => {
      p.setError({ title: "Attach a file - error", body: err.text })
    },
    errorText: () => () => null,
  }),
  withHandlers({
    handleRemove: p => i => e => {
      e.stopPropagation()
      e.preventDefault()
      p.onChange(p.singleFile ? undefined : R.remove(i, 1, p.value))
    },
    handleChange: p => e => {
      if (e.target.files.length === 0) {
        return
      }

      const f = e.target.files[0]

      if (f.size > p.data.fileSizeLimit) {
        return p.setError({
          title: `Attach a file - file is too big`,
          body: `Max. allowed size is ${p.data.fileSizeLimitText}.`,
        })
      }

      const allowed = p.accept ?? p.data.allowedExtensions
      const nameParts = f.name.split(".")
      if (!allowed.includes(`.${R.last(nameParts)}`.toLowerCase())) {
        return p.setError({
          title: `Attach a file - not allowed file type`,
          body: `Only following file extensions allowed: ${allowed.join(
            ", ",
          )}.`,
        })
      }

      const fd = new FormData()
      fd.append("file", f)
      p.submit({ data: fd, fileName: f.name })
    },
    handleOpen: p => f => {
      downloadFile(p.download(f.id), f.fileName)
    },
  }),
)(Filepicker)

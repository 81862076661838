export default () => (
  <svg width="16" height="15" viewBox="0 0 16 15" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M13.3028 2.19674C10.3741 -0.73192 5.62586 -0.73192 2.6972 2.19674C-0.232399 5.1254 -0.232399 9.87462 2.6972 12.8033C5.62586 15.7319 10.3741 15.7319 13.3028 12.8033C16.2324 9.87462 16.2324 5.1254 13.3028 2.19674ZM12.219 8.43775H8.93701V11.719H7.06206V8.43775L3.78149 8.43748V6.56254L7.06274 6.56275V3.24764L8.93774 3.2815V6.56275L12.219 6.56254V8.43775Z"
      fill="white"
    />
  </svg>
)

import React from "react"
import { IconWrap } from "../styled"
import { assessmentQuestionRequest } from "data/assessments/api"
import withSubmit from "hoc/withSubmit"
import OutlineMark from "icons/appkit/outline-mark"

const BookmarkButton = ({ active, submit }) => (
  <IconWrap active={active} onClick={submit}>
    <OutlineMark />
  </IconWrap>
)

export default withSubmit({
  onSubmit: p => () =>
    assessmentQuestionRequest.bookmark(p.assessmentId, p.questionId, !p.active),
  onSuccess: p => () => p.refetch(),
})(BookmarkButton)

import React from "react"
import withFormField from "hoc/withFormField"
import CrossIcon from "icons/Cross"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import {
  AbsoluteIconWrap,
  ReadonlyTextfield,
  StyledTextfield,
  TextfieldWrap,
} from "./styled"
import * as R from "ramda"
import { fromEvent } from "rxjs"
import withHandlers from "hoc/withHandlers"
import withState from "hoc/withState"

let timer

const Textfield = ({
  value,
  handleChange,
  icon: Icon,
  clearable,
  clear,
  small,
  readOnly,
  borderless,
  preventMenuKeyEvents,
  transparent,
  debounce,
  tmpValue,
  setTmpValue,
  round,
  activeBorder,
  width,
  ...rest
}) => {
  const sub = React.useRef(null)

  const setRef = ref => {
    if (!ref || sub.current) return

    sub.current = fromEvent(ref, "keydown").subscribe(e => {
      const k = e.key

      // this is to prevent being unable to navigate in textfield inside a modal
      if (k === "ArrowLeft" || k === "ArrowRight") {
        e.stopPropagation()
      }
      if (
        (preventMenuKeyEvents && k === "ArrowDown") ||
        k === "ArrowUp" ||
        k === "Enter"
      ) {
        e.preventDefault()
      }
    })
  }

  React.useEffect(() => {
    return () => {
      if (!sub.current) return

      sub.current.unsubscribe()
    }
  }, [])

  const v = value ?? ""

  React.useEffect(() => {
    setTmpValue(v)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [v])

  return (
    <TextfieldWrap
      small={small}
      borderless={borderless}
      transparent={transparent}
      edge2edge={rest.edge2edge}
      round={round}
      activeBorder={activeBorder}
      width={width}
    >
      {Icon && (
        <AbsoluteIconWrap static>
          <Icon />
        </AbsoluteIconWrap>
      )}
      {readOnly ? (
        <ReadonlyTextfield {...rest} hasIcon={!!Icon} small={small}>
          <P lightgray={!v}>{v || rest.placeholder}</P>
        </ReadonlyTextfield>
      ) : (
        <>
          <StyledTextfield
            {...rest}
            autoComplete="off"
            value={debounce ? tmpValue : v}
            small={small}
            onChange={handleChange}
            hasIcon={!!Icon}
            ref={setRef}
            transparent={transparent}
          />
          {clearable && v !== "" && (
            <IconWrap onClick={clear} data-id="input-clear">
              <CrossIcon />
            </IconWrap>
          )}
        </>
      )}
    </TextfieldWrap>
  )
}

export default R.compose(
  withFormField,
  withState("tmpValue", "setTmpValue", p => p.value),
  withHandlers({
    handleChange: p => e => {
      // this is used for datepicker for example, this can manipulate the value via clear, but not by typing
      if (p.noDirectChange) return

      const v = e.target.value
      if (!p.debounce) return p.onChange(v)

      // debounce is set
      p.setTmpValue(v)
      clearTimeout(timer)
      timer = setTimeout(() => {
        p.onChange(v)
      }, p.debounce)
    },
    clear: p => () => p.onChange(null),
  }),
)(Textfield)

import React from "react"
import Textfield from "inputs/Textfield"
import { caseRequest } from "data/cockpit/api"
import * as R from "ramda"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import Columns from "components/Columns"
import { ActiveIcon } from "styledComponents/Icons"
import { Box } from "styledComponents/Containers"
import withForm from "hoc/withForm"
import EditIcon from "icons/appkit/fill-edit"
import { strRequired } from "validators/string"

const AdditionalComments = ({ editId, setEditId, case: case_ }) => {
  const readOnly = !case_.canEdit

  return (
    <Columns widths={["auto", 50]}>
      <Box>
        <Textfield
          mt={20}
          name="additionalComments"
          label="Additional comments"
          value={"The title must correspond bla bla"}
          borderless={editId !== "comments" || readOnly}
          readOnly={editId !== "comments" || readOnly}
        />
        {editId === "comments" && (
          <ModalButtons>
            <Button
              secondary
              type="button"
              onClick={() => setEditId()}
              text="Cancel"
            />
            <Button type="submit" text="Save" />
          </ModalButtons>
        )}
      </Box>
      {!readOnly && (
        <Box mt={42}>
          <ActiveIcon onClick={() => setEditId("comments")} disabled={editId}>
            <EditIcon />
          </ActiveIcon>
        </Box>
      )}
    </Columns>
  )
}

export default R.compose(
  withForm({
    preventLoseProgress: true,
    mapProps: R.prop("case"),
    schema: R.always({
      additionalComments: strRequired(5000),
    }),
    onSubmit: p => v => caseRequest.additionalCommennts(p.caseId, v),
    onSuccess: p => () => {
      p.setEditId()
      p.refetch()
    },
  }),
)(AdditionalComments)

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const SetWrap = styled.div`
  border: 1px solid ${theme.color.border};
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 ${theme.color.shadow};

  ${p =>
    p.mb &&
    css`
      margin-bottom: ${p.mb}px;
    `};
`

export const SetHeader = styled.div`
  background: rgb(238, 238, 238);
  padding: 15px 10px;
`

export const SetBody = styled.div`
  padding: 10px;
`

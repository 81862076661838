import React from "react"
import Panel from "components/Panel"
import { formatConclusionStatus } from "data/conclusions/formatters"
import { conclusionErrorStatus } from "data/conclusions/statuses"
import { localize } from "locale"
import Conclusion from "pages/ConclusionsDetailPage/Conclusion"
import { P } from "styledComponents/Typography"

const ConclusionPreview = ({ conclusion, isValid, isPreassessment }) => {
  const title = (
    <P>
      <P inline>{localize("conclusionPreview")}</P>
      {isValid && (
        <>
          <P inline mr={5}>
            :
          </P>
          <P inline bold>
            {formatConclusionStatus(conclusion.status)}
          </P>
        </>
      )}
    </P>
  )
  return (
    <Panel title={title}>
      {isValid && conclusion.status !== conclusionErrorStatus ? (
        <Conclusion conclusion={conclusion} noPanel />
      ) : (
        <>
          <P bold mb={20}>
            {localize("conclusionPreviewUnavailable")}
          </P>
          <P>
            Please fix the errors and re-check the conclusion preview of your{" "}
            {isPreassessment ? "pre-" : ""}assessment.
          </P>
        </>
      )}
    </Panel>
  )
}

export default ConclusionPreview

import React from "react"
import OutlineComment from "icons/appkit/outline-comment"
import { IconWrap } from "../styled"
import CommentsModal from "./CommentsModal"
import useOpen from "hooks/useOpen"

const CommentsButton = ({
  refetch,
  questionId,
  assessmentId,
  index,
  hasComments,
  canEdit,
  questionRequests,
  isLibrary,
  isPreassessment,
}) => {
  const { isOpen, open, close } = useOpen()

  return (
    <>
      <IconWrap active={isOpen || hasComments} onClick={open}>
        <OutlineComment />
      </IconWrap>
      {isOpen && (
        <CommentsModal
          close={() => {
            close()
            refetch()
          }}
          assessmentId={assessmentId}
          questionId={questionId}
          index={index}
          canEdit={canEdit}
          questionRequests={questionRequests}
          isLibrary={isLibrary}
          isPreassessment={isPreassessment}
        />
      )}
    </>
  )
}

export default CommentsButton

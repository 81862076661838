import React from "react"
import { localize } from "locale"
import * as R from "ramda"
import Status from "components/Status"
import ArrangementId from "components/ArrangementId"
import Link from "components/Link"
import { formatDate } from "data/units/date/formatters"
import dayjs from "dayjs"
import theme from "theme"
import { statuses } from "data/assessments/statuses"
import { assessmentColors } from "data/assessments/colors"
import OptionBox from "components/OptionBox"
import IcoMore from "icons/IcoMore"
import { preconclusionColors } from "data/conclusions/colors"

export const config = (suburls, isDeletedTab, rowOptions, caseId) => ({
  columns: [
    {
      label: localize("arrangementId"),
      path: x => (
        <ArrangementId
          caseId={caseId}
          isPreassessment
          getLink={() => suburls.detail.url(x.id)}
          arrangementId={x.arrangementId}
        />
      ),
    },
    {
      label: localize("name"),
      path: x => (
        <Link ul to={`${suburls.detail.url(x.id)}/settings`}>
          {x.name}
        </Link>
      ),
      sortBy: R.propOr("", "name"),
      sortKey: "Name",
    },
    ...(isDeletedTab
      ? []
      : [
          {
            label: localize("status"),
            sortBy: R.propOr("", "status"),
            sortKey: "Status",
            path: x => (
              <Status
                color={assessmentColors[x.status]}
                text={localize(x.status)}
              />
            ),
          },
        ]),
    {
      label: "Conclusion type",
      path: x => (
        <Status
          color={preconclusionColors[x.conclusionType]}
          text={x.conclusionType}
        />
      ),
      sortBy: R.propOr("", "conclusionType"),
    },
    {
      label: "Contact person",
      path: x => [x.contactPersonFirstName, x.contactPersonLastName].join(" "),
    },
    {
      label: localize("createdOn"),
      sortBy: R.propOr("", "createdAtUtc"),
      path: R.pipe(R.prop("createdAtUtc"), formatDate),
    },
    {
      label: "Approved on",
      sortBy: R.propOr("", "approvedAtUtc"),
      path: R.pipe(R.prop("approvedAtUtc"), formatDate),
    },
    {
      label: localize("dueDate"),
      path: x => {
        const isLate = dayjs(x.dueDate).isBefore(dayjs(), "days")
        const showError = ![
          statuses.deleted.value,
          statuses.deactivated.value,
        ].includes(x.status)

        return (
          <Status
            color={isLate && showError && theme.color.error}
            text={formatDate(x.dueDate)}
          />
        )
      },
      sortBy: R.propOr("", "dueDate"),
      sortKey: "DueDate",
    },
    ...(isDeletedTab
      ? [
          {
            path: x => (
              <OptionBox
                options={rowOptions.map(option => {
                  return {
                    ...option,
                    onClick: () => option.onClick(x),
                  }
                })}
                icon={IcoMore}
              />
            ),
          },
        ]
      : []),
  ],
  filters: [],
})

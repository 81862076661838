import { localize } from "locale"
import Conclusion from "./Conclusion"
import Reporting from "./Reporting"
import AssessmentQuestions from "./AssessmentQuestions"
import Settings from "pages/AssessmentsCreatePage/Settings"
import Coworkers from "pages/AssessmentsCreatePage/Coworkers"
import Review from "components/Review"
import { statuses } from "data/assessments/statuses"
import * as R from "ramda"

export const conclusionSuburls = {
  conclusion: "conclusion",
  reporting: "reporting",
  questions: "questions",
  settings: "settings",
  coworkers: "coworkers",
  review: "review",
}

export const getSuburls = (match, conclusion, status) => {
  const path = match.path
  const url = match.url

  const result = {
    conclusion: {
      name: localize("conclusion"),
      path: path + "/conclusion",
      url: url + "/conclusion",
      component: Conclusion,
    },
    reporting: {
      name: localize("reporting"),
      path: path + "/reporting",
      url: url + "/reporting",
      component: Reporting,
    },
    questions: {
      name: localize("assessmentQuestions"),
      path: path + "/questions",
      url: url + "/questions",
      component: AssessmentQuestions,
    },
    settings: {
      name: localize("assesmentSettings"),
      path: path + "/settings",
      url: url + "/settings",
      component: Settings,
    },
    coworkers: {
      name: localize("coworkers"),
      path: path + "/coworkers",
      url: url + "/coworkers",
      component: Coworkers,
    },
    review: {
      name: localize("review"),
      path: path + "/review",
      url: url + "/review",
      component: Review,
      right: true,
      props: {
        canEditApprovers: false,
        canEditReviewers:
          (status === statuses.conclusionApproved.value ||
            status === statuses.conclusionReadyForReview.value) &&
          conclusion.canRejectAndRegenerateConclusion,
      },
    },
  }

  return conclusion?.hasReportingObligation
    ? result
    : R.omit(["reporting"], result)
}

import styled from "styled-components/macro"

export const CategoryToggle = styled.div`
  border-bottom: 1px solid #eee;
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
`

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentLibraryRequest } from "data/assessments/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import { stringRequired } from "validators/string"
import * as R from "ramda"
import { preassessmentLibraryRequest } from "data/preassessments/api"
// import { getCaseUrl } from "data/cockpit/redirect"

const AddToLibraryModal = ({ close, isConclusion, isPreassessment, match }) => {
  const assessmentWord = isPreassessment ? "pre-assessment" : "assessment"
  return (
    <>
      <P mb={20}>
        {localize(
          isConclusion
            ? `The conclusion will be added to the library with “in draft” status. In the library you can further modify the ${assessmentWord} (e.g. remove sensitive information) and request for publication. After the site admin publishes this item, all users can use it to create new ${assessmentWord}s.`
            : `The ${assessmentWord} will be added to the library with “in draft” status. In the library you can further modify the ${assessmentWord} (e.g. remove sensitive information) and request for publication. After the site admin publishes this item, all users can use it to create new ${assessmentWord}s.`,
        )}
      </P>
      <Textarea
        required
        name="name"
        label={localize(
          isConclusion
            ? "addToLibModalNameLabelConclusion"
            : "addToLibModalNameLabel",
        )}
        placeholder={localize("addToLibModalPlaceholder")}
      />

      <P bold gray inline>
        {localize("addToLibModalClearedLabel")}
      </P>
      <P>{localize("addToLibModalClearedCoworkers")}</P>
      <P>{localize("addToLibModalClearedComments")}</P>
      <P mb={20}>{localize("addToLibModalClearedContact")}</P>

      <P mb={20} bold>
        {localize(
          isConclusion
            ? "addToLibModalConfirmTextConclusion"
            : "addToLibModalConfirmText",
        )}
      </P>
      <ModalButtons>
        <Button secondary type="button" onClick={close} text="No" />
        <Button
          danger
          type="submit"
          text={localize("addToLibModalButtonYesAdd")}
        />
      </ModalButtons>
    </>
  )
}

export default R.compose(
  withModal(p => `${localize("addToLibModalTitle")(p.assessment.name)}`),
  withUrls,
  withForm({
    mapProps: p => ({
      name: `${p.assessment.name}`,
    }),
    schema: R.always({
      name: stringRequired,
    }),
    onSubmit: p => v =>
      (p.isPreassessment
        ? preassessmentLibraryRequest
        : assessmentLibraryRequest
      ).addToLibrary(p.assessment.id, v),
    onSuccess: p => () => p.close(),
    successText: () => (_, values) =>
      `${localize("itemHasBeenAdded")(values.name)}`,
    redirect: (id, p) => {
      // if (p.match?.path.startsWith(p.routes.caseCreate.path)) {
      //   return getCaseUrl(p.match, p.assessment)
      // }

      return p.urls.assessmentLibraryCreate(id)
    },
  }),
)(AddToLibraryModal)

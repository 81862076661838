import React from "react"
import { Switch, Route, Redirect } from "react-router-dom"
import { getSuburls } from "./suburls"
import * as R from "ramda"
import { caseRequest } from "data/cockpit/api"
import withData from "hoc/withData"
import { conclusionRequest } from "data/conclusions/api"

const Generation = ({
  match,
  caseId,
  refetchCase,
  case_,
  editId,
  setEditId,
  urls,
  suburls,
  conclusionTypes,
}) => {
  const conclusionSuburls = getSuburls(match)

  return (
    <Switch>
      {R.values(conclusionSuburls)
        .filter(x => !x.disabled)
        .map(x => {
          const Component = x.component

          return (
            <Route
              key={x.path}
              path={x.path}
              render={p => (
                <Component
                  {...p}
                  caseConclusionUrls={conclusionSuburls}
                  cancelUrl={conclusionSuburls.list.url}
                  caseUrls={suburls}
                  caseId={caseId}
                  refetch={refetchCase}
                  case_={case_}
                  editId={editId}
                  setEditId={setEditId}
                  urls={urls}
                  fromCase
                  conclusionTypes={conclusionTypes}
                  listRequest={caseRequest.listConclusions}
                />
              )}
            />
          )
        })}
      <Redirect to={conclusionSuburls.list.url} />
    </Switch>
  )
}

export default withData(() => conclusionRequest.listTypes(), {
  dataKey: "conclusionTypes",
})(Generation)

import React from "react"
import ContentEditable from "react-contenteditable"
import { TextareaWrap, TextareaControls } from "./styled"
import EditButton from "./EditButton"
import { styles } from "./styles"
import { normalizePastedHtml, inTransform, outTransform } from "./transforms"
import withFormField from "hoc/withFormField"

const RichTextarea = ({ value, onChange, borderless, readOnly, edge2edge }) => {
  const [active, setActive] = React.useState({})
  const [isFocused, setIsFocused] = React.useState(false)
  const ref = React.useRef(null)

  const handleActive = () => {
    ref.current.focus()
    setActive(
      styles.reduce(
        (acc, cur) => ({
          ...acc,
          [cur.cmd]: document.queryCommandState(cur.cmd),
        }),
        {},
      ),
    )
  }

  const handleClick = e => {
    const t = e.target
    if (t.tagName === "A") {
      window.open(t.getAttribute("href"), "_blank")
    }
    handleActive()
  }

  const handleChange = v => {
    onChange(outTransform(v))
    // handleActive()
  }

  const onBlur = () => {
    setIsFocused(false)
    setActive({})
  }

  const onFocus = () => {
    setIsFocused(true)
  }

  return (
    <TextareaWrap borderless={borderless} edge2edge={edge2edge}>
      {!readOnly && (
        <TextareaControls>
          {styles.map((x, xi) => (
            <EditButton
              key={xi}
              {...x}
              active={active}
              onClick={handleActive}
              isFocused={isFocused}
            />
          ))}
        </TextareaControls>
      )}
      <ContentEditable
        innerRef={ref}
        html={inTransform(value || "")}
        disabled={readOnly}
        onChange={e => {
          const tmp = e.target.value
          const v =
            e.nativeEvent.inputType === "insertFromPaste"
              ? normalizePastedHtml(tmp)
              : tmp
          handleChange(v)
        }}
        onMouseDown={handleClick}
        onKeyUp={handleActive}
        onFocus={onFocus}
        onBlur={onBlur}
      />
    </TextareaWrap>
  )
}

export default withFormField(RichTextarea)

import React from "react"
import withFormField from "hoc/withFormField"
import RadioItem from "./RadioItem"
import { RadioWrap } from "./styled"

const Radio = ({
  value,
  onChange,
  options,
  inline,
  byValue,
  readOnly,
  transparent,
}) => (
  <RadioWrap inline={inline} readOnly={readOnly}>
    {options.map((o, oi) => {
      const active = byValue
        ? value === o.value
        : value && value.value === o.value
      return (
        <RadioItem
          key={oi}
          onClick={() => onChange(byValue ? o.value : o)}
          disabled={o.disabled}
          inline={inline}
          active={active}
          text={o.name}
          readOnly={readOnly}
          transparent={transparent}
        />
      )
    })}
  </RadioWrap>
)

export default withFormField(Radio)

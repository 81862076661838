import withData from "hoc/withData"
import { countryListRequest } from "data/countries/api"
import * as R from "ramda"

const Country = ({ code, data = [] }) =>
  R.pipe(
    R.find(x => x.countryCode === code),
    R.propOr(null, "countryName"),
  )(data)

export default withData(() => countryListRequest(false), {
  cacheKey: () => "allCountries",
  noLoader: true,
})(Country)

import React from "react"
import withFormField from "hoc/withFormField"
import Columns from "components/Columns"
import * as R from "ramda"
import { localize } from "locale"
import Textfield from "inputs/Textfield"
import Radio from "inputs/Radio"
import CompareToolLink from "./CompareToolLink"
import { P } from "styledComponents/Typography"
import CrossIcon from "icons/Cross"
import { IconWrap } from "styledComponents/Icons"

const CompareToolLinks = ({
  onChange,
  readOnly,
  borderless,
  value,
  template,
}) => {
  const val = value || []
  return (
    <>
      {val.map((x, xi) => (
        <Columns
          key={xi}
          widths={["auto", 5, "auto", "<30%", 5, 40]}
          mb={10}
          align="flex-end"
        >
          <Textfield
            value={x.name}
            onChange={v => onChange(R.set(R.lensPath([xi, "name"]), v, val))}
            label={localize("nameOfTheLink")}
            placeholder={localize("enterTheNameOfTheLink")}
            readOnly={readOnly}
            borderless={borderless}
          />
          <P required={!readOnly} mb={10} />
          <Radio
            byValue
            value={x.type}
            onChange={v =>
              onChange(
                R.pipe(
                  R.over(R.lensPath([xi]), R.pick(["name"])),
                  R.set(R.lensPath([xi, "type"]), v),
                )(val),
              )
            }
            label={localize("linkType")}
            inline
            readOnly={readOnly}
            options={[
              { name: localize("compareTool"), value: "CompareTool" },
              { name: localize("url"), value: "Url" },
            ]}
          />
          {x.type === "Url" ? (
            <Textfield
              value={x.linkUrl}
              label={localize("link")}
              onChange={v =>
                onChange(R.set(R.lensPath([xi, "linkUrl"]), v, val))
              }
              readOnly={readOnly}
              borderless={borderless}
            />
          ) : (
            <CompareToolLink
              value={{
                headerName: x.headerName,
                headerId: x.headerId,
              }}
              label={localize("link")}
              onChange={v =>
                onChange(R.over(R.lensPath([xi]), R.mergeLeft(v), val))
              }
              readOnly={readOnly}
              template={template}
            />
          )}
          <P required={!readOnly} mb={10} />
          {readOnly ? (
            <div />
          ) : (
            <IconWrap
              onClick={() => onChange(R.remove(xi, 1, val))}
              height={40}
            >
              <CrossIcon />
            </IconWrap>
          )}
        </Columns>
      ))}
      {!readOnly && (
        <P
          red
          link
          hovernolink
          onClick={() => onChange(val.concat({ type: "CompareTool" }))}
        >
          {localize("addANewLink")}
        </P>
      )}
    </>
  )
}

export default R.compose(withFormField)(CompareToolLinks)

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M14.047,23.813 C14.587,23.72 15.118,23.6 15.63,23.438 C19.566,22.19 22.611,18.964 23.625,14.929 C23.861,13.991 23.999,13.015 24,12.004 L24,12 L24,11.996 C23.999,10.985 23.861,10.009 23.625,9.071 C22.479,4.512 18.735,0.996 14.052,0.188 C13.384,0.073 12.701,1.28771611e-16 12,0 C11.299,-1.28771611e-16 10.616,0.073 9.948,0.188 C5.264,0.997 1.52,4.513 0.375,9.072 C0.139,10.011 0,10.988 0,12 C0,13.012 0.139,13.989 0.375,14.928 C1.388,18.963 4.433,22.189 8.37,23.438 C8.882,23.6 9.413,23.72 9.953,23.813 C10.619,23.928 11.301,24 12,24 C12.699,24 13.381,23.928 14.047,23.813 L14.047,23.813 Z M1.5,12 C1.5,6.21 6.21,1.5 12,1.5 C17.79,1.5 22.5,6.21 22.5,12 C22.5,17.79 17.79,22.5 12,22.5 C6.21,22.5 1.5,17.79 1.5,12 L1.5,12 Z M11.2266,12.0002 L14.8946,8.3332 L13.8336,7.2732 L9.1056,12.0002 L13.8336,16.7272 L14.8946,15.6672 L11.2266,12.0002 L11.2266,12.0002 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

import sanitizeHtml from "sanitize-html"
import * as R from "ramda"

// we're using sup and sub because thats supported easily by the richtext
export const inTransform = html =>
  html
    .replace(new RegExp("<big>", "g"), "<sup>")
    .replace(new RegExp("</big>", "g"), "</sup>")
    .replace(new RegExp("<small>", "g"), "<sub>")
    .replace(new RegExp("</small>", "g"), "</sub>")

export const outTransform = html =>
  html
    .replace(new RegExp("<sup>", "g"), "<big>")
    .replace(new RegExp("</sup>", "g"), "</big>")
    .replace(new RegExp("<sub>", "g"), "<small>")
    .replace(new RegExp("</sub>", "g"), "</small>")

export const normalizePastedHtml = html => {
  const sanitized = sanitizeHtml(html, {
    allowedTags: [
      "small",
      "big",
      "b",
      "i",
      "u",
      "strong",
      "em",
      "div",
      "span",
      "ul",
      "ol",
      "li",
    ],
    allowedAttributes: {
      a: ["href"],
      span: ["style"],
    },
  })

  const replaceTag = (from, to) => str =>
    str
      .replace(new RegExp(`<${from}>`, "g"), `<${to}>`)
      .replace(new RegExp(`</${from}>`, "g"), `</${to}>`)

  const result = R.pipe(
    replaceTag("ol", "ul"),
    replaceTag("strong", "b"),
    replaceTag("em", "i"),
  )(sanitized)

  return result
}

import styled from "styled-components/macro"
import { Spread } from "styledComponents/Containers"

export const MenuWrap = styled.div`
  background: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 350px;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  border-right: solid 1px #d8d8d8;
`

export const MenuHeader = styled(Spread)`
  height: 60px;
  align-items: center;
`

export const MenuBody = styled.div`
  padding: 15px;
`

import styled from "styled-components/macro"

export const SectionWrap = styled.div`
  margin-top: 20px;
`

export const SectionHeader = styled.div`
  background: #333;
  padding: 0 20px;
  line-height: 50px;
`

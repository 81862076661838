import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M24,4.81305 L24,4.49955 C24,3.67155 23.328,2.99955 22.5,2.99955 L1.5,2.99955 C0.672,2.99955 0,3.67155 0,4.49955 L0,4.81305 L12,10.57905 L24,4.81305 Z M12.0006,13.90755 L0.0006,8.14155 L0.0006,19.49955 C0.0006,20.32905 0.6711,20.99955 1.5006,20.99955 L22.5006,20.99955 C23.3286,20.99955 24.0006,20.32905 24.0006,19.49955 L24.0006,8.14155 L12.0006,13.90755 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

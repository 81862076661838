import React from "react"
import Empty from "components/Empty"
import Panel from "components/Panel"
import { statuses } from "data/assessments/statuses"
import withOnMount from "hoc/withOnMount"
import withEditProps from "hoc/withEditProps"
import scrollIntoView from "scroll-into-view"
import Node from "./Node"
import * as R from "ramda"
import { templateTagRequest } from "data/registry/api"
import withData from "hoc/withData"

const Questions = ({
  tree,
  next,
  assessmentId,
  refetch,
  assessment,
  canEdit,
  bookmarkedOnly,
  hideBookmarks,
  isPreview,
  questionRequests,
  emptyText,
  editProps,
  questionFilter,
  catalogs,
  isLibrary,
  isPreassessment,
}) => (
  <>
    {tree.length > 0 ? (
      tree.map((x, xi) => (
        <Node
          node={x}
          key={xi}
          indices={[xi]}
          next={next}
          assessmentId={assessmentId}
          refetch={refetch}
          canEdit={canEdit}
          bookmarkedOnly={bookmarkedOnly}
          assessment={assessment}
          isPreview={isPreview}
          editProps={editProps}
          questionFilter={questionFilter}
          hideBookmarks={hideBookmarks}
          questionRequests={questionRequests}
          catalogs={catalogs}
          isLibrary={isLibrary}
          isPreassessment={isPreassessment}
        />
      ))
    ) : (
      <Panel>
        <Empty text={emptyText} />
      </Panel>
    )}
  </>
)

export default R.compose(
  withData(() => templateTagRequest.catalogs(), {
    dataKey: "catalogs",
  }),
  withOnMount(p => {
    const { lastId } = p
    const isFirst = R.path(["questions", 0, "referencedId"], p) === lastId

    if (
      (p.assessment.status === statuses.inProcess.value ||
        p.assessment.status === statuses.libraryInDraft.value) &&
      !R.isNil(lastId) &&
      !isFirst
    ) {
      scrollIntoView(document.getElementById(`q-${lastId}`))
    }
  }),
  withEditProps(R.prop("canEdit"), p => {
    if (!p.canEdit) return undefined

    const lastQIndex = p.questions.findIndex(x => x.referencedId === p.lastId)

    // set to the first active question, if nothing is found as last answered question
    if (lastQIndex === -1) {
      const first = p.questions.find(x => x.data.isEnabled)
      return R.path(["id"], first)
    }

    // id of the last one
    return R.path(["questions", lastQIndex, "id"], p)
  }),
)(Questions)

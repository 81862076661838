import React from "react"
import withSubmit from "hoc/withSubmit"
import { involvedPartyRequest } from "data/cockpit/api"
import * as R from "ramda"
import { ActiveIcon } from "styledComponents/Icons"
import DeleteIcon from "icons/appkit/fill-delete"
import WarningConfirmModal from "components/WarningConfirmModal"
import InfoModal from "components/InfoModal"
import { localize } from "locale"
import useOpen from "hooks/useOpen"

const DeleteButton = ({ submit, party }) => {
  const { isOpen, open, close } = useOpen()

  if (party.linkedObjectsCount > 0)
    return (
      <>
        <ActiveIcon onClick={open}>
          <DeleteIcon />
        </ActiveIcon>
        {isOpen && (
          <InfoModal
            close={close}
            title="Permanently delete Involved party"
            text="Please kindly note you may only delete an involved party when there are no objects (assessments or conclusions) linked to it. Please unlink the objects prior to deletion."
          />
        )}
      </>
    )
  return (
    <WarningConfirmModal
      onConfirm={submit}
      doConfirm={R.always(true)}
      title="Permanently delete Involved party"
      text="Involved party will be permanently deleted and cannot be restored."
      buttonText="Do you want to permanently delete Involved party?"
      confirmText={localize("yesDelete")}
      dangerConfirm
    >
      {onClick => (
        <ActiveIcon onClick={onClick}>
          <DeleteIcon />
        </ActiveIcon>
      )}
    </WarningConfirmModal>
  )
}

export default R.compose(
  withSubmit({
    onSubmit: p => () => involvedPartyRequest.del(p.data.id),
    onSuccess: p => () => p.refetch(),
    successText: p => () => `Involved party ${p.data.name} has been deleted.`,
  }),
)(DeleteButton)

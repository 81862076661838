import React from "react"
import OverlayWrapper from "components/OverlayWrapper"
import CaretIcon from "icons/Caret"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import Menu from "./Menu"
import { P } from "styledComponents/Typography"
import { TenantSwitchWrap } from "./styled"
import * as R from "ramda"
import useOpen from "hooks/useOpen"

const TenantSwitch = ({ profile, tenants }) => {
  const { isOpen, close, toggle } = useOpen()

  const currTenant = tenants.find(x => x.id === profile.tenantId)
  const tenantName = R.prop("name", currTenant)

  if (tenants.length === 1) {
    return (
      <TenantSwitchWrap>
        <P>{tenantName}</P>
      </TenantSwitchWrap>
    )
  }

  return (
    <TenantSwitchWrap>
      <OverlayWrapper isOpen={isOpen} close={close}>
        <Box flex onClick={toggle}>
          <P nowrap nobreak>
            {tenantName}
          </P>
          <IconWrap static>
            <CaretIcon />
          </IconWrap>
        </Box>
        <Menu
          isOpen={isOpen}
          close={close}
          profile={profile}
          tenants={tenants}
        />
      </OverlayWrapper>
    </TenantSwitchWrap>
  )
}

export default TenantSwitch

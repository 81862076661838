import React from "react"
import { P } from "styledComponents/Typography"

const boldenMatch = (text, match) => {
  if (!match) return text

  const splitIndex = text.match(new RegExp(match, "i")).index

  return (
    <>
      <span>{text.slice(0, splitIndex)}</span>
      <strong>{text.slice(splitIndex, splitIndex + match.length)}</strong>
      <span>{text.slice(splitIndex + match.length)}</span>
    </>
  )
}

const HighlightedText = ({ text = "", match }) => (
  <P>{boldenMatch(text, match)}</P>
)

export default HighlightedText

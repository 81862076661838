import React from "react"
import * as R from "ramda"
import withData from "hoc/withData"
import withModal from "hoc/withModal"
import { caseRequest } from "data/cockpit/api"
import { P } from "styledComponents/Typography"
import ExpandableText from "components/ExpandableText"
import InvolvedParty from "./InvolvedParty"
import withUrls from "hoc/withUrls"
import { withRouter } from "react-router"
import Empty from "components/Empty"
import { Box } from "styledComponents/Containers"

const Browser = ({ data, ...rest }) => {
  if (!data.preAssessment && data.linkedParties.length === 0) return <Empty />

  return (
    <>
      {data.preAssessment && (
        <Box mb={20}>
          <InvolvedParty
            involvedParty={{
              name: "Pre-assessment",
              objects: [data.preAssessment],
            }}
            isPreassessment
            {...rest}
          />
        </Box>
      )}
      {data.linkedParties.length > 0 && (
        <>
          <P bold>Involved parties</P>
          {data.linkedParties.map(x => (
            <InvolvedParty key={x.id} involvedParty={x} {...rest} />
          ))}
        </>
      )}
      {data.additionalComments && (
        <>
          <P bold mt={30} mb={10}>
            Additional comments
          </P>
          <ExpandableText text={data.additionalComments} />
        </>
      )}
    </>
  )
}

export default R.compose(
  withModal(R.always("Case browser"), {
    fixedRight: true,
    width: 450,
    boldTitle: true,
    titleLine: false,
  }),
  withUrls,
  withRouter,
  withData(p => caseRequest.browser(p.caseId)),
)(Browser)

import { localize } from "locale"
import * as R from "ramda"

export const getErrorTitle = error => {
  const response = R.path(["error", "response"], error)
  if (!response) return localize("networkError")
  const msg = error.text

  return msg

  // const code = response.status
  // if (code === 403) return localize("noAccess")
  // if (code === 404) return localize("notFound")
  // if (code === 500) return localize("serverError")

  // return localize("unknownError")
}

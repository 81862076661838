import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentRequest } from "data/assessments/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import { stringRequired } from "validators/string"
import * as R from "ramda"
import { getCaseUrl } from "data/cockpit/redirect"
import { preassessmentRequest } from "data/preassessments/api"

const ConfirmRejectModal = ({ close, isPreassessment }) => (
  <>
    <Textarea
      required
      name="reason"
      label={localize("reasonForRejection")}
      placeholder={localize("writeTheReason")}
    />
    <P mb={20} bold>
      Do you want to reject the {isPreassessment ? "pre-" : ""}assessment?
    </P>
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="No" />
      <Button danger type="submit" text={localize("yesReject")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(
    p =>
      `Reject ${p.isPreassessment ? "pre-" : ""}assessment ${
        p.assessment.name
      }`,
  ),
  withUrls,
  withForm({
    schema: R.always({
      reason: stringRequired,
    }),
    onSubmit: p => v =>
      (p.isPreassessment ? preassessmentRequest : assessmentRequest).reject(
        p.assessment.id,
        v,
      ),
    onSuccess: p => () => p.close(),
    successText: p => () =>
      `The ${p.isPreassessment ? "pre-" : ""}assessment ${
        p.assessment.name
      } was rejected.`,
    redirect: (_, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return getCaseUrl(p.match, p.assessment)
      }
      return p.urls.assessments.local
    },
  }),
)(ConfirmRejectModal)

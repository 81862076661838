import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M23.904,22.843 L15.857,14.796 C17.186,13.228 18,11.212 18,9 C18,4.037 13.963,0 9,0 C4.037,0 0,4.037 0,9 C0,13.963 4.037,18 9,18 C11.212,18 13.228,17.186 14.796,15.856 L22.843,23.904 L23.904,22.843 Z M1.5,9 C1.5,4.865 4.865,1.5 9,1.5 C13.136,1.5 16.5,4.865 16.5,9 C16.5,13.135 13.136,16.5 9,16.5 C4.865,16.5 1.5,13.135 1.5,9 Z"
      fillRule="evenodd"
    />
  </svg>
)

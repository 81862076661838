import { useEffect, useRef, useState } from "react"

export const useScrollLock = lock => {
  const [scrollLocked, setScrollLocked] = useState(lock || false)

  const locked = useRef(false)
  const bodyOverflow = useRef(null)

  const unlockScroll = () => {
    if (locked.current) {
      locked.current = false
      document.body.style.overflow = bodyOverflow.current || ""
    }
  }

  const lockScroll = () => {
    locked.current = true
    bodyOverflow.current = document.body.style.overflow
    document.body.style.overflow = "hidden"
  }

  useEffect(() => {
    if (scrollLocked) {
      lockScroll()
    } else {
      unlockScroll()
    }

    return unlockScroll
  }, [scrollLocked])

  useEffect(() => {
    if (lock !== undefined) {
      setScrollLocked(lock)
    }
  }, [lock])

  useEffect(() => {
    if (lock === undefined && typeof window !== "undefined") {
      window.document.body.style.overflow === "hidden" && setScrollLocked(true)
    }
  }, [setScrollLocked]) // eslint-disable-line

  return [scrollLocked, setScrollLocked]
}

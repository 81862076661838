import React from "react"
import Columns from "components/Columns"
import Panel from "components/Panel"
import Status from "components/Status"
import ArrangementId from "components/ArrangementId"
import LabelValue from "components/LabelValue"
import { localize } from "locale"
import { conclusionCommentsRequests } from "data/reporting/api"
import { indexColorsByStatuses } from "data/conclusions/colors"
import { conclusionStatuses } from "data/assessments/statuses"
import { formatConclusionStatus } from "data/conclusions/formatters"
import { Hr } from "styledComponents/Typography"
import Comments from "components/Comments"
import ConclusionStatus from "./ConclusionStatus"
import Identifiers from "./Identifiers"
import { formatDate } from "data/units/date/formatters"
import withData from "hoc/withData"
import { reportRequest } from "data/report/api"

const ConclusionDetail = ({
  conclusion,
  statuses,
  openId,
  setOpenId,
  refetch,
  report,
}) => {
  const disabled =
    conclusion.conclusionStatus === conclusionStatuses.conclusionDeleted.value

  return (
    <Panel>
      <Columns>
        <LabelValue
          label={localize("dueDate")}
          value={formatDate(conclusion.dueDateUtc)}
        />
        <LabelValue
          label={localize("arrangementId")}
          value={<ArrangementId arrangementId={conclusion.arrangementId} />}
        />
        <LabelValue
          label={localize("conclusionType")}
          value={
            <Status
              color={indexColorsByStatuses(statuses)[conclusion.conclusionType]}
              text={formatConclusionStatus(conclusion.conclusionType)}
            />
          }
        />
      </Columns>
      <Hr />
      <ConclusionStatus
        setOpenId={setOpenId}
        openId={openId}
        conclusion={conclusion}
        refetch={refetch}
        readOnly={disabled}
        report={report}
      />
      <Identifiers
        conclusion={conclusion}
        setOpenId={setOpenId}
        openId={openId}
        refetch={refetch}
        readOnly={disabled}
      />
      <Comments
        addCommentId={`add-${conclusion.assessmentId}`}
        setOpenId={setOpenId}
        openId={openId}
        requests={conclusionCommentsRequests(conclusion.assessmentId)}
        long
        readOnly={disabled}
      />
    </Panel>
  )
}

export default withData(
  p => reportRequest.conclusion(p.report.id, p.conclusionId),
  {
    dataKey: "conclusion",
  },
)(ConclusionDetail)

import * as R from "ramda"

export const formatCurrency = v => {
  if (R.isNil(v)) return ""
  const [first, second] = ("" + v).split(".")

  const f = R.pipe(R.reverse, R.splitEvery(3), R.join(" "), R.reverse)(first)
  const result = second === undefined ? f : [f, second].join(".")
  return result
}

export const limitToDecimal = v => {
  if (v === "") return ""

  return v
    .split("")
    .filter(x => "0123456789.".includes(x))
    .filter((x, xi, arr) => {
      return x !== "." || !arr.slice(0, xi).includes(".")
    })
    .filter((x, xi, arr) => {
      /**
       * BE accepts maximum number of 14 digits before the decimal point and 2 digits after.
       */
      const soFar = arr.slice(0, xi)
      const pointIndex = soFar.indexOf(".")
      const digitsBeforePoint =
        pointIndex === -1 ? soFar.length + 1 : pointIndex
      const digitsAfterPoint =
        pointIndex === -1 ? 0 : soFar.slice(pointIndex).length
      return (
        (pointIndex === -1 && digitsBeforePoint <= 14) ||
        (pointIndex !== -1 &&
          digitsBeforePoint <= 14 &&
          digitsAfterPoint <= 2) ||
        x === "."
      )
    })
    .join("")
}

export const shortenBigNumber = x => {
  const str = `${x}`

  if (str.length > 6) return `${str.slice(0, -6)}M`
  if (str.length > 3) return `${str.slice(0, -3)}k`

  return x
}

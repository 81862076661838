import { get, post, del, put } from "common/request"

export const registryRequest = {
  setupList: registryType => get(`/api/registry/${registryType}/catalogs`),
  setupEdit: id => v => put(`/api/registry/catalog/${id}`, v),
  setupCreate: registryType => v =>
    post(`/api/registry/${registryType}/catalog`, v),
  setupDelete: id => del(`/api/registry/catalog/${id}`),
  list: (registryType, params) =>
    get(`/api/registry/${registryType}?${params}`),
  detail: id => get(`/api/registry/${id}/detail`),
  create: registryType => v => post(`/api/registry/${registryType}`, v),
  edit: id => v => put(`/api/registry/${id}`, v),
  delete: id => del(`/api/registry/${id}`),
  import: registryType => file =>
    post(`/api/registry/${registryType}/import`, file, {
      doNotSetContentType: true,
    }),
}

export const templateTagRequest = {
  catalogs: () => get("/api/registry/catalogs"),
  tag: (templateId, questionId, data) =>
    put(
      `/api/template/${templateId}/question/${questionId}/registryTagging`,
      data,
    ),
  getTagging: templateId => get(`/api/template/${templateId}/registryTagging`),
  updateGroupName: (templateId, data) =>
    put(`/api/template/${templateId}/autoFillGroupName`, data),
}

export const assessmentTagRequest = {
  search: registryType => search =>
    get(`/api/registry/${registryType}/search?search=${search}`),
  values: registryKeyId => get(`/api/registry/${registryKeyId}/values`),
}

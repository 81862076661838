import React from "react"

const Arrow = () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M17.8408,0.2197 C17.5478,-0.0733 17.0728,-0.0733 16.7798,0.2197 L4.9998,11.9997 L16.7798,23.7807 C16.9268,23.9267 17.1188,23.9997 17.3108,23.9997 C17.5028,23.9997 17.6948,23.9267 17.8408,23.7807 C18.1338,23.4877 18.1338,23.0127 17.8408,22.7197 L7.1208,11.9997 L17.8408,1.2807 C18.1338,0.9877 18.1338,0.5127 17.8408,0.2197"
      fillRule="evenodd"
    />
  </svg>
)

export default Arrow

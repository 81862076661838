import React from "react"
import ContextMenu from "components/ContextMenu"
import OverlayWrapper from "components/OverlayWrapper"
import OutlineHelpQuestion from "icons/appkit/outline-help-question"
import { IconWrap } from "styledComponents/Icons"
import HelpcenterList from "./HelpcenterList"
import useOpen from "hooks/useOpen"

const Helpcenter = () => {
  const { isOpen, close, toggle } = useOpen()
  return (
    <>
      <OverlayWrapper isOpen={isOpen} close={close}>
        <IconWrap onClick={toggle} large mr={10} ml={10}>
          <OutlineHelpQuestion />
        </IconWrap>
        <ContextMenu isOpen={isOpen}>
          <HelpcenterList />
        </ContextMenu>
      </OverlayWrapper>
    </>
  )
}

export default Helpcenter

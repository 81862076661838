import React from "react"
import * as R from "ramda"
import { Box, Spread } from "styledComponents/Containers"
import FixedPanel from "components/FixedPanel"
import { localize } from "locale"
import ButtonModal from "components/ButtonModal"
import { P } from "styledComponents/Typography"
import CreateModal from "./CreateModal"
import { arrToAndString } from "common/string"
import ModalButtons from "components/ModalButtons"

const BottomPanel = ({ value, setValue, caseId, suburls }) => {
  if (value.length === 0) return null

  return (
    <FixedPanel>
      <Spread mt={15} mb={15}>
        <Box>
          <P>
            Generate assessments for{" "}
            {arrToAndString(value.map(R.prop("countryName")))}
          </P>
          <Box flex mt={15}>
            <P bold>
              {value.length} {value.length === 0 ? "template" : "templates"}{" "}
              selected
            </P>
            <P ml={10} red link hovernolink onClick={() => setValue([])}>
              {localize("deselectAll")}
            </P>
          </Box>
        </Box>
        <ModalButtons nomargin>
          <ButtonModal
            modal={CreateModal}
            text="Generate assessments"
            templateIds={value
              .filter(x => R.isNil(x.libraryItemId))
              .map(R.prop("templateId"))}
            libraryItemIds={value
              .filter(x => !R.isNil(x.libraryItemId))
              .map(R.prop("libraryItemId"))}
            caseId={caseId}
            suburls={suburls}
          />
        </ModalButtons>
      </Spread>
    </FixedPanel>
  )
}

export default BottomPanel

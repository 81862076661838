import React from "react"
import Single from "./Single"
import Multi from "./Multi"

const Form = ({
  node,
  questions,
  tree,
  editProps,
  schemaId,
  templateId,
  last,
  refetch,
  valueProps,
  indices,
  lastSelectedValue,
  setLastSelectedValue,
}) => {
  const hasOptions = node.element.elementOptions.length > 0

  if (hasOptions) {
    return (
      <Multi
        questions={questions}
        tree={tree}
        node={node}
        editProps={editProps}
        schemaId={schemaId}
        templateId={templateId}
        mb={last ? 0 : 20}
        refetch={refetch}
        indices={indices}
        valueProps={valueProps}
        lastSelectedValue={lastSelectedValue}
        setLastSelectedValue={setLastSelectedValue}
      />
    )
  }

  return (
    <Single
      questions={questions}
      tree={tree}
      node={node}
      editProps={editProps}
      schemaId={schemaId}
      templateId={templateId}
      mb={last ? 0 : 20}
      indices={indices}
      valueProps={valueProps}
      lastSelectedValue={lastSelectedValue}
      setLastSelectedValue={setLastSelectedValue}
    />
  )
}

export default Form

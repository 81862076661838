import React from "react"
import useOpen from "hooks/useOpen"
import { Box } from "styledComponents/Containers"
import { StyledTooltip } from "./styled"
import ContextMenu from "components/ContextMenu"
import OverlayWrapper from "components/OverlayWrapper"

const Tooltip = ({ content, children }) => {
  const { isOpen, open, close } = useOpen()

  return (
    <OverlayWrapper isOpen={isOpen} close={close} transparent>
      <Box relative onMouseOver={open} onMouseOut={close}>
        {children}
        <ContextMenu isOpen={isOpen}>
          <StyledTooltip>{content}</StyledTooltip>
        </ContextMenu>
      </Box>
    </OverlayWrapper>
  )
}

export default Tooltip

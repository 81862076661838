import React from "react"
import withModal from "hoc/withModal"
import withData from "hoc/withData"
import withProps from "hoc/withProps"
import withForm from "hoc/withForm"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import Select from "inputs/Select"
import Textfield from "inputs/Textfield"
import AutocompleteSelectRegistry from "inputs/AutocompleteSelectRegistry"
import { registryTypeOptions } from "data/registry/types"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { stringRequired } from "validators/string"
import { assessmentTagRequest, templateTagRequest } from "data/registry/api"
import { getFieldOptions } from "./helpers"
import { objectRequired } from "validators/object"
import { emptyRequest } from "common/rxjs"

const ManualRegistryAutofillModal = ({
  close,
  values,
  data,
  allCatalogs,
  submitForm,
  setValues,
}) => {
  const fieldValue = values.field
  const catalogValue = allCatalogs.find(
    y => y.id === fieldValue?.registryCatalogId,
  )
  const isMulti = catalogValue?.allowsMultiple

  return (
    <Box>
      <Select
        name="form"
        label="Legal form"
        options={registryTypeOptions}
        onChange={v =>
          // remove other fields
          setValues({ form: v })
        }
        byValue
      />
      <AutocompleteSelectRegistry
        name="subject"
        registryType={values.form}
        label="Subject"
        readOnly={!values.form}
        onChange={v =>
          // remove other fields
          setValues({ form: values.form, subject: v })
        }
      />
      <Select
        label="Field"
        name="field"
        options={getFieldOptions(allCatalogs, data)}
        onChange={v =>
          // remove other fields
          setValues({ form: values.form, subject: values.subject, field: v })
        }
      />
      {isMulti ? (
        <Select
          name="valueIndex"
          byValue
          label="Value"
          readOnly={!isMulti}
          options={values.field.value
            .split(",")
            .map((y, yi) => ({ value: yi, name: y }))}
        />
      ) : (
        <Textfield
          label="Value"
          borderless
          readOnly
          value={values.field?.value}
        />
      )}
      <ModalButtons>
        <Button secondary type="button" onClick={close} text="Cancel" />
        <Button onClick={submitForm} text="Insert value" />
      </ModalButtons>
    </Box>
  )
}

export default R.compose(
  withModal(R.always("Manual entity registry auto-fill")),
  withData(() => templateTagRequest.catalogs(), {
    dataKey: "catalogs",
  }),
  withProps(p => ({ allCatalogs: R.values(p.catalogs).flatMap(R.identity) })),
  withForm({
    mapProps: p => {
      if (!p.tag) return {}

      return {
        form: p.catalogs.individuals.find(x => x.id === p.tag.registryCatalogId)
          ? "I"
          : "E",
        subject: {
          value: p.tag.keyValueId,
          name: p.tag.keyValue,
        },
        field: {
          id: p.tag.registryValueId,
          name: p.tag.registryCatalog,
          registryCatalogId: p.tag.registryCatalogId,
          registryValueId: p.tag.registryValueId,
          value: p.tag.registryValue,
        },
        valueIndex: p.tag.valueIndex,
      }
    },
    schema: R.always({
      form: stringRequired,
      subject: objectRequired,
      field: objectRequired,
    }),
    onSubmit: p => v => {
      const applicableRegistryValue = R.isNil(v.valueIndex)
        ? v.field.value
        : v.field.value.split(",")[v.valueIndex]

      p.onSelect({
        applicableRegistryValue,
        keyValue: v.subject.name,
        keyValueId: v.subject.value,
        registryCatalog: v.field.name,
        registryCatalogId: v.field.registryCatalogId,
        registryValue: v.field.value,
        registryValueId: v.field.registryValueId,
        valueIndex: v.valueIndex,
      })
      p.close()
      return emptyRequest
    },
  }),
  withData(p => assessmentTagRequest.values(p.values.subject.value), {
    skip: p => !R.path(["values", "subject", "value"], p),
    refetchParams: p => [R.path(["values", "subject", "value"], p)],
  }),
)(ManualRegistryAutofillModal)

import React from "react"
import Panel from "components/Panel"
import { P } from "styledComponents/Typography"
import { Box, Spread } from "styledComponents/Containers"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import useOpen from "hooks/useOpen"
import theme from "theme"
import PartyObject from "./PartyObject"
import { IconWrapStyled, Shadow } from "./styled"

const InvolvedParty = ({
  involvedParty,
  objectId,
  history,
  caseSuburls,
  isPreassessment,
  ...rest
}) => {
  const { isOpen, toggle } = useOpen(
    involvedParty.objects.find(x => x.id === objectId) ||
      (objectId === "preassessment" && isPreassessment),
  )

  return (
    <>
      <Panel key={involvedParty.id} mt={10} bg={isOpen && theme.color.red}>
        <Spread>
          <P
            bold
            white={isOpen}
            pointer
            onClick={() => {
              history.push(
                isPreassessment
                  ? `${caseSuburls.preassessment.url}/list/All`
                  : `${caseSuburls.detail.url}/list?involvedPartyId=${involvedParty.id}`,
              )
              rest.close()
            }}
          >
            {involvedParty.name}
          </P>
          <Box flex acenter>
            <P mr={10} white={isOpen}>
              {involvedParty.countryCode}
            </P>
            <IconWrapStyled
              onClick={toggle}
              // the boolean convert is so that styled-components dont throw warning
              rotate={+isOpen}
              white={isOpen}
              disabled={involvedParty.objects.length === 0}
            >
              <OutlineArrow />
            </IconWrapStyled>
          </Box>
        </Spread>
      </Panel>
      {isOpen && (
        <Shadow>
          {involvedParty.objects.map(x => (
            <PartyObject
              object={x}
              key={x.id}
              objectId={objectId}
              history={history}
              caseSuburls={caseSuburls}
              isPreassessment={isPreassessment}
              {...rest}
            />
          ))}
        </Shadow>
      )}
    </>
  )
}

export default InvolvedParty

import * as R from "ramda"
import errors from "./errors"
import { localize } from "locale"

export const combineValidators =
  (...validatorFns) =>
  v =>
    validatorFns.reduce((acc, cur) => (acc ? acc : cur(v)), "")

export const fieldOptional = R.always("")

const noWhitespaceOnly = v => (/\S/.test(v) ? "" : localize("noWhitespaceOnly"))

export const fieldRequired = combineValidators(
  fieldOptional,
  v => (R.isNil(v) || R.isEmpty(v) ? errors.required : ""),
  noWhitespaceOnly,
)

import React from "react"
import OutlineHelpQuestion from "icons/appkit/outline-help-question"
import { IconWrap } from "../styled"
import InfoModal from "./InfoModal"
import useOpen from "hooks/useOpen"

const BookmarkButton = ({
  questionId,
  templateId,
  index,
  hasSupportiveInfo,
}) => {
  const { isOpen, open, close } = useOpen()

  return (
    <>
      <IconWrap active={isOpen || hasSupportiveInfo} onClick={open}>
        <OutlineHelpQuestion />
      </IconWrap>
      {isOpen && (
        <InfoModal
          close={close}
          templateId={templateId}
          questionId={questionId}
          index={index}
        />
      )}
    </>
  )
}

export default BookmarkButton

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const AriaMenuItem = styled.div`
  display: flex;
  align-items: center;
  min-height: 40px;
  min-width: 200px;
  padding: 0 20px;
  border: 1px solid #d8d8d8;
  white-space: nowrap;
  cursor: pointer;
  color: #333;

  svg {
    margin-right: 10px;
    width: 20px;
    height: 20px;
    fill: #333;
  }

  ${p =>
    p.isActive
      ? css`
          background: ${theme.color.orange};
          border-color: ${theme.color.orange};
          color: #fff;
          svg {
            fill: #fff;
          }
        `
      : css`
          &:hover {
            background: #ffe8dc;
            border-color: ${theme.color.orange};
          }
        `};
`

export const MenuItemText = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
`

import styled, { css } from "styled-components/macro"

export const StyledTextarea = styled.textarea`
  border: 1px solid #d8d8d8;
  display: flex;
  outline: none;
  height: 45px;
  position: relative;
  padding: 11px;
  color: #333;
  font-size: 16px;
  width: 100%;
  resize: none;

  background-color: ${p => (p.transparent ? "transparent" : "#fff")};

  ${p =>
    p.borderless &&
    css`
      border: 0;
    `};
  ${p =>
    p.edge2edge &&
    css`
      padding: 0;
      min-height: 0;
    `};

  &::placeholder,
  &:-ms-input-placeholder {
    fill: rgba(51, 51, 51, 0.333);
    color: rgba(51, 51, 51, 0.333);
    text-shadow: none;
  }
  ::-webkit-input-placeholder {
    color: rgba(51, 51, 51, 0.333);
    text-shadow: none;
  }
`

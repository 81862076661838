import React from "react"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import withFormField from "hoc/withFormField"
import CompareToolModal from "./CompareToolModal"
import useOpen from "hooks/useOpen"

const CompareToolLink = ({ value, onChange, readOnly, template }) => {
  const { isOpen, open, close } = useOpen()

  return (
    <>
      {isOpen && (
        <CompareToolModal
          close={close}
          onChange={onChange}
          value={value}
          template={template}
        />
      )}
      <P
        pt={10.8}
        pb={10.8}
        ml={15}
        red
        link={!readOnly}
        hovernolink
        onClick={readOnly ? undefined : open}
        nowrap
        ellipsis
      >
        {value.headerName || localize("selectFromDacCompareTool")}
      </P>
    </>
  )
}

export default withFormField(CompareToolLink)

import { del, get, post, put } from "common/request"
import { encodeQueryParams } from "common/url"

export const templateRequest = {
  list: status => get(`/api/templates?${status ? `&status=${status}` : ``}`),
  detail: templateId => get(`/api/template/${templateId}?lang=en`),
  create: data => post("/api/template", data),
  edit: (templateId, data) => put(`/api/template/${templateId}`, data),
  copy: (templateId, data) => post(`/api/template/${templateId}/copy`, data),
  deactivate: (templateId, data) =>
    put(`/api/template/${templateId}/deactivate`, data),
  delete: (id, data) => put(`/api/template/${id}/delete`, data),
  teamMembersList: () => get(`/api/template/teamUsers`),
  restore: data => post(`/api/templates/restore`, data),
  deletePermanent: data => post(`/api/templates/deletePermanent`, data),
}

export const coworkersRequest = {
  list: templateId => get(`/api/template/${templateId}/coworkers`),
  find: (email, ids) =>
    get(
      `/api/template/coworkers/search${encodeQueryParams({
        filterEmail: email,
        excludeUserIds: ids,
      })}`,
    ),
  edit: (templateId, data) =>
    post(`/api/template/${templateId}/coworkers`, data),
}

export const groupRequest = {
  create: (templateId, data) => post(`/api/template/${templateId}/group`, data),
  del: (templateId, groupId) =>
    del(`/api/template/${templateId}/group/${groupId}`),
  edit: (templateId, groupId, data) =>
    put(`/api/template/${templateId}/group/${groupId}`, data),
  move: (templateId, groupId, data) =>
    put(`/api/template/${templateId}/group/${groupId}/position`, data),
  copy: (templateId, groupId, data) =>
    put(`/api/template/${templateId}/group/${groupId}/copy`, data),
}

export const questionRequest = {
  list: templateId => get(`/api/template/${templateId}/questions`),
  create: (templateId, data) =>
    post(`/api/template/${templateId}/question`, data),
  edit: (templateId, questionId, data) =>
    put(`/api/template/${templateId}/question/${questionId}`, data),
  del: (templateId, questionId) =>
    del(`/api/template/${templateId}/question/${questionId}`),
  move: (templateId, questionId, data) =>
    put(`/api/template/${templateId}/question/${questionId}/position`, data),
  copy: (templateId, questionId, data) =>
    put(`/api/template/${templateId}/question/${questionId}/copy`, data),
  moveSubquestion: (templateId, subQuestionId, data) =>
    put(
      `/api/template/${templateId}/subquestion/${subQuestionId}/position`,
      data,
    ),
}

export const conclusionRequest = {
  list: templateId => get(`/api/template/${templateId}/conclusion`),
  updateHeader: templateId => text =>
    put(`/api/template/${templateId}/conclusion/header`, {
      headerText: text,
    }),
  updateFooter: templateId => text =>
    put(`/api/template/${templateId}/conclusion/footer`, {
      footerText: text,
    }),
  updateDisclaimer: templateId => text =>
    put(`/api/template/${templateId}/conclusion/disclaimer`, {
      disclaimerText: text,
    }),
  updateDefinition: (templateId, id, data) =>
    put(`/api/template/${templateId}/conclusion/definition/${id}`, data),
  createReasoning: (templateId, data) =>
    post(`/api/template/${templateId}/conclusion/reasoning`, data),
  updateReasoning: (templateId, id, data) =>
    put(`/api/template/${templateId}/conclusion/reasoning/${id}`, data),
  moveReasoning: (templateId, id, position) =>
    put(
      `/api/template/${templateId}/conclusion/reasoning/${id}/move/${position}`,
    ),
  deleteReasoning: (templateId, id) =>
    del(`/api/template/${templateId}/conclusion/reasoning/${id}`),
}

export const uploadRequest = {
  create: templateId => data =>
    post(`/api/template/${templateId}/file`, data, {
      doNotSetContentType: true,
    }),
  download: templateId => attachmentId =>
    get(`/api/template/${templateId}/file/${attachmentId}`),
}

export const historyRequest = {
  list: templateId => get(`/api/template/${templateId}/history`),
}

export const approveRequest = {
  check: templateId => get(`/api/template/${templateId}/verify?lang=en`),
  submit: templateId => put(`/api/template/${templateId}/submit`),
  approve: templateId => put(`/api/template/${templateId}/approve`),
  reject: (templateId, data) => put(`/api/template/${templateId}/reject`, data),
}

export const publishRequest = {
  info: templateId => get(`/api/template/${templateId}/publishAndShare`),
  publish: (templateId, data) =>
    post(`/api/template/${templateId}/publish`, data),
  share: (templateId, data) => post(`/api/template/${templateId}/share`, data),
}

export const previewRequest = {
  list: templateId => get(`/api/preview/assessments?templateId=${templateId}`),
  detail: previewId => get(`/api/preview/assessment/${previewId}`),
  delete: previewId => del(`/api/preview/assessment/${previewId}/delete`),
  create: data => post(`/api/preview/assessment`, data),
  questions: previewId => get(`/api/preview/assessment/${previewId}/questions`),
  verify: previewId => get(`/api/preview/assessment/${previewId}/verify`),
  conclusions: previewId =>
    get(`/api/preview/assessment/${previewId}/conclusions`),
  answer: (previewId, questionId, data) =>
    put(
      `/api/preview/assessment/${previewId}/question/${questionId}/answer`,
      data,
    ),
  reporting: previewId => get(`/api/preview/assessment/${previewId}/reporting`),
  verifyReporting: previewId =>
    get(`/api/preview/assessment/${previewId}/reporting/verify`),
  downloadXML: (previewId, schemaId) =>
    get(
      `/api/preview/assessment/${previewId}/reporting/xml?schemaId=${schemaId}`,
    ),
}

export const compareToolRequest = {
  hierarchy: countryCode =>
    get(`/api/compareTool/hierarchy?country=${countryCode}`),
  header: id => get(`/api/compareTool/content/${id}`),
}

export const templatePoolRequest = {
  count: () => get("/api/templatePool/counts"),
  list: params => get(`/api/templatePool?${params}`),
  tenantList: () => get("/api/templatePool/tenants"),
  deactivate: sharedTemplateId =>
    put(`/api/templatePool/${sharedTemplateId}/deactivate`),
  delete: sharedTemplateId =>
    del(`/api/templatePool/${sharedTemplateId}/delete`),
  download: (sharedTemplateId, asPublished) =>
    put(
      `/api/templatePool/${sharedTemplateId}/download?${
        asPublished ? "asPublished=true" : ""
      }`,
    ),
  permissions: filters => get(`/api/templatePool/permissions?${filters}`),
  permissionDetail: tenantId => get(`/api/templatePool/permission/${tenantId}`),
  updatePermissionDetail: (tenantId, data) =>
    put(`/api/templatePool/permission/${tenantId}`, data),
}

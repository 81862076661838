import React from "react"
import withModal from "hoc/withModal"
import { localize } from "locale"
import Loader from "components/Loader"
import { getFilenameFromResponse, downloadFileFromUri } from "common/files"
import * as R from "ramda"
import { toastError } from "data/toasts/rx"

const DownloadFileModal = ({ request, close, filename }) => {
  React.useEffect(() => {
    const commonFn = () => {
      clearInterval(interval)
      close()
    }

    const interval = setInterval(() => {
      request.subscribe({
        next: res => {
          if (res.data) {
            const fn = getFilenameFromResponse(res) || filename
            downloadFileFromUri(res.data, fn)
            commonFn()
          }
        },
        error: err => {
          toastError(R.path(["error", "response", "data"], err) || err.text)
          commonFn()
        },
      })
    }, 2000)

    return () => clearInterval(interval)
  }, []) // eslint-disable-line

  return <Loader small />
}

export default R.compose(withModal(R.always(localize("downloading"))))(
  DownloadFileModal,
)

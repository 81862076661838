import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import LabelValue from "components/LabelValue"
import Textfield from "inputs/Textfield"
import { elmaasRequest } from "data/elmaas/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import { stringRequired } from "validators/string"
import * as R from "ramda"

const CheckUpdateTaModal = ({ close, report, taxAccount }) => (
  <>
    <LabelValue mb={20} label="Filing entity name" value={report.entityName} />
    <LabelValue mb={20} label="Account name" value={taxAccount.accountName} />
    <LabelValue mb={20} label="Account ID" value={taxAccount.accountId} />
    <LabelValue mb={20} label="AuthSteuernummer (BOP)" value={taxAccount.bop} />
    <Textfield required width={130} name="pin" label="PIN" type="password" />
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="Cancel" />
      <Button type="submit" text="Check updates from TA" />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(R.always("Check udpates from TA")),
  withForm({
    schema: R.always({
      pin: stringRequired,
    }),
    onSubmit: p => v => elmaasRequest.updateStatus(p.reportId, v),
    onSuccess: p => () => {
      p.close()
      p.refetch()
    },
    successText: () =>
      R.always(
        "Request to update the report was sent. It will be processed in a few minutes.",
      ),
  }),
)(CheckUpdateTaModal)

import styled from "styled-components/macro"

export const HistoryItem = styled.div`
  padding: 15px 0;
  border-top: 1px solid #d8d8d8;

  &:first-child {
    border-top: 0;
    padding-top: 0;
  }
  svg {
    width: 15px;
    height: 15px;
    fill: #666;
    margin-right: 5px;
    vertical-align: bottom;
  }
`

import styled, { css } from "styled-components/macro"
import * as R from "ramda"
import theme from "theme"

export const StyledTab = styled.div`
  display: flex;
  align-items: center;
  height: 48px;
  font-size: 16px;
  color: #333;
  padding: 0 12px;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  ${p =>
    p.right &&
    p.arrows &&
    css`
      margin-right: 20px;
    `}

  &::before {
    position: absolute;
    left: calc(100% - 12px);
    font-size: 11px;
    bottom: 8px;
    color: ${p => (p.count === 0 ? "#999" : theme.color.red)};
  }

  ${p =>
    !R.isNil(p.count) &&
    css`
      &::before {
        content: "${p.count > 99 ? "99+" : p.count}";
      }
    `};

  &::after {
    display: block;
    position: absolute;
    content: "";
    bottom: 0;
    left: 0;
    width: 100%;
    height: 4px;
    background: ${theme.color.red};
    transform: scaleX(0);
    transition: transform 0.25s ease;
  }

  svg {
    margin-right: 10px;
  }

  ${p =>
    p.active &&
    css`
      color: ${theme.color.red};

      svg {
        fill: ${theme.color.red};
      }

      &::after {
        transform: scaleX(1);
      }
    `};
  ${p =>
    p.active &&
    p.disableActive &&
    css`
      cursor: default;
    `};

  &:hover {
    color: ${theme.color.red};

    svg {
      fill: ${theme.color.red};
    }
  }
  ${p =>
    p.inPanel &&
    css`
      bottom: -1px;
      line-height: 46px;
      padding: 0 20px;

      &::before {
        left: calc(100% - 19px);
      }
    `};
  ${p =>
    p.arrows &&
    css`
      padding: 0;
      &:first-child {
        margin-left: 20px;
      }
    `};

  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
      pointer-events: none;
    `};
  ${p =>
    p.underline &&
    css`
      padding: 0;
      margin-right: 20px;

      &::after {
        height: 3px;
        z-index: 1;
      }
    `};
`

export const ArrowWrap = styled.div`
  width: 40px;
  flex-shrink: 0;
  position: relative;
  text-align: center;
`

import * as R from "ramda"
import { parseSearch } from "common/router"

export const getTemplateId = (location, assessment) => {
  const search = parseSearch(location.search)

  if (R.isNil(search.templateId)) return assessment.templateId

  return +search.templateId
}

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M12,0 C5.372,0 0,5.373 0,12 C0,18.627 5.372,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 Z M12,22.5 C6.21,22.5 1.5,17.79 1.5,12 C1.5,6.21 6.21,1.5 12,1.5 C17.79,1.5 22.5,6.21 22.5,12 C22.5,17.79 17.79,22.5 12,22.5 Z M12.7505,11.2501 L12.7505,4.5601 L11.2505,4.5601 L11.2505,12.7501 L19.4405,12.7501 L19.4405,11.2501 L12.7505,11.2501 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"
import Textfield from "inputs/Textfield"
import withFormField from "hoc/withFormField"
import Button from "components/Button"
import { localize } from "locale"
import { formatUserName, formatInitialsFromName } from "data/user/formatters"
import { emailRequired } from "validators/string"
import { InputContactsWrap } from "./styled"
import UserList from "inputs/UserList"
import * as R from "ramda"
import withState from "hoc/withState"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"

const InputContacts = ({
  v,
  setV,
  disabled,
  handleAdd,
  handleRemove,
  handleClear,
  value,
  readOnly,
  splitName,
  listTitle = localize("totalContacts"),
}) => (
  <>
    {!readOnly && (
      <InputContactsWrap splitName={splitName}>
        {splitName ? (
          <>
            <Textfield
              value={R.pathOr("", ["firstName"], v)}
              onChange={x => setV(R.set(R.lensPath(["firstName"]), x, v))}
              placeholder={localize("firstName")}
            />
            <Textfield
              value={R.pathOr("", ["lastName"], v)}
              onChange={x => setV(R.set(R.lensPath(["lastName"]), x, v))}
              placeholder={localize("surname")}
            />
          </>
        ) : (
          <Textfield
            value={R.pathOr("", ["name"], v)}
            onChange={x => setV(R.set(R.lensPath(["name"]), x, v))}
            placeholder={localize("name")}
          />
        )}

        <Textfield
          value={R.pathOr("", ["email"], v)}
          onChange={x => setV(R.set(R.lensPath(["email"]), x, v))}
          placeholder={localize("email")}
        />
        <Button
          text={localize("add")}
          disabled={disabled}
          onClick={handleAdd}
        />
      </InputContactsWrap>
    )}
    {value && value.length > 0 && (
      <UserList
        value={value}
        title={listTitle}
        optionToAvatar={
          splitName
            ? R.pipe(formatUserName, formatInitialsFromName)
            : x => formatInitialsFromName(x.name)
        }
        optionToTitle={splitName ? formatUserName : R.prop("name")}
        optionToSubtitle={R.prop("email")}
        removeUser={readOnly ? undefined : handleRemove}
        clear={readOnly ? undefined : handleClear}
      />
    )}
  </>
)

export default R.compose(
  withFormField,
  withState("v", "setV", {}),
  withProps(p => {
    const nameValid = p.splitName ? p.v.firstName && p.v.lastName : p.v.name
    return {
      disabled: emailRequired(p.v.email) || !nameValid,
    }
  }),
  withHandlers({
    handleAdd: p => () => {
      const oldValue = p.value || []
      p.onChange(oldValue.concat(p.v))
      p.setV({})
    },
    handleRemove: p => i => {
      p.onChange(R.remove(i, 1, p.value))
    },
    handleClear: p => () => p.onChange([]),
  }),
)(InputContacts)

import styled, { css } from "styled-components/macro"
import theme from "theme"

export const ActivePanel = styled.div`
  border: 1px solid ${theme.color.border};
  border-radius: 2px;
  background-color: #fff;
  box-shadow: 0 1px 3px 0 ${theme.color.shadow};

  ${p =>
    !p.noPadding &&
    css`
      padding: 20px;
    `}

  ${p =>
    p.active &&
    css`
      border-color: ${theme.color.red};
      cursor: default;
    `};

  ${p =>
    p.disabled &&
    !p.disabledNoBg &&
    css`
      background: #eee;
    `};

  ${p =>
    p.hide &&
    css`
      border: 0;
      margin: 0;
      padding: 0;
    `}
`

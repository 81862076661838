import styled, { css, keyframes } from "styled-components/macro"
import theme from "theme"

export const disappear = keyframes`
  0%{
    opacity: 1;
  }
  to{
    transform: scaleY(0);
    opacity: 0;
  }
`

export const ToastItemWrap = styled.div`
  display: flex;
  justify-content: center;
  transform-origin: top center;
  margin-top: 10px;

  ${p =>
    p.closing &&
    css`
      animation: ${disappear} 0.5s ease;
    `}
`

export const StyledToast = styled.div`
  pointer-events: all;
  cursor: pointer;
  padding: 9px 20px;
  width: 100%;
  max-width: 600px;
  color: ${theme.color.successDark};
  background: ${theme.color.successLight};
  border-radius: 2px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${p =>
    p.type === "error" &&
    css`
      color: ${theme.color.errorDark};
      background: ${theme.color.errorLight};
    `};

  svg {
    fill: ${theme.color.successDark};
    width: 16px;
    height: 16px;
    flex-shrink: 0;

    ${p =>
      p.type === "error" &&
      css`
        fill: ${theme.color.errorDark};
      `};
  }
`

import React from "react"
import {
  reportingRequest,
  conclusionCommentsRequests,
} from "data/reporting/api"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import Panel from "components/Panel"
import Button from "components/Button"
import Columns from "components/Columns"
import { downloadContentAsFile } from "common/files"
import Comments from "components/Comments"
import LabelValue from "components/LabelValue"
import { getStatusName } from "data/assessments/statuses"
import { formatDate } from "data/units/date/formatters"
import * as R from "ramda"
import ButtonModal from "components/ButtonModal"
import PollModal from "components/PollModal"

const ColumnsOf3 = ({ children }) => {
  const tmp = children.concat(
    R.range(0, children.length % 3).map(x => <div key={x} />),
  )
  const threes = R.splitEvery(3, tmp)

  return threes.map(x => <Columns>{x}</Columns>)
}

const Reporting = ({ assessmentId, reporting, fetchCount, template }) => {
  const identifiers = R.pathOr([], ["reportIdentifiers"], reporting)
  const internal = identifiers.filter(x => x.type === "Internal")
  const external = identifiers.filter(x => x.type === "External")
  const decisions = identifiers.filter(x => x.type === "ReportingDecisions")

  const hasXml = template.schemas.length !== 0

  return (
    <>
      <Panel mb={20}>
        <ColumnsOf3>
          {[
            <LabelValue
              mb={15}
              label={localize("dueDate")}
              value={formatDate(reporting.dueDate)}
            />,
            ...decisions.map((x, xi) => (
              <LabelValue key={xi} mb={15} label={x.text} value={x.value} />
            )),
          ]}
        </ColumnsOf3>
        {external.length > 0 && (
          <>
            <P gray mt={30} mb={10}>
              {localize("taxAuthorityIds")}:
            </P>
            <ColumnsOf3>
              {external.map((x, xi) => (
                <LabelValue key={xi} mb={15} label={x.text} value={x.value} />
              ))}
            </ColumnsOf3>
          </>
        )}
        {internal.length > 0 && (
          <>
            <P gray mt={30} mb={10}>
              {localize("internalLocalReportingIds")}:
            </P>
            <ColumnsOf3>
              {internal.map((x, xi) => (
                <LabelValue key={xi} mb={15} label={x.text} value={x.value} />
              ))}
            </ColumnsOf3>
          </>
        )}
      </Panel>
      {reporting.doReport && (
        <Panel mb={20}>
          <Button
            text="XML report preview"
            onClick={() =>
              downloadContentAsFile(reportingRequest.downloadXML(assessmentId))
            }
            disabled={!hasXml}
            mr={10}
          />
          <ButtonModal
            text="PDF report preview"
            disabled={!hasXml}
            modal={PollModal}
            initRequest={reportingRequest.initDownloadPDF(assessmentId, {
              type: "PdfReportPreview",
            })}
            title={localize("downloadPDF")}
            body={localize("pdfIsBeingGeneratedPleaseWait")}
            mr={10}
          />
        </Panel>
      )}
      <Panel>
        <P mb={30}>
          <P inline>{localize("conclusionStatus")}:</P>{" "}
          <P inline bold>
            {getStatusName(reporting.conclusionStatus)}
          </P>
        </P>
        <Comments
          key={fetchCount}
          requests={conclusionCommentsRequests(assessmentId)}
          readOnly
          noAdd
        />
      </Panel>
    </>
  )
}

export default Reporting

import React from "react"
import CaretIcon from "icons/Caret"
import { CaretTd } from "styledComponents/Table"
import { P } from "styledComponents/Typography"
import Link from "components/Link"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { formatDate } from "data/units/date/formatters"
import { caseRequest } from "data/cockpit/api"
import FavoriteButton from "components/FavoriteButton"
import { IconWrap } from "styledComponents/Icons"
import CaseIcon from "icons/Case"
import useOpen from "hooks/useOpen"
import { Box } from "styledComponents/Containers"
import Status from "components/Status"
import { caseStatuses } from "data/cockpit/statuses"
import { caseSuburls } from "pages/CaseCreatePage/suburls"
import LabelValue from "components/LabelValue"
import Columns from "components/Columns"
import { BaseTr, MoreTr } from "./styled"
import { conclusionColors } from "data/conclusions/colors"
import NameWithModal from "./NameWithModal"

const TableRow = ({ item, rowIndex, urls, refetch }) => {
  const { isOpen, toggle } = useOpen()

  const stat = R.values(caseStatuses).find(x => x.value === item.status)

  return (
    <>
      <BaseTr rowIndex={rowIndex} isOpen={isOpen}>
        <td>
          <FavoriteButton
            isFavorite={item.isFavorite}
            request={caseRequest.setFavorite(item.id)}
            onSuccess={refetch}
          />
        </td>
        <td>
          <Box flex acenter>
            <IconWrap large mr={5} static black>
              <CaseIcon />
            </IconWrap>
            {item.canAccess ? (
              <Link
                ul
                to={urls.caseCreate(
                  item.id,
                  item.involvedParties
                    ? caseSuburls.detail
                    : caseSuburls.country,
                )}
              >
                {item.name}
              </Link>
            ) : (
              <NameWithModal name={item.name} />
            )}
          </Box>
        </td>
        <td>
          <Status color={stat.color} text={stat.name} />
        </td>
        <td>{item.includedObjects} objects</td>
        <td>
          <P lightgray>{formatDate(item.lastEditAtUtc)}</P>
        </td>
        <td>
          {item.aggregatedConclusionType && (
            <Status
              color={
                item.aggregatedConclusionType === "Reportable"
                  ? R.last(conclusionColors)
                  : R.head(conclusionColors)
              }
              text={item.aggregatedConclusionType}
            />
          )}
        </td>
        <CaretTd onClick={toggle} open={isOpen}>
          <CaretIcon />
        </CaretTd>
      </BaseTr>
      {isOpen && (
        <MoreTr>
          <td />
          <td colSpan={6}>
            <Box ml={8}>
              <P mb={20}>{item.description}</P>
              <Columns>
                <LabelValue
                  label="Involved parties"
                  value={item.involvedParties}
                />
                <LabelValue
                  label="Reportable jurisdiction(s)"
                  value={item.reportableJurisdictions}
                />
                <LabelValue
                  label="Assessment jurisdiction(s)"
                  value={item.assessmentJurisdictions}
                />
              </Columns>
            </Box>
          </td>
        </MoreTr>
      )}
    </>
  )
}

export default withUrls(TableRow)

import React from "react"
import { formatDate, formatTime } from "data/units/date/formatters"
import FillClose from "icons/appkit/fill-close"
import FillNews from "icons/appkit/fill-news"
import FillSettings from "icons/appkit/fill-settings"
import FillSurvey from "icons/appkit/fill-survey"
import OutlineAlert from "icons/appkit/outline-alert"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import theme from "theme"
import { NotificationIcon, NotifItem } from "./styled"
import DowloadIcon from "icons/Download"
import * as R from "ramda"

const getIcon = t => {
  // template, 13 = template pool
  if (t === 1 || t === 7 || t === 13) {
    return <FillNews />
  }

  // assesment
  if (t === 2 || t === 8 || t === 19) {
    return <FillSurvey />
  }
  // conclusion export
  if (t === 5) {
    return <DowloadIcon />
  }
  // client
  if (t === 3) {
    return <FillSettings />
  }

  return <OutlineAlert />
}

const colors = {
  1: theme.color.pink,
  2: theme.color.yellow,
  3: theme.color.orange,
  5: theme.color.red,
  7: theme.color.pink,
  8: theme.color.yellow,
  11: theme.color.orange,
  13: theme.color.pink,
  19: theme.color.yellow,
}

const NotificationItem = ({ notification, markAsRead }) => {
  const body =
    R.type(notification.body) === "Object" ? "N/A" : notification.body

  return (
    <>
      <NotifItem
        unread={!notification.seenAtUtc}
        onClick={() => markAsRead({ notification })}
      >
        <NotificationIcon
          bg={colors[notification.notificationType] || theme.color.orange}
        >
          {getIcon(notification.notificationType)}
        </NotificationIcon>
        <Box grow>
          <P bold={!notification.seenAtUtc} mb={10}>
            {body}
          </P>
          <P gray fs={12}>
            {[
              formatDate(notification.createdAtUtc),
              formatTime(notification.createdAtUtc),
            ].join(", ")}
          </P>
        </Box>
        <IconWrap
          onClick={e => {
            e.stopPropagation()
            markAsRead({ notification, silent: true })
          }}
        >
          <FillClose />
        </IconWrap>
      </NotifItem>
    </>
  )
}

export default NotificationItem

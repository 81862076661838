import { ReactPlugin } from "@microsoft/applicationinsights-react-js"
import { ApplicationInsights } from "@microsoft/applicationinsights-web"
import { history } from "AppComponent/history"
import config from "config"

export const reactPlugin = new ReactPlugin()

const cfg = config.appInsights ?? {}

const { connectionString, ...rest } = cfg

const appInsights = new ApplicationInsights({
  config: {
    connectionString,
    ...rest,
    extensions: [reactPlugin],
    extensionConfig: {
      [reactPlugin.identifier]: { history },
    },
  },
})

if (connectionString) {
  appInsights.loadAppInsights()
}

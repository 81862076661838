import React from "react"
import { assessmentQuestionRequest } from "data/assessments/api"
import { assessmentLibraryQuestionRequest } from "data/assessments/api"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import { emptyRequest, parallel } from "common/rxjs"
import withData from "hoc/withData"
import Questions from "../Questions"
import { localize } from "locale"
import Button from "components/Button"
import { Box } from "styledComponents/Containers"
import withProps from "hoc/withProps"
import * as R from "ramda"
import { previewRequest } from "data/templates/api"

const ReportingQuestions = ({
  tree,
  questions,
  lastId,
  assessmentId,
  assessment,
  bookmarkedOnly,
  isPreview,
  canEdit,
  refetchData,
  suburls,
  questionFilter,
  isLibrary,
}) => {
  return (
    <>
      <Questions
        tree={tree}
        questions={questions}
        lastId={lastId}
        assessmentId={assessmentId}
        assessment={assessment}
        bookmarkedOnly={bookmarkedOnly}
        isPreview={isPreview}
        canEdit={canEdit}
        refetch={refetchData}
        emptyText={localize("allInformationHasBeenProvided")}
        questionFilter={questionFilter}
        isLibrary={isLibrary}
      />
      <Box flex mt={20}>
        <Box ml="auto">
          <Button
            text={localize("done")}
            to={isLibrary ? suburls.settings.url : suburls.preview.url}
          />
        </Box>
      </Box>
    </>
  )
}

export default R.compose(
  withData(p => {
    const getQuestionListRequest = () => {
      if (p.isPreview) return previewRequest.questions
      if (p.isLibrary) return assessmentLibraryQuestionRequest.list

      return assessmentQuestionRequest.list
    }
    const getLastPositionRequest = () => {
      if (p.isPreview) return () => emptyRequest
      if (p.isLibrary) return assessmentLibraryQuestionRequest.lastPosition

      return assessmentQuestionRequest.lastPosition
    }

    return parallel(
      getQuestionListRequest()(p.assessmentId),
      getLastPositionRequest()(p.assessmentId),
    )
  }),
  withProps(() => ({
    questionFilter: x => x.data.forReportingOnly,
  })),
  withProps(p => {
    const [qs, lastId] = p.data
    const tree = buildTree(qs, p.questionFilter)
    return {
      tree,
      questions: getQuestionsFromTree(tree),
      lastId,
    }
  }),
)(ReportingQuestions)

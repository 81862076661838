import React from "react"
import { openLink } from "common/files"
import Text from "components/Text"
import { helpdeskRequest } from "data/overview/api"
import withSubmit from "hoc/withSubmit"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import { TutorialWrap } from "./styled"
import * as R from "ramda"
import { toastError } from "data/toasts/rx"

const Tutorial = ({ tutorial, submit }) => (
  <TutorialWrap>
    <P
      fs={16}
      gray={!tutorial.hasUrl}
      mb={12}
      red={tutorial.hasUrl}
      link={tutorial.hasUrl}
      hovernolink={tutorial.hasUrl}
      onClick={tutorial.hasUrl ? submit : undefined}
    >
      {tutorial.name}
    </P>
    <Text>{tutorial.description}</Text>
  </TutorialWrap>
)

export default R.compose(
  withSubmit({
    onSubmit: p => () => helpdeskRequest.tutorial(p.tutorial.id),
    onSuccess: p => v =>
      v === "" ? toastError(localize("noTutorialProvided")) : openLink(v),
  }),
)(Tutorial)

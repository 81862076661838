import React from "react"

const timers = {}

const withFilters =
  (debounceKeys = []) =>
  Component =>
  props => {
    const [f, setF] = React.useState(null)

    const set = (v, k) => {
      if (!v && !k) {
        return setF(null)
      }

      if (debounceKeys.includes(k)) {
        clearTimeout(timers[k])
        timers[k] = setTimeout(() => {
          setF(v)
        }, 300)
        return
      }

      setF(v)
    }

    return <Component {...props} filters={f} setFilters={set} />
  }

export default withFilters

import React from "react"
import SearchIcon from "icons/Search"
import Textfield from "inputs/Textfield"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { FilterRight, FilterWrap } from "./styled"

const Filters = ({
  configFilters = [],
  data,
  search,
  setSearch,
  filterChange,
  filters,
  showSearch,
  searchPlaceholder,
}) => {
  if (!showSearch && configFilters.length === 0) return null

  return (
    <FilterWrap>
      <FilterRight>
        {configFilters.map((f, fi) => (
          <React.Fragment key={fi}>
            {f.render(filters[f.name], filterChange(f.name), data)}
          </React.Fragment>
        ))}
        {showSearch && (
          <Box width={200} flex acenter>
            <Textfield
              value={search}
              placeholder={searchPlaceholder ?? localize("search")}
              icon={SearchIcon}
              onChange={setSearch}
              clearable
              small
            />
          </Box>
        )}
      </FilterRight>
    </FilterWrap>
  )
}

export default Filters

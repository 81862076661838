import React from "react"
import CaretIcon from "icons/Caret"
import { CaretTd, Tr } from "styledComponents/Table"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import Link from "components/Link"
import ArrangementId from "components/ArrangementId"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { formatDate } from "data/units/date/formatters"
import Checkbox from "inputs/Checkbox"
import Status from "components/Status"
import Columns from "components/Columns"
import { indexColorsByStatuses } from "data/conclusions/colors"
import { formatConclusionStatus } from "data/conclusions/formatters"
import { assessmentColors } from "data/assessments/colors"
import { localize } from "locale"
import LabelValue from "components/LabelValue"
import dayjs from "dayjs"
import theme from "theme"
import useOpen from "hooks/useOpen"
import { reportStatuses, conclusionStatuses } from "data/assessments/statuses"

const TableRow = ({
  item,
  rowIndex,
  urls,
  ids,
  handleCheck,
  conclusionTypes,
  suburls,
}) => {
  const identifiers = R.pathOr([], ["reportIdentifiers"], item)
  const { isOpen, toggle } = useOpen()

  const isLate = dayjs(item.dueDateUtc).isBefore(dayjs(), "days")
  const showOverdueError = ![
    ...R.values(reportStatuses),
    conclusionStatuses.conclusionDeleted,
    conclusionStatuses.conclusionRejected,
  ]
    .map(R.prop("value"))
    .includes(item.status)

  const getLink = item.conclusionType
    ? suburls.conclusion.url
    : suburls.assessment.url

  return (
    <>
      <Tr rowIndex={rowIndex}>
        <td>
          <Box flex acenter>
            <Checkbox
              value={ids.includes(item.assessmentId)}
              onChange={() => handleCheck(item.assessmentId)}
              squared
              mr={10}
            />
            <ArrangementId
              arrangementId={item.arrangementId}
              getLink={getLink}
            />
          </Box>
        </td>
        <td>
          <Link ul to={getLink(item.assessmentId)}>
            {item.name}
          </Link>
        </td>
        <td>{item.countryCode}</td>
        <td>
          <Status
            color={assessmentColors[item.status]}
            text={localize(item.status)}
          />
        </td>
        <td>
          {item.conclusionType && (
            <Status
              color={
                indexColorsByStatuses(conclusionTypes)[item.conclusionType]
              }
              text={formatConclusionStatus(item.conclusionType)}
              rejected={
                item.status === conclusionStatuses.conclusionRejected.value
              }
            />
          )}
        </td>
        <td>
          <Status
            color={isLate && showOverdueError ? theme.color.error : undefined}
            text={formatDate(item.dueDateUtc)}
          />
        </td>
        <td>{item.contact}</td>
        <td>
          <P lighgray>{formatDate(item.lastStatusChange)}</P>
        </td>
        <CaretTd onClick={toggle} open={isOpen}>
          <CaretIcon />
        </CaretTd>
      </Tr>
      {isOpen && (
        <tr>
          <td></td>
          <td colSpan={8}>
            <Columns>
              <Box>
                <P gray mt={30} mb={10}>
                  {localize("taxAuthorityIds")}:
                </P>
                <Box flex>
                  {identifiers
                    .filter(x => x.type === "External")
                    .map((x, xi) => (
                      <LabelValue
                        ml={xi === 0 ? 0 : 30}
                        key={xi}
                        label={x.text}
                        value={x.value}
                      />
                    ))}
                </Box>
              </Box>
              <Box>
                <P gray mt={30} mb={10}>
                  {localize("internalLocalReportingIds")}:
                </P>
                <Box flex>
                  {identifiers
                    .filter(x => x.type === "Internal")
                    .map((x, xi) => (
                      <LabelValue
                        ml={xi === 0 ? 0 : 30}
                        key={xi}
                        label={x.text}
                        value={x.value}
                      />
                    ))}
                </Box>
              </Box>
            </Columns>
          </td>
        </tr>
      )}
    </>
  )
}

export default R.compose(withUrls)(TableRow)

import styled, { css } from "styled-components/macro"

export const RibbonsWrap = styled.div`
  display: flex;
  ${p =>
    !p.inline &&
    css`
      position: absolute;
      top: 0;
      z-index: 1;
      ${p =>
        p.right
          ? css`
              right: ${p.right}px;
            `
          : css`
              left: ${p.left}px;
            `}
      transform: translateY(-50%);
    `};
`

export const Ribbon = styled.div`
  background: #666;
  font-size: 10px;
  padding: 2px 5px;
  border-radius: 2px;
  color: white;
  margin-right: 5px;

  ${p =>
    p.light &&
    css`
      border-radius: 12px;
      color: #464646;
      background: #dedede;
      font-size: 12px;
      padding: 5px 10px;
      font-weight: bold;
    `};
`

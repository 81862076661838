import React from "react"
import Table from "components/Table"
import { config } from "./config"
import withData from "hoc/withData"
import withFilters from "hoc/withFilters"
import * as R from "ramda"
import { caseRequest } from "data/cockpit/api"

const List = ({ data, loading, setFilters, suburls, caseId }) => (
  <Table
    data={R.pathOr([], ["massReports"], data)}
    loading={loading}
    count={R.path(["count"], data)}
    config={config(suburls, caseId)}
    onParamChange={setFilters}
    sorting={{
      index: 1,
      desc: true,
    }}
  />
)

const getSearchParams = (filters, caseId) =>
  [
    `orderBy=${R.pathOr("CreatedAtUtc", ["sort", "key"], filters)}`,
    `orderDescending=${R.pathOr(true, ["sort", "desc"], filters)}`,
    `page=${R.pathOr(0, ["page"], filters)}`,
    `size=${R.pathOr(10, ["size"], filters)}`,
    filters && filters.search ? `search=${filters.search}` : "",
    filters && filters.filters.id ? `idSearch=${filters.filters.id}` : "",
    `caseId=${caseId}`,
  ]
    .filter(x => x !== "")
    .join("&")

export default R.compose(
  withFilters(["search"]),
  withData(p => caseRequest.listReports(getSearchParams(p.filters, p.caseId)), {
    refetchParams: p => [getSearchParams(p.filters, p.caseId)],
    noLoader: true,
    clearOnRefetch: true,
  }),
)(List)

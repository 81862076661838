import { parseSearch } from "common/router"
import { statuses } from "data/assessments/statuses"
import * as R from "ramda"

// one field should be "status" and one "type" but they are sometimes used interchangably so that makes the names a little confusing
// tabStatus comes from the tab value on conclusions table
// filterStatus comes from the table select filter
const getFilter = (tabStatus, caseId, filterStatus) => {
  const getStatus = () => {
    if (!R.isNil(caseId)) {
      return `conclusionStatus=${statuses.conclusionReadyForGeneration.value}`
    }

    if (tabStatus === statuses.conclusionDeleted.value) {
      return `conclusionStatus=${statuses.conclusionDeleted.value}`
    }

    if (filterStatus) {
      return `conclusionStatus=${filterStatus}`
    }

    return ""
  }

  const getType = () => {
    if (tabStatus === statuses.all.value) {
      return ""
    }

    if (tabStatus === statuses.conclusionDeleted.value) {
      return ""
    }

    if (!tabStatus) {
      return ""
    }

    return `conclusionType=${tabStatus}`
  }

  // conclusionStatus is hardset on case view

  return [getStatus(), getType()]
}

export const getSearchParams = (status, search, caseId) => {
  const v = parseSearch(search)

  return [
    ...getFilter(status, caseId, v.status),
    `orderBy=${R.pathOr("ApprovedAtUtc", ["orderBy"], v)}`,
    `orderDescending=${R.pathOr(true, ["orderDescending"], v)}`,
    `page=${R.pathOr(0, ["page"], v)}`,
    `size=${R.pathOr(10, ["size"], v)}`,
    v.xml ? `hasXmlReportPreview=${v.xml}` : "",
    v.search ? `nameLike=${v.search}` : "",
    v.id ? `reportingIdFilter=${v.id}` : "",
    v.country ? `countryCodeFilter=${v.country}` : "",
    v.type ? `conclusionType=${v.type}` : "",
    `favoritesOnly=${R.pathOr(false, ["favoritesOnly"], v)}`,
    `overdueOnly=${R.pathOr(false, ["overdueOnly"], v)}`,
    `marketableArrangementOnly=${R.pathOr(
      false,
      ["marketableArrangementOnly"],
      v,
    )}`,
    R.isNil(caseId) ? "" : `caseId=${caseId}`,
  ]
    .filter(x => x !== "")
    .join("&")
}

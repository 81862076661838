export const getAnswerInsertIndex = (nodes, parentNodeId, previousNodeId) => {
  if (!previousNodeId) {
    // no previous node, insert after parent node
    return nodes.findIndex(x => x.id === parentNodeId) + 1
  }
  // previous node exists, so insert after it
  const previousNodeIndex = nodes.findIndex(x => x.id === previousNodeId)
  const previousNode = nodes[previousNodeIndex]
  const previousNodeIsSubsection =
    previousNode.nodeType === "section" && previousNode.parentId !== null

  if (previousNodeIsSubsection) {
    return (
      previousNodeIndex +
      nodes.filter(x => x.parentId === previousNodeId).length +
      1
    )
  }

  return previousNodeIndex + 1
}

export const showAddButtons = ({ canEdit, moveIndex, copyIndex }) =>
  canEdit && !moveIndex && !copyIndex

export const showMoveSectionToStart = ({ moveIndex }) =>
  moveIndex && moveIndex.type === "section" && moveIndex.sectionIndex > 0

export const showCopySectionToStart = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "section"

export const showMoveSection = ({ moveIndex, sectionIndex }) =>
  moveIndex &&
  moveIndex.type === "section" &&
  moveIndex.sectionIndex !== sectionIndex &&
  moveIndex.sectionIndex !== sectionIndex + 1

export const showCopySection = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "section"

export const showCopySubsection = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "subsection"

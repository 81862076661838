import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { conclusionRequest } from "data/templates/api"
import withForm from "hoc/withForm"
import DeleteIcon from "icons/appkit/fill-delete"
import EditIcon from "icons/appkit/fill-edit"
import SortIcon from "icons/appkit/fill-sort-default"
import InputLogic from "inputs/InputLogic"
import RichTextarea from "inputs/RichTextarea"
import { localize } from "locale"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { arrayOptional } from "validators/array"
import { longStringRequired } from "validators/string"
import { ActiveIcon } from "styledComponents/Icons"
import { NodeHeader } from "../../nodeStyles"
import Panel from "components/Panel"
import ConfirmDeleteModal from "./ConfirmDeleteModal"
import TemplateEditWarning from "components/TemplateEditWarning"
import { scrollToId } from "common/scroll"
import useOpen from "hooks/useOpen"

const ReasoningForm = ({
  reasoning,
  editIndex,
  onEdit,
  onCancelEdit,
  questions,
  first,
  templateId,
  onSuccess,
  canEdit,
  canEditAfterDone,
  onMove,
  moveIndex,
  count,
  index,
}) => {
  const { isOpen, open, close } = useOpen()

  const isEdited = editIndex && editIndex.reasoningId === reasoning.id

  React.useEffect(() => {
    if (isEdited) {
      scrollToId(`r-${reasoning.id}`)
    }
  }, [isEdited]) // eslint-disable-line

  return (
    <Panel mt={first ? 0 : 20} id={`r-${reasoning.id}`}>
      <NodeHeader>
        <P bold>
          {localize("reasoning")} {index + 1}
        </P>
        {(canEdit || canEditAfterDone) && (
          <Box flex ml="auto">
            <ActiveIcon
              disabled={editIndex || moveIndex}
              onClick={() => onEdit({ reasoningId: reasoning.id })}
            >
              <EditIcon />
            </ActiveIcon>
            {canEditAfterDone && <TemplateEditWarning />}
            {canEdit && (
              <>
                <ActiveIcon onClick={open} disabled={editIndex || moveIndex}>
                  <DeleteIcon />
                </ActiveIcon>
                {count > 1 && (
                  <ActiveIcon
                    onClick={() =>
                      onMove(
                        moveIndex && moveIndex.reasoningId === reasoning.id
                          ? undefined
                          : {
                              reasoningId: reasoning.id,
                              position: reasoning.position,
                            },
                      )
                    }
                    disabled={
                      editIndex ||
                      (moveIndex && moveIndex.reasoningId !== reasoning.id)
                    }
                    active={moveIndex && moveIndex.reasoningId === reasoning.id}
                  >
                    <SortIcon />
                  </ActiveIcon>
                )}
              </>
            )}
          </Box>
        )}
      </NodeHeader>

      <InputLogic
        conditionText={localize("showReasoningIf")}
        name="precondition"
        readOnly={!isEdited || !canEdit}
        previousQuestions={questions}
        noEmptyError
      />
      <RichTextarea
        name="text"
        label={localize("reasoningText")}
        placeholder={localize("enterReasoningText")}
        readOnly={!isEdited}
        borderless={!isEdited}
        mb={0}
        noEmptyError
      />
      {isEdited && (
        <ModalButtons>
          <Button
            secondary
            type="button"
            onClick={onCancelEdit}
            text="Cancel"
          />
          <Button type="submit" text="Save" />
        </ModalButtons>
      )}
      {isOpen && (
        <ConfirmDeleteModal
          close={close}
          reasoningId={reasoning.id}
          templateId={templateId}
          onSuccess={onSuccess}
        />
      )}
    </Panel>
  )
}

export default withForm({
  mapProps: R.prop("reasoning"),
  schema: R.always({
    text: longStringRequired,
    precondition: arrayOptional,
  }),
  onSubmit: p => v => {
    const data = {
      text: v.text,
      precondition: v.precondition,
    }
    return p.reasoning.id === "new"
      ? conclusionRequest.createReasoning(p.templateId, data)
      : conclusionRequest.updateReasoning(p.templateId, p.reasoning.id, data)
  },
  onSuccess: p => p.onSuccess,
})(ReasoningForm)

import * as R from "ramda"
import withState from "hoc/withState"
import withProps from "hoc/withProps"

const withEditProps = (getCanEdit, getInitEditId = R.always(undefined)) =>
  R.compose(
    withState("ids", "setIds", p => ({
      editId: getInitEditId(p),
    })),
    withProps(p => ({
      editProps: {
        ids: p.ids,
        skipTo: id => {
          if (!getCanEdit(p)) {
            return
          }

          // nothing currently being edited or currently edited item has no unsaved changes
          if (R.isNil(p.ids.editId) || R.isNil(p.ids.dirtyId)) {
            return p.setIds({
              editId: id,
            })
          }

          p.setIds(x => ({
            ...x,
            skipToId: id,
            skipFromId: p.ids.editId,
          }))
        },
        // for after skip modal is resolved as submit/discard
        forceSkipTo: id =>
          p.setIds({
            editId: id,
          }),
        stopSkip: () => p.setIds(R.omit(["skipToId", "skipFromId"])),
        reset: () => p.setIds({}),
        setDirty: id => p.setIds(x => ({ ...x, dirtyId: id })),
      },
    })),
  )

export default withEditProps

import React from "react"
import ButtonModal from "components/ButtonModal"
import InfoModal from "components/InfoModal"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { conclusionErrorStatus } from "data/conclusions/statuses"
import HistoryModal from "./HistoryModal"
import CopyAssessmentModal from "components/CopyAssessmentModal"
import RejectModal from "./RejectModal"
import WithdrawModal from "./WithdrawModal"
import ConfirmModal from "components/ConfirmModal"
import DeleteModal from "./DeleteModal"
import AddToLibraryModal from "components/AddToLibraryModal"
import withSubmit from "hoc/withSubmit"
import { assessmentRequest } from "data/assessments/api"
import { conclusionRequest } from "data/conclusions/api"
import { conclusionStatuses } from "data/assessments/statuses"
import withUrls from "hoc/withUrls"
import { localize } from "locale"
import * as R from "ramda"
import useOpen from "hooks/useOpen"
import { execute } from "common/rxjs"
import {
  preassessmentRequest,
  preconclusionRequest,
} from "data/preassessments/api"
import { getCaseUrl } from "data/cockpit/redirect"

const TitleControl = ({
  conclusion,
  submit,
  assessment,
  refetch,
  urls,
  match,
  routes,
  isPreassessment,
  case_,
  refetchCase,
}) => {
  const { isOpen, open, close } = useOpen()

  const status = assessment.status

  const stateForReview =
    (status === conclusionStatuses.conclusionReadyForReview.value ||
      status === conclusionStatuses.conclusionApproved.value) &&
    status !== conclusionStatuses.conclusionReadyForGeneration.value &&
    status !== conclusionStatuses.conclusionReviewed.value
  const stateForWithdrawReject =
    status === conclusionStatuses.conclusionReadyForReview.value ||
    status === conclusionStatuses.conclusionApproved.value ||
    status === conclusionStatuses.conclusionReadyForGeneration.value ||
    status === conclusionStatuses.conclusionReviewed.value
  const conclusionDeleted =
    assessment.status === conclusionStatuses.conclusionDeleted.value

  return (
    <ModalButtons nomargin>
      <ButtonModal
        secondary
        text={localize("history")}
        assessmentId={assessment.id}
        modal={HistoryModal}
        isPreassessment={isPreassessment}
      />
      {conclusionDeleted && (
        <>
          {case_?.hasActivePreAssessment && isPreassessment ? (
            <ButtonModal
              secondary
              text="Restore"
              modal={InfoModal}
              title="Pre-assessment already exists for the case"
              body="Pre-assessment cannot be restored, because there is already existing pre-assessment under the case. Only 1 pre-assessment for the case is allowed."
            />
          ) : (
            <ButtonModal
              secondary
              text="Restore"
              modal={ConfirmModal}
              title={`Restore ${
                isPreassessment ? "pre-assessment" : "conclusion"
              }`}
              confirmText={`${
                isPreassessment ? "Pre-assessment" : "Conclusion"
              } ${
                conclusion.name
              } will be restored into original reporting status. Notification will be sent to all users, who have access to this ${
                isPreassessment ? "pre-assessment" : "conclusion"
              }.`}
              buttonText={`Do you want to restore the ${
                isPreassessment ? "pre-assessment" : "conclusion"
              }?`}
              cancelText="No"
              submitText="Yes"
              request={() =>
                (isPreassessment
                  ? preconclusionRequest
                  : conclusionRequest
                ).restore({
                  assessmentIds: [assessment.id],
                })
              }
              onSuccess={() => {
                refetchCase?.()
              }}
              successText={() =>
                `You have restored the ${
                  isPreassessment ? "pre-assessment" : "conclusion"
                } ${conclusion.name}.`
              }
              redirect={() => {
                if (match.path.startsWith(routes.caseCreate.path)) {
                  return getCaseUrl(match, assessment)
                }
                return urls.conclusions
              }}
            />
          )}
        </>
      )}
      {conclusionDeleted && (
        <ButtonModal
          text="Delete permanently"
          modal={ConfirmModal}
          title={`Permanently delete ${
            isPreassessment ? "pre-assessment" : "conclusion"
          }`}
          confirmText={`${isPreassessment ? "Pre-assessment" : "Conclusion"} ${
            conclusion.name
          } will be permanently deleted and cannot be restored.${
            isPreassessment
              ? ""
              : `\nIf included in the mass report object it will be also permanently removed from this object, which will cause inconsitency between mass report detail and XML report file, unless the mass report object is also deleted.`
          }`}
          buttonText={`Do you want to permanently delete the ${
            isPreassessment ? "pre-assessment" : "conclusion"
          }?`}
          cancelText="No"
          submitText="Yes"
          request={() =>
            (isPreassessment
              ? preconclusionRequest
              : conclusionRequest
            ).deletePermanent({
              assessmentIds: [assessment.id],
            })
          }
          redirect={() => {
            if (match.path.startsWith(routes.caseCreate.path)) {
              return getCaseUrl(match, assessment)
            }
            return urls.conclusions
          }}
          successText={() =>
            localize("permanentlyDeleteConclusionSuccess")(conclusion.name)
          }
          submitDanger
        />
      )}
      {!isPreassessment && (
        <ButtonModal
          secondary
          text={localize("makeACopy")}
          assessment={assessment}
          modal={CopyAssessmentModal}
          title={`${localize("makeACopy")} ${conclusion.name}`}
          successText={() => localize("conclusionCopySuccess")(conclusion.name)}
        />
      )}
      {conclusion.canWithdrawConclusion &&
        !conclusion.isRejectedConclusion &&
        stateForWithdrawReject && (
          <ButtonModal
            text={localize("withdraw")}
            modal={WithdrawModal}
            conclusion={conclusion}
            assessment={assessment}
            refetch={refetch}
            isPreassessment={isPreassessment}
            secondary
            onSuccess={() => {
              refetchCase?.()
            }}
            caseId={case_?.id}
          />
        )}
      {conclusion.canRejectAndRegenerateConclusion &&
        stateForReview &&
        !isPreassessment && (
          <ButtonModal
            text={localize("review")}
            modal={ConfirmModal}
            confirmText={localize(
              "pleaseConfirmThatYouHavePerformedTheConclusionReview",
            )}
            submitText={localize("approve")}
            title={localize("conclusionReview")}
            request={() => conclusionRequest.review(conclusion.id)}
            redirect={() => {
              if (match.path.startsWith(routes.caseCreate.path)) {
                return getCaseUrl(match, assessment)
              }
              return urls.conclusions
            }}
            successText={() =>
              localize("conclusionHasBeenReviewedSuccessfully")(conclusion.name)
            }
            secondary
          />
        )}
      {conclusion.canRejectAndRegenerateConclusion &&
        conclusion.isRejectedConclusion && (
          <Button
            text={localize("regenerateAssessment")}
            onClick={submit}
            secondary
          />
        )}

      {conclusion.canRejectAndRegenerateConclusion &&
        !conclusion.isRejectedConclusion &&
        stateForWithdrawReject &&
        !isPreassessment && (
          <ButtonModal
            text={localize("reject")}
            modal={RejectModal}
            conclusion={conclusion}
            assessment={assessment}
            refetch={refetch}
            secondary
          />
        )}
      {conclusion.status !== conclusionErrorStatus && !conclusionDeleted && (
        <Button
          text={localize("export")}
          secondary
          onClick={() => {
            open()
            execute(
              (isPreassessment
                ? preassessmentRequest
                : assessmentRequest
              ).exportDetailAsZip(
                assessment.id,
                isPreassessment ? "Pre-conclusionZip" : "ConclusionZip",
              ),
            )
          }}
        />
      )}
      {isOpen && (
        <InfoModal
          close={close}
          title={localize("export")}
          text={localize("exportIsBeingGenerated")}
        />
      )}
      {assessment.canEditCoworkers && !conclusionDeleted && (
        <ButtonModal
          secondary
          text={localize("createLibraryItem")}
          assessment={assessment}
          isPreassessment={isPreassessment}
          isConclusion={true}
          modal={AddToLibraryModal}
        />
      )}
      {assessment.canDelete && !conclusionDeleted && (
        <ButtonModal
          secondary
          modal={DeleteModal}
          text="Delete"
          conclusion={conclusion}
          assessment={assessment}
          isPreassessment={isPreassessment}
          caseId={case_?.id}
          onSuccess={() => {
            refetch()
            refetchCase?.()
          }}
        />
      )}
    </ModalButtons>
  )
}

export default R.compose(
  withUrls,
  withSubmit({
    onSubmit: p => () => conclusionRequest.regenerate(p.conclusion.id),
    redirect: (res, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return `${R.head(
          p.match.url.split("conclusion/"),
        )}assessment/${res}/settings`
      }
      return p.urls.assessmentsCreate(res)
    },
    onSuccess: p => p.refetch,
  }),
)(TitleControl)

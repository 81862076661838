import React from "react"
import Columns from "components/Columns"
import { answerTypes, fileUploadOptions } from "data/templates/answers"
import CrossIcon from "icons/Cross"
import Radio from "inputs/Radio"
import Textarea from "inputs/Textarea"
import { IconWrap } from "styledComponents/Icons"

const AnswerGrid = ({
  value,
  type,
  changeText,
  readOnly,
  borderless,
  changeFile,
  remove,
  withAttachments,
  allowTextChangeInReadOnly,
}) =>
  value.map(a => (
    <Columns
      gap={10}
      mb={10}
      key={a.i}
      widths={["auto", ...(withAttachments ? [300] : []), 50]}
      align="center"
    >
      <Textarea
        onChange={changeText(a.i)}
        value={a.text}
        readOnly={readOnly && !allowTextChangeInReadOnly}
        borderless={borderless && !allowTextChangeInReadOnly}
      />
      {withAttachments && (
        <Radio
          onChange={changeFile(a.i)}
          value={a.answerAttachmentPolicy}
          readOnly={readOnly}
          options={fileUploadOptions}
          inline
          byValue
        />
      )}
      {readOnly || type === answerTypes.confirmation.value ? (
        <div />
      ) : (
        <IconWrap onClick={() => remove(a.i)}>
          <CrossIcon />
        </IconWrap>
      )}
    </Columns>
  ))

export default AnswerGrid

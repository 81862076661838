import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M12,0 C5.373,0 0,5.373 0,12 C0,18.627 5.373,24 12,24 C18.627,24 24,18.627 24,12 C24,5.373 18.627,0 12,0 Z M12,22.5 C6.21,22.5 1.5,17.79 1.5,12 C1.5,6.21 6.21,1.5 12,1.5 C17.79,1.5 22.5,6.21 22.5,12 C22.5,17.79 17.79,22.5 12,22.5 Z M11.837,17.252 L13.223,17.252 L13.223,15.503 L11.837,15.503 L11.837,17.252 Z M14.5963,6.8437 C14.2723,6.5657 13.8953,6.3527 13.4723,6.2077 C12.5453,5.8897 11.4083,5.9447 10.5763,6.2837 C10.0993,6.4767 9.6853,6.7517 9.3453,7.1037 C9.0043,7.4567 8.7433,7.8877 8.5703,8.3887 C8.4083,8.8617 8.3263,9.3847 8.3263,9.9437 L9.8263,9.9437 C9.8263,9.5497 9.8813,9.1907 9.9883,8.8767 C10.0903,8.5837 10.2323,8.3437 10.4233,8.1457 C10.6193,7.9437 10.8533,7.7897 11.1403,7.6737 C11.6473,7.4677 12.4043,7.4277 12.9873,7.6267 C13.2313,7.7097 13.4383,7.8267 13.6183,7.9807 C13.7933,8.1317 13.9273,8.3097 14.0283,8.5217 C14.1253,8.7277 14.1743,8.9777 14.1743,9.2627 C14.1743,9.6247 14.1013,9.9247 13.9563,10.1547 C13.7673,10.4547 13.5213,10.7487 13.2103,11.0437 C12.9353,11.3197 12.7163,11.5337 12.5523,11.6897 C12.3303,11.8987 12.1553,12.1197 12.0273,12.3567 C11.9063,12.5897 11.8253,12.8647 11.7853,13.1797 C11.7563,13.4187 11.7363,13.7607 11.7263,14.2107 L13.2263,14.2437 C13.2343,13.8577 13.2513,13.5627 13.2743,13.3617 C13.2943,13.1967 13.3313,13.1007 13.3523,13.0597 C13.3843,13.0007 13.4493,12.9057 13.5823,12.7797 C13.7543,12.6167 13.9843,12.3917 14.2563,12.1197 C14.6443,11.7527 14.9703,11.3597 15.2253,10.9537 C15.5233,10.4817 15.6743,9.9127 15.6743,9.2627 C15.6743,8.7547 15.5773,8.2907 15.3843,7.8817 C15.1943,7.4797 14.9293,7.1307 14.5963,6.8437 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

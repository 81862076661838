import React from "react"
import * as R from "ramda"
import { Redirect, Switch, withRouter } from "react-router-dom"
import LoginRoute from "./LoginRoute"
import ProtectedRoute from "./ProtectedRoute"
import Loader from "components/Loader"

const routeToProps = r => ({
  key: r.path,
  path: r.path,
  component: r.component,
  ...r.props,
})

const Routes = ({
  profile,
  modules,
  tenants,
  tenant,
  location,
  loggedIn,
  urls,
  routes,
}) => {
  const availableProtectedRoutes = R.values(routes).filter(
    x =>
      !x.public && modules && (!x.module || modules.modules.includes(x.module)),
  )

  return (
    <React.Suspense fallback={<Loader center />}>
      <Switch>
        {R.values(routes)
          .flatMap(r => (r.submodules ? R.values(r.submodules) : [r]))
          .map(r => {
            const Component = r.public ? LoginRoute : ProtectedRoute
            return (
              <Component
                {...routeToProps(r)}
                profile={profile}
                modules={modules}
                tenants={tenants}
                tenant={tenant}
                loggedIn={loggedIn}
                urls={urls}
                routes={routes}
                availableProtectedRoutes={availableProtectedRoutes}
              />
            )
          })}
        <Redirect
          to={{
            pathname: loggedIn
              ? R.head(availableProtectedRoutes).path
              : urls.login,
            state: { from: location.pathname },
          }}
        />
      </Switch>
    </React.Suspense>
  )
}

export default withRouter(Routes)

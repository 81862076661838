import * as R from "ramda"
import { answerKey } from "data/templates/answers"

export const getNewIndex = (indices, i, values, sq) => {
  const newI = indices[i.length + 1]
  const foundI = R.path([...i, "children"], values).findIndex(
    x => x.questionId === sq.id,
  )

  if (R.isNil(newI)) return foundI

  const qid = R.path([...i, "children", newI], values).questionId
  if (sq.id === qid) return newI

  return foundI
}

export const isQuestionEnabled = (q, indices, enabled) => {
  let map = enabled
  for (let i = 0; ; i++) {
    // parent disabled
    if (!map || map.isEnabled === false) return false

    const item = map.enabled?.find(x => x.id === q.id)
    if (item) {
      return item.isEnabled
    }

    // eslint-disable-next-line
    const getNewIndex = () => {
      const currIndex = indices[i]
      if (currIndex === "answers") return "children"

      if (!Number.isInteger(currIndex) && !R.isNil(currIndex)) return currIndex

      if (q.parentIndices.includes(map[currIndex]?.questionId)) return currIndex

      return map.findIndex(x => q.parentIndices.includes(x.questionId))
    }

    map = map[getNewIndex()]
  }
}

// so top level questions have same structure as subquestions we unwrap "data" atribute to top level
export const normalizeNodeIntoQuestion = node => ({
  ...node.data,
  id: node.referencedId,
})

const addIndices = (parent, q, sqi) => ({
  ...q,
  parentIndices: (parent.parentIndices || []).concat(parent.id),
  indices: (parent.indices || []).concat(sqi),
})

// get all subquestions of question recursively
const extractSubquestions = q =>
  q.subQuestions.flatMap((sq, sqi) => {
    const tmp = addIndices(q, sq, sqi + 1)
    return sq.questionType === "Custom"
      ? [tmp, ...extractSubquestions(tmp)]
      : [tmp]
  })

// get question and all its recursive subquestions in a list
export const flattenQuestion = (node, normalizeRoot = true) => {
  const questionData = normalizeRoot ? normalizeNodeIntoQuestion(node) : node

  const qd = { ...questionData, indices: [questionData.idx] }
  return [qd].concat(extractSubquestions(qd))
}

// get ids of question and all its recursive subquestions
export const getAllQuestionIdsFromNode = node =>
  flattenQuestion(node).map(R.prop("id"))

export const getQuestionGroup = R.path([
  "registryCatalogTags",
  0,
  "autoFillGroupNr",
])

export const getQuestionsWithValue = ({
  question,
  values,
  indices,
  rootQuestion,
  enabled,
}) => {
  const targetGroup = getQuestionGroup(question)

  let questionsWithValue = []
  const getVal = (q, i) => {
    const curVal = R.path(i, values)
    if (!curVal) return

    q.subQuestions.forEach(sq => {
      const gr = getQuestionGroup(sq)

      if (gr === targetGroup && sq.questionType !== "Custom") {
        const ans = curVal.answers.find(x => x.templateQuestionId === sq.id)
        const v = ans && ans[answerKey[sq.questionType]]

        if (!R.isNil(v) && v !== "") {
          questionsWithValue = questionsWithValue.concat(sq)
        }
      }

      if (sq.questionType === "Custom") {
        getVal(sq, i.concat(["children", getNewIndex(indices, i, values, sq)]))
      }
    })
  }

  getVal(rootQuestion, indices.slice(0, 1))

  const seq = flattenQuestion(rootQuestion, false)

  return questionsWithValue.filter(x =>
    isQuestionEnabled(
      // we need to find the question here because we are depending on indices which are calculated and added into the questionn by the flattenQuestion call
      seq.find(y => y.id === x.id),
      indices,
      enabled,
    ),
  )
}

import React from "react"
import { P } from "styledComponents/Typography"
import { showClearValue } from "./helpers"

const ClearTagging = ({
  value,
  onChange,
  canEdit,
  question,
  indices = [],
  isCustom,
}) => {
  if (!showClearValue(value, indices, canEdit, question, isCustom)) {
    return null
  }

  return (
    <P
      mt={10}
      red
      link
      hovernolink
      onClick={() => {
        const newVal = value.filter(x => question.id !== x.templateQuestionId)
        onChange(newVal)
      }}
    >
      Clear tagging
    </P>
  )
}

export default ClearTagging

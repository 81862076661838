import config from "config"
import React from "react"
import { Helmet } from "react-helmet"

const allowedUrls = [
  config.apiRoot,
  config.testApiRoot,
  "wss://localhost:55000",
  "https://localhost:55000",
  "https://dc.services.visualstudio.com/v2/track",
]

const CSP = () => {
  const isDev = process.env.NODE_ENV === "development"

  if (!isDev) return null

  return (
    <Helmet>
      <meta
        http-equiv="Content-Security-Policy"
        content={`default-src 'none'; script-src 'self'; img-src 'self' data:; style-src 'self' 'unsafe-inline';font-src 'self'; connect-src ${allowedUrls.join(
          " ",
        )}`}
      />
    </Helmet>
  )
}

export default CSP

import styled, { css } from "styled-components/macro"
import { IconWrap } from "styledComponents/Icons"

export const ModalBody = styled.div`
  border: 1px solid #d8d8d8;
  border-radius: 2px;
  background: #fff;
  max-width: 90%;
  max-height: 90%;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
  ${p =>
    p.noScroll
      ? css`
          overflow: hidden;
        `
      : css`
          overflow-y: auto;
          overflow-x: hidden;
        `};
  ${p =>
    p.fullScreen
      ? css`
          width: 90%;
          height: 90%;
        `
      : css`
          width: ${p.width}px;
        `};

  ${p =>
    p.fixedRight &&
    css`
      height: 100%;
      right: 0;
      top: 0;
      transform: none;
      left: initial;
      max-height: 100%;
    `};
`

export const ModalHeader = styled.div`
  padding: 0px 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  ${p =>
    p.modalLine &&
    css`
      border-bottom: 1px solid #d8d8d8;
    `};
`

export const ModalInner = styled.div`
  min-height: 80px;
  height: 100%;

  ${p =>
    !p.noPadding &&
    css`
      padding: 20px;
    `};
`

export const CrossWrap = styled(IconWrap)`
  height: 40px;
`

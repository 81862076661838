import React from "react"
import Table from "components/Table"
import { config } from "./config"
import withData from "hoc/withData"
import { caseRequest } from "data/cockpit/api"
import * as R from "ramda"
import withFilters from "hoc/withFilters"

const Cases = ({ cases, loading, refetchData, setFilters }) => (
  <Table
    loading={loading}
    sorting={{
      index: 4,
      desc: true,
    }}
    config={config()}
    count={R.path(["count"], cases)}
    data={R.pathOr([], ["cases"], cases)}
    refetch={refetchData}
    onParamChange={setFilters}
    boldHeader
  />
)

const getSearchParams = filters =>
  [
    `orderBy=${R.pathOr("EditedAtUtc", ["sort", "key"], filters)}`,
    `orderDescending=${R.pathOr(true, ["sort", "desc"], filters)}`,
    `page=${R.pathOr(0, ["page"], filters)}`,
    `size=${R.pathOr(10, ["size"], filters)}`,
    filters && filters.search ? `search=${filters.search}` : "",
    `favoritesOnly=${R.pathOr(false, ["filters", "favorite"], filters)}`,
  ]
    .filter(x => x !== "")
    .join("&")

export default R.compose(
  withFilters(["search"]),
  withData(p => caseRequest.list(p.folder.id, getSearchParams(p.filters)), {
    refetchParams: p => [getSearchParams(p.filters)],
    noLoader: true,
    clearOnRefetch: true,
    dataKey: "cases",
  }),
)(Cases)

import React from "react"
import ContextMenu from "components/ContextMenu"
import OverlayWrapper from "components/OverlayWrapper"
import withFormField from "hoc/withFormField"
import CaretIcon from "icons/Caret"
import Button from "components/Button"
import Textfield from "inputs/Textfield"
import { CaretWrap, SelectText, SelectWrap, BodyWrap } from "./styled"
import { localize } from "locale"
import { Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"

const IdSearch = ({
  value,
  onChange,
  readOnly,
  borderless,
  small,
  large,
  text,
}) => {
  const { isOpen, close, toggle } = useOpen()
  const [v, setV] = React.useState(value || "")

  return (
    <OverlayWrapper isOpen={isOpen} close={close}>
      <SelectWrap
        onClick={readOnly ? undefined : toggle}
        borderless={borderless}
        small={small}
      >
        <>
          <SelectText empty={!value} large={large}>
            {value || localize("idSearch")}
          </SelectText>
          {!readOnly && (
            <CaretWrap>
              <CaretIcon />
            </CaretWrap>
          )}
        </>
      </SelectWrap>
      <ContextMenu isOpen={isOpen}>
        <BodyWrap>
          <Textfield
            small
            placeholder={localize("enterId")}
            onChange={setV}
            value={v}
          />
          <P mt={10} mb={20}>
            {text}
          </P>
          <Spread mt={10}>
            {value && (
              <Button
                small
                secondary
                text={localize("clear")}
                onClick={() => {
                  onChange()
                  setV("")
                  close()
                }}
              />
            )}
            <Button
              small
              ml="auto"
              text={localize("search")}
              onClick={() => {
                onChange(v)
                close()
              }}
            />
          </Spread>
        </BodyWrap>
      </ContextMenu>
    </OverlayWrapper>
  )
}

export default withFormField(IdSearch)

import React from "react"
import Button from "components/Button"
import Panel from "components/Panel"
import PlusIcon from "icons/Plus"
import { localize } from "locale"
import { Box, Spread } from "styledComponents/Containers"
import EditBar from "../EditBar"
import Question from "../Question"
import { showAddButtons } from "../../helpers"
import {
  showMoveQuestionToStart,
  showCopyQuestionToStart,
  showMoveSubsection,
  showMoveQuestion,
  showCopyQuestion,
  showCopySubsection,
  showCopySection,
} from "./helpers"

const Subsection = ({
  section,
  subsection,
  ops,
  templateId,
  template,
  questions,
}) => (
  <>
    <div data-group-id={subsection.referencedId}>
      <EditBar
        data={subsection}
        type="subsection"
        ops={ops}
        templateId={templateId}
        template={template}
        questions={questions}
      />
      <Panel mb={20} bg="#eee">
        {showAddButtons(ops) && (
          <Box flex>
            <Button
              ml="auto"
              icon={PlusIcon}
              text={localize("addQuestion")}
              onClick={() => ops.addQuestion(subsection.id, null)}
              disabled={!!ops.editIndex}
            />
          </Box>
        )}
        {showMoveQuestionToStart(ops) && (
          <Button
            text={localize("moveQuestionHere")}
            onClick={() => ops.onMoveConfirm(subsection.id, null)}
          />
        )}
        {showCopyQuestionToStart(ops) && (
          <Button
            text={localize("pasteQuestionHere")}
            onClick={() => ops.onCopyConfirm(subsection.id, null)}
          />
        )}
        {subsection.children.map((q, qi) => (
          <Question
            key={q.id}
            question={q}
            section={subsection}
            ops={{ ...ops, subsectionNodeIndex: qi }}
            templateId={templateId}
            template={template}
            questions={questions}
          />
        ))}
      </Panel>
    </div>
    {showAddButtons(ops) && (
      <Spread>
        <Button
          secondary
          text={localize("addSubsection")}
          onClick={() => ops.addSection(section.id, subsection.id)}
          disabled={!!ops.editIndex}
        />
        <Button
          icon={PlusIcon}
          text={localize("addQuestion")}
          onClick={() => ops.addQuestion(section.id, subsection.id)}
          disabled={!!ops.editIndex}
        />
      </Spread>
    )}
    {showMoveSubsection(ops) && (
      <Button
        text={localize("moveSubsectionHere")}
        onClick={() => ops.onMoveConfirm(section.id, subsection.id)}
      />
    )}
    {showMoveQuestion(ops) && (
      <Button
        text={localize("moveQuestionHere")}
        onClick={() => ops.onMoveConfirm(section.id, subsection.id)}
      />
    )}
    {showCopyQuestion(ops) && (
      <Button
        text={localize("pasteQuestionHere")}
        onClick={() => ops.onCopyConfirm(section.id, subsection.id)}
      />
    )}
    {showCopySubsection(ops) && (
      <Button
        text={localize("pasteSubsectionHere")}
        onClick={() => ops.onCopyConfirm(section.id, subsection.id)}
      />
    )}
    {showCopySection(ops) && (
      <Button
        text={localize("pasteSectionHere")}
        onClick={() => ops.onCopyConfirm(section.id, subsection.id)}
      />
    )}
  </>
)

export default Subsection

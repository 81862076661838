import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentRequest } from "data/assessments/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import { stringRequired } from "validators/string"
import * as R from "ramda"
import { preassessmentRequest } from "data/preassessments/api"

const DeleteModal = ({ close, isPreassessment }) => (
  <>
    <P mb={20}>
      The {isPreassessment ? "pre-" : ""}assessment will be deleted. You may
      permanently delete it or restore it from the recycle bin. Notification
      will be sent to all coworkers which the {isPreassessment ? "pre-" : ""}
      assessment was assigned to.
    </P>
    <Textarea
      required
      name="reason"
      label={localize("reasonForRejection")}
      placeholder={localize("writeTheReason")}
    />
    <P mb={20} bold>
      Do you want to delete the {isPreassessment ? "pre-" : ""}assessment?
    </P>
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="No" />
      <Button danger type="submit" text={localize("yesDelete")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(p => `Delete ${p.assessment.name}`),
  withUrls,
  withForm({
    schema: R.always({
      reason: stringRequired,
    }),
    onSubmit: p => v =>
      (p.isPreassessment ? preassessmentRequest : assessmentRequest).delete(
        p.assessment.id,
        v,
      ),
    onSuccess: p => () => {
      p.close()
      if (p.isPreassessment) {
        p.refetchCase()
      }
    },
    successText: p => () =>
      `You have deleted the ${p.isPreassessment ? "pre-" : ""}assessment ${
        p.assessment.name
      }.`,
    redirect: (_, p) => {
      if (p.caseUrls) {
        return `${p.caseUrls.list.url}?involvedPartyId=${R.path(
          ["breadcrumbs", "case", "involvedParties", 0, "id"],
          p.assessment,
        )}`
      }
      return p.urls.assessments.local
    },
  }),
)(DeleteModal)

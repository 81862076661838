import React from "react"
import Warning from "components/Warning"
import {
  assessmentLibraryQuestionRequest,
  assessmentQuestionRequest,
} from "data/assessments/api"
import { getQuestions } from "data/templates/transforms"
import withData from "hoc/withData"
import { localize } from "locale"
import * as R from "ramda"
import QuestionValidationMessages from "components/QuestionValidationMessages"
import withProps from "hoc/withProps"
import { preassessmentRequest } from "data/preassessments/api"
import { previewRequest } from "data/templates/api"

const ValidationErrors = ({
  data,
  questionMessages,
  messages,
  count,
  isValid,
  showCompact,
  questions,
}) => {
  if (isValid) return null
  const qs = !showCompact && getQuestions(data || questions)

  return (
    <>
      {/* general messages, always shown */}
      {messages.map((x, xi) => (
        <Warning error key={`msg-${xi}`} text={x} />
      ))}
      {/* now if compact view is false, or there's only one, show more detailed view*/}
      {showCompact ? (
        <Warning error text={localize("thereAreXErrorsInQuestions")(count)} />
      ) : (
        <QuestionValidationMessages
          questions={qs}
          questionMessages={questionMessages}
        />
      )}
    </>
  )
}

export default R.compose(
  withProps(p => ({
    count: R.keys(p.questionMessages).length,
  })),
  withProps(p => ({
    showCompact: p.compact && p.count > 1,
  })),
  withData(
    p => {
      const getQuestionListRequest = () => {
        if (p.isLibrary) return assessmentLibraryQuestionRequest.list
        if (p.isPreview) return previewRequest.questions
        if (p.isPreassessment) return preassessmentRequest.questions

        return assessmentQuestionRequest.list
      }

      return getQuestionListRequest()(p.assessmentId)
    },
    {
      // questions should come as props
      skip: p => p.showCompact || p.isValid || p.questions,
    },
  ),
)(ValidationErrors)

import React from "react"
import Button from "components/Button"
import { withRouter } from "react-router"

const ButtonModalRouted = ({
  modal: Modal,
  hash,
  location,
  history,
  disabled,
  ...rest
}) => {
  const h = `#${hash}`
  const isOpen = location.hash === h
  const open = () => history.push(h)
  const close = () => history.push(location.pathname)

  React.useEffect(() => {
    if (disabled && isOpen) {
      close()
    }
  }, []) // eslint-disable-line

  return (
    <>
      <Button {...rest} onClick={open} disabled={disabled} />
      {isOpen && <Modal {...rest} close={close} />}
    </>
  )
}

export default withRouter(ButtonModalRouted)

import { get, post, del } from "common/request"

export const elmaasRequest = {
  listAccounts: params => get(`/api/elmaas/account/all?${params}`),
  history: () => get(`/api/elmaas/account/history`),
  register: data => post("/api/elmaas/account/register", data),
  delAccount: (id, data) => del(`/api/elmaas/account/${id}`, data),
  delProtocols: (id, downloadProtocols = false, data) =>
    del(
      `/api/elmaas/account/${id}/protocols?downloadProtocols=${downloadProtocols}`,
      data,
    ),
  uploadCertificate: data =>
    post("/api/elmaas/account/certificate/upload", data, {
      doNotSetContentType: true,
    }),
  submitReport: (massReportId, data) =>
    post(`/api/elmaas/massReport/${massReportId}/submit-report-known`, data),
  submitReportUnknown: (massReportId, data) =>
    post(`/api/elmaas/massReport/${massReportId}/submit-report-unknown`, data),
  updateStatus: (massReportId, data) =>
    post(`/api/elmaas/massReport/${massReportId}/update-status-known`, data),
  updateStatusUnknown: (massReportId, data) =>
    post(`/api/elmaas/massReport/${massReportId}/update-status-unknown`, data),
  // credentials: () => get(`/api/elmaas/credentials`),
  // deleteCredentials: () => del(`/api/elmaas/credentials`),
  // createKeys: data => post("/api/elmaas/createKeys", data),
  // decryptPassword: data => put("/api/elmaas/decryptPassword", data),
}

import styled, { css } from "styled-components/macro"

export const NotifItem = styled.div`
  padding: 15px;
  background: #efefef;
  border-bottom: 1px solid #e6e6e6;
  cursor: pointer;
  display: flex;
  align-items: flex-start;

  ${p =>
    p.unread &&
    css`
      background: #fff;
      &:hover {
        background: #efefef;
      }
    `}
`

export const NotificationIcon = styled.div`
  width: 30px;
  height: 30px;
  border-radius: 100%;
  margin-right: 10px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p =>
    p.bg &&
    css`
      background: ${p.bg};
    `}

  svg {
    fill: #fff;
    width: 16px;
    height: 16px;
  }
`

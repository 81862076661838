import styled, { css } from "styled-components/macro"

export const SelectWrap = styled.div`
  background-color: #fff;
  border: 1px solid #d8d8d8;
  height: 42px;
  align-items: center;
  display: flex;
  position: relative;
  padding: 0 30px 0 15px;

  ${p =>
    p.borderless &&
    css`
      border: 0;
    `}
  ${p =>
    p.small &&
    css`
      height: 32px;
    `}
`

export const SelectText = styled.div`
  color: #333;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  ${p =>
    p.empty &&
    css`
      color: #999;
    `};
`

export const CaretWrap = styled.div`
  position: absolute;
  right: 0;
  top: 0;
  bottom: 0;
  width: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: none;

  svg {
    width: 16px;
    height: 16px;
    fill: #999;
  }
`

export const BodyWrap = styled.div`
  padding: 10px;
  max-width: 300px;
`

import React from "react"
import Empty from "components/Empty"
import Panel from "components/Panel"
import withEditProps from "hoc/withEditProps"
import Node from "./Node"
import * as R from "ramda"
import { templateTagRequest } from "data/registry/api"
import withData from "hoc/withData"

const Questions = ({
  tree,
  next,
  catalogs,
  refetchData,
  canEdit,
  editProps,
  templateId,
  data,
  template,
  refetchTemplate,
  fetchCount,
}) => (
  <>
    {tree.length > 0 ? (
      tree.map((x, xi) => (
        <Node
          node={x}
          catalogs={catalogs}
          key={`${xi}-${fetchCount}`}
          indices={[xi]}
          next={next}
          refetch={refetchData}
          canEdit={canEdit}
          editProps={editProps}
          templateId={templateId}
          tagData={data}
          template={template}
          refetchTemplate={refetchTemplate}
        />
      ))
    ) : (
      <Panel>
        <Empty />
      </Panel>
    )}
  </>
)

export default R.compose(
  withData(p => templateTagRequest.getTagging(p.templateId)),
  withEditProps(R.prop("canEdit")),
)(Questions)

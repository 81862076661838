import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { questionRequest } from "data/templates/api"
import withForm from "hoc/withForm"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { statuses } from "data/templates/statuses"
import { schema } from "./schema"
import SupportiveInformation from "./SupportiveInformation"
import Subquestions from "./Subquestions"
import Answers from "./Answers"
import Precondition from "./Precondition"
import Flags from "./Flags"
import * as R from "ramda"
import { scrollToEl } from "common/scroll"

const QuestionEdit = ({
  values,
  question,
  ops,
  templateId,
  questions,
  isEdited,
  template,
  setValues,
}) => {
  const isCustom = values.questionType === "Custom"
  const d = question.data

  // some other fields may be edited even outside of inProcess state
  const readOnlyStandard =
    !isEdited || template.status !== statuses.inProcess.value
  const canBeMoved = !isEdited && template.status === statuses.inProcess.value

  const { isPreAssessment } = template

  return (
    <>
      <Precondition
        readOnly={readOnlyStandard}
        questions={questions}
        isEdited={isEdited}
        question={question}
        values={values}
      />
      <Box mt={20}>
        <Textarea
          name="questionText"
          label={localize("question")}
          placeholder={localize("enterYourQuestion")}
          required={isEdited && !isCustom}
          readOnly={!isEdited}
          borderless={!isEdited}
        />
      </Box>
      <Box responsive>
        <Box minWidth={612}>
          <Flags
            isPreAssessment={isPreAssessment}
            values={values}
            setValues={setValues}
            readOnly={readOnlyStandard}
          />
        </Box>
      </Box>
      <Box mt={20}>
        <Answers
          values={values}
          readOnly={readOnlyStandard}
          isEdited={isEdited}
          questionType={values.questionType}
        />
        <Subquestions
          question={question}
          ops={ops}
          canBeMoved={canBeMoved}
          readOnly={readOnlyStandard}
          questionType={values.questionType}
          isEdited={isEdited}
          values={values}
        />
      </Box>
      <Box mt={20}>
        <SupportiveInformation
          isEdited={isEdited}
          template={template}
          templateId={templateId}
          readOnly={readOnlyStandard}
          data={d}
        />
      </Box>
      {isEdited && (
        <ModalButtons>
          <Button
            secondary
            type="button"
            onClick={() => ops.onEdit(undefined)}
            text="Cancel"
          />
          <Button type="submit" text="Save" />
        </ModalButtons>
      )}
    </>
  )
}

export default R.compose(
  withForm({
    preventLoseProgress: true,
    mapProps: p => ({
      answerAttachmentPolicy: "N",
      isMandatory: true,
      ...p.question.data,
    }),
    schema,
    onSubmit: p => v => {
      if (p.question.id === "new") {
        return questionRequest.create(p.templateId, {
          question: {
            ...v,
            questionText: v.questionText || "",
          },
          position: {
            parentNodeId: p.ops.editIndex.parentNodeId,
            previousNodeId: p.ops.editIndex.previousNodeId,
          },
        })
      }

      return questionRequest.edit(p.templateId, p.question.referencedId, v)
    },
    onSuccess: p => res => {
      p.ops.onUpdate(res)
      scrollToEl(document.getElementById(`q-${res.id}`), 150)
    },
  }),
)(QuestionEdit)

import React from "react"
import Button from "components/Button"
import Columns from "components/Columns"
import ModalButtons from "components/ModalButtons"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import { strOptional } from "validators/string"
import Textfield from "inputs/Textfield"
// import { Box } from "styledComponents/Containers"
import { templateTagRequest } from "data/registry/api"

const EditGroupModal = ({ groupNumber, close, values, setFieldValue }) => (
  <>
    <Columns widths={[80, "auto"]} align="center">
      <P mb={20} bold>
        Group {groupNumber}
      </P>
      <Textfield
        name="name"
        placeholder="Enter custom group name"
        nomargin
        mb={0}
      />
    </Columns>
    <ModalButtons
      noBg
      nomargin
      buttonText={
        R.pathOr("", ["name"], values).length > 0 ? (
          <P red link hovernolink onClick={() => setFieldValue("name", "")}>
            Clear custom group name
          </P>
        ) : undefined
      }
    >
      <Button secondary type="button" onClick={close} text="Cancel" />
      <Button primary type="submit" text="OK" />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(R.always("Custom autofill group")),
  withForm({
    mapProps: p => ({
      name: R.pathOr(
        "",
        ["newName"],
        p.options.find(x => x.value === p.groupNumber),
      ),
    }),
    schema: R.always({
      name: strOptional(25),
    }),
    onSubmit: p => v =>
      templateTagRequest.updateGroupName(p.templateId, {
        autoFillGroupNr: p.groupNumber,
        text: v.name,
      }),
    onSuccess: p => () => {
      p.refetchTemplate()
      p.close()
    },
  }),
)(EditGroupModal)

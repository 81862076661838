import styled, { css } from "styled-components/macro"
import { darken } from "common/colors"
import boxModel from "styledComponents/bases/boxModel"
import theme from "theme"

const getTheme = p => {
  if (p.secondary)
    return css`
      color: ${theme.color.red};
      background: transparent;
      border-color: ${theme.color.red};

      svg {
        fill: ${theme.color.red};
      }

      &:hover {
        background: ${theme.color.red};
        color: #fff;

        svg {
          fill: #fff;
        }
      }
    `

  if (p.danger)
    return css`
      color: #fff;
      background: ${theme.color.error};
      border-color: ${theme.color.error};
      fill: #fff;

      svg {
        fill: #fff;
      }

      &:hover {
        background: ${darken(0.05, theme.color.error)};
        border-color: ${darken(0.05, theme.color.error)};
      }
    `
  if (p.success)
    return css`
      color: #fff;
      background: ${theme.color.success};
      border-color: ${theme.color.success};

      &:hover {
        background: ${darken(0.05, theme.color.success)};
        border-color: ${darken(0.05, theme.color.success)};
      }
    `

  return css`
    color: #fff;
    background: ${theme.color.red};
    border-color: ${theme.color.red};

    svg {
      fill: #fff;
    }

    &:hover {
      background: ${darken(0.05, theme.color.red)};
      border-color: ${darken(0.05, theme.color.red)};
    }
  `
}

const style = css`
  ${boxModel};
  outline: none;
  border-radius: 2px;
  font-size: 16px;
  border-width: 1px;
  border-style: solid;
  height: 42px;
  padding: 0 20px;
  white-space: nowrap;
  cursor: pointer;
  position: relative;
  ${getTheme};

  ${p =>
    p.disabled &&
    css`
      cursor: default;
      opacity: 0.4;
      pointer-events: none;
    `}
  ${p =>
    p.withIcon &&
    css`
      padding-left: 40px;
    `};
  ${p =>
    p.noText &&
    css`
      padding-right: 0;
    `};
  ${p =>
    p.fullWidth &&
    css`
      width: 100%;
    `};
  ${p =>
    p.small &&
    css`
      height: 32px;
      padding: 0 10px;
    `};
  ${p =>
    p.large &&
    css`
      height: 50px;
      padding: 0 60px;
    `};
`

export const StyledButton = styled.button`
  ${style}
`
export const StyledDivButton = styled.div`
  ${style}
  display: flex;
  align-items: center;
`

export const IconWrap = styled.div`
  position: absolute;
  top: -1px;
  left: -1px;
  bottom: -1px;
  width: 42px;
  display: flex;
  align-items: center;
  justify-content: center;
  ${p =>
    p.iconBorder &&
    css`
      border-right: 1px solid #fff;
    `}

  svg {
    width: 20px;
    height: 20px;
  }

  ${p =>
    p.secondary &&
    css`
      border-color: ${theme.color.red};
    `};
`

import React from "react"
import { ctx } from "hoc/withForm"
import InputWrap from "./InputWrap"

const withFormField = Component => p => {
  if (!p.name) {
    return (
      <InputWrap {...p} noEmptyError mb={0}>
        <Component {...p} />
      </InputWrap>
    )
  }

  return (
    <ctx.Consumer>
      {c => {
        if (!c) return null

        const formSubmitted = c.submitCount > 0
        const fieldDirty = c.dirty[p.name]
        const showError = formSubmitted || fieldDirty
        const error = showError && c.errors[p.name]
        return (
          <InputWrap {...p} error={error}>
            <Component
              {...p}
              value={c.values[p.name]}
              onChange={v => {
                c.onChange(p.name)(v)
                if (p.onChange) {
                  p.onChange(v)
                }
              }}
            />
          </InputWrap>
        )
      }}
    </ctx.Consumer>
  )
}

export default withFormField

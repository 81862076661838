import React from "react"
import Question from "./Question"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import { arrEmpty } from "common/array"

const Node = ({
  node,
  onChange,
  elementId,
  elementOptionId,
  value,
  expand,
  scroller,
  lastSelectedValue,
}) => {
  if (!node) return null

  if (node.nodeType === "question") {
    return (
      <Question
        node={node}
        onChange={onChange}
        elementId={elementId}
        elementOptionId={elementOptionId}
        value={value}
        expand={expand}
        scroller={scroller}
        lastSelectedValue={lastSelectedValue}
      />
    )
  }

  if (arrEmpty(node.children)) return null

  return (
    <>
      <P fs={12} gray bold uppercase mb={10}>
        {node.data.name}
      </P>
      <Box ml={10}>
        {node.children.map((x, xi) => (
          <Node
            key={xi}
            node={x}
            onChange={onChange}
            elementId={elementId}
            elementOptionId={elementOptionId}
            value={value}
            expand={expand}
            scroller={scroller}
            lastSelectedValue={lastSelectedValue}
          />
        ))}
      </Box>
    </>
  )
}

export default Node

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M19.9355,24 L3.566,24 C2.702,24 2,23.283 2,22.3995 L2,1.6005 C2,0.717 2.702,0 3.566,0 L19.9355,0 C20.7995,0 21.5,0.717 21.5,1.6005 L21.5,22.3995 C21.5,23.283 20.7995,24 19.9355,24 Z M5.00075,12 L18.50075,12 L18.50075,9 L5.00075,9 L5.00075,12 Z M5.00075,18 L18.50075,18 L18.50075,15 L5.00075,15 L5.00075,18 Z M5.00075,6 L11.00075,6 L11.00075,3 L5.00075,3 L5.00075,6 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

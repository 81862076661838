import styled, { css } from "styled-components/macro"
import theme from "theme"

export const FavoriteButtonWrap = styled.div`
  border: 1px solid #999;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  width: 24px;
  height: 24px;
  svg {
    width: 18px;
    height: 18px;
  }

  ${p =>
    p.isFavorite &&
    css`
      border-color: ${theme.color.orange};
      background: ${theme.color.orange};
    `};
`

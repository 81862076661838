import React from "react"
import { P } from "styledComponents/Typography"
import { Clear, Count, CountTitleLeft, CountTitleWrap } from "./styled"

const CountTitle = ({ title, count, onClear }) => (
  <CountTitleWrap>
    <CountTitleLeft>
      <P gray fs={12}>
        {title}
      </P>
      {count > 0 && <Count>{count}</Count>}
    </CountTitleLeft>
    {onClear && <Clear onClick={onClear}>Clear all</Clear>}
  </CountTitleWrap>
)

export default CountTitle

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 32 32">
    <path
      d="M8.01346229,27.5 C10.278578,29.0760365 13.0313874,30 16,30 C18.9686126,30 21.721422,29.0760365 23.9865377,27.5 C21.721422,25.9239635 18.9686126,25 16,25 C13.0313874,25 10.278578,25.9239635 8.01346229,27.5 Z M6.40507073,26.195021 C9.07831514,24.1887195 12.4003374,23 16,23 C19.5996626,23 22.9216849,24.1887195 25.5949293,26.195021 C28.3068172,23.6417964 30,20.0186302 30,16 C30,8.2680135 23.7319865,2 16,2 C8.2680135,2 2,8.2680135 2,16 C2,20.0186302 3.69318278,23.6417964 6.40507073,26.195021 Z M16,32 C7.163444,32 0,24.836556 0,16 C0,7.163444 7.163444,0 16,0 C24.836556,0 32,7.163444 32,16 C32,24.836556 24.836556,32 16,32 Z M16,19 C13.2385763,19 11,16.7614237 11,14 C11,11.2385763 13.2385763,9 16,9 C18.7614237,9 21,11.2385763 21,14 C21,16.7614237 18.7614237,19 16,19 Z M16,17 C17.6568542,17 19,15.6568542 19,14 C19,12.3431458 17.6568542,11 16,11 C14.3431458,11 13,12.3431458 13,14 C13,15.6568542 14.3431458,17 16,17 Z"
      transform="translate(-240.000000, -2353.000000) translate(240.000000, 2353.000000)"
      fillRule="nonzero"
    ></path>
  </svg>
)

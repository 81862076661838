import React from "react"
import { questionRequest } from "data/templates/api"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import withData from "hoc/withData"
import Questions from "./Questions"
import * as R from "ramda"
import withProps from "hoc/withProps"
import { parallel } from "common/rxjs"
import { templateTagRequest } from "data/registry/api"
import { statuses } from "data/templates/statuses"

const Tagging = ({
  tree,
  questions,
  templateId,
  catalogs,
  refetch,
  template,
}) => {
  const canEdit =
    template.canEdit &&
    [
      statuses.inProcess.value,
      statuses.approved.value,
      statuses.published.value,
    ].includes(template.status)

  return (
    <Questions
      tree={tree}
      questions={questions}
      catalogs={catalogs}
      canEdit={canEdit}
      templateId={templateId}
      template={template}
      refetchTemplate={refetch}
    />
  )
}

export default R.compose(
  withData(
    p =>
      parallel(
        questionRequest.list(p.templateId),
        templateTagRequest.catalogs(),
      ),
    { dataKeys: ["questions", "catalogs"] },
  ),
  withProps(p => {
    const tree = buildTree(p.questions)
    return {
      tree,
      questions: getQuestionsFromTree(tree),
    }
  }),
)(Tagging)

import React from "react"
import CrossLightIcon from "icons/appkit/outline-close"
import { StyledToast, ToastItemWrap } from "./styled"
import { P } from "styledComponents/Typography"

const ToastItem = ({ close, id, message, type }) => {
  const timeout = React.useRef(null)
  const [closing, setClosing] = React.useState(false)

  const setCloseTimeout = () => {
    clearTimeout(timeout.current)
    setClosing(true)
    setTimeout(() => {
      close(id)
    }, 450)
  }

  React.useEffect(() => {
    const textLength = message ? message.split(" ").length : 1
    const timeShown = textLength * 1000
    timeout.current = setTimeout(setCloseTimeout, timeShown)
  }, []) // eslint-disable-line

  return (
    <ToastItemWrap key={id} closing={closing}>
      <StyledToast onClick={setCloseTimeout} type={type}>
        <P mr={10} inherit>
          {message}
        </P>
        <CrossLightIcon />
      </StyledToast>
    </ToastItemWrap>
  )
}

export default ToastItem

import React from "react"
import Button from "components/Button"
import { groupRequest, questionRequest } from "data/templates/api"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import withData from "hoc/withData"
import withSubmit from "hoc/withSubmit"
import { localize } from "locale"
import * as R from "ramda"
import {
  showAddButtons,
  getAnswerInsertIndex,
  showMoveSectionToStart,
  showCopySectionToStart,
  showMoveSection,
  showCopySection,
  showCopySubsection,
} from "./helpers"
import Section from "./Section"
import withState from "hoc/withState"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"

const Questions = ({
  ops,
  tree,
  moveIndex,
  copyIndex,
  templateId,
  template,
  canEdit,
}) => (
  <>
    {showAddButtons(ops) && (
      <Button
        text={localize("addSection")}
        onClick={() => ops.addSection(null, null)}
        disabled={!!ops.editIndex}
        mt={20}
      />
    )}
    {showMoveSectionToStart(ops) && (
      <Button
        text={localize("moveSectionHere")}
        onClick={() => ops.onMoveConfirm(null, null)}
        mt={20}
      />
    )}
    {showCopySectionToStart(ops) && (
      <Button
        text={localize("pasteSectionHere")}
        onClick={() => ops.onCopyConfirm(null, null)}
        mt={20}
      />
    )}
    {tree.map((x, xi) => {
      const newOps = {
        ...ops,
        sectionIndex: xi,
      }
      return (
        <React.Fragment key={x.id}>
          <Section
            tree={tree}
            section={x}
            ops={newOps}
            templateId={templateId}
            template={template}
            questions={getQuestionsFromTree(tree)}
          />
          {canEdit && !moveIndex && !copyIndex && (
            <Button
              text={localize("addSection")}
              onClick={() => ops.addSection(null, x.id)}
              disabled={!!ops.editIndex}
            />
          )}
          {showMoveSection(newOps) && (
            <Button
              text={localize("moveSectionHere")}
              onClick={() => ops.onMoveConfirm(null, x.id)}
            />
          )}
          {showCopySection(newOps) && (
            <Button
              text={localize("pasteSectionHere")}
              onClick={() => ops.onCopyConfirm(null, x.id)}
            />
          )}
          {showCopySubsection(newOps) && (
            <Button
              text={localize("pasteSubsectionHere")}
              onClick={() => ops.onCopyConfirm(null, x.id)}
            />
          )}
        </React.Fragment>
      )
    })}
  </>
)

export default R.compose(
  withData(p => questionRequest.list(p.templateId)),
  withState("localData", "setLocalData", p => p.data),
  withProps(p => ({
    tree: buildTree(p.localData),
  })),
  withState("moveIndex", "setMoveIndex", undefined),
  withState("editIndex", "setEditIndex", undefined),
  withState("copyIndex", "setCopyIndex", undefined),
  withSubmit({
    onSubmit: () => req => req,
    onSuccess: p => () => {
      p.refetchData(p.setLocalData)
      p.setMoveIndex(undefined)
      p.setCopyIndex(undefined)
    },
  }),
  withHandlers({
    addSection: p => (parentNodeId, previousNodeId) => {
      const index = p.localData.nodes.findIndex(x => x.id === previousNodeId)
      const insertIndex = index === -1 ? 0 : index + 1

      p.setLocalData({
        ...p.localData,
        nodes: R.insert(
          insertIndex,
          {
            id: "new",
            parentId: parentNodeId,
            nodeType: "section",
            referencedId: "new",
          },
          p.localData.nodes,
        ),
        groups: [
          ...p.localData.groups,
          {
            id: "new",
          },
        ],
      })
      p.setEditIndex({
        nodeId: "new",
        referencedId: "new",
        parentNodeId,
        previousNodeId,
      })
    },
    addQuestion: p => (parentNodeId, previousNodeId) => {
      p.setLocalData({
        ...p.localData,
        nodes: R.insert(
          getAnswerInsertIndex(p.localData.nodes, parentNodeId, previousNodeId),
          {
            id: "new",
            parentId: parentNodeId,
            nodeType: "question",
            referencedId: "new",
          },
          p.localData.nodes,
        ),
        questions: [
          ...p.localData.questions,
          {
            id: "new",
          },
        ],
      })
      p.setEditIndex({
        nodeId: "new",
        referencedId: "new",
        parentNodeId,
        previousNodeId,
      })
    },
    handleEdit: p => v => {
      // this means cancelling edit
      if (v === undefined) {
        p.setLocalData({
          ...p.localData,
          nodes: p.localData.nodes.filter(x => x.id !== "new"),
          questions: p.localData.questions.filter(x => x.id !== "new"),
        })
        return p.setEditIndex(undefined)
      }

      p.setEditIndex(v)
    },
    handleMove: p => v => p.setMoveIndex(v),
    handleMoveConfirm: p => (parentNodeId, previousNodeId) => {
      const getReq = t => {
        if (t === "question")
          return questionRequest.move(p.templateId, p.moveIndex.referencedId, {
            parentNodeId,
            previousNodeId,
          })

        if (t === "subquestion")
          return questionRequest.moveSubquestion(
            p.templateId,
            p.moveIndex.subquestionId,
            {
              previousQuestionId: previousNodeId,
            },
          )

        return groupRequest.move(p.templateId, p.moveIndex.referencedId, {
          parentNodeId,
          previousNodeId,
        })
      }

      return p.submit(getReq(p.moveIndex.type))
    },
    handleCopy: p => v => p.setCopyIndex(v),
    handleCopyConfirm: p => (parentNodeId, previousNodeId) => {
      const getReq = t => {
        if (t === "question")
          return questionRequest.copy(p.templateId, p.copyIndex.referencedId, {
            parentNodeId,
            previousNodeId,
          })

        return groupRequest.copy(p.templateId, p.copyIndex.referencedId, {
          parentNodeId,
          previousNodeId,
        })
      }

      return p.submit(getReq(p.copyIndex.type))
    },
    handleUpdate: p => () => {
      p.refetchData(p.setLocalData)
      p.setEditIndex(undefined)
    },
  }),
  withProps(p => ({
    ops: {
      canEdit: p.canEdit,
      editIndex: p.editIndex,
      moveIndex: p.moveIndex,
      copyIndex: p.copyIndex,
      onEdit: p.handleEdit,
      onUpdate: p.handleUpdate,
      onMove: p.handleMove,
      onCopy: p.handleCopy,
      onMoveConfirm: p.handleMoveConfirm,
      onCopyConfirm: p.handleCopyConfirm,
      addQuestion: p.addQuestion,
      addSection: p.addSection,
    },
  })),
)(Questions)

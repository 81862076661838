import React from "react"
import Panel from "components/Panel"
import { config } from "./config"
import withData from "hoc/withData"
import withFilters from "hoc/withFilters"
import { involvedPartyRequest } from "data/cockpit/api"
import * as R from "ramda"
import Empty from "./Empty"
import Table from "components/Table"
import AdditionalComments from "./AdditionalComments"
import { parseSearch } from "common/router"
import { TableStyle } from "./styled"
import { P } from "styledComponents/Typography"

const getSearchParams = filters =>
  [
    `orderBy=${R.pathOr("Name", ["sort", "key"], filters)}`,
    `orderDescending=${R.pathOr(true, ["sort", "desc"], filters)}`,
    `page=${R.pathOr(0, ["page"], filters)}`,
    `size=${R.pathOr(10, ["size"], filters)}`,
    filters && filters.search ? `search=${filters.search}` : "",
    filters && filters.filters.id ? `idSearch=${filters.filters.id}` : "",
    filters && filters.filters.country
      ? `country=${filters.filters.country.value}`
      : "",
    `showOverdue=${R.pathOr(false, ["filters", "overdue"], filters)}`,
  ]
    .filter(x => x !== "")
    .join("&")

const PartyList = ({
  data,
  caseId,
  editId,
  setEditId,
  refetchData,
  refetch,
  case_,
  filters,
  setFilters,
  suburls,
  history,
  location,
}) => {
  const tmp = R.pathOr([], ["involvedParties"], data)
  const d = editId === "new" ? [{ id: "new", name: "" }, ...tmp] : tmp
  const { involvedPartyId } = parseSearch(window.location.search)
  const searchParams = getSearchParams(filters)
  const [openParties, setOpenParties] = React.useState([])
  const toggleAll = () =>
    setOpenParties(prev => {
      if (prev.length === 0) return tmp.map(x => x.id)

      return []
    })
  React.useEffect(() => {
    if (!involvedPartyId) return

    setOpenParties(
      d.map(x => x.id).filter(id => id !== "new" && id === +involvedPartyId),
    )
    // remove it from search
    history.replace(location.pathname)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [involvedPartyId])

  const filtersNotEmpty =
    filters?.search || R.values(filters?.filters).some(x => x)
  React.useEffect(() => {
    if (filtersNotEmpty) {
      setOpenParties(prev => tmp.map(x => x.id))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filtersNotEmpty])

  return (
    <Panel>
      {d.length > 0 ? (
        <TableStyle>
          <Table
            data={d}
            openParties={openParties}
            setOpenParties={setOpenParties}
            config={config()}
            suburls={suburls}
            editId={editId}
            setEditId={setEditId}
            refetch={() => {
              refetchData()
              refetch()
            }}
            caseId={caseId}
            noPagination
            pageSize={Infinity}
            borderless
            boldHeader
            noRowBorder
            case={case_}
            onParamChange={setFilters}
            searchParams={searchParams}
            filterLeftContent={
              <P red link onClick={toggleAll}>
                {openParties.length > 0
                  ? "Hide all details"
                  : "Show all details"}
              </P>
            }
          />
        </TableStyle>
      ) : (
        <Empty setEditId={setEditId} readOnly={!case_.canEdit} />
      )}
      <AdditionalComments
        case={case_}
        caseId={caseId}
        refetch={refetch}
        editId={editId}
        setEditId={setEditId}
      />
    </Panel>
  )
}

export default R.compose(
  withFilters(["search"]),
  withData(p => involvedPartyRequest.list(p.caseId)),
)(PartyList)

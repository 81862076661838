import styled, { css } from "styled-components/macro"
import theme from "theme"

export const TitleWrap = styled.div`
  padding: 3px;
  border-radius: 2px;
  ${p =>
    p.isActive &&
    css`
      background: ${theme.color.orange};
    `}
`

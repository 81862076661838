import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M9,16.6176726 L16.5,21.601059 L16.5,1.67441783 C16.5,1.64624025 16.3205968,1.5 15.9,1.5 L2.1,1.5 C1.67940495,1.5 1.5,1.64624151 1.5,1.67441783 L1.49999996,21.601059 L9,16.6176726 Z M9,18.4186072 L0.6,24 C0.181242,23.9998884 0.000102,23.8604094 0,23.4418607 L0,1.67441783 C0,0.761162437 0.887901,0 2.1,0 L15.9,0 C17.112102,0 18,0.761162437 18,1.67441783 L18,23.4418607 C17.999898,23.8604094 17.818758,23.9998884 17.4,24 L9,18.4186072 Z"
      fillRule="nonzero"
      transform="translate(3.000000, 0.000000)"
    ></path>
  </svg>
)

import styled, { css } from "styled-components/macro"

export const InputContactsWrap = styled.div`
  display: flex;

  ${p =>
    p.splitName &&
    css`
      > div {
        margin-right: 10px;
      }
      > div:first-child,
      > div:nth-child(2) {
        width: calc(25% - 8px);
      }
      > div:nth-child(3) {
        flex-grow: 1;
      }
    `};
  ${p =>
    !p.splitName &&
    css`
      > div {
        flex-grow: 1;
        margin-right: 10px;
      }
    `}
`

import { answerTypes, answerKey } from "data/templates/answers"
import AutocompleteSelectCountry from "inputs/AutocompleteSelectCountry"
import Datepicker from "inputs/Datepicker"
import InputName from "inputs/InputName"
import Textarea from "inputs/Textarea"
import Combobox from "inputs/Combobox"
import ValueOfArrangement from "inputs/ValueOfArrangement"
import Textfield from "inputs/Textfield"
import Radio from "inputs/Radio"
import * as R from "ramda"

const getOptions = options =>
  options.map(x => ({
    ...x,
    value: x.id,
    name: x.text,
  }))

export const getSpecificProps = data => {
  const t = data.questionType

  if (t === answerTypes.single.value)
    return {
      byValue: true,
      options: getOptions(data.options),
    }
  if (t === answerTypes.multiple.value || t === answerTypes.confirmation.value)
    return {
      flip: true,
      squared: true,
      options: getOptions(data.options),
    }
  if (t === answerTypes.country.value || t === answerTypes.countryOther.value)
    return {
      byValue: true,
    }
  if (t === answerTypes.email.value)
    return {
      type: "email",
    }

  return {}
}

export const componentMap = {
  [answerTypes.date.value]: Datepicker,
  [answerTypes.value.value]: ValueOfArrangement,
  [answerTypes.country.value]: AutocompleteSelectCountry,
  [answerTypes.countryOther.value]: AutocompleteSelectCountry,
  [answerTypes.freeText.value]: Textarea,
  [answerTypes.email.value]: Textfield,
  [answerTypes.name.value]: InputName,
  [answerTypes.single.value]: Radio,
  [answerTypes.multiple.value]: Combobox,
  [answerTypes.confirmation.value]: Combobox,
}

export const getEnabled = (
  data,
  indices,
  enabled,
  disabledByParent,
  nonCustomRoot,
  disabledByRoot,
) => {
  if (disabledByRoot) return false
  if (disabledByParent) return false
  if (nonCustomRoot) return true
  if (!data.precondition) return true

  const x = R.path(indices.slice(0, -1).concat("enabled"), enabled).find(
    x => x.id === data.id,
  )
  return x && x.isEnabled
}

export const getHandleChange =
  ({ indices, values = [], data, onChange }) =>
  (x, registryValueTags, keyRegistryValue, clearTags) => {
    const answerIndex = R.pathOr([], indices, values).findIndex(
      y => y.templateQuestionId === data.id,
    )

    const tagValues =
      registryValueTags || clearTags
        ? {
            registryValueTags,
            keyRegistryValue,
          }
        : {}

    const answerObj = {
      [answerKey[data.questionType]]: x,
    }

    // answer exists
    if (answerIndex !== -1) {
      const newVal = R.pipe(
        // edit answer
        R.over(
          R.lensPath(indices.concat([answerIndex])),
          R.mergeLeft({
            ...answerObj,
            ...tagValues,
          }),
        ),
      )(values)

      onChange(newVal)
      return newVal
    }

    // answer doesnt exist
    const newVal = R.pipe(
      // edit answer
      R.over(
        R.lensPath(indices),
        R.append({
          templateQuestionId: data.id,
          ...answerObj,
          ...tagValues,
        }),
      ),
    )(values)

    onChange(newVal)
    return newVal
  }

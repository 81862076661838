import { localize } from "locale"

export const getSuburlOptions = (match, options) =>
  options
    .map(x => ({
      ...x,
      name: `${x.reportType} (${localize("version")} ${x.version})`,
      path: match.path + "/:id",
      url: match.url + "/" + x.id,
      disabled: !x.isSelected,
    }))
    .map(x => ({ ...x, value: x.url }))

import { switchMap, throwError, from, map } from "rxjs"
import { fromFetch } from "rxjs/fetch"
// import * as R from "ramda"

const getHeaders = response => {
  const { headers } = response
  return [...headers.keys()].reduce(
    (acc, cur) => ({ ...acc, [cur]: headers.get(cur) }),
    {},
  )
}

const getContentType = headers =>
  headers["content-type"] ?? headers["Content-Type"] ?? ""

const isJson = headers => getContentType(headers).includes("application/json")

export const rxFetch = ({ url, data, headers, ...config }) => {
  return fromFetch(url, {
    ...config,
    headers,
    body: isJson(headers) ? JSON.stringify(data) : data,
  }).pipe(
    switchMap(response => {
      const { status, statusText } = response
      const headers = getHeaders(response)

      const mapper = response => {
        if (response.ok) {
          return map(x => ({ data: x, headers }))
        }

        return switchMap(x =>
          throwError({
            response: {
              data: x,
              status,
            },
            status,
            statusText,
            headers,
          }),
        )
      }

      if (isJson(headers)) {
        return from(response.json()).pipe(mapper(response))
      }

      return from(response.text()).pipe(mapper(response))
    }),
  )
}

import styled from "styled-components/macro"
import theme from "theme"

export const EditBarWrap = styled.div`
  display: flex;

  margin-top: 10px;

  ${theme.mq.tablet} {
    margin-top: 0;
  }
`

import { del, get, post, put } from "common/request"

// these helpdesk requests are used for managing tutorials
export const reportingRequest = {
  reporting: assessmentId => get(`/api/assessment/${assessmentId}/reporting`),
  conclusionReporting: assessmentId =>
    get(`/api/conclusion/${assessmentId}/reporting`),
  editReporting: (assessmentId, data) =>
    put(`/api/assessment/${assessmentId}/reporting`, data),
  uploadFile: assessmentId => data =>
    post(`/api/assessment/${assessmentId}/reporting/file`, data, {
      doNotSetContentType: true,
    }),
  downloadXML: assessmentId =>
    get(`/api/assessment/${assessmentId}/reporting/xml`),
  initDownloadPDF: (assessmentId, data) =>
    post(`/api/assessment/${assessmentId}/fileExport`, data),
  pollDownloadPDF: exportId => get(`/api/fileExport/${exportId}/doc`),
  downloadFile: assessmentId => attachmentId =>
    get(`/api/assessment/${assessmentId}/reporting/attachment/${attachmentId}`),
  reportingTypes: assessmentId =>
    get(`/api/assessment/${assessmentId}/reporting/types`),
  verify: assessmentId =>
    get(`/api/assessment/${assessmentId}/reporting/verify`),
}

export const reportingLibraryRequest = {
  reporting: assessmentId =>
    get(`/api/assessmentLibrary/${assessmentId}/reporting`),
  editReporting: (assessmentId, data) =>
    put(`/api/assessmentLibrary/${assessmentId}/reporting`, data),
  reportingTypes: assessmentId =>
    get(`/api/assessmentLibrary/${assessmentId}/reporting/types`),
  verify: assessmentId =>
    get(`/api/assessmentLibrary/${assessmentId}/reporting/verify`),
  uploadFile: assessmentId => data =>
    post(`/api/assessmentLibrary/${assessmentId}/reporting/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: assessmentId => attachmentId =>
    get(
      `/api/assessmentLibrary/${assessmentId}/reporting/attachment/${attachmentId}`,
    ),
}

export const conclusionCommentsRequests = assessmentId => ({
  comments: get(`/api/conclusion/${assessmentId}/comments`),
  addComment: data => post(`/api/conclusion/${assessmentId}/comments`, data),
  deleteComment: id => del(`/api/conclusion/comment/${id}`),
  editComment: (id, data) => put(`/api/conclusion/comment/${id}`, data),
  uploadFile: data =>
    post(`/api/conclusion/${assessmentId}/comment/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: attachmentId =>
    get(`/api/conclusion/comment/attachment/${attachmentId}`),
})

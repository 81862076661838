import React, { useRef } from "react"
import withFormField from "hoc/withFormField"
import { StyledTextarea } from "./styled"

const setHeight = el => {
  el.style.height = "1px"
  el.style.height = 3 + el.scrollHeight + "px"
}

const Textarea = ({ value, onChange, readOnly, ...rest }) => {
  const ref = useRef(null)

  const isLongText = value?.length > 100

  const onRef = el => {
    if (!el) return

    ref.current = el
    if (isLongText) {
      setHeight(el)
    }
  }

  return (
    <StyledTextarea
      {...rest}
      readOnly={readOnly}
      value={value || ""}
      ref={onRef}
      onChange={e => {
        const t = e.target
        onChange(t.value)
        setHeight(t)
      }}
    />
  )
}

export default withFormField(Textarea)

import React from "react"
import { Box } from "styledComponents/Containers"
import withData from "hoc/withData"
import * as R from "ramda"
import { announcementsRequest } from "data/announcements/api"
import Announcement from "./Announcement"

const Announcements = ({ data }) => {
  const [announcements, setAnnouncements] = React.useState(data)
  const [closingId, setClosingId] = React.useState()

  return (
    <Box bg="#f8f8f8">
      {announcements.map(x => (
        <Announcement
          text={x.message}
          key={x.id}
          onClose={() => {
            setClosingId(x.id)
            setTimeout(() => {
              setAnnouncements(R.filter(y => y.id !== x.id))
              setClosingId()
            }, 450)
          }}
          closing={closingId === x.id}
        />
      ))}
    </Box>
  )
}
export default withData(() => announcementsRequest.activeList())(Announcements)

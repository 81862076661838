import React from "react"
import withModal from "hoc/withModal"
import withData from "hoc/withData"
import { compareToolRequest } from "data/templates/api"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import Node from "./Node"
import Empty from "components/Empty"
import * as R from "ramda"

const CompareToolModal = ({ data, onChange, value, close }) => {
  if (data.length === 0) return <Empty />

  return (
    <>
      {data.map((group, i) => (
        <Box mt={i === 0 ? 0 : 30} key={i}>
          <P fs={20} bold mb={15}>
            {group.caption}
          </P>
          {group.data.map(x => (
            <Node
              node={x}
              key={x.id}
              onChange={onChange}
              value={value}
              closeModal={close}
            />
          ))}
        </Box>
      ))}
    </>
  )
}

export default R.compose(
  withModal(() => localize("compareToolLinks"), {
    fullScreen: true,
  }),
  withData(p => compareToolRequest.hierarchy(p.template.countryCode)),
)(CompareToolModal)

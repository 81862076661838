import styled, { css } from "styled-components/macro"

export const NodeWrap = styled.div`
  margin-bottom: 20px;

  ${p =>
    p.last &&
    css`
      margin-bottom: 0;
    `}
`

export const NodeHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const NodeBody = styled.div`
  padding: 20px;
`

export const EditBarWrap = styled.div`
  display: flex;
`

import React from "react"
import { Route, Redirect } from "react-router-dom"

const ProtectedRoute = ({ path, component: C, loggedIn, urls, ...rest }) => (
  <Route
    path={path}
    render={p => {
      if (!loggedIn) {
        return (
          <Redirect
            to={{
              // if modules are loaded, logout was performed
              pathname: rest.modules ? urls.logout : urls.login,
              state: { from: p.location.pathname + p.location.search },
            }}
          />
        )
      }

      return <C {...p} {...rest} urls={urls} />
    }}
  />
)

export default ProtectedRoute

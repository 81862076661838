import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import LabelValue from "components/LabelValue"
import { elmaasRequest } from "data/elmaas/api"
import withForm from "hoc/withForm"
import { P } from "styledComponents/Typography"
import withModal from "hoc/withModal"
import Textfield from "inputs/Textfield"
import Filepicker from "inputs/Filepicker"
import { stringRequired } from "validators/string"
import * as R from "ramda"

const SendReportToTaUnknownModal = ({ close, report, taxAccount }) => (
  <>
    <P mb={20}>
      Tax authority account is not registered. Please conntact the site admin,
      if you want to register the account. You can still submit the report
      without account registration, but a valid certificate must be provided.
    </P>
    <LabelValue mb={20} label="Filing entity name" value={report.entityName} />
    <LabelValue mb={20} label="Account ID" value={taxAccount.accountId} />
    <LabelValue mb={20} label="AuthSteuernummer (BOP)" value={taxAccount.bop} />
    <Filepicker
      name="certificateBlob"
      label="Client Certificate"
      sublabel="in PEM format"
      accept={[".pem"]}
      required
      upload={elmaasRequest.uploadCertificate}
      noEmptyError
      singleFile
    />
    <Textfield required width={130} name="pin" label="PIN" type="password" />
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="Cancel" />
      <Button danger type="submit" text="Send report to tax authority" />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(R.always("Send report to tax authority")),
  withForm({
    schema: R.always({
      pin: stringRequired,
      certificateBlob: stringRequired,
    }),
    onSubmit: p => v =>
      elmaasRequest.submitReportUnknown(p.reportId, {
        pin: v.pin,
        certificateBlob: R.path(["certificateBlob", "blobName"], v),
      }),
    onSuccess: p => () => {
      p.close()
      p.refetch()
    },
    successText: () => R.always("Report successfully sent."),
    onError: (_, onChange) => () => onChange("certificateBlob")(null),
  }),
)(SendReportToTaUnknownModal)

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { conclusionRequest } from "data/conclusions/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textarea from "inputs/Textarea"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import { stringRequired } from "validators/string"
import * as R from "ramda"
import { preconclusionRequest } from "data/preassessments/api"
import { caseRequest } from "data/cockpit/api"
import withData from "hoc/withData"
import { getCaseUrl } from "data/cockpit/redirect"

const WithdrawModal = ({ close, isPreassessment, data, conclusion }) => (
  <>
    <P mb={20}>
      The withdrawal will remove current conclusion.{" "}
      {isPreassessment ? "Pre-assessment" : "Assessment"} will be put back into
      the "In progress" state and{" "}
      {isPreassessment ? "pre-assessment" : "assessment"} team can edit it.
    </P>
    {isPreassessment && data && conclusion.status === "Potentially reportable" && (
      <P mb={20} bold>
        When a Pre-assessment conclusion is withdrawn, it will only be possible
        to access the following sections including CASE DETAILS and any linked
        Assessments or Conclusions when you disable the switch "Pre-Assessment"
        in the CASE SETTINGS section.
      </P>
    )}
    <Textarea
      required
      name="reason"
      label={localize("reasonForRejection")}
      placeholder={localize("writeTheReason")}
    />
    <P mb={20} bold>
      {localize("areYouSureYouWantToWithdrawConclusion")}
    </P>
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="No" />
      <Button danger type="submit" text={localize("yesWithdraw")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(p => `${localize("withdrawConclusion")} ${p.conclusion.name}`),
  withUrls,
  withForm({
    schema: R.always({
      reason: stringRequired,
    }),
    onSubmit: p => v =>
      (p.isPreassessment ? preconclusionRequest : conclusionRequest).withdraw(
        p.conclusion.id,
      )(v),
    onSuccess: p => () => {
      p.close()
      p.onSuccess()
    },
    redirect: (_, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return getCaseUrl(p.match, p.assessment)
      }
      return p.urls.conclusions
    },
    successText: p => () =>
      localize("conclusionHasBeenWithdrawnSuccessfully")(p.conclusion.name),
  }),
  withData(p => caseRequest.hasLinkedObjects(p.caseId), {
    skip: p => !p.isPreassessment,
  }),
)(WithdrawModal)

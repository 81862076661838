import React from "react"

const withOnUpdate = fn => C =>
  class extends React.Component {
    componentDidUpdate(prev) {
      fn(prev, this.props)
    }

    render() {
      return <C {...this.props} />
    }
  }

export default withOnUpdate

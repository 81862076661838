import { get, post, put, del } from "common/request"

export const reportRequest = {
  list: params => get(`/api/massReports?${params}`),
  detail: id => get(`/api/massReport/${id}`),
  conclusion: (id, conclusionId) =>
    get(`/api/massReport/${id}/conclusion/${conclusionId}`),
  create: data => post("/api/massReport", data),
  setStatus: assessmentId => data =>
    put(`/api/conclusion/${assessmentId}/status`, data),
  setStatuses: id => data => put(`/api/massReport/${id}/statuses`, data),
  // downloadXML: assessmentId =>
  //   get(`/api/assessment/${assessmentId}/reporting/xml`),
  editIds: assessmentId => data =>
    put(`/api/conclusion/${assessmentId}/reporting/externalIds`, data),
  downloadXML: id => get(`/api/massReport/${id}/xml`),
  export: id =>
    post(`/api/massReport/${id}/fileExport`, {
      type: "MassReportZip",
    }),
  delete: id => put(`/api/massReport/${id}/delete`),
}

export const reportCommentsRequests = reportId => ({
  comments: get(`/api/massReport/${reportId}/comments`),
  addComment: data => post(`/api/massReport/${reportId}/comment`, data),
  deleteComment: id => del(`/api/massReport/comment/${id}`),
  editComment: (id, data) => put(`/api/massReport/comment/${id}`, data),
  uploadFile: data =>
    post(`/api/massReport/${reportId}/comment/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: attachmentId =>
    get(`/api/massReport/comment/attachment/${attachmentId}`),
})

import React from "react"
import { P } from "styledComponents/Typography"

const Validations = ({ val, type }) => {
  if (!val.some(x => x.registryCatalogId) && type) {
    return (
      <P fs={12} error mt={3}>
        Tag is missing
      </P>
    )
  }

  return null
}

export default Validations

import React from "react"
import Link from "components/Link"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"

const Deutsch = () => (
  <>
    <P italic mb={40} bold fs={24}>
      Deutsch:
    </P>
    <P bold mb={20} fs={20}>
      Datenschutzhinweise nach Art. 13, 14 EU Datenschutzgrundverordnung (DSGVO)
    </P>

    <P mb={20}>
      Die nachfolgenden Datenschutzhinweise sollen Ihnen verständlich,
      transparent und übersichtlich erläutern, wie Ihre personenbezogenen Daten
      bei der Nutzung durch unsere PwC DAC6 Compliance Solution (
      <Link to="https://dac6compliance.pwc.com">
        https://dac6compliance.pwc.com
      </Link>
      ) verarbeitet werden. Sollten Sie dennoch Verständnisfragen oder sonstige
      Rückfragen zum Datenschutz bei PwC haben, können Sie sich gerne über die
      untenstehenden Kontaktdaten an uns wenden.
    </P>
    <P mb={20}>
      DAC6 Compliance Solution wurde entwickelt, um DAC6-Fragebögen
      vorzubereiten, die bei den Steuerbehörden eingereicht werden. Sie enthält
      Assessments als notwendigen ersten Schritt bei der Vorbereitung dieser
      Fragebögen. Sie unterstützt Kunden bei der Einhaltung ihrer DAC6
      compliance.
    </P>
    <P mb={20}>
      DAC6 Compliance Solution kann im Rahmen von Engagements eingesetzt werden,
      bei denen Kunden einen Zugriff erhalten. Engagements bedeutet in diesem
      Zusammenhang, dass Sie oder Ihr Arbeitgeber eine geschäftliche Beziehung
      (Beratungsleistung) mit PwC eingehen bzw. eingegangen sind. Hierzu gilt
      ergänzend zu den hier vorliegenden Datenschutzhinweisen Ziffer 6.
    </P>
    <P mb={20}>
      DAC6 Compliance Solution kann als SaaS Stand-alone angeboten bzw.
      vertrieben werden. SaaS Stand-alone bedeutet in diesem Zusammenhang, dass
      die Technologie ausschließlich außerhalb einer konkreten Beratungsleistung
      angeboten bzw. vertrieben wird. Hierzu gilt ergänzend zu den hier
      vorliegenden Datenschutzhinweisen Ziffer 7. Die Verantwortung für die in
      Ziffern 7 beschriebenen Verarbeitungsvorgänge liegt hingegen bei den dort
      genannten Verantwortlichen im Sinne von Art. 4 Nr. 7 DSGVO. Die Ziffer 7
      ist vor diesem Hintergrund rein informativ.
    </P>

    <P bold>1. Verantwortlicher</P>

    <P mb={20}>
      Der Verantwortliche im Sinne von Art. 4 Nr. 7 DSGVO für die Verarbeitung
      Ihrer personenbezogenen Daten ist:
    </P>
    <P bold>PricewaterhouseCoopers GmbH Wirtschaftsprüfungsgesellschaft</P>
    <P>Friedrich-Ebert-Anlage 35-37</P>

    <P>60327 Frankfurt am Main </P>

    <P>
      E-Mail:{" "}
      <Link to="mailto:webkontakt_anfragen@de.pwc.com">
        webkontakt_anfragen@de.pwc.com
      </Link>
    </P>

    <P>Telefonzentrale: +49 69 9585-0 </P>

    <P mb={20}>Fax: +49 69 9585-1000 </P>
    <P bold mb={20}>
      2. Datenschutzbeauftragter
    </P>
    <P mb={20}>
      PwC hat einen Datenschutzbeauftragten gemäß Art. 37 DSGVO benannt. Sie
      können den Datenschutzbeauftragten der PwC, Dr. Tobias Gräber, unter den
      folgenden Kontaktdaten erreichen:
    </P>
    <P>
      E-Mail:{" "}
      <Link to="mailto:webkontakt_anfragen@de.pwc.com">
        webkontakt_anfragen@de.pwc.com
      </Link>
    </P>

    <P mb={20}>Telefon: +49 69 9585-0 </P>
    <P>Adresse für postalische Kontaktaufnahme: </P>

    <P>PricewaterhouseCoopers GmbH WPG </P>

    <P>Dr. Tobias Gräber, Datenschutzbeauftragter </P>

    <P>Friedrich-Ebert-Anlage 35-37 </P>

    <P mb={20}>60327 Frankfurt am Main </P>

    <P bold mb={20}>
      3. Betroffenenrechte/Ihre Rechte aus dem Datenschutzrecht
    </P>
    <P mb={20}>
      Sie haben folgende Rechte nach dem geltenden Datenschutzrecht hinsichtlich
      der Sie betreffenden personenbezogenen Daten.
    </P>
    <P mb={20}>
      <P bold inline>
        Recht auf Auskunft:
      </P>{" "}
      Sie können jederzeit von uns Auskunft darüber verlangen, ob und welche
      personenbezogenen Daten wir über Sie speichern. Die Auskunftserteilung ist
      für Sie kostenfrei. Das Recht auf Auskunft besteht nicht oder nur
      eingeschränkt, wenn und soweit durch die Auskunft geheimhaltungsbedürftige
      Informationen offenbart würden, bspw. Informationen, die einem
      Berufsgeheimnis unterliegen.
    </P>
    <P mb={20}>
      <P bold inline>
        Recht auf Berichtigung:
      </P>{" "}
      Wenn Ihre personenbezogenen Daten, die bei uns gespeichert sind, unrichtig
      oder unvollständig sind, haben Sie das Recht, von uns jederzeit die
      Berichtigung dieser Daten zu verlangen.
    </P>
    <P mb={20}>
      <P bold inline>
        Recht auf Löschung:
      </P>{" "}
      Sie haben das Recht, von PwC die Löschung Ihrer personenbezogenen Daten zu
      verlangen, wenn und soweit die Daten für die Zwecke, für die sie erhoben
      wurden, nicht mehr benötigt werden oder, wenn die Verarbeitung auf Ihrer
      Einwilligung beruht, Sie Ihre Einwilligung widerrufen haben. In diesem
      Fall müssen wir die Verarbeitung Ihrer personenbezogenen Daten einstellen
      und diese aus unseren IT-Systemen und Datenbanken entfernen.
    </P>
    <P>Ein Recht auf Löschung besteht nicht, soweit</P>
    <P ul ml={20} mb={20}>
      <li>
        die Daten aufgrund einer gesetzlichen Pflicht nicht gelöscht werden
        dürfen oder aufgrund einer gesetzlichen Pflicht verarbeitet werden
        müssen;
      </li>

      <li>
        die Datenverarbeitung erforderlich ist zur Geltendmachung, Ausübung oder
        Verteidigung von Rechtsansprüchen.
      </li>
    </P>
    <P mb={20}>
      <P inline bold>
        Recht auf Einschränkung der Verarbeitung:
      </P>{" "}
      Sie haben das Recht, die Einschränkung der Verarbeitung Ihrer
      personenbezogenen Daten von uns zu verlangen.
    </P>
    <P>
      <P inline bold>
        Recht auf Datenübertragbarkeit:
      </P>{" "}
      Sie haben das Recht, von uns die von Ihnen bereitgestellten Daten in einem
      strukturierten, gängigen und maschinenlesbaren Format zu erhalten sowie
      das Recht, dass diese Daten an einen anderen Verantwortlichen übermittelt
      werden.
    </P>
    <P>Dieses Recht besteht nur, wenn</P>
    <P ul ml={20} mb={20}>
      <li>
        Sie uns die Daten auf der Grundlage einer Einwilligung oder aufgrund
        eines mit Ihnen abgeschlossenen Vertrages zur Verfügung gestellt haben;
      </li>

      <li>die Verarbeitung mithilfe automatisierter Verfahren erfolgt.</li>
    </P>
    <Box mb={20} highlight>
      <P>
        <P inline bold>
          Recht auf Widerspruch gegen die Verarbeitung:
        </P>{" "}
        Wenn die Verarbeitung Ihrer Daten auf der Grundlage des Art. 6 Abs. 1
        lit. f) DSGVO erfolgt, können Sie jederzeit Widerspruch gegen die
        Verarbeitung einlegen.
      </P>
    </Box>
    <P mb={20}>
      Alle der oben beschriebenen Betroffenenrechte können Sie gegenüber PwC
      geltend machen, wenn Sie Ihr konkretes Begehren an die folgenden
      Kontaktdaten richten:
    </P>
    <P bold>Per E-Mail:</P>

    <P mb={20}>
      <Link to="mailto:DE_Datenschutz@pwc.com">DE_Datenschutz@pwc.com</Link>
    </P>
    <P bold>Per Post:</P>

    <P>PricewaterhouseCoopers GmbH WPG</P>

    <P>Dr. Tobias Gräber, Datenschutzbeauftragter</P>

    <P>Friedrich-Ebert-Anlage 35-37</P>

    <P mb={20}>60327 Frankfurt am Main</P>

    <P bold mb={20}>
      4. Beschwerderecht bei einer Datenschutzaufsichtsbehörde
    </P>
    <P mb={20}>
      Sie haben nach Maßgabe des Art. 77 DSGVO das Recht auf Beschwerde bei
      einer Datenschutzaufsichtsbehörde, wenn Sie der Ansicht sind, dass die
      Verarbeitung Ihrer personenbezogenen Daten gegen das Datenschutzrecht
      verstößt.
    </P>

    <P bold mb={20}>
      5. Beschreibung der Datenverarbeitungen und Rechtsgrundlagen für die
      Verarbeitung
    </P>

    <P bold mb={20}>
      5.1 Empfänger der Daten
    </P>

    <P mb={20}>
      Zur Erfüllung der nachstehend aufgezählten Verarbeitungszwecke findet auch
      ein Datentransfer an Dritte statt. Dies kann auch die Übermittlung von
      personenbezogenen Daten in das europäische und außereuropäische Ausland
      beinhalten und die Speicherung von Daten auch außerhalb der EU bzw. des
      Europäischen Wirtschaftsraums (EWR).
    </P>

    <P mb={20} underline>
      Weisungsgebundene Empfänger
    </P>

    <P mb={20}>
      Wir geben Ihre Daten an weisungsgebundene Dienstleister sowohl innerhalb
      des PwC-Netzwerks als auch gegenüber sonstigen Dritten, wie z.B.
      IT-Dienstleistern weiter, die uns bei unserem Tätigwerden, z.B. im Rahmen
      der Verwaltung und Pflege der Webseiten und den damit verbundenen Systemen
      und/oder für andere interne oder administrative Zwecke unterstützen.
    </P>

    <P mb={20}>
      PwC ist Mitglied des globalen PwC-Netzwerks, das aus den einzelnen
      rechtlich selbständigen und unabhängigen PwC-Gesellschaften besteht. Wir
      nutzen im Rahmen unserer Tätigkeit weitere deutsche oder ausländische
      PwC-Netzwerkgesellschaften als weisungsgebundene netzwerkinterne
      IT-Dienstleister, die Leistungen des Betriebs, der Wartung und Pflege der
      von den PwC-Netzwerkgesellschaften genutzten IT-Systeme und Applikationen
      erbringen. Dies ist insbesondere die PwC IT Services Ltd. mit Sitz im
      Vereinigten Königreich (UK).
    </P>

    <P mb={20}>
      Wenn wir Daten an weisungsgebundene Dienstleister weitergeben, benötigen
      wir dafür keine gesonderte Rechtsgrundlage.
    </P>

    <P mb={20}>
      Nähere Informationen zu den von PwC eingesetzten Cloud-Dienste-Anbietern
      können Sie unter folgendem Link abfragen:
      www.pwc.de/externe-dienstleister.
    </P>

    <P mb={20} underline>
      Eigenverantwortliche Empfänger
    </P>

    <P mb={20}>
      Darüber hinaus geben wir Ihre Daten im Einzelfall sowohl innerhalb des
      PwC-Netzwerks als auch an sonstige Dritten weiter, welche Ihre Daten
      eigenverantwortlich nutzen. Bspw. übermitteln wir im Einzelfall
      personenbezogene Daten zur Unterstützung und Effektivierung unserer
      Geschäftsprozesse (einschließlich koordinierter Marketingaktivität) auch
      an andere Unternehmen aus dem PwC-Netzwerk.
    </P>

    <P mb={20}>
      Darüber hinaus geben wir Ihre Daten im Einzelfall auch an andere Dritte
      weiter, wie z.B. Behörden, Gerichte oder andere Stellen, wenn wir
      gesetzlich oder aufgrund behördlicher oder gerichtlicher Anordnung eines
      EU-Mitgliedsstaates zur Herausgabe von personenbezogenen Daten an diese
      Stellen verpflichtet sind. Diese Stellen nutzen die Daten ebenfalls
      eigenverantwortlich.
    </P>

    <P mb={20}>
      Soweit Sie explizit eingewilligt haben, ist Art. 6 Abs. 1 lit. a) DSGVO
      Rechtsgrundlage für die Datenübermittlung. Soweit eine gesetzliche
      Verpflichtung zur Offenlegung der Daten besteht, ist Art. 6 Abs. 1 lit. c)
      DSGVO Rechtsgrundlage für die Datenübermittlung. Wenn die Offenlegung
      dagegen zur Erfüllung einer vertraglichen- oder vorvertraglichen Maßnahme
      mit Ihnen als natürliche Person erforderlich ist, ist Art. 6 Abs. 1 lit.
      b) DSGVO Rechtsgrundlage für die Datenübermittlung. Andernfalls basiert
      die Übermittlung auf unseren berechtigten Interessen und die
      Rechtsgrundlage ist Art. 6 Abs. 1 lit. f) DSGVO; wir und die anderen
      Unternehmen des PwC-Netzwerks haben ein Interesse daran, unsere
      Arbeitsprozesse effizient zu gestalten und Geschäftsprozesse dazu
      innerhalb des PwC-Netzwerks aufzuteilen.
    </P>

    <P mb={20} underline>
      Datentransfer an Empfänger in Drittländern außerhalb EU/EWR
    </P>

    <P mb={20}>
      Soweit einer der o.g. Datentransfers an einen Empfänger außerhalb des EWR
      erfolgt (in sog. „Drittländer“), wird ein angemessenes Datenschutzniveau
      für die Auslandsübermittlung durch geeignete Sicherheitsmaßnahmen
      gewährleistet.
    </P>

    <P mb={20}>
      Für Datentransfers innerhalb des PwC-Netzwerks haben die
      PwC-Netzwerkgesellschaften unter anderem einen internen Datenschutzvertrag
      abgeschlossen, der für den Datentransfer von personenbezogenen Daten aus
      EU/EWR-Staaten zu PwC-Netzwerkgesellschaften außerhalb EU/EWR die
      Einhaltung der EU-Standardvertragsklauseln der EU-Kommission im Sinne des
      Art. 46 Abs. 2 lit. c) DSGVO vorsieht.
    </P>

    <P mb={20}>
      Soweit Sie Fragen zu solchen Datenschutzverträgen auf Basis der
      EU-Standardvertragsklauseln haben oder Sie gerne mehr Informationen zu
      weiteren Sicherheitsmechanismen und Sicherheitsmaßnahmen für den
      Datentransfer in Drittländer hätten, können Sie sich gerne an unseren
      Datenschutzbeauftragten wenden und diesen z.B. unter
      DE_Datenschutz@pwc.com kontaktieren.
    </P>
    <P bold mb={20}>
      5.2 Verarbeitung personenbezogener Daten bei Aufrufen und Nutzung der PwC
      DAC6 Compliance Solution
    </P>
    <P mb={20}>
      Wenn Sie unser webbasiertes Tool aufrufen, erheben wir die Daten, die
      technisch notwendig sind, um Ihnen PwC DAC6 Compliance Solution
      anzuzeigen. Es handelt sich dabei um die folgenden personenbezogenen
      Daten, die von Ihrem Browser automatisch an unseren Server übermittelt
      werden:
    </P>
    <P>DAC6 Compliance Solution:</P>

    <P ul ml={20} mb={20}>
      <li>E-Mail-Adresse</li>
    </P>
    <P>
      Nur für Azure und zu Zwecken der Fehlerbehebung (bitte beachten Sie, dass
      die folgenden Angaben nur auf Azure-Ebene gemacht werden, unsere DCS
      solution verarbeitet oder sammelt diese Angaben NICHT):
    </P>
    <P ul ml={20} mb={20}>
      <li>IP-Adresse (und Standort basierend auf der IP-Adresse)</li>

      <li>Datum und Uhrzeit Ihrer Anfrage/Ihres Aufrufs </li>

      <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>

      <li>
        Inhalt der Anfrage (Information, welche konkrete Seite von Ihnen
        aufgerufen wurde)
      </li>

      <li>Zugriffsstatus/http-Statuscode</li>

      <li>Website, von der die Anfrage kam</li>

      <li>Browser (Informationen zu dem von Ihnen genutzten Browser)</li>

      <li>
        Betriebssystem und dessen Oberfläche (Betriebssystem des von Ihnen zum
        Aufruf (Name der Technologie) genutzten Rechners)
      </li>

      <li>Sprache und Version der Browsersoftware</li>

      <li>
        Gerätekennzeichnung (dies ist die eindeutige Nummer Ihres Endgeräts,
        z.B. UDID, DeviceID, IMEI)
      </li>

      <li>Nummer des Netzteilnehmers (IMSI)</li>
      <li>Mobilfunknummer (MSISDN)</li>

      <li>MAC-Adresse für WLAN-Nutzung</li>

      <li>Name des mobilen Endgeräts</li>
    </P>
    <P mb={20}>
      Die Verarbeitung dieser personenbezogenen Daten erfolgt auf Grundlage des
      Art. 6 Abs. 1 lit. f) DSGVO. Ohne die Verwendung dieser Daten kann das
      Tool nicht aufgerufen und den Nutzern angeboten werden, es besteht ein
      berechtigtes Interesse daran, Aufruf und Nutzung des Tools technisch zu
      ermöglichen. Zudem werden die Daten gespeichert, um PwC DAC6 Compliance
      Solution technisch abzusichern. Der Verantwortliche hat ein Interesse an
      der Sicherheit und Verfügbarkeit des Tools.
    </P>
    <P mb={20}>
      Die oben genannten Daten werden für 90 Tage gespeichert und dann gelöscht.
    </P>
    <P mb={20}>
      PwC DAC6 Compliance Solution wird vom Dienstleister Microsoft Corporation
      One Microsoft Way Redmond, WA 98052-6399 USA gehostet. Die auf unserem
      Tool erhobenen Daten werden daher auf den Servern des Dienstleisters
      gespeichert. Die Serverstandorte befinden sich in Deutschland und in
      anderen EU-Ländern.
    </P>
    <P mb={20} bold>
      5.3 Registrierung und Anmeldung{" "}
    </P>
    <P mb={20}>
      Der Aufruf und die Nutzung unseres Tools ist nicht ohne Registrierung und
      Anmeldung möglich. Eine Registrierung kann nur im Anschluss an den
      Vertragsschluss mit PwC erfolgen.
    </P>
    <P mb={20}>
      Die Registrierung erfolgt über das Group- und Usermanagement (GUM) von PwC
      Identity, unserer unternehmensweiten Single-Sign-On-Lösung. In diesem
      Zusammenhang müssen Sie sich mit mehreren unten aufgeführten persönlichen
      Datenattributen registrieren, um sich über PwC Identity anmelden zu
      können.
    </P>
    <P mb={20}>
      Bei der Registrierung und Anmeldung verarbeitet PwC die folgenden
      Informationen:
    </P>
    <P ul ml={20} mb={20}>
      <li>E-Mail-Adresse</li>

      <li>Name</li>

      <li>Mobilfunknummer (optional)</li>

      <li>Passwort</li>
    </P>
    <P>
      Die Verarbeitung dieser personenbezogenen Daten erfolgt auf Grundlage von
      Art. 6 Abs. 1 lit. c) i. V. m. Art. 32 DSGVO. Sämtliche Daten werden nach
      Beendigung des Vertragsverhältnisses von uns gelöscht. Soweit gesetzliche
      Aufbewahrungspflichten bestehen, werden die Daten für die Dauer der
      gesetzlich vorgeschriebenen
    </P>

    <P mb={20}>Aufbewahrungspflicht gespeichert.</P>

    <P bold mb={20}>
      5.4 Cookies
    </P>

    <P mb={20}>
      Um den Besuch unseres Tools attraktiv zu gestalten und die Nutzung
      bestimmter Funktionen zu ermöglichen, verwenden wir in unserem Tool
      Cookies. Hierbei handelt es sich um kleine Textdateien, die auf Ihrem
      Endgerät abgelegt werden. Einige der von uns verwendeten Cookies werden
      nach dem Ende der Browser-Sitzung, also nach Schließen Ihres Browsers,
      wieder gelöscht (sog. Sitzungs-Cookies). Andere Cookies verbleiben auf
      Ihrem Endgerät und ermöglichen uns oder unseren Partnerunternehmen, Ihren
      Browser beim nächsten Besuch wiederzuerkennen (sog. persistente Cookies).
    </P>

    <P mb={20}>Es werden die nachfolgenden Cookie-Kategorien verwendet: </P>
    <P mb={20} underline>
      Es werden im aktuellen technischen Setup unseres webbasierten Tools keine
      Cookies verwendet. Dennoch informieren wir Sie über die Funktionsweise
      technisch erforderlicher Cookies, sollte sich das technische Setup
      zukünftig ändern.
    </P>
    <P>
      <P inline bold>
        Technisch erforderliche
      </P>{" "}
      Cookies:
    </P>

    <P mb={20}>
      Diese Cookies sind für den Betrieb und die Funktionalität der Webseite
      technisch erforderlich. Sie machen die Webseite technisch zugänglich,
      sicher und nutzbar und bieten wesentliche und grundlegende
      Funktionalitäten, wie z. B. die Navigation auf der Webseite, die korrekte
      Darstellung der Webseite im Internet-Browser oder das
      Einwilligungsmanagement.
    </P>

    <P mb={20}>
      Rechtsgrundlage für die Nutzung technisch erforderlicher Cookies ist § 25
      Abs. 2 Nr. 2 TTDSG (“Telekommunikations-Telemediendatenschutz-Gesetz”)
      bzw. auf Grundlage des Art. 6 Abs. 1 lit. f DSGVO zur Wahrung unserer
      berechtigten Interessen. Unsere berechtigten Interessen liegen hierbei
      insbesondere darin, Ihnen eine technisch optimierte sowie
      benutzerfreundlich und bedarfsgerecht gestaltete Website zur Verfügung
      stellen zu können sowie die Sicherheit unserer Systeme zu gewährleisten.
    </P>

    <P bold mb={20}>
      6. Datenverarbeitung bei Nutzung der DAC6 Compliance Solution innerhalb
      von Engagements
    </P>
    <P mb={20}>
      Sie selbst oder Ihr Arbeitgeber bezieht eine Beratungsleistung von PwC.
    </P>
    <P>
      DAC6 Compliance Solution nutzen wir zur Erbringung unserer vertraglich
      vereinbarten Leistungen. Hierzu gelten in Ergänzung zu diesen vorliegenden
      Datenschutzhinweisen die “Datenschutzhinweise im Rahmen der Leistungen von
      PwC”, welche vor unserer Beauftragung übermittelt wurden. Die
      “Datenschutzhinweise im Rahmen der Leistungen von PwC” beschreiben, wie
      PwC personenbezogene Daten im Rahmen der Durchführung von Dienstleistungen
      der Steuerberatung, Rechtsberatung oder Unternehmensberatung /
      Wirtschaftsprüfung oder sonstigen Aufträgen verarbeitet. Die
      “Datenschutzhinweise im Rahmen der Leistungen von PwC” können Sie nochmals
      unter folgendem Link abrufen:
    </P>

    <P mb={20}>
      <Link to="https://www.pwc.de/de/datenschutzerklaerung-fuer-mandanten/datenschutzhinweise-pricewaterhousecoopers-gmbh-wirtschaftspruefungsgesellschaft.html">
        https://www.pwc.de/de/datenschutzerklaerung-fuer-mandanten/datenschutzhinweise-pricewaterhousecoopers-gmbh-wirtschaftspruefungsgesellschaft.html
      </Link>
    </P>

    <P bold mb={20}>
      7. Verarbeitung, für die der Kunde verantwortlich ist (SaaS Stand-alone)
    </P>
    <P mb={20}>
      Verarbeitung von Kundendaten innerhalb von DAC6 Compliance Solution als
      SaaS stand-alone, bei denen der Kunde ausschließlich das Tool und keine
      Beratungsleistung von PwC bezieht:
    </P>
    <P>
      Innerhalb von DAC6 Compliance Solution werden personenbezogene Daten
      verarbeitet. Diese Datenverarbeitung erfolgt in der Verantwortung des
      Kunden (Art. 4 Abs. 7 DSGVO). PwC wird insoweit als Software-as-a-Service
      (SaaS) Anbieter für den Kunden als Auftragsverarbeiter i.S.v. Art. 4 Nr. 8
      DSGVO tätig.
    </P>
  </>
)

export default Deutsch

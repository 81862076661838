import * as R from "ramda"

export const store = key => val => {
  sessionStorage.setItem(key, JSON.stringify(val))
  return val
}

export const retrieve = key => JSON.parse(sessionStorage.getItem(key))

export const purge = () => {
  R.keys(sessionStorage).forEach(k => {
    sessionStorage.removeItem(k)
  })
}

import styled, { css } from "styled-components/macro"
import { ActiveIcon } from "styledComponents/Icons"
import theme from "theme"

export const IconWrapStyled = styled(ActiveIcon)`
  ${p =>
    p.white &&
    css`
      &,
      &:hover {
        border-color: white;
        svg {
          fill: white;
        }
      }
    `}
`

export const Shadow = styled.div`
  box-shadow: 0 1px 3px 0 ${theme.color.shadow};
`

import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"
import * as R from "ramda"

const withAutocomplete = R.compose(
  withState("text", "setText", ""),
  withState("selectedOption", "setSelectedOption", undefined),
  withHandlers({
    selectAutocompleteItem: p => v => {
      p.setSelectedOption(v)
      p.setText(v.name)
    },
    resetAutocomplete: p => () => {
      p.setText("")
      p.setSelectedOption(undefined)
    },
    changeText: p => v => {
      p.setSelectedOption(undefined)
      p.setText(v)
    },
  }),
)

export default withAutocomplete

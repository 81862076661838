import { get, post } from "common/request"

export const overviewRequest = {
  visibility: () => get("/api/user/overviewVisibility"),
  count: () => get("/api/tasks/count"),
  assessments: () => get("/api/data/assessments"),
  conclusions: () => get("/api/data/conclusions"),
  arrangements: () => get(`/api/data/arrangements`),
  statistics: data => post(`/api/data/statistics`, data),
  siteFilter: () => get(`/api/data/statistics/siteFilter`),
  export: data => post(`/api/data/statistics/export`, data),
}

// this part of helpdesk requests is used just for displaying for user
export const helpdeskRequest = {
  sections: () => get("/api/helpdesk/sections?lang=en"),
  contacts: () => get("/api/tenant/contacts"),
  tutorial: tutorialId => get(`/api/helpdesk/tutorial/${tutorialId}`),
}

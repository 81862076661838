import React from "react"
import withModal from "hoc/withModal"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"

const OverrideConfirmModal = ({
  submit,
  close,
  questionsWithValue,
  question,
  nonCustomRoot,
}) => (
  <Box>
    {nonCustomRoot ? (
      <P>
        The answer for question Q{question.idx} is already filled and will be
        overwritten by selected subject data from the Registry.
      </P>
    ) : (
      <>
        <P>
          Some answers are already filled and will be overwritten by selected
          subject data from the Registry.
        </P>
        <P bold gray mt={20}>
          Answers to the following questions will be overrwritten:
        </P>
        {questionsWithValue.map(q => (
          <P>Q{q.nrLabel}</P>
        ))}
      </>
    )}
    <ModalButtons buttonText="Do you want to proceed?">
      <Button secondary type="button" onClick={close} text="No" />
      <Button
        danger
        onClick={() => {
          submit()
        }}
        text="Yes, proceed"
      />
    </ModalButtons>
  </Box>
)

export default R.compose(withModal(R.always("Auto-filling from Registry")))(
  OverrideConfirmModal,
)

import React from "react"
import {
  assessmentLibraryQuestionRequest,
  assessmentQuestionRequest,
} from "data/assessments/api"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import * as R from "ramda"
import { P } from "styledComponents/Typography"
import ClashModal from "./ClashModal"
import QuestionAnswer from "./QuestionAnswer"
import QuestionFooter from "./QuestionFooter"
import { QuestionWrap, QuestionBody, QuestionHeader } from "./styled"
import { calculateEnabled } from "./logicEvaluator"
import Ribbons from "components/Ribbons"
import withState from "hoc/withState"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"
import { scrollToEl } from "common/scroll"
import { getDefaultAnswerSet, isClash } from "./helpers"
import useOpen from "hooks/useOpen"
import SkipConfirm from "components/SkipConfirm"
import { previewRequest } from "data/templates/api"

const Question = ({
  node,
  save,
  assessmentId,
  refetch,
  canEdit,
  assessment,
  isPreview,
  hideQuestion,
  hideBookmarks,
  answerClash,
  setAnswerClash,
  answerRequest,
  questionRequests,
  handleSuccess,
  catalogs,
  isLibrary,
  isPreassessment,
  v,
  setV,
  getValue,
  editProps,
}) => {
  const { isOpen, toggle } = useOpen()

  const questionData = {
    ...node.data,
    id: node.referencedId,
    idx: node.idx,
  }

  return (
    <SkipConfirm
      editProps={editProps}
      hide={hideQuestion}
      noPadding
      noSaveButton
      id={node.id}
      v={v}
      onChange={setV}
      resetValue={() => setV(getValue(node))}
      disabled={!R.path(["data", "isEnabled"], node)}
      onSubmit={() => answerRequest(assessmentId, node.referencedId, v)}
      onSuccess={(_, goToNext) => handleSuccess(editProps.ids.editId, goToNext)}
      onError={(_, err) => {
        if (isClash(err)) {
          setAnswerClash(err.error.response.data)
        }
      }}
      errorText={(message, err) => {
        if (isClash(err)) {
          return null
        }

        return message
      }}
    >
      {({ v, onChange, editingThis, save }) => (
        <>
          {!hideQuestion && (
            <QuestionWrap>
              <Ribbons ribbons={questionData.reportTypes} left={95} />
              <QuestionHeader
                isAnswered={questionData.isAnswered && questionData.isEnabled}
              >
                <P
                  required={questionData.isMandatory && questionData.isEnabled}
                  data-qnum={`Q${node.idx}`}
                  title={`ID: ${questionData.id}`}
                >
                  Q{node.idx}
                </P>
              </QuestionHeader>
              <QuestionBody mandatory={questionData.isEnabled}>
                <QuestionAnswer
                  data={questionData}
                  node={node}
                  value={v.answerSets}
                  values={v.answerSets}
                  attachments={v.attachments}
                  enabled={v.enabled}
                  onChange={x =>
                    onChange(prev => ({
                      ...prev,
                      answerSets: x,
                      enabled: calculateEnabled(x, node),
                    }))
                  }
                  setAttachments={x =>
                    onChange(prev => ({ ...prev, attachments: x }))
                  }
                  readOnly={!editingThis}
                  submit={() => save(true)}
                  assessment={assessment}
                  isPreview={isPreview}
                  isPreassessment={isPreassessment}
                  isLibrary={isLibrary}
                  rootEnabled={questionData.isEnabled}
                  rootOpen={isOpen}
                  catalogs={catalogs}
                />
              </QuestionBody>
              <QuestionFooter
                node={node}
                refetch={refetch}
                assessmentId={assessmentId}
                canEdit={canEdit}
                isOpen={isOpen}
                toggle={toggle}
                assessment={assessment}
                isPreview={isPreview}
                hideBookmarks={hideBookmarks}
                questionRequests={questionRequests}
                isLibrary={isLibrary}
                isPreassessment={isPreassessment}
              />
            </QuestionWrap>
          )}
          {answerClash && (
            <ClashModal
              close={() => setAnswerClash(undefined)}
              node={node}
              data={questionData}
              myValue={v}
              clash={answerClash}
              assessment={assessment}
              onSuccess={handleSuccess}
              answerRequest={answerRequest}
            />
          )}
        </>
      )}
    </SkipConfirm>
  )
}

export default R.compose(
  withHandlers({
    getValue: () => node => {
      const answerSets = getDefaultAnswerSet(
        R.path(["data", "answerSets"], node),
        node,
      )
      return {
        answerSets,
        enabled: calculateEnabled(answerSets, node),
        attachments: R.path(["data", "attachments"], node),
        timestamp: R.pathOr(0, ["data", "timestamp"], node),
      }
    },
  }),
  withState("v", "setV", p => p.getValue(p.node)),
  withState("answerClash", "setAnswerClash", undefined),
  withProps(p => {
    const getRequest = () => {
      if (p.isLibrary) return assessmentLibraryQuestionRequest.answer

      if (p.isPreview) return previewRequest.answer

      return assessmentQuestionRequest.answer
    }

    return {
      answerRequest: getRequest(),
    }
  }),
  withHandlers({
    handleSuccess: p => (currentId, goToNext) => {
      p.refetch(res => {
        const updatedQuestions = getQuestionsFromTree(buildTree(res[0]))
        const thisQuestion = updatedQuestions.find(
          x => x.referencedId === p.node.referencedId,
        )
        p.setV(p.getValue(thisQuestion))
        if (goToNext) {
          const followingQuestions = updatedQuestions
            .slice(updatedQuestions.findIndex(x => x.id === currentId) + 1)
            .filter(x => updatedQuestions.find(q => q.id === x.id))
          const nextQ = followingQuestions.find(
            x => x.data.isEnabled && p.questionFilter(x),
          )

          if (nextQ) {
            p.editProps.forceSkipTo(nextQ.id)
            scrollToEl(document.querySelector(`.q-${nextQ.referencedId}`), 150)
          }
        }
      })
    },
  }),
)(Question)

import styled, { css } from "styled-components/macro"
import * as R from "ramda"

const parseHex = x => parseInt(x, 16)

const isLightColor = x => {
  const treshold = 178

  // rgb/rgba
  if (x.startsWith("rgb")) {
    return R.pipe(
      R.slice(x.indexOf("(") + 1, x.lastIndexOf(")")),
      R.split(","),
      R.map(parseInt),
      R.all(x => x > treshold),
    )(x)
  }

  // hex
  return R.pipe(
    R.slice(1, Infinity),
    R.splitEvery(2),
    R.map(parseHex),
    R.all(x => x > treshold),
  )(x)
}

export const StatusWrap = styled.div`
  background: ${p => p.color};
  color: ${p => (isLightColor(p.color || "") ? "#000" : "#fff")};
  padding: 4px 8px;
  display: inline-block;
  vertical-align: middle;
  border-radius: 2px;
  white-space: ${p => (p.wrap ? "normal" : "nowrap")};
  line-height: 24px;
  font-size: 16px;

  svg {
    width: 16px;
    height: 16px;
    fill: #fff;
    margin-right: 3px;
    vertical-align: text-top;
  }

  ${p =>
    p.rejected &&
    css`
      color: #fff;
      background: #8a092c;
      text-decoration: line-through;
    `}
`

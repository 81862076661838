import React from "react"
import { Box } from "styledComponents/Containers"
import Template from "./Template"
import Empty from "components/Empty"
import { P } from "styledComponents/Typography"

const Templates = ({
  filtered,
  countries,
  country,
  involvedPartyId,
  value,
  onChange,
}) => {
  if (country.length === 0)
    return (
      <Box mt={20}>
        <P bold center>
          Please select countries
        </P>
        <P bold center>
          for which you want to generate assessments
        </P>
      </Box>
    )

  if (filtered.length === 0) return <Empty />

  return (
    <>
      {filtered.map(x => (
        <Template
          key={x.id}
          template={x}
          involvedPartyId={involvedPartyId}
          value={value}
          onChange={onChange}
          countries={countries}
        />
      ))}
    </>
  )
}

export default Templates

import React from "react"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { IconWrap } from "styledComponents/Icons"
import CrossIcon from "icons/appkit/fill-close"
import { AnnouncementWrap } from "./styled"

const Announcement = ({ text, onClose, closing }) => (
  <AnnouncementWrap p={20} bg="#464646" flex mb={2} closing={closing}>
    <Box center flex grow justifyContent="center">
      <P white>{text}</P>
    </Box>
    <IconWrap mr={-10} white onClick={onClose}>
      <CrossIcon />
    </IconWrap>
  </AnnouncementWrap>
)

export default Announcement

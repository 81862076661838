const numberToHex = value => {
  const hex = value.toString(16)
  return hex.length === 1 ? `0${hex}` : hex
}

const colorToHex = color => numberToHex(Math.round(color * 255))

const reduceHexValue = value => {
  if (
    value.length === 7 &&
    value[1] === value[2] &&
    value[3] === value[4] &&
    value[5] === value[6]
  ) {
    return `#${value[1]}${value[3]}${value[5]}`
  }
  return value
}

const convertToHex = (red, green, blue) =>
  reduceHexValue(`#${colorToHex(red)}${colorToHex(green)}${colorToHex(blue)}`)

const hslToRgb = (hue, saturation, lightness, convert) => {
  if (saturation === 0) {
    // achromatic
    return convert(lightness, lightness, lightness)
  }

  // formulae from https://en.wikipedia.org/wiki/HSL_and_HSV
  const huePrime = (((hue % 360) + 360) % 360) / 60
  const chroma = (1 - Math.abs(2 * lightness - 1)) * saturation
  const secondComponent = chroma * (1 - Math.abs((huePrime % 2) - 1))

  let red = 0
  let green = 0
  let blue = 0

  if (huePrime >= 0 && huePrime < 1) {
    red = chroma
    green = secondComponent
  } else if (huePrime >= 1 && huePrime < 2) {
    red = secondComponent
    green = chroma
  } else if (huePrime >= 2 && huePrime < 3) {
    green = chroma
    blue = secondComponent
  } else if (huePrime >= 3 && huePrime < 4) {
    green = secondComponent
    blue = chroma
  } else if (huePrime >= 4 && huePrime < 5) {
    red = secondComponent
    blue = chroma
  } else if (huePrime >= 5 && huePrime < 6) {
    red = chroma
    blue = secondComponent
  }

  const lightnessModification = lightness - chroma / 2
  const finalRed = red + lightnessModification
  const finalGreen = green + lightnessModification
  const finalBlue = blue + lightnessModification
  return convert(finalRed, finalGreen, finalBlue)
}

const hslToHex = value =>
  hslToRgb(value.hue, value.saturation, value.lightness, convertToHex)

const hexToRgb = color => ({
  red: parseInt(`${color[1]}${color[2]}`, 16),
  green: parseInt(`${color[3]}${color[4]}`, 16),
  blue: parseInt(`${color[5]}${color[6]}`, 16),
})

const rgbToHsl = color => {
  // make sure rgb are contained in a set of [0, 255]
  const red = color.red / 255
  const green = color.green / 255
  const blue = color.blue / 255

  const max = Math.max(red, green, blue)
  const min = Math.min(red, green, blue)
  const lightness = (max + min) / 2

  if (max === min) {
    // achromatic
    if (color.alpha !== undefined) {
      return {
        hue: 0,
        saturation: 0,
        lightness,
        alpha: color.alpha,
      }
    } else {
      return { hue: 0, saturation: 0, lightness }
    }
  }

  let hue
  const delta = max - min
  const saturation =
    lightness > 0.5 ? delta / (2 - max - min) : delta / (max + min)
  switch (max) {
    case red:
      hue = (green - blue) / delta + (green < blue ? 6 : 0)
      break
    case green:
      hue = (blue - red) / delta + 2
      break
    default:
      // blue case
      hue = (red - green) / delta + 4
      break
  }

  hue *= 60
  if (color.alpha !== undefined) {
    return {
      hue,
      saturation,
      lightness,
      alpha: color.alpha,
    }
  }
  return { hue, saturation, lightness }
}

export const darken = (amount, color) => {
  const hslColor = rgbToHsl(hexToRgb(color))
  return hslToHex({
    ...hslColor,
    lightness: Math.max(
      0,
      Math.min(1, hslColor.lightness - parseFloat(amount)),
    ),
  })
}

import { Subject } from "rxjs"
import { execute } from "common/rxjs"
import { store, retrieve, purge } from "common/storage"
import { getUid } from "common/ids"
import { authRequest } from "data/auth/api"
import * as R from "ramda"

export const auth$ = new Subject()

export const getClientId = () =>
  retrieve("clientId") || store("clientId")(getUid())

export const login = data => {
  R.keys(data).forEach(k => store(k)(data[k]))

  auth$.next(data)
}

export const logout = () => {
  const clientId = getClientId()
  const accessToken = retrieve("accessToken")
  const refreshToken = retrieve("refreshToken")

  // invalidate all current id and otkens
  if (accessToken) {
    execute(authRequest.logout(clientId, accessToken, refreshToken))
    purge()
    auth$.next({})
  }
}

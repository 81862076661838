import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 32 32">
    <path
      d="M16,32 L16,1 L28,12.8748998 L25.197225,15.6481505 L19.9643211,10.4703699 L19.9643211,32 L16,32 Z M7.7998521e-14,19.1272144 L2.80246642,16.3538468 L8.0347941,21.5305382 L8.0347941,1.77635684e-15 L12,1.77635684e-15 L12,31 L7.7998521e-14,19.1272144 Z"
      transform="translate(-380.000000, -2613.000000) translate(382.000000, 2613.000000)"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M22.5,6 L18,6 L18,1.5 C18,0.672 17.328,0 16.5,0 L0,0 L0,16.5 C0,17.328 0.672,18 1.5,18 L6,18 L6,15 L3,15 L3,3 L15,3 L15,6 L6,6 L6,15 L9,15 L9,12.75 L11.25,15 L12.75,16.5 L11.25,18 L9,20.25 L9,18 L6,18 L6,22.5 C6,23.328 6.672,24 7.5,24 L22.5,24 C23.328,24 24,23.328 24,22.5 L24,7.5 C24,6.672 23.328,6 22.5,6"
      fillRule="evenodd"
    ></path>
  </svg>
)

import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 32 32">
    <path
      d="M24,2 C25.104,2 26,2.896 26,4 L26,30 C26,31.104 25.104,32 24,32 L2,32 C0.896,32 0,31.104 0,30 L0,4 C0,2.896 0.896,2 2,2 L5,2 L5,4 L5,8 L7,8 L19,8 L21,8 L21,4 L21,2 L24,2 Z M4,24 L22,24 L22,20 L4,20 L4,24 Z M4,16 L22,16 L22,12 L4,12 L4,16 Z M9,0 L17,0 L17,2 L17,4 L9,4 L9,2 L9,0 Z"
      transform="translate(-695.000000, -2615.000000) translate(698.000000, 2615.000000)"
      fillRule="evenodd"
    ></path>
  </svg>
)

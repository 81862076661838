import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M9.27156821,12 L0,2.72843179 L2.72843179,0 L12,9.27156821 L21.2715682,0 L24,2.72843179 L14.7284318,12 L23.9994,21.2709682 L21.2709682,23.9994 L12,14.7284318 L2.72903177,23.9994 L0.000599985,21.2709682 L9.27156821,12 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

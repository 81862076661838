import React from "react"
import Button from "components/Button"
import Columns from "components/Columns"
import ModalButtons from "components/ModalButtons"
import Panel from "components/Panel"
import { Box } from "styledComponents/Containers"
import {
  assessmentCoworkersRequest,
  assessmentRequest,
} from "data/assessments/api"
import { formatInitials, formatUserName } from "data/user/formatters"
import withForm from "hoc/withForm"
import AutocompleteList from "inputs/AutocompleteList"
import Datepicker from "inputs/Datepicker"
import InputNotify from "inputs/InputNotify"
import Textfield from "inputs/Textfield"
import Checkbox from "inputs/Checkbox"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import { arrayExactLength } from "validators/array"
import { positiveIntegerOptional } from "validators/number"
import { stringRequired } from "validators/string"
import ArrangementId from "components/ArrangementId"
import LabelValue from "components/LabelValue"
import { localize } from "locale"
import useMedia from "hooks/useMedia"
import { preassessmentRequest } from "data/preassessments/api"
import Textarea from "inputs/Textarea"
import Filepicker from "inputs/Filepicker"

const contactToOption = u => ({
  ...u,
  value: u.id,
  name: u.email,
})

const getDownloadRequest = (isPreassessment, assessment) => {
  if (!assessment) return undefined

  if (isPreassessment) {
    return preassessmentRequest.downloadCaseDescriptionFile(assessment.id)
  }

  return assessmentRequest.downloadCaseDescriptionFile(assessment.id)
}

const getUploadRequest = assessment => {
  if (!assessment) {
    return assessmentRequest.uploadCaseDescriptionFileNew
  }

  return assessmentRequest.uploadCaseDescriptionFile(assessment.id)
}

const SettingsForm = ({
  data,
  canEdit,
  urls,
  caseUrls,
  values,
  case_,
  isPreassessment,
}) => {
  const { isMobile } = useMedia()
  const identifiers = R.pathOr([], ["internalReportIdentifiers"], data)

  return (
    <Panel mb={20}>
      <Textfield
        name="name"
        label={localize("nameOfAssessment")}
        placeholder={localize("enterName")}
        required={canEdit}
        readOnly={!canEdit}
        borderless={!canEdit}
        noEmptyError
      />
      <Textarea
        name="caseDescription"
        label="Case description"
        placeholder="Enter short description of the case"
        readOnly={!canEdit || data?.isLinkedToCase}
        borderless={!canEdit || data?.isLinkedToCase}
        noEmptyError
      />
      <Filepicker
        name="questionnaireCaseAttachments"
        upload={getUploadRequest(data)}
        download={getDownloadRequest(isPreassessment, data)}
        readOnly={!canEdit || data?.isLinkedToCase}
      />
      <Box flex>
        {data && (
          <Box mb={20}>
            <ArrangementId
              withLabel
              arrangementId={data.arrangementId}
              caseId={case_?.id}
              isPreassessment={isPreassessment}
              getLink={
                isPreassessment
                  ? () => `${caseUrls.detail.url(data.id)}/settings`
                  : undefined
              }
            />
          </Box>
        )}
        {!isPreassessment && (
          <Checkbox
            name="marketableArrangement"
            label={localize("marketableArrangement")}
            readOnly={!canEdit}
            noEmptyError
            ml={data ? 30 : 0}
          />
        )}
        {data &&
          identifiers.map((x, xi) => (
            <LabelValue ml={30} key={xi} label={x.text} value={x.value} />
          ))}
      </Box>
      <Columns noCols={isMobile} gap={isMobile ? 0 : 15}>
        <Datepicker
          name="dueDate"
          label={localize("estimatedDueDateAssessment")}
          placeholder={localize("selectDate")}
          required={canEdit}
          readOnly={!canEdit}
          borderless={!canEdit}
        />
        <InputNotify
          label="&nbsp;"
          name="notificationDays"
          readOnly={!canEdit || !values.dueDate}
          borderless={!canEdit}
        />
      </Columns>
      <AutocompleteList
        required={canEdit}
        name="contacts"
        label={localize("addContactPerson")}
        sublabel={localize("onlyOnePersonCanBeAdded")}
        request={assessmentCoworkersRequest.list}
        mapOptions={R.map(contactToOption)}
        title={localize("addedUsers")}
        optionToAvatar={formatInitials}
        optionToTitle={formatUserName}
        optionToSubtitle={R.prop("email")}
        readOnly={!canEdit}
      />
      {canEdit && (
        <ModalButtons>
          <Button
            secondary
            type="button"
            to={
              caseUrls
                ? `${caseUrls.list.url}?involvedPartyId=${R.path(
                    ["breadcrumbs", "case", "involvedParties", 0, "id"],
                    data,
                  )}`
                : urls.assessments.local
            }
            text="Cancel"
          />
          <Button type="submit" text="Save" />
        </ModalButtons>
      )}
    </Panel>
  )
}

export default R.compose(
  withUrls,
  withForm({
    preventLoseProgress: true,
    mapProps: p => {
      const contact = p.data && p.data.contactPerson
      // the input is working with arrays
      const contacts = contact ? [contactToOption(contact)] : []
      return {
        ...p.data,
        contacts,
      }
    },
    schema: R.always({
      notificationDays: positiveIntegerOptional,
      name: stringRequired,
      dueDate: stringRequired,
      contacts: arrayExactLength(1)(localize("contact")),
    }),
    onSubmit: p => v => {
      const data = {
        templateId: p.templateId,
        name: v.name,
        caseDescription: v.caseDescription,
        questionnaireCaseAttachments: v.questionnaireCaseAttachments,
        dueDate: v.dueDate,
        marketableArrangement: !!v.marketableArrangement,
        notificationDays: +v.notificationDays,
        contactPersonId: v.contacts[0].value,
      }

      const req = p.isPreassessment ? preassessmentRequest : assessmentRequest

      return p.data ? req.edit(p.assessmentId, data) : req.create(data)
    },
    redirect: (res, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return `${R.head(p.match.url.split("/settings"))}/approvers`
      }
      return p.suburls.coworkers.path.replace(":id", res.id)
    },
  }),
)(SettingsForm)

import { localize } from "locale"
import * as R from "ramda"
import Conclusion from "./Conclusion"
import Coworkers from "./Coworkers"
import PublishShare from "./PublishShare"
import Questions from "./Questions"
import Tagging from "./Tagging"
import SettingsForm from "./SettingsForm"
import Validation from "./Validation"
import Testing from "./Testing"
import Mapping from "./Mapping"
import { statuses } from "data/templates/statuses"

const exclude = (status, template) => {
  return [
    ...(!status || status === statuses.inProcess.value ? [] : ["validation"]),
    ...(!status ||
    status === statuses.inProcess.value ||
    status === statuses.requestForApproval.value
      ? []
      : ["testing"]),
    ...(status === statuses.approved.value ||
    status === statuses.published.value
      ? []
      : ["publish"]),
    ...(template?.isPreAssessment ? ["mapping"] : []),
  ]
}

export const templateSuburls = {
  settings: "settings",
  coworkers: "approvers",
  questions: "questions",
  questionTagging: "tagging",
  conclusion: "conclusion",
  mapping: "mapping",
  validation: "validation",
  publish: "publish",
  testing: "testing",
}

export const getSuburls = (match, template, publishSettings) => {
  const path = match.path
  const url = match.url
  const status = template && template.status

  const all = {
    settings: {
      name: localize("templateSettings"),
      path: path + "/settings",
      url: url + "/settings",
      component: SettingsForm,
      disabled: false,
    },
    coworkers: {
      name: localize("approvers"),
      path: path + "/approvers",
      url: url + "/approvers",
      component: Coworkers,
      disabled: !status,
    },
    questions: {
      name: localize("questions"),
      path: path + "/questions",
      url: url + "/questions",
      component: Questions,
      disabled: !status,
    },
    questionTagging: {
      name: "Question tagging",
      path: path + "/tagging",
      url: url + "/tagging",
      component: Tagging,
      disabled: !status,
    },
    conclusion: {
      name: localize("conclusion"),
      path: path + "/conclusion",
      url: url + "/conclusion",
      component: Conclusion,
      disabled: !status,
    },
    mapping: {
      name: localize("mapping"),
      path: path + "/mapping",
      url: url + "/mapping",
      component: Mapping,
      disabled: !status,
    },
    validation: {
      name: localize("validation"),
      path: path + "/validation",
      url: url + "/validation",
      component: Validation,
      disabled: !status,
    },
    publish: {
      name: `Publish${
        R.path(["isShareHidden"], publishSettings) ? "" : " & Share"
      }`,
      path: path + "/publish",
      url: url + "/publish",
      component: PublishShare,
      disabled: !status,
    },
    testing: {
      name: localize("testing"),
      path: path + "/testing",
      url: url + "/testing",
      component: Testing,
      disabled: !status,
    },
  }

  return R.omit(exclude(status, template), all)
}

import React from "react"
import { AppContext } from "data/auth/context"

const withProfile = C => p => (
  <AppContext.Consumer>
    {v => (
      <C {...p} modules={v.modules} profile={v.profile} tenants={v.tenants} />
    )}
  </AppContext.Consumer>
)

export default withProfile

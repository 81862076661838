import React from "react"
import Link from "components/Link"
import Warning from "components/Warning"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"

const Coworkers = ({ approvers = [], close, suburls, isPreassessment }) => {
  if (approvers.length < 2) return null
  return (
    <Box>
      {approvers.length > 2 && (
        <Warning text={localize("moreThanTwoApprovers")} />
      )}
      {approvers.length >= 2 && (
        <>
          <P mb={10}>
            The {isPreassessment ? "pre-" : ""}assessment will be sent for
            approval to the below approvers:
          </P>
          {approvers.map((x, xi) => (
            <P mb={5} key={xi}>
              {x.name}
            </P>
          ))}
          <Box mt={20}>
            <Link ul to={suburls.coworkers.url} onClick={close}>
              {localize("modifyApprovers")}
            </Link>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Coworkers

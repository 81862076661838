import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import {
  assessmentCoworkersRequest,
  assessmentRequest,
} from "data/assessments/api"
import { assessmentRoles } from "data/assessments/roles"
import { tenantRoles } from "data/tenant/roles"
import { parallel } from "common/rxjs"
import withData from "hoc/withData"
import withModal from "hoc/withModal"
import withSubmit from "hoc/withSubmit"
import { localize } from "locale"
import ValidationErrors from "pages/AssessmentsCreatePage/ValidationErrors"
import withUrls from "hoc/withUrls"
import { withRouter } from "react-router"
import { P } from "styledComponents/Typography"
import Coworkers from "./Coworkers"
import { reportingRequest } from "data/reporting/api"
import * as R from "ramda"
import {
  preassessmentCoworkersRequest,
  preassessmentRequest,
} from "data/preassessments/api"
import { getCaseUrl } from "data/cockpit/redirect"

const SubmitModal = ({
  close,
  submit,
  assessmentId,
  check,
  suburls,
  coworkersData,
  isPreassessment,
}) => {
  const { coworkers, reviewers } = coworkersData

  const coworkerApprovers = coworkers.filter(
    worker => worker.permission === assessmentRoles[0].value,
  )

  const reviewersApprovers = (reviewers || []).filter(
    x => x.permission === tenantRoles.approverSelected.value,
  )

  return (
    <>
      {check.isValid && (
        <Coworkers
          isPreassessment={isPreassessment}
          approvers={coworkerApprovers.concat(reviewersApprovers)}
          close={close}
          suburls={suburls}
        />
      )}
      <ValidationErrors
        {...check}
        compact
        assessmentId={assessmentId}
        isPreassessment={isPreassessment}
      />
      {check.isValid && (
        <P mt={20} bold>
          Do you want to submit the {isPreassessment ? "pre-" : ""}assessment?
        </P>
      )}
      <ModalButtons>
        <Button secondary onClick={close} text="Cancel" />
        {check.isValid ? (
          <Button onClick={submit} text={localize("yesSubmit")} />
        ) : (
          <>
            {check.containsApproverErrors && (
              <Button
                onClick={close}
                to={suburls.coworkers.url}
                text={localize("modifyApprovers")}
              />
            )}
            {check.containsAssessmentErrors && (
              <Button
                onClick={close}
                to={suburls.decisions.url}
                text={localize("checkValidations")}
              />
            )}
            {check.containsReportingErrors && (
              <Button
                onClick={close}
                to={suburls.preview.url}
                text={localize("checkReportingValidations")}
              />
            )}
          </>
        )}
      </ModalButtons>
    </>
  )
}

export default R.compose(
  withRouter,
  withData(
    p =>
      parallel(
        (p.isPreassessment
          ? preassessmentRequest.verify
          : reportingRequest.verify)(p.assessmentId),
        (p.isPreassessment
          ? preassessmentCoworkersRequest
          : assessmentCoworkersRequest
        ).coworkers(p.assessmentId),
      ),
    { dataKeys: ["check", "coworkersData"] },
  ),
  withModal(
    p =>
      p.check.isValid
        ? `Submit ${p.isPreassessment ? "pre-" : ""}assessment: ${
            p.assessment.name
          }`
        : `${localize("youCannotSubmit")}: ${p.assessment.name}`,
    { width: 840 },
  ),
  withUrls,
  withSubmit({
    onSubmit: p => () =>
      (p.isPreassessment ? preassessmentRequest : assessmentRequest).submit(
        p.assessmentId,
      ),
    onSuccess: p => () => {
      p.close()
    },
    successText: p => () =>
      `${p.isPreassessment ? "Pre-assessment" : "Assessment"} ${
        p.assessment.name
      } was submitted successfully.`,
    redirect: (_, p) => {
      if (p.match.path.startsWith(p.routes.caseCreate.path)) {
        return getCaseUrl(p.match, p.assessment)
      }
      return p.urls.assessments.local
    },
  }),
)(SubmitModal)

import React from "react"
import Panel from "components/Panel"
import Comments from "components/Comments"
import { P } from "styledComponents/Typography"
import { localize } from "locale"
import {
  approverCommentRequests,
  reviewerCommentRequests,
} from "data/siteConfiguration/api"
import { preassessmentApproverCommentRequests } from "data/preassessments/api"

const Review = ({
  assessmentId,
  canEditReviewers,
  canEditApprovers,
  template,
  isPreassessment,
}) => {
  const [openId, setOpenId] = React.useState()

  return (
    <>
      <Panel mb={20}>
        <P mb={15}>{localize("approversSection")}</P>
        <Comments
          addCommentId="approver"
          setOpenId={setOpenId}
          openId={openId}
          requests={(isPreassessment
            ? preassessmentApproverCommentRequests
            : approverCommentRequests)(assessmentId)}
          readOnly={!canEditApprovers}
          long
        />
      </Panel>
      {!template.isPreAssessment && (
        <Panel>
          <P mb={15}>{localize("reviewersSection")}</P>
          <Comments
            addCommentId="reviewer"
            setOpenId={setOpenId}
            openId={openId}
            requests={reviewerCommentRequests(assessmentId)}
            sublabel={localize("pleaseSpecifyNameOfReviewer")}
            readOnly={!canEditReviewers}
            long
          />
        </Panel>
      )}
    </>
  )
}

export default Review

import React from "react"
import Columns from "components/Columns"
import { localize } from "locale"
import { reportingRequest } from "data/reporting/api"
import { reportingLibraryRequest } from "data/reporting/api"
import Textfield from "inputs/Textfield"
import Filepicker from "inputs/Filepicker"

const DoNotReportFields = ({ canEdit, assessmentId, isLibrary }) => (
  <>
    <Columns widths={["auto", 150]} align="flex-end">
      <Textfield
        name="terminateReason"
        label={localize("reasonForNotToReport")}
        placeholder={localize("enterReason")}
        readOnly={!canEdit}
        required
      />
      <Filepicker
        name="attachments"
        upload={
          isLibrary
            ? reportingLibraryRequest.uploadFile(assessmentId)
            : reportingRequest.uploadFile(assessmentId)
        }
        readOnly={!canEdit}
        onlyUpload
      />
    </Columns>
    <Filepicker
      name="attachments"
      download={
        isLibrary
          ? reportingLibraryRequest.downloadFile(assessmentId)
          : reportingRequest.downloadFile(assessmentId)
      }
      onlyUploaded
      readOnly={!canEdit}
    />
  </>
)

export default DoNotReportFields

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { groupRequest, questionRequest } from "data/templates/api"
import withModal from "hoc/withModal"
import withSubmit from "hoc/withSubmit"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import * as R from "ramda"

const ConfirmDeleteModal = ({ close, type, submit, data }) => {
  const nonEmptySection = type !== "question" && data.children.length > 0
  return (
    <>
      {nonEmptySection ? (
        <P mb={20}>{localize("mustBeEmpty-" + type)}</P>
      ) : (
        <>
          {type !== "question" && (
            <P mb={20}>{localize("confirmDelete-" + type)}</P>
          )}
          <P bold mb={20}>
            {localize("doYouWantToDeleteThis")} {localize(type)}?
          </P>
          <ModalButtons>
            <Button secondary type="button" onClick={close} text="No" />
            <Button danger onClick={submit} text={localize("yesDelete")} />
          </ModalButtons>
        </>
      )}
    </>
  )
}

export default R.compose(
  withModal(p =>
    p.type === "question"
      ? `${localize("delete")} ${localize(p.type)} ${p.data.idx}`
      : `${localize("delete")} ${localize(p.type)}: "${p.data.data.name}"`,
  ),
  withSubmit({
    onSubmit: p => () =>
      p.type === "question"
        ? questionRequest.del(p.templateId, p.data.referencedId)
        : groupRequest.del(p.templateId, p.data.referencedId),
    onSuccess: p => () => {
      p.close()
      p.onSuccess()
    },
  }),
)(ConfirmDeleteModal)

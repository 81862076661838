import React from "react"
import CaretIcon from "icons/Caret"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"
import * as R from "ramda"
import InputLogic from "inputs/InputLogic"

const Precondition = ({ question, questions, values }) => {
  const { isOpen, toggle } = useOpen(false)
  const hasPrecondition = R.path(["precondition"], values)

  if (!hasPrecondition) return <P mb={10}>{localize("noPrecondition")}</P>
  return (
    <>
      <Box mb={isOpen ? 20 : 0} onClick={toggle} flex>
        <P red link>
          {localize("precondition")}
        </P>
        <IconWrap static rotate={+isOpen}>
          <CaretIcon />
        </IconWrap>
      </Box>
      {isOpen && (
        <InputLogic
          conditionText={localize("showQuestionIf")}
          value={values.precondition}
          readOnly
          previousQuestions={questions.slice(0, question.idx - 1)}
          noEmptyError
        />
      )}
    </>
  )
}

export default Precondition

import React from "react"
import { localize } from "locale"
import Link from "components/Link"
import { formatDate } from "data/units/date/formatters"
import * as R from "ramda"
import { IconWrap } from "styledComponents/Icons"
import CrossIcon from "icons/Cross"

export const config = (urls, openDelete, canEdit) => ({
  columns: [
    {
      label: localize("createdOn"),
      sortBy: R.prop("createdAtUtc"),
      path: R.pipe(R.prop("createdAtUtc"), formatDate),
    },
    {
      label: localize("createdBy"),
      path: R.prop("createdBy"),
      sortBy: R.prop("createdBy"),
    },
    {
      label: localize("testFileName"),
      path: x => (
        <Link ul to={urls.testFile(x.id)}>
          {x.name}
        </Link>
      ),
      sortBy: R.prop("name"),
    },
    ...(canEdit
      ? [
          {
            path: x => (
              <IconWrap onClick={() => openDelete(x)}>
                <CrossIcon />
              </IconWrap>
            ),
          },
        ]
      : []),
  ],
})

import React from "react"
import InfoModal from "components/InfoModal"
import Button from "components/Button"
import { localize } from "locale"
import ModalButtons from "components/ModalButtons"
import useOpen from "hooks/useOpen"

const WarningConfirmModal = ({
  children,
  onConfirm,
  text,
  doConfirm,
  confirmText = localize("ok"),
  cancelText = localize("cancel"),
  title = localize("warning"),
  buttonText,
  dangerConfirm,
  body = () => null,
}) => {
  const { isOpen, open, close } = useOpen()

  return (
    <>
      {isOpen && (
        <InfoModal
          title={title}
          text={text}
          body={
            <>
              {body()}
              <ModalButtons buttonText={buttonText}>
                <Button secondary text={cancelText} onClick={close} />
                <Button
                  danger={dangerConfirm}
                  text={confirmText}
                  onClick={() => {
                    onConfirm()
                    close()
                  }}
                />
              </ModalButtons>
            </>
          }
          close={close}
        />
      )}
      {children(() => {
        // calculation will be expensive, must be lazy
        if (doConfirm()) {
          return open()
        }

        return onConfirm()
      })}
    </>
  )
}

export default WarningConfirmModal

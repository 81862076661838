import * as R from "ramda"
import { arrEmpty } from "common/array"

export const wrongNodeType = mappingElement => node => {
  // section
  if (node.nodeType === "section") {
    return arrEmpty(node.children)
  }

  // question
  if (node.nodeType === "question") {
    const t = R.path(["data", "questionType"], node)
    if (t === "Custom") return arrEmpty(node.data.subQuestions)

    return mappingElement
      ? t === "Single" || t === "Multiple" || t === "Confirmation"
      : t !== "Single" && t !== "Multiple" && t !== "Confirmation"
  }

  // subquestion
  const t = R.path(["questionType"], node)
  if (t === "Custom") return arrEmpty(node.subQuestions)

  return mappingElement
    ? t === "Single" || t === "Multiple" || t === "Confirmation"
    : t !== "Single" && t !== "Multiple" && t !== "Confirmation"
}

export const doesntMeetSearch = search => node => {
  if (!search) return false

  // section
  if (node.nodeType === "section") {
    return arrEmpty(node.children)
  }

  const r = new RegExp(search, "i")
  // question
  if (node.nodeType === "question") {
    return (
      arrEmpty(node.data.subQuestions) &&
      !r.test(node.data.questionText) &&
      (node.data.options === 0 || !node.data.options.some(y => r.test(y.text)))
    )
  }

  // subquestion
  return (
    arrEmpty(node.subQuestions) &&
    !r.test(node.questionText) &&
    (node.options.length === 0 || !node.options.some(y => r.test(y.text)))
  )
}

export const getChildrenLen = node => {
  // section
  if (node.nodeType === "section") {
    return R.lensPath(["children"])
  }

  // question
  if (node.nodeType === "question") {
    return R.lensPath(["data", "subQuestions"])
  }

  return R.lensPath(["subQuestions"])
}

import React from "react"
import Table from "components/Table"
import { config } from "./config"
import { preassessmentRequest } from "data/preassessments/api"
import ConfirmModal from "components/ConfirmModal"
import RestoreModalButton from "./RestoreModalButton"

const PreassessmentTable = ({
  data,
  case_,
  suburls,
  isDeletedTab,
  refetch,
  refetchCase,
}) => {
  const [detail, setDetail] = React.useState()

  const rowOptions = [
    {
      onClick: v => setDetail({ ...v, action: "restore" }),
      name: "Restore",
    },
    {
      onClick: v => setDetail({ ...v, action: "deletePermanent" }),
      name: "Delete permanently",
    },
  ]

  return (
    <>
      <Table
        config={config(suburls, isDeletedTab, rowOptions, case_.id)}
        data={data}
        sorting={{
          index: isDeletedTab ? 5 : 6,
          desc: true,
        }}
      />
      {detail && detail.action === "restore" && (
        <RestoreModalButton
          detail={detail}
          setDetail={setDetail}
          case_={case_}
          onSuccess={() => {
            refetch()
            refetchCase()
          }}
        />
      )}
      {detail && detail.action === "deletePermanent" && (
        <ConfirmModal
          close={() => setDetail()}
          onSuccess={() => {
            refetch()
            refetchCase()
          }}
          modal={ConfirmModal}
          title="Permanently delete pre-assessment"
          confirmText={`Pre-assessment ${detail.name} will be permanently deleted and cannot be restored.`}
          confirmQuestion="Do you want to permanently delete the pre-assessment?"
          cancelText="No"
          submitText="Yes"
          successText={() =>
            `You have permanently deleted the pre-assessment ${detail.name}`
          }
          request={() =>
            preassessmentRequest.deletePermanent({
              assessmentIds: [detail.id],
            })
          }
          submitDanger
        />
      )}
    </>
  )
}

export default PreassessmentTable

import React from "react"
import withFormField from "hoc/withFormField"
import Checkbox from "inputs/Checkbox"
import { OptionWrapper } from "./styled"
import { Box } from "styledComponents/Containers"
import { handleUnique } from "./helpers"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"
import * as R from "ramda"

const Combobox = ({
  value,
  handleToggle,
  options,
  flip,
  readOnly,
  squared,
  maxHeight,
  inline,
}) => (
  <OptionWrapper maxHeight={maxHeight} inline={inline}>
    {options.map((o, oi) => (
      <Box key={o.value} ml={inline && oi !== 0 ? 20 : 0}>
        <Checkbox
          value={value.find(x => x === o.value)}
          onChange={() => handleToggle(o.value)}
          text={o.name}
          flip={flip}
          readOnly={readOnly}
          squared={squared}
        />
      </Box>
    ))}
  </OptionWrapper>
)

export default R.compose(
  withFormField,
  withProps(p => ({
    value: p.value || [],
  })),
  withHandlers({
    handleToggle: p => v => {
      const getNewVal = () => {
        if (p.value.find(x => x === v)) {
          return p.value.filter(x => x !== v)
        }

        return p.value.concat(v)
      }

      return p.onChange(handleUnique(getNewVal(), v, p.options))
    },
  }),
)(Combobox)

import React from "react"
import Panel from "components/Panel"
import { assessmentRequest } from "data/assessments/api"
import withData from "hoc/withData"
import withOnMount from "hoc/withOnMount"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import ValidationErrors from "../ValidationErrors"
import ConclusionPreview from "./ConclusionPreview"
import Decisions from "./Decisions"
import { preassessmentRequest } from "data/preassessments/api"
import { previewRequest } from "data/templates/api"
import { parallel } from "common/rxjs"

const ReportingDecisions = ({
  verify,
  conclusion,
  assessmentId,
  isPreview,
  reporting,
  refetch,
  refetchReporting,
  reportingFetchCount,
  suburls,
  canEdit,
  template,
}) => {
  const isPreassessment = template.isPreAssessment
  const count = verify.messages.length + R.keys(verify.questionMessages).length
  return (
    <>
      <Panel mb={20}>
        {verify.isValid ? (
          <P>
            {isPreview
              ? "Everything is correct, you may check for conclusion preview now."
              : `Everything is correct, you can submit the ${
                  isPreassessment ? "pre-" : ""
                }assessment now.`}
          </P>
        ) : (
          <P mb={20}>
            There are {count} errors which have to be solved before you can
            submit the {template.isPreAssessment ? "pre-" : ""}assessment.
          </P>
        )}
        <ValidationErrors
          {...verify}
          assessmentId={assessmentId}
          isPreassessment={template.isPreAssessment}
          isPreview={isPreview}
        />
      </Panel>
      <ConclusionPreview
        assessmentId={assessmentId}
        isValid={verify.isValid}
        template={template}
        isPreassessment={template.isPreAssessment}
        isPreview={isPreview}
        conclusion={conclusion}
      />
      {verify.isValid && (
        <Decisions
          canEdit={canEdit}
          assessmentId={assessmentId}
          reporting={reporting}
          refetch={refetch}
          refetchReporting={refetchReporting}
          suburls={suburls}
          isPreview={isPreview}
          reportingFetchCount={reportingFetchCount}
          template={template}
          conclusion={conclusion}
          isPreassessment={template.isPreAssessment}
        />
      )}
    </>
  )
}

export default R.compose(
  withOnMount(p => {
    if (!p.template.isPreAssessment) {
      p.refetchReporting()
    }
  }),
  withData(
    p => {
      const getVerifyRequest = () => {
        if (p.isPreview) return previewRequest.verify
        if (p.template.isPreAssessment) return preassessmentRequest.verify

        return assessmentRequest.verify
      }

      const getConclusionRequest = () => {
        if (p.isPreview) {
          return previewRequest.conclusions
        }
        if (p.template.isPreAssessment) {
          return preassessmentRequest.conclusion
        }

        return assessmentRequest.conclusion
      }

      return parallel(
        getVerifyRequest()(p.assessmentId),
        getConclusionRequest()(p.assessmentId),
      )
    },
    { dataKeys: ["verify", "conclusion"] },
  ),
)(ReportingDecisions)

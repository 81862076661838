import React from "react"
import Button from "components/Button"
import { conclusionRequest } from "data/templates/api"
import withSubmit from "hoc/withSubmit"
import PlusIcon from "icons/Plus"
import { localize } from "locale"
import * as R from "ramda"
import Section from "../Section"
import ReasoningForm from "./ReasoningForm"
import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"

const Reasonings = ({
  reasonings,
  addingNew,
  addReasoning,
  handleSuccess,
  handleCancel,
  canEdit,
  submit,
  ...rest
}) => {
  return (
    <Section title={localize("reasonings")}>
      {reasonings.concat(addingNew ? [{ id: "new" }] : []).map((x, xi, arr) => (
        <React.Fragment key={x.id}>
          {rest.moveIndex &&
            rest.moveIndex.reasoningId !== x.id &&
            rest.moveIndex.reasoningId !== R.path([xi - 1, "id"], arr) && (
              <Button
                text={localize("moveReasoningHere")}
                mt={xi === 0 ? 0 : 20}
                mb={xi === 0 ? 20 : 0}
                onClick={() => submit(xi)}
              />
            )}
          <ReasoningForm
            {...rest}
            reasoning={x}
            onSuccess={handleSuccess}
            onCancelEdit={handleCancel}
            first={xi === 0}
            count={arr.length}
            canEdit={canEdit}
            index={xi}
          />
        </React.Fragment>
      ))}
      {canEdit && (
        <>
          {rest.moveIndex &&
            rest.moveIndex.reasoningId !== R.last(reasonings).id && (
              <Button
                text={localize("moveReasoningHere")}
                mt={20}
                onClick={() => submit(reasonings.length)}
                mr={10}
              />
            )}
          {!rest.moveIndex && (
            <Button
              icon={PlusIcon}
              text={localize("reasoning")}
              onClick={addReasoning}
              mt={reasonings.length > 0 || addingNew ? 20 : 0}
              disabled={!!(addingNew || rest.editIndex)}
            />
          )}
        </>
      )}
    </Section>
  )
}

export default R.compose(
  withState("addingNew", "setAddingNew", false),
  withSubmit({
    onSubmit: p => position => {
      const moveToIndex =
        p.moveIndex.position > position ? position + 1 : position
      return conclusionRequest.moveReasoning(
        p.templateId,
        p.moveIndex.reasoningId,
        moveToIndex,
      )
    },
    onSuccess: p => p.onSuccess,
  }),
  withHandlers({
    addReasoning: p => () => {
      p.setAddingNew(true)
      p.onEdit({ reasoningId: "new" })
    },
    handleSuccess: p => () => {
      p.onSuccess()
      p.setAddingNew(false)
    },
    handleCancel: p => () => {
      p.onCancelEdit()
      p.setAddingNew(false)
    },
  }),
)(Reasonings)

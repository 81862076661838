import React from "react"
import withModal from "hoc/withModal"
import withData from "hoc/withData"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import LabelValue from "components/LabelValue"
import Select from "inputs/Select"
import { answerTypes } from "data/templates/answers"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { assessmentTagRequest } from "data/registry/api"
import {
  getOptions,
  handleChange,
  getTagsFromQuestion,
  autofillGroup,
} from "./helpers"
import OverrideConfirmModal from "./OverrideConfirmModal"
import { getQuestionsWithValue } from "data/autofill/helpers"
import useOpen from "hooks/useOpen"

const MultipleValuesModal = ({
  values,
  question,
  close,
  catalogs,
  onChange,
  data,
  subject,
  rootQuestion,
  nonCustomRoot,
  onChangeFull,
  indices,
  inputValue,
  enabled,
  closeModal,
  clear,
  closeClear,
}) => {
  const {
    isOpen: isOpenOverride,
    close: closeOverride,
    open: openOverride,
  } = useOpen()

  const allCatalog = R.values(catalogs).flatMap(R.identity)
  const tags = getTagsFromQuestion(question)
  const catalogItems = R.values(tags)
    .filter(x => x)
    .map(x => allCatalog.find(y => y.id === x.registryCatalogId))
  const isMulti = catalogItems.some(x => x.allowsMultiple)

  const [multipleValuesIndices, setMultipleValuesIndices] = React.useState({
    first: 0,
    FirstName: 0,
    MiddleName: 0,
    LastName: 0,
  })
  const fillGroup = initValuess =>
    autofillGroup({
      question,
      values: initValuess || values,
      onChangeFull,
      data,
      multipleValuesIndices,
      subject,
      indices,
      rootQuestion,
      allCatalog,
    })

  const fillSimple = () =>
    onChange(
      ...handleChange(question, data, multipleValuesIndices, isMulti)(subject),
    )

  const fillAndClose = () => {
    if (nonCustomRoot) {
      fillSimple()
    }
    if (!nonCustomRoot) {
      const result = clear()

      fillGroup(result)
    }

    closeModal()
  }

  React.useEffect(() => {
    if (nonCustomRoot) {
      const containsValue = !R.isNil(inputValue) && !R.isEmpty(inputValue)
      if (containsValue) {
        closeClear()
        openOverride()
      }
      if (!containsValue && !isMulti) {
        fillAndClose()
      }
    }

    if (!nonCustomRoot) {
      const containsValues =
        getQuestionsWithValue({
          question,
          values,
          indices,
          rootQuestion,
          enabled,
        }).length > 0

      if (containsValues) {
        closeClear()
        openOverride()
      }
      if (!containsValues) {
        fillAndClose()
      }
    }
  }, []) // eslint-disable-line

  if (isOpenOverride) {
    return (
      <OverrideConfirmModal
        close={() => {
          closeOverride()
          close()
        }}
        submit={() => {
          if (!isMulti || !nonCustomRoot) {
            fillAndClose()
          }
          closeOverride()
        }}
        question={question}
        nonCustomRoot={nonCustomRoot}
        questionsWithValue={
          nonCustomRoot
            ? []
            : getQuestionsWithValue({
                question,
                values,
                indices,
                rootQuestion,
                enabled,
              })
        }
      />
    )
  }

  if (!isMulti) {
    return null
  }

  const isName = question.questionType === answerTypes.name.value

  return (
    <Box>
      <LabelValue label="Subject" value={subject.name} mb={20} />
      {isName ? (
        <>
          {tags.FirstName && (
            <Select
              label="First name"
              options={getOptions(data, tags.FirstName.registryCatalogId)}
              readOnly={
                getOptions(data, tags.FirstName.registryCatalogId).length === 1
              }
              value={multipleValuesIndices.FirstName}
              byValue
              onChange={i =>
                setMultipleValuesIndices(prev => ({ ...prev, FirstName: i }))
              }
            />
          )}
          {tags.MiddleName && (
            <Select
              mt={20}
              label="Middle name"
              options={getOptions(data, tags.MiddleName.registryCatalogId)}
              readOnly={
                getOptions(data, tags.MiddleName.registryCatalogId).length === 1
              }
              value={multipleValuesIndices.MiddleName}
              byValue
              onChange={i =>
                setMultipleValuesIndices(prev => ({ ...prev, MiddleName: i }))
              }
            />
          )}
          {tags.LastName && (
            <Select
              mt={20}
              label="Last name"
              options={getOptions(data, tags.LastName.registryCatalogId)}
              readOnly={
                getOptions(data, tags.LastName.registryCatalogId).length === 1
              }
              value={multipleValuesIndices.LastName}
              byValue
              onChange={i =>
                setMultipleValuesIndices(prev => ({ ...prev, LastName: i }))
              }
            />
          )}
        </>
      ) : (
        <Select
          label="Value"
          options={getOptions(data, tags.first.registryCatalogId)}
          value={multipleValuesIndices.first}
          byValue
          onChange={i =>
            setMultipleValuesIndices(prev => ({ ...prev, first: i }))
          }
        />
      )}
      <ModalButtons>
        <Button secondary type="button" onClick={close} text="Cancel" />
        <Button
          text="Insert value"
          onClick={() => {
            onChange(
              ...handleChange(
                question,
                data,
                multipleValuesIndices,
                isMulti,
              )(subject),
            )
            closeModal()
          }}
        />
      </ModalButtons>
    </Box>
  )
}

export default R.compose(
  withModal(R.always("Freetext - Multiple values allowed data")),
  withData(p => assessmentTagRequest.values(p.subject.value)),
)(MultipleValuesModal)

import React from "react"
import { RadioItemWrap, RadioCircle } from "./styled"
import Text from "components/Text"

const RadioItem = ({
  onClick,
  inline,
  active,
  text,
  readOnly,
  disabled,
  transparent,
}) => (
  <RadioItemWrap
    onClick={readOnly || disabled ? undefined : onClick}
    inline={inline}
    readOnly={readOnly}
    disabled={disabled}
    data-id-checked={active}
    data-optiontext={text}
  >
    <RadioCircle active={active} transparent={transparent} />
    <Text>{text}</Text>
  </RadioItemWrap>
)

export default RadioItem

import styled from "styled-components/macro"
import theme from "theme"

export const InputContactsWrap = styled.div`
  display: block;
  width: 100%;

  ${theme.mq.tablet} {
    display: flex;
  }

  > div {
    flex-grow: 1;
    margin-right: 10px;
    margin-bottom: 10px;

    ${theme.mq.tablet} {
      margin-bottom: 0;
    }
  }
`

import React from "react"
import Link from "components/Link"
import ArrangementIdModal from "./ArrangementIdModal"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { localize } from "locale"
import useOpen from "hooks/useOpen"

const ArrangementId = ({
  withLabel,
  arrangementId,
  fromTasks,
  getLink,
  isPreassessment,
  caseId,
}) => {
  const { isOpen, open, close } = useOpen()
  const el = (
    <>
      <Link ul onClick={open}>
        {arrangementId}
      </Link>
      {isOpen && (
        <ArrangementIdModal
          arrangementId={arrangementId}
          fromTasks={fromTasks}
          getLink={getLink}
          close={close}
          caseId={caseId}
          isPreassessment={isPreassessment}
        />
      )}
    </>
  )
  return withLabel ? (
    <Box>
      <P gray bold mb={15}>
        {localize("arrangementId")}
      </P>
      {el}
    </Box>
  ) : (
    el
  )
}

export default ArrangementId

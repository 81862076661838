import styled, { css } from "styled-components/macro"
import theme from "theme"

export const ModalButtonsWrap = styled.div`
  margin-top: 20px;
  ${p =>
    p.nomargin &&
    css`
      margin-top: 0;
    `};

  ${p =>
    p.buttonText &&
    css`
      ${!p.noBg && `background: #f5f5f5;`};
      margin: ${p.nomargin ? 0 : 20}px -20px -20px -20px;
      padding: 10px 20px 20px 20px;
    `}
`

export const ButtonWrap = styled.div`
  button {
    margin-top: 10px;
    margin-left: 10px;
    margin-bottom: 10px;

    ${theme.mq.tablet} {
      margin-bottom: 0;
    }

    ${p =>
      p.first &&
      css`
        margin-left: 0;
      `};
    ${p =>
      p.nomargin &&
      css`
        margin-top: 0;
      `};
  }
`

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import withModal from "hoc/withModal"
import Radio from "inputs/Radio"
import * as R from "ramda"
import withForm from "hoc/withForm"
import { stringRequired } from "validators/string"
import { getCoworkerOptions } from "data/assessments/coworkerOptions"
import { caseRequest } from "data/cockpit/api"
import { toastSuccess } from "data/toasts/rx"

const CreateModal = ({ templateIds, libraryItemIds, close }) => {
  return (
    <>
      {templateIds.length > 0 && (
        <Radio
          options={getCoworkerOptions()}
          name="templateAssessmentCreationOptions"
          label={`For ${templateIds.length} assessment(s) generated from empty template:`}
          byValue
        />
      )}
      {libraryItemIds.length > 0 && (
        <Radio
          options={getCoworkerOptions(true)}
          name="libraryItemAssessmentCreationOptions"
          label={`For ${libraryItemIds.length} assessment(s) generated from library`}
          byValue
        />
      )}
      <ModalButtons buttonText="Do you want to generate assessments?">
        <Button secondary text="No" onClick={close} />
        <Button text="Yes, generate" type="submit" />
      </ModalButtons>
    </>
  )
}

export default R.compose(
  withModal(R.always("Choose options for co-workers")),
  withForm({
    mapProps: R.always({
      templateAssessmentCreationOptions: "useCaseCoworkers",
      libraryItemAssessmentCreationOptions: "useCaseCoworkers",
    }),
    schema: R.always({
      templateAssessmentCreationOptions: stringRequired,
      libraryItemAssessmentCreationOptions: stringRequired,
    }),
    onSubmit: p => data =>
      caseRequest.createAssessments(p.caseId, {
        templateIds: p.templateIds,
        libraryItemIds: p.libraryItemIds,
        templateAssessmentCreationOptions: {
          assessmentsCoworkersOption: data.templateAssessmentCreationOptions,
        },
        libraryItemAssessmentCreationOptions: {
          assessmentsCoworkersOption: data.libraryItemAssessmentCreationOptions,
        },
      }),
    onSuccess: () => res => {
      res.assessmentNames?.forEach(x =>
        toastSuccess(`Assessment ${x} created in Case detail.`),
      )
      res.involvedPartyNames?.forEach(x =>
        toastSuccess(`Involved party ${x} created in Case detail.`),
      )
    },
    redirect: (_, p) => p.suburls.detail.url,
  }),
)(CreateModal)

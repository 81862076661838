import React from "react"
import * as R from "ramda"
import Condition from "./Condition"
import { LogicNodeWrap, SectionButtonsBox } from "./styled"
import { operationOptions } from "data/templates/logic"
import AddButtons from "./AddButtons"
import Select from "inputs/Select"
import Columns from "components/Columns"
import { Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { localize } from "locale"

const LogicNode = ({
  item,
  operationBefore,
  indices,
  addSingleCondition,
  addSingleBracket,
  ...rest
}) => {
  // this should only be the case at the very root level, when no logic is there yet
  if (!item) {
    return (
      <AddButtons
        indices={indices}
        {...rest}
        // override addCondition from rest, only for this case
        addCondition={addSingleCondition}
        addBrackets={addSingleBracket}
      />
    )
  }

  if (R.type(item) === "Object") {
    return (
      <Condition
        term={item}
        operationBefore={operationBefore}
        indices={indices}
        {...rest}
      />
    )
  }

  const showOperation = indices.some(x => x !== 0)
  const showButtonsAfter = indices.length > 0
  return (
    <Columns
      widths={[
        ...(indices.length > 0 ? [80] : [0]),
        "auto",
        ...(showButtonsAfter ? [146] : []),
      ]}
      mt={10}
      mb={10}
      gap={10}
    >
      {showOperation ? (
        <Select
          options={operationOptions}
          value={operationBefore}
          onChange={rest.editOperation(indices)}
          byValue
        />
      ) : (
        <div />
      )}
      <LogicNodeWrap depth={indices.length}>
        {/* <div>section, indices: {JSON.stringify(indices)}</div> */}
        {item.length === 0 ? (
          <Spread pt={10} pb={10}>
            <P gray nobreak nowrap mr={10}>
              {localize("sectionIsEmpty")}
            </P>
            <AddButtons indices={indices.concat(0)} {...rest} />
          </Spread>
        ) : (
          item.map((x, xi) => (
            <LogicNode
              key={xi}
              indices={indices.concat(xi)}
              item={x.item}
              operationBefore={x.operationBefore}
              {...rest}
            />
          ))
        )}
      </LogicNodeWrap>
      {showButtonsAfter && (
        <SectionButtonsBox>
          <AddButtons indices={indices} {...rest} withDelete />
        </SectionButtonsBox>
      )}
    </Columns>
  )
}

export default LogicNode

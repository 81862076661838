import React from "react"
import ContextMenu from "components/ContextMenu"
import AriaMenu from "components/AriaMenu"
import OverlayWrapper from "components/OverlayWrapper"
import { IconWrap } from "styledComponents/Icons"
import useOpen from "hooks/useOpen"

const OptionBox = ({ options, icon: Icon }) => {
  const { isOpen, toggle, close } = useOpen()

  return (
    <OverlayWrapper isOpen={isOpen} close={close}>
      <IconWrap onClick={toggle}>
        <Icon />
      </IconWrap>
      <ContextMenu isOpen={isOpen}>
        <AriaMenu
          options={options.map(option => ({
            ...option,
            onClick: () => {
              close()
              option.onClick()
            },
          }))}
        />
      </ContextMenu>
    </OverlayWrapper>
  )
}

export default OptionBox

import React from "react"
import { mappingRequest } from "data/mapping/api"
import withData from "hoc/withData"
import { P } from "styledComponents/Typography"
import Panel from "components/Panel"
import { localize } from "locale"
import { Switch, Redirect, Route } from "react-router-dom"
import SchemaMapping from "./SchemaMapping"
import withEditProps from "hoc/withEditProps"
import { getSuburlOptions } from "./suburls"
import * as R from "ramda"
import SchemaForm from "./SchemaForm"
import Tabs from "components/Tabs"

const Mapping = ({
  templateId,
  refetchData,
  editProps,
  history,
  location,
  match,
  data,
}) => {
  const suburls = getSuburlOptions(match, data)

  const activeOptions = suburls.filter(x => !x.disabled)

  return (
    <>
      <SchemaForm
        templateId={templateId}
        options={data}
        refetch={refetchData}
        editProps={editProps}
      />
      <Tabs
        options={activeOptions}
        value={location.pathname}
        onChange={v => history.push(v)}
        prefixActive
      />
      {activeOptions.length === 0 ? (
        <Panel mt={20}>
          <P>{localize("noSchemaIsEnabled")}</P>
        </Panel>
      ) : (
        <Switch>
          {activeOptions.map(x => (
            <Route
              key={x.url}
              path={x.url}
              render={() => (
                <SchemaMapping
                  {...x}
                  key={x.url}
                  templateId={templateId}
                  schema={x}
                  editProps={editProps}
                  refetch={refetchData}
                />
              )}
            />
          ))}
          <Redirect to={activeOptions[0].url} />
        </Switch>
      )}
    </>
  )
}

export default R.compose(
  withData(p => mappingRequest.schemas(p.templateId)),
  withEditProps(R.prop("canEdit")),
)(Mapping)

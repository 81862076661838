import React from "react"
import errorimg from "./errorimg.png"
import { P } from "styledComponents/Typography"
import { AppErrorWrap, StyledImg } from "./styled"

const AppError = ({ title }) => (
  <AppErrorWrap>
    <StyledImg src={errorimg} alt="error" />
    <P center fs={30}>
      {title}
    </P>
  </AppErrorWrap>
)

export default AppError

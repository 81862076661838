import * as R from "ramda"

export const getFilenameFromResponse = res => {
  const disposition = R.pathOr("", ["headers", "content-disposition"], res)
  const split = disposition.split("; ")
  const filenamePart =
    split.find(y => y.startsWith("filename=")) ||
    "filename=file-name-not-available"
  const filename = filenamePart.slice("filename=".length)

  return filename
}

export const downloadFileFromUri = (uri, filename) => {
  const link = document.createElement("a")
  link.download = filename
  link.href = uri
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadFile = (req, filename) =>
  req.subscribe(res => downloadFileFromUri(res.data, filename))

export const openLink = (uri, target = "_blank") => {
  const link = document.createElement("a")
  link.href = uri
  link.target = target
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}

export const downloadContentAsFile = req => {
  req.subscribe(res => {
    const filename = getFilenameFromResponse(res)

    const pom = document.createElement("a")
    pom.setAttribute(
      "href",
      "data:text/plain;charset=utf-8," + encodeURIComponent(res.data),
    )
    pom.setAttribute("download", filename)

    if (document.createEvent) {
      const event = document.createEvent("MouseEvents")
      event.initEvent("click", true, true)
      pom.dispatchEvent(event)
    } else {
      pom.click()
    }
  })
}

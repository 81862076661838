import React from "react"
import { assessmentRequest } from "data/assessments/api"
import withData from "hoc/withData"
import withModal from "hoc/withModal"
import { config } from "./config"
import Table from "components/Table"
import { parallel } from "common/rxjs"
import { conclusionRequest } from "data/conclusions/api"
import * as R from "ramda"
import withUrls from "hoc/withUrls"
import { preassessmentRequest } from "data/preassessments/api"

const ArrangementIdModal = ({ items, statuses, fromTasks, urls, getLink }) => (
  <Table
    pageSize={Infinity}
    borderless
    boldHeader
    config={config(urls, statuses, fromTasks, getLink)}
    data={items}
  />
)

export default R.compose(
  withModal(p => p.arrangementId, { noPadding: true, width: 840 }),
  withUrls,
  withData(
    p =>
      parallel(
        p.isPreassessment
          ? preassessmentRequest.getByArrangementId(p.caseId, p.arrangementId)
          : assessmentRequest.getByArrangementId(p.arrangementId),
        conclusionRequest.listTypes(),
      ),
    {
      dataKeys: ["items", "statuses"],
    },
  ),
)(ArrangementIdModal)

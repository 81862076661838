import styled from "styled-components/macro"

export const CommentItemWrap = styled.div`
  margin-bottom: 30px;

  svg {
    width: 15px;
    height: 15px;
    fill: #666;
    margin-right: 5px;
    vertical-align: bottom;
  }
`

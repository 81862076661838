import { darken } from "common/colors"
import styled, { keyframes } from "styled-components/macro"
import theme from "theme"

export const appear = keyframes`
  0%{
    opacity: 0;
  }
  to{
    opacity: 1;
  }
`

export const ScrollToTopWrap = styled.div`
  position: fixed;
  bottom: 75px;
  right: 20px;
  width: 50px;
  height: 50px;
  background: ${theme.color.orange};
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${appear} 0.5s ease;
  box-shadow: 0 0 10px #636363;

  &:hover {
    background: ${darken(0.05, theme.color.red)};
  }
`

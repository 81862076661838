import IconBig from "./IconBig"
import IconBold from "./IconBold"
import IconItalic from "./IconItalic"
import IconList from "./IconList"
import IconSmall from "./IconSmall"
import IconUnderline from "./IconUnderline"

export const styles = [
  { icon: IconSmall, cmd: "subscript", text: "Small" },
  { icon: IconBig, cmd: "superscript", text: "Big", space: true },
  { icon: IconBold, cmd: "bold", text: "Bold" },
  { icon: IconItalic, cmd: "italic", text: "Italic" },
  { icon: IconUnderline, cmd: "underline", text: "Underline", space: true },
  { icon: IconList, cmd: "insertUnorderedList", text: "UL" },
]

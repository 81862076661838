import React from "react"
import { uploadRequest } from "data/templates/api"
import CaretIcon from "icons/Caret"
import Text from "components/Text"
import LabelValue from "components/LabelValue"
import CompareToolLinks from "inputs/CompareToolLinks"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { IconWrap } from "styledComponents/Icons"
import { P } from "styledComponents/Typography"
import useOpen from "hooks/useOpen"
import Filepicker from "inputs/Filepicker"
import * as R from "ramda"

const SupportiveInformation = ({ values, templateId, template }) => {
  const { isOpen, toggle } = useOpen(false)
  const hasExtras =
    values.supportiveInfo ||
    (values.links && values.links.length > 0) ||
    (values.attachments && values.attachments.length > 0)

  if (!hasExtras) return <P>{localize("noSupportiveInformation")}</P>

  return (
    <>
      <Box mb={isOpen ? 20 : 0} onClick={toggle} flex>
        <P red link>
          {localize("supportiveInformation")}
        </P>
        <IconWrap static rotate={+isOpen}>
          <CaretIcon />
        </IconWrap>
      </Box>
      {isOpen && (
        <>
          {R.path(["supportiveInfo"], values) && (
            <LabelValue
              label={localize("supportiveInformation")}
              value={<Text>{values.supportiveInfo}</Text>}
            />
          )}
          {R.pathOr([], ["links"], values).length > 0 && (
            <CompareToolLinks
              value={values.links}
              label={localize("links")}
              readOnly
              borderless
              template={template}
              noEmptyError
            />
          )}
          {R.pathOr([], ["attachments"], values).length > 0 && (
            <Filepicker
              value={values.attachments}
              label={localize("attachFiles")}
              upload={uploadRequest.create(templateId)}
              download={uploadRequest.download(templateId)}
              readOnly
            />
          )}
        </>
      )}
    </>
  )
}

export default SupportiveInformation

import React from "react"
import Link from "components/Link"
import Status from "components/Status"
import { assessmentColors } from "data/assessments/colors"
import { formatDate } from "data/units/date/formatters"
import { localize } from "locale"
import * as R from "ramda"
import { indexColorsByStatuses } from "data/conclusions/colors"
import { formatConclusionStatus } from "data/conclusions/formatters"

const types = {
  assessment: {
    value: "Assessment",
    name: "Assessment",
  },
  conclusion: {
    value: "Conclusion",
    name: "Conclusion",
  },
  preassessment: {
    value: "Pre-assessment",
    name: "Pre-assessment",
  },
  preconclusion: {
    value: "Pre-conclusion",
    name: "Pre-conclusion",
  },
}

export const config = (urls, statuses, fromTasks, getLink) => ({
  columns: [
    {
      label: localize("createdOn"),
      path: R.pipe(R.prop("createdAtUtc"), formatDate),
    },
    {
      label: localize("type"),
      path: x => R.values(types).find(y => y.value === x.type).name,
      nowrap: true,
    },
    {
      label: localize("name"),
      path: x => {
        const getTo = () => {
          if (getLink) return getLink(x.id)

          const link =
            x.type === types.assessment.value
              ? urls.assessmentsCreate(x.id)
              : urls.conclusionsDetail(x.id)

          if (fromTasks) {
            return {
              pathname: link,
              state: { fromTasks: true },
            }
          }

          return link
        }
        return (
          <Link ul to={getTo()}>
            {x.name}
          </Link>
        )
      },
    },
    {
      label: localize("status"),
      path: x => (
        <Status
          color={
            {
              ...assessmentColors,
              ...indexColorsByStatuses(statuses),
            }[x.status]
          }
          text={
            x.type === types.assessment.value
              ? localize(x.status)
              : formatConclusionStatus(x.status)
          }
          rejected={x.isRejectedConclusion}
        />
      ),
    },
  ],
})

import React from "react"
import ContextMenu from "components/ContextMenu"
import OverlayWrapper from "components/OverlayWrapper"
import withFormField from "hoc/withFormField"
import CaretIcon from "icons/Caret"
import AriaMenu from "components/AriaMenu"
import { CaretWrap, SelectText, SelectWrap } from "./styled"
import CrossIcon from "icons/Cross"
import { Box } from "styledComponents/Containers"
import useOpen from "hooks/useOpen"

const getActiveOption = (value, options, byValue) =>
  byValue ? options.find(x => x.value === value) : value

const getText = (value, options, byValue, placeholder) =>
  getActiveOption(value, options, byValue)?.name || placeholder

const isEmpty = (value, byValue) => {
  if (value === undefined) return true
  if (!byValue) return value && value.value === undefined

  return false
}

const Select = ({
  options,
  value,
  onChange,
  readOnly,
  byValue,
  placeholder,
  borderless,
  small,
  large,
  edge2edge,
  clearable,
  gray,
}) => {
  const { isOpen, close, toggle } = useOpen()

  const canClear = clearable && value
  const activeOption = getActiveOption(value, options, byValue)

  const handleChange = v => {
    onChange(v)
    close()
  }

  return (
    <OverlayWrapper isOpen={isOpen} close={close}>
      <SelectWrap
        onClick={readOnly ? undefined : toggle}
        borderless={borderless}
        small={small}
        edge2edge={edge2edge}
        gray={gray}
      >
        <>
          <SelectText
            empty={isEmpty(value, byValue)}
            large={large}
            data-id="select-value"
          >
            {getText(value, options, byValue, placeholder)}
          </SelectText>
          {activeOption?.typeLabel && (
            <Box ml="auto">{activeOption.typeLabel}</Box>
          )}
          {!readOnly && (
            <CaretWrap
              canClear={canClear}
              onClick={
                canClear
                  ? e => {
                      handleChange()
                      e.preventDefault()
                      e.stopPropagation()
                    }
                  : undefined
              }
            >
              {canClear ? <CrossIcon /> : <CaretIcon />}
            </CaretWrap>
          )}
        </>
      </SelectWrap>
      <ContextMenu isOpen={isOpen}>
        <AriaMenu
          options={options}
          value={value}
          onChange={handleChange}
          byValue={byValue}
        />
      </ContextMenu>
    </OverlayWrapper>
  )
}

export default withFormField(Select)

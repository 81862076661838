import { debounceTime, merge, throttleTime, of, combineLatestWith } from "rxjs"
import { noop } from "common/functions"
import * as R from "ramda"

// fire first, throttle, then emit last
export const throunceTime = duration => source =>
  merge(
    source.pipe(throttleTime(duration)),
    source.pipe(debounceTime(duration)),
  ).pipe(throttleTime(0, undefined, { leading: true, trailing: false }))

export const parallel = (first$, ...rest) =>
  first$.pipe(combineLatestWith(rest))

export const execute = observable => observable.subscribe(noop)

export const getData = res => {
  if (!res) return null

  if (Array.isArray(res)) return res.map(R.prop("data"))

  return res.data
}

export const emptyRequest = of({ data: null })

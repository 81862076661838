import { localize } from "locale"
import * as R from "ramda"

export const userRoles = {
  siteAdmin: {
    value: "SiteAdmin",
    name: localize("siteAdmin"),
  },
  centralTeam: {
    value: "PwcCentralTeam",
    name: localize("centralTeam"),
  },
  templateUser: {
    value: "TemplateUser",
    name: localize("templateUser"),
  },
  user: {
    value: "User",
    name: localize("user"),
  },
  noRole: {
    name: localize("noRole"),
    value: null,
  },
}

export const localizeRole = r =>
  R.values(userRoles).find(x => x.value == r).name // eslint-disable-line

export const getOptions = (currentRole, isCentral) => {
  if (currentRole === userRoles.centralTeam.value || isCentral) {
    return [userRoles.siteAdmin, userRoles.centralTeam, userRoles.noRole]
  }

  return [
    userRoles.siteAdmin,
    userRoles.templateUser,
    userRoles.user,
    userRoles.noRole,
  ]
}

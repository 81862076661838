import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"
import * as R from "ramda"

export default R.compose(
  withState("isOpen", "setIsOpen", false),
  withHandlers({
    open: p => () => p.setIsOpen(true),
    close: p => () => p.setIsOpen(false),
    toggle: p => () => p.setIsOpen(!p.isOpen),
  }),
)

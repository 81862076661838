export default {
  apiRoot: window.config.apiRoot,
  testApiRoot: window.config.testApiRoot,
  idamAuthServer: window.config.idamAuthServer,
  idamClientId: window.config.idamClientId,
  appInsights: window.config.appInsights,
  buildNumber: process.env.REACT_APP_BUILD_NUMBER,
  buildNumberFull: process.env.REACT_APP_BUILD_NUMBER_FULL,
  test: window.config.test,
}

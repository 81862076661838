import React from "react"
import withFormField from "hoc/withFormField"
import LogicView from "./LogicView"
import LogicEdit from "./LogicEdit"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import withDefaultProps from "hoc/withDefaultProps"
import * as R from "ramda"

const InputLogic = ({ readOnly, ...rest }) =>
  readOnly ? (
    <LogicView {...rest} title={rest.conditionText} readOnly />
  ) : (
    <Box mb={20}>
      <LogicEdit {...rest} />
      <LogicView {...rest} title={localize("logicPreview")} />
    </Box>
  )

export default R.compose(
  withDefaultProps({
    entityName: localize("question"),
  }),
  withFormField,
)(InputLogic)

import React from "react"
import { Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import Panel from "components/Panel"
import Link from "components/Link"
import OutlineArrow from "icons/appkit/outline-down-chevron"
import { ActiveIcon } from "styledComponents/Icons"
import ConclusionDetail from "./ConclusionDetail"
import withUrls from "hoc/withUrls"
import useOpen from "hooks/useOpen"

const ConclusionPanel = ({
  conclusion,
  statuses,
  openId,
  setOpenId,
  refetch,
  fetchCount,
  urls,
  report,
}) => {
  const { isOpen, toggle } = useOpen()
  return (
    <>
      <Panel mt={20}>
        <Spread>
          <Link to={urls.conclusionsDetail(conclusion.assessmentId)}>
            <P link red>
              {conclusion.assessmentName}
            </P>
          </Link>
          <ActiveIcon rotate={+isOpen} onClick={toggle} ml={10}>
            <OutlineArrow />
          </ActiveIcon>
        </Spread>
      </Panel>
      {isOpen && (
        <ConclusionDetail
          key={fetchCount}
          conclusionId={conclusion.assessmentId}
          statuses={statuses}
          setOpenId={setOpenId}
          openId={openId}
          refetch={refetch}
          report={report}
        />
      )}
    </>
  )
}

export default withUrls(ConclusionPanel)

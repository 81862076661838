import { localize } from "locale"
import Coworkers from "./Coworkers"
import Settings from "./Settings"
import EvaluationQuestions from "./EvaluationQuestions"
import ReportingDecisions from "./ReportingDecisions"
import ReportingQuestions from "./ReportingQuestions"
import ReportingPreview from "./ReportingPreview"
import Review from "components/Review"
import { statuses } from "data/assessments/statuses"
import * as R from "ramda"

export const assessmentSuburls = {
  settings: "settings",
  coworkers: "approvers",
  evaluation: "evaluation",
  decisions: "decisions",
  reporting: "reporting-questions",
  preview: "preview",
  review: "review",
}

export const getSuburls = (
  match,
  status,
  assessment,
  reporting = {},
  template = {},
) => {
  const path = match.path
  const url = match.url

  const reportingDisabled = !status || !reporting.doReport

  const urls = {
    settings: {
      name: localize("settings"),
      path: path + "/settings",
      url: url + "/settings",
      component: Settings,
      disabled: false,
    },
    coworkers: {
      name: localize("coworkers"),
      path: path + "/approvers",
      url: url + "/approvers",
      component: Coworkers,
      disabled: !status,
    },
    evaluation: {
      name: localize("evaluationQuestions"),
      path: path + "/evaluation",
      url: url + "/evaluation",
      component: EvaluationQuestions,
      disabled: !status,
    },
    decisions: {
      name: template.isPreAssessment
        ? "Conclusion preview"
        : localize("reportingDecisions"),
      path: path + "/decisions",
      url: url + "/decisions",
      component: ReportingDecisions,
      disabled: !status,
    },
    reporting: {
      name: localize("reportingQuestions"),
      path: path + "/reporting-questions",
      url: url + "/reporting-questions",
      component: ReportingQuestions,
      disabled: reportingDisabled,
    },
    preview: {
      name: localize("reportingPreview"),
      path: path + "/preview",
      url: url + "/preview",
      component: ReportingPreview,
      disabled: reportingDisabled,
    },
    review: {
      name: localize("review"),
      path: path + "/review",
      url: url + "/review",
      component: Review,
      right: true,
      props: {
        canEditApprovers:
          (status === statuses.inProcess.value ||
            status === statuses.requestForApproval.value) &&
          assessment.canApprove,
        canEditReviewers: false,
      },
    },
  }

  return template.isPreAssessment
    ? R.omit(["reporting", "preview"], urls)
    : urls
}

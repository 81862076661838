import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M23.813,9.953 C23.72,9.413 23.6,8.882 23.438,8.37 C22.19,4.434 18.964,1.389 14.929,0.375 C13.991,0.139 13.015,0.001 12.004,0 L12,0 L11.996,0 C10.985,0.001 10.009,0.139 9.071,0.375 C4.512,1.521 0.996,5.265 0.188,9.948 C0.073,10.616 0,11.299 0,12 C0,12.701 0.073,13.384 0.188,14.052 C0.997,18.736 4.513,22.48 9.072,23.625 C10.011,23.861 10.988,24 12,24 C13.012,24 13.989,23.861 14.928,23.625 C18.963,22.612 22.189,19.567 23.438,15.63 C23.6,15.118 23.72,14.587 23.813,14.047 C23.928,13.381 24,12.699 24,12 C24,11.301 23.928,10.619 23.813,9.953 L23.813,9.953 Z M12,22.5 C6.21,22.5 1.5,17.79 1.5,12 C1.5,6.21 6.21,1.5 12,1.5 C17.79,1.5 22.5,6.21 22.5,12 C22.5,17.79 17.79,22.5 12,22.5 L12,22.5 Z M12.0002,12.7734 L8.3332,9.1054 L7.2732,10.1664 L12.0002,14.8944 L16.7272,10.1664 L15.6672,9.1054 L12.0002,12.7734 L12.0002,12.7734 Z"
      transform="translate(12.000000, 12.000000) rotate(-90.000000) translate(-12.000000, -12.000000)"
      fillRule="evenodd"
    ></path>
  </svg>
)

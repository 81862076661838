import React from "react"
import Select from "inputs/Select"
import ButtonModal from "components/ButtonModal"
import { Box } from "styledComponents/Containers"
import EditGroupModal from "./EditGroupModal"
import { getOptions, handleChange } from "./helpers"
import Validations from "./Validations"

const Group = ({
  value,
  onChange,
  question,
  groupNumber,
  parentGroupNumber,
  grandParentGroupNumber,
  template,
  templateId,
  isCustom,
  refetchTemplate,
  root,
  canEdit,
}) => {
  const options = getOptions(template.autoFillGroupNames)

  // const readOnly = (!parentGroupNumber && !root) || !isCustom || !canEdit
  const readOnly = !isCustom || !canEdit

  return (
    <Box ml={200}>
      <Box flex align="flex-end">
        <Box width={230}>
          <Select
            options={options}
            label="Auto-fill group"
            placeholder={
              readOnly && !root ? "Select parent group" : "Select group"
            }
            readOnly={readOnly}
            onChange={handleChange(value, question, onChange)}
            value={isCustom ? groupNumber : parentGroupNumber}
            byValue
            edge2edge={!isCustom}
            borderless={!isCustom}
            clearable={canEdit}
          />
        </Box>
        {isCustom && (
          <ButtonModal
            modal={EditGroupModal}
            text="Edit"
            ml={10}
            options={options}
            disabled={!groupNumber}
            groupNumber={groupNumber}
            templateId={templateId}
            refetchTemplate={refetchTemplate}
          />
        )}
      </Box>
      <Validations
        groupNumber={groupNumber}
        parentGroupNumber={parentGroupNumber}
        grandParentGroupNumber={grandParentGroupNumber}
        question={question}
        isCustom={isCustom}
        value={value}
      />
    </Box>
  )
}

export default Group

import React from "react"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import ToggleMark from "components/ToggleMark"
import { TitleWrap } from "./styled"

const TitleBox = ({
  isOpen,
  isOpenable,
  isSelectable,
  toggle,
  onSelect,
  title,
  isActive,
  noToggle,
  expand,
  scroller,
  wasActiveInLastSelection,
}) => {
  const scrollTo = (isActive, wasActiveInLastSelection) => el => {
    if (!el) return
    if (!isActive && !wasActiveInLastSelection) return

    scroller.scrollTop = el.offsetTop - scroller.offsetTop - 50
  }

  return (
    <Box flex acenter>
      {!noToggle && (
        <ToggleMark
          onClick={toggle}
          isOpen={isOpen || expand}
          hide={!isOpenable}
        />
      )}
      <TitleWrap
        isActive={isActive}
        ref={scrollTo(isActive, wasActiveInLastSelection)}
      >
        <P
          bold
          red={isSelectable}
          link={isSelectable}
          hovernolink={!isActive}
          onClick={isSelectable ? onSelect : undefined}
          white={isActive}
        >
          {title}
        </P>
      </TitleWrap>
    </Box>
  )
}

export default TitleBox

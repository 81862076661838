export const showCopySection = ({ copyIndex, sectionIndex }) =>
  copyIndex &&
  copyIndex.type === "section" &&
  // cant copy section into itself (BE rule)
  copyIndex.sectionIndex !== sectionIndex &&
  !copyIndex.hasSubsection

export const showCopySubsection = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "subsection"

export const showCopyQuestion = ({ copyIndex }) =>
  copyIndex && copyIndex.type === "question"

export const showMoveQuestion = ({ moveIndex, sectionIndex }) =>
  moveIndex &&
  moveIndex.type === "question" &&
  (moveIndex.sectionIndex !== sectionIndex || moveIndex.nodeIndex > 0)

export const showMoveSubsection = ({ moveIndex, sectionIndex }) =>
  moveIndex &&
  moveIndex.type === "subsection" &&
  (moveIndex.sectionIndex !== sectionIndex || moveIndex.nodeIndex > 0)

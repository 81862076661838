import styled from "styled-components/macro"

export const ContextMenuWrap = styled.div`
  background: #fff;
  position: fixed;
  z-index: 100;
  overflow-y: auto;
  overflow-x: hidden;
  border: solid 1px #d8d8d8;
  ${p => p.pos};
`

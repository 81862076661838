import React from "react"
import withData from "hoc/withData"
// import withFormField from "hoc/withFormField"
import AutocompleteSelect from "inputs/AutocompleteSelect"
import Columns from "components/Columns"
import Warning from "components/Warning"
import { Box } from "styledComponents/Containers"
import { countryListRequest } from "data/countries/api"
import * as R from "ramda"

const AutocompleteSelectCountry = ({
  data,
  warning,
  // name,
  // label,
  ...rest
}) => {
  if (!data) return null

  const input = (
    <AutocompleteSelect
      {...rest}
      options={data.map(x => ({
        ...x,
        name: x.countryName,
        value: x.countryCode,
      }))}
    />
  )

  if (warning)
    return (
      <Columns gap={10} align="flex-start">
        {input}
        <Box mt={rest.label ? 24 : 0}>
          <Warning error small text={warning} mb={0} fs={13} />
        </Box>
      </Columns>
    )

  return input
}

export default R.compose(
  // withFormField,
  withData(
    p => countryListRequest(p.tenantOnlyCountries, p.templateOnlyCountries),
    {
      cacheKey: p =>
        `${p.tenantOnlyCountries ? "tenant" : ""}-${
          p.templateOnlyCountries ? "template" : ""
        }-countries`,
      noLoader: true,
    },
  ),
)(AutocompleteSelectCountry)

import styled, { css } from "styled-components/macro"

export const UserListItemWrap = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0;
  ${p =>
    p.readOnly &&
    css`
      border-bottom: 1px solid #d8d8d8;
    `}
`

export const UserListItemRight = styled.div`
  display: flex;
  align-items: center;
`

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M23.25 9.75H21.75V6.75H15.75C15.3 6.75 15 6.45 15 6V0H3C2.55 0 2.25 0.3 2.25 0.75V9.75H0.75C0.3 9.75 0 10.05 0 10.5V19.5C0 19.95 0.3 20.25 0.75 20.25H23.25C23.7 20.25 24 19.95 24 19.5V10.5C24 10.05 23.7 9.75 23.25 9.75ZM7.10156 12.1348L6.14282 14.0845L5.18408 12.1348H3.57275L5.20825 15.0432L3.52844 18H5.15588L6.14282 16.014L7.12976 18H8.76123L7.07739 15.0432L8.71692 12.1348H7.10156ZM12.3907 16.2155L11.0815 12.1348H9.22449V18H10.6384V16.6304L10.5015 13.8226L11.9235 18H12.858L14.276 13.8267L14.139 16.6304V18H15.557V12.1348H13.6959L12.3907 16.2155ZM17.8733 16.9124H20.3346V18H16.4594V12.1348H17.8733V16.9124ZM16.5 1.2V5.25H20.55L16.5 1.2ZM2.25 21.75V23.25C2.25 23.7 2.55 24 3 24H21C21.45 24 21.75 23.7 21.75 23.25V21.75H2.25Z"
      fill="#464646"
    />
  </svg>
)

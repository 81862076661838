import styled, { css } from "styled-components/macro"

export const LogicNodeWrap = styled.div`
  ${p =>
    p.depth > 0 &&
    css`
      border: 1px solid #999;
      padding: 0px 10px;
    `}
`

export const SectionButtonsBox = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
`

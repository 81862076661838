import React from "react"
import withFormField from "hoc/withFormField"
import Autocomplete from "components/Autocomplete"
import withState from "hoc/withState"
import withHandlers from "hoc/withHandlers"
import * as R from "ramda"

const getNewText = (value, byValue, options) => {
  if (!value) return ""

  if (byValue) {
    const option = options && options.find(o => o.value === value)

    return option ? option.name : ""
  }

  return value.name
}

const AutocompleteSelect = ({
  options,
  text,
  handleChangeText,
  onChange,
  handleBlur,
  renderContent,
  readOnly,
  borderless,
  byValue,
  placeholder,
  small,
  transparent,
  gray,
  setText,
  value,
}) => {
  React.useEffect(() => {
    setText(getNewText(value, byValue, options))
  }, [byValue, options, setText, value])

  return (
    <Autocomplete
      options={options}
      text={text}
      placeholder={placeholder}
      changeText={handleChangeText}
      onChange={onChange}
      renderContent={renderContent}
      readOnly={readOnly}
      borderless={borderless}
      onBlur={handleBlur}
      byValue={byValue}
      small={small}
      transparent={transparent}
      gray={gray}
    />
  )
}

export default R.compose(
  withFormField,
  withState("text", "setText", p => getNewText(p.value, p.byValue, p.options)),
  withHandlers({
    handleChangeText: p => v => {
      if (!v) {
        p.onChange(undefined)
      }
      p.setText(v)
    },
    handleBlur: p => () => {
      if (!p.value) {
        p.onChange(undefined)
        p.setText("")
      }
    },
  }),
)(AutocompleteSelect)

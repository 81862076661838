import { answerTypes, answerKey } from "data/templates/answers"
import * as R from "ramda"

export const getShowFileUpload = (data, value) => {
  const qt = data.questionType
  const v = R.path([0, "answers", 0, answerKey[qt]], value)

  if (qt === answerTypes.single.value) {
    const answer = data.options.find(x => x.id === v)
    return answer && answer.answerAttachmentPolicy !== "N"
  }

  if (qt === answerTypes.multiple.value) {
    const answers = data.options.filter(x => v && v.includes(x.id))
    return answers.some(x => x.answerAttachmentPolicy !== "N")
  }

  return data.answerAttachmentPolicy !== "N"
}

export const isAttachmentRequired = (data, value) => {
  const qt = data.questionType
  const v = R.path([0, "answers", 0, answerKey[qt]], value)

  if (qt === answerTypes.single.value) {
    const answer = data.options.find(x => x.id === v)
    return answer && answer.answerAttachmentPolicy === "R"
  }

  if (qt === answerTypes.multiple.value) {
    const answers = data.options.filter(x => v && v.includes(x.id))
    return answers.some(x => x.answerAttachmentPolicy === "R")
  }

  return data.answerAttachmentPolicy === "R"
}

import React from "react"
import withModal from "hoc/withModal"
import withUrls from "hoc/withUrls"
import { P } from "styledComponents/Typography"
import { Box, Spread } from "styledComponents/Containers"
import * as R from "ramda"
import Link from "components/Link"
import Panel from "components/Panel"
import Ribbons from "components/Ribbons"
import theme from "theme"
import { ValueWrap } from "./styled"

const ValidationModal = ({ validationErrors, urls }) => (
  <>
    {validationErrors.map((x, xi) => (
      <Panel key={xi}>
        <P mb={5}>{x.arrangementIdentifierText}</P>
        <P fs={20} mb={10} bold>
          {x.arrangementIdentifier}
        </P>
        {x.errorsByType.length === 0 ? (
          <P>No errors</P>
        ) : (
          x.errorsByType.map((y, yi) => (
            <Box relative>
              <Ribbons left={10} ribbons={["VALUES MISMATCH FOR ELEMENTS"]} />
              <Panel key={yi} mt={20} noPadding>
                <Box p={10} bg={theme.color.border} mt={-1}>
                  <P mt={10} fs={18}>
                    {y.errorType}
                  </P>
                </Box>
                {y.errorValuesByConclusion.map((z, zi) => (
                  <ValueWrap key={zi}>
                    <Spread>
                      <Link ul to={urls.conclusionsDetail(z.conclusionId)}>
                        {z.conclusionName}
                      </Link>
                      <P>{z.value}</P>
                    </Spread>
                  </ValueWrap>
                ))}
              </Panel>
            </Box>
          ))
        )}
      </Panel>
    ))}
  </>
)

export default R.compose(
  withModal(() => "Could not create report", { width: 1000 }),
  withUrls,
)(ValidationModal)

import styled, { css } from "styled-components/macro"
import boxModel from "styledComponents/bases/boxModel"
import * as R from "ramda"

export const Row = styled.div`
  ${p =>
    !p.noCols &&
    css`
      display: flex;
    `};
  ${boxModel};
  ${p =>
    p.align &&
    css`
      align-items: ${p.align};
    `};
`

const getSizing = p => {
  // no widths specified means equal widths
  if (p.noCols)
    return css`
      width: 100%;
    `

  // no widths specified means equal widths
  if (!p.widths)
    return css`
      flex-basis: 0;
      flex-grow: 1;
      flex-shrink: 0;
      width: 0;
    `

  const edge = p.index === 0 || p.index === p.count - 1
  const addedWidth = edge ? p.gap / 2 : p.gap
  const w = p.widths[p.index]
  // specific width is set or auto

  if (R.type(w) === "String" && w.startsWith("<"))
    return css`
      flex-grow: 1;
      max-width: ${w.endsWith("%")
        ? w.slice(1)
        : `${+w.slice(1) + addedWidth}px`};
    `

  if (w === "auto")
    return css`
      flex-grow: 1;
      flex-shrink: 1;
      flex-basis: 0;
    `

  return css`
    width: ${p.widths[p.index] + addedWidth}px;
    flex-shrink: 0;
  `
}

export const Col = styled.div`
  font-size: 16px;
  padding: 0 ${p => p.gap / 2}px;
  ${getSizing};

  &:first-child {
    padding-left: 0;
  }
  &:last-child {
    padding-right: 0;
  }
  ${p =>
    p.noCols &&
    css`
      padding: 0;
      margin-top: ${p.gap}px;
      &:first-child {
        margin-top: 0;
      }
    `};
  ${p =>
    p.flexColumn &&
    css`
      display: flex;
      flex-direction: column;
    `}
`

import { EnhancedConclusionsDetailPage } from "pages/ConclusionsDetailPage"
import ConclusionsTable from "pages/ConclusionsPage/ConclusionsTable"

export const getSuburls = match => {
  const path = match.path
  const url = match.url

  const urls = {
    list: {
      path: `${path}/list`,
      url: `${url}/list`,
      component: ConclusionsTable,
    },
    conclusion: {
      path: `${path}/conclusion/:id`,
      url: id => `${url}/conclusion/${id}`,
      component: EnhancedConclusionsDetailPage,
    },
  }

  return urls
}

import React from "react"
import { StatusWrap } from "./styled"
import CrossIcon from "icons/appkit/outline-end-process"

const Status = ({ text, color, rejected, wrap = false }) => (
  <StatusWrap rejected={rejected} color={color} wrap={+wrap}>
    {rejected && <CrossIcon />}
    {text}
  </StatusWrap>
)

export default Status

import { arrayOptional, arrayOptionalOf } from "validators/array"
import { optionRequired } from "validators/options"
import {
  stringOptional,
  longStringOptional,
  longStringRequired,
  urlRequired,
} from "validators/string"
import { localize } from "locale"
import { validURL } from "common/router"
import { combineValidators } from "validators/helpers"

const subqCheck = predicate => v => {
  if (!v || v.length === 0) return true

  return v.every(x => predicate(x) && subqCheck(predicate)(x.subQuestions))
}

export const schema = (_, values) => ({
  questionText: combineValidators(longStringOptional, v => {
    if (values.questionType === "Custom") return ""

    return longStringRequired(v)
  }),
  questionType: optionRequired,
  isMandatory: stringOptional,
  forReportingOnly: stringOptional,
  answerAttachmentPolicy: optionRequired,
  supportiveInfo: longStringOptional,
  links: combineValidators(
    arrayOptionalOf(x => {
      if (!x.name) return localize("incompleteLinks")
      if (x.type === "Url") return x.linkUrl ? "" : localize("incompleteLinks")
      return x.headerId ? "" : localize("incompleteLinks")
    }),
    arrayOptionalOf(x => {
      if (x.type !== "Url") return ""

      return validURL(x.linkUrl) || urlRequired(x.linkUrl)
    }),
  ),
  attachments: arrayOptional,
  options: combineValidators(
    arrayOptionalOf(x => (x.text ? "" : localize("incompleteOptions"))),
    arrayOptionalOf(x =>
      longStringRequired(x.text) ? localize("tooLongOptions") : "",
    ),
  ),
  precondition: arrayOptional,
  subQuestions: combineValidators(
    arrayOptional,
    v =>
      subqCheck(x => x.questionType)(v)
        ? ""
        : localize("incompleteSubquestions"),
    v =>
      subqCheck(
        x =>
          (x.questionType !== "Multiple" && x.questionType !== "Single") ||
          (x.options && x.options.length > 0 && x.options.every(y => y.text)),
      )(v)
        ? ""
        : localize("incompleteSubquestionOptions"),
  ),
})

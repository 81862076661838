import React from "react"
import { assessmentQuestionRequest } from "data/assessments/api"
import { assessmentLibraryQuestionRequest } from "data/assessments/api"
import { buildTree, getQuestionsFromTree } from "data/templates/transforms"
import { emptyRequest, parallel } from "common/rxjs"
import withData from "hoc/withData"
import Questions from "../Questions"
import { localize } from "locale"
import Button from "components/Button"
import { Box } from "styledComponents/Containers"
import * as R from "ramda"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"
import { preassessmentRequest } from "data/preassessments/api"
import { previewRequest } from "data/templates/api"

const EvaluationQuestions = ({
  tree,
  questions,
  lastId,
  assessmentId,
  assessment,
  bookmarkedOnly,
  isPreview,
  canEdit,
  handleRefetch,
  suburls,
  questionFilter,
  isLibrary,
  isPreassessment,
}) => {
  return (
    <>
      <Questions
        tree={tree}
        questions={questions}
        lastId={lastId}
        assessmentId={assessmentId}
        assessment={assessment}
        bookmarkedOnly={bookmarkedOnly}
        isPreview={isPreview}
        canEdit={canEdit}
        refetch={handleRefetch}
        questionFilter={questionFilter}
        isLibrary={isLibrary}
        isPreassessment={isPreassessment}
      />
      <Box flex mt={20}>
        <Box ml="auto">
          <Button text={localize("done")} to={suburls.decisions.url} />
        </Box>
      </Box>
    </>
  )
}

export default R.compose(
  withData(p => {
    const getQuestionListRequest = () => {
      if (p.isLibrary) return assessmentLibraryQuestionRequest.list
      if (p.isPreview) return previewRequest.questions
      if (p.isPreassessment) return preassessmentRequest.questions

      return assessmentQuestionRequest.list
    }
    const getLastPositionRequest = () => {
      if (p.isLibrary) return assessmentLibraryQuestionRequest.lastPosition
      if (p.isPreview) return () => emptyRequest
      if (p.isPreassessment) return preassessmentRequest.lastPosition

      return assessmentQuestionRequest.lastPosition
    }

    return parallel(
      getQuestionListRequest()(p.assessmentId),
      getLastPositionRequest()(p.assessmentId),
    )
  }),
  withProps(() => ({
    questionFilter: x => !x.data.forReportingOnly,
  })),
  withProps(p => {
    const [qs, lastId] = p.data
    const tree = buildTree(qs, p.questionFilter)
    return {
      tree,
      questions: getQuestionsFromTree(tree),
      lastId,
    }
  }),
  withHandlers({
    handleRefetch: p => cb => {
      p.refetchData(res => {
        if (R.type(cb) === "Function") {
          cb(res)
        }
        // questions endpoint returns doReport, which can be changed by answer
        // so here we check for change and if different we refetch reporting
        if (p.reporting && res[0].doReport !== p.reporting.doReport) {
          p.refetchReporting()
        }
      })
    },
  }),
)(EvaluationQuestions)

import React from "react"
import withFormField from "hoc/withFormField"
import Checkbox from "inputs/Checkbox"
import Textfield from "inputs/Textfield"
import { localize } from "locale"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"

const InputNotify = ({ value, onChange, readOnly, borderless, gray }) => (
  <Box flex acenter>
    <Checkbox
      text={localize("notify")}
      value={!!value}
      onChange={() => onChange(value ? null : 7)}
      readOnly={readOnly}
      squared
      flip
      gray={gray}
    />
    {(!readOnly || value) && (
      <>
        <Box width={60} ml={10} mr={10} noshrink>
          <Textfield
            value={value}
            // max 3 chars
            onChange={v => onChange(v === "" ? null : +v.slice(0, 3))}
            readOnly={readOnly}
            gray={gray}
            borderless={borderless}
          />
        </Box>
        <P>{localize("daysBeforeDueDate")}</P>
      </>
    )}
  </Box>
)

export default withFormField(InputNotify)

import React from "react"
import Empty from "components/Empty"
import Loader from "components/Loader"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import Filters from "./Filters"
import Pagination from "components/Pagination"
import { StyledTable, TableWrap } from "./styled"
import TBody from "./TBody"
import THead from "components/THead"
import useTable from "./useTable"

const Table = ({
  config,
  data,
  loading,
  sorting,
  onItemClick = R.always(),
  pageSize,
  boldHeader,
  borderless,
  highlightFirst,
  params,
  onParamChange,
  count,
  filterLeftContent,
  filterRightContent,
  noPagination,
  head,
  grid,
  noRowBorder,
  darkHeader,
  searchPlaceholder,
  lastRight,
  ...rest
}) => {
  const {
    size,
    setSize,
    page,
    setPage,
    filters,
    setFilters,
    sort,
    setSort,
    search,
    setSearch,
    paginatedData,
    filteredSearchedSortedData,
  } = useTable({
    pageSize,
    sorting,
    config,
    data,
    params,
    onParamChange,
    count,
    noPagination,
  })
  const isEmpty = filteredSearchedSortedData.length === 0

  return (
    <TableWrap borderless={borderless}>
      <Box flex acenter mr={10}>
        {filterLeftContent && (
          <Box ml={10} mr={10}>
            {filterLeftContent}
          </Box>
        )}
        <Box ml="auto" flex acenter>
          <Filters
            showSearch={config.search}
            search={search}
            setSearch={setSearch}
            configFilters={config.filters}
            filters={filters}
            filterChange={name => value => {
              setFilters({
                ...filters,
                [name]: value,
              })
            }}
            data={data}
            searchPlaceholder={searchPlaceholder}
          />
          {filterRightContent}
        </Box>
      </Box>

      <StyledTable grid={grid} noRowBorder={noRowBorder}>
        {!isEmpty && (
          <>
            {head || (
              <THead
                config={config}
                sorting={sort}
                handleSort={(i, key) =>
                  setSort({
                    index: i,
                    key,
                    desc: i === sort.index ? !sort.desc : false,
                  })
                }
                bold={boldHeader}
                darkHeader={darkHeader}
                lastRight={lastRight}
              />
            )}
          </>
        )}
        <TBody
          {...rest}
          highlightFirst={highlightFirst}
          columns={config.columns}
          rowComponent={config.rowComponent}
          idKey={config.idKey}
          paginatedData={paginatedData}
          onItemClick={onItemClick}
          lastRight={lastRight}
        />
      </StyledTable>
      {loading && <Loader />}
      {isEmpty && <Empty />}
      {!noPagination && size !== Infinity && (
        <Pagination
          pageSize={size}
          setPageSize={setSize}
          setPage={setPage}
          page={page}
          itemsLength={count || filteredSearchedSortedData.length}
        />
      )}
    </TableWrap>
  )
}

export default Table

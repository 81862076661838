import React from "react"
import ButtonModal from "components/ButtonModal"
import ButtonModalRouted from "components/ButtonModalRouted"
import ConfirmModal from "components/ConfirmModal"
import ModalButtons from "components/ModalButtons"
import { assessmentRequest } from "data/assessments/api"
import { statuses } from "data/assessments/statuses"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import CopyAssessmentModal from "components/CopyAssessmentModal"
import DeleteModal from "./DeleteModal"
import AddToLibraryModal from "components/AddToLibraryModal"
import SubmitModal from "./SubmitModal"
import HistoryModal from "./HistoryModal"
import * as R from "ramda"
import useOpen from "hooks/useOpen"
import InfoModal from "components/InfoModal"
import Button from "components/Button"
import { execute } from "common/rxjs"
import { preassessmentRequest } from "data/preassessments/api"
import { getCaseUrl } from "data/cockpit/redirect"

const getSubmitAllowed = (suburls, location, reporting) => {
  const isDecisionsTab =
    location.pathname === R.path(["decisions", "url"], suburls)
  const isPreviewTab = location.pathname === R.path(["preview", "url"], suburls)

  if (reporting && reporting.isReportingAllowed) {
    if (reporting.doReport) return isPreviewTab

    return isDecisionsTab
  }

  return isDecisionsTab
}

const TitleControl = ({
  assessment,
  canEdit,
  suburls,
  reporting,
  location,
  urls,
  match,
  routes,
  isPreassessment,
  case_,
  refetchCase,
  caseUrls,
}) => {
  const { isOpen, open, close } = useOpen()

  if (!assessment) return null

  const submitAllowed = getSubmitAllowed(suburls, location, reporting)

  return (
    <ModalButtons nomargin>
      <ButtonModal
        secondary
        text={localize("history")}
        assessmentId={assessment.id}
        modal={HistoryModal}
        isPreassessment={isPreassessment}
      />
      {assessment.status === statuses.deleted.value && (
        <>
          {case_?.hasActivePreAssessment && isPreassessment ? (
            <ButtonModal
              secondary
              text="Restore"
              modal={InfoModal}
              title="Pre-assessment already exists for the case"
              body="Pre-assessment cannot be restored, because there is already existing pre-assessment under the case. Only 1 pre-assessment for the case is allowed."
            />
          ) : (
            <ButtonModal
              secondary
              text="Restore"
              modal={ConfirmModal}
              title={`Restore ${isPreassessment ? "pre-" : ""}assessment`}
              confirmText={`${
                isPreassessment ? "Pre-assessment" : "Assessment"
              } ${
                assessment.name
              } will be restored to "In Progress" status, and all relevant coworkers will receive tasks and notifications.`}
              confirmQuestion={`Do you want to restore the ${
                isPreassessment ? "pre-" : ""
              }assessment?`}
              cancelText="No"
              submitText="Yes"
              successText={() =>
                `You have restored the ${
                  isPreassessment ? "pre-" : ""
                }assessment ${assessment.name}.`
              }
              onSuccess={() => refetchCase?.()}
              request={() =>
                (isPreassessment
                  ? preassessmentRequest
                  : assessmentRequest
                ).restore({
                  assessmentIds: [assessment.id],
                })
              }
              redirect={() => {
                if (match.path.startsWith(routes.caseCreate.path)) {
                  return getCaseUrl(match, assessment)
                }

                return `${urls.assessments.local}/${statuses.deleted.value}`
              }}
            />
          )}
        </>
      )}
      {assessment.canEdit && !isPreassessment && (
        <ButtonModal
          secondary
          text={localize("makeACopy")}
          assessment={assessment}
          modal={CopyAssessmentModal}
          title={`${localize("makeACopy")} ${assessment.name}`}
          successText={() => localize("assessmentCopySuccess")(assessment.name)}
        />
      )}
      {assessment.status !== statuses.deleted.value && (
        <Button
          text={localize("export")}
          secondary
          onClick={() => {
            open()
            execute(
              (isPreassessment
                ? preassessmentRequest
                : assessmentRequest
              ).exportDetailAsZip(
                assessment.id,
                isPreassessment ? "Pre-assessmentZip" : "AssessmentZip",
              ),
            )
          }}
        />
      )}
      {isOpen && (
        <InfoModal
          close={close}
          title={localize("export")}
          text={localize("exportIsBeingGenerated")}
        />
      )}
      {assessment.canEditCoworkers &&
        assessment.status !== statuses.deleted.value &&
        assessment.status !== statuses.deactivated.value && (
          <ButtonModal
            secondary
            text={localize("createLibraryItem")}
            assessment={assessment}
            modal={AddToLibraryModal}
            isPreassessment={isPreassessment}
          />
        )}
      {(canEdit || assessment.status === statuses.deactivated.value) && (
        <ButtonModal
          secondary
          text={localize("deleteAssessment")}
          assessment={assessment}
          modal={DeleteModal}
          isPreassessment={isPreassessment}
          refetchCase={refetchCase}
          caseUrls={caseUrls}
          caseId={case_?.id}
        />
      )}
      {assessment.status === statuses.deleted.value && (
        <ButtonModal
          text="Delete permanently"
          modal={ConfirmModal}
          title={`Permanently delete assessment`}
          confirmText={`Assessment ${assessment.name} will be permanently deleted and cannot be restored.`}
          confirmQuestion={localize("permanentlyDeleteAssessmentQuestion")}
          cancelText="No"
          submitText="Yes"
          successText={() =>
            `You have permanently deleted the assessment ${assessment.name}`
          }
          request={() =>
            (isPreassessment
              ? preassessmentRequest
              : assessmentRequest
            ).deletePermanent({
              assessmentIds: [assessment.id],
            })
          }
          redirect={() => {
            if (match.path.startsWith(routes.caseCreate.path)) {
              return getCaseUrl(match, assessment)
            }

            return `${urls.assessments.local}/${statuses.deleted.value}`
          }}
          submitDanger
        />
      )}
      {canEdit && (
        <ButtonModalRouted
          text="Submit"
          assessmentId={assessment.id}
          modal={SubmitModal}
          isPreassessment={isPreassessment}
          assessment={assessment}
          suburls={suburls}
          disabled={!submitAllowed}
          hash="submit"
        />
      )}
    </ModalButtons>
  )
}

export default withUrls(TitleControl)

import React from "react"
import { PanelWrap, TitleWrap } from "./styled"
import { P } from "styledComponents/Typography"

const Panel = ({ children, title, ...rest }) => (
  <PanelWrap {...rest}>
    {title && (
      <TitleWrap>
        <P mb={15}>{title}</P>
      </TitleWrap>
    )}
    {children}
  </PanelWrap>
)

export default Panel

import React from "react"
import Tabs from "components/Tabs"
import { withRouter } from "react-router"
import withUrls from "hoc/withUrls"
import * as R from "ramda"
import {
  filterNavLinks,
  linksToOptions,
  getCurrentOption,
  getCurrentSuboption,
} from "./helpers"

const NavigationTabs = ({ modules, match, history, routes }) => {
  const routesArr = R.values(routes)
  const currentOption = getCurrentOption(routesArr, match)
  const currentSuboption = getCurrentSuboption(currentOption, match)

  const filtered = filterNavLinks(routesArr, modules)
  const navOptions = linksToOptions(filtered, modules)

  return (
    <Tabs
      options={navOptions}
      value={currentOption?.navLabel}
      menuValue={currentSuboption?.navLabel}
      onChange={(_, o) => history.push(o.path)}
    />
  )
}

export default R.compose(withRouter, withUrls)(NavigationTabs)

import { get, put, post, del } from "common/request"

export const siteConfigurationRequest = {
  listReportingTeams: () => get(`/api/tenant/reportingTeams`),
  getReportingTeam: countryCode =>
    get(`/api/tenant/reportingTeams/${countryCode}`),
  updateReportingTeam: countryCode => data =>
    put(`/api/tenant/reportingTeams/${countryCode}`, data),
  listReviewTeams: () => get(`/api/tenant/reviewTeams`),
  getReviewTeam: countryCode => get(`/api/tenant/reviewTeams/${countryCode}`),
  updateReviewTeam: countryCode => data =>
    put(`/api/tenant/reviewTeams/${countryCode}`, data),
  retentionPeriods: () => get(`/configuration/retention-periods`),
  updateRetentionPeriods: v => put(`/configuration/retention-periods`, v),
  listReportingUsers: params =>
    get(`/api/tenant/reportingTeams/availablePersons${params}`),
  listReviewUsers: params =>
    get(`/api/tenant/reviewTeams/availablePersons${params}`),
}

export const approverCommentRequests = assessmentId => ({
  comments: get(`/api/assessment/${assessmentId}/approval/comments`),
  addComment: data =>
    post(`/api/assessment/${assessmentId}/approval/comment`, data),
  deleteComment: id => del(`/api/assessment/approval/comment/${id}`),
  editComment: (id, data) =>
    put(`/api/assessment/approval/comment/${id}`, data),
  uploadFile: data =>
    post(`/api/assessment/${assessmentId}/approval/comment/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: attachmentId =>
    get(`/api/assessment/approval/comment/attachment/${attachmentId}`),
})

export const reviewerCommentRequests = assessmentId => ({
  comments: get(`/api/assessment/${assessmentId}/review/comments`),
  addComment: data =>
    post(`/api/assessment/${assessmentId}/review/comment`, data),
  deleteComment: id => del(`/api/assessment/review/comment/${id}`),
  editComment: (id, data) => put(`/api/assessment/review/comment/${id}`, data),
  uploadFile: data =>
    post(`/api/assessment/${assessmentId}/review/comment/file`, data, {
      doNotSetContentType: true,
    }),
  downloadFile: attachmentId =>
    get(`/api/assessment/review/comment/attachment/${attachmentId}`),
})

export const localSettingsRequest = {
  settings: () => get("/api/tenant/settings"),
  fileRestrictions: () => get("/api/app/uploadFileRules"),
  contacts: () => get("/api/tenant/contacts"),
  updateContacts: v => put("/api/tenant/contacts", v),
}

import React from "react"
import Button from "components/Button"
import IconPlus from "icons/appkit/outline-circle-plus"
import IconBrackets from "icons/appkit/outline-expand"
import IconDelete from "icons/appkit/outline-circle-delete"
import { Box } from "styledComponents/Containers"
// import * as R from "ramda"
import { incrementLast } from "common/array"

const AddButtons = ({
  indices,
  addBrackets,
  addCondition,
  withDelete,
  deleteNode,
}) => (
  <Box flex mt="auto">
    {/* add buttons, indices {JSON.stringify(indices)} */}
    <Button
      type="button"
      icon={IconBrackets}
      onClick={() => addBrackets(incrementLast(indices))}
      mr="10"
    />
    <Button
      type="button"
      icon={IconPlus}
      onClick={() => addCondition(incrementLast(indices))}
    />
    {withDelete && (
      <Button
        danger
        type="button"
        icon={IconDelete}
        onClick={() => deleteNode(indices)}
        ml="10"
      />
    )}
  </Box>
)

export default AddButtons

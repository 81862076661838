import React from "react"
import { answerTypes } from "data/templates/answers"
import { P } from "styledComponents/Typography"
import { localize } from "locale"

const CountryLabel = ({ type }) => {
  if (type === answerTypes.country.value)
    return (
      <P fs={12} gray italic mt={10}>
        {localize("countryArrangementLabel")}
      </P>
    )

  if (type === answerTypes.countryOther.value)
    return (
      <P fs={12} gray italic mt={10}>
        {localize("countryOtherLabel")}
      </P>
    )

  return null
}

export default CountryLabel

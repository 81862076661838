import React from "react"
import Columns from "components/Columns"
import { P } from "styledComponents/Typography"
import InputQuestion from "inputs/InputQuestion"
import { mappingRequest } from "data/mapping/api"
import Status from "components/Status"
import theme from "theme"
import * as R from "ramda"
import { localize } from "locale"
import SkipConfirm from "components/SkipConfirm"

const Single = ({
  node,
  questions,
  tree,
  lastSelectedValue,
  setLastSelectedValue,
  valueProps,
  indices,
  schemaId,
  templateId,
  editProps,
}) => (
  <SkipConfirm
    editProps={editProps}
    title={`R${node.element.nr} ${localize("element")}`}
    id={node.element.id}
    v={R.path(indices.concat(["mappedQuestion"]), valueProps.value)}
    onChange={v => {
      valueProps.onChange(
        R.set(
          R.lensPath(indices.concat(["mappedQuestion"])),
          v,
          valueProps.value,
        ),
      )
    }}
    resetValue={valueProps.resetValue}
    onSubmit={v =>
      mappingRequest.setMapping(templateId, {
        schemaId: schemaId,
        mappings: [v].filter(x => x && (!R.isNil(x.elementId) || !x.text)),
      })
    }
    onSuccess={res => {
      valueProps.resetValue(res)
      valueProps.setLastValue(res)
    }}
  >
    {({ v, editingThis, onChange }) => (
      <Columns widths={["auto", 400]} align="center">
        <div>
          <P mr="auto" mb={5} bold>
            R{node.element.nr} {node.element.displayText}
          </P>
          <Status
            wrap
            text={node.element.validationText}
            color={theme.color.red}
          />
        </div>
        <InputQuestion
          questions={questions}
          tree={tree}
          value={v}
          readOnly={!editingThis}
          elementId={node.element.id}
          onChange={x => {
            onChange(x)

            if (x.questionId || x.questionOptionId) {
              setLastSelectedValue(x)
            }
          }}
          lastSelectedValue={lastSelectedValue}
        />
      </Columns>
    )}
  </SkipConfirm>
)

export default Single

import { localize } from "locale"
import AssessmentsTable from "./AssessmentsTable"
import TemplatesTable from "./TemplatesTable"
import ReportsTable from "./ReportsTable"

export const taskSuburls = {
  templates: "templates",
  assessments: "assessments",
  reports: "reports",
}

export const getSuburls = match => {
  const path = match.path
  const url = match.url

  return {
    templates: {
      name: localize("templates"),
      path: path + "/templates",
      url: url + "/templates",
      component: TemplatesTable,
      id: "templateCount",
    },
    assessments: {
      name: localize("assessments"),
      path: path + "/assessments",
      url: url + "/assessments",
      component: AssessmentsTable,
      id: "assessmentCount",
    },
    reports: {
      name: localize("reports"),
      path: path + "/reports",
      url: url + "/reports",
      component: ReportsTable,
      id: "reportCount",
    },
  }
}

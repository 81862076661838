import React from "react"
import Button from "components/Button"
import ConfirmModal from "components/ConfirmModal"
import IconModal from "components/IconModal"
import { formatDate, formatTime } from "data/units/date/formatters"
import withForm from "hoc/withForm"
import EditIcon from "icons/appkit/fill-edit"
import OutlineAvatar from "icons/appkit/outline-avatar"
import IconDelete from "icons/appkit/outline-circle-delete"
import OutlineTime from "icons/appkit/outline-time"
import { IconWrap } from "styledComponents/Icons"
import Textarea from "inputs/Textarea"
import RichTextarea from "inputs/RichTextarea"
import Filepicker from "inputs/Filepicker"
import { localize } from "locale"
import * as R from "ramda"
import { Box, Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { stringRequired, longStringRequired } from "validators/string"
import { CommentItemWrap } from "./styled"
import { arrayOptional } from "validators/array"
import { emptyRequest } from "common/rxjs"

const CommentItem = ({
  values,
  comment,
  onSuccess,
  openId,
  setOpenId,
  requests,
  profile,
  readOnly,
  noAttachments,
  resetForm,
}) => {
  const isOpen = comment.id === openId
  const disabled = !R.isNil(openId) && !isOpen
  const Component = comment.isReadOnly ? RichTextarea : Textarea

  return (
    <CommentItemWrap>
      {comment.deletedAtUtc ? (
        <P mb={5}>{localize("commentDeleted")}</P>
      ) : (
        <Component
          name="text"
          readOnly={!isOpen}
          borderless={!isOpen}
          edge2edge={!isOpen}
          mb={5}
          noEmptyError
        />
      )}
      {!noAttachments && !comment.deletedAtUtc && (
        <Filepicker
          name="attachments"
          label={
            isOpen || R.pathOr([], ["attachments"], values).length > 0
              ? localize("attachments")
              : undefined
          }
          upload={requests.uploadFile}
          download={requests.downloadFile}
          mb={5}
          readOnly={!isOpen}
          borderless={!isOpen}
          noEmptyError
        />
      )}
      <Spread>
        <Box flex>
          <P fs={12} mr={10}>
            <OutlineAvatar />
            {comment.createdByUserName}
          </P>
          <P fs={12}>
            <OutlineTime />
            {`${localize("created")}: `}
            {[
              formatDate(comment.createdAtUtc),
              formatTime(comment.createdAtUtc),
            ].join(", ")}
            {comment.lastModifiedAtUtc && (
              <>
                {`; ${localize("lastEdit")}: `}
                {[
                  formatDate(comment.lastModifiedAtUtc),
                  formatTime(comment.lastModifiedAtUtc),
                ].join(", ")}
              </>
            )}
            {comment.deletedAtUtc && (
              <>
                {`; ${localize("deleted")}: `}
                {[
                  formatDate(comment.deletedAtUtc),
                  formatTime(comment.deletedAtUtc),
                ].join(", ")}
              </>
            )}
          </P>
        </Box>
        {isOpen && (
          <Box flex>
            <Button
              secondary
              text="Cancel"
              mr={5}
              onClick={() => {
                setOpenId()
                resetForm()
              }}
            />
            <Button type="submit" text={localize("confirm")} />
          </Box>
        )}
        {!isOpen &&
          profile.id === comment.createdByUserId &&
          !readOnly &&
          !comment.deletedAtUtc &&
          !comment.isReadOnly && (
            <Box flex>
              <IconWrap
                onClick={() => setOpenId(comment.id)}
                disabled={disabled}
              >
                <EditIcon />
              </IconWrap>
              <IconModal
                disabled={disabled}
                icon={IconDelete}
                modal={ConfirmModal}
                onSuccess={onSuccess}
                title={localize("deleteYourComment")}
                confirmText={localize("doYouWantToDeleteYourComment")}
                cancelText="No"
                submitText="Yes"
                request={() => requests.deleteComment(comment.id)}
              />
            </Box>
          )}
      </Spread>
    </CommentItemWrap>
  )
}

export default withForm({
  schema: p => ({
    text: p.long ? longStringRequired : stringRequired,
    ...(p.noAttachments ? {} : { attachments: arrayOptional }),
  }),
  mapProps: p => ({
    text: p.comment.text,
    attachments: p.comment.attachments,
  }),
  onSubmit: p => v =>
    p.comment.id === p.openId
      ? p.requests.editComment(p.comment.id, v)
      : emptyRequest,
  onSuccess: p => () => {
    p.onSuccess()
    p.setOpenId()
  },
})(CommentItem)

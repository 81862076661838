import React from "react"
import {
  PaginationWrap,
  PaginationLeft,
  PaginationShown,
  PaginationControls,
  PaginationPage,
  ArrowWrap,
} from "./styled"
import Arrow from "./Arrow"
import { localize } from "locale"
import Select from "inputs/Select"

const Pagination = ({ itemsLength, pageSize, setPageSize, setPage, page }) => {
  if (itemsLength === 0) return null

  const totalPages = Math.ceil(itemsLength / pageSize)
  const isLastPage = page + 1 === totalPages

  const pageStart = page * pageSize + 1
  const pageEnd =
    isLastPage && itemsLength % pageSize > 0
      ? (itemsLength % pageSize) + pageStart - 1
      : (page + 1) * pageSize

  return (
    <PaginationWrap>
      <PaginationLeft>
        <Select
          value={pageSize}
          onChange={setPageSize}
          byValue
          borderless
          small
          options={[5, 10, 20, 50].map(x => ({
            value: x,
            name: `${x} / ${localize("page")}`,
          }))}
        />
        <PaginationShown>
          {pageStart} - {pageEnd} {localize("of")} {itemsLength}{" "}
          {localize("items")}
        </PaginationShown>
      </PaginationLeft>
      <PaginationControls>
        <ArrowWrap onClick={() => setPage(page - 1)} disabled={page === 0}>
          <Arrow />
        </ArrowWrap>
        <PaginationPage>
          <strong>{page + 1}</strong> {localize("of")} {totalPages}
        </PaginationPage>
        <ArrowWrap flip onClick={() => setPage(page + 1)} disabled={isLastPage}>
          <Arrow />
        </ArrowWrap>
      </PaginationControls>
    </PaginationWrap>
  )
}

export default Pagination

import React from "react"
import Panel from "components/Panel"
import withData from "hoc/withData"
import ValidationErrors from "../ValidationErrors"
import { approveRequest } from "data/templates/api"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import * as R from "ramda"

const Validation = ({ data, templateId }) => {
  const count =
    data.messages.length +
    R.keys(data.questionMessages).length +
    R.keys(data.conclusionDefinitionMessages).length +
    R.keys(data.conclusionReasoningMessages).length
  return (
    <Panel>
      {data.isValid ? (
        <P>{localize("everythingIsCorrectYouCanSubmitTemplate")}</P>
      ) : (
        <P mb={20}>{localize("thereAreXErrorsInTemplate")(count)}</P>
      )}
      <ValidationErrors {...data} templateId={templateId} />
    </Panel>
  )
}

export default withData(p => approveRequest.check(p.templateId))(Validation)

import styled, { css, keyframes } from "styled-components/macro"
import theme from "theme"

export const LoaderWrap = styled.div`
  text-align: center;
  ${p =>
    p.center &&
    css`
      z-index: 1000;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `};
`

export const StyledLoader = styled.div`
  padding: 15px 30px;
  border-radius: 4px;
  text-align: center;
  display: inline-block;
  ${p =>
    !p.small &&
    css`
      background-color: rgba(0, 0, 0, 0.7);
    `}
`

export const LoaderText = styled.div`
  color: #fff;
  font-size: 12px;
`

export const rotate = keyframes`
  0%{transform:rotate(0deg)}
  to{transform:rotate(1turn)}}
`

export const LoaderSpinner = styled.div`
  display: inline-block;
  height: 22px;
  width: 22px;
  border-radius: 50%;
  border-left: 0.1875rem solid #fff;
  animation: ${rotate} 0.3s infinite linear;
  transform: translateZ(0);

  ${p =>
    p.small
      ? css`
          border-left-color: ${theme.color.red};
        `
      : css`
          margin: 20px 0;
        `}
`

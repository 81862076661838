import React from "react"
import { AppLogoWrap, LogoLink, AppName } from "./styled"
import logo from "./logo.png"
import { localize } from "locale"
import withUrls from "hoc/withUrls"

const AppLogo = ({ name = localize("appName"), nolink, column, urls }) => (
  <AppLogoWrap column={column}>
    <LogoLink to={urls.home} nolink={nolink}>
      <img alt="logo" src={logo} />
    </LogoLink>
    <AppName column={column}>{name}</AppName>
  </AppLogoWrap>
)

export default withUrls(AppLogo)

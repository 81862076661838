import React from "react"
import withProfile from "hoc/withProfile"
import { localize } from "locale"
import { P } from "styledComponents/Typography"
import CommentItem from "./CommentItem"

const CommentList = ({
  comments,
  refetch,
  openId,
  setOpenId,
  profile,
  canEdit,
  requests,
  noAttachments,
  readOnly,
  long,
}) => (
  <>
    {comments.length === 0 && <P>{localize("noComments")}</P>}
    {comments.map(x => (
      <CommentItem
        key={x.id}
        comment={x}
        onSuccess={refetch}
        openId={openId}
        setOpenId={setOpenId}
        profile={profile}
        canEdit={canEdit}
        requests={requests}
        noAttachments={noAttachments}
        readOnly={readOnly}
        long={long}
      />
    ))}
  </>
)

export default withProfile(CommentList)

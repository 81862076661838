import React from "react"
import Columns from "components/Columns"
import { answerTypes, specialBehavior } from "data/templates/answers"
import withFormField from "hoc/withFormField"
import { localize } from "locale"
import * as R from "ramda"
import { Box } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import AnswerGrid from "./AnswerGrid"
import { getTemporaryId } from "common/ids"
import withProps from "hoc/withProps"
import withHandlers from "hoc/withHandlers"

const InputAnswers = ({
  type,
  value,
  addAnswer,
  addNoneOfTheAbove,
  changeText,
  changeFile,
  remove,
  readOnly,
  borderless,
  withAttachments,
  allowTextChangeInReadOnly,
}) => {
  const specialAnswers = value.filter(a => a.specialBehavior)
  const hasSpecialAnswers = specialAnswers.length !== 0
  return (
    <Box responsive>
      <Box minWidth={552}>
        <Columns
          gap={10}
          widths={["auto", ...(withAttachments ? [300] : []), 50]}
          mb={10}
        >
          <P bold gray>
            {localize("answers")}
          </P>
          {withAttachments && (
            <P bolder gray>
              {localize("attachments")}
            </P>
          )}
          <P />
        </Columns>
        <AnswerGrid
          value={value.filter(a => !a.specialBehavior)}
          type={type}
          changeText={changeText}
          readOnly={readOnly}
          borderless={borderless}
          changeFile={changeFile}
          allowTextChangeInReadOnly={allowTextChangeInReadOnly}
          remove={remove}
          withAttachments={withAttachments}
        />
        {!readOnly &&
          (type !== answerTypes.confirmation.value || value.length === 0) && (
            <P>
              <P link bold inline red onClick={addAnswer}>
                {localize("addAnswer")}
              </P>
              {!hasSpecialAnswers && type === answerTypes.multiple.value && (
                <>
                  <P inline ml={5} mr={5}>
                    {localize("or")}
                  </P>
                  <P link inline bold red onClick={addNoneOfTheAbove}>
                    {localize("addNoneOfTheAbove")}
                  </P>
                </>
              )}
            </P>
          )}
        {hasSpecialAnswers && (
          <P mt={10} mb={5}>
            <P inline bold fs={14} gray>
              {localize("noneOfTheAbove")}
            </P>{" "}
            {!readOnly && (
              <P inline fs={14} gray>
                ({localize("youMayTranslateNoneOfTheAbove")})
              </P>
            )}
          </P>
        )}
        <AnswerGrid
          value={specialAnswers}
          type={type}
          changeText={changeText}
          readOnly={readOnly}
          borderless={borderless}
          changeFile={changeFile}
          remove={remove}
          withAttachments={withAttachments}
        />
      </Box>
    </Box>
  )
}

export default R.compose(
  withFormField,
  withProps(p => ({
    value: (
      p.value || [p.allOptions ? { id: getTemporaryId(p.allOptions) } : {}]
    ).map((a, i) => ({
      ...a,
      i,
    })),
  })),
  withHandlers({
    add: p => (text, specialBehavior) =>
      p.onChange(
        p.value.concat({
          text,
          answerAttachmentPolicy: "N",
          specialBehavior,
          ...(p.allOptions ? { id: getTemporaryId(p.allOptions) } : {}),
        }),
      ),
    update: p => prop => i => v =>
      p.onChange(R.update(i, { ...p.value[i], [prop]: v }, p.value)),
    remove: p => i => p.onChange(R.remove(i, 1, p.value)),
  }),
  withHandlers({
    addNoneOfTheAbove: p => () =>
      p.add(localize("noneOfTheAbove"), specialBehavior.noneOfTheAbove),
    addAnswer: p => () => p.add(""),
    changeText: p => p.update("text"),
    changeFile: p => p.update("answerAttachmentPolicy"),
  }),
)(InputAnswers)

import React from "react"
import { SectionWrap, SectionHeader, SectionBody } from "./styled"
import { P } from "styledComponents/Typography"

const Section = ({ node, children, indices }) => {
  const depth = indices.length

  return (
    <SectionWrap>
      <SectionHeader depth={depth}>
        <P fs={20} white>
          {node.data.name}
        </P>
      </SectionHeader>
      <SectionBody>{children}</SectionBody>
    </SectionWrap>
  )
}

export default Section

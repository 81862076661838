import PartyList from "./PartyList"
import { EnhancedAssessmentsCreatePage } from "pages/AssessmentsCreatePage"
import { EnhancedAssessmentsTemplatePage } from "pages/AssessmentsTemplatePage"
import { EnhancedConclusionsDetailPage } from "pages/ConclusionsDetailPage"

export const getSuburls = match => {
  const path = match.path
  const url = match.url

  const urls = {
    list: {
      path: `${path}/list`,
      url: `${url}/list`,
      component: PartyList,
    },
    assessmentTemplate: {
      path: `${path}/assessmentTemplate`,
      url: () => `${url}/assessmentTemplate`,
      component: EnhancedAssessmentsTemplatePage,
    },
    assessment: {
      path: `${path}/assessment/:id`,
      url: id => `${url}/assessment/${id}`,
      component: EnhancedAssessmentsCreatePage,
    },
    conclusion: {
      path: `${path}/conclusion/:id`,
      url: id => `${url}/conclusion/${id}`,
      component: EnhancedConclusionsDetailPage,
    },
  }

  return urls
}

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import { previewRequest } from "data/templates/api"
import withForm from "hoc/withForm"
import withModal from "hoc/withModal"
import Textfield from "inputs/Textfield"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import { stringRequired } from "validators/string"
import * as R from "ramda"

const CreateTestFileModal = ({ close }) => (
  <>
    <Textfield
      required
      name="name"
      label={localize("nameOfTheTestFile")}
      placeholder={localize("enterName")}
    />
    <ModalButtons>
      <Button secondary type="button" onClick={close} text="Cancel" />
      <Button type="submit" text={localize("create")} />
    </ModalButtons>
  </>
)

export default R.compose(
  withModal(() => localize("createANewTestFile")),
  withUrls,
  withForm({
    schema: R.always({
      name: stringRequired,
    }),
    onSubmit: p => v =>
      previewRequest.create({
        templateId: p.templateId,
        name: v.name,
      }),
    onSuccess: p => p.close,
    redirect: (res, p) => p.urls.testFile(res.id),
  }),
)(CreateTestFileModal)

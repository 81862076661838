import styled, { css } from "styled-components/macro"
import theme from "theme"

export const RadioItemWrap = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;

  ${p =>
    p.inline
      ? css`
          margin-right: 10px;
          white-space: nowrap;
          line-height: 40px;

          &:last-child {
            margin-right: 0;
          }
        `
      : css`
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
        `};
  ${p =>
    (p.readOnly || p.disabled) &&
    css`
      cursor: default;
    `};
  ${p =>
    p.disabled &&
    css`
      opacity: 0.5;
    `};
`

export const RadioCircle = styled.div`
  width: 16px;
  height: 16px;
  border-radius: 100%;
  border: 1px solid #999;
  margin-right: 5px;
  position: relative;
  flex-shrink: 0;
  &::after {
    width: 6px;
    height: 6px;
    border-radius: 100%;
    background: ${p => (p.transparent ? "transparent" : "#fff")};
    content: "";
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }

  ${p =>
    p.active &&
    css`
      background: ${theme.color.red};
      border-color: ${theme.color.red};
    `};
`

import React from "react"
import { P } from "styledComponents/Typography"
import * as R from "ramda"
import { isCompatible } from "./helpers"

const TagValidations = ({ value, question, catalogs }) => {
  if (!value) return null
  const allCatalogs = R.values(catalogs).flatMap(R.identity)
  const catalogItem = allCatalogs.find(x => x.id === value.registryCatalogId)

  if (!isCompatible(question.questionType, catalogItem)) {
    return (
      <P fs={12} error mt={3}>
        Incompatible types
      </P>
    )
  }

  return null
}

export default TagValidations

import config from "config"
import { getClientId, login, logout } from "data/auth/rx"
import {} from "common/rxjs"
import { catchError, switchMap, share, of, throwError } from "rxjs"
import { getError } from "./helpers"
import { rxFetch } from "./fetch"
import * as R from "ramda"
import { retrieve } from "common/storage"

let refresh$

const getDefaultHeaders = (accessToken, doNotSetContentType) => ({
  ...(doNotSetContentType ? {} : { "Content-Type": "application/json" }),
  ...(accessToken ? { Authorization: `Bearer ${accessToken}` } : {}),
})

const defaultCfg = {
  headers: {},
  urlRoot: config.apiRoot,
  doNotSetContentType: false,
}

const request =
  method =>
  (url, data, cfg = {}) => {
    const currCfg = R.mergeLeft(cfg, defaultCfg)

    return rxFetch({
      url: currCfg.urlRoot + url,
      method,
      data,
      headers: {
        ...getDefaultHeaders(
          retrieve("accessToken"),
          currCfg.doNotSetContentType,
        ),
        ...currCfg.headers,
      },
    }).pipe(
      catchError(error => {
        if (error.status === 401 || error.headers["token-expired"] === "true") {
          const accessToken = retrieve("accessToken")
          const refreshToken = retrieve("refreshToken")
          const clientId = getClientId()

          if (!refresh$) {
            refresh$ = rxFetch({
              url: config.apiRoot + "/api/auth/refresh",
              method: "post",
              headers: {
                Authorization: `Bearer ${accessToken}`,
                "Content-Type": "application/json",
              },
              data: {
                clientId,
                accessToken,
                refreshToken,
              },
            }).pipe(share())
          }

          return refresh$.pipe(
            switchMap(refreshResponse => {
              refresh$ = null

              login({
                accessToken: refreshResponse.data.access_token,
                refreshToken: refreshResponse.data.refresh_token,
              })

              return rxFetch({
                url: currCfg.urlRoot + url,
                method,
                data,
                headers: {
                  ...getDefaultHeaders(
                    refreshResponse.data.access_token,
                    currCfg.doNotSetContentType,
                  ),
                  ...currCfg.headers,
                },
              })
            }),
            catchError(() => {
              refresh$ = null
              logout()
              // we tried to refresh, but it failed, so we logout, but mark this request as resolved
              return of()
            }),
          )
        }

        // 401, yet token not expired, so somehow it must have been invalidated
        if (error.status === 401) {
          logout()
        }

        return throwError(getError(error))
      }),
    )
  }

export const post = request("post")
export const get = request("get")
export const put = request("put")
export const del = request("delete")

import { localize } from "locale"
import { combineValidators, fieldOptional, fieldRequired } from "./helpers"
import * as R from "ramda"

export const numberOptional = combineValidators(fieldOptional, x => {
  if (R.isNil(x) || R.isEmpty(x)) return ""

  return /^-?\d+(.\d+)?$/.test(x) ? "" : localize("validNumber")
})

export const positiveNumberOptional = combineValidators(numberOptional, x =>
  x < 0 ? localize("greaterThanZero") : "",
)

export const positiveIntegerOptional = combineValidators(
  positiveNumberOptional,
  x => (!x || Number.isInteger(+x) ? "" : localize("isInteger")),
)

export const positiveIntegerRequired = combineValidators(
  positiveIntegerOptional,
  fieldRequired,
)

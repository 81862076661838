import dayjs from "dayjs"
import { combineValidators, fieldOptional, fieldRequired } from "./helpers"

export const afterDateOptional = (start, errorText) =>
  combineValidators(fieldOptional, v =>
    !v ||
    !start ||
    dayjs(start).isSame(v, "day") ||
    dayjs(start).isBefore(v, "day")
      ? ""
      : errorText,
  )

export const afterDateRequired = (start, errorText) =>
  combineValidators(fieldRequired, afterDateOptional(start, errorText))

export const afterTodayRequired = combineValidators(fieldRequired, v =>
  dayjs(v).isSame(dayjs(), "day") || dayjs(v).isAfter(dayjs(), "day")
    ? ""
    : "Must be today, or later.",
)

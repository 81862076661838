import styled from "styled-components/macro"

export const FixedPanelWrap = styled.div`
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  background: #fff;
  padding: 15px 0;
  z-index: 2;
  border-top: 1px solid #d8d8d8;
`

export const ScrollSpacer = styled.div`
  height: 73px;
`

import React from "react"
import AppLogo from "components/AppLogo"
import { logout } from "data/auth/rx"
import withProfile from "hoc/withProfile"
import NavigationTabs from "./NavigationTabs"
import Extras from "./Extras"
import { StyledNav, TopBar } from "./styled"

const Navigation = ({ profile, modules, tenants, title, titleOnly }) => {
  if (titleOnly) {
    return (
      <StyledNav>
        <TopBar>
          <AppLogo name={title} />
        </TopBar>
      </StyledNav>
    )
  }

  return (
    <StyledNav>
      <TopBar>
        <AppLogo name={title} />
        <Extras profile={profile} tenants={tenants} logout={logout} />
      </TopBar>
      <NavigationTabs modules={modules.modules} />
    </StyledNav>
  )
}

export default withProfile(Navigation)

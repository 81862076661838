import styled from "styled-components/macro"

export const SizeErrorWrap = styled.div`
  text-align: center;
  max-width: 250px;
`

export const StyledImg = styled.img`
  max-width: 150px;
  filter: grayscale(100%);
  transform: scaleX(-0.7);
  opacity: 0.5;
`

import React from "react"
import Button from "components/Button"
import ModalButtons from "components/ModalButtons"
import Checkbox from "inputs/Checkbox"
import UserList from "inputs/UserList"
import Panel from "components/Panel"
import { assessmentRoles } from "data/assessments/roles"
import { tenantRoles, toggleApproverSelectedRole } from "data/tenant/roles"
import { formatInitialsFromName, formatUserName } from "data/user/formatters"
import AutocompleteList from "inputs/AutocompleteList"
import { localize } from "locale"
import * as R from "ramda"
import { P } from "styledComponents/Typography"

const CoworkersPanel = ({ readOnly, apiRequest, cancelUrl, infoText }) => {
  return (
    <Panel>
      <P mb={20} gray>
        {infoText}
      </P>

      <AutocompleteList
        name="coworkers"
        label={!readOnly ? localize("addCoworker") : undefined}
        request={apiRequest.list}
        roles={assessmentRoles}
        deleteByNullingPermission
        noResultText={localize("noEmailInTenant")}
        mapOptions={R.map(x => ({
          ...x,
          value: x.id,
          name: x.email,
        }))}
        title={localize("assessmentTeam")}
        optionToAvatar={R.pipe(formatUserName, formatInitialsFromName)}
        optionToTitle={formatUserName}
        optionToSubtitle={R.prop("email")}
        readOnly={readOnly}
      />
      <UserList
        name="reviewers"
        title={localize("reviewTeam")}
        optionToAvatar={R.pipe(formatUserName, formatInitialsFromName)}
        optionToTitle={formatUserName}
        optionToSubtitle={R.prop("email")}
        customComponent={(x, xi, onChangeItem) =>
          x.permission === tenantRoles.reviewer.value ? null : (
            <Checkbox
              value={x.permission === tenantRoles.approverSelected.value}
              text={localize("selectAsApprover")}
              flip
              readOnly={readOnly}
              onChange={() =>
                onChangeItem({
                  ...x,
                  permission: toggleApproverSelectedRole(x.permission),
                })
              }
              squared
            />
          )
        }
      />
      {!readOnly && (
        <ModalButtons>
          <Button secondary type="button" to={cancelUrl} text="Cancel" />
          <Button type="submit" text="Save" />
        </ModalButtons>
      )}
    </Panel>
  )
}

export default CoworkersPanel

import React from "react"
import History from "components/History"
import { assessmentRequest } from "data/assessments/api"
import withData from "hoc/withData"
import withModal from "hoc/withModal"
import { localize } from "locale"
import * as R from "ramda"
import { preassessmentRequest } from "data/preassessments/api"

const HistoryModal = ({ data }) => <History data={data} />

export default R.compose(
  withModal(() => localize("history")),
  withData(p =>
    (p.isPreassessment ? preassessmentRequest : assessmentRequest).history(
      p.assessmentId,
    ),
  ),
)(HistoryModal)

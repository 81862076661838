import React from "react"
import Link from "components/Link"
import { P } from "styledComponents/Typography"
import { Box } from "styledComponents/Containers"
import * as R from "ramda"
import { IconWrap } from "styledComponents/Icons"
import FolderIcon from "icons/Folder"
import CaseIcon from "icons/Case"
import CaretIcon from "icons/Caret"
import { folderSuburls } from "pages/FolderCreatePage/suburls"

const WrapInLink = ({ to, isClickable, children }) => {
  if (!isClickable) return children

  return <Link to={to}>{children}</Link>
}

const Breadcrumbs = ({ breadcrumbs, urls, noCase, toggleProps }) => {
  const case_ = R.path(["case"], breadcrumbs)

  return (
    <Box flex mb={20} acenter>
      <Link to={urls.folders}>
        <P bolder red>
          Folders
        </P>
      </Link>
      <P lightgray ml={10} fs={20}>
        /
      </P>
      {breadcrumbs && (
        <WrapInLink
          ul
          to={urls.folderCreate(breadcrumbs.id, folderSuburls.cases)}
          isClickable={breadcrumbs.isClickable}
        >
          <IconWrap red={breadcrumbs.isClickable} static>
            <FolderIcon />
          </IconWrap>
          <P
            bolder
            red={breadcrumbs.isClickable}
            gray={!breadcrumbs.isClickable}
          >
            {breadcrumbs.name}
          </P>
        </WrapInLink>
      )}
      {case_ && !noCase && (
        <>
          <P lightgray ml={10} fs={20}>
            /
          </P>
          <WrapInLink
            ul
            to={urls.caseCreate(case_.id)}
            isClickable={case_.isClickable}
          >
            <IconWrap red={case_.isClickable} static>
              <CaseIcon />
            </IconWrap>
            <P bolder red={case_.isClickable} gray={!case_.isClickable}>
              {case_.name}
            </P>
          </WrapInLink>
        </>
      )}
      {toggleProps && (
        <>
          <P lightgray ml={10} fs={20}>
            /
          </P>
          <Box
            flex
            onClick={toggleProps.disabled ? undefined : toggleProps.toggle}
            ml={10}
          >
            <P bolder red={case_.isClickable} gray={!case_?.isClickable}>
              {toggleProps.isOpen ? toggleProps.hideText : toggleProps.openText}
            </P>
            <IconWrap
              red={case_?.isClickable}
              static
              rotate={toggleProps.isOpen}
            >
              <CaretIcon />
            </IconWrap>
          </Box>
        </>
      )}
    </Box>
  )
}

export default Breadcrumbs

import React from "react"
import { StyledHref, StyledNolink } from "./styled"
import { withRouter } from "react-router"
import { noop } from "common/functions"
import * as R from "ramda"
import { IconWrap } from "styledComponents/Icons"
import withHandlers from "hoc/withHandlers"

const Link = ({ to = "", children, handleClick, icon: Icon, ...rest }) => {
  const to_ = R.type(to) === "String" ? to : R.pathOr("", ["pathname"], to)

  if (
    to_.startsWith("http") ||
    to_.startsWith("mailto:") ||
    to_.startsWith("tel:")
  ) {
    return (
      <StyledHref {...rest} href={to} rel="noopener noreferrer" target="_blank">
        {Icon && (
          <IconWrap static autowidth mr={5}>
            <Icon />
          </IconWrap>
        )}
        {children}
      </StyledHref>
    )
  }

  return (
    <StyledNolink {...rest} onClick={handleClick}>
      {Icon && (
        <IconWrap static autowidth mr={5}>
          <Icon />
        </IconWrap>
      )}
      {children}
    </StyledNolink>
  )
}

export default R.compose(
  withRouter,
  withHandlers({
    handleClick: ({ onClick = noop, to, history }) => () => {
      onClick()
      if (to) {
        history.push(to)
      }
    },
  }),
)(Link)

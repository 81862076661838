import * as R from "ramda"

export const getCaseUrl = (match, assessment) => {
  if (match.url.includes("/preassessment/")) {
    return `${R.head(match.url.split("preassessment/"))}preassessment/list`
  }

  const involvedPartyId = R.path(
    ["breadcrumbs", "case", "involvedParties", 0, "id"],
    assessment,
  )

  if (match.url.includes("/conclusion/")) {
    return `${R.head(match.url.split("/conclusion/"))}/list${
      involvedPartyId ? `?involvedPartyId=${involvedPartyId}` : ""
    }`
  }

  return `${R.head(match.url.split("/assessment/"))}/list${
    involvedPartyId ? `?involvedPartyId=${involvedPartyId}` : ""
  }`
}

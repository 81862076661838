import React from "react"

export default () => (
  <svg width="24" height="24" viewBox="0 0 24 24">
    <path
      d="M11.9998748,1 C11.5178748,1 11.0358748,1.239 10.7598748,1.716 L0.193874841,20.017 C-0.357125159,20.971 0.331874841,22.165 1.43487484,22.165 L22.5658748,22.165 C23.6678748,22.165 24.3568748,20.971 23.8058748,20.017 L13.2408748,1.716 C12.9648748,1.239 12.4828748,1 11.9998748,1 Z M1.55187484,20.665 L11.9998748,2.568 L22.4478748,20.665 L1.55187484,20.665 Z M11.2503748,9.6278 L11.2503748,13.6068 C11.2503748,14.0208 11.5853748,14.3568 12.0003748,14.3568 C12.4153748,14.3568 12.7503748,14.0208 12.7503748,13.6068 L12.7503748,9.6278 C12.7503748,9.2128 12.4153748,8.8778 12.0003748,8.8778 C11.5853748,8.8778 11.2503748,9.2128 11.2503748,9.6278 Z M10.8750748,17.528 C10.8750748,18.149 11.3790748,18.653 12.0000748,18.653 C12.6210748,18.653 13.1250748,18.149 13.1250748,17.528 C13.1250748,16.907 12.6210748,16.403 12.0000748,16.403 C11.3790748,16.403 10.8750748,16.907 10.8750748,17.528 Z"
      fillRule="evenodd"
    ></path>
  </svg>
)

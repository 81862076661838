import styled, { css } from "styled-components/macro"

export const EditBarWrap = styled.div`
  padding: 10px 20px;
  margin-top: 20px;

  ${p =>
    p.type === "section" &&
    css`
      background: #333;
    `}
  ${p =>
    p.type === "subsection" &&
    css`
      background: #bbb;
    `}
  ${p =>
    p.type === "question" &&
    css`
      margin: 0;
      padding: 0;
    `}
`

import React from "react"
import Table from "components/Table"
import Button from "components/Button"
import Panel from "components/Panel"
import { Box, Spread } from "styledComponents/Containers"
import { P } from "styledComponents/Typography"
import { config } from "./config"
import { conclusionRequest } from "data/conclusions/api"
import { reportRequest } from "data/report/api"
import withData from "hoc/withData"
import withSubmit from "hoc/withSubmit"
import * as R from "ramda"
import { localize } from "locale"
import withUrls from "hoc/withUrls"
import FixedPanel from "components/FixedPanel"
import withState from "hoc/withState"
import Filters from "./Filters"
import Modals from "./Modals"
import { getSearchParams } from "./helpers"
import Pagination from "components/Pagination"
import THead from "components/THead"
import { parseSearch, stringifySearch } from "common/router"
import { stringToBool } from "common/bool"

const ConclusionsTable = ({
  location,
  history,
  data,
  loading,
  conclusionTypes,
  status,
  refetchData,
  refetch,
  ids,
  setIds,
  submit,
  urls,
  validationErrors,
  setValidationErrors,
  caseId,
  caseUrls,
  caseConclusionUrls,
}) => {
  const [detail, setDetail] = React.useState()

  const rowOptions = [
    {
      onClick: v => setDetail({ ...v, action: "restore" }),
      name: localize("restore"),
    },
    {
      onClick: v => setDetail({ ...v, action: "deletePermanent" }),
      name: localize("deletePermanently"),
    },
  ]

  const handleCheck = id => {
    if (ids.includes(id)) {
      return setIds(ids.filter(x => x !== id))
    }

    return setIds(ids.concat(id))
  }
  const d = R.pathOr([], ["conclusions"], data)
  const pageIds = d.filter(R.prop("canCreateReport")).map(R.prop("id"))

  const searchValues = parseSearch(location.search)
  const onChange = k => v => {
    const newVal = k
      ? {
          ...searchValues,
          page: 0,
          [k]: v,
        }
      : { ...searchValues, ...v }
    history.push({
      search: stringifySearch(newVal),
    })
  }

  const tableConfig = config({
    urls,
    conclusionTypes,
    refetch: refetchData,
    ids,
    handleCheck,
    rowOptions,
    status,
    caseConclusionUrls,
  })

  const sorting = {
    key: R.pathOr("ApprovedAtUtc", ["orderBy"], searchValues),
    desc: R.pathOr(true, ["orderDescending"], searchValues),
  }

  const refetchFn = () => {
    refetchData()
    refetch()
    history.push({
      search: "",
    })
  }

  return (
    <Panel noPadding>
      <Filters
        status={status}
        conclusionTypes={conclusionTypes}
        pageIds={pageIds}
        ids={ids}
        setIds={setIds}
        d={d}
        refetch={refetchFn}
        searchValues={searchValues}
        onChange={onChange}
        caseId={caseId}
      />
      <Table
        pageSize={Infinity}
        head={
          <THead
            config={tableConfig}
            sorting={sorting}
            handleSort={(_, key) =>
              onChange()({
                orderBy: key,
                orderDescending:
                  key === sorting.key ? !stringToBool(sorting.desc) : false,
              })
            }
          />
        }
        config={tableConfig}
        data={d}
        loading={loading}
        noPagination
        borderless
      />
      <Panel noPadding>
        <Pagination
          pageSize={+R.pathOr(10, ["size"], searchValues)}
          setPageSize={onChange("size")}
          page={+R.pathOr(0, ["page"], searchValues)}
          setPage={onChange("page")}
          itemsLength={R.path(["count"], data)}
        />
      </Panel>

      {ids.length > 0 && (
        <FixedPanel>
          <Spread>
            <Box flex>
              <P bold>
                {ids.length}{" "}
                {localize(ids.length === 0 ? "conclusion" : "conclusions")}{" "}
                {localize("selected")}
              </P>
              <P ml={10} red link hovernolink onClick={() => setIds([])}>
                {localize("deselectAll")}
              </P>
            </Box>
            <Button text={localize("createReport")} onClick={submit} />
          </Spread>
        </FixedPanel>
      )}
      <Modals
        validationErrors={validationErrors}
        detail={detail}
        setDetail={setDetail}
        setValidationErrors={setValidationErrors}
        refetch={refetchFn}
      />
    </Panel>
  )
}

export default R.compose(
  withUrls,
  withState("validationErrors", "setValidationErrors"),
  withState("ids", "setIds", []),
  withSubmit({
    onSubmit: p => () => reportRequest.create({ conclusionIds: p.ids }),
    onSuccess: p => res => {
      if (!res.isValid) {
        p.setValidationErrors(res.errors)
      }
    },
    redirect: (res, p) => {
      if (!res.isValid) return

      if (p.caseUrls) {
        return p.caseUrls.reports.url
      }

      return p.urls.reports
    },
  }),
  withData(
    p => {
      const req = p.listRequest ?? conclusionRequest.list
      return req(getSearchParams(p.status, p.location.search, p.caseId))
    },
    {
      refetchParams: p => [
        getSearchParams(p.status, p.location.search, p.caseId),
      ],
      noLoader: true,
      clearOnRefetch: true,
    },
  ),
)(ConclusionsTable)

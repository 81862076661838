import styled, { css } from "styled-components/macro"
import theme from "theme"
import boxModel from "styledComponents/bases/boxModel"

const style = css`
  cursor: pointer;
  color: ${theme.color.red};
  text-decoration: none;
  display: inline-flex;
  ${boxModel};

  ${p =>
    p.ul &&
    css`
      text-decoration: underline;
      &:hover {
        text-decoration: none;
      }
    `}
  ${p =>
    p.white &&
    css`
      color: #fff;
    `}
  ${p =>
    p.ellipsis &&
    css`
      white-space: nowrap;
      max-width: 500px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: block;
      min-width: 100%;
    `}
`

export const StyledHref = styled.a`
  ${style};
`
export const StyledNolink = styled.span`
  ${style};
`

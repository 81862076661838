import React from "react"
import Columns from "components/Columns"
import {
  answerTypeOptions,
  fileUploadOptions,
  isSingleMultipleOrConfirmation,
} from "data/templates/answers"
import Checkbox from "inputs/Checkbox"
import Radio from "inputs/Radio"
import LabelValue from "components/LabelValue"
import { localize } from "locale"
import * as R from "ramda"

const Flags = ({ values }) => {
  const singleOrMultiOrConfirm = isSingleMultipleOrConfirmation(
    values.questionType,
  )
  const isCustom = values.questionType === "Custom"
  const type = answerTypeOptions.find(x => x.value === values.questionType)

  return (
    <Columns widths={[90, 140, "auto", 300, 50]}>
      {isCustom ? (
        <div />
      ) : (
        <Checkbox
          value={values.isMandatory}
          label={localize("mandatoryAnswer")}
          readOnly
          noEmptyError
          mb={0}
        />
      )}
      <Checkbox
        value={values.forReportingOnly}
        label={localize("forReportingOnly")}
        readOnly
        noEmptyError
        mb={0}
      />
      <LabelValue
        label={localize("typeOfAnswer")}
        value={R.path(["name"], type)}
      />
      {singleOrMultiOrConfirm ? (
        <div />
      ) : (
        <Radio
          value={values.answerAttachmentPolicy}
          label={localize("attachment")}
          readOnly
          options={fileUploadOptions}
          inline
          byValue
          noEmptyError
          mb={0}
        />
      )}
      {/* multiple answers have cross here to delete */}
      {/* so we include this with 50px width for alignment */}
      <div />
    </Columns>
  )
}

export default Flags

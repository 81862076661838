import { Box } from "styledComponents/Containers"
import styled, { css, keyframes } from "styled-components/macro"

export const disappear = keyframes`
  0%{
    opacity: 1;
  }
  to{
    transform: scaleY(0);
    opacity: 0;
  }
`

export const AnnouncementWrap = styled(Box)`
  transform-origin: top center;

  ${p =>
    p.closing &&
    css`
      animation: ${disappear} 0.5s ease;
    `};
`
